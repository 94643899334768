import React, { Component } from "react";
import { observer } from "mobx-react";

import newEventStore from "../Stores/NewEventStore";
import storageStore from "../Stores/StorageStore";
import settingStore from "../Stores/SettingStore";
import { Col, Row } from "react-bootstrap";
import moment from "moment";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { Chart } from "react-google-charts";

class JobsForecast extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTable: false,
    };
  }

  componentDidMount() {}

  render() {
    let startDate = moment(settingStore.calstartdate);
    let endDate = moment(settingStore.calenddate);
    let dateDiff = endDate.diff(startDate, "days");
    let newDate;
    let jobdates = newEventStore.sectorSum;
    let stJob = storageStore.storageConverted;
    let bookprice = 0;
    let bookcbm = 0;
    let convprice = 0;
    let convcbm = 0;
    let targetprice = 0;
    let targetcbm = 0;
    let cbmgraph = [];
    let pricegraph = [];
    let datacbm = {};
    let dataprice = {};
    let imprice = 0;
    let improfit = 0;
    let localprice = 0;
    let imcbm = 0;
    let localcbm = 0;
    let storageCBM = 0;
    let storagePrice = 0;
    let ccprice = 0;
    let otherprice = 0;

    jobdates = jobdates.filter(
      (job) => moment(job.jobdate) >= moment(startDate)
    );
    jobdates = jobdates.filter((job) => moment(job.jobdate) <= moment(endDate));

    for (let j = 0; j < dateDiff; j++) {
      for (let i = 0; i < stJob.length; i++) {
        if (
          (moment(stJob[i].storagestartdate) <=
            moment(startDate).add(j, "days")) &
          (moment(stJob[i].storageenddate) >= moment(startDate).add(j, "days"))
        ) {
          storageCBM += parseFloat(stJob[i].storagecbm) / parseFloat(dateDiff);
          if (parseFloat(stJob[i].monthlycost) > 0) {
            storagePrice +=
              parseFloat(stJob[i].monthlycost) / parseFloat(dateDiff);
          }
        }
      }
    }

    for (let k = 0; k < dateDiff + 1; k++) {
      newDate = moment(startDate).add(k, "days").format("DD-MMM-YY");
      for (let i = 0; i < jobdates.length; i++) {
        if (
          moment(jobdates[i].jobdate).format("DD-MMM-YY") ===
          moment(startDate).add(k, "days").format("DD-MMM-YY")
        ) {
          if (jobdates[i].status === "Booked") {
            bookprice += jobdates[i].dailyprice;
            bookcbm += jobdates[i].dailycbm;
          } else if (jobdates[i].status === "Converted") {
            convprice += jobdates[i].dailyprice;
            convcbm += jobdates[i].dailycbm;
          }
          if (
            jobdates[i].status === "Booked" ||
            jobdates[i].status === "Converted"
          ) {
            if (
              jobdates[i].jobmain === "Local Move" ||
              jobdates[i].jobmain === "Corporate Move"
            ) {
              localprice += jobdates[i].dailyprice;
              localcbm += jobdates[i].dailycbm;
            } else if (
              jobdates[i].jobmain === "IM - Outbound" ||
              jobdates[i].jobmain === "CIM - Outbound" ||
              jobdates[i].jobmain === "IM - Inbound" ||
              jobdates[i].jobmain === "CIM - Inbound"
            ) {
              imprice += jobdates[i].dailyprice;
              improfit += jobdates[i].dailyprofit;
              imcbm += jobdates[i].dailycbm;
            } else if (jobdates[i].jobmain === "Only Clearance") {
              ccprice += jobdates[i].dailyprice;
            } else {
              otherprice += jobdates[i].dailyprice;
            }
          }
        }
      }
      targetcbm =
        Math.round(10 * (900 / dateDiff) * k) / 10 -
        Math.round((bookcbm + convcbm) * 10) / 10;
      targetprice =
        Math.round(10 * (7000 / dateDiff) * k) / 10 -
        Math.round((bookprice + convprice) * 10) / 10;
      if (targetcbm < 0) {
        targetcbm = 0;
      }
      if (targetprice < 0) {
        targetprice = 0;
      }
      targetprice = Math.round(10 * targetprice) / 10;
      targetcbm = Math.round(10 * targetcbm) / 10;

      dataprice = {
        name: moment(newDate).format("DD-MMM"),
        booked: Math.round(bookprice * 10) / 10,
        converted: Math.round(convprice * 10) / 10,
        target: targetprice,
      };
      datacbm = {
        name: moment(newDate).format("DD-MMM"),
        booked: Math.round(bookcbm * 10) / 10,
        converted: Math.round(convcbm * 10) / 10,
        target: targetcbm,
      };
      cbmgraph.push(datacbm);
      pricegraph.push(dataprice);
    }

    improfit = Math.round(improfit * 10) / 10;

    return (
      <div>
        <h2>{moment(startDate).format("MMMM")} Report</h2>
        <h3>{moment(startDate).format("MMMM")} Revenue (KD)</h3>
        <Row>
          <Chart
            width={200}
            height={200}
            chartType="Gauge"
            loader={<div>Loading Chart</div>}
            data={[
              ["Label", "Value"],
              [
                "All-Profit",
                Math.round(10 * (localprice + improfit + storagePrice)) / 10,
              ],
            ]}
            options={{
              redFrom: 0,
              redTo: 5000,
              yellowFrom: 5000,
              yellowTo: 7500,
              greenFrom: 7500,
              greenTo: 10000,
              minorTicks: 2,
              majorTicks: [0, "", "", 7500, ""],
              max: 10000,
            }}
            rootProps={{ "data-testid": "1" }}
          />
          {/* <Chart
            width={200}
            height={200}
            chartType="Gauge"
            loader={<div>Loading Chart</div>}
            data={[
              ["Label", "Value"],
              [
                "All",
                Math.round(10 * (localprice + imprice + storagePrice)) / 10,
              ],
            ]}
            options={{
              redFrom: 0,
              redTo: 24750,
              yellowFrom: 24750,
              yellowTo: 33000,
              greenFrom: 33000,
              greenTo: 41250,
              minorTicks: 2,
              majorTicks: [0, "", "", "", 33000, ""],
              max: 41250,
            }}
            rootProps={{ "data-testid": "1" }}
          /> */}
          <Chart
            width={200}
            height={200}
            chartType="Gauge"
            loader={<div>Loading Chart</div>}
            data={[
              ["Label", "Value"],
              ["LM", localprice],
            ]}
            options={{
              redFrom: 0,
              redTo: 5000,
              yellowFrom: 5000,
              yellowTo: 7000,
              greenFrom: 7000,
              greenTo: 10000,
              minorTicks: 2,
              majorTicks: [0, "", "", "", "", "", "", 7000, "", "", ""],
              max: 10000,
            }}
            rootProps={{ "data-testid": "1" }}
          />
          <Chart
            width={200}
            height={200}
            chartType="Gauge"
            loader={<div>Loading Chart</div>}
            data={[
              ["Label", "Value"],
              ["IM", imprice],
            ]}
            options={{
              redFrom: 0,
              redTo: 15000,
              yellowFrom: 15000,
              yellowTo: 25000,
              greenFrom: 25000,
              greenTo: 30000,
              minorTicks: 2,
              majorTicks: [0, "", "", "", "", 25000, ""],
              max: 30000,
            }}
            rootProps={{ "data-testid": "1" }}
          />
          <Chart
            width={200}
            height={200}
            chartType="Gauge"
            loader={<div>Loading Chart</div>}
            data={[
              ["Label", "Value"],
              ["ST", Math.round(10 * storagePrice) / 10],
            ]}
            options={{
              redFrom: 0,
              redTo: 500,
              yellowFrom: 500,
              yellowTo: 1000,
              greenFrom: 1000,
              greenTo: 1500,
              minorTicks: 2,
              majorTicks: [0, "", "", "", 1000, "", ""],
              max: 1500,
            }}
            rootProps={{ "data-testid": "1" }}
          />
          <Chart
            width={200}
            height={200}
            chartType="Gauge"
            loader={<div>Loading Chart</div>}
            data={[
              ["Label", "Value"],
              ["IM-Profit", improfit],
            ]}
            options={{
              redFrom: 0,
              redTo: 3000,
              yellowFrom: 3000,
              yellowTo: 5000,
              greenFrom: 5000,
              greenTo: 6000,
              minorTicks: 2,
              majorTicks: [0, "", "", "", "", 5000, ""],
              max: 6000,
            }}
            rootProps={{ "data-testid": "1" }}
          />
          <Chart
            width={200}
            height={200}
            chartType="Gauge"
            loader={<div>Loading Chart</div>}
            data={[
              ["Label", "Value"],
              ["Clearance", ccprice],
            ]}
            options={{
              redFrom: 0,
              redTo: 3000,
              yellowFrom: 3000,
              yellowTo: 5000,
              greenFrom: 5000,
              greenTo: 6000,
              minorTicks: 2,
              majorTicks: [0, "", "", "", "", 5000, ""],
              max: 6000,
            }}
            rootProps={{ "data-testid": "1" }}
          />
          <Chart
            width={200}
            height={200}
            chartType="Gauge"
            loader={<div>Loading Chart</div>}
            data={[
              ["Label", "Value"],
              ["Other", otherprice],
            ]}
            options={{
              redFrom: 0,
              redTo: 3000,
              yellowFrom: 3000,
              yellowTo: 5000,
              greenFrom: 5000,
              greenTo: 6000,
              minorTicks: 2,
              majorTicks: [0, "", "", "", "", 5000, ""],
              max: 6000,
            }}
            rootProps={{ "data-testid": "1" }}
          />
        </Row>
        <hr />
        <h3>{moment(startDate).format("MMMM")} CBM</h3>
        <Row>
          <Chart
            width={200}
            height={200}
            chartType="Gauge"
            loader={<div>Loading Chart</div>}
            data={[
              ["Label", "Value"],
              ["All", localcbm + imcbm],
            ]}
            options={{
              redFrom: 0,
              redTo: 500,
              yellowFrom: 500,
              yellowTo: 700,
              greenFrom: 700,
              greenTo: 1000,
              minorTicks: 2,
              majorTicks: [0, "", "", "", "", "", "", 700, "", "", ""],
              max: 1000,
            }}
            rootProps={{ "data-testid": "1" }}
          />
          <Chart
            width={200}
            height={200}
            chartType="Gauge"
            loader={<div>Loading Chart</div>}
            data={[
              ["Label", "Value"],
              ["LM", localcbm],
            ]}
            options={{
              redFrom: 0,
              redTo: 500,
              yellowFrom: 500,
              yellowTo: 700,
              greenFrom: 700,
              greenTo: 1000,
              minorTicks: 2,
              majorTicks: [0, "", "", "", "", "", "", 700, "", "", ""],
              max: 1000,
            }}
            rootProps={{ "data-testid": "1" }}
          />
          <Chart
            width={200}
            height={200}
            chartType="Gauge"
            loader={<div>Loading Chart</div>}
            data={[
              ["Label", "Value"],
              ["IM", imcbm],
            ]}
            options={{
              redFrom: 0,
              redTo: 500,
              yellowFrom: 500,
              yellowTo: 700,
              greenFrom: 700,
              greenTo: 1000,
              minorTicks: 2,
              majorTicks: [0, "", "", "", "", "", "", 700, "", "", ""],
              max: 1000,
            }}
            rootProps={{ "data-testid": "1" }}
          />
          <Chart
            width={200}
            height={200}
            chartType="Gauge"
            loader={<div>Loading Chart</div>}
            data={[
              ["Label", "Value"],
              ["ST", Math.round(10 * storageCBM) / 10],
            ]}
            options={{
              redFrom: 0,
              redTo: 200,
              yellowFrom: 200,
              yellowTo: 300,
              greenFrom: 300,
              greenTo: 500,
              minorTicks: 2,
              majorTicks: [0, "", "", 300, "", ""],
              max: 500,
            }}
            rootProps={{ "data-testid": "1" }}
          />
        </Row>
        <hr />
        <Row>
          <Col>
            <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
              Total Price for {moment(settingStore.startdate).format("MMM-YY")}
            </h3>
            <ResponsiveContainer width="100%" height={400}>
              <AreaChart
                data={pricegraph}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis
                  label={{ value: "KD", angle: -90, position: "insideLeft" }}
                />
                <Tooltip />
                <Area
                  type="monotone"
                  dataKey="converted"
                  stackId="1"
                  stroke="#136600"
                  fill="#136600"
                />
                <Area
                  type="monotone"
                  dataKey="booked"
                  stackId="1"
                  stroke="#20b000"
                  fill="#20b000"
                />
                <Area
                  type="monotone"
                  dataKey="target"
                  stackId="1"
                  stroke="#f5b4ae"
                  fill="#f5b4ae"
                />
              </AreaChart>
            </ResponsiveContainer>
          </Col>
          <Col>
            <h3 style={{ textAlign: "center", fontWeight: "bold" }}>
              CBM for {moment(settingStore.startdate).format("MMM-YY")}
            </h3>
            <ResponsiveContainer width="100%" height={400}>
              <AreaChart
                data={cbmgraph}
                margin={{
                  top: 10,
                  right: 30,
                  left: 0,
                  bottom: 0,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="name" />
                <YAxis
                  label={{ value: "CBM", angle: -90, position: "insideLeft" }}
                />
                <Tooltip />
                <Area
                  type="monotone"
                  dataKey="converted"
                  stackId="1"
                  stroke="#136600"
                  fill="#136600"
                />
                <Area
                  type="monotone"
                  dataKey="booked"
                  stackId="1"
                  stroke="#20b000"
                  fill="#20b000"
                />
                <Area
                  type="monotone"
                  dataKey="target"
                  stackId="1"
                  stroke="#f5b4ae"
                  fill="#f5b4ae"
                />
              </AreaChart>
            </ResponsiveContainer>
          </Col>
        </Row>
        <br />
        <hr />
        <br />
      </div>
    );
  }
}

export default observer(JobsForecast);

// export default class Example extends PureComponent {
//   static jsfiddleUrl = "https://jsfiddle.net/alidingling/c1rLyqj1/";
