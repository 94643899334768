import React from "react";
// First way to import
import { observer } from "mobx-react";
import { Button, Container, Row, Col } from "react-bootstrap";
import jobEditStore from "../Stores/JobEditStore";
import PaymentRow from "./PaymentRow/PaymentRow";

import "react-datepicker/dist/react-datepicker.css";

class PaymentPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let paymentRows;

    paymentRows = jobEditStore.payments.map((payment, index) => (
      <div key={index}>
        Payment #{index + 1}
        <PaymentRow payment={payment} indexVal={index} />
        <hr />
        <hr />
      </div>
    ));

    return (
      <div>
        <Container style={{ maxWidth: "98%" }}>
          <form>{paymentRows}</form>
          <hr />
          <Row>
            <Col>
              <Button
                block
                variant="success"
                onClick={() => jobEditStore.addPayment("Cash")}
              >
                Add Cash Payment
              </Button>
            </Col>
            <Col>
              <Button
                block
                variant="dark"
                onClick={() => jobEditStore.addPayment("Online Link")}
              >
                Add Online Link Payment
              </Button>
            </Col>
            <Col>
              <Button
                block
                variant="dark"
                onClick={() => jobEditStore.addPayment("KNET")}
              >
                Add KNET Payment
              </Button>
            </Col>
            <Col>
              <Button
                block
                variant="outline-danger"
                onClick={() => jobEditStore.addPayment("Bank Charge")}
              >
                Add Bank Charge
              </Button>
            </Col>
            <Col>
              <Button
                block
                variant="outline-danger"
                onClick={() => jobEditStore.addPayment("Shipping Expense")}
              >
                Add Shipping Expense
              </Button>
            </Col>
            <Col>
              <Button
                block
                variant="danger"
                onClick={() => jobEditStore.addPayment("Refund")}
              >
                Add Refund
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default observer(PaymentPage);
