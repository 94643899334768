import React, { Component } from "react";
import { observer } from "mobx-react";

import hrEventStore from "../Stores/HREventStore";
import newEventStore from "../Stores/NewEventStore";
import editJobStore from "../Stores/EditJobStore";
import JobModal from "../FilterModules/JobModal";
import jobSelectionStore from "../Stores/JobSelectionStore";

import MaterialTable from "material-table";
import { Button, Col, Row } from "react-bootstrap";

class PendingClients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTable: false,
      filtername: "",
    };
    this.options = {
      defaultSortName: "id", // default sort column name
      // exportCSVBtn: this.createCustomExportCSVButton,
      defaultSortOrder: "asc", // default sort order
      onRowClick: function (job) {
        editJobStore.selectJob(job.id);
      },
    };
  }

  componentDidMount() {
    hrEventStore.fetchLeadClientTimes();
  }
  handleClose = () => jobSelectionStore.closeModal();

  render() {
    let staffbuttons = newEventStore.officestaff.map((staff, index) => (
      <Col key={index}>
        <Button
          variant={
            this.state.filtername === staff.first_name ? "dark" : "outline-dark"
          }
          onClick={() => this.setState({ filtername: staff.first_name })}
          block
        >
          {staff.first_name}
        </Button>
        <br />
      </Col>
    ));

    let jobs = hrEventStore.leadclient;

    if (this.state.filtername !== "") {
      jobs = jobs.filter((job) => job.emfocalpoint === this.state.filtername);
    } else if (this.state.filtername === "empty") {
      jobs = jobs.filter((job) => job.emfocalpoint.length < 1);
    }

    let columnnames = [
      { title: "ID", field: "jobid", removable: true },
      { title: "Client", field: "title", removable: true },
      { title: "Type", field: "jobmain", removable: true },
      { title: "Status", field: "status", removable: true },
      {
        title: "Days Since",
        field: "daysdiff",
        removable: true,
        defaultSort: "desc",
      },
      { title: "Focal Point", field: "emfocalpoint", removable: true },
      { title: "Last Update", field: "lastupdatedays", removable: true },
    ];

    return (
      <div>
        <JobModal
          show={jobSelectionStore.showModal}
          onHide={this.handleClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        />
        <h1>Pending Clients Page</h1>
        <Row>
          {staffbuttons}
          <Col>
            <Button
              variant="outline-danger"
              onClick={() => this.setState({ filtername: "" })}
              block
            >
              Reset
            </Button>
          </Col>
        </Row>
        <Button
          block
          variant={this.state.showTable ? "outline-dark" : "dark"}
          onClick={() => this.setState({ showTable: !this.setState.showTable })}
        >
          {this.state.showTable ? "Hide" : "Show"} Pending Clients List
        </Button>
        {this.state.showTable && (
          <MaterialTable
            title={
              "Clients Sorted By Last Status Change - Only Leads and Proposals"
            }
            columns={columnnames}
            data={jobs}
            paging={false}
            options={{
              paging: false,
              toolbarButtonAlignment: "left",
              tableLayout: "auto",
              grouping: true,
              padding: "dense",
              maxBodyHeight: 1000,
            }}
            actions={[
              (rowData) => ({
                icon: "edit",
                tooltip: "Edit Item",
                onClick: (event, rowData) => {
                  jobSelectionStore.fetchSingleJob(rowData.jobid);
                },
              }),
            ]}
          />
        )}
      </div>
    );
  }
}

export default observer(PendingClients);
