import React from "react";
import moment from "moment";
// First way to import
import { observer } from "mobx-react";
import { Button, Container, Row, Col } from "react-bootstrap";
import inventoryStore from "../Stores/InventoryStore";

class JobUpdatePage2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <h4>
          Today's Job Material Consumption{" "}
          {moment(inventoryStore.selectedjob.start).format("DD-MMM-YYYY")}
        </h4>
        <h5>{inventoryStore.selectedjob.title}</h5>
        <hr />

        <b>Bubble Wrap Used (Meters):</b>
        <Container>
          <Row>
            <Col>
              {inventoryStore.bubble >= 10 && (
                <Button
                  onClick={() => inventoryStore.updateInventory(-10, "bubble")}
                  size="sm"
                  variant={"outline-danger"}
                >
                  -10
                </Button>
              )}
            </Col>
            <Col>
              {inventoryStore.bubble >= 5 && (
                <Button
                  onClick={() => inventoryStore.updateInventory(-5, "bubble")}
                  size="sm"
                  variant={"outline-danger"}
                >
                  -5
                </Button>
              )}
            </Col>
            <Col>
              <Button
                variant={"outline-dark"}
                disabled
                style={{ fontWeight: "bold" }}
              >
                {inventoryStore.bubble}
              </Button>
            </Col>
            <Col>
              <Button
                variant={"outline-success"}
                onClick={() => inventoryStore.updateInventory(5, "bubble")}
                size="sm"
              >
                +5
              </Button>
            </Col>
            <Col>
              <Button
                variant={"outline-success"}
                onClick={() => inventoryStore.updateInventory(10, "bubble")}
                size="sm"
              >
                +10
              </Button>
            </Col>
          </Row>
        </Container>
        <hr />
        <b>Easy Move Boxes Used:</b>
        <Container>
          <Row>
            <Col>
              {inventoryStore.embox >= 5 && (
                <Button
                  onClick={() => inventoryStore.updateInventory(-5, "embox")}
                  size="sm"
                  variant={"outline-danger"}
                >
                  -5
                </Button>
              )}
            </Col>
            <Col>
              {inventoryStore.embox >= 1 && (
                <Button
                  onClick={() => inventoryStore.updateInventory(-1, "embox")}
                  size="sm"
                  variant={"outline-danger"}
                >
                  -1
                </Button>
              )}
            </Col>
            <Col>
              <Button
                variant={"outline-dark"}
                disabled
                style={{ fontWeight: "bold" }}
              >
                {inventoryStore.embox}
              </Button>
            </Col>
            <Col>
              <Button
                variant={"outline-success"}
                onClick={() => inventoryStore.updateInventory(1, "embox")}
                size="sm"
              >
                +1
              </Button>
            </Col>
            <Col>
              <Button
                variant={"outline-success"}
                onClick={() => inventoryStore.updateInventory(5, "embox")}
                size="sm"
              >
                +5
              </Button>
            </Col>
          </Row>
        </Container>
        <hr />
        <b>Stretch Film Used:</b>
        <Container>
          <Row>
            <Col>
              {inventoryStore.stretch >= 5 && (
                <Button
                  onClick={() => inventoryStore.updateInventory(-5, "stretch")}
                  size="sm"
                  variant={"outline-danger"}
                >
                  -5
                </Button>
              )}
            </Col>
            <Col>
              {inventoryStore.stretch >= 1 && (
                <Button
                  onClick={() => inventoryStore.updateInventory(-1, "stretch")}
                  size="sm"
                  variant={"outline-danger"}
                >
                  -1
                </Button>
              )}
            </Col>
            <Col>
              <Button
                variant={"outline-dark"}
                disabled
                style={{ fontWeight: "bold" }}
              >
                {inventoryStore.stretch}
              </Button>
            </Col>
            <Col>
              <Button
                variant={"outline-success"}
                onClick={() => inventoryStore.updateInventory(1, "stretch")}
                size="sm"
              >
                +1
              </Button>
            </Col>
            <Col>
              <Button
                variant={"outline-success"}
                onClick={() => inventoryStore.updateInventory(5, "stretch")}
                size="sm"
              >
                +5
              </Button>
            </Col>
          </Row>
        </Container>
        <hr />
        <b>Nylon Wraps Used:</b>
        <Container>
          <Row>
            <Col>
              {inventoryStore.nylon >= 5 && (
                <Button
                  onClick={() => inventoryStore.updateInventory(-5, "nylon")}
                  size="sm"
                  variant={"outline-danger"}
                >
                  -5
                </Button>
              )}
            </Col>
            <Col>
              {inventoryStore.nylon >= 1 && (
                <Button
                  onClick={() => inventoryStore.updateInventory(-1, "nylon")}
                  size="sm"
                  variant={"outline-danger"}
                >
                  -1
                </Button>
              )}
            </Col>
            <Col>
              <Button
                variant={"outline-dark"}
                disabled
                style={{ fontWeight: "bold" }}
              >
                {inventoryStore.nylon}
              </Button>
            </Col>
            <Col>
              <Button
                variant={"outline-success"}
                onClick={() => inventoryStore.updateInventory(1, "nylon")}
                size="sm"
              >
                +1
              </Button>
            </Col>
            <Col>
              <Button
                variant={"outline-success"}
                onClick={() => inventoryStore.updateInventory(5, "nylon")}
                size="sm"
              >
                +5
              </Button>
            </Col>
          </Row>
        </Container>
        <hr />
        <b>Local Tape Used:</b>
        <Container>
          <Row>
            <Col>
              {inventoryStore.localtape >= 5 && (
                <Button
                  onClick={() =>
                    inventoryStore.updateInventory(-5, "localtape")
                  }
                  size="sm"
                  variant={"outline-danger"}
                >
                  -5
                </Button>
              )}
            </Col>
            <Col>
              {inventoryStore.localtape >= 1 && (
                <Button
                  onClick={() =>
                    inventoryStore.updateInventory(-1, "localtape")
                  }
                  size="sm"
                  variant={"outline-danger"}
                >
                  -1
                </Button>
              )}
            </Col>
            <Col>
              <Button
                variant={"outline-dark"}
                disabled
                style={{ fontWeight: "bold" }}
              >
                {inventoryStore.localtape}
              </Button>
            </Col>
            <Col>
              <Button
                variant={"outline-success"}
                onClick={() => inventoryStore.updateInventory(1, "localtape")}
                size="sm"
              >
                +1
              </Button>
            </Col>
            <Col>
              <Button
                variant={"outline-success"}
                onClick={() => inventoryStore.updateInventory(5, "localtape")}
                size="sm"
              >
                +5
              </Button>
            </Col>
          </Row>
        </Container>
        <hr />
        <b>Shipping Tape Used:</b>
        <Container>
          <Row>
            <Col>
              {inventoryStore.shippingtape >= 5 && (
                <Button
                  onClick={() =>
                    inventoryStore.updateInventory(-5, "shippingtape")
                  }
                  size="sm"
                  variant={"outline-danger"}
                >
                  -5
                </Button>
              )}
            </Col>
            <Col>
              {inventoryStore.shippingtape >= 1 && (
                <Button
                  onClick={() =>
                    inventoryStore.updateInventory(-1, "shippingtape")
                  }
                  size="sm"
                  variant={"outline-danger"}
                >
                  -1
                </Button>
              )}
            </Col>
            <Col>
              <Button
                variant={"outline-dark"}
                disabled
                style={{ fontWeight: "bold" }}
              >
                {inventoryStore.shippingtape}
              </Button>
            </Col>
            <Col>
              <Button
                variant={"outline-success"}
                onClick={() =>
                  inventoryStore.updateInventory(1, "shippingtape")
                }
                size="sm"
              >
                +1
              </Button>
            </Col>
            <Col>
              <Button
                variant={"outline-success"}
                onClick={() =>
                  inventoryStore.updateInventory(5, "shippingtape")
                }
                size="sm"
              >
                +5
              </Button>
            </Col>
          </Row>
        </Container>
        <hr />

        <Row>
          <Col>
            <Button
              block
              variant="danger"
              onClick={() => inventoryStore.changePage(1)}
            >
              Back
            </Button>
          </Col>
          <Col>
            <Button
              block
              variant="outline-success"
              onClick={() => inventoryStore.changePage(3)}
            >
              Next
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default observer(JobUpdatePage2);
