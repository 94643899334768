import React, { Component } from "react";
import { observer } from "mobx-react";

import eventStore from "../Stores/EventStore";
import { Button, Row, Col } from "react-bootstrap";
import Grid from "@material-ui/core/Grid";
import MaterialTable from "material-table";
import PaymentRowAll from "./PaymentRowAll";
import PaymentModalNew from "./PaymentModalNew";
import "react-datepicker/dist/react-datepicker.css";

import userStore from "../Stores/UserStore";
import ReactGA from "react-ga";

function initializeReactGA() {
  if (userStore.user.username !== "jazzaf") {
    ReactGA.initialize("UA-163244126-1");
    ReactGA.pageview(window.location.pathname);
    ReactGA.event({
      category: window.location.pathname,
      action: userStore.user.username,
      label: new Date().toLocaleTimeString(),
    });
  }
}

class FinancialPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editPayment: false,
      financialsLoaded: false,
      modalShow: false,
      indexVal: 0,
      filter: false,
    };
    this.options = {
      defaultSortName: "paymentdate", // default sort column name
      defaultSortOrder: "desc", // default sort order
      onRowClick: function (event) {
        this.showModal();
      },
    };
  }

  componentDidMount() {
    initializeReactGA();

    if (!eventStore.financialsLoaded) {
      eventStore.fetchFinancials();
    }
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  render() {
    let columnnames = [
      { title: "ID", field: "id", removable: true },
      {
        title: "Confirmed",
        field: "expenseheading",
        removable: true,
        lookup: { Yes: "Yes", No: "No" },
      },
      {
        title: "Date",
        field: "paymentdate",
        removable: true,
        defaultSort: "asc",
      },
      { title: "Amount", field: "invoiceamount", removable: true },
      { title: "Department", field: "category1", removable: true },
      {
        title: "Type",
        field: "category2",
        removable: true,

        render: (rowData) => {
          return rowData.category2 === "Income" ||
            rowData.category2 === "Transfer In" ? (
            <p style={{ color: "#006b17", fontWeight: "bold" }}>
              {rowData.category2}
            </p>
          ) : (
            <p style={{ color: "#57000e", fontWeight: "bold" }}>
              {rowData.category2}
            </p>
          );
        },
      },
      { title: "Cash", field: "category3", removable: true },
      { title: "General", field: "category4", removable: true },
      { title: "Specific", field: "category5", removable: true },
      {
        title: "Date",
        field: "paymentdate",
        removable: true,
        defaultSort: "asc",
      },
      { title: "Account", field: "bankaccount", removable: true },
      { title: "Method", field: "paymentmethod", removable: true },
      { title: "Voucher", field: "vouchernumber", removable: true },
      { title: "Invoice", field: "invoicenumber", removable: true },
      { title: "JobID", field: "job", removable: true },
      { title: "Client", field: "clientname", removable: true },
      { title: "Remark", field: "remarks", removable: true },
    ];

    if (userStore.access < 2.5) {
      columnnames.push({
        title: "Confirmed",
        field: "expenseheading",
        removable: true,
        lookup: { "": "Blank", Yes: "Yes", No: "No" },
      });
    }

    let modalClose = () => {
      this.setState({ modalShow: false });
      eventStore.postNewPaymentUpdate(eventStore.newpayment, "reset");
    };

    let deletepayment = (
      <Button
        variant="danger"
        block
        onClick={() => {
          eventStore.postNewPaymentUpdate(eventStore.newpayment, "delete");
        }}
      >
        Delete Payment
      </Button>
    );

    let clientForm = (
      <Row>
        <Col>
          <Button
            block
            variant="success"
            onClick={() => {
              eventStore.postNewPaymentUpdate({}, "reset");
              eventStore.changePaymentUpdate(true);
            }}
          >
            Add A New Payment
          </Button>
        </Col>
        <Col>
          <Button
            block
            variant="success"
            onClick={() => {
              eventStore.postNewPaymentUpdate({}, "Gas");
              eventStore.changePaymentUpdate(true);
            }}
          >
            Add A Gas Receipt
          </Button>
        </Col>
        <Col>
          <Button
            block
            variant="success"
            onClick={() => {
              eventStore.postNewPaymentUpdate({}, "Boxes");
              eventStore.changePaymentUpdate(true);
            }}
          >
            Add A Boxes Receipt
          </Button>
        </Col>
      </Row>
    );

    let newPaymentForm = (
      <div>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={6} sm={6} md={6}>
            <Button
              variant="success"
              block
              onClick={() =>
                eventStore.postNewPaymentUpdate(eventStore.newpayment, "post")
              }
            >
              Save
            </Button>
          </Grid>
          <Grid item xs={6} sm={4} md={4}>
            <Button
              variant="warning"
              block
              onClick={() => {
                eventStore.postNewPaymentUpdate(eventStore.newpayment, "reset");
              }}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6} sm={2} md={2}>
            {eventStore.newpayment.id !== 0 && deletepayment}
          </Grid>
        </Grid>
        <hr />
        <PaymentRowAll />
        <hr />
      </div>
    );

    if (eventStore.paymentupdate) {
      return newPaymentForm;
    }

    let financials = eventStore.financialrows;
    if (this.state.filter) {
      financials = financials.filter((row) => row.expenseheading !== "Yes");
      financials = financials.filter((row) => row.expenseheading !== "No");
    }

    return (
      <div>
        <PaymentModalNew show={this.state.modalShow} onHide={modalClose} />
        {this.state.editPayment ? newPaymentForm : clientForm}
        <br />

        <hr />

        <MaterialTable
          title={"Book Keeping Records"}
          columns={columnnames}
          data={financials}
          paging={true}
          options={{
            padding: "dense",
            actionsColumnIndex: 0,
            exportAllData: true,
            paging: true,
            pageSize: 50,
            toolbarButtonAlignment: "left",
            maxBodyHeight: 1000,
            tableLayout: "auto",
            exportButton: true,
            exportFileName: "Book Keeping Records",
            grouping: true,
            filtering: true,
          }}
          actions={[
            (rowData) => ({
              icon: "edit",
              tooltip: "Edit Item",
              onClick: (event, rowData) => {
                eventStore.selectPayment(rowData);
                this.setState({ modalShow: true });
              },
            }),
          ]}
        />

        <br />
        <br />
      </div>
    );
  }
}

export default observer(FinancialPage);
