import React from "react";
import { observer } from "mobx-react";

import { Text, View, StyleSheet, Font } from "@react-pdf/renderer";

Font.register(
  "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
  { family: "Oswald" }
);

const styles = StyleSheet.create({
  body: {
    padding: 60
    // paddingTop: 35,
    // paddingBottom: 65,
    // paddingHorizontal: 35
  },
  unbreakable: { width: "100%", height: 400 },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Oswald"
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: "Oswald"
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman"
  },
  text2: {
    fontSize: 10,
    textAlign: "justify",
    fontFamily: "Times-Roman"
  },
  text3: {
    fontSize: 12,
    textAlign: "justify",
    fontFamily: "Times-Roman"
  },
  text4: {
    fontSize: 8,
    textAlign: "justify",
    fontFamily: "Times-Roman"
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey"
  },
  header2: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
    fontWeight: "italic"
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey"
  },
  headerTite: {
    fontSize: 14
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0
  },
  tableRow: { margin: "auto", flexDirection: "row" },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0
  },
  tableCell: { margin: "auto", marginTop: 5, fontSize: 10 },
  tableHeaderStyle: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 14,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    fontWeight: "bold",
    paddingTop: 6
  },
  tableRow2a: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 12,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "70%"
  },
  tableRow2b: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontWeight: "bold",
    fontSize: 12,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "30%"
  },
  tableRow2c: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontWeight: "bold",
    fontSize: 12,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "20%"
  },
  subtitle2: {
    fontSize: 14,
    fontFamily: "Oswald"
  },
  subtitle3: {
    fontSize: 12,
    margin: 12,
    fontFamily: "Oswald"
  },
  tableRow2e: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 12,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "70%"
  },
  tableRow2f: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 12,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "30%"
  },
  tableRow2g: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 12,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "20%"
  }
});

class ItemsList extends React.Component {
  render() {
    let room = this.props.room;
    let itemslist;

    itemslist = room.item.map((item, index) => (
      <View style={{ flexDirection: "row" }} key={index}>
        <Text style={styles.tableRow2a}>{item.title}</Text>
        <Text style={styles.tableRow2b}>{item.quantity}</Text>
      </View>
    ));

    let itemcount = 0;
    for (let i = 0; i < room.item.length; i++) {
      itemcount = itemcount + parseFloat(room.item[i].quantity);
    }

    let summarytab = (
      <View style={{ flexDirection: "row" }}>
        <Text style={styles.tableRow2e}>Total Items in {room.title}</Text>
        <Text style={styles.tableRow2f}>{itemcount}</Text>
      </View>
    );

    return (
      <View>
        {itemslist}
        {summarytab}
      </View>
    );
  }
}

export default observer(ItemsList);
