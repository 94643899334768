import React from "react";
// First way to import
import moment from "moment";
import { observer } from "mobx-react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Button } from "react-bootstrap";
import jobSelectionStore from "../Stores/JobSelectionStore";
import JobModal from "../FilterModules/JobModal";
import storageStore from "../Stores/StorageStore";
import MaterialTable from "material-table";

moment.locale("ko", {
  week: {
    dow: 6,
    doy: 6,
  },
});
const localizer = momentLocalizer(moment);

class StoragePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalShow: false,
      estCalendar: false,
      perfCalendar: false,
      showTab: 0,
    };
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  eventStyleGetter = (event, start, end, isSelected) => {
    var backgroundColor = "green";
    var style = {
      backgroundColor: backgroundColor,
      borderRadius: "3px",
      opacity: 0.7,
      border: "3px",
      borderColor: "black",
      display: "block",
      fontSize: 11,
      height: 16,
    };
    return {
      style: style,
    };
  };

  handleClose = () => jobSelectionStore.closeModal();

  render() {
    let remainingDue = 0;

    for (let m = 0; m < storageStore.storageUnpaidJobs.length; m++) {
      remainingDue += parseFloat(
        storageStore.storageUnpaidJobs[m].totalremaining
      );
    }

    remainingDue = Math.round(1000 * remainingDue) / 1000;

    let storageCBM = 0;
    let storagePrice = 0;

    for (let i = 0; i < storageStore.storageCurrent.length; i++) {
      storageCBM += parseFloat(storageStore.storageCurrent[i].storagecbm);
      storagePrice += parseFloat(storageStore.storageCurrent[i].monthlycost);
    }
    let columnnames = [
      { title: "Client", field: "clienttitle", removable: true },
      { title: "Storage", field: "storagestatus", removable: true },
      { title: "CBM", field: "storagecbm", removable: true },
      { title: "Monthly Cost", field: "monthlycost", removable: true },
      { title: "Stard Date", field: "storagestartdate", removable: true },
      { title: "End Date", field: "storageenddate", removable: true },
      { title: "Paid To", field: "storagepaidto", removable: true },
      { title: "Job Status", field: "status", removable: true },
    ];

    let columnnamesUnpaid = columnnames;
    columnnamesUnpaid.push({
      title: "Months Behind",
      field: "monthsbehind",
      removable: true,
    });
    columnnamesUnpaid.push({
      title: "Total Remaining KD",
      field: "totalremaining",
      removable: true,
    });

    return (
      <div>
        <JobModal
          show={jobSelectionStore.showModal}
          onHide={this.handleClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        />
        <h1>{Math.round(10 * storageCBM) / 10} CBM Filled.</h1>
        <h1>{Math.round(10 * storagePrice) / 10} KD Monthly.</h1>

        <Button
          onClick={() => this.setState({ showTab: 0 })}
          variant={this.state.showTab === 0 ? "dark" : "outline-dark"}
        >
          Calendar
        </Button>
        <Button
          onClick={() => this.setState({ showTab: 1 })}
          variant={this.state.showTab === 1 ? "dark" : "outline-dark"}
        >
          Current Clients
        </Button>
        <Button
          onClick={() => this.setState({ showTab: 2 })}
          variant={this.state.showTab === 2 ? "dark" : "outline-dark"}
        >
          Missing Payment
        </Button>
        <Button
          onClick={() => this.setState({ showTab: 3 })}
          variant={this.state.showTab === 3 ? "dark" : "outline-dark"}
        >
          Old & Not Closed
        </Button>
        <Button
          onClick={() => this.setState({ showTab: 4 })}
          variant={this.state.showTab === 4 ? "dark" : "outline-dark"}
        >
          Potential Clients
        </Button>
        <Button
          onClick={() => this.setState({ showTab: 5 })}
          variant={this.state.showTab === 5 ? "dark" : "outline-dark"}
        >
          Old Closed
        </Button>
        {this.state.showTab === 0 && (
          <Calendar
            localizer={localizer}
            events={storageStore.storageConverted}
            eventPropGetter={this.eventStyleGetter}
            startAccessor="storagestartdate"
            endAccessor="storageenddate"
            style={{ height: 1200 }}
            defaultDate={new Date()}
            views={["month"]}
            titleAccessor="desc"
            popup
            onSelectEvent={(event) => {
              jobSelectionStore.fetchSingleJob(event.job);
            }}
          />
        )}
        {this.state.showTab === 1 && (
          <MaterialTable
            title={storageStore.storageCurrent.length + " Current Storage Jobs"}
            columns={columnnames}
            data={storageStore.storageCurrent}
            paging={true}
            options={{
              actionsColumnIndex: 0,
              exportAllData: true,
              paging: true,
              pageSize: 25,
              pageSizeOptions: [5, 10, 15, 20, 25],
              toolbarButtonAlignment: "left",
              tableLayout: "auto",
              exportButton: true,
              exportFileName: "Current Storage Jobs",
              grouping: true,
              padding: "dense",
            }}
            actions={[
              (rowData) => ({
                icon: "edit",
                tooltip: "Edit Item",
                onClick: (event, rowData) => {
                  jobSelectionStore.fetchSingleJob(rowData.job);
                },
              }),
            ]}
          />
        )}
        {this.state.showTab === 2 && (
          <MaterialTable
            title={
              storageStore.storageUnpaidJobs.length +
              " Unpaid Storage Jobs: " +
              remainingDue +
              " KD to be collected"
            }
            columns={columnnamesUnpaid}
            data={storageStore.storageUnpaidJobs}
            paging={true}
            options={{
              actionsColumnIndex: 0,
              exportAllData: true,
              paging: true,
              pageSize: 25,
              pageSizeOptions: [5, 10, 15, 20, 25],
              toolbarButtonAlignment: "left",
              tableLayout: "auto",
              exportButton: true,
              exportFileName: "Unpaid Storage Jobs",
              grouping: true,
              padding: "dense",
            }}
            actions={[
              (rowData) => ({
                icon: "edit",
                tooltip: "Edit Item",
                onClick: (event, rowData) => {
                  jobSelectionStore.fetchSingleJob(rowData.job);
                },
              }),
            ]}
          />
        )}
        {this.state.showTab === 3 && (
          <MaterialTable
            title={
              storageStore.storageOldNotClosed.length + " Old Storage Jobs"
            }
            columns={columnnames}
            data={storageStore.storageOldNotClosed}
            paging={true}
            options={{
              actionsColumnIndex: 0,
              exportAllData: true,
              paging: true,
              pageSize: 25,
              pageSizeOptions: [5, 10, 15, 20, 25],
              toolbarButtonAlignment: "left",
              tableLayout: "auto",
              exportButton: true,
              exportFileName: "Old Storage Jobs",
              grouping: true,
              padding: "dense",
            }}
            actions={[
              (rowData) => ({
                icon: "edit",
                tooltip: "Edit Item",
                onClick: (event, rowData) => {
                  jobSelectionStore.fetchSingleJob(rowData.job);
                },
              }),
            ]}
          />
        )}
        {this.state.showTab === 4 && (
          <MaterialTable
            title={
              storageStore.storagePotential.length + " Potential Storage Jobs"
            }
            columns={columnnames}
            data={storageStore.storagePotential}
            paging={true}
            options={{
              actionsColumnIndex: 0,
              exportAllData: true,
              paging: true,
              pageSize: 25,
              pageSizeOptions: [5, 10, 15, 20, 25],
              toolbarButtonAlignment: "left",
              tableLayout: "auto",
              exportButton: true,
              exportFileName: "Potential Storage Jobs",
              grouping: true,
              padding: "dense",
            }}
            actions={[
              (rowData) => ({
                icon: "edit",
                tooltip: "Edit Item",
                onClick: (event, rowData) => {
                  jobSelectionStore.fetchSingleJob(rowData.job);
                },
              }),
            ]}
          />
        )}
        {this.state.showTab === 5 && (
          <MaterialTable
            title={
              storageStore.storageOldClosed.length + " Closed Storage Jobs"
            }
            columns={columnnames}
            data={storageStore.storageOldClosed}
            paging={true}
            options={{
              actionsColumnIndex: 0,
              exportAllData: true,
              paging: true,
              pageSize: 25,
              pageSizeOptions: [5, 10, 15, 20, 25],
              toolbarButtonAlignment: "left",
              tableLayout: "auto",
              exportButton: true,
              exportFileName: "Closed Storage Jobs",
              grouping: true,
              padding: "dense",
            }}
            actions={[
              (rowData) => ({
                icon: "edit",
                tooltip: "Edit Item",
                onClick: (event, rowData) => {
                  jobSelectionStore.fetchSingleJob(rowData.job);
                },
              }),
            ]}
          />
        )}
      </div>
    );
  }
}

export default observer(StoragePage);
