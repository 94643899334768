import React from "react";
// First way to import
import { observer } from "mobx-react";
import { Button, Container } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import jobEditStore from "../Stores/JobEditStore";

import { Select } from "antd";
const { Option } = Select;

class JobDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let minPrice = 0;
    let maxPrice = 0;

    if (jobEditStore.job.jobmain === "Local Move") {
      if (jobEditStore.job.jobsecond === "Full Service") {
        minPrice = jobEditStore.job.cbm * 4;
        maxPrice = jobEditStore.job.cbm * 6;
        if (minPrice < 65) {
          minPrice = 65;
        }
        if (maxPrice < 65) {
          maxPrice = 65;
        }
      } else if (jobEditStore.job.jobsecond === "Regular Service") {
        minPrice = jobEditStore.job.cbm * 4;
        maxPrice = jobEditStore.job.cbm * 6;
        if (minPrice < 65) {
          minPrice = 65;
        }
        if (maxPrice < 65) {
          maxPrice = 65;
        }
      }
    } else if (jobEditStore.job.jobmain === "Corporate Move") {
      if (jobEditStore.job.jobsecond === "Full Service") {
        minPrice = jobEditStore.job.cbm * 4;
        maxPrice = jobEditStore.job.cbm * 6;
        if (minPrice < 65) {
          minPrice = 65;
        }
        if (maxPrice < 65) {
          maxPrice = 65;
        }
      } else if (jobEditStore.job.jobsecond === "Regular Service") {
        minPrice = jobEditStore.job.cbm * 4;
        maxPrice = jobEditStore.job.cbm * 6;
        if (minPrice < 65) {
          minPrice = 65;
        }
        if (maxPrice < 65) {
          maxPrice = 65;
        }
      }
    }

    let secondjob;
    if (
      jobEditStore.job.jobmain === "Local Move" ||
      jobEditStore.job.jobmain === "Corporate Move"
    ) {
      secondjob = (
        <Select
          showSearch
          placeholder="Second Job"
          optionFilterProp="children"
          value={jobEditStore.job.jobsecond}
          onChange={e => jobEditStore.updateJobDetails(e, "jobsecond")}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          <Option value="" />
          <Option value={"Full Service"}>Full Service</Option>
          <Option value={"Regular Service"}>Regular Service</Option>
        </Select>
      );
    } else if (
      jobEditStore.job.jobmain === "IM - Inbound" ||
      jobEditStore.job.jobmain === "CIM - Inbound"
    ) {
      secondjob = (
        <Select
          showSearch
          placeholder="Second Job"
          optionFilterProp="children"
          value={jobEditStore.job.jobsecond}
          onChange={e => jobEditStore.updateJobDetails(e, "jobsecond")}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          <Option value="" />
          <Option value={"Door to Door"}>Door to Door</Option>
          <Option value={"Port to Port"}>Port to Port</Option>
          <Option value={"Port to Door"}>Port to Door</Option>
          <Option value={"Door to Port"}>Door to Port</Option>
        </Select>
      );
    } else if (
      jobEditStore.job.jobmain === "IM - Outbound" ||
      jobEditStore.job.jobmain === "CIM - Outbound"
    ) {
      secondjob = (
        <Select
          showSearch
          placeholder="Second Job"
          optionFilterProp="children"
          value={jobEditStore.job.jobsecond}
          onChange={e => jobEditStore.updateJobDetails(e, "jobsecond")}
        >
          <Option value="" />
          <Option value={"Door to Door"}>Door to Door</Option>
          <Option value={"Port to Port"}>Port to Port</Option>
          <Option value={"Port to Door"}>Port to Door</Option>
          <Option value={"Door to Port"}>Door to Port</Option>
        </Select>
      );
    } else if (jobEditStore.job.jobmain === "Other") {
      secondjob = (
        <Select
          showSearch
          placeholder="Second Job"
          optionFilterProp="children"
          value={jobEditStore.job.jobsecond}
          onChange={e => jobEditStore.updateJobDetails(e, "jobsecond")}
        >
          <Option value="" />
          <Option value={"Other"}>Other</Option>
        </Select>
      );
    } else {
      secondjob = (
        <Select
          showSearch
          placeholder="Second Job"
          optionFilterProp="children"
          value={jobEditStore.job.jobsecond}
          onChange={e => jobEditStore.updateJobDetails(e, "jobsecond")}
        >
          <Option value="" />
          <Option value={"Only Storage"}>Only Storage</Option>
          <Option value={"Only Clearance"}>Only Clearance</Option>
        </Select>
      );
    }
    let addChargeList;

    addChargeList = jobEditStore.addcharge.map((addcharge, index) => (
      <Grid container spacing={1} alignItems="center" key={index}>
        <Grid xs={2} sm={1} md={1}></Grid>
        <Grid xs={2} sm={1} md={1}>
          {index + 1}){" "}
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <TextField
            size="small"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label={"Additional Charge " + (index + 1) + ":"}
            name="title"
            autoComplete="title"
            type="text"
            style={{ color: "red" }}
            value={addcharge.title}
            onChange={e =>
              jobEditStore.updateAddCharge(e.target.value, index, "title")
            }
          />
        </Grid>
        <Grid item xs={12} sm={4} md={4}>
          <TextField
            size="small"
            variant="outlined"
            margin="normal"
            required
            fullWidth
            label="Additional Charge Price"
            name="addchargeprice"
            autoComplete="addchargeprice"
            type="text"
            style={{ color: "red" }}
            value={addcharge.price}
            onChange={e =>
              jobEditStore.updateAddCharge(e.target.value, index, "price")
            }
          />
        </Grid>
        <Grid item xs={2} sm={2} md={2}>
          <Button
            variant="outline-danger"
            block
            onClick={() =>
              jobEditStore.postAddChargeUpdate(
                [],
                addcharge.id,
                "destroy",
                index
              )
            }
          >
            <i className="fas fa-times"></i>
          </Button>
        </Grid>
      </Grid>
    ));

    let addChargeTotal = 0;
    for (let i = 0; i < jobEditStore.addcharge.length; i++) {
      addChargeTotal =
        addChargeTotal + parseFloat(jobEditStore.addcharge[i].price);
    }

    addChargeTotal = Math.round(1000 * addChargeTotal) / 1000;

    return (
      <div>
        <Container style={{ maxWidth: "98%" }}>
          <h6>Quality Score = {jobEditStore.jobdetailQuality}%</h6>
          <form>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12} sm={6} md={4}>
                <FormControl
                  variant="outlined"
                  style={{ width: "100%" }}
                  size="small"
                >
                  <b>Main Job</b>
                  <Select
                    showSearch
                    autoFocus
                    placeholder="Main Job"
                    optionFilterProp="children"
                    value={jobEditStore.job.jobmain}
                    onChange={e => jobEditStore.updateJobDetails(e, "jobmain")}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option value={""}></Option>
                    <Option value={"Local Move"}>Local Move</Option>
                    <Option value={"Corporate Move"}>Corporate Move</Option>
                    <Option value={"IM - Inbound"}>IM - Inbound</Option>
                    <Option value={"IM - Outbound"}>IM - Outbound</Option>
                    <Option value={"CIM - Inbound"}>CIM - Inbound</Option>
                    <Option value={"CIM - Outbound"}>CIM - Outbound</Option>
                    <Option value={"Only Storage"}>Only Storage</Option>
                    <Option value={"Only Clearance"}>Only Clearance</Option>
                    <Option value={"Other"}>Other</Option>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={4}>
                <FormControl
                  variant="outlined"
                  style={{ width: "100%" }}
                  size="small"
                >
                  <b>Second Job</b>
                  {secondjob}
                </FormControl>
              </Grid>
              <hr />
              <Grid item xs={12} sm={12} md={4}>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Company Group"
                  name="companygroup"
                  autoComplete="companygroup"
                  type="text"
                  value={jobEditStore.job.companygroup}
                  onChange={e =>
                    jobEditStore.updateJobDetails(
                      e.target.value,
                      "companygroup"
                    )
                  }
                />
              </Grid>
            </Grid>
            <hr />
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Estimated CBM"
                  name="cbm"
                  autoComplete="cbm"
                  type="number"
                  value={jobEditStore.job.cbm}
                  onChange={e =>
                    jobEditStore.updateJobDetails(e.target.value, "cbm")
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Minimum Price"
                  name="minprice"
                  autoComplete="minprice"
                  type="text"
                  value={jobEditStore.job.cbm * 4 + " KD"}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="maxprice"
                  label="Maximum Price"
                  name="maxprice"
                  autoComplete="maxprice"
                  type="text"
                  value={jobEditStore.job.cbm * 6 + " KD"}
                  disabled
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Calculated Discount (%)"
                  name="discount"
                  autoComplete="discount"
                  type="number"
                  disabled
                  value={
                    Math.round(
                      (1 -
                        jobEditStore.job.lastprice /
                          jobEditStore.job.firstprice) *
                        10000
                    ) / 100
                  }
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="First Price"
                  name="firstprice"
                  autoComplete="firstprice"
                  type="number"
                  value={jobEditStore.job.firstprice}
                  onChange={e =>
                    jobEditStore.updateJobDetails(e.target.value, "firstprice")
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label={
                    "Moving Price " +
                    jobEditStore.job.cbm * 4 +
                    " to " +
                    jobEditStore.job.cbm * 4 +
                    "KD"
                  }
                  name="lastprice"
                  autoComplete="lastprice"
                  type="number"
                  value={jobEditStore.job.lastprice}
                  onChange={e =>
                    jobEditStore.updateJobDetails(e.target.value, "lastprice")
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Discount Name"
                  name="discountname"
                  autoComplete="discountname"
                  type="text"
                  value={jobEditStore.job.discountname}
                  onChange={e =>
                    jobEditStore.updateJobDetails(
                      e.target.value,
                      "discountname"
                    )
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Discount Percent (%)"
                  name="discountvalue"
                  autoComplete="discountvalue"
                  type="number"
                  value={jobEditStore.job.discountvalue}
                  onChange={e =>
                    jobEditStore.updateJobDetails(
                      e.target.value,
                      "discountvalue"
                    )
                  }
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12} sm={6} md={6}>
                <Button
                  block
                  variant="outline-success"
                  onClick={() => jobEditStore.addAddCharge()}
                >
                  Add an Additional Charge
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <Button block variant="dark" disabled>
                  Total Additional Charges = <b>+{addChargeTotal} KWD</b>
                </Button>
              </Grid>
            </Grid>
            {addChargeList}
            <br />
            <Button block variant="dark">
              Total Move (
              {Math.round(1000 * parseFloat(jobEditStore.job.lastprice)) / 1000}
              ) + Add Charges ({addChargeTotal}) ={" "}
              {Math.round(
                1000 *
                  (parseFloat(jobEditStore.job.lastprice) +
                    parseFloat(addChargeTotal))
              ) / 1000}{" "}
              KWD
            </Button>
          </form>
          <br />
        </Container>
      </div>
    );
  }
}

export default observer(JobDetails);
