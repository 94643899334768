import React from "react";
// First way to import
import moment from "moment";
import { observer } from "mobx-react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Button } from "react-bootstrap";
import { SyncLoader } from "react-spinners";

import settingStore from "../Stores/SettingStore";

import hrEventStore from "../Stores/HREventStore";
import {
  ComposedChart,
  Area,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

class LeadsGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
      accumulative: true,
      type: "price",
    };
  }

  componentDidMount() {}

  render() {
    if (!hrEventStore.leadjobtimeloaded) {
      hrEventStore.fetchLeadJobTimes();
      return (
        <SyncLoader
          sizeUnit={"px"}
          size={50}
          color={"#0017FF"}
          loading={true}
        />
      );
    }

    let newDate;
    let oldDate;
    let startDate = moment(settingStore.calstartdate);
    let endDate = moment(settingStore.calenddate);
    let dateDiff = endDate.diff(startDate, "days");
    let jobs = hrEventStore.leadjobs;

    let graphstatus = [];
    let name = "";

    let leadcount = 0;
    let proposalcount = 0;
    let bookedcount = 0;
    let convertedcount = 0;
    let cancelledcount = 0;

    let oldcount = 0;

    for (let k = 0; k < dateDiff; k++) {
      newDate = moment(startDate).add(k, "days").format("DD-MMM-YY");
      oldDate = moment(newDate).subtract(30, "days").format("DD-MMM-YY");

      name = moment(newDate).format("DD-MMM");

      for (let i = 0; i < jobs.length; i++) {
        if (
          moment(jobs[i].leaddate).format("DD-MMM-YY") ===
          moment(startDate).add(k, "days").format("DD-MMM-YY")
        ) {
          if (jobs[i].status === "Lead") {
            leadcount += 1;
          } else if (jobs[i].status === "Proposal") {
            proposalcount += 1;
          } else if (jobs[i].status === "Booked") {
            bookedcount += 1;
          } else if (jobs[i].status === "Converted") {
            convertedcount += 1;
          } else if (jobs[i].status === "Cancelled") {
            cancelledcount += 1;
          }
        } else if (
          moment(jobs[i].leaddate).format("DD-MMM-YY") ===
          moment(oldDate).format("DD-MMM-YY")
        ) {
          oldcount += 1;
        }
      }
      if (moment(newDate) > moment(new Date())) {
        leadcount = 0;
        proposalcount = 0;
        bookedcount = 0;
        convertedcount = 0;
        cancelledcount = 0;
      }

      graphstatus.push({
        name: name,
        leads: leadcount,
        proposals: proposalcount,
        booked: bookedcount,
        converted: convertedcount,
        cancelled: cancelledcount,
        lastmonth: oldcount,
      });
      if (!this.state.accumulative) {
        leadcount = 0;
        proposalcount = 0;
        bookedcount = 0;
        convertedcount = 0;
        cancelledcount = 0;
        oldcount = 0;
      }
    }

    return (
      <div>
        <Button
          block
          variant={"dark"}
          onClick={() =>
            this.setState({ accumulative: !this.state.accumulative })
          }
        >
          <h3>
            {this.state.accumulative ? "Accumulative" : "Daily"} - Leads By
            Status for {moment(settingStore.startdate).format("MMM-YY")}
          </h3>
        </Button>

        <ResponsiveContainer width="100%" height={400}>
          <ComposedChart
            data={graphstatus}
            margin={{
              top: 20,
              right: 20,
              bottom: 20,
              left: 20,
            }}
          >
            <CartesianGrid stroke="#f5f5f5" />
            <XAxis dataKey="name" />
            <YAxis
              label={{
                value: "Number of Leads",
                angle: -90,
                position: "insideLeft",
              }}
            />
            <Tooltip />
            <Legend />
            <Area
              type="monotone"
              dataKey="lastmonth"
              fill="#8884d8"
              stroke="#8884d8"
              fillOpacity={0.5}
            />
            <Bar dataKey="leads" stackId="a" fill="#878787" />
            <Bar dataKey="proposals" stackId="a" fill="#ccc500" />
            <Bar dataKey="booked" stackId="a" fill="#00cc18" />
            <Bar dataKey="converted" stackId="a" fill="#007d0f" />
            <Bar dataKey="cancelled" stackId="a" fill="#b0152f" />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    );
  }
}

export default observer(LeadsGraph);
