import React, { Component } from "react";
import { observer } from "mobx-react";

import managementStore from "../Stores/ManagementStore";

import settingStore from "../Stores/SettingStore";
import { Button, Col, Row } from "react-bootstrap";
import moment from "moment";

import ReactApexChart from "react-apexcharts";

import { SyncLoader } from "react-spinners";

class ProposalPipeline extends Component {
  constructor(props) {
    super(props);

    this.state = {
      staff: {
        reyna: true,
        yobel: true,
        aasim: true,
        katrina: true,
        vinith: true,
      },
      options1: {
        chart: {
          type: "bar",
          height: 100,
          stacked: true,
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        xaxis: {
          categories: ["Pipeline", "Target"],
          labels: {
            formatter: function (val) {
              return val + "K";
            },
          },
        },
        theme: { palette: "palette1" },
        tooltip: {
          y: {
            formatter: function (val) {
              return 1000 * val + " KD";
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 40,
        },
      },
      options2: {
        chart: {
          type: "bar",
          height: 100,
          stacked: true,
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        xaxis: {
          categories: ["Distribution"],
          labels: {
            formatter: function (val) {
              return val;
            },
          },
        },
        theme: { palette: "palette1" },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 40,
        },
      },
    };
  }

  render() {
    if (!managementStore.pipelineloaded) {
      managementStore.fetchPipeline();
      return (
        <SyncLoader
          sizeUnit={"px"}
          size={50}
          color={"#0017FF"}
          loading={true}
        />
      );
    }
    let tarCBM = 1800;
    let tarPrice = 32000;
    let tarProfit = 12000;

    let startDate = moment(settingStore.calstartdate);
    let endDate = moment(settingStore.calenddate);
    let dateDiff = endDate.diff(startDate, "days");
    let jobdates = managementStore.pipelinejobs.filter(
      (job) => job.status === "Proposal"
    );

    if (managementStore.filtType === "im") {
      jobdates = jobdates.filter(
        (job) =>
          job.jobmain === "IM - Inbound" ||
          job.jobmain === "IM - Outbound" ||
          job.jobmain === "CIM - Inbound" ||
          job.jobmain === "Only Clearance" ||
          job.jobmain === "CIM - Outbound"
      );
    } else {
      jobdates = jobdates.filter(
        (job) =>
          job.jobmain === "Local Move" ||
          job.jobmain === "Corporate Move" ||
          job.jobmain === "Only Storage"
      );
    }

    if (!managementStore.stafflist.reyna)
      jobdates = jobdates.filter((job) => job.emfocalpoint !== "Reyna");
    if (!managementStore.stafflist.yobel)
      jobdates = jobdates.filter((job) => job.emfocalpoint !== "Yobel");
    if (!managementStore.stafflist.aasim)
      jobdates = jobdates.filter((job) => job.emfocalpoint !== "Aasim");
    if (!managementStore.stafflist.katrina)
      jobdates = jobdates.filter((job) => job.emfocalpoint !== "Katrina");
    if (!managementStore.stafflist.vinith)
      jobdates = jobdates.filter((job) => job.emfocalpoint !== "Vinith");

    let dashboardButtons = (
      <Row>
        <Col>
          <Button
            block
            variant={
              managementStore.filtType !== "im" ? "dark" : "outline-dark"
            }
            onClick={() => managementStore.changeDashboard("Local")}
          >
            Local
          </Button>
        </Col>
        <Col>
          <Button
            block
            variant={
              managementStore.filtType === "im" ? "dark" : "outline-dark"
            }
            onClick={() => managementStore.changeDashboard("im")}
          >
            International
          </Button>
        </Col>
      </Row>
    );

    let filterstaffbuttons;

    let totalCBM = 0;
    let totalPrice = 0;
    let totalProfit = 0;

    if (managementStore.filtType === "im") {
      tarCBM = 900;
      tarPrice = 25000;
      tarProfit = 5000;
      filterstaffbuttons = (
        <Row>
          <Col>
            <Button
              block
              variant={
                managementStore.stafflist.katrina ? "dark" : "outline-dark"
              }
              onClick={() => managementStore.changeStaff("Katrina")}
            >
              Katrina
            </Button>
          </Col>
          <Col>
            <Button
              block
              variant={
                managementStore.stafflist.vinith ? "dark" : "outline-dark"
              }
              onClick={() => managementStore.changeStaff("Vinith")}
            >
              Vinith
            </Button>
          </Col>
        </Row>
      );
    } else {
      tarCBM = 900;
      tarPrice = 7000;
      tarProfit = 7000;
      filterstaffbuttons = (
        <Row>
          <Col>
            <Button
              block
              variant={
                managementStore.stafflist.reyna ? "dark" : "outline-dark"
              }
              onClick={() => managementStore.changeStaff("Reyna")}
            >
              Reyna
            </Button>
          </Col>
          <Col>
            <Button
              block
              variant={
                managementStore.stafflist.yobel ? "dark" : "outline-dark"
              }
              onClick={() => managementStore.changeStaff("Yobel")}
            >
              Yobel
            </Button>
          </Col>
          <Col>
            <Button
              block
              variant={
                managementStore.stafflist.aasim ? "dark" : "outline-dark"
              }
              onClick={() => managementStore.changeStaff("Aasim")}
            >
              Aasim
            </Button>
          </Col>
        </Row>
      );
    }

    let totalJobs = [];
    let vinithJobs = [];
    let katrinaJobs = [];
    let reynaJobs = [];
    let aasimJobs = [];
    let yobelJobs = [];
    let otherJobs = [];

    let vinithCBM = 0;
    let vinithPrice = 0;
    let vinithProfit = 0;
    let katrinaCBM = 0;
    let katrinaPrice = 0;
    let katrinaProfit = 0;
    let reynaCBM = 0;
    let reynaPrice = 0;
    let reynaProfit = 0;
    let aasimCBM = 0;
    let aasimPrice = 0;
    let aasimProfit = 0;
    let yobelCBM = 0;
    let yobelPrice = 0;
    let yobelProfit = 0;
    let otherCBM = 0;
    let otherPrice = 0;
    let otherProfit = 0;

    for (let j = 0; j < dateDiff; j++) {
      for (let i = 0; i < jobdates.length; i++) {
        if (
          moment(jobdates[i].jobdate).format("DD-MMM-YY") ===
          moment(startDate).add(j, "days").format("DD-MMM-YY")
        ) {
          if (jobdates[i].emfocalpoint === "Vinith") {
            vinithCBM += parseFloat(jobdates[i].dailycbm);
            vinithPrice += parseFloat(jobdates[i].dailyprice);
            vinithProfit += parseFloat(jobdates[i].dailyprofit);
            vinithJobs.push(jobdates[i].id);
          } else if (jobdates[i].emfocalpoint === "Katrina") {
            katrinaCBM += parseFloat(jobdates[i].dailycbm);
            katrinaPrice += parseFloat(jobdates[i].dailyprice);
            katrinaProfit += parseFloat(jobdates[i].dailyprofit);
            katrinaJobs.push(jobdates[i].id);
          } else if (jobdates[i].emfocalpoint === "Reyna") {
            reynaCBM += parseFloat(jobdates[i].dailycbm);
            reynaPrice += parseFloat(jobdates[i].dailyprice);
            reynaProfit += parseFloat(jobdates[i].dailyprofit);
            reynaJobs.push(jobdates[i].id);
          } else if (jobdates[i].emfocalpoint === "Aasim") {
            aasimCBM += parseFloat(jobdates[i].dailycbm);
            aasimPrice += parseFloat(jobdates[i].dailyprice);
            aasimProfit += parseFloat(jobdates[i].dailyprofit);
            aasimJobs.push(jobdates[i].id);
          } else if (jobdates[i].emfocalpoint === "Yobel") {
            yobelCBM += parseFloat(jobdates[i].dailycbm);
            yobelPrice += parseFloat(jobdates[i].dailyprice);
            yobelProfit += parseFloat(jobdates[i].dailyprofit);
            yobelJobs.push(jobdates[i].id);
          } else {
            otherCBM += parseFloat(jobdates[i].dailycbm);
            otherPrice += parseFloat(jobdates[i].dailyprice);
            otherProfit += parseFloat(jobdates[i].dailyprofit);
            otherJobs.push(jobdates[i].id);
          }
          totalCBM += parseFloat(jobdates[i].dailycbm);
          totalPrice += parseFloat(jobdates[i].dailyprice);
          totalProfit += parseFloat(jobdates[i].dailyprofit);
          totalJobs.push(jobdates[i].id);
        }
      }
    }

    let utotal = [...new Set(totalJobs)];
    let uvinith = [...new Set(vinithJobs)];
    let ukatrina = [...new Set(katrinaJobs)];
    let ureyna = [...new Set(reynaJobs)];
    let uaasim = [...new Set(aasimJobs)];
    let uyobel = [...new Set(yobelJobs)];
    let uother = [...new Set(otherJobs)];

    totalCBM = Math.round(totalCBM);
    totalPrice = Math.round(totalPrice) / 1000;
    totalProfit = Math.round(totalProfit) / 1000;

    vinithCBM = Math.round(vinithCBM);
    vinithPrice = Math.round(vinithPrice) / 1000;
    vinithProfit = Math.round(vinithProfit) / 1000;
    katrinaCBM = Math.round(katrinaCBM);
    katrinaPrice = Math.round(katrinaPrice) / 1000;
    katrinaProfit = Math.round(katrinaProfit) / 1000;
    reynaCBM = Math.round(reynaCBM);
    reynaPrice = Math.round(reynaPrice) / 1000;
    reynaProfit = Math.round(reynaProfit) / 1000;
    aasimCBM = Math.round(aasimCBM);
    aasimPrice = Math.round(aasimPrice) / 1000;
    aasimProfit = Math.round(aasimProfit) / 1000;
    yobelCBM = Math.round(yobelCBM);
    yobelPrice = Math.round(yobelPrice) / 1000;
    yobelProfit = Math.round(yobelProfit) / 1000;
    otherCBM = Math.round(otherCBM);
    otherPrice = Math.round(otherPrice) / 1000;
    otherProfit = Math.round(otherProfit) / 1000;

    tarCBM = Math.round(tarCBM);
    tarPrice = Math.round(tarPrice) / 1000;
    tarProfit = Math.round(tarProfit) / 1000;

    let graphDataPrice = [
      { name: "Reyna", data: [reynaPrice] },
      { name: "Yobel", data: [yobelPrice] },
      { name: "Aasim", data: [aasimPrice] },
      { name: "Katrina", data: [katrinaPrice] },
      { name: "Vinith", data: [vinithPrice] },
      { name: "Other", data: [otherPrice] },
    ];
    let graphDataCBM = [
      { name: "Reyna", data: [reynaCBM] },
      { name: "Yobel", data: [yobelCBM] },
      { name: "Aasim", data: [aasimCBM] },
      { name: "Katrina", data: [katrinaCBM] },
      { name: "Vinith", data: [vinithCBM] },
      { name: "Other", data: [otherCBM] },
    ];
    let graphDataProfit = [
      { name: "Reyna", data: [reynaProfit] },
      { name: "Yobel", data: [yobelProfit] },
      { name: "Aasim", data: [aasimProfit] },
      { name: "Katrina", data: [katrinaProfit] },
      { name: "Vinith", data: [vinithProfit] },
      { name: "Other", data: [otherProfit] },
    ];
    let graphDataJob = [
      { name: "Reyna", data: [ureyna.length] },
      { name: "Yobel", data: [uyobel.length] },
      { name: "Aasim", data: [uaasim.length] },
      { name: "Katrina", data: [ukatrina.length] },
      { name: "Vinith", data: [uvinith.length] },
      { name: "Other", data: [uother.length] },
    ];

    return (
      <div>
        {dashboardButtons}
        <hr />
        {filterstaffbuttons}
        <h3>
          Total Price: {1000 * totalPrice} KD (
          {Math.round((1000 * totalPrice) / tarPrice) / 10} %)
        </h3>
        <ReactApexChart
          options={this.state.options1}
          series={graphDataPrice}
          type="bar"
          height={200}
        />
        <hr />
        <h3>Total Jobs: {utotal.length} Jobs</h3>
        <ReactApexChart
          options={this.state.options2}
          series={graphDataJob}
          type="bar"
          height={200}
        />
        <hr />
        <h3>
          Total CBM: {totalCBM} CBM (
          {Math.round((1000 * totalCBM) / tarCBM) / 10}%)
        </h3>
        <ReactApexChart
          options={this.state.options2}
          series={graphDataCBM}
          type="bar"
          height={200}
        />
        <hr />
        <h3>
          Total Profit: {1000 * totalProfit} KD (
          {Math.round((1000 * totalProfit) / tarProfit) / 10}%)
        </h3>
        <ReactApexChart
          options={this.state.options1}
          series={graphDataProfit}
          type="bar"
          height={200}
        />
        <hr />
        <br />
        <hr />
        <br />
        <br />
        <br />
      </div>
    );
  }
}

export default observer(ProposalPipeline);
