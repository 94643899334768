import React from "react";
// First way to import
import { observer } from "mobx-react";
import { Button, Container } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import eventStore from "../Stores/EventStore";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import { Input } from "antd";
import Paper from "@material-ui/core/Paper";
import settingStore from "../Stores/SettingStore";
import jobEditStore from "../Stores/JobEditStore";

const { TextArea } = Input;

class EditClientPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        <h3 style={{ fontweight: "bold" }}>
          {"   "}
          Update {eventStore.editClient.title}:
        </h3>
        <h6>Quality Score = {jobEditStore.clientQuality}%</h6>
        <Paper elevation={5}>
          <Container style={{ maxWidth: "98%" }}>
            <br />

            <form>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="title"
                label="Name"
                name="title"
                autoComplete="title"
                autoFocus
                type="text"
                style={{ color: "red" }}
                value={eventStore.editClient.title}
                onChange={e =>
                  eventStore.updateClientDetails(e.target.value, "title")
                }
              />
              <TextField
                style={{ width: "25%" }}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="phone1"
                label="Phone 1"
                name="phone1"
                autoComplete="phone1"
                autoFocus
                type="text"
                value={eventStore.editClient.phone1}
                onChange={e =>
                  eventStore.updateClientDetails(e.target.value, "phone1")
                }
              />
              <TextField
                style={{ width: "25%" }}
                variant="outlined"
                margin="normal"
                fullWidth
                id="phone2"
                label="Phone 2"
                name="phone2"
                autoComplete="phone2"
                autoFocus
                type="text"
                value={eventStore.editClient.phone2}
                onChange={e =>
                  eventStore.updateClientDetails(e.target.value, "phone2")
                }
              />
              <TextField
                style={{ width: "50%" }}
                variant="outlined"
                margin="normal"
                fullWidth
                id="email"
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                type="email"
                value={eventStore.editClient.email}
                onChange={e =>
                  eventStore.updateClientDetails(e.target.value, "email")
                }
              />
              <br />
              <br />
              <FormControl variant="outlined" style={{ width: "50%" }}>
                <InputLabel>Lead Type</InputLabel>
                <Select
                  native
                  label="Age"
                  value={eventStore.editClient.leadtype}
                  onChange={e =>
                    eventStore.updateClientDetails(e.target.value, "leadtype")
                  }
                  inputProps={{
                    name: "age",
                    id: "outlined-age-native-simple"
                  }}
                >
                  <option value="" />
                  <option value={"Received Call"}>Received Call</option>
                  <option value={"Missed Call"}>Missed Call</option>
                  <option value={"Email Lead"}>Email Lead</option>
                  <option value={"Online Lead"}>Online Lead</option>
                  <option value={"Referral"}>Referral</option>
                  <option value={"Other"}>Other</option>
                </Select>
              </FormControl>
              <FormControl variant="outlined" style={{ width: "50%" }}>
                <InputLabel>How They Heard of Us?</InputLabel>
                <Select
                  native
                  value={eventStore.editClient.heardofus}
                  onChange={e =>
                    eventStore.updateClientDetails(e.target.value, "heardofus")
                  }
                  inputProps={{
                    name: "age",
                    id: "outlined-age-native-simple"
                  }}
                >
                  <option value="" />
                  <option value={"Google"}>Google</option>
                  <option value={"Instagram"}>Instagram</option>
                  <option value={"Facebook"}>Facebook</option>
                  <option value={"Google Maps"}>Google Maps</option>
                  <option value={"Returning Customer"}>
                    Returning Customer
                  </option>
                  <option value={"Referrals"}>Referrals</option>
                  <option value={"Other"}>Other</option>
                  <option value={"Not Applicable"}>Not Applicable</option>
                </Select>
              </FormControl>
              <br />
              <br />
            </form>
            <b>Client Remarks:</b>
            <TextArea
              style={{ width: "100%" }}
              placeholder="Add client remarks here"
              autoSize={{ minRows: 2, maxRows: 10 }}
              value={eventStore.editClient.remarks}
              onChange={e =>
                eventStore.updateClientDetails(e.target.value, "remarks")
              }
            />

            <br />
            <br />
            {settingStore.edit && (
              <Button
                block
                variant={
                  eventStore.editClient.title === "" ||
                  eventStore.editClient.leadtype === ""
                    ? "dark"
                    : "success"
                }
                disabled={
                  eventStore.editClient.title === "" ||
                  eventStore.editClient.leadtype === ""
                }
                onClick={() => {
                  eventStore.updateClient();
                }}
              >
                Update {eventStore.editClient.title}
              </Button>
            )}
            <br />
            <br />
          </Container>
        </Paper>
      </div>
    );
  }
}

export default observer(EditClientPage);
