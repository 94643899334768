import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "react-bootstrap";
import Paper from "@material-ui/core/Paper";
import settingStore from "../Stores/SettingStore";
import { observer } from "mobx-react";
import { Row } from "react-bootstrap";
import { Tabs } from "antd";

// import Select from "@material-ui/core/Select";
import moment from "moment";

import UserLastSignIn from "./UserLastSignIn";
import userStore from "../Stores/UserStore";
import OfficeJobsDistribution from "./OfficeJobsDistribution";
import JobsForecast from "./JobsForecast";
import YearEndTarget from "./YearEndTarget";
import LeadsGraph from "./LeadsGraph";
import FeedbackAndCancellations from "./FeedbackAndCancellations";
import PaymentsList from "./PaymentsList";
import PendingClients from "./PendingClients";
import AttendanceSummary from "./AttendanceSummary";
import GoogleAnalyticsUpdate from "./GoogleAnalyticsUpdate";
import PerformanceCalendar from "../Calendar/PerformanceCalendar";
import Pipeline from "./Pipeline";
import ProposalPipeline from "./ProposalPipeline";
import StoragePipeline from "./StoragePipeline";

const { TabPane } = Tabs;

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

function ManagmentTabs() {
  const classes = useStyles();

  let startDate = moment(settingStore.calstartdate);
  let endDate = moment(settingStore.calenddate);

  let users = [
    { user: "Reyna", group: "Local Team" },
    { user: "Yobel", group: "Local Team" },
    { user: "Aasim", group: "Local Team" },
    { user: "Katrina", group: "IM Team" },
    { user: "Vinith", group: "IM Team" },
    { user: "Ali", group: "Management" },
    { user: "Hamad", group: "Management" },
    { user: "Rajan", group: "Management" },
  ];

  let usersignincards = users.map((user, index) => (
    <UserLastSignIn user={user} key={index} />
  ));

  return (
    <div className={classes.root}>
      <Paper elevation={5}>
        <h1>
          Management Dashboard{" "}
          {moment(settingStore.startdate).format("MMMM YYYY")}
        </h1>
        <hr />
        <Row>{usersignincards}</Row>
        <hr />
        <Button
          variant="dark"
          onClick={(e) => {
            let oldDate = moment(startDate);
            oldDate = oldDate.subtract(1, "d");
            settingStore.changeCalDate(oldDate);
          }}
        >
          Last Month (
          {moment(moment(startDate).subtract(1, "days")).format("MMM-YY")})
        </Button>
        {moment(startDate).format("MMM-YY") !== moment().format("MMM-YY") && (
          <Button
            variant="dark"
            onClick={(e) => {
              let oldDate = moment(endDate);
              oldDate = oldDate.add(1, "d");
              settingStore.changeCalDate(oldDate);
            }}
          >
            Next Month (
            {moment(moment(endDate).add(1, "days")).format("MMM-YY")})
          </Button>
        )}
        <hr />
        <Tabs tabPosition={userStore.profile.editstyle}>
          <TabPane tab="Users" key="1">
            <OfficeJobsDistribution />
          </TabPane>
          <TabPane tab="Targets" key="2">
            <YearEndTarget
              start={moment(endDate).subtract(2, "months").startOf("month")}
              target={7750}
            />
            <YearEndTarget
              start={moment(endDate).subtract(1, "months").startOf("month")}
              target={7750}
            />
            <YearEndTarget
              start={moment(endDate).subtract(0, "months").startOf("month")}
              target={7750}
            />
            <YearEndTarget
              start={moment(endDate)
                .add(1, "day")
                .subtract(0, "months")
                .startOf("month")}
              target={7750}
            />
            <JobsForecast />
          </TabPane>
          <TabPane tab="Pipeline" key="3">
            <Pipeline />
          </TabPane>
          <TabPane tab="Proposal Pipeline" key="4">
            <ProposalPipeline />
          </TabPane>
          <TabPane tab="Storage Pipeline" key="5">
            <StoragePipeline />
          </TabPane>
          <TabPane tab="Pending Clients" key="6">
            <PendingClients />
          </TabPane>
          <TabPane tab="Daily Leads" key="7">
            <LeadsGraph />
          </TabPane>
          <TabPane tab="Feedback" key="8">
            <FeedbackAndCancellations />
          </TabPane>
          <TabPane tab="Attendance" key="9">
            <AttendanceSummary />
          </TabPane>
          <TabPane tab="Payments" key="10">
            <PaymentsList />
          </TabPane>
          <TabPane tab="Website Statistics" key="11">
            <GoogleAnalyticsUpdate />
          </TabPane>
          <TabPane tab="Daily Performance" key="12">
            <PerformanceCalendar />
          </TabPane>
        </Tabs>
      </Paper>
    </div>
  );
}
export default observer(ManagmentTabs);
