import React, { Component } from "react";
import { PDFViewer } from "@react-pdf/renderer";
import { observer } from "mobx-react";

import proposalHeader from "../../ProposalPics/easymoveheader.png";

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
import moment from "moment";
import editJobStore from "../../Stores/EditJobStore";

Font.register(
  "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
  { family: "Oswald" }
);

const styles = StyleSheet.create({
  body: {
    // padding: 60,
    paddingTop: 0,
    paddingBottom: 5,
    paddingHorizontal: 35,
  },
  unbreakable: { width: "100%", height: 400 },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Oswald",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: "Oswald",
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  text2: {
    fontSize: 10,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  text3: {
    fontSize: 12,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  text4: {
    fontSize: 8,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  header2: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
    fontWeight: "italic",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  headerTite: {
    fontSize: 14,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: { margin: "auto", flexDirection: "row" },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: { margin: "auto", marginTop: 5, fontSize: 10 },
  tableHeaderStyle: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 14,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    fontWeight: "bold",
    paddingTop: 6,
  },
  tableRow1b: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "20%",
  },
  tableRow1c: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "40%",
  },
  tableRow2a: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "80%",
  },
  tableRow2b: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontWeight: "bold",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "20%",
  },
  tableRow2c: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontWeight: "bold",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "40%",
  },
  tableRow2d: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "grey",
    color: "white",
    fontWeight: "bold",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "100%",
  },
  tableRow2e: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "70%",
  },
  tableRow2f: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontWeight: "bold",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "30%",
  },
  tableRow3a: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 10,
    fontWeight: "bold",
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "20%",
  },
  tableRow3b: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 10,
    fontWeight: "bold",
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "10%",
  },
  tableRow4a: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 10,
    fontWeight: "bold",
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "10%",
  },
  tableRow4b: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 10,
    fontWeight: "bold",
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "45%",
  },
  tableRow5a: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 10,
    fontWeight: "bold",
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "10%",
  },
  tableRow5b: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 10,
    fontWeight: "bold",
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "45%",
  },
  subtitle2: {
    fontSize: 14,
    fontFamily: "Oswald",
  },
  subtitle3: {
    fontSize: 12,
    margin: 12,
    fontFamily: "Oswald",
  },
  tableRow6a: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 4,
    width: "4%",
  },
  tableRow6b: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 4,
    width: "38%",
  },
  tableRow6c: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 4,
    width: "14%",
  },
  tableRow6c2: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 4,
    width: "30%",
  },
  tableRow6d: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontWeight: "bold",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "4%",
  },
  tableRow6e: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontWeight: "bold",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "38%",
  },
  tableRow6f: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontWeight: "bold",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "14%",
  },
  tableRow6g: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontWeight: "bold",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "72%",
  },
  tableRow6h: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontWeight: "bold",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "14%",
  },
  tableRow6i: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontWeight: "bold",
    fontSize: 10,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "30%",
  },
});

class OnlyCBMPacking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showproposal: false,
      packinglist: true,
      storage: true,
      showdiscount: false,
    };
  }

  render() {
    let itemslisttable;
    let roomlist = editJobStore.rooms;
    let itemslist = [];
    let singleitem = {};
    for (let i = 0; i < roomlist.length; i++) {
      for (let j = 0; j < roomlist[i].item.length; j++) {
        if (
          roomlist[i].item[j].length === 0 ||
          roomlist[i].item[j].width === 0 ||
          roomlist[i].item[j].length === 0
        ) {
          roomlist[i].dimensions = "";
        } else {
          roomlist[i].item[j].dimensions = `${Math.round(
            roomlist[i].item[j].length
          )} x ${Math.round(roomlist[i].item[j].width)} x ${Math.round(
            roomlist[i].item[j].height
          )}`;
        }

        singleitem = {
          room: roomlist[i].title,
          item: roomlist[i].item[j].title,
          length: roomlist[i].item[j].length,
          width: roomlist[i].item[j].width,
          height: roomlist[i].item[j].height,
          quantity: roomlist[i].item[j].quantity,
          dimensions: roomlist[i].item[j].dimensions,
          cbm:
            Math.round(
              10 *
                parseFloat(roomlist[i].item[j].quantity) *
                parseFloat(roomlist[i].item[j].cbm)
            ) / 10,
        };
        itemslist.push(singleitem);
      }
    }

    let itemslistrows = itemslist.map((item, index) => (
      <View style={{ flexDirection: "row" }} key={index}>
        <Text style={styles.tableRow6a}>{index + 1}</Text>
        <Text style={styles.tableRow6b}>{item.item}</Text>
        <Text style={styles.tableRow6c2}>{item.dimensions}</Text>
        <Text style={styles.tableRow6c}>{item.quantity}</Text>
        <Text style={styles.tableRow6c}>{item.cbm}</Text>
      </View>
    ));
    itemslisttable = (
      <View>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.tableRow6d}>#</Text>
          <Text style={styles.tableRow6e}>Item</Text>
          <Text style={styles.tableRow6i}>Dimensions</Text>
          <Text style={styles.tableRow6f}>Qty.</Text>
          <Text style={styles.tableRow6f}>CBM</Text>
        </View>
        {itemslistrows}
      </View>
    );

    let packinglist = (
      <View>
        <Text style={styles.header}>Items List</Text>

        {itemslisttable}
      </View>
    );

    let itemcount = 0;
    let itemcbm = 0;

    let addresslist = (
      <View style={{ flexDirection: "row" }}>
        <Text style={styles.tableRow4a}>#</Text>
        <Text style={styles.tableRow4b}>From Address:</Text>
        <Text style={styles.tableRow4b}>To Address:</Text>
      </View>
    );
    let addressdetails;
    if (editJobStore.addresses.length > 0) {
      addressdetails = editJobStore.addresses.map((address, index) => (
        <View style={{ flexDirection: "row" }} key={index}>
          <Text style={styles.tableRow5a}>{index + 1}</Text>
          <Text style={styles.tableRow5b}>{address.locationfrom}</Text>
          <Text style={styles.tableRow5b}>{address.locationto}</Text>
        </View>
      ));
    }

    for (let i = 0; i < editJobStore.rooms.length; i++) {
      for (let j = 0; j < editJobStore.rooms[i].item.length; j++) {
        itemcount =
          itemcount + parseFloat(editJobStore.rooms[i].item[j].quantity);
        itemcbm =
          itemcbm +
          parseFloat(editJobStore.rooms[i].item[j].cbm) *
            parseFloat(editJobStore.rooms[i].item[j].quantity);
      }
    }

    let dates = "";
    if (editJobStore.jobdates.length > 0) {
      for (let i = 0; i < editJobStore.jobdates.length; i++) {
        dates =
          dates +
          moment(editJobStore.jobdates[i].jobdate).format("DD-MMM-YY") +
          ", ";
      }
    }
    let datesFinal = (
      <View style={{ flexDirection: "row" }}>
        <Text style={styles.tableRow1b}>
          {editJobStore.jobdates.length} Job Days
        </Text>
        <Text style={styles.tableRow2a}>{dates}</Text>
      </View>
    );

    let fullproposal = (
      <PDFViewer width={"100%"} height={600}>
        <Document>
          <Page size="A4" style={styles.body}>
            <Image src={proposalHeader} />
            <Text style={styles.header}>
              {editJobStore.job.jobmain} Items List on{" "}
              {moment().format("DD-MMM-YYYY")}{" "}
            </Text>
            <View style={{ flexDirection: "row" }}>
              <Text style={styles.tableRow1b}>Client Information</Text>
              <Text style={styles.tableRow2a}>
                {editJobStore.client.title} / {editJobStore.client.phone1} /{" "}
                {editJobStore.client.phone2} / {editJobStore.client.email}{" "}
              </Text>
            </View>
            {editJobStore.addresses.length > 0 && (
              <Text style={{ lineHeight: 0.5 }}> </Text>
            )}
            {editJobStore.addresses.length > 0 && addresslist}
            {editJobStore.addresses.length > 0 && addressdetails}
            {editJobStore.jobdates.length > 0 && (
              <Text style={{ lineHeight: 0.5 }}> </Text>
            )}
            {editJobStore.jobdates.length > 0 && datesFinal}
            <Text style={{ lineHeight: 0.5 }}> </Text>
            {packinglist}
            <View>
              <Text style={{ lineHeight: 0.5 }}> </Text>
            </View>
            <View style={{ flexDirection: "row" }}>
              <Text style={styles.tableRow6g}>Total Items</Text>
              <Text style={styles.tableRow6h}>{itemcount} items</Text>
              <Text style={styles.tableRow6h}>
                {Math.round(10 * itemcbm) / 10} CBM
              </Text>
            </View>
          </Page>
        </Document>
      </PDFViewer>
    );

    return <div>{fullproposal}</div>;
  }
}

export default observer(OnlyCBMPacking);
