import React, { Component } from "react";
import { observer } from "mobx-react";
import { Button, Row, Col } from "react-bootstrap";

import CustomInvoice from "./CustomInvoice";
import InvoiceExport from "./InvoiceExport";

class InvoicePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      custom: false,
    };
  }

  render() {
    return (
      <div>
        <br />
        <Row>
          <Col>
            <Button
              block
              variant={this.state.custom ? "outline-dark" : "dark"}
              onClick={() => this.setState({ custom: false })}
            >
              Full Job Invoice
            </Button>
          </Col>
          <Col>
            <Button
              block
              variant={this.state.custom ? "dark" : "outline-dark"}
              onClick={() => this.setState({ custom: true })}
            >
              Custom Invoice
            </Button>
          </Col>
        </Row>
        <hr />
        {this.state.custom ? <CustomInvoice /> : <InvoiceExport />}
      </div>
    );
  }
}

export default observer(InvoicePage);
