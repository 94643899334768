import React from "react";
// First way to import
import { observer } from "mobx-react";
import { Container } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";

import jobEditStore from "../Stores/JobEditStore";

class NewJobModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let secondjob;
    if (
      jobEditStore.job.jobmain === "Local Move" ||
      jobEditStore.job.jobmain === "Corporate Move"
    ) {
      secondjob = (
        <Select
          native
          label="jobsecond"
          value={jobEditStore.job.jobsecond}
          onChange={e =>
            jobEditStore.updateJobDetails(e.target.value, "jobsecond")
          }
        >
          <option value="" />
          <option value={"Full Service"}>Full Service</option>
          <option value={"Regular Service"}>Regular Service</option>
        </Select>
      );
    } else if (
      jobEditStore.job.jobmain === "IM - Inbound" ||
      jobEditStore.job.jobmain === "CIM - Inbound"
    ) {
      secondjob = (
        <Select
          native
          label="jobsecond"
          value={jobEditStore.job.jobsecond}
          onChange={e =>
            jobEditStore.updateJobDetails(e.target.value, "jobsecond")
          }
        >
          <option value="" />
          <option value={"Door to Door"}>Door to Door</option>
          <option value={"Port to Port"}>Port to Port</option>
          <option value={"Port to Door"}>Port to Door</option>
          <option value={"Door to Port"}>Door to Port</option>
        </Select>
      );
    } else if (
      jobEditStore.job.jobmain === "IM - Outbound" ||
      jobEditStore.job.jobmain === "CIM - Outbound"
    ) {
      secondjob = (
        <Select
          native
          label="jobsecond"
          value={jobEditStore.job.jobsecond}
          onChange={e =>
            jobEditStore.updateJobDetails(e.target.value, "jobsecond")
          }
        >
          <option value="" />
          <option value={"Door to Door"}>Door to Door</option>
          <option value={"Port to Port"}>Port to Port</option>
          <option value={"Port to Door"}>Port to Door</option>
          <option value={"Door to Port"}>Door to Port</option>
        </Select>
      );
    } else {
      secondjob = (
        <Select
          native
          label="jobsecond"
          value={jobEditStore.job.jobsecond}
          onChange={e =>
            jobEditStore.updateJobDetails(e.target.value, "jobsecond")
          }
        >
          <option value="" />
          <option value={"Only Storage"}>Only Storage</option>
          <option value={"Only Clearance"}>Only Clearance</option>
        </Select>
      );
    }

    return (
      <div>
        <Container style={{ maxWidth: "98%" }}>
          <form>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12} sm={6} md={6}>
                <FormControl
                  variant="outlined"
                  style={{ width: "100%" }}
                  size="small"
                >
                  <InputLabel>Main Job</InputLabel>
                  <Select
                    native
                    label="Age"
                    value={jobEditStore.job.jobmain}
                    onChange={e =>
                      jobEditStore.updateJobDetails(e.target.value, "jobmain")
                    }
                  >
                    <option value="" />
                    <option value={"Local Move"}>Local Move</option>
                    <option value={"Corporate Move"}>Corporate Move</option>
                    <option value={"IM - Inbound"}>IM - Inbound</option>
                    <option value={"IM - Outbound"}>IM - Outbound</option>
                    <option value={"CIM - Inbound"}>CIM - Inbound</option>
                    <option value={"CIM - Outbound"}>CIM - Outbound</option>
                    <option value={"Only Storage"}>Only Storage</option>
                    <option value={"Only Clearance"}>Only Clearance</option>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl
                  variant="outlined"
                  style={{ width: "100%" }}
                  size="small"
                >
                  <InputLabel>Second Job</InputLabel>
                  {secondjob}
                </FormControl>
              </Grid>
            </Grid>
            <hr />
            <Grid container>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="companygroup"
                  label="Company Group"
                  name="companygroup"
                  autoComplete="companygroup"
                  autoFocus
                  type="text"
                  style={{ color: "red" }}
                  value={jobEditStore.job.companygroup}
                  onChange={e =>
                    jobEditStore.updateJobDetails(
                      e.target.value,
                      "companygroup"
                    )
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="cbm"
                  label="Estimated CBM"
                  name="cbm"
                  autoComplete="cbm"
                  autoFocus
                  type="number"
                  value={jobEditStore.job.cbm}
                  onChange={e =>
                    jobEditStore.updateJobDetails(e.target.value, "cbm")
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="weight"
                  label="Estimated Weight - Kg"
                  name="weight"
                  autoComplete="weight"
                  autoFocus
                  type="number"
                  value={jobEditStore.job.weight}
                  onChange={e =>
                    jobEditStore.updateJobDetails(e.target.value, "weight")
                  }
                />
              </Grid>
            </Grid>
            <hr />
            <Grid container>
              <Grid item xs={12} sm={6} md={6}>
                <b>Status</b>
                <FormControl
                  variant="outlined"
                  style={{ width: "100%" }}
                  size="small"
                >
                  <Select
                    native
                    label="Age"
                    value={jobEditStore.job.status}
                    onChange={e =>
                      jobEditStore.updateJobDetails(e.target.value, "status")
                    }
                    inputProps={{
                      name: "age",
                      id: "outlined-age-native-simple"
                    }}
                  >
                    <option value="" />
                    <option value={"Lead"}>Lead</option>
                    <option value={"Proposal"}>Proposal</option>
                    <option value={"Booked"}>Booked</option>
                    <option value={"Converted"}>Converted</option>
                    <option value={"Cancelled"}>Cancelled</option>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <b>Estimation Type</b>
                <FormControl
                  variant="outlined"
                  style={{ width: "100%" }}
                  size="small"
                >
                  <Select
                    native
                    label="Age"
                    value={jobEditStore.job.estimationType}
                    onChange={e =>
                      jobEditStore.updateJobDetails(
                        e.target.value,
                        "estimationType"
                      )
                    }
                    inputProps={{
                      name: "age",
                      id: "outlined-age-native-simple"
                    }}
                  >
                    <option value="" />
                    <option value={"Scheduled"}>Scheduled</option>
                    <option value={"On Site"}>On Site</option>
                    <option value={"By Phone"}>By Phone</option>
                    <option value={"Online"}>Online</option>
                    <option value={"No Estimation"}>No Estimation</option>
                    <option value={"Other"}>Other</option>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </form>
          <br />
        </Container>
      </div>
    );
  }
}

export default observer(NewJobModal);
