import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import JobDetails from "./JobDetails";
import { Button } from "react-bootstrap";
import Paper from "@material-ui/core/Paper";
import settingStore from "../Stores/SettingStore";
import { observer } from "mobx-react";
import { Input } from "antd";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
// import Select from "@material-ui/core/Select";
import moment from "moment";
import { DatePicker, Select } from "antd";
import { Steps } from "antd";

import jobEditStore from "../Stores/JobEditStore";
import eventStore from "../Stores/EventStore";
import AddressRow from "./AddressRow";
import JobSummary from "./JobSummary";
import JobDatePage from "./JobDatePage";
import StoragePage from "./StoragePage";
import PaymentPage from "./PaymentPage";
import PackingPage from "./PackingPage";
import IMQuotePage from "./IMQuotePage";
import IMTrackPage from "./IMTrackPage";
import EditClient from "../ClientEdit/EditClient";
import ProposalExport from "../ProposalPics/ProposalExport";
import CustomProposal from "../ProposalPics/CustomProposal";

const { Option } = Select;

const { Step } = Steps;
const { TextArea } = Input;
const onChange = (date, dateString) => {
  jobEditStore.updateJobDetails(date, "estimationdate");
};
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
}));

function JobTabsPage() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  let addressrows;

  if (jobEditStore.addresses.length > 1) {
    addressrows = jobEditStore.addresses.map((address, index) => (
      <AddressRow addressindex={index} key={index + 1} />
    ));
  } else if (jobEditStore.addresses.length === 1) {
    addressrows = <AddressRow addressindex={0} />;
  }

  let currentStatus = 0;
  if (jobEditStore.job.status === "Proposal") {
    currentStatus = 1;
  } else if (jobEditStore.job.status === "Booked") {
    currentStatus = 2;
  } else if (jobEditStore.job.status === "Converted") {
    currentStatus = 3;
  } else if (jobEditStore.job.status === "Cancelled") {
    currentStatus = 4;
  }

  let feedbackcells = (
    <div>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={12} sm={3} md={3}>
          <FormControl
            variant="outlined"
            style={{ width: "100%" }}
            size="small"
          >
            <b>Rating:</b>
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder="Customer Rating"
              optionFilterProp="children"
              value={jobEditStore.job.rating}
              onChange={(e) => jobEditStore.updateJobDetails(e, "rating")}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={1}>Bad</Option>
              <Option value={3}>Neutral</Option>
              <Option value={5}>Good</Option>
              <Option value={0}>Not Reachable</Option>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={9} md={9}>
          <FormControl
            variant="outlined"
            style={{ width: "100%" }}
            size="small"
          >
            <b>Customer Feedback</b>
            <TextArea
              placeholder="Customer Feedback"
              autoSize
              value={jobEditStore.job.feedback}
              onChange={(e) =>
                jobEditStore.updateJobDetails(e.target.value, "feedback")
              }
            />
          </FormControl>
        </Grid>
      </Grid>
      <hr />
    </div>
  );

  let cancelfeedbackcells = (
    <div>
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={12} sm={3} md={3}>
          <FormControl variant="outlined" style={{ width: "100%" }}>
            <b>Cancellation Reason:</b>
            <Select
              showSearch
              style={{ width: 200 }}
              placeholder="Customer Rating"
              optionFilterProp="children"
              value={jobEditStore.job.cancellationreason}
              onChange={(e) =>
                jobEditStore.updateJobDetails(e, "cancellationreason")
              }
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="" />
              <Option value={"Price Too High"}>Price Too High</Option>
              <Option value={"Client Unreachable"}>Client Unreachable</Option>
              <Option value={"Date NA"}>Date NA</Option>
              <Option value={"Not Interested"}>Not Interested</Option>
              <Option value={"Service Not Available"}>
                Service Not Available
              </Option>
              <Option value={"Too Long to Price"}>Too Long to Price</Option>
              <Option value={"Other"}>Other</Option>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={9} md={9}>
          <FormControl variant="outlined" style={{ width: "100%" }}>
            <b>Cancellation Remarks:</b>
            <TextArea
              placeholder="Cancellation Remarks:"
              autoSize
              value={jobEditStore.job.cancellationremarks}
              onChange={(e) =>
                jobEditStore.updateJobDetails(
                  e.target.value,
                  "cancellationremarks"
                )
              }
            />
          </FormControl>
        </Grid>
      </Grid>
      <hr />
    </div>
  );

  let jobPricing = `${Math.round(1000 * jobEditStore.job.lastprice) / 1000} KD`;
  if (jobEditStore.addchargeprice > 0) {
    jobPricing += ` + ${jobEditStore.addchargeprice} KD Additional`;
  }
  if (jobEditStore.totalstorageprice > 0) {
    jobPricing += ` + ${jobEditStore.totalstorageprice} KD Storage`;
  }

  if (jobEditStore.addchargeprice > 0 || jobEditStore.totalstorageprice > 0) {
    jobPricing += ` = ${jobEditStore.totaljobprice} KD Total`;
  }

  return (
    <div className={classes.root}>
      <Grid container justify="space-between">
        <Button
          variant="warning"
          onClick={() => settingStore.changeEditJob(false)}
        >
          <i className="fas fa-chevron-left"></i> Go Back / Cancel Changes
        </Button>
        <Button
          variant="success"
          onClick={() => jobEditStore.postUpdate()}
          disabled={!jobEditStore.checkForUpdate}
        >
          Save Job Details
        </Button>
      </Grid>

      <hr />
      <br />

      <Grid container spacing={1}>
        <Grid item xs={12} sm={3} md={3}>
          <b>Status</b>
          <FormControl
            variant="outlined"
            style={{ width: "100%" }}
            size="small"
          >
            <Select
              showSearch
              placeholder="Status"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              value={jobEditStore.job.status}
              onChange={(e) => jobEditStore.updateJobDetails(e, "status")}
            >
              <Option value="" />
              <Option value={"Lead"}>Lead</Option>
              <Option value={"Proposal"}>Proposal</Option>
              <Option value={"Booked"}>Booked</Option>
              <Option value={"Converted"}>Converted</Option>
              <Option value={"Cancelled"}>Cancelled</Option>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3} md={3}>
          <FormControl variant="outlined" style={{ width: "100%" }}>
            <b>Estimation Date</b>
            <DatePicker
              onChange={onChange}
              format={"DD-MMM-YY"}
              size="medium"
              defaultValue={
                (jobEditStore.job.estimationdate !== null) &
                  (jobEditStore.job.estimationdate !== "") &&
                moment(jobEditStore.job.estimationdate)
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3} md={3}>
          <b>Estimation Type</b>
          <FormControl
            variant="outlined"
            style={{ width: "100%" }}
            size="small"
          >
            <Select
              showSearch
              placeholder="Estimation Type"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              value={jobEditStore.job.estimationType}
              onChange={(e) =>
                jobEditStore.updateJobDetails(e, "estimationType")
              }
            >
              <Option value="" />
              <Option value={"Scheduled"}>Scheduled</Option>
              <Option value={"On Site"}>On Site</Option>
              <Option value={"By Phone"}>By Phone</Option>
              <Option value={"Online"}>Online</Option>
              <Option value={"No Estimation"}>No Estimation</Option>
              <Option value={"Other"}>Other</Option>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={2} md={2}>
          <b>Focal Point</b>
          <FormControl
            variant="outlined"
            style={{ width: "100%" }}
            size="small"
          >
            <Select
              showSearch
              placeholder="Focal Point"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              value={jobEditStore.job.emfocalpoint}
              onChange={(e) => jobEditStore.updateJobDetails(e, "emfocalpoint")}
            >
              <Option value="" />
              <Option value={"Reyna"}>Reyna</Option>
              <Option value={"Yobel"}>Yobel</Option>
              <Option value={"Aasim"}>Aasim</Option>
              <Option value={"Katrina"}>Katrina</Option>
              <Option value={"Vinith"}>Vinith</Option>
              <Option value={"Other"}>Other</Option>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={1} md={1}>
          <b>Urgency</b>
          <FormControl
            variant="outlined"
            style={{ width: "100%" }}
            size="small"
          >
            <Select
              showSearch
              placeholder="Urgency"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              value={jobEditStore.job.followupurgency}
              onChange={(e) =>
                jobEditStore.updateJobDetails(e, "followupurgency")
              }
            >
              <Option value="" />
              <Option value={"Low"}>Low</Option>
              <Option value={"Medium"}>Medium</Option>
              <Option value={"High"}>High</Option>
              <Option value={"Urgent"}>Urgent</Option>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <hr />
      <h4>
        {" "}
        <b>Client- {eventStore.editClient.id}:</b> {eventStore.editClient.title}{" "}
        - <b>Job {jobEditStore.job.id}:</b> {jobEditStore.job.jobmain} -{" "}
        {jobEditStore.job.jobsecond}
        {" - "}
        <i>({jobEditStore.fullQualityScore}% Quality)</i>
      </h4>
      <h6>{jobPricing}</h6>
      <p>Quality Issues: {jobEditStore.fullQualityRemark}</p>
      <Paper elevation={5}>
        <AppBar position="static" color="default">
          <Tabs
            value={value}
            onChange={handleChange}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="scrollable auto tabs example"
          >
            <Tab
              label="Job Summary"
              {...a11yProps(0)}
              disabled={settingStore.editJobItem}
            />
            <Tab
              label={
                jobEditStore.clientQuality < 100
                  ? `Client Details (${jobEditStore.clientQuality}%)`
                  : "Client Details"
              }
              {...a11yProps(1)}
              disabled={settingStore.editJobItem}
            />
            <Tab
              label={
                jobEditStore.jobdetailQuality < 100
                  ? `Job Details (${jobEditStore.jobdetailQuality}%)`
                  : "Job Details"
              }
              {...a11yProps(2)}
              disabled={settingStore.editJobItem}
            />
            <Tab
              label={
                jobEditStore.addressQuality < 100
                  ? `Address (${jobEditStore.addressQuality}%)`
                  : "Address"
              }
              {...a11yProps(3)}
              disabled={settingStore.editJobItem}
            />
            <Tab
              label={
                jobEditStore.datesQuality < 100
                  ? `Dates (${jobEditStore.datesQuality}%)`
                  : "Dates"
              }
              {...a11yProps(4)}
              disabled={settingStore.editJobItem}
            />
            <Tab
              label="Storage"
              {...a11yProps(5)}
              disabled={settingStore.editJobItem}
            />
            <Tab
              label={
                jobEditStore.imQuality < 100
                  ? `IM (${jobEditStore.imQuality}%)`
                  : "IM"
              }
              {...a11yProps(6)}
              disabled={settingStore.editJobItem}
            />
            <Tab
              label={`Proposal`}
              {...a11yProps(7)}
              disabled={settingStore.editJobItem}
            />
            <Tab
              label={
                jobEditStore.paymentQuality < 100
                  ? `Payments (${jobEditStore.paymentQuality}%)`
                  : "Payments"
              }
              {...a11yProps(8)}
              disabled={settingStore.editJobItem}
            />
            <Tab
              label={
                jobEditStore.packinglistQuality < 100
                  ? `Packing List (${jobEditStore.packinglistQuality}%)`
                  : "Packing List"
              }
              {...a11yProps(9)}
              disabled={settingStore.editJobItem}
            />

            <Tab
              label={
                jobEditStore.imtrackQuality < 100
                  ? `Tracking (${jobEditStore.imtrackQuality}%)`
                  : "Tracking"
              }
              {...a11yProps(10)}
              disabled={settingStore.editJobItem}
            />
            <Tab label="Custom Proposal" {...a11yProps(11)} />
          </Tabs>
        </AppBar>
        <TabPanel value={value} index={0}>
          <JobSummary />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <EditClient />
        </TabPanel>
        <TabPanel value={value} index={2}>
          <JobDetails />
        </TabPanel>
        <TabPanel value={value} index={3}>
          <h6>Quality Score = {jobEditStore.jobdetailQuality}%</h6>
          {addressrows}
          <Button
            size="sm"
            block
            variant="outline-success"
            onClick={() => jobEditStore.addAddress()}
          >
            Add Address
          </Button>
        </TabPanel>
        <TabPanel value={value} index={4}>
          <JobDatePage />
        </TabPanel>
        <TabPanel value={value} index={5}>
          <StoragePage />
        </TabPanel>

        <TabPanel value={value} index={6}>
          <IMQuotePage />
        </TabPanel>
        <TabPanel value={value} index={7}>
          <ProposalExport />
        </TabPanel>
        <TabPanel value={value} index={8}>
          <PaymentPage />
        </TabPanel>
        <TabPanel value={value} index={9}>
          <PackingPage />
        </TabPanel>
        <TabPanel value={value} index={10}>
          <IMTrackPage />
        </TabPanel>
        <TabPanel value={value} index={11}>
          <CustomProposal />
        </TabPanel>
      </Paper>
      <br />
      {jobEditStore.job.status === "Converted" && feedbackcells}
      {jobEditStore.job.status === "Cancelled" && cancelfeedbackcells}
      <b>Job Remarks:</b>
      <TextArea
        style={{ width: "100%" }}
        placeholder="Add client remarks here"
        autoSize={{ minRows: 2, maxRows: 10 }}
        value={jobEditStore.job.remarks}
        onChange={(e) =>
          jobEditStore.updateJobDetails(e.target.value, "remarks")
        }
      />
      <br />
      <br />
      <Steps size="small" current={currentStatus}>
        <Step
          title="Lead"
          description={moment(jobEditStore.job.leaddate).format("DD-MMM-YY")}
        />
        <Step
          title="Proposal"
          description={moment(jobEditStore.job.proposaldate).format(
            "DD-MMM-YY"
          )}
        />
        <Step
          title="Booked"
          description={moment(jobEditStore.job.bookeddate).format("DD-MMM-YY")}
        />
        <Step
          title="Converted"
          description={moment(jobEditStore.job.converteddate).format(
            "DD-MMM-YY"
          )}
        />
      </Steps>
      <br />
      <br />
    </div>
  );
}
export default observer(JobTabsPage);
