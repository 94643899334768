import React from "react";
// First way to import
import { observer } from "mobx-react";
import { Button, Container } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import { Select } from "antd";
import { Input } from "antd";
import Grid from "@material-ui/core/Grid";
import intSupStore from "../Stores/IntSupStore";

import "react-datepicker/dist/react-datepicker.css";

import countryList from "../Stores/Static/countries";

const { TextArea } = Input;
const { Option } = Select;

class SupplierForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
    };
  }

  render() {
    let countryListOption;

    countryListOption = countryList.map((country, index) => (
      <Option key={index} value={country}>
        {country}
      </Option>
    ));

    return (
      <div>
        <Container style={{ maxWidth: "98%" }}>
          <Grid container justify="space-between">
            <Button
              variant="warning"
              onClick={() => intSupStore.cancelChanges()}
            >
              <i className="fas fa-chevron-left"></i> Go Back / Cancel Changes
            </Button>
            <Button
              variant="success"
              onClick={() => intSupStore.updateIntSupplier()}
            >
              Save Suppplier Details
            </Button>
          </Grid>
          <br />
          <form>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Company Name"
                  name="company"
                  autoComplete="company"
                  autoFocus
                  type="text"
                  value={intSupStore.editIntSupplier.company}
                  onChange={(e) =>
                    intSupStore.updateIntSupplierDetails(
                      e.target.value,
                      "company"
                    )
                  }
                />
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <FormControl
                  variant="outlined"
                  style={{ width: "100%" }}
                  size="small"
                >
                  <b>Country:</b>
                  <Select
                    showSearch
                    placeholder="Country"
                    optionFilterProp="children"
                    value={intSupStore.editIntSupplier.country}
                    onChange={(e) => {
                      intSupStore.updateIntSupplierDetails(e, "country");
                    }}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {countryListOption}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={3} md={3}>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="City/State"
                  name="citystate"
                  autoComplete="citystate"
                  autoFocus
                  type="text"
                  value={intSupStore.editIntSupplier.citystate}
                  onChange={(e) =>
                    intSupStore.updateIntSupplierDetails(
                      e.target.value,
                      "citystate"
                    )
                  }
                />
              </Grid>
            </Grid>

            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12} sm={3} md={3}>
                <FormControl
                  variant="outlined"
                  style={{ width: "100%" }}
                  size="small"
                >
                  <Select
                    label="Company Type"
                    showSearch
                    placeholder="Company Type"
                    optionFilterProp="children"
                    value={intSupStore.editIntSupplier.companytype}
                    onChange={(e) => {
                      intSupStore.updateIntSupplierDetails(e, "companytype");
                    }}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option value={"Freight Forwarder"}>
                      Freight Forwarder
                    </Option>
                    <Option value={"Sea Transportation"}>
                      Sea Transportation
                    </Option>
                    <Option value={"Air Transporation"}>
                      Air Transporation
                    </Option>
                    <Option value={"Land Transportation"}>
                      Land Transportation
                    </Option>
                    <Option value={"Customs Clearance"}>
                      Customs Clearance
                    </Option>
                    <Option value={"Other"}>Other</Option>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="Normal"
                  required
                  fullWidth
                  label="Contact Person Name"
                  name="contact"
                  autoComplete="contact"
                  autoFocus
                  type="text"
                  value={intSupStore.editIntSupplier.contact}
                  onChange={(e) =>
                    intSupStore.updateIntSupplierDetails(
                      e.target.value,
                      "contact"
                    )
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Phone Number"
                  name="phone"
                  autoComplete="phone"
                  autoFocus
                  type="text"
                  value={intSupStore.editIntSupplier.phone}
                  onChange={(e) =>
                    intSupStore.updateIntSupplierDetails(
                      e.target.value,
                      "phone"
                    )
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Email"
                  name="email"
                  autoComplete="email"
                  autoFocus
                  type="text"
                  value={intSupStore.editIntSupplier.email}
                  onChange={(e) =>
                    intSupStore.updateIntSupplierDetails(
                      e.target.value,
                      "email"
                    )
                  }
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12} sm={6} md={3}>
                <FormControl
                  variant="outlined"
                  style={{ width: "100%" }}
                  size="small"
                >
                  <b>Has Kuwait Office?</b>
                  <Select
                    showSearch
                    placeholder="Kuwait Office"
                    optionFilterProp="children"
                    value={intSupStore.editIntSupplier.kuwaitoffice}
                    onChange={(e) => {
                      intSupStore.updateIntSupplierDetails(e, "kuwaitoffice");
                    }}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option value={"yes"}>Yes</Option>
                    <Option value={"no"}>No</Option>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl
                  variant="outlined"
                  style={{ width: "100%" }}
                  size="small"
                >
                  <b>Rating:</b>
                  <Select
                    showSearch
                    placeholder="Customer Rating"
                    optionFilterProp="children"
                    value={intSupStore.editIntSupplier.rating}
                    onChange={(e) => {
                      intSupStore.updateIntSupplierDetails(e, "rating");
                    }}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option value={"expensive"}>Price Too High</Option>
                    <Option value={"time"}>Too Long to Reply</Option>
                    <Option value={"quality"}>Poor Quality</Option>
                    <Option value={"not interested"}>Not Interested</Option>
                    <Option value={"good"}>Good</Option>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl
                  variant="outlined"
                  style={{ width: "100%" }}
                  size="small"
                >
                  <b>Organization:</b>
                  <Select
                    showSearch
                    placeholder="Organization"
                    optionFilterProp="children"
                    value={intSupStore.editIntSupplier.organization}
                    onChange={(e) => {
                      intSupStore.updateIntSupplierDetails(e, "organization");
                    }}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option value={" "}> </Option>
                    <Option value={"AMSA"}>AMSA</Option>
                    <Option value={"ARA"}>ARA</Option>
                    <Option value={"IAM"}>IAM</Option>
                    <Option value={"FIATA"}>FIATA</Option>
                    <Option value={"FIDI"}>FIDI</Option>
                    <Option value={"Other"}>Other</Option>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl variant="outlined" style={{ width: "100%" }}>
                  <b>Remarks:</b>
                  <TextArea
                    placeholder="Cancellation Remarks:"
                    autoSize
                    value={intSupStore.editIntSupplier.remark}
                    onChange={(e) =>
                      intSupStore.updateIntSupplierDetails(
                        e.target.value,
                        "remark"
                      )
                    }
                  />
                </FormControl>
              </Grid>
            </Grid>
          </form>
        </Container>
      </div>
    );
  }
}

export default observer(SupplierForm);
