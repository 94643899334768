import React, { Component } from "react";
import { observer } from "mobx-react";
import Modal from "react-bootstrap/Modal";
import { CircleLoader } from "react-spinners";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Button, Row, Col, Container } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import Grid from "@material-ui/core/Grid";

import jobSelectionStore from "../Stores/JobSelectionStore";
import JobModal from "../FilterModules/JobModal";

class JobsTable extends Component {
  constructor(props) {
    super(props);
    this.state = { show: false };
    this.options = {
      defaultSortName: "id", // default sort column name
      // exportCSVBtn: this.createCustomExportCSVButton,
      defaultSortOrder: "asc", // default sort order
      onRowClick: function (event) {
        jobSelectionStore.fetchSingleJob(event.id);
        // jobSelectionStore.selectClient(event.id);
      },
    };
  }
  handleShow = () => {
    jobSelectionStore.addJob();
    this.setState({ show: true });
  };
  handleShow2 = () => {
    jobSelectionStore.closeJobList();
  };
  handleClose = () => jobSelectionStore.closeModal();
  handleClose2 = () => this.setState({ show: false });

  render() {
    if (jobSelectionStore.loading) {
      return (
        <CircleLoader
          sizeUnit={"px"}
          size={50}
          color={"#0017FF"}
          loading={true}
        />
      );
    }
    let secondjob;
    if (
      jobSelectionStore.editJob.jobmain === "Local Move" ||
      jobSelectionStore.editJob.jobmain === "Corporate Move"
    ) {
      secondjob = (
        <Select
          native
          label="jobsecond"
          value={jobSelectionStore.editJob.jobsecond}
          onChange={(e) =>
            jobSelectionStore.updateJob(e.target.value, "jobsecond")
          }
        >
          <option value="" />
          <option value={"Full Service"}>Full Service</option>
          <option value={"Regular Service"}>Regular Service</option>
        </Select>
      );
    } else if (
      jobSelectionStore.editJob.jobmain === "IM - Inbound" ||
      jobSelectionStore.editJob.jobmain === "CIM - Inbound"
    ) {
      secondjob = (
        <Select
          native
          label="jobsecond"
          value={jobSelectionStore.editJob.jobsecond}
          onChange={(e) =>
            jobSelectionStore.updateJob(e.target.value, "jobsecond")
          }
        >
          <option value="" />
          <option value={"Door to Door"}>Door to Door</option>
          <option value={"Port to Port"}>Port to Port</option>
          <option value={"Port to Door"}>Port to Door</option>
          <option value={"Door to Port"}>Door to Port</option>
        </Select>
      );
    } else if (
      jobSelectionStore.editJob.jobmain === "IM - Outbound" ||
      jobSelectionStore.editJob.jobmain === "CIM - Outbound"
    ) {
      secondjob = (
        <Select
          native
          label="jobsecond"
          value={jobSelectionStore.editJob.jobsecond}
          onChange={(e) =>
            jobSelectionStore.updateJob(e.target.value, "jobsecond")
          }
        >
          <option value="" />
          <option value={"Door to Door"}>Door to Door</option>
          <option value={"Port to Port"}>Port to Port</option>
          <option value={"Port to Door"}>Port to Door</option>
          <option value={"Door to Port"}>Door to Port</option>
        </Select>
      );
    } else {
      secondjob = (
        <Select
          native
          label="jobsecond"
          value={jobSelectionStore.editJob.jobsecond}
          onChange={(e) =>
            jobSelectionStore.updateJob(e.target.value, "jobsecond")
          }
        >
          <option value="" />
          <option value={"Only Storage"}>Only Storage</option>
          <option value={"Only Clearance"}>Only Clearance</option>
        </Select>
      );
    }

    let jobModalData = (
      <div>
        <Container style={{ maxWidth: "98%" }}>
          <form>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12} sm={6} md={6}>
                <FormControl
                  variant="outlined"
                  style={{ width: "100%" }}
                  size="small"
                >
                  <InputLabel>Main Job</InputLabel>
                  <Select
                    native
                    label="Age"
                    value={jobSelectionStore.editJob.jobmain}
                    onChange={(e) =>
                      jobSelectionStore.updateJob(e.target.value, "jobmain")
                    }
                  >
                    <option value="" />
                    <option value={"Local Move"}>Local Move</option>
                    <option value={"Corporate Move"}>Corporate Move</option>
                    <option value={"IM - Inbound"}>IM - Inbound</option>
                    <option value={"IM - Outbound"}>IM - Outbound</option>
                    <option value={"CIM - Inbound"}>CIM - Inbound</option>
                    <option value={"CIM - Outbound"}>CIM - Outbound</option>
                    <option value={"Only Storage"}>Only Storage</option>
                    <option value={"Only Clearance"}>Only Clearance</option>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <FormControl
                  variant="outlined"
                  style={{ width: "100%" }}
                  size="small"
                >
                  <InputLabel>Second Job</InputLabel>
                  {secondjob}
                </FormControl>
              </Grid>
            </Grid>
            <hr />
            <Grid container>
              <Grid item xs={12} sm={12} md={6}>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="companygroup"
                  label="Company Group"
                  name="companygroup"
                  autoComplete="companygroup"
                  autoFocus
                  type="text"
                  style={{ color: "red" }}
                  value={jobSelectionStore.editJob.companygroup}
                  onChange={(e) =>
                    jobSelectionStore.updateJob(e.target.value, "companygroup")
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="cbm"
                  label="Estimated CBM"
                  name="cbm"
                  autoComplete="cbm"
                  autoFocus
                  type="number"
                  value={jobSelectionStore.editJob.cbm}
                  onChange={(e) =>
                    jobSelectionStore.updateJob(e.target.value, "cbm")
                  }
                />
              </Grid>
            </Grid>
            <hr />
            <Grid container>
              <Grid item xs={12} sm={6} md={6}>
                <b>Status</b>
                <FormControl
                  variant="outlined"
                  style={{ width: "100%" }}
                  size="small"
                >
                  <Select
                    native
                    label="Age"
                    value={jobSelectionStore.editJob.status}
                    onChange={(e) =>
                      jobSelectionStore.updateJob(e.target.value, "status")
                    }
                    inputProps={{
                      name: "age",
                      id: "outlined-age-native-simple",
                    }}
                  >
                    <option value="" />
                    <option value={"Lead"}>Lead</option>
                    <option value={"Proposal"}>Proposal</option>
                    <option value={"Booked"}>Booked</option>
                    <option value={"Converted"}>Converted</option>
                    <option value={"Cancelled"}>Cancelled</option>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <b>Estimation Type</b>
                <FormControl
                  variant="outlined"
                  style={{ width: "100%" }}
                  size="small"
                >
                  <Select
                    native
                    label="Age"
                    value={jobSelectionStore.editJob.estimationType}
                    onChange={(e) =>
                      jobSelectionStore.updateJob(
                        e.target.value,
                        "estimationType"
                      )
                    }
                    inputProps={{
                      name: "age",
                      id: "outlined-age-native-simple",
                    }}
                  >
                    <option value="" />
                    <option value={"Pending"}>Pending</option>
                    <option value={"Scheduled"}>Scheduled</option>
                    <option value={"On Site"}>On Site</option>
                    <option value={"By Phone"}>By Phone</option>
                    <option value={"Online"}>Online</option>
                    <option value={"No Estimation"}>No Estimation</option>
                    <option value={"Other"}>Other</option>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </form>
          <br />
        </Container>
      </div>
    );

    return (
      <div>
        <JobModal
          show={jobSelectionStore.showModal}
          onHide={this.handleClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        />
        <Modal
          show={this.state.show}
          onHide={this.handleClose2}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Create A New Job for {jobSelectionStore.singleclient.title}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{jobModalData}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose2}>
              Cancel
            </Button>
            <Button
              variant="success"
              disabled={
                jobSelectionStore.editJob.jobmain === "" ||
                jobSelectionStore.editJob.jobsecond === ""
              }
              onClick={() => {
                this.handleClose2();
                jobSelectionStore.postJob();
              }}
            >
              Create Job
            </Button>
          </Modal.Footer>
        </Modal>
        <Row>
          <Col md={1} />
          <Col md={2}>
            <Button variant="success" onClick={this.handleShow} size="sm">
              Add A New Job
            </Button>
          </Col>
          <Col md={7}>
            <h3>
              {jobSelectionStore.singleclient.title}{" "}
              {jobSelectionStore.jobs.length} Job
              {jobSelectionStore.jobs.length !== 1 && "s"}:
            </h3>
          </Col>

          <Col md={2}>
            <Button
              variant="outline-danger"
              onClick={this.handleShow2}
              size="sm"
            >
              Close Jobs List
            </Button>
          </Col>
        </Row>

        <BootstrapTable
          data={jobSelectionStore.jobs}
          striped
          hover
          search={true}
          condensed
          options={this.options}
        >
          <TableHeaderColumn isKey dataField="id" dataSort>
            Job id
          </TableHeaderColumn>
          <TableHeaderColumn dataField="status" dataSort>
            Status
          </TableHeaderColumn>
          <TableHeaderColumn dataField="jobmain" dataSort>
            Main
          </TableHeaderColumn>
          <TableHeaderColumn dataField="jobsecond" dataSort>
            Type
          </TableHeaderColumn>
          <TableHeaderColumn dataField="cbm" dataSort>
            CBM
          </TableHeaderColumn>
          <TableHeaderColumn dataField="lastprice" dataSort>
            Price
          </TableHeaderColumn>
          <TableHeaderColumn dataField="dateText" dataSort>
            Job Dates
          </TableHeaderColumn>
          <TableHeaderColumn dataField="lastupdate" dataSort>
            Last Update
          </TableHeaderColumn>
        </BootstrapTable>
      </div>
    );
  }
}

export default observer(JobsTable);
