import React, { Component } from "react";
import { observer } from "mobx-react";

import { Button, Col, Row } from "react-bootstrap";

class GoogleAnalyticsUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filtername: "Yearly",
      show: ""
    };
  }

  componentDidMount() {}

  render() {
    return (
      <div>
        <h1>Google Analytics</h1>
        <Row>
          <Col>
            <Button
              variant={
                this.state.filtername === "Yearly" ? "dark" : "outline-dark"
              }
              onClick={() => this.setState({ filtername: "Yearly" })}
              block
            >
              Yearly
            </Button>
          </Col>
          <Col>
            <Button
              variant={
                this.state.filtername === "All Countries"
                  ? "dark"
                  : "outline-dark"
              }
              onClick={() => this.setState({ filtername: "All Countries" })}
              block
            >
              All Countries
            </Button>
          </Col>
          <Col>
            <Button
              variant={
                this.state.filtername === "GCC" ? "dark" : "outline-dark"
              }
              onClick={() => this.setState({ filtername: "GCC" })}
              block
            >
              GCC
            </Button>
          </Col>
          <Col>
            <Button
              variant={
                this.state.filtername === "Asia" ? "dark" : "outline-dark"
              }
              onClick={() => this.setState({ filtername: "Asia" })}
              block
            >
              Asia
            </Button>
          </Col>
          <Col>
            <Button
              variant={
                this.state.filtername === "North America"
                  ? "dark"
                  : "outline-dark"
              }
              onClick={() => this.setState({ filtername: "North America" })}
              block
            >
              North America
            </Button>
          </Col>
          <Col>
            <Button
              variant={
                this.state.filtername === "Europe" ? "dark" : "outline-dark"
              }
              onClick={() => this.setState({ filtername: "Europe" })}
              block
            >
              Europe
            </Button>
          </Col>
        </Row>
        <div class="container">
          {this.state.filtername === "Yearly" && (
            <iframe
              title="Yearly"
              width="2000"
              height="1400"
              seamless
              frameborder="0"
              scrolling="no"
              src="https://docs.google.com/spreadsheets/d/e/2PACX-1vQwZAhOTktO5mVsbtV7ObMsZVv8tfHJius_GBdX6kxVTrufPgh9sHKdbm5M43QjTCgELtvFalGZCZcT/pubchart?oid=1295130214&amp;format=interactive"
            ></iframe>
          )}
          {this.state.filtername === "All Countries" && (
            <iframe
              title="All Countries Graph"
              width="2000"
              height="1400"
              seamless
              frameborder="0"
              scrolling="no"
              src="https://docs.google.com/spreadsheets/d/e/2PACX-1vQwZAhOTktO5mVsbtV7ObMsZVv8tfHJius_GBdX6kxVTrufPgh9sHKdbm5M43QjTCgELtvFalGZCZcT/pubchart?oid=1276299784&amp;format=interactive"
            ></iframe>
          )}
          {this.state.filtername === "GCC" && (
            <iframe
              title="GCC Graph"
              width="2000"
              height="1400"
              seamless
              frameborder="0"
              scrolling="no"
              src="https://docs.google.com/spreadsheets/d/e/2PACX-1vQwZAhOTktO5mVsbtV7ObMsZVv8tfHJius_GBdX6kxVTrufPgh9sHKdbm5M43QjTCgELtvFalGZCZcT/pubchart?oid=329047340&amp;format=interactive"
            ></iframe>
          )}
          {this.state.filtername === "Asia" && (
            <iframe
              title="Asia Graph"
              width="2000"
              height="1400"
              seamless
              frameborder="0"
              scrolling="no"
              src="https://docs.google.com/spreadsheets/d/e/2PACX-1vQwZAhOTktO5mVsbtV7ObMsZVv8tfHJius_GBdX6kxVTrufPgh9sHKdbm5M43QjTCgELtvFalGZCZcT/pubchart?oid=1203766810&amp;format=interactive"
            ></iframe>
          )}
          {this.state.filtername === "North America" && (
            <iframe
              title="North America Graph"
              width="2000"
              height="1400"
              seamless
              frameborder="0"
              scrolling="no"
              src="https://docs.google.com/spreadsheets/d/e/2PACX-1vQwZAhOTktO5mVsbtV7ObMsZVv8tfHJius_GBdX6kxVTrufPgh9sHKdbm5M43QjTCgELtvFalGZCZcT/pubchart?oid=264442521&amp;format=interactive"
            ></iframe>
          )}
          {this.state.filtername === "Europe" && (
            <iframe
              title="Europe Graph"
              width="2000"
              height="1400"
              seamless
              frameborder="0"
              scrolling="no"
              src="https://docs.google.com/spreadsheets/d/e/2PACX-1vQwZAhOTktO5mVsbtV7ObMsZVv8tfHJius_GBdX6kxVTrufPgh9sHKdbm5M43QjTCgELtvFalGZCZcT/pubchart?oid=992528184&amp;format=interactive"
            ></iframe>
          )}
        </div>
      </div>
    );
  }
}

export default observer(GoogleAnalyticsUpdate);
