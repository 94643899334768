import React, { Component } from "react";
import { observer } from "mobx-react";

import newEventStore from "../Stores/NewEventStore";

import FilterModule from "../FilterModules/FilterModule";
import { Button, Col, Row, Card, Modal } from "react-bootstrap";
import axios from "axios";

import ReactApexChart from "react-apexcharts";

const instance = axios.create({
  // baseURL: "https://easymovecalendar.app/"
  // baseURL: "http://127.0.0.1:8000/",
  // baseURL: "http://157.230.113.108/",
  baseURL: "https://jazzafs.com/",
});

class AssignTLModal extends React.Component {
  render() {
    let job = newEventStore.jobforOffice;

    let selectFocalPoint;
    selectFocalPoint = newEventStore.officestaff.map((staff, index) => (
      <Button
        key={index}
        variant={
          job.emfocalpoint === staff.first_name ? "success" : "outline-dark"
        }
        block
        onClick={() => newEventStore.selectJobForOffice(job, staff.first_name)}
      >
        {staff.first_name}
      </Button>
    ));

    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{job.title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            {job.cbm} CBM for {job.lastprice} KD
          </p>
        </Modal.Body>
        <Modal.Body>
          {selectFocalPoint}

          <Button
            variant={
              job.emfocalpoint === "" || job.emfocalpoint === null
                ? "success"
                : "outline-dark"
            }
            block
            onClick={() => newEventStore.selectJobForOffice(job, "")}
          >
            None
          </Button>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={() => {
              let data = { emfocalpoint: job.emfocalpoint };

              instance
                .put("/job/" + job.id + "/", data)
                .then((res) => res.data)
                .then((events) => {
                  console.log("successfully added");
                  newEventStore.updateTLjob(job);
                  alert(`Job #${events.id} assigned to ${job.emfocalpoint}.`);
                  this.props.onHide();
                  this.setState({ modalShow: false });
                })
                .catch((err) => {
                  alert(
                    "An error occurred. The data did NOT save. Please contact me (97666886)."
                  );
                  console.error(err.response);
                });
            }}
          >
            Save
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              newEventStore.selectJobForOffice(job, "reset");
              this.props.onHide();
            }}
          >
            No, Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

class AssignJobCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      client: true,
      job: true,
      remarks: true,
      management: true,
      feedback: false,
      cancellation: false,
      update: true,
    };
  }

  render() {
    let job = this.props.job;

    return (
      <Col>
        <Card
          style={{
            width: "150px",
            outline: "black",
            boxShadow: "1px 1px 1px black",
          }}
        >
          <Card.Header as="h4">
            <Button
              block
              variant={"outline-dark"}
              style={{
                backgroundColor: "transparent",
                color: "black",
                fontSize: 11,
              }}
            >
              {job.id}: {job.clienttitle}
            </Button>
          </Card.Header>
          <Card.Body>
            <Card.Title style={{ fontSize: 10 }}>
              {job.jobmain} - {job.status}
            </Card.Title>
            <Card.Title style={{ fontSize: 9 }}>
              {job.cbm} CBM at {job.lastprice} KD
            </Card.Title>
            <Card.Text style={{ fontSize: 8 }}>
              {job.remarks &&
                job.remarks.substring(
                  job.remarks.length - 100,
                  job.remarks.length
                )}
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <Card.Text style={{ fontSize: 10 }}>
              <b>Focal Point: </b>
              {job.emfocalpoint ? job.emfocalpoint : "Not Assigned"}
            </Card.Text>
          </Card.Footer>
        </Card>
      </Col>
    );
  }
}

class AssignOfficeJob extends Component {
  constructor(props) {
    super(props);
    this.state = {
      job: {},
      modalShow: false,
    };
  }

  showModal = () => {
    this.setState({
      modalShow: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      modalShow: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      modalShow: false,
    });
  };

  render() {
    let modalClose = () => {
      this.setState({ modalShow: false });
    };

    let jobslist = newEventStore.dashboardJobs.filter(
      (job) => job.emfocalpoint === ""
    );
    let jobslistassigned = newEventStore.dashboardJobs.filter(
      (job) => job.emfocalpoint !== ""
    );

    let NAJobs = jobslist.map((job, index) => (
      <div
        key={index}
        onClick={() => {
          newEventStore.selectJobForOffice(job, "add");
          this.setState({ modalShow: true });
        }}
      >
        <AssignJobCard job={job} />
      </div>
    ));
    let AJobs = jobslistassigned.map((job, index) => (
      <div
        key={index}
        onClick={() => {
          newEventStore.selectJobForOffice(job, "add");
          this.setState({ modalShow: true });
        }}
      >
        <AssignJobCard job={job} />
      </div>
    ));

    let notassignedportion = (
      <div>
        <Button block variant="dark" disabled>
          <h1> {NAJobs.length} Not Assigned Clients</h1>
        </Button>
        <Row style={{ justifyContent: "flex-start" }}>{NAJobs}</Row>
        <hr />
      </div>
    );

    let assignedportion = (
      <div>
        <Button block variant="dark" disabled>
          <h1> {AJobs.length} Assigned Clients</h1>
        </Button>
        <Row style={{ justifyContent: "flex-start" }}>{AJobs}</Row>
        <hr />
      </div>
    );

    let stafflist = [];

    let leadjobs = newEventStore.dashboardJobs.filter(
      (job) => job.status === "Lead" || job.status === "Estimation"
    );
    let propjobs = newEventStore.dashboardJobs.filter(
      (job) => job.status === "Proposal"
    );
    let bookjobs = newEventStore.dashboardJobs.filter(
      (job) => job.status === "Booked"
    );

    let vinith = 0;
    let katrina = 0;
    let yobel = 0;
    let reyna = 0;
    let aasim = 0;
    let nauser = 0;

    // Lead
    for (let i = 0; i < leadjobs.length; i++) {
      if (leadjobs[i].emfocalpoint === "Reyna") {
        reyna = reyna + 1;
      } else if (leadjobs[i].emfocalpoint === "Yobel") {
        yobel = yobel + 1;
      } else if (leadjobs[i].emfocalpoint === "Katrina") {
        katrina = katrina + 1;
      } else if (leadjobs[i].emfocalpoint === "Vinith") {
        vinith = vinith + 1;
      } else if (leadjobs[i].emfocalpoint === "Aasim") {
        aasim = aasim + 1;
      } else {
        nauser = nauser + 1;
      }
    }
    stafflist.push({
      name: "Lead",
      data: [reyna, yobel, aasim, katrina, vinith, nauser],
    });
    // Proposal
    vinith = 0;
    katrina = 0;
    yobel = 0;
    reyna = 0;
    aasim = 0;
    nauser = 0;
    for (let i = 0; i < propjobs.length; i++) {
      if (propjobs[i].emfocalpoint === "Reyna") {
        reyna = reyna + 1;
      } else if (propjobs[i].emfocalpoint === "Yobel") {
        yobel = yobel + 1;
      } else if (propjobs[i].emfocalpoint === "Katrina") {
        katrina = katrina + 1;
      } else if (propjobs[i].emfocalpoint === "Vinith") {
        vinith = vinith + 1;
      } else if (propjobs[i].emfocalpoint === "Aasim") {
        aasim = aasim + 1;
      } else {
        nauser = nauser + 1;
      }
    }
    stafflist.push({
      name: "Proposal",
      data: [reyna, yobel, aasim, katrina, vinith, nauser],
    });
    vinith = 0;
    katrina = 0;
    yobel = 0;
    reyna = 0;
    aasim = 0;
    nauser = 0;
    // Booked
    for (let i = 0; i < bookjobs.length; i++) {
      if (bookjobs[i].emfocalpoint === "Reyna") {
        reyna = reyna + 1;
      } else if (bookjobs[i].emfocalpoint === "Yobel") {
        yobel = yobel + 1;
      } else if (bookjobs[i].emfocalpoint === "Katrina") {
        katrina = katrina + 1;
      } else if (bookjobs[i].emfocalpoint === "Vinith") {
        vinith = vinith + 1;
      } else if (bookjobs[i].emfocalpoint === "Aasim") {
        aasim = aasim + 1;
      } else {
        nauser = nauser + 1;
      }
    }
    stafflist.push({
      name: "Booked",
      data: [reyna, yobel, aasim, katrina, vinith, nauser],
    });

    let optionsforchart = {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        foreColor: "black",
        dropShadow: {
          enabled: true,
          bottom: 1,
          right: 1,
          blur: 3,
          opacity: 0.1,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
              colors: "black",
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      xaxis: {
        type: "text",
        categories: [
          "Reyna",
          "Yobel",
          "Aasim",
          "Katrina",
          "Vinith",
          "Not Assigned",
        ],
      },
      legend: {
        position: "top",
        offsetY: 20,
      },
      colors: ["#999999", "#f2ff00", "#00c707"],
      fill: {
        opacity: 1,
      },
      dataLabels: {
        enabled: true,
        style: {
          fontWeight: "bold",
          fontSize: "18px",
        },
        dropShadow: {
          enabled: true,
          left: 2,
          top: 2,
          opacity: 0.7,
        },
      },
    };

    return (
      <div>
        <AssignTLModal
          show={this.state.modalShow}
          onHide={modalClose}
          event={this.state.event}
          job={this.state.job}
        />

        <ReactApexChart
          options={optionsforchart}
          series={stafflist}
          type="bar"
          height={350}
        />

        <div>
          <FilterModule />
          <hr />
          {newEventStore.selectedstaff === "" && notassignedportion}
          {assignedportion}
        </div>
      </div>
    );
  }
}

export default observer(AssignOfficeJob);
