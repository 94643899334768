import { decorate, observable, computed } from "mobx";
import axios from "axios";
import jwt_decode from "jwt-decode";
import moment from "moment";
import eventStore from "./EventStore";
import newEventStore from "./NewEventStore";
import settingStore from "./SettingStore";

const instance = axios.create({
  // baseURL: "https://easymovecalendar.app/"
  // baseURL: "http://127.0.0.1:8000/",
  // baseURL: "http://157.230.113.108/",
  baseURL: "https://jazzafs.com/",
});

class UserStore {
  constructor() {
    this.user = null;
    this.profile = {
      group: "",
      first_name: "",
      last_name: "",
      last_signin: new Date(),
    };
    this.username = "";
    this.password = "";
    this.token = "";
    this.signedIn = false;
    this.userType = 0;
    this.checkForToken();
    this.loading = false;
    this.attendanceloaded = false;
    this.attendance = [];
    this.staff = {
      local: {
        reyna: "Pending",
        yobel: "Pending",
        aasim: "Pending",
      },
      im: {
        katrina: "Pending",
        vinith: "Pending",
      },
      mgt: {
        ali: "Pending",
        hamad: "Pending",
        rajan: "Pending",
      },
    };
  }

  // Profiles:
  // Admin      1= Administrator
  // Management 2= Management
  // Accounts   3= Accounting Staff
  // Office     4= Office Staff
  // Field      5= Team Leaders
  // Warehouse  6= Warehouse Staff -> Aiman

  signOut = () => {
    this.signedIn = false;
  };
  signIn = () => {
    this.signedIn = true;
  };

  editUsername(val) {
    this.username = val;
  }
  editPassword(val) {
    this.password = val;
  }

  fetchAttendance() {
    this.loading = true;
    return instance
      .get("/attendancelist/?format=json")
      .then((res) => res.data)
      .then((attendance) => {
        this.attendance = attendance;
        if (this.attendance.length > 0) {
          for (let i = 0; i < this.attendance.length; i++) {
            this.attendance[i].newdate = moment(
              this.attendance[i].date
            ).subtract(3, "hours");
            this.attendance[i].formatteddate = moment(this.attendance[i].date)
              .subtract(3, "hours")
              .format("DD-MMM-YY HH:mm");
            this.attendance[i].timeago = moment(this.attendance[i].date)
              .subtract(3, "hours")
              .fromNow();
          }
        }
        console.log("Attendance Loaded");
        this.loading = false;
        this.attendanceloaded = true;
      });
  }

  fetchUserAccess(user) {
    return instance
      .get("/profile/" + user.user_id + "/?format=json")
      .then((res) => res.data)
      .then((profile) => {
        this.profile = profile;
        if (this.profile.first_name !== "Abdulla") {
          this.postLastSignIn();
        }
        if (
          this.profile.group === "Management" ||
          this.profile.group === "Admin"
        ) {
          newEventStore.fetchFooterData();
        }
      });
  }

  postFormatChange(value) {
    this.profile.editstyle = value;
    let data = this.profile;

    instance
      .put("/profile/" + this.profile.id + "/", data)
      .then((res) => res.data)
      .then((events) => {
        // console.log("successfully added");
        console.log("updated style");
      })
      .catch((err) => {
        let message = {
          activity: "Changing User Tabs - Vertical / Horizontal",
          ClientName: "",
          ClientID: "",
          JobID: "",
          JobMain: "",
          Status: "",
          Data: data,
          error: err.response,
        };
        settingStore.sendUnifiedEmail(message, "error");
        console.log(err.response);
      });
  }

  postLastSignIn() {
    let data = {
      name: this.profile.first_name,
      date: moment(new Date()).add(3, "hours"),
    };
    let data2 = this.profile;
    data2.last_signin = data.date;

    instance
      .put("/profile/" + this.profile.id + "/", data2)
      .then((res) => res.data)
      .then((events) => {
        // console.log("successfully added");
        console.log("Last Sign In Posted");
      })
      .catch((err) => {
        let message = {
          activity: "Posting Last Sign In",
          ClientName: "",
          ClientID: "",
          JobID: "",
          JobMain: "",
          Status: "",
          Data: data,
          error: err.response,
        };
        settingStore.sendUnifiedEmail(message, "error");
        console.log(err.response);
      });

    instance
      .post("/c-attendance/", data)
      .then((res) => res.data)
      .then((events) => {
        // console.log("successfully added");
        console.log("Sign In Added");
      })
      .catch((err) => {
        let message = {
          activity: "Posting Attendance",
          ClientName: "",
          ClientID: "",
          JobID: "",
          JobMain: "",
          Status: "",
          Data: data,
          error: err.response,
        };
        settingStore.sendUnifiedEmail(message, "error");
        alert(
          "An error occurred. The data did NOT save. Please contact me (97666886)." +
            "\n" +
            JSON.stringify(err.response)
        );
        console.error(err.response);
      });
  }

  checkForToken() {
    const token = localStorage.getItem("myToken");
    if (token) {
      const currentTime = Date.now() / 1000;
      const user = jwt_decode(token);
      if (user.exp >= currentTime) {
        this.setUser(token);
      } else {
        this.logoutUser();
      }
    }
  }

  setUser(token) {
    axios.defaults.headers.common.Authorization = `JWT ${token}`;
    const decodedUser = jwt_decode(token);
    this.user = decodedUser;
    localStorage.setItem("myToken", token);
    this.fetchUserAccess(decodedUser);
    this.signedIn = true;
  }

  logoutUser() {
    delete axios.defaults.headers.common.Authorization;
    this.token = "";
    this.user = null;
    this.signedIn = false;
    localStorage.removeItem("myToken");
    console.log("User Logged Out");
  }

  loginUser = async (userData) => {
    try {
      const res = await instance.post("/login/?format=json", userData);
      console.log("success");
      const user = res.data;
      this.token = user.token;
      this.setUser(user.token);
    } catch (err) {
      alert("Sign In Failed. Please check the Username and Password.");
    }
  };

  get lastSignIn() {
    let userslist = eventStore.allstaff.filter(
      (staff) => (staff.group !== "Field") & (staff.group !== "Admin")
    );
    let indexVal = -1;
    for (let i = 0; i < userslist.length; i++) {
      indexVal = this.attendance.findIndex(
        (attendance) => attendance.name === userslist[i].first_name
      );

      if (indexVal >= 0) {
        userslist[i].lastsignin = this.attendance[indexVal].timeago;
      } else {
        userslist[i].lastsignin = "Pending";
      }
    }
    return userslist;
  }

  get access() {
    if (this.profile.group === "Admin") {
      return 1;
    } else if (this.profile.group === "Management") {
      return 2;
    } else if (this.profile.group === "Accounts") {
      return 3;
    } else if (this.profile.group === "Office") {
      return 4;
    } else if (this.profile.group === "Field") {
      return 5;
    } else if (this.profile.group === "Warehouse") {
      return 6;
    } else {
      return 7;
    }
  }
}

decorate(UserStore, {
  user: observable,
  loading: observable,
  attendance: observable,
  username: observable,
  password: observable,
  profile: observable,
  token: observable,
  signedIn: observable,
  userType: observable,
  attendanceloaded: observable,
  staff: observable,
  lastSignIn: computed,
  access: computed,
});

const userStore = new UserStore();
export default userStore;
