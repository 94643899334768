import React, { Component } from "react";
import { PDFViewer } from "@react-pdf/renderer";
import { observer } from "mobx-react";
import { Button } from "react-bootstrap";

import proposalFooter from "./easymovefooter.png";
import proposalHeader from "./easymoveheader.png";
import signature from "./ArmieSignature.png";

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
import moment from "moment";
import editJobStore from "../Stores/EditJobStore";
import newEventStore from "../Stores/NewEventStore";
import ServicesUpdate from "./ServicesUpdate";
import ItemsList from "./ItemsList/ItemsList";

Font.register(
  "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
  { family: "Oswald" }
);

const styles = StyleSheet.create({
  body: {
    padding: 60,
    // paddingTop: 35,
    // paddingBottom: 65,
    // paddingHorizontal: 35
  },
  unbreakable: { width: "100%", height: 400 },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Oswald",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: "Oswald",
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  text2: {
    fontSize: 10,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  text3: {
    fontSize: 12,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  text4: {
    fontSize: 8,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
  },
  header2: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
    fontWeight: "italic",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  headerTite: {
    fontSize: 14,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: { margin: "auto", flexDirection: "row" },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: { margin: "auto", marginTop: 5, fontSize: 10 },
  tableHeaderStyle: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 14,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    fontWeight: "bold",
    paddingTop: 6,
  },
  tableRow1b: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 12,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "20%",
  },
  tableRow1c: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 12,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "40%",
  },
  tableRow2a: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 12,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "80%",
  },
  tableRow2b: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontWeight: "bold",
    fontSize: 12,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "20%",
  },
  tableRow2c: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontWeight: "bold",
    fontSize: 12,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "40%",
  },
  tableRow2d: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "grey",
    color: "white",
    fontWeight: "bold",
    fontSize: 12,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "100%",
  },
  tableRow2e: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 12,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "70%",
  },
  tableRow2f: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontWeight: "bold",
    fontSize: 12,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "30%",
  },
  tableRow3a: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "20%",
  },
  tableRow3b: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "10%",
  },
  tableRow4a: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "10%",
  },
  tableRow4b: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "45%",
  },
  tableRow5a: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "10%",
  },
  tableRow5b: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "45%",
  },
  subtitle2: {
    fontSize: 14,
    fontFamily: "Oswald",
  },
  subtitle3: {
    fontSize: 12,
    margin: 12,
    fontFamily: "Oswald",
  },
});

class ProposalExport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showproposal: false,
      packinglist: true,
      storage: true,
      showdiscount: false,
    };
  }

  render() {
    let addresslist = (
      <View style={{ flexDirection: "row" }}>
        <Text style={styles.tableRow4a}>#</Text>
        <Text style={styles.tableRow4b}>From Address:</Text>
        <Text style={styles.tableRow4b}>To Address:</Text>
      </View>
    );
    let addressdetails;
    if (editJobStore.addresses.length > 0) {
      addressdetails = editJobStore.addresses.map((address, index) => (
        <View style={{ flexDirection: "row" }} key={index}>
          <Text style={styles.tableRow5a}>{index + 1}</Text>
          <Text style={styles.tableRow5b}>{address.locationfrom}</Text>
          <Text style={styles.tableRow5b}>{address.locationto}</Text>
        </View>
      ));
    }

    let dates = "";
    if (editJobStore.jobdates.length > 0) {
      for (let i = 0; i < editJobStore.jobdates.length; i++) {
        dates =
          dates +
          moment(editJobStore.jobdates[i].jobdate).format("DD-MMM-YY") +
          ", ";
      }
    }

    let datesFinal = (
      <View style={{ flexDirection: "row" }}>
        <Text style={styles.tableRow1b}>
          {editJobStore.jobdates.length} Job Days
        </Text>
        <Text style={styles.tableRow2a}>{dates}</Text>
      </View>
    );

    let servicesitems = "";
    for (let i = 0; i < newEventStore.includedServices.length; i++) {
      if (newEventStore.includedServices[i].included) {
        servicesitems += `${newEventStore.includedServices[i].title}, `;
      }
    }
    if (editJobStore.job.includeservice) {
      if (editJobStore.job.includeservice.length > 1) {
        servicesitems += editJobStore.job.includeservice + ", ";
      }
    }
    if (servicesitems.length > 1) {
      servicesitems =
        servicesitems.substring(0, servicesitems.length - 2) + ".";
    }

    let excludedservicesitems = "";
    for (let i = 0; i < newEventStore.excludedServicesCalc.length; i++) {
      if (newEventStore.excludedServicesCalc[i].included) {
        excludedservicesitems += `${newEventStore.excludedServicesCalc[i].title}, `;
      }
    }
    if (editJobStore.job.excludeservice.length > 1) {
      excludedservicesitems += editJobStore.job.excludeservice + ", ";
    }
    if (excludedservicesitems.length > 1) {
      excludedservicesitems =
        excludedservicesitems.substring(0, excludedservicesitems.length - 2) +
        ".";
    }
    let addcharges;
    addcharges = editJobStore.addcharge.map((charge, index) => (
      <View style={{ flexDirection: "row" }} key={index}>
        <Text style={styles.tableRow2a}>{charge.title}</Text>
        <Text style={styles.tableRow2b}>
          {Math.round(1000 * charge.price) / 1000} KD
        </Text>
      </View>
    ));

    let itemslisttable;
    itemslisttable = editJobStore.rooms.map((room, index) => (
      <View key={index}>
        <View style={{ flexDirection: "row" }} key={index}>
          <Text style={styles.tableRow2d}>{room.title}</Text>
        </View>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.tableRow2e}>Items:</Text>
          <Text style={styles.tableRow2f}>Quantity</Text>
        </View>
        <ItemsList room={room} />
      </View>
    ));

    let totaladdchargeprice = 0;
    for (let i = 0; i < editJobStore.addcharge.length; i++) {
      totaladdchargeprice += parseFloat(editJobStore.addcharge[i].price);
    }

    let storageprice = 0;

    for (let i = 0; i < editJobStore.storages.length; i++) {
      if (
        moment(editJobStore.storages[i].storageenddate).diff(
          moment(editJobStore.storages[i].storagestartdate),
          "months",
          true
        ) < 3
      ) {
        storageprice =
          storageprice + 3 * parseFloat(editJobStore.storages[i].monthlycost);
      } else {
        storageprice =
          storageprice +
          editJobStore.storages[i].monthlycost *
            moment(editJobStore.storages[i].storageenddate).diff(
              moment(editJobStore.storages[i].storagestartdate),
              "months",
              true
            );
      }
    }

    storageprice = Math.round(10 * storageprice) / 10;

    let storagecharges;
    storagecharges = editJobStore.storages.map((storage, index) => (
      <View style={{ flexDirection: "row" }} key={index}>
        <Text style={styles.tableRow2a}>
          Storage:{" "}
          {Math.round(
            moment(storage.storageenddate).diff(
              moment(storage.storagestartdate),
              "months",
              true
            )
          )}{" "}
          Months from {moment(storage.storagestartdate).format("DD-MMM-YYYY")}{" "}
          to {moment(storage.storageenddate).format("DD-MMM-YYYY")}{" "}
        </Text>
        <Text style={styles.tableRow2b}>{storageprice} KD</Text>
      </View>
    ));

    let packinglist = (
      <View break>
        <Text style={styles.header} break>
          Items List
        </Text>

        {itemslisttable}
      </View>
    );

    let discounttable = (
      <View style={{ flexDirection: "row" }}>
        <Text style={styles.tableRow2a}>
          Discount - {editJobStore.job.discountname}{" "}
          {Math.round(10 * editJobStore.job.discountvalue) / 10}%{" "}
        </Text>
        <Text style={styles.tableRow2b}>
          -{" "}
          {Math.round(
            1000 * (editJobStore.job.firstprice - editJobStore.job.lastprice)
          ) / 1000}{" "}
          KD
        </Text>
      </View>
    );

    let fullproposal = (
      <PDFViewer width={"100%"} height={600}>
        <Document>
          <Page size="A4" style={styles.body}>
            <Image src={proposalHeader} />
            <Text style={styles.header}>
              {editJobStore.job.jobmain} Proposal on{" "}
              {moment().format("DD-MMM-YYYY")}{" "}
            </Text>
            <View style={{ flexDirection: "row" }}>
              <Text style={styles.tableRow2e}>Quotation Number:</Text>
              <Text style={styles.tableRow2f}>
                {editJobStore.job.id}-{moment().format("YYMMDD")}
              </Text>
            </View>
            <View style={{ flexDirection: "row" }}>
              <Text style={styles.tableRow1b}>Client</Text>
              <Text style={styles.tableRow2a}>{editJobStore.client.title}</Text>
            </View>
            <View style={{ flexDirection: "row" }}>
              <Text style={styles.tableRow1b}>Phone</Text>
              <Text style={styles.tableRow2a}>
                {editJobStore.client.phone1} / {editJobStore.client.phone2}
              </Text>
            </View>
            <View style={{ flexDirection: "row" }}>
              <Text style={styles.tableRow1b}>Email</Text>
              <Text style={styles.tableRow2a}>{editJobStore.client.email}</Text>
            </View>
            {editJobStore.addresses.length > 0 && (
              <Text style={styles.text}> </Text>
            )}
            {editJobStore.addresses.length > 0 && addresslist}
            {editJobStore.addresses.length > 0 && addressdetails}
            {editJobStore.jobdates.length > 0 && (
              <Text style={styles.text}> </Text>
            )}
            {editJobStore.jobdates.length > 0 && datesFinal}
            <Text style={styles.text}> </Text>
            <Text style={styles.text3}>Included Services: {servicesitems}</Text>
            {excludedservicesitems.length > 1 && (
              <Text style={styles.text3}>
                Excluded Services: {excludedservicesitems}
              </Text>
            )}
            <Text style={styles.tableHeaderStyle}>
              Estimated Cost of Service
            </Text>
            <View style={{ flexDirection: "row" }}>
              <Text style={styles.tableRow2a}>Service Charges (KWD)</Text>
              <Text style={styles.tableRow2b}>
                {this.state.showdiscount
                  ? `${
                      Math.round(1000 * editJobStore.job.firstprice) / 1000
                    } KD`
                  : `${
                      Math.round(1000 * editJobStore.job.lastprice) / 1000
                    } KD`}
              </Text>
            </View>
            {this.state.showdiscount && discounttable}
            {addcharges}
            {storagecharges}
            <View style={{ flexDirection: "row" }}>
              <Text style={styles.tableRow2e}>Total Charges</Text>
              <Text style={styles.tableRow2f}>
                {Math.round(
                  1000 *
                    (parseFloat(editJobStore.job.lastprice) +
                      parseFloat(totaladdchargeprice) +
                      parseFloat(storageprice))
                ) / 1000}{" "}
                KD
              </Text>
            </View>
            <Text> - </Text>
            <Text style={styles.text2}>
              * Any cancellations less than 48 hours of the start of the job
              under any circumstance will result in a penalty equivalent to 65
              Kuwaiti Dinars.
            </Text>
            <Text style={styles.text2}>
              * This quotation is valid for 30 days from the date of proposal.
            </Text>
            <Text style={styles.text2}> </Text>
            <Text style={styles.text2}>
              * The date on the proposal is subject to availability.
            </Text>
            <Text style={styles.text2}>
              * Drilling and wall fixing is charged separately as an additional
              cost.
            </Text>
            {this.state.packing && packinglist}

            <Text style={styles.header} break>
              Relocation Proposal
            </Text>
            <Text style={styles.subtitle2}>Work Timing</Text>
            <Text style={styles.subtitle3}>
              Crew Working Hours: Sunday to Saturday 08:00HRS to 17:30HRS.
            </Text>
            <Text style={styles.subtitle3}>
              Administration Office Working Hours: Saturday to Thursday 09:00HRS
              to 18:00HRS. (Excluding Public Holidays).
            </Text>
            <Text style={styles.subtitle2}>Terms of Payment</Text>
            <Text style={styles.subtitle3}>
              100% Advance payment agreed amount are strictly required prior to
              starting of the job, either by Cash / K-net.{" "}
            </Text>
            <Text style={styles.subtitle3}>
              Should you require any additional information please feel free to
              contact the undersigned at your earliest convenience.
            </Text>
            <Text style={styles.subtitle3}>
              We thank you for the opportunity to quote for your business and
              assure you of our best service levels and prompt attention at all
              times.
            </Text>
            <Text> </Text>
            <Text> </Text>
            <Text> </Text>
            <Text> </Text>
            <Text style={styles.subtitle2}>{"        "}Yours Sincerely,</Text>
            <Image src={signature} style={{ width: 150 }} />
            <Text style={styles.header} break>
              Terms and Conditions
            </Text>
            <Text style={styles.text2}>
              Following are the Term and Conditions for relocation services,
              between the owner / shipper of the goods named in this
              documentation and Easy Move Co. Hawally Kuwait.
            </Text>
            <View style={{ flexDirection: "row" }}>
              <View style={{ flexDirection: "column", width: "45%" }}>
                <Text style={styles.text2}>
                  1. Customer's responsibility during removal
                </Text>
                <Text style={styles.text4}>
                  {" "}
                  a) It will be the customer's sole responsibility to ensure
                  that nothing is taken away in error or left behind.
                </Text>
                <Text style={styles.text4}>
                  {" "}
                  b) The customer will obtain at his own expense all documents
                  and permissions necessary to for the removal being completed.
                </Text>
                <Text style={styles.text4}>
                  {" "}
                  c) The customer will take responsibility for the security of
                  his goods at the departure and destination points by being
                  present himself or arranging for someone else to represent
                  him.
                </Text>
                <Text style={styles.text4}>
                  {" "}
                  d) The customer must adequately prepare and stabilize all
                  appliances and electronic equipment prior to their removal.
                </Text>
                <Text style={styles.text4}>
                  {" "}
                  e) The customer must arrange and pay for any necessary parking
                  facilities for the move van.
                </Text>
                <Text style={styles.text4}>
                  {" "}
                  f) The customer must ensure that there is adequate and
                  suitable access for Easy Move Freight vehicles.
                </Text>
                <Text style={styles.text4}>
                  Easy Move Freight will not be liable for any loss or damage,
                  costs or additional harges that may arise from any of these
                  matters.
                </Text>
                <Text style={styles.text2}> - </Text>
                <Text style={styles.text2}>2. Excluded Goods</Text>
                <Text style={styles.text4}>
                  {" "}
                  The following items are specifically excluded from this
                  contract and if you ask us to move, store or ship them, we do
                  not accept any responsibility for loss or damage;
                </Text>
                <Text style={styles.text4}>
                  {" "}
                  a) Jewelry, items of precious nature.
                </Text>
                <Text style={styles.text4}>
                  {" "}
                  b) Potentially dangerous, damaging or explosive items.
                </Text>
                <Text style={styles.text4}>
                  {" "}
                  c) Goods which are legally prohibited, either in the country
                  of origin or destination. It is the customer's responsibility
                  to identify and exclude such prohibited goods.
                </Text>
                <Text style={styles.text4}>
                  {" "}
                  d) Goods likely to encourage vermin or other pests or to cause
                  infection.
                </Text>
                <Text style={styles.text4}>
                  {" "}
                  e) Refrigerated or frozen food or drink.
                </Text>
                <Text style={styles.text4}>
                  {" "}
                  f) Any plants and animals, including pets.
                </Text>
                <Text style={styles.text4}>
                  {" "}
                  g) Computer / electronic equipment and any data storage
                  devices.
                </Text>
                <Text style={styles.text2}> - </Text>
                <Text style={styles.text2}>3. Company's Liability</Text>
                <Text style={styles.text4}>
                  {" "}
                  The company's liability shall be limited to value of actual
                  loss or damage to a maximum of 60 fils per kg. We shall not be
                  liable for loss or damage resulting from:
                </Text>
                <Text style={styles.text4}>
                  {" "}
                  a) War, invasion, strikes, acts of foreign enemies or other
                  such events outside our reasonable control.
                </Text>
                <Text style={styles.text4}>
                  {" "}
                  b) Normal wear and tear, leakage's or evaporation, atmospheric
                  or climatic changes.
                </Text>
                <Text style={styles.text4}>
                  {" "}
                  c) Any damage to goods which are not packed / unpacked by us.
                </Text>
                <Text style={styles.text4}>
                  {" "}
                  All vehicles including boats and car are to be insured failing
                  which the company shall not be liable for any loss/damage to
                  them.
                </Text>
                <Text style={styles.text4}>
                  {" "}
                  The customer may elect to complete a valued inventory of his
                  goods and purchase All Risk Insurance in which event coverage
                  will increase to the values so purchased and the terms and
                  conditions of the insurance policy will apply.
                </Text>
              </View>
              <View style={{ flexDirection: "column", width: "10%" }}>
                <Text> </Text>
              </View>
              <View style={{ flexDirection: "column", width: "45%" }}>
                <Text style={styles.text2}>4. Jurisdiction</Text>
                <Text style={styles.text4}>
                  {" "}
                  This contract is subject to the laws of Kuwait.
                </Text>
                <Text style={styles.text2}> - </Text>
                <Text style={styles.text2}>5. Disputes</Text>
                <Text style={styles.text4}>
                  {" "}
                  If there is a dispute arising from this Agreement, which
                  cannot be resolved, either party may refer it to judicial
                  arbitration under Ministerial Resolution No. 43/1995.
                </Text>
                <Text style={styles.text2}> - </Text>
                <Text style={styles.text2}>6. Claims</Text>
                <Text style={styles.text4}>
                  {" "}
                  All claims for loss or damage to the goods should be submitted
                  to the Company. Initial written notification of such loss or
                  damage, must be reported within 3 days of delivery of the
                  goods. A full written report, setting out the details of the
                  claim, including estimates of the amount claimed must be
                  submitted to the Company within 45 days of full delivery. In
                  the absence of these notitifations the customer hereby waives
                  and agrees to waive all claims for loss or damage.
                </Text>
                <Text style={styles.text2}> - </Text>
                <Text style={styles.text2}>7. Your address while in store</Text>
                <Text style={styles.text4}>
                  {" "}
                  The customer will at all times keep the company informed in
                  writing of his current address.
                </Text>
                <Text style={styles.text2}> - </Text>
                <Text style={styles.text2}>8. Inventory</Text>
                <Text style={styles.text4}>
                  {" "}
                  When we prepare and inventory and present or send it to you it
                  will be accepted as a conclusive and accurate list of property
                  received unless, within 7 days of receiving it, the company is
                  notified in writing of any error/omission.
                </Text>
                <Text style={styles.text2}> - </Text>
                <Text style={styles.text2}>9. Storage Contracts</Text>
                <Text style={styles.text4}>
                  {" "}
                  a. The company is entitled to revise charges from time to time
                  and will give the customer at least 28 days notice of any
                  charges which will not take effect until the beginning of the
                  next chargeable period of storage.
                </Text>
                <Text style={styles.text4}>
                  {" "}
                  b. Charges are billed and are payable 3 months in advance. If
                  the goods are removed from storage before the expirey of the 3
                  month period the customer's account will be credited for the
                  un-expired portion of the period, but not for periods of less
                  than one complete week.
                </Text>
                <Text style={styles.text4}>
                  {" "}
                  The company shall have a general lien on any and all property
                  deposited with it. In the event that storage and other related
                  charges are not paid when due, and after the required notice
                  to the owner and public announcement, the goods shall be sold
                  at public auction as required by law, with the proceeds going
                  first to pay accrued storage and related charges and the
                  expenses incurred in the sale of the goods.
                </Text>
              </View>
            </View>
            <Image
              src={proposalFooter}
              style={{
                position: "absolute",
                bottom: 45,
                left: 0,
                right: 0,
              }}
            />
            <Text
              style={styles.pageNumber}
              render={({ pageNumber, totalPages }) =>
                `${pageNumber} / ${totalPages}`
              }
              fixed
            />
          </Page>
        </Document>
      </PDFViewer>
    );

    return (
      <div>
        <Button
          onClick={() =>
            this.setState({ showdiscount: !this.state.showdiscount })
          }
          variant={this.state.showdiscount ? "dark" : "outline-dark"}
        >
          {this.state.showdiscount ? "Hide Discount" : "Show Discount"}
        </Button>
        <Button
          onClick={() => this.setState({ packing: !this.state.packing })}
          variant={this.state.packing ? "dark" : "outline-dark"}
        >
          {this.state.packing ? "Remove Packing List" : "Include Packing List"}
        </Button>
        <ServicesUpdate />
        <Button
          block
          variant={
            this.state.showproposal ? "outline-danger" : "outline-success"
          }
          onClick={() =>
            this.setState({ showproposal: !this.state.showproposal })
          }
        >
          {this.state.showproposal ? "Hide Proposal" : "Show Proposal"}
        </Button>
        <div>{this.state.showproposal && fullproposal}</div>
      </div>
    );
  }
}

export default observer(ProposalExport);
