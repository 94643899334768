import React, { Component } from "react";
import { observer } from "mobx-react";

import eventStore from "../Stores/EventStore";
import { Button } from "react-bootstrap";

import MaterialTable from "material-table";

import "react-datepicker/dist/react-datepicker.css";

import financialStore from "../Stores/FinancialStore";
import jobSelectionStore from "../Stores/JobSelectionStore";
import JobModal from "../FilterModules/JobModal";
import DynamicClientFinances from "./DynamicClientFinances";

class ClientsNotPaid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editPayment: false,
      financialsLoaded: false,
      modalShow: false,
      indexVal: 0,
      filter: false,
      show: false,
    };
  }

  componentDidMount() {
    if (!eventStore.financialsLoaded) {
      financialStore.fetchFinancialData();
    }
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };
  handleClose = () => jobSelectionStore.closeModal();

  render() {
    let columnnames = [
      { title: "Job ID", field: "jobid", removable: true },
      { title: "Client", field: "clientname" },
      { title: "Status", field: "status" },
      { title: "Main", field: "jobmain" },
      { title: "Focal Point", field: "emfocalpoint" },
      { title: "Second", field: "jobsecond", removable: true },
      { title: "Move Price", field: "lastprice", removable: true },
      { title: "Additional", field: "addchargeprice", removable: true },
      { title: "Storage", field: "storageprice", removable: true },
      { title: "Payments", field: "customerpayment", removable: true },
      { title: "Balance", field: "clientbalance", removable: true },
      { title: "Last Job", field: "lastjob", removable: true },
    ];

    let loadingstatus;
    if (financialStore.loading) {
      loadingstatus = (
        <Button variant={"danger"} block>
          Please wait while the data loads
        </Button>
      );
    } else {
      loadingstatus = (
        <Button
          variant={this.state.show ? "dark" : "outline-dark"}
          block
          onClick={() => this.setState({ show: !this.state.show })}
        >
          {this.state.show ? "Hide" : "Show"} Outstanding
        </Button>
      );
    }

    return (
      <div>
        <JobModal
          show={jobSelectionStore.showModal}
          onHide={this.handleClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        />
        {loadingstatus}
        <hr />
        {this.state.show && (
          <div>
            <MaterialTable
              title={
                "Missing Payments " +
                financialStore.missingPayments.length +
                " Clients"
              }
              columns={columnnames}
              data={financialStore.missingPayments}
              paging={true}
              options={{
                padding: "dense",
                actionsColumnIndex: 0,
                exportAllData: true,
                paging: true,
                pageSize: 20,
                toolbarButtonAlignment: "left",
                tableLayout: "auto",
                exportButton: true,
                exportFileName: "Missing Payments",
                grouping: true,
                filtering: true,
              }}
              actions={[
                (rowData) => ({
                  icon: "edit",
                  tooltip: "Edit Item",
                  onClick: (event, rowData) => {
                    jobSelectionStore.fetchSingleJob(rowData.jobid);
                  },
                }),
              ]}
            />
            <hr />
            <MaterialTable
              title={
                "Missing Charges " +
                financialStore.freeofcostJobs.length +
                " Clients"
              }
              columns={columnnames}
              data={financialStore.freeofcostJobs}
              paging={true}
              options={{
                padding: "dense",
                actionsColumnIndex: 0,
                exportAllData: true,
                paging: true,
                pageSize: 20,
                toolbarButtonAlignment: "left",
                tableLayout: "auto",
                exportButton: true,
                exportFileName: "Missing Charges",
                grouping: true,
                filtering: true,
              }}
              actions={[
                (rowData) => ({
                  icon: "edit",
                  tooltip: "Edit Item",
                  onClick: (event, rowData) => {
                    jobSelectionStore.fetchSingleJob(rowData.jobid);
                  },
                }),
              ]}
            />
            <hr />
            <MaterialTable
              title={
                "Overpaid Clients " +
                financialStore.overPaid.length +
                " Clients"
              }
              columns={columnnames}
              data={financialStore.overPaid}
              paging={true}
              options={{
                padding: "dense",
                actionsColumnIndex: 0,
                exportAllData: true,
                paging: true,
                pageSize: 20,
                toolbarButtonAlignment: "left",
                tableLayout: "auto",
                exportButton: true,
                exportFileName: "Overpaid Clients",
                grouping: true,
                filtering: true,
              }}
              actions={[
                (rowData) => ({
                  icon: "edit",
                  tooltip: "Edit Item",
                  onClick: (event, rowData) => {
                    jobSelectionStore.fetchSingleJob(rowData.jobid);
                  },
                }),
              ]}
            />
            <hr />
            <MaterialTable
              title={
                "Closed Client Balance " +
                financialStore.closedBalance.length +
                " Clients"
              }
              columns={columnnames}
              data={financialStore.closedBalance}
              paging={true}
              options={{
                padding: "dense",
                actionsColumnIndex: 0,
                exportAllData: true,
                paging: true,
                pageSize: 20,
                toolbarButtonAlignment: "left",
                tableLayout: "auto",
                exportButton: true,
                exportFileName: "Closed Balances",
                grouping: true,
                filtering: true,
              }}
              actions={[
                (rowData) => ({
                  icon: "edit",
                  tooltip: "Edit Item",
                  onClick: (event, rowData) => {
                    jobSelectionStore.fetchSingleJob(rowData.jobid);
                  },
                }),
              ]}
            />
            <hr />
            <MaterialTable
              title={
                "2020 Full Clients List " +
                financialStore.allclientfinances.length +
                " Clients"
              }
              columns={columnnames}
              data={financialStore.allclientfinances}
              paging={true}
              options={{
                padding: "dense",
                actionsColumnIndex: 0,
                exportAllData: true,
                paging: true,
                pageSize: 20,
                toolbarButtonAlignment: "left",
                tableLayout: "auto",
                exportButton: true,
                exportFileName: "All Client Finances",
                grouping: true,
                filtering: true,
              }}
              actions={[
                (rowData) => ({
                  icon: "edit",
                  tooltip: "Edit Item",
                  onClick: (event, rowData) => {
                    jobSelectionStore.fetchSingleJob(rowData.jobid);
                  },
                }),
              ]}
            />
            <hr />
            <DynamicClientFinances />
          </div>
        )}

        <br />
        <br />
      </div>
    );
  }
}

export default observer(ClientsNotPaid);
