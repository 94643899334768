import React from "react";
// First way to import
import { observer } from "mobx-react";
import { Button, Row, Col } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import { Select } from "antd";
import { Input } from "antd";
import moment from "moment";

import { DatePicker } from "antd";
import editJobStore from "../../Stores/EditJobStore";

import "react-datepicker/dist/react-datepicker.css";
import ReceiptExport from "../../ProposalPics/ReceiptExport";
import userStore from "../../Stores/UserStore";

const { TextArea } = Input;
const { Option } = Select;

class PaymentRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      print: false,
    };
  }

  render() {
    let singlepayment = this.props.payment;
    let indexVal = this.props.indexVal;

    let category1list = [
      "Local Move",
      "International Move",
      "Storage",
      "Custom Clearance",
      "Express Delivery",
      "Other",
    ];
    let category2list = ["Income", "Expense Variable"];
    let category5list = [];

    if (singlepayment.category2 === "Income") {
      category5list = ["Customer Payment", "Other"];
    } else if (singlepayment.category2 === "Expense Variable") {
      category5list = [
        "Refund",
        "Damage",
        "Additional Materials",
        "Clearance Charge",
        "Insurance",
        "Shipping Expense",
        "Crating Expense",
        "Other",
      ];
    }

    let cat1list = category1list.map((cat, index) => (
      <Option key={index} value={cat}>
        {cat}
      </Option>
    ));
    let cat2list = category2list.map((cat, index) => (
      <Option key={index} value={cat}>
        {cat}
      </Option>
    ));
    let cat5list = category5list.map((cat, index) => (
      <Option key={index} value={cat}>
        {cat}
      </Option>
    ));

    let printReceipt;

    if (userStore.access > 3.5) {
      printReceipt = (
        <Button block disabled variant="dark">
          Show Receipt
        </Button>
      );
    } else if (singlepayment.id === 0) {
      printReceipt = (
        <Button block disabled variant="dark">
          Receipt available after Save
        </Button>
      );
    } else {
      printReceipt = (
        <Button
          block
          onClick={() => this.setState({ print: !this.state.print })}
          variant={this.state.print ? "primary" : "outline-primary"}
        >
          {this.state.print ? "Hide Receipt" : "Show Receipt"}
        </Button>
      );
    }

    return (
      <div>
        <Row>
          <Col md={3}>
            <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              size="small"
            >
              <b
                style={{
                  color: singlepayment.category2 === "" ? "red" : "black",
                }}
              >
                From Type:
              </b>
              <Select
                showSearch
                size="small"
                error
                width="100%"
                optionFilterProp="children"
                onChange={(e) =>
                  editJobStore.updatePayment("category2", e, indexVal)
                }
                value={singlepayment.category2}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {cat2list}
              </Select>
            </FormControl>
          </Col>
          <Col md={3}>
            <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              size="small"
            >
              <b
                style={{
                  color: singlepayment.category5 === "" ? "red" : "black",
                }}
              >
                Specific:
              </b>
              <Select
                showSearch
                size="small"
                error
                width="100%"
                optionFilterProp="children"
                onChange={(e) =>
                  editJobStore.updatePayment("category5", e, indexVal)
                }
                value={singlepayment.category5}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {cat5list}
              </Select>
            </FormControl>
          </Col>
          <Col md={3}>
            <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              size="small"
            >
              <b
                style={{
                  color: singlepayment.category1 === "" ? "red" : "black",
                }}
              >
                Department:
              </b>
              <Select
                showSearch
                size="small"
                error
                width="100%"
                optionFilterProp="children"
                onChange={(e) =>
                  editJobStore.updatePayment("category1", e, indexVal)
                }
                value={singlepayment.category1}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                {cat1list}
              </Select>
            </FormControl>
          </Col>
          <Col md={3}>
            <FormControl variant="outlined" style={{ width: "100%" }}>
              <b>Payment Date</b>
              <DatePicker
                size="small"
                format={"DD-MMM-YY"}
                onChange={(e) =>
                  editJobStore.updatePayment("paymentdate", e, indexVal)
                }
                defaultValue={
                  (singlepayment.paymentdate !== null) &
                    (singlepayment.paymentdate !== "") &&
                  moment(singlepayment.paymentdate)
                }
              />
            </FormControl>
          </Col>
        </Row>
        <p />
        <Row>
          <Col md={3}>
            <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              size="small"
            >
              <b
                style={{
                  color: singlepayment.paymentmethod === "" ? "red" : "black",
                }}
              >
                Payment Method:
              </b>
              <Select
                showSearch
                size="small"
                error
                width="100%"
                optionFilterProp="children"
                onChange={(e) =>
                  editJobStore.updatePayment("paymentmethod", e, indexVal)
                }
                value={singlepayment.paymentmethod}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value="Cash">Cash</Option>
                <Option value="KNET">KNET</Option>
                <Option value="Online Link">Online Link</Option>
                <Option value="Check">Check</Option>
                <Option value="Bank Transfer">Bank Transfer</Option>
              </Select>
            </FormControl>
          </Col>
          <Col md={3}>
            <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              size="small"
            >
              <b
                style={{
                  color: singlepayment.bankaccount === "" ? "red" : "black",
                }}
              >
                Bank Account:
              </b>
              <Select
                showSearch
                size="small"
                error
                width="100%"
                optionFilterProp="children"
                onChange={(e) =>
                  editJobStore.updatePayment("bankaccount", e, indexVal)
                }
                value={singlepayment.bankaccount}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value="Cash">Cash</Option>
                <Option value="Boubyan">Boubyan</Option>
                <Option value="NBK">NBK</Option>
                <Option value="NBK-Clearance">NBK-Clearance</Option>
                <Option value="KFH">KFH</Option>
              </Select>
            </FormControl>
          </Col>
          <Col md={3}>
            <TextField
              variant="outlined"
              size="small"
              type="number"
              label="Amount (KD)"
              style={{ width: "100%" }}
              value={singlepayment.invoiceamount}
              onChange={(e) =>
                editJobStore.updatePayment(
                  "invoiceamount",
                  e.target.value,
                  indexVal
                )
              }
            />
          </Col>
          <Col md={3}>
            <TextField
              variant="outlined"
              size="small"
              label="Invoice Number"
              style={{ width: "100%" }}
              value={singlepayment.invoicenumber}
              onChange={(e) =>
                editJobStore.updatePayment(
                  "invoicenumber",
                  e.target.value,
                  indexVal
                )
              }
            />
          </Col>
        </Row>
        <p />
        <Row>
          <Col md={3}>
            <Row>Delete</Row>
            <Row>
              <Button
                variant="outline-danger"
                block
                size="sm"
                onClick={() =>
                  editJobStore.updatePayment("delete", 0, indexVal)
                }
              >
                x
              </Button>
            </Row>
          </Col>
          <Col>
            <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              size="small"
            >
              <b>Remarks</b>
              <TextArea
                size="small"
                placeholder="Payment Remarks"
                autoSize
                value={singlepayment.remarks}
                onChange={(e) =>
                  editJobStore.updatePayment(
                    "remarks",
                    e.target.value,
                    indexVal
                  )
                }
              />
            </FormControl>
          </Col>
          <Col md={2}>{printReceipt}</Col>
        </Row>
        {this.state.print && <ReceiptExport payment={singlepayment} />}
        <hr />
      </div>
    );
  }
}

export default observer(PaymentRow);
