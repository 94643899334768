import React from "react";
import moment from "moment";
// First way to import
import { observer } from "mobx-react";
import { Button, Row, Col } from "react-bootstrap";
import inventoryStore from "../Stores/InventoryStore";

class JobUpdatePage3 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let activity = [];
    let exttruck = `${inventoryStore.exttruck} External Trucks`;
    let extcrew = `${inventoryStore.extcrew} External Crews Hired`;
    let extcarp = `${inventoryStore.extcarp} External Carpenters Hired`;
    if (inventoryStore.exttruck > 0) activity.push(exttruck);
    if (inventoryStore.extcrew > 0) activity.push(extcrew);
    if (inventoryStore.extcarp > 0) activity.push(extcarp);

    let activitystring = activity.map((activity, index) => (
      <div key={index}>
        {" "}
        {index + 1}) {activity}
        <br />
      </div>
    ));

    let materials = [];
    let bubble = `${inventoryStore.bubble} Meters of Bubble Used`;
    let embox = `${inventoryStore.embox} Easy Move Boxes Used`;
    let nylon = `${inventoryStore.nylon} Nylon Wraps Used`;
    let stretch = `${inventoryStore.stretch} Stretch Film Used`;
    let localtape = `${inventoryStore.localtape} Local Tape Used`;
    let shippingtape = `${inventoryStore.shippingtape} Shipping Tape Used`;

    if (inventoryStore.bubble > 0) materials.push(bubble);
    if (inventoryStore.embox > 0) materials.push(embox);
    if (inventoryStore.nylon > 0) materials.push(nylon);
    if (inventoryStore.stretch > 0) materials.push(stretch);
    if (inventoryStore.localtape > 0) materials.push(localtape);
    if (inventoryStore.shippingtape > 0) materials.push(shippingtape);

    let materialstring = materials.map((material, index) => (
      <div key={index}>
        {" "}
        {index + 1}) {material}
        <br />
      </div>
    ));

    return (
      <div>
        {inventoryStore.selectedjob.lastdate ===
          inventoryStore.selectedjob.start && (
          <h4>Total Job CBM = {inventoryStore.cbm}</h4>
        )}
        <h4>
          Summary for{" "}
          {moment(inventoryStore.selectedjob.start).format("DD-MMM-YYYY")}
        </h4>
        <h5>{inventoryStore.selectedjob.title}</h5>
        <hr />
        <b>Daily Activity:</b>
        <br />
        {inventoryStore.trip} Truck Trips
        <br />
        {activitystring}
        <br />
        Material Consumption:
        <br />
        {materialstring}
        <br />
        <Row>
          <Col>
            <Button
              block
              variant="danger"
              onClick={() => inventoryStore.changePage(2)}
            >
              Back
            </Button>
          </Col>
          <Col>
            <Button
              block
              variant="success"
              onClick={() => {
                inventoryStore.changePage(0);
                inventoryStore.postJobDate();
              }}
            >
              Submit
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default observer(JobUpdatePage3);
