import React, { Component } from "react";
import { observer } from "mobx-react";

import newEventStore from "../Stores/NewEventStore";
import settingStore from "../Stores/SettingStore";
import jobSelectionStore from "../Stores/JobSelectionStore";
import JobModal from "../FilterModules/JobModal";
import { Button } from "react-bootstrap";
import MaterialTable from "material-table";

import FilterModule from "../FilterModules/FilterModule";

class JobListPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      client: true,
      clientdetail: true,
      job: true,
      remarks: false,
      management: false,
      feedback: false,
      cancellation: false,
      update: true,
    };
    this.options = {
      defaultSortName: "id", // default sort column name
      // exportCSVBtn: this.createCustomExportCSVButton,
      defaultSortOrder: "asc", // default sort order
      onRowClick: function (job) {
        console.log(job);
        // newEventStore.selectJob(job.id);
      },
    };
  }

  componentDidMount() {}

  addColumn(item) {
    if (item === "Client") {
      this.setState({ client: !this.state.client });
    }
  }
  handleClose = () => jobSelectionStore.closeModal();

  render() {
    let columnnames = [{ title: "Job ID", field: "id", removable: true }];
    if (this.state.client) {
      columnnames.push({ title: "Client", field: "clienttitle" });
      columnnames.push({
        title: "Status",
        field: "status",
      });
      columnnames.push({ title: "Last Update", field: "lastupdate" });
    }
    if (this.state.clientdetail) {
      columnnames.push({ title: "Phone 1", field: "phone1" });
      columnnames.push({ title: "Phone 2", field: "phone1" });
      columnnames.push({ title: "Email", field: "email" });
    }

    if (this.state.job) {
      columnnames.push({
        title: "Main",
        field: "jobmain",
      });
      columnnames.push({ title: "CBM", field: "cbm" });
      columnnames.push({ title: "Price", field: "lastprice" });
      columnnames.push({ title: "Profit", field: "profit" });
    }
    if (this.state.remarks) {
      columnnames.push({ title: "Remarks", field: "remarks" });
    }

    if (this.state.lastupdate) {
      columnnames.push({ title: "Focal Point", field: "emfocalpoint" });
      columnnames.push({ title: "Urgency", field: "followupurgency" });
    }

    if (
      settingStore.newClient ||
      settingStore.viewClient ||
      settingStore.editJob
    ) {
      return <div />;
    }

    return (
      <div>
        <JobModal
          show={jobSelectionStore.showModal}
          onHide={this.handleClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        />
        <Button
          onClick={() => this.setState({ client: !this.state.client })}
          variant={this.state.client ? "dark" : "outline-dark"}
        >
          Client
        </Button>
        <Button
          onClick={() =>
            this.setState({ clientdetail: !this.state.clientdetail })
          }
          variant={this.state.clientdetail ? "dark" : "outline-dark"}
        >
          Client Detail
        </Button>
        <Button
          onClick={() => this.setState({ job: !this.state.job })}
          variant={this.state.job ? "dark" : "outline-dark"}
        >
          Job
        </Button>
        <Button
          onClick={() => this.setState({ remarks: !this.state.remarks })}
          variant={this.state.remarks ? "dark" : "outline-dark"}
        >
          Remarks
        </Button>
        <Button
          onClick={() => this.setState({ lastupdate: !this.state.lastupdate })}
          variant={this.state.lastupdate ? "dark" : "outline-dark"}
        >
          Last Update
        </Button>

        <hr />
        <FilterModule />

        <hr />
        <MaterialTable
          title={newEventStore.dashboardJobs.length + " Jobs"}
          columns={columnnames}
          data={newEventStore.dashboardJobs}
          paging={true}
          options={{
            actionsColumnIndex: 0,
            exportAllData: true,
            paging: true,
            pageSize: 25,
            pageSizeOptions: [5, 10, 15, 20, 25],
            toolbarButtonAlignment: "left",
            maxBodyHeight: 1000,
            tableLayout: "auto",
            exportButton: true,
            exportFileName: "Easy Move Jobs List",
            grouping: true,
            padding: "dense",
          }}
          actions={[
            (rowData) => ({
              icon: "edit",
              tooltip: "Edit Item",
              onClick: (event, rowData) => {
                jobSelectionStore.fetchSingleJob(rowData.id);
              },
            }),
          ]}
        />
        <br />
        <hr />
      </div>
    );
  }
}

export default observer(JobListPage);
