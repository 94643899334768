import React from "react";
// First way to import
import { observer } from "mobx-react";
// import ReactDataSheet from "react-datasheet";
// import "react-datasheet/lib/react-datasheet.css";

import { OutTable, ExcelRenderer } from "react-excel-renderer";

class TestExcelUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = { col: [], row: [], updated: false };
  }

  fileHandler = (event) => {
    let fileObj = event.target.files[0];
    //just pass the fileObj as parameter
    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        this.setState({
          cols: resp.cols,
          rows: resp.rows,
          updated: true,
        });
      }
    });
  };

  render() {
    let datagridshow;
    if (this.state.updated) {
      datagridshow = (
        <OutTable
          data={this.state.rows}
          columns={this.state.cols}
          tableClassName="ExcelTable2007"
          tableHeaderRowClass="heading"
        />
      );
    }
    return (
      <div>
        This is a test file.
        <div>
          <input
            type="file"
            onChange={this.fileHandler.bind(this)}
            style={{ padding: "10px" }}
          />
        </div>
        {datagridshow}
      </div>
    );
  }
}

export default observer(TestExcelUpload);
