import React, { Component } from "react";
import { observer } from "mobx-react";
import TextField from "@material-ui/core/TextField";

import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";

import editJobStore from "../Stores/EditJobStore";
import newEventStore from "../Stores/NewEventStore";

class ServicesUpdate extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let serviceboxes = newEventStore.includedServices.map((service, index) => (
      <FormControlLabel
        key={index}
        control={
          <Checkbox
            checked={service.included}
            onChange={(e) => editJobStore.changeService(service.id)}
            value="checkedA"
          />
        }
        label={service.title}
      />
    ));
    let excludedserviceboxes = newEventStore.excludedServicesCalc.map(
      (service, index) => (
        <FormControlLabel
          key={index}
          control={
            <Checkbox
              checked={service.included}
              onChange={(e) => editJobStore.changeExcludedService(service.id)}
              value="checkedA"
            />
          }
          label={service.title}
        />
      )
    );

    return (
      <div>
        <hr />
        Included Services:
        <TextField
          id="standard-size-small-full-width"
          fullWidth
          label="Additional Included"
          value={editJobStore.job.includeservice}
          onChange={(e) =>
            editJobStore.updateJob("includeservice", e.target.value)
          }
        />
        <FormGroup row>{serviceboxes}</FormGroup>
        <hr />
        Excluded Services:
        <TextField
          id="standard-size-small-full-width"
          fullWidth
          label="Additional Excluded"
          value={editJobStore.job.excludeservice}
          onChange={(e) =>
            editJobStore.updateJob("excludeservice", e.target.value)
          }
        />
        <FormGroup row>{excludedserviceboxes}</FormGroup>
        <hr />
      </div>
    );
  }
}

export default observer(ServicesUpdate);
