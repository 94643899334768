import React, { Component } from "react";
import { observer } from "mobx-react";
import moment from "moment";

import settingStore from "../Stores/SettingStore";
import jobEditStore from "../Stores/JobEditStore";
import userStore from "../Stores/UserStore";
import MaterialTable from "material-table";

class AttendanceSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTable: true
    };
    this.options = {
      defaultSortName: "id", // default sort column name
      // exportCSVBtn: this.createCustomExportCSVButton,
      defaultSortOrder: "asc", // default sort order
      onRowClick: function(job) {
        jobEditStore.selectJob(job.id);
      }
    };
  }

  componentDidMount() {
    userStore.fetchAttendance();
    console.log("Fetching Attendance");
  }

  render() {
    let columnnames = [
      { title: "ID", field: "id", removable: true, hidden: true },
      { title: "User", field: "name", removable: true },
      { title: "Time Ago", field: "timeago", removable: true },
      { title: "Date", field: "formatteddate", removable: true }
    ];

    return (
      <div>
        {!userStore.loading && (
          <MaterialTable
            title={
              moment(settingStore.startDate).format("MMM-YY") + " Payments"
            }
            columns={columnnames}
            data={userStore.attendance}
            paging={false}
            options={{
              paging: false,
              toolbarButtonAlignment: "left",
              tableLayout: "auto",
              padding: "dense",
              maxBodyHeight: 1000
            }}
          />
        )}
      </div>
    );
  }
}

export default observer(AttendanceSummary);
