import React from "react";
// First way to import
import { observer } from "mobx-react";
import { Container, Row, Col } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import { Select, Input } from "antd";

import editJobStore from "../../Stores/EditJobStore";

const { Option } = Select;
const { TextArea } = Input;

class ClientDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onChangeSelect(type, val) {
    editJobStore.updateJob(type, val);
  }

  onChangeAddCharge(type, val, index) {
    editJobStore.updateAddCharge(type, val, index);
  }

  render() {
    return (
      <div>
        <Container style={{ maxWidth: "98%" }}>
          <h3>Client Details</h3>
          <hr />
          <Row>
            <Col md={5}>
              <b
                style={{
                  color: editJobStore.client.title === "" ? "red" : "black",
                }}
              >
                Client Name
              </b>
              <TextField
                required
                variant="outlined"
                size="small"
                style={{ width: "100%" }}
                value={editJobStore.client.title}
                onChange={(e) =>
                  editJobStore.updateClient("title", e.target.value)
                }
              />
            </Col>
            <Col>
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                size="small"
              >
                <b
                  style={{
                    color:
                      editJobStore.client.leadtype === "" ? "red" : "black",
                  }}
                >
                  Lead Type
                </b>
                <Select
                  showSearch
                  error
                  width="100%"
                  placeholder="Lead Type"
                  optionFilterProp="children"
                  onChange={(e) => editJobStore.updateClient("leadtype", e)}
                  value={editJobStore.client.leadtype}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value={""}></Option>
                  <Option value={"Received Call"}>Received Call</Option>
                  <Option value={"Missed Call"}>Missed Call</Option>
                  <Option value={"Email Lead"}>Email Lead</Option>
                  <Option value={"Online Lead"}>Online Lead</Option>
                  <Option value={"Referral"}>Referral</Option>
                  <Option value={"Other"}>Other</Option>
                </Select>
              </FormControl>
            </Col>
            <Col>
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                size="small"
              >
                <b
                  style={{
                    color:
                      editJobStore.client.heardofus === "" ? "red" : "black",
                  }}
                >
                  Heard of Us
                </b>
                <Select
                  showSearch
                  error
                  width="100%"
                  placeholder="How They Heard of Us?"
                  optionFilterProp="children"
                  onChange={(e) => editJobStore.updateClient("heardofus", e)}
                  value={editJobStore.client.heardofus}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value={""}></Option>
                  <Option value={"Google"}>Google</Option>
                  <Option value={"Instagram"}>Instagram</Option>
                  <Option value={"Facebook"}>Facebook</Option>
                  <Option value={"Google Maps"}>Google Maps</Option>
                  <Option value={"Returning Customer"}>
                    Returning Customer
                  </Option>
                  <Option value={"Referrals"}>Referrals</Option>
                  <Option value={"Other"}>Other</Option>
                  <Option value={"Not Applicable"}>Not Applicable</Option>
                </Select>
              </FormControl>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <b
                style={{
                  color: editJobStore.client.phone1 === "" ? "red" : "black",
                }}
              >
                Phone 1
              </b>
              <TextField
                required
                variant="outlined"
                size="small"
                style={{ width: "100%" }}
                value={editJobStore.client.phone1}
                onChange={(e) =>
                  editJobStore.updateClient("phone1", e.target.value)
                }
              />
            </Col>
            <Col>
              <b
                style={{
                  color: editJobStore.client.phone2 === "" ? "red" : "black",
                }}
              >
                Phone 2
              </b>
              <TextField
                required
                variant="outlined"
                size="small"
                style={{ width: "100%" }}
                value={editJobStore.client.phone2}
                onChange={(e) =>
                  editJobStore.updateClient("phone2", e.target.value)
                }
              />
            </Col>
            <Col md={5}>
              <b
                style={{
                  color: editJobStore.client.email === "" ? "red" : "black",
                }}
              >
                Email
              </b>
              <TextField
                required
                variant="outlined"
                size="small"
                style={{ width: "100%" }}
                value={editJobStore.client.email}
                onChange={(e) =>
                  editJobStore.updateClient("email", e.target.value)
                }
              />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <b>Client Remarks:</b>
              <TextArea
                autoSize={true}
                style={{ width: "100%" }}
                value={editJobStore.client.remarks}
                onChange={(e) =>
                  editJobStore.updateClient("remarks", e.target.value)
                }
              />
            </Col>
          </Row>
          <hr />
        </Container>
      </div>
    );
  }
}

export default observer(ClientDetails);
