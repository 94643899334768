import { decorate, observable, computed } from "mobx";
import axios from "axios";
import moment from "moment";
import settingStore from "./SettingStore";
import eventStore from "./EventStore";
import userStore from "./UserStore";
import emailjs from "emailjs-com";

const instance = axios.create({
  // baseURL: "https://easymovecalendar.app/",
  // baseURL: "http://127.0.0.1:8000/",
  // baseURL: "http://157.230.113.108/",
  baseURL: "https://jazzafs.com/",
});

class JobEditStore {
  constructor() {
    this.job = {
      id: 0,
      remarks: " ",
    };
    this.oldstatus = "";
    this.internationalfee = {};
    this.storages = [];
    this.jobdates = [];
    this.addresses = [];
    this.payments = [];
    this.addcharge = [];
    this.intquote = [];
    this.rooms = [];
    this.items = [];
    this.imtracker = [];
    this.jobUpdate = false;
    this.addressUpdate = false;
    this.addchargeUpdate = false;
    this.jobdateUpdate = false;
    this.storageUpdate = false;
    this.paymentUpdate = false;
    this.roomUpdate = false;
    this.itemsUpdate = false;
    this.intQuoteUpdate = false;
    this.intFeeUpdate = false;
    this.imtrackerUpdate = false;
    this.selectedIMQuote = {
      companytype: "",
      company: "",
      price: 0,
      rating: "",
      remarks: "",
      job: this.job.id,
      id: 0,
    };
    this.customproposal = {
      title: "",
      body: "",
      cost: 0,
    };
  }

  updateCustomProposal(type, val) {
    if (type === "reset") {
      this.customproposal = {
        title: "",
        body: "",
        cost: 0,
      };
    } else if (type === "title") {
      this.customproposal.title = val;
    } else if (type === "body") {
      this.customproposal.body = val;
    } else if (type === "cost") {
      this.customproposal.cost = val;
    }
  }

  updateSelectedIMQuote(val, index, type) {
    if (type === "price") {
      this.selectedIMQuote.price = val;
    } else if (type === "rating") {
      this.selectedIMQuote.rating = val;
    } else if (type === "id") {
      this.selectedIMQuote.id = val;
    } else if (type === "remark") {
      this.selectedIMQuote.remark = val;
    } else if (type === "company") {
      this.selectedIMQuote.company = val;
    } else if (type === "companytype") {
      this.selectedIMQuote.companytype = val;
    } else if (type === "reset") {
      this.selectedIMQuote = {
        companytype: "",
        company: "",
        price: 0,
        rating: "",
        remarks: "",
        job: this.job.id,
        id: 0,
      };
    } else if (type === "update") {
      this.intquote[index] = this.selectedIMQuote;
      this.selectedIMQuote = {
        companytype: "",
        company: "",
        price: 0,
        rating: "",
        remarks: "",
        job: this.job.id,
        id: 0,
      };
    }
    this.intQuoteUpdate = true;
  }

  errorEmail(response, activity) {
    var template_params = {
      activitytype: activity,
      username: userStore.user.username,
      ClientID: this.job.client,
      JobID: this.job.id,
      consoleerror: JSON.stringify(response.data),
    };
    emailjs.send(
      "default_service",
      "error",
      template_params,
      "user_IaUVUsVD6VMlmBLY50c9p"
    );
  }

  resetPage() {
    this.job = { id: 0 };
    this.oldstatus = "";
    this.internationalfee = {
      thcharge: 0,
      docharge: 0,
      originclearance: 0,
      unloadingcharge: 0,
      unpackingcharge: 0,
      assemblingcharge: 0,
      debrisremoval: 0,
      containerreturn: 0,
      intwrapandpack: 0,
      shippingcharges: 0,
      transitclearance: 0,
      destinationclearance: 0,
      firstmilecharge: 0,
      lastmilecharge: 0,
      cratecharge: 0,
      palletcharge: 0,
      dgrcharge: 0,
      dghandling: 0,
      detentioncharge: 0,
      demoragecharge: 0,
      shippingline: 0,
      originstorageinout: 0,
      destinationstorageinout: 0,
      shuttlecharge: 0,
      containerType: "",
      shippingmethod: "",
      imlength: 0,
      imwidth: 0,
      imheight: 0,
      transportationcharges: 0,
      loadingcharge: 0,
      commodity: "",
      id: 0,
      job: this.job.id,
    };
    this.storages = [];
    this.jobdates = [];
    this.addresses = [];
    this.payments = [];
    this.addcharge = [];
    this.intquote = [];
    this.rooms = [];
    this.items = [];
    this.jobUpdate = false;
    this.addressUpdate = false;
    this.addchargeUpdate = false;
    this.jobdateUpdate = false;
    this.storageUpdate = false;
    this.paymentUpdate = false;
    this.roomUpdate = false;
    this.itemsUpdate = false;
    this.intQuoteUpdate = false;
    this.intFeeUpdate = false;
  }

  addJobDate() {
    this.jobdates.push({
      jobdate: moment(new Date()).format("YYYY-MM-DD"),
      status: "Pending",
      dateremarks: "",
      job: this.job.id,
      id: 0,
    });
    this.jobdateUpdate = true;
  }
  addPayment(type) {
    let department = "";
    if (this.job.jobmain === "Local Move") {
      department = "Local Move";
    } else if (this.job.jobmain === "International Move") {
      department = "International Move";
    } else if (this.job.jobmain === "Storage") {
      department = "Storage";
    } else if (this.job.jobmain === "Custom Clearance") {
      department = "Custom Clearance";
    } else if (this.job.jobmain === "Express Delivery") {
      department = "Express Delivery";
    } else if (this.job.jobmain === "Other") {
      department = "Other";
    }
    if (type === "Online Link") {
      this.payments.push({
        job: this.job.id,
        category1: department,
        category2: "Income",
        category3: "Cash",
        category4: "Job Related",
        category5: "Customer Payment",
        paymentmethod: "Online Link",
        bankaccount: "Boubyan",
        expenseheading: "No",
        id: 0,
        title: "",
      });
    } else if (type === "KNET") {
      this.payments.push({
        job: this.job.id,
        category1: department,
        category2: "Income",
        category3: "Cash",
        category4: "Job Related",
        category5: "Customer Payment",
        paymentmethod: "KNET",
        bankaccount: "Boubyan",
        expenseheading: "No",
        id: 0,
        title: "",
      });
    } else if (type === "Cash") {
      this.payments.push({
        job: this.job.id,
        category1: department,
        category2: "Income",
        category3: "Cash",
        category4: "Job Related",
        category5: "Customer Payment",
        paymentmethod: "Cash",
        bankaccount: "Cash",
        expenseheading: "No",
        id: 0,
        title: "",
      });
    } else if (type === "Refund") {
      this.payments.push({
        job: this.job.id,
        category1: department,
        category2: "Expense Variable",
        category3: "Cash",
        category4: "Job Related",
        category5: "Refund",
        paymentmethod: "Check",
        bankaccount: "KFH",
        expenseheading: "No",
        id: 0,
        title: "",
      });
    } else if (type === "Shipping Expense") {
      this.payments.push({
        job: this.job.id,
        category1: "International Move",
        category2: "Expense Variable",
        category3: "Cash",
        category4: "Job Related",
        category5: "Shipping Expense",
        paymentmethod: "Check",
        bankaccount: "KFH",
        expenseheading: "No",
        id: 0,
        title: "",
      });
    } else if (type === "Bank Charge") {
      this.payments.push({
        job: this.job.id,
        category1: department,
        category2: "Expense Variable",
        category3: "Cash",
        category4: "Bank Related",
        category5: "Bankk Charge",
        paymentmethod: "Bank Transfer",
        bankaccount: "Boubyan",
        expenseheading: "No",
        id: 0,
        title: "",
      });
    }

    this.paymentUpdate = true;
  }
  addIMTrack() {
    this.imtracker.push({
      job: this.job.id,
      id: 0,
      title: "",
      status: "Pending",
      remark: "",
      originaldate: null,
      reviseddate: null,
      order: 0,
    });
    this.paymentUpdate = true;
  }
  addIntQuote() {
    this.intquote.push({
      job: this.job.id,
      companytype: -1,
      company: "",
      rating: "",
      ratingshort: "",
      price: 0,
      remark: "",
      id: 0,
    });
    this.intQuoteUpdate = true;
  }

  addRoom(title) {
    this.rooms.push({
      title: title,
      item: [],
      id: 0,
      job: this.job.id,
    });
    this.roomUpdate = true;
  }
  addItem(data, roomindex) {
    this.rooms[roomindex].item.push({
      title: data.title,
      cbm: data.cbm,
      length: data.length,
      quantity: data.quantity,
      width: data.width,
      height: data.height,
      weight: data.weight,
      remark: data.remark,
      status: data.status,
      room: this.rooms[roomindex].id,
      id: 0,
    });
    this.roomUpdate = true;
  }
  updateItem(data, roomindex, itemindex) {
    this.rooms[roomindex].item[itemindex] = {
      title: data.title,
      cbm: data.cbm,
      length: data.length,
      quantity: data.quantity,
      width: data.width,
      height: data.height,
      weight: data.weight,
      remark: data.remark,
      status: data.status,
      room: this.rooms[roomindex].id,
      id: data.id,
    };
    this.roomUpdate = true;
  }

  postNewItem(data) {
    return instance
      .post("/c-item/", data)
      .then((res) => res.data)
      .then((events) => {
        console.log("item added");
      })
      .catch((err) => {
        this.errorEmail(err.response, "New Item");
        alert(
          "An error occurred. The data did NOT save. Please contact me (97666886)." +
            "\n" +
            JSON.stringify(err.response)
        );
        console.error(err.response);
      });
  }
  putUpdateItem(data) {
    return instance
      .put("/item/" + data.id + "/", data)
      .then((res) => res.data)
      .then((events) => {
        console.log("item updated");
      })
      .catch((err) => {
        this.errorEmail(err.response, "Update Item");
        alert(
          "An error occurred. The data did NOT save. Please contact me (97666886)." +
            "\n" +
            JSON.stringify(err.response)
        );
        console.error(err.response);
      });
  }

  deleteItem(data, index, roomindex) {
    if (data.id === 0) {
      jobEditStore.rooms[roomindex].item.splice(index, 1);
    } else {
      return instance
        .delete("/d-item/" + data.id + "/")
        .then((res) => res.data)
        .then((events) => {
          // console.log("successfully added");
          jobEditStore.rooms[roomindex].item.splice(index, 1);
          alert(`Item Deleted.`);
        })
        .catch((err) => {
          this.errorEmail(err.response, "Delete Item");
          alert(
            "An error occurred. The data did NOT save. Please contact me (97666886)." +
              "\n" +
              JSON.stringify(err.response)
          );
          console.error(err.response);
        });
    }
  }

  deleteQuote(data, index) {
    jobEditStore.intquote.splice(index, 1);
    if (data.id === 0) {
    } else {
      return instance
        .delete("/d-intquote/" + data.id + "/")
        .then((res) => res.data)
        .then((events) => {
          // console.log("successfully added");
          alert(`Quote Deleted.`);
        })
        .catch((err) => {
          this.errorEmail(err.response, "Delete Quote");
          alert(
            "An error occurred. The data did NOT save. Please contact me (97666886)." +
              "\n" +
              JSON.stringify(err.response)
          );
          console.error(err.response);
        });
    }
  }

  addStorage() {
    this.storages.push({
      job: this.job.id,
      id: 0,
      storageloc_t: "EM Abu Hulaifa",
      monthlycost: 0,
      storagecbm: 0,
      storagestatus: "Pending",
    });
    this.storageUpdate = true;
  }
  addAddCharge() {
    this.addcharge.push({
      price: 0,
      title: "",
      job: this.job.id,
      id: 0,
    });
    this.addchargeUpdate = true;
  }
  addAddress() {
    this.addresses.push({
      locationfrom: "",
      locationto: "",
      order: 0,
      typefrom: "",
      typeto: "",
      job: this.job.id,
      id: 0,
    });
    this.addressUpdate = true;
  }

  updateStorage(val, index, type) {
    if (settingStore.edit === false) {
      alert("Please enable edit to make changes.");
    } else {
      if (type === "storageloc_t") {
        this.storages[index].storageloc_t = val;
      } else if (type === "storagestartdate") {
        this.storages[index].storagestartdate = val;
      } else if (type === "storageenddate") {
        this.storages[index].storageenddate = val;
      } else if (type === "storagepaidto") {
        this.storages[index].storagepaidto = val;
      } else if (type === "storagestatus") {
        this.storages[index].storagestatus = val;
      } else if (type === "monthlycost") {
        this.storages[index].monthlycost = val;
      } else if (type === "storagecbm") {
        this.storages[index].storagecbm = val;
      }

      this.storageUpdate = true;
    }
  }
  updateJobDate(val, index, type) {
    if (settingStore.edit === false) {
      alert("Please enable edit to make changes.");
    } else {
      if (type === "jobdate") {
        this.jobdates[index].jobdate = val;
      } else if (type === "status") {
        this.jobdates[index].status = val;
      } else if (type === "dateremarks") {
        this.jobdates[index].dateremarks = val;
      }
      this.jobdateUpdate = true;
    }
  }

  updateAddCharge(val, index, type) {
    if (settingStore.edit === false) {
      alert("Please enable edit to make changes.");
    } else {
      if (type === "title") {
        this.addcharge[index].title = val;
      } else if (type === "price") {
        this.addcharge[index].price = val;
      }
      this.addchargeUpdate = true;
    }
  }
  updateIMTracker(e, index, type) {
    if (settingStore.edit === false) {
      alert("Please enable edit to make changes.");
    } else {
      if (type === "title") {
        this.imtracker[index].title = e.target.value;
      } else if (type === "status") {
        this.imtracker[index].status = e;
      } else if (type === "remark") {
        this.imtracker[index].remark = e.target.value;
      } else if (type === "originaldate") {
        this.imtracker[index].originaldate = moment(e).format();
        this.imtracker[index].reviseddate = moment(e).format();
      } else if (type === "reviseddate") {
        this.imtracker[index].reviseddate = moment(e).format();
      } else if (type === "order") {
        this.imtracker[index].order = e;
      }
      this.imtrackerUpdate = true;
    }
  }
  updateRoom(val, index, type) {
    if (settingStore.edit === false) {
      alert("Please enable edit to make changes.");
    } else {
      if (type === "title") {
        this.rooms[index].title = val;
      }
      this.roomUpdate = true;
    }
  }

  updateAddress(val, index, type) {
    if (settingStore.edit === false) {
      alert("Please enable edit to make changes.");
    } else {
      if (type === "typefrom") {
        this.addresses[index].typefrom = val;
      } else if (type === "typeto") {
        this.addresses[index].typeto = val;
      } else if (type === "order") {
        this.addresses[index].order = val * 1;
      } else if (type === "locationfrom") {
        this.addresses[index].locationfrom = val;
      } else if (type === "locationto") {
        this.addresses[index].locationto = val;
      }
      this.addressUpdate = true;
    }
  }
  updatePayment(val, index, type) {
    if (settingStore.edit === false) {
      alert("Please enable edit to make changes.");
    } else {
      if (type === "paymentdate") {
        this.payments[index].paymentdate = val;
      } else if (type === "category1") {
        this.payments[index].category1 = val;
      } else if (type === "category2") {
        this.payments[index].category2 = val;
      } else if (type === "category3") {
        this.payments[index].category3 = val;
      } else if (type === "category4") {
        this.payments[index].category4 = val;
      } else if (type === "category5") {
        this.payments[index].category5 = val;
      } else if (type === "paymenttype") {
        this.payments[index].paymenttype = val;
      } else if (type === "paymentdetail") {
        this.payments[index].paymentdetail = val;
      } else if (type === "bankaccount") {
        this.payments[index].bankaccount = val;
      } else if (type === "remarks") {
        this.payments[index].remarks = val;
      } else if (type === "paymentmethod") {
        this.payments[index].paymentmethod = val;
      } else if (type === "invoiceamount") {
        this.payments[index].invoiceamount = val;
      } else if (type === "invoicenumber") {
        this.payments[index].invoicenumber = val;
      } else if (type === "vouchernumber") {
        this.payments[index].vouchernumber = val;
      } else if (type === "expenseheading") {
        this.payments[index].expenseheading = val;
      }
      this.paymentUpdate = true;
    }
  }
  updateIntQuote(val, index, type) {
    if (settingStore.edit === false) {
      alert("Please enable edit to make changes.");
    } else {
      if (type === "price") {
        this.intquote[index].price = val;
      } else if (type === "rating") {
        this.intquote[index].rating = val;
      } else if (type === "remark") {
        this.intquote[index].remark = val;
      } else if (type === "company") {
        this.intquote[index].company = val;
      } else if (type === "companytype") {
        this.intquote[index].companytype = val;
      }
      this.paymentUpdate = true;
    }
  }

  updateIntFee(val, type) {
    if (settingStore.edit === false) {
      alert("Please enable edit to make changes.");
    } else {
      if (type === "thcharge") {
        this.internationalfee.thcharge = val;
      } else if (type === "docharge") {
        this.internationalfee.docharge = val;
      } else if (type === "originclearance") {
        this.internationalfee.originclearance = val;
      } else if (type === "unloadingcharge") {
        this.internationalfee.unloadingcharge = val;
      } else if (type === "assemblingcharge") {
        this.internationalfee.assemblingcharge = val;
      } else if (type === "debrisremoval") {
        this.internationalfee.debrisremoval = val;
      } else if (type === "containerreturn") {
        this.internationalfee.containerreturn = val;
      } else if (type === "intwrapandpack") {
        this.internationalfee.intwrapandpack = val;
      } else if (type === "shippingcharges") {
        this.internationalfee.shippingcharges = val;
      } else if (type === "transitclearance") {
        this.internationalfee.transitclearance = val;
      } else if (type === "destinationclearance") {
        this.internationalfee.destinationclearance = val;
      } else if (type === "firstmilecharge") {
        this.internationalfee.firstmilecharge = val;
      } else if (type === "lastmilecharge") {
        this.internationalfee.lastmilecharge = val;
      } else if (type === "cratecharge") {
        this.internationalfee.cratecharge = val;
      } else if (type === "palletcharge") {
        this.internationalfee.palletcharge = val;
      } else if (type === "dgrcharge") {
        this.internationalfee.dgrcharge = val;
      } else if (type === "dghandling") {
        this.internationalfee.dghandling = val;
      } else if (type === "detentioncharge") {
        this.internationalfee.detentioncharge = val;
      } else if (type === "demoragecharge") {
        this.internationalfee.demoragecharge = val;
      } else if (type === "shippingline") {
        this.internationalfee.shippingline = val;
      } else if (type === "originstorageinout") {
        this.internationalfee.originstorageinout = val;
      } else if (type === "destinationstorageinout") {
        this.internationalfee.destinationstorageinout = val;
      } else if (type === "shuttlecharge") {
        this.internationalfee.shuttlecharge = val;
      } else if (type === "containerType") {
        this.internationalfee.containerType = val;
      } else if (type === "shippingmethod") {
        this.internationalfee.shippingmethod = val;
      } else if (type === "imlength") {
        this.internationalfee.imlength = val;
      } else if (type === "imwidth") {
        this.internationalfee.imwidth = val;
      } else if (type === "imheight") {
        this.internationalfee.imheight = val;
      } else if (type === "transportationcharges") {
        this.internationalfee.transportationcharges = val;
      } else if (type === "loadingcharge") {
        this.internationalfee.loadingcharge = val;
      } else if (type === "commodity") {
        this.internationalfee.commodity = val;
      }
      this.intFeeUpdate = true;
    }
  }

  updateJobDetails(val, type) {
    if (settingStore.edit === false) {
      alert("Please enable edit to make changes.");
    } else {
      if (type === "status") {
        console.log(val);
        this.job.status = val;
        if (val === "Lead") {
          this.job.leaddate = moment(new Date()).format("YY-MM-DD");
        } else if (val === "Proposal") {
          this.job.proposaldate = moment(new Date()).format("YY-MM-DD");
        } else if (val === "Booked") {
          this.job.bookeddate = moment(new Date()).format("YY-MM-DD");
        } else if (val === "Converted") {
          this.job.converteddate = moment(new Date()).format("YY-MM-DD");
        }
      } else if (type === "estimationdate") {
        this.job.estimationdate = moment(val).format("YY-MM-DD");
      } else if (type === "feedback") {
        this.job.feedback = val;
      } else if (type === "rating") {
        this.job.rating = val;
      } else if (type === "remarks") {
        this.job.remarks = val;
      } else if (type === "cancellationreason") {
        this.job.cancellationreason = val;
      } else if (type === "cancellationremarks") {
        this.job.cancellationremarks = val;
      } else if (type === "discountvalue") {
        this.job.discountvalue = val;
      } else if (type === "discountname") {
        this.job.discountname = val;
      } else if (type === "companygroup") {
        this.job.companygroup = val;
      } else if (type === "firstprice") {
        this.job.firstprice = val;
      } else if (type === "lastprice") {
        this.job.lastprice = val;
      } else if (type === "jobmain") {
        this.job.jobmain = val;
      } else if (type === "jobsecond") {
        this.job.jobsecond = val;
      } else if (type === "cbm") {
        this.job.cbm = val;
      } else if (type === "weight") {
        this.job.weight = val;
      } else if (type === "teamLeader") {
        this.job.teamLeader = val;
      } else if (type === "estCheck") {
        this.job.estCheck = val;
      } else if (type === "cancCheck") {
        this.job.cancCheck = val;
      } else if (type === "proposalsentcheck") {
        this.job.proposalsentcheck = val;
      } else if (type === "estimationType") {
        this.job.estimationType = val;
      } else if (type === "service") {
        this.job.service = val;
      } else if (type === "actualcbm") {
        this.job.actualcbm = val;
      } else if (type === "actualweight") {
        this.job.actualweight = val;
      } else if (type === "teammember") {
        this.job.teammember = val;
      } else if (type === "emfocalpoint") {
        this.job.emfocalpoint = val;
      } else if (type === "followupurgency") {
        this.job.followupurgency = val;
      } else if (type === "includeservice") {
        this.job.includeservice = val;
      } else if (type === "excludeservice") {
        this.job.excludeservice = val;
      }
      this.jobUpdate = true;
    }
  }

  postUpdate() {
    let newjob = false;
    // if (this.jobUpdate) {
    let data = {
      status: this.job.status,
      leaddate: null,
      proposaldate: null,
      bookeddate: null,
      converteddate: null,
      estimationdate: null,
      feedback: this.job.feedback,
      rating: this.job.rating,
      remarks: this.job.remarks,
      cancellationremarks: this.job.cancellationremarks,
      cancellationreason: this.job.cancellationreason,
      discountvalue: this.job.discountvalue,
      discountname: this.job.discountname,
      companygroup: this.job.companygroup,
      firstprice: this.job.firstprice,
      lastprice: this.job.lastprice,
      jobmain: this.job.jobmain,
      jobsecond: this.job.jobsecond,
      cbm: this.job.cbm,
      weight: this.job.weight,
      teamLeader: this.job.teamLeader,
      estCheck: this.job.estCheck,
      cancCheck: this.job.cancCheck,
      proposalsentcheck: this.job.proposalsentcheck,
      estimationType: this.job.estimationType,
      service: this.job.service,
      actualcbm: this.job.actualcbm,
      actualweight: this.job.actualweight,
      teammember: this.job.teammember,
      emfocalpoint: this.job.emfocalpoint,
      followupurgency: this.job.followupurgency,
      serviceslist: this.job.serviceslist,
      excludedserviceslist: this.job.excludedserviceslist,
      includeservice: this.job.includeservice,
      excludeservice: this.job.excludeservice,
      lastupdate: moment(new Date()).format("YYYY-MM-DD"),
      userupdated: userStore.user.username,
      qualityscore: this.fullQualityScore,
      qualityremark: this.fullQualityRemark,

      id: this.job.id,
      // client: this.job.client,
    };

    if (this.job.firstprice > 0 && this.job.lastprice === 0) {
      this.job.lastprice = this.job.firstprice;
    }
    if (this.job.lastprice > 0 && this.job.firstprice === 0) {
      this.job.firstprice = this.job.lastprice;
    }

    if (this.job.leaddate !== null) {
      data.leaddate = moment(this.job.leaddate).format("YYYY-MM-DD");
    }
    if (this.job.proposaldate !== null) {
      data.proposaldate = moment(this.job.proposaldate).format("YYYY-MM-DD");
    }
    if (this.job.bookeddate !== null) {
      data.bookeddate = moment(this.job.bookeddate).format("YYYY-MM-DD");
    }
    if (this.job.estimationdate !== null) {
      data.estimationdate = moment(this.job.estimationdate).format(
        "YYYY-MM-DD"
      );
    }
    if (this.job.converteddate !== null) {
      data.converteddate = moment(this.job.converteddate).format("YYYY-MM-DD");
    }
    if (this.job.cancellationdate !== null) {
      data.cancellationdate = moment(this.job.cancellationdate).format(
        "YYYY-MM-DD"
      );
    }

    if (this.job.id === 0) {
      data.client = eventStore.editClient.id;
      instance
        .post("/c-job/", data)
        .then((res) => {
          let events = res.data;
          console.log(res.data);
          // eventStore.addNewJob(events);
          // eventStore.addJobToClient(events);
          this.job = events;
          alert(`New Job #${events.id} Created.`);
        })
        .then((events) => {
          newjob = true;
        })
        .catch((err) => {
          console.error(err);
          console.error(err.response);
          this.errorEmail(err.response, "New Job");
          alert(
            "An error occurred. The data did NOT save. Please contact me (97666886)." +
              "\n" +
              JSON.stringify(err.response)
          );
        });
    } else {
      instance
        .put("/job/" + this.job.id + "/", data)
        .then((res) => res.data)
        .then((events) => {
          // console.log("successfully added");
          alert(`Job #${events.id} Details Updated.`);
        })
        .catch((err) => {
          this.errorEmail(err.response, "Update Job");
          alert(
            "An error occurred. The data did NOT save. Please contact me (97666886)." +
              "\n" +
              JSON.stringify(err.response)
          );
          console.error(err.response);
        });
    }

    if (this.job.status === "Booked" || this.job.status === "Cancelled") {
      if (this.job.status !== this.oldstatus) {
        let template_params;
        if (this.job.status === "Booked") {
          template_params = {
            clientID: this.job.client,
            client: eventStore.editClient.title,
            Activity: this.job.status,
            jobtype: this.job.jobmain,
            username: userStore.user.username,
            activitymain: this.job.lastprice,
            activitydetail: this.job.remarks,
          };
        } else if (this.job.status === "Cancelled") {
          template_params = {
            clientID: this.job.client,
            client: eventStore.editClient.title,
            Activity: this.job.status,
            jobtype: this.job.jobmain,
            username: userStore.user.username,
            activitymain: this.job.cancellationreason,
            activitydetail: this.job.cancellationremarks,
          };
        }

        var service_id = "default_service";
        var template_id = "template_MVq3u0Ub";
        emailjs.send(
          service_id,
          template_id,
          template_params,
          "user_IaUVUsVD6VMlmBLY50c9p"
        );
      }
    }
    // }
    if (this.addressUpdate) {
      if (this.addresses.length > 0) {
        for (let i = 0; i < this.addresses.length; i++) {
          let data2 = {
            locationfrom: this.addresses[i].locationfrom,
            locationto: this.addresses[i].locationto,
            order: this.addresses[i].order,
            typefrom: this.addresses[i].typefrom,
            typeto: this.addresses[i].typeto,
            job: this.addresses[i].job,
          };
          if (this.addresses[i].id === 0) {
            this.postAddressUpdate(data2, this.addresses[i].id, "new", i);
          } else {
            this.postAddressUpdate(data2, this.addresses[i].id, "update", i);
          }
        }
      }
    }
    if (this.addchargeUpdate) {
      if (this.addcharge.length > 0) {
        for (let i = 0; i < this.addcharge.length; i++) {
          let data3 = {
            title: this.addcharge[i].title,
            price: this.addcharge[i].price,
            job: this.addcharge[i].job,
          };
          if (this.addcharge[i].id === 0) {
            this.postAddChargeUpdate(data3, this.addcharge[i].id, "new", i);
          } else {
            this.postAddChargeUpdate(data3, this.addcharge[i].id, "update", i);
          }
        }
      }
    }
    if (this.imtrackerUpdate) {
      if (this.imtracker.length > 0) {
        for (let i = 0; i < this.imtracker.length; i++) {
          let data3 = {
            title: this.imtracker[i].title,
            status: this.imtracker[i].status,
            remark: this.imtracker[i].remark,
            order: this.imtracker[i].order,
            job: this.imtracker[i].job,
          };

          if (this.imtracker[i].reviseddate) {
            data3.reviseddate = moment(this.imtracker[i].reviseddate).format(
              "YYYY-MM-DD"
            );
          }
          if (this.imtracker[i].originaldate) {
            data3.originaldate = moment(this.imtracker[i].originaldate).format(
              "YYYY-MM-DD"
            );
          }

          if (this.imtracker[i].id === 0) {
            this.postIMTracker(data3, this.imtracker[i].id, "new", i);
          } else {
            this.postIMTracker(data3, this.imtracker[i].id, "update", i);
          }
        }
      }
    }
    if (this.roomUpdate) {
      if (this.rooms.length > 0) {
        for (let i = 0; i < this.rooms.length; i++) {
          let data3 = {
            title: this.rooms[i].title,
            item: this.rooms[i].item,
            job: this.rooms[i].job,
            id: this.rooms[i].id,
          };
          if (this.rooms[i].id === 0) {
            this.postRoomUpdate(data3, this.rooms[i].id, "new", i);
          } else {
            this.postRoomUpdate(data3, this.rooms[i].id, "update", i);
          }
        }
      }
    }
    if (this.paymentUpdate) {
      if (this.payments.length > 0) {
        for (let i = 0; i < this.payments.length; i++) {
          let data4 = {
            paymentdate: moment(this.payments[i].paymentdate).format(
              "YYYY-MM-DD"
            ),
            category1: this.payments[i].category1,
            category2: this.payments[i].category2,
            category3: this.payments[i].category3,
            category4: this.payments[i].category4,
            category5: this.payments[i].category5,
            paymenttype: this.payments[i].paymenttype,
            paymentdetail: this.payments[i].paymentdetail,
            bankaccount: this.payments[i].bankaccount,
            remarks: this.payments[i].remarks,
            paymentmethod: this.payments[i].paymentmethod,
            invoicenumber: this.payments[i].invoicenumber,
            vouchernumber: this.payments[i].vouchernumber,
            invoiceamount: this.payments[i].invoiceamount,
            expenseheading: this.payments[i].expenseheading,
            job: this.payments[i].job,
          };
          if (this.payments[i].id === 0) {
            this.postPaymentUpdate(data4, this.payments[i].id, "new", i);
          } else {
            this.postPaymentUpdate(data4, this.payments[i].id, "update", i);
          }
        }
      }
    }

    if (this.jobdateUpdate) {
      if (this.jobdates.length > 0) {
        for (let i = 0; i < this.jobdates.length; i++) {
          let data5 = {
            jobdate: this.jobdates[i].jobdate,
            dateremarks: this.jobdates[i].dateremarks,
            status: this.jobdates[i].status,
            job: this.jobdates[i].job,
          };
          if (this.jobdates[i].id === 0) {
            this.postJobDateUpdate(data5, this.jobdates[i].id, "new", i);
          } else {
            this.postJobDateUpdate(data5, this.jobdates[i].id, "update", i);
          }
        }
      }
    }
    if (this.storageUpdate) {
      if (this.storages.length > 0) {
        for (let i = 0; i < this.storages.length; i++) {
          let data6 = {
            storageloc_t: this.storages[i].storageloc_t,
            storagestartdate: moment(this.storages[i].storagestartdate).format(
              "YYYY-MM-DD"
            ),
            storageenddate: moment(this.storages[i].storageenddate).format(
              "YYYY-MM-DD"
            ),
            storagepaidto: moment(this.storages[i].storagepaidto).format(
              "YYYY-MM-DD"
            ),
            storagestatus: this.storages[i].storagestatus,
            monthlycost: this.storages[i].monthlycost,
            storagecbm: this.storages[i].storagecbm,
            job: this.storages[i].job,
          };
          if (this.storages[i].id === 0) {
            this.postStorageUpdate(data6, this.storages[i].id, "new", i);
          } else {
            this.postStorageUpdate(data6, this.storages[i].id, "update", i);
          }
        }
      }
    }
    if (this.intFeeUpdate) {
      let data7 = {
        thcharge: this.internationalfee.thcharge,
        docharge: this.internationalfee.docharge,
        originclearance: this.internationalfee.originclearance,
        unloadingcharge: this.internationalfee.unloadingcharge,
        unpackingcharge: this.internationalfee.unpackingcharge,
        assemblingcharge: this.internationalfee.assemblingcharge,
        debrisremoval: this.internationalfee.debrisremoval,
        containerreturn: this.internationalfee.containerreturn,
        intwrapandpack: this.internationalfee.intwrapandpack,
        shippingcharges: this.internationalfee.shippingcharges,
        transitclearance: this.internationalfee.transitclearance,
        destinationclearance: this.internationalfee.destinationclearance,
        firstmilecharge: this.internationalfee.firstmilecharge,
        lastmilecharge: this.internationalfee.lastmilecharge,
        cratecharge: this.internationalfee.cratecharge,
        palletcharge: this.internationalfee.palletcharge,
        dgrcharge: this.internationalfee.dgrcharge,
        dghandling: this.internationalfee.dghandling,
        detentioncharge: this.internationalfee.detentioncharge,
        demoragecharge: this.internationalfee.demoragecharge,
        shippingline: this.internationalfee.shippingline,
        originstorageinout: this.internationalfee.originstorageinout,
        destinationstorageinout: this.internationalfee.destinationstorageinout,
        shuttlecharge: this.internationalfee.shuttlecharge,
        containerType: this.internationalfee.containerType,
        shippingmethod: this.internationalfee.shippingmethod,
        imlength: this.internationalfee.imlength,
        imwidth: this.internationalfee.imwidth,
        imheight: this.internationalfee.imheight,
        transportationcharges: this.internationalfee.transportationcharges,
        loadingcharge: this.internationalfee.loadingcharge,
        commodity: this.internationalfee.commodity,
        job: this.internationalfee.job,
      };
      if (this.internationalfee.id === 0) {
        this.postIntFeeUpdate(data7, this.internationalfee.id, "new", 0);
      } else {
        this.postIntFeeUpdate(data7, this.internationalfee.id, "update", 0);
      }
      eventStore.resetPage();
      eventStore.fetchAllClients();
    }

    if (this.intQuoteUpdate) {
      if (this.intquote.length > 0) {
        for (let i = 0; i < this.intquote.length; i++) {
          let data8 = {
            price: this.intquote[i].price,
            rating: this.intquote[i].rating,
            remark: this.intquote[i].remark,
            company: this.intquote[i].company,
            companytype: this.intquote[i].companytype,
            job: this.intquote[i].job,
            id: this.intquote[i].id,
          };
          if (this.intquote[i].id === 0) {
            this.postIntQuote(data8, this.intquote[i].id, "new", i);
          } else {
            this.postIntQuote(data8, this.intquote[i].id, "update", i);
          }
        }
      }
    }
    if (newjob === false) {
      settingStore.changeEditJob(false);
      eventStore.fetchAllClients();
      this.fetchAll(this.job.id);
      this.resetPage();
    } else {
      eventStore.selectClient(this.job.client);
    }
  }

  postAddressUpdate(data, id, type, index) {
    if (type === "new") {
      return instance
        .post("/c-address/", data)
        .then((res) => res.data)
        .then((events) => {
          // console.log("successfully added");
          alert(`Address Created.`);
        })
        .catch((err) => {
          this.errorEmail(err.response, "New Address");
          alert(
            "An error occurred. The data did NOT save. Please contact me (97666886)." +
              "\n" +
              JSON.stringify(err.response)
          );
          console.error(err.response);
        });
    } else if (type === "update") {
      return instance
        .put("/address/" + id + "/", data)
        .then((res) => res.data)
        .then((events) => {
          // console.log("successfully added");
          alert(`Address #${id}  Updated.`);
        })
        .catch((err) => {
          this.errorEmail(err.response, "Update Address");
          alert(
            "An error occurred. The data did NOT save. Please contact me (97666886)." +
              "\n" +
              JSON.stringify(err.response)
          );
          console.error(err.response);
        });
    } else if (type === "destroy") {
      if (id > 0) {
        return instance
          .delete("/d-address/" + id + "/")
          .then((res) => res.data)
          .then((events) => {
            // console.log("successfully added");
            this.addresses.splice(index, 1);
            alert(`Address Deleted.`);
          })
          .catch((err) => {
            this.errorEmail(err.response, "Delete Address");
            alert(
              "An error occurred. The data did NOT save. Please contact me (97666886)." +
                "\n" +
                JSON.stringify(err.response)
            );
            console.error(err.response);
          });
      } else {
        this.addresses.splice(index, 1);
      }
    }
  }

  postRoomUpdate(data, id, type, index) {
    if (type === "new") {
      return instance
        .post("/c-room/", data)
        .then((res) => res.data)
        .then((events) => {
          if (data.item.length > 0) {
            for (let i = 0; i < data.item.length; i++) {
              let data2 = {
                title: data.item[i].title,
                cbm: data.item[i].cbm,
                length: data.item[i].length,
                quantity: data.item[i].quantity,
                width: data.item[i].width,
                height: data.item[i].height,
                weight: data.item[i].weight,
                remark: data.item[i].remark,
                status: data.item[i].status,
                room: events.id,
              };
              this.postNewItem(data2);
            }
          }

          alert(`Room Created.`);
        })
        .catch((err) => {
          this.errorEmail(err.response, "New Room");
          alert(
            "An error occurred. The data did NOT save. Please contact me (97666886)." +
              "\n" +
              JSON.stringify(err.response)
          );
          console.error(err.response);
        });
    } else if (type === "update") {
      return instance
        .put("/room/" + id + "/", data)
        .then((res) => res.data)
        .then((events) => {
          if (data.item.length > 0) {
            for (let i = 0; i < data.item.length; i++) {
              let data2 = {
                title: data.item[i].title,
                cbm: data.item[i].cbm,
                length: data.item[i].length,
                quantity: data.item[i].quantity,
                width: data.item[i].width,
                height: data.item[i].height,
                weight: data.item[i].weight,
                remark: data.item[i].remark,
                status: data.item[i].status,
                room: id,
                id: data.item[i].id,
              };
              if (data2.id === 0) {
                this.postNewItem(data2);
              } else {
                this.putUpdateItem(data2);
              }
            }
          }

          alert(`Room #${id}  Updated.`);
        })
        .catch((err) => {
          this.errorEmail(err.response, "Update Room");
          alert(
            "An error occurred. The data did NOT save. Please contact me (97666886)." +
              "\n" +
              JSON.stringify(err.response)
          );
          console.error(err.response);
        });
    } else if (type === "destroy") {
      if (id > 0) {
        console.log("destroy");
        return instance
          .delete("/d-room/" + id + "/")
          .then((res) => res.data)
          .then((events) => {
            // console.log("successfully added");
            this.rooms.splice(index, 1);
            alert(`Room Deleted.`);
          })
          .catch((err) => {
            this.errorEmail(err.response, "Delete Room");
            alert(
              "An error occurred. The data did NOT save. Please contact me (97666886)." +
                "\n" +
                JSON.stringify(err.response)
            );
            console.error(err.response);
          });
      } else {
        this.rooms.splice(index, 1);
      }
    }
  }

  postAddChargeUpdate(data, id, type, index) {
    if (type === "new") {
      return instance
        .post("/c-addcharge/", data)
        .then((res) => res.data)
        .then((events) => {
          alert(`Additional Charge Created.`);
        })
        .catch((err) => {
          this.errorEmail(err.response, "New Additional Charge");
          alert(
            "An error occurred. The data did NOT save. Please contact me (97666886)." +
              "\n" +
              JSON.stringify(err.response)
          );
          console.error(err.response);
        });
    } else if (type === "update") {
      return instance
        .put("/addcharge/" + id + "/", data)
        .then((res) => res.data)
        .then((events) => {
          alert(`Additional Charge #${id}  Updated.`);
        })
        .catch((err) => {
          this.errorEmail(err.response, "Update Additional Charge");
          alert(
            "An error occurred. The data did NOT save. Please contact me (97666886)." +
              "\n" +
              JSON.stringify(err.response)
          );
          console.error(err.response);
        });
    } else if (type === "destroy") {
      if (id > 0) {
        return instance
          .delete("/d-addcharge/" + id + "/")
          .then((res) => res.data)
          .then((events) => {
            // console.log("successfully added");
            this.addcharge.splice(index, 1);
            alert(`Additional Charge Deleted.`);
          })
          .catch((err) => {
            this.errorEmail(err.response, "Delete Additional Charge");
            alert(
              "An error occurred. The data did NOT save. Please contact me (97666886)." +
                "\n" +
                JSON.stringify(err.response)
            );
            console.error(err.response);
          });
      } else {
        this.addcharge.splice(index, 1);
      }
    }
  }
  postIMTracker(data, id, type, index) {
    if (type === "new") {
      return instance
        .post("/c-imtracker/", data)
        .then((res) => res.data)
        .then((events) => {
          alert(`IM Track Created.`);
        })
        .catch((err) => {
          this.errorEmail(err.response, "New IM Tracker");
          alert(
            "An error occurred. The data did NOT save. Please contact me (97666886)." +
              "\n" +
              JSON.stringify(err.response)
          );
          console.error(err.response);
        });
    } else if (type === "update") {
      return instance
        .put("/imtracker/" + id + "/", data)
        .then((res) => res.data)
        .then((events) => {
          alert(`IM Track #${id}  Updated.`);
        })
        .catch((err) => {
          this.errorEmail(err.response, "Update IM Tracker");
          alert(
            "An error occurred. The data did NOT save. Please contact me (97666886)." +
              "\n" +
              JSON.stringify(err.response)
          );
          console.error(err.response);
        });
    } else if (type === "destroy") {
      if (id > 0) {
        return instance
          .delete("/d-imtracker/" + id + "/")
          .then((res) => res.data)
          .then((events) => {
            // console.log("successfully added");
            this.imtracker.splice(index, 1);
            alert(`IM Track Deleted.`);
          })
          .catch((err) => {
            this.errorEmail(err.response, "Delete IM Tracker");
            alert(
              "An error occurred. The data did NOT save. Please contact me (97666886)." +
                "\n" +
                JSON.stringify(err.response)
            );
            console.error(err.response);
          });
      } else {
        this.imtracker.splice(index, 1);
      }
    }
  }
  postPaymentUpdate(data, id, type, index) {
    if (type === "new") {
      return instance
        .post("/c-payment/", data)
        .then((res) => res.data)
        .then((events) => {
          alert(`Payment Created.`);
        })
        .catch((err) => {
          this.errorEmail(err.response, "New Payment");
          alert(
            "An error occurred. The data did NOT save. Please contact me (97666886)." +
              "\n" +
              JSON.stringify(err.response)
          );
          console.error(err.response);
        });
    } else if (type === "update") {
      return instance
        .put("/payment/" + id + "/", data)
        .then((res) => res.data)
        .then((events) => {
          alert(`Payment #${id}  Updated.`);
        })
        .catch((err) => {
          this.errorEmail(err.response, "Update Payment");
          alert(
            "An error occurred. The data did NOT save. Please contact me (97666886)." +
              "\n" +
              JSON.stringify(err.response)
          );
          console.error(err.response);
        });
    } else if (type === "destroy") {
      if (id > 0) {
        return instance
          .delete("/d-payment/" + id + "/")
          .then((res) => res.data)
          .then((events) => {
            // console.log("successfully added");
            this.payments.splice(index, 1);
            alert(`Payment Record Deleted.`);
          })
          .catch((err) => {
            this.errorEmail(err.response, "Delete Payment");
            alert(
              "An error occurred. The data did NOT save. Please contact me (97666886)." +
                "\n" +
                JSON.stringify(err.response)
            );
            console.error(err.response);
          });
      } else {
        this.payments.splice(index, 1);
      }
    }
  }
  postIntFeeUpdate(data, id, type, index) {
    if (type === "new") {
      return instance
        .post("/c-intfee/", data)
        .then((res) => res.data)
        .then((events) => {
          alert(`International Fee Created.`);
        })
        .catch((err) => {
          this.errorEmail(err.response, "New International Fee");
          alert(
            "An error occurred. The data did NOT save. Please contact me (97666886)." +
              "\n" +
              JSON.stringify(err.response)
          );
          console.error(err.response);
        });
    } else if (type === "update") {
      return instance
        .put("/intfee/" + id + "/", data)
        .then((res) => res.data)
        .then((events) => {
          alert(`International Fee #${id}  Updated.`);
        })
        .catch((err) => {
          this.errorEmail(err.response, "Update International Fee");
          alert(
            "An error occurred. The data did NOT save. Please contact me (97666886)." +
              "\n" +
              JSON.stringify(err.response)
          );
          console.error(err.response);
        });
    } else if (type === "destroy") {
      if (id > 0) {
        return instance
          .delete("/d-intfee/" + id + "/")
          .then((res) => res.data)
          .then((events) => {
            // console.log("successfully added");
            this.payments.splice(index, 1);
            alert(`International Fee Record Deleted.`);
          })
          .catch((err) => {
            this.errorEmail(err.response, "Delete International Fee");
            alert(
              "An error occurred. The data did NOT save. Please contact me (97666886)." +
                "\n" +
                JSON.stringify(err.response)
            );
            console.error(err.response);
          });
      } else {
        this.internationalfee.splice(index, 1);
      }
    }
  }

  changeService(id) {
    let indexval = this.job.serviceslist.findIndex(
      (service) => parseFloat(service) === parseFloat(id)
    );

    if (indexval >= 0) {
      this.job.serviceslist.splice(indexval, 1);
    } else {
      this.job.serviceslist.push(id);
    }
    this.jobUpdate = true;
  }
  changeExcludedService(id) {
    let indexval = this.job.excludedserviceslist.findIndex(
      (service) => parseFloat(service) === parseFloat(id)
    );

    if (indexval >= 0) {
      this.job.excludedserviceslist.splice(indexval, 1);
    } else {
      this.job.excludedserviceslist.push(id);
    }
    this.jobUpdate = true;
  }

  postJobDateUpdate(data, id, type, index) {
    if (type === "new") {
      return instance
        .post("/c-jobdate/", data)
        .then((res) => res.data)
        .then((events) => {
          alert(`New Move Date Created.`);
        })
        .catch((err) => {
          this.errorEmail(err.response, "New Job Date");
          alert(
            "An error occurred. The data did NOT save. Please contact me (97666886)." +
              "\n" +
              JSON.stringify(err.response)
          );
          console.error(err.response);
        });
    } else if (type === "update") {
      return instance
        .put("/jobdate/" + id + "/", data)
        .then((res) => res.data)
        .then((events) => {
          alert(`Date Details Modified.`);
        })
        .catch((err) => {
          this.errorEmail(err.response, "Update Job Date");
          alert(
            "An error occurred. The data did NOT save. Please contact me (97666886)." +
              "\n" +
              JSON.stringify(err.response)
          );
          console.error(err.response);
        });
    } else if (type === "destroy") {
      if (id > 0) {
        return instance
          .delete("/d-jobdate/" + id + "/")
          .then((res) => res.data)
          .then((events) => {
            // console.log("successfully added");
            this.jobdates.splice(index, 1);
            alert(`Date Deleted.`);
          })
          .catch((err) => {
            this.errorEmail(err.response, "Delete Job Date");
            alert(
              "An error occurred. The data did NOT save. Please contact me (97666886)." +
                "\n" +
                JSON.stringify(err.response)
            );
            console.error(err.response);
          });
      } else {
        this.jobdates.splice(index, 1);
      }
    }
  }

  postIntQuote(data, id, type, index) {
    if (type === "new") {
      return instance
        .post("/c-intquote/", data)
        .then((res) => res.data)
        .then((events) => {
          alert(`New International Quote Created.`);
        })
        .catch((err) => {
          this.errorEmail(err.response, "New International Quote");
          alert(
            "An error occurred. The data did NOT save. Please contact me (97666886)." +
              "\n" +
              JSON.stringify(err.response)
          );
          console.error(err.response);
        });
    } else if (type === "update") {
      return instance
        .put("/intquote/" + id + "/", data)
        .then((res) => res.data)
        .then((events) => {
          alert(`IM Quote Modified.`);
        })
        .catch((err) => {
          this.errorEmail(err.response, "Update International Quote");
          alert(
            "An error occurred. The data did NOT save. Please contact me (97666886)." +
              "\n" +
              JSON.stringify(err.response)
          );
          console.error(err.response);
        });
    } else if (type === "destroy") {
      if (id > 0) {
        return instance
          .delete("/d-intquote/" + id + "/")
          .then((res) => res.data)
          .then((events) => {
            // console.log("successfully added");
            this.intquote.splice(index, 1);
            alert(`IM Quote Deleted.`);
          })
          .catch((err) => {
            this.errorEmail(err.response, "Delete International Quote");
            alert(
              "An error occurred. The data did NOT save. Please contact me (97666886)." +
                "\n" +
                JSON.stringify(err.response)
            );
            console.error(err.response);
          });
      } else {
        this.intquote.splice(index, 1);
      }
    }
  }
  postStorageUpdate(data, id, type, index) {
    if (type === "new") {
      return instance
        .post("/c-storage/", data)
        .then((res) => res.data)
        .then((events) => {
          alert(`New Storage Job Created.`);
        })
        .catch((err) => {
          this.errorEmail(err.response, "New Storage");
          alert(
            "An error occurred. The data did NOT save. Please contact me (97666886)." +
              "\n" +
              JSON.stringify(err.response.data)
          );
          console.error(err.response);
        });
    } else if (type === "update") {
      return instance
        .put("/storage/" + id + "/", data)
        .then((res) => res.data)
        .then((events) => {
          alert(`Storage Details Modified.`);
        })
        .catch((err) => {
          this.errorEmail(err.response, "Update Storage");
          alert(
            "An error occurred. The data did NOT save. Please contact me (97666886)." +
              "\n" +
              JSON.stringify(err.response.data)
          );
          console.error(err.response);
        });
    } else if (type === "destroy") {
      if (id > 0) {
        return instance
          .delete("/d-storage/" + id + "/")
          .then((res) => res.data)
          .then((events) => {
            // console.log("successfully added");
            this.storages.splice(index, 1);
            alert(`Storage Job Deleted.`);
          })
          .catch((err) => {
            this.errorEmail(err.response, "Delete Storage");
            alert(
              "An error occurred. The data did NOT save. Please contact me (97666886)." +
                "\n" +
                JSON.stringify(err.response)
            );
            console.error(err.response);
          });
      } else {
        this.storages.splice(index, 1);
      }
    }
  }
  postMaterialUpdate(data, id, type, index) {
    if (type === "new") {
      return instance
        .post("/c-material/", data)
        .then((res) => res.data)
        .then((events) => {
          alert(`New Move Date Created.`);
        })
        .catch((err) => {
          this.errorEmail(err.response, "New Materials");
          alert(
            "An error occurred. The data did NOT save. Please contact me (97666886)." +
              "\n" +
              JSON.stringify(err.response)
          );
          console.error(err.response);
        });
    } else if (type === "update") {
      return instance
        .put("/material/" + id + "/", data)
        .then((res) => res.data)
        .then((events) => {
          alert(`Materials Modified.`);
        })
        .catch((err) => {
          this.errorEmail(err.response, "Update Materials");
          alert(
            "An error occurred. The data did NOT save. Please contact me (97666886)." +
              "\n" +
              JSON.stringify(err.response)
          );
          console.error(err.response);
        });
    }
  }

  selectJob(id) {
    if (id === 0) {
      this.job = {};
      this.oldstatus = "";
      this.internationalfee = {
        thcharge: 0,
        docharge: 0,
        originclearance: 0,
        unloadingcharge: 0,
        unpackingcharge: 0,
        assemblingcharge: 0,
        debrisremoval: 0,
        containerreturn: 0,
        intwrapandpack: 0,
        shippingcharges: 0,
        transitclearance: 0,
        destinationclearance: 0,
        firstmilecharge: 0,
        lastmilecharge: 0,
        cratecharge: 0,
        palletcharge: 0,
        dgrcharge: 0,
        dghandling: 0,
        detentioncharge: 0,
        demoragecharge: 0,
        shippingline: 0,
        originstorageinout: 0,
        destinationstorageinout: 0,
        shuttlecharge: 0,
        containerType: "",
        shippingmethod: "",
        imlength: 0,
        imwidth: 0,
        imheight: 0,
        transportationcharges: 0,
        loadingcharge: 0,
        commodity: "",
        id: 0,
      };
      this.storages = [];
      this.material = {};
      this.jobdates = [];
      this.addresses = [];
      this.payments = [];
      this.addcharge = [];
      this.intquote = [];
      this.rooms = [];
      this.items = [];
    } else {
      instance
        .get("/job/" + id + "/?format=json")
        .then((res) => res.data)
        .then((job) => {
          if (!job.excludeservice) {
            job.excludeservice = "";
          }
          if (!job.includeservice) {
            job.includeservice = "";
          }
          this.job = job;
          this.oldstatus = job.status;

          this.fetchAll(id);
          settingStore.changeEditJob(true);
        })
        .catch((err) => {
          this.errorEmail(err.response, "Get Job Data");
          alert(
            "An error occurred. The data did NOT save. Please contact me (97666886)." +
              "\n" +
              JSON.stringify(err.response)
          );
          console.error(err.response);
        });
    }
  }
  fetchAll(jobid) {
    this.fetchStorage(jobid);
    this.fetchJobDate(jobid);
    this.fetchAddress(jobid);
    this.fetchPayment(jobid);
    this.fetchAddCharge(jobid);
    this.fetchIMTracker(jobid);
    this.fetchRooms(jobid);
    this.fetchIntQuote(jobid);
    this.fetchIntFee(jobid);
  }

  fetchIntFee(jobid) {
    instance
      .get("/intfeejob/" + jobid + "/?format=json")
      .then((res) => res.data)
      .then((internationalfee) => {
        this.internationalfee = {
          thcharge: 0,
          docharge: 0,
          originclearance: 0,
          unloadingcharge: 0,
          unpackingcharge: 0,
          assemblingcharge: 0,
          debrisremoval: 0,
          containerreturn: 0,
          intwrapandpack: 0,
          shippingcharges: 0,
          transitclearance: 0,
          destinationclearance: 0,
          firstmilecharge: 0,
          lastmilecharge: 0,
          cratecharge: 0,
          palletcharge: 0,
          dgrcharge: 0,
          dghandling: 0,
          detentioncharge: 0,
          demoragecharge: 0,
          shippingline: 0,
          originstorageinout: 0,
          destinationstorageinout: 0,
          shuttlecharge: 0,
          containerType: "",
          shippingmethod: "",
          imlength: 0,
          imwidth: 0,
          imheight: 0,
          transportationcharges: 0,
          loadingcharge: 0,
          commodity: "",
          id: 0,
          job: this.job.id,
        };

        if (internationalfee.length > 0) {
          this.internationalfee = internationalfee[0];
        }
      });
  }
  fetchStorage(jobid) {
    instance
      .get("/storagejob/" + jobid + "/?format=json")
      .then((res) => res.data)
      .then((storages) => {
        this.storages = storages;
        console.log("storage received");
      });
  }
  fetchJobDate(jobid) {
    instance
      .get("/jobdatejob/" + jobid + "/?format=json")
      .then((res) => res.data)
      .then((jobdates) => {
        this.jobdates = jobdates;
        console.log("job dates received");
      });
  }
  fetchAddress(jobid) {
    instance
      .get("/addressbyjob/" + jobid + "/?format=json")
      .then((res) => res.data)
      .then((addresses) => {
        this.addresses = addresses;
        console.log("address received");
      });
  }
  fetchPayment(jobid) {
    instance
      .get("/paymentjob/" + jobid + "/?format=json")
      .then((res) => res.data)
      .then((payments) => {
        this.payments = payments;
        console.log("payment job received");
      });
  }
  fetchAddCharge(jobid) {
    instance
      .get("/addchargejob/" + jobid + "/?format=json")
      .then((res) => res.data)
      .then((addcharge) => {
        this.addcharge = addcharge;
        console.log("addcharge received");
      });
  }
  fetchIMTracker(jobid) {
    instance
      .get("/imtrackerjob/" + jobid + "/?format=json")
      .then((res) => res.data)
      .then((imtracker) => {
        this.imtracker = imtracker;
        console.log("imtracker received");
      });
  }
  fetchRooms(jobid) {
    instance
      .get("/roomjob/" + jobid + "/?format=json")
      .then((res) => res.data)
      .then((rooms) => {
        this.rooms = rooms;
        console.log("rooms received");
      });
  }
  fetchIntQuote(jobid) {
    instance
      .get("/intquotejob/" + jobid + "/?format=json")
      .then((res) => res.data)
      .then((intquote) => {
        this.intquote = intquote;
        console.log("intquote received");
      });
  }

  get clientQuality() {
    let count = 5;
    if (eventStore.editClient) {
      if (eventStore.editClient.title === "") {
        count -= 1;
      }
      if (eventStore.editClient.phone1 === 0) {
        count -= 1;
      }
      if (eventStore.editClient.email === "") {
        count -= 1;
      }
      if (eventStore.editClient.leadtype === "") {
        count -= 1;
      }
      if (eventStore.editClient.heardofus === "") {
        count -= 1;
      }
    }
    return Math.round((100 * count) / 5);
  }
  get jobdetailQuality() {
    let count = 0;
    let total = 0;
    if (this.job.status === "Lead") {
      count = 2;
      total = 2;
    } else {
      count = 5;
      total = 5;

      if (
        parseFloat(this.job.cbm) === 0 ||
        this.job.cbm === "" ||
        !this.job.cbm
      ) {
        count -= 1;
      }
      if (
        parseFloat(this.job.firstprice) === 0 ||
        this.job.firstprice === "" ||
        !this.job.firstprice
      ) {
        count -= 1;
      }
      if (
        parseFloat(this.job.lastprice) === 0 ||
        this.job.lastprice === "" ||
        !this.job.lastprice
      ) {
        count -= 1;
      }
    }
    if (this.job.jobmain === "") {
      count -= 1;
    }
    if (this.job.jobsecond === "") {
      count -= 1;
    }

    return Math.round((100 * count) / total);
  }

  get addressQuality() {
    if (this.addresses.length > 0) {
      return 100;
    } else {
      return 0;
    }
  }
  get datesQuality() {
    if (this.jobdates.length > 0) {
      return 100;
    } else {
      return 0;
    }
  }
  get packinglistQuality() {
    if (this.job.status === "Lead") {
      return 100;
    }
    if (this.rooms.length > 0) {
      return 100;
    } else {
      return 0;
    }
  }
  get paymentQuality() {
    if (this.job.status === "Booked" || this.job.status === "Converted") {
      if (this.payments.length > 0) {
        return 100;
      }
      return 0;
    } else {
      return 100;
    }
  }
  get imQuality() {
    if (
      this.job.jobmain === "IM - Inbound" ||
      this.job.jobmain === "CIM - Inbound" ||
      this.job.jobmain === "IM - Outbound" ||
      this.job.jobmain === "CIM - Outbound"
    ) {
      let total = 5;
      let count = 5;

      if (this.internationalfee.containerType === "") {
        count -= 1;
      }
      if (this.internationalfee.commodity === "") {
        count -= 1;
      }
      if (this.internationalfee.shippingmethod === "") {
        count -= 1;
      }

      let easymovecharges =
        Math.round(
          1000 *
            (parseFloat(this.internationalfee.intwrapandpack) +
              parseFloat(this.internationalfee.cratecharge) +
              parseFloat(this.internationalfee.palletcharge) +
              parseFloat(this.internationalfee.loadingcharge) +
              parseFloat(this.internationalfee.transportationcharges) +
              parseFloat(this.internationalfee.originstorageinout) +
              parseFloat(this.internationalfee.originclearance) +
              parseFloat(this.internationalfee.shippingcharges) +
              parseFloat(this.internationalfee.transitclearance) +
              parseFloat(this.internationalfee.docharge) +
              parseFloat(this.internationalfee.thcharge) +
              parseFloat(this.internationalfee.destinationclearance) +
              parseFloat(this.internationalfee.shuttlecharge) +
              parseFloat(this.internationalfee.demoragecharge) +
              parseFloat(this.internationalfee.detentioncharge) +
              parseFloat(this.internationalfee.destinationstorageinout) +
              parseFloat(this.internationalfee.unloadingcharge) +
              parseFloat(this.internationalfee.assemblingcharge) +
              parseFloat(this.internationalfee.debrisremoval) +
              parseFloat(this.internationalfee.containerreturn) +
              parseFloat(this.internationalfee.dgrcharge) +
              parseFloat(this.internationalfee.dghandling))
        ) / 1000;

      if (parseFloat(easymovecharges) === 0) {
        count -= 1;
      }
      if (this.job.status !== "Lead") {
        if (this.intquote.length === 0) {
          count -= 1;
        }
      }
      return Math.round((100 * count) / total);
    }
    return 100;
  }
  get imtrackQuality() {
    if (
      this.job.jobmain === "IM - Inbound" ||
      this.job.jobmain === "CIM - Inbound" ||
      this.job.jobmain === "IM - Outbound" ||
      this.job.jobmain === "CIM - Outbound"
    ) {
      if (this.job.status === "Booked" || this.job.status === "Converted") {
        if (this.imtracker.length > 0) {
          return 100;
        } else {
          return 0;
        }
      }
    }

    return 100;
  }

  get fullQualityScore() {
    let count =
      this.clientQuality +
      this.jobdetailQuality +
      this.addressQuality +
      this.datesQuality;
    let total = 4;

    if (
      this.job.status === "Proposal" ||
      this.job.status === "Booked" ||
      this.job.status === "Converted"
    ) {
      total += 1;
      count += this.packinglistQuality;
      if (
        this.job.jobmain === "IM - Inbound" ||
        this.job.jobmain === "CIM - Inbound" ||
        this.job.jobmain === "IM - Outbound" ||
        this.job.jobmain === "CIM - Outbound"
      ) {
        total += 1;
        count += this.imQuality;
      }
    }
    if (this.job.status === "Booked" || this.job.status === "Converted") {
      total += 1;
      count += this.paymentQuality;
      if (
        this.job.jobmain === "IM - Inbound" ||
        this.job.jobmain === "CIM - Inbound" ||
        this.job.jobmain === "IM - Outbound" ||
        this.job.jobmain === "CIM - Outbound"
      ) {
        total += 1;
        count += this.imtrackQuality;
      }
    }
    return Math.round(count / total);
  }
  get fullQualityRemark() {
    let count = "";
    if (this.clientQuality < 100) {
      count += ", Client Details";
    }
    if (this.jobdetailQuality < 100) {
      count += ", Job Details";
    }
    if (this.addressQuality < 100) {
      count += ", Missing Address";
    }
    if (this.datesQuality < 100) {
      count += ", Missing Dates";
    }

    if (
      this.job.status === "Proposal" ||
      this.job.status === "Booked" ||
      this.job.status === "Converted"
    ) {
      if (this.packinglistQuality < 100) {
        count += ", PackingList";
      }
      if (
        this.job.jobmain === "IM - Inbound" ||
        this.job.jobmain === "CIM - Inbound" ||
        this.job.jobmain === "IM - Outbound" ||
        this.job.jobmain === "CIM - Outbound"
      ) {
        if (this.imQuality < 100) {
          count += ", IM Details";
        }
      }
    }
    if (this.job.status === "Booked" || this.job.status === "Converted") {
      if (
        this.job.jobmain === "IM - Inbound" ||
        this.job.jobmain === "CIM - Inbound" ||
        this.job.jobmain === "IM - Outbound" ||
        this.job.jobmain === "CIM - Outbound"
      ) {
        if (this.imtrackQuality < 100) {
          count += ", IM Tracking";
        }
      }
    }
    return count.substring(2) + ".";
  }

  get addchargeprice() {
    let acprice = 0;
    for (let i = 0; i < this.addcharge.length; i++) {
      acprice += parseFloat(this.addcharge[i].price);
    }
    return Math.round(1000 * acprice) / 1000;
  }

  get totalstorageprice() {
    let storageprice = 0;
    for (let j = 0; j < this.storages.length; j++) {
      storageprice +=
        parseFloat(this.storages[j].monthlycost) *
        Math.round(
          moment(this.storages[j].storageenddate).diff(
            moment(this.storages[j].storagestartdate),
            "months",
            true
          )
        );
    }
    return Math.round(1000 * storageprice) / 1000;
  }

  get totaljobprice() {
    return (
      Math.round(
        1000 *
          (parseFloat(this.job.lastprice) +
            parseFloat(this.addchargeprice) +
            parseFloat(this.totalstorageprice))
      ) / 1000
    );
  }

  get checkForUpdate() {
    if (
      this.jobUpdate ||
      this.addressUpdate ||
      this.addchargeUpdate ||
      this.jobdateUpdate ||
      this.materialUpdate ||
      this.storageUpdate ||
      this.paymentUpdate ||
      this.intQuoteUpdate ||
      this.roomUpdate ||
      this.intFeeUpdate ||
      this.imtrackerUpdate
    ) {
      return true;
    } else {
      return false;
    }
  }
}

decorate(JobEditStore, {
  job: observable,
  oldstatus: observable,
  internationalfee: observable,
  storages: observable,
  material: observable,
  jobdates: observable,
  addresses: observable,
  payments: observable,
  imtracker: observable,
  addcharge: observable,
  intquote: observable,
  rooms: observable,
  items: observable,
  jobUpdate: observable,
  addressUpdate: observable,
  addchargeUpdate: observable,
  jobdateUpdate: observable,
  materialUpdate: observable,
  storageUpdate: observable,
  paymentUpdate: observable,
  intQuoteUpdate: observable,
  intFeeUpdate: observable,
  imtrackerUpdate: observable,
  roomUpdate: observable,
  selectedIMQuote: observable,
  customproposal: observable,
  clientQuality: computed,
  jobdetailQuality: computed,
  addressQuality: computed,
  datesQuality: computed,
  packinglistQuality: computed,
  paymentQuality: computed,
  imQuality: computed,
  imtrackQuality: computed,
  fullQualityScore: computed,
  fullQualityRemark: computed,
  addchargeprice: computed,
  totalstorageprice: computed,
  totaljobprice: computed,
  checkForUpdate: computed,
});

const jobEditStore = new JobEditStore();

export default jobEditStore;
