import React, { Component } from "react";
import { observer } from "mobx-react";

import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import FormControl from "@material-ui/core/FormControl";
import moment from "moment";
import { DatePicker } from "antd";

import eventStore from "../Stores/EventStore";
import jobEditStore from "../Stores/JobEditStore";
import settingStore from "../Stores/SettingStore";

import userStore from "../Stores/UserStore";
import ReactGA from "react-ga";

function initializeReactGA() {
  if (userStore.user.username !== "jazzaf") {
    ReactGA.initialize("UA-163244126-1");
    ReactGA.pageview(window.location.pathname);
    ReactGA.event({
      category: window.location.pathname,
      action: userStore.user.username,
      label: new Date().toLocaleTimeString(),
    });
  }
}

class WorkSchedule extends Component {
  constructor(props) {
    super(props);
    this.state = {
      client: true,
      job: true,
      remarks: true,
      management: true,
      feedback: false,
      cancellation: false,
      update: true,
      date: new Date(),
    };
    this.options = {
      defaultSortName: "id", // default sort column name
      // exportCSVBtn: this.createCustomExportCSVButton,
      defaultSortOrder: "asc", // default sort order
      onRowClick: function (job) {
        jobEditStore.selectJob(job.id);
      },
    };
  }

  componentDidMount() {
    eventStore.changeJobFilter("reset");
    initializeReactGA();
  }

  createCustomExportCSVButton = (onClick) => {
    return (
      <button style={{ color: "red" }} onClick={onClick}>
        Download
      </button>
    );
  };

  onChange = (date, dateString) => {
    settingStore.changeWorkScheduleDate(moment(date._d));
  };

  addColumn(item) {
    if (item === "Client") {
      this.setState({ client: !this.state.client });
    }
  }

  handleDateChange(date) {
    this.setState({ date: date });
  }

  render() {
    let filtjobs = eventStore.filterWorkSchedule;
    let filtest = eventStore.filterEstimationWorkSchedule;

    let filtjobs2 = eventStore.jobdates;
    filtjobs2 = filtjobs2.filter((job) => job.status !== "Cancelled");

    for (let i = 0; i < filtjobs2.length; i++) {
      if (
        moment(filtjobs2[i].estimationdate).format("DD-MMM-YY") ===
        moment(settingStore.workscheddate).format("DD-MMM-YY")
      ) {
        // console.log("Catch");
      } else {
        // console.log("Miss");
      }

      if (filtjobs2.id === 270) {
        // console.log("Catch");
      }
    }

    for (let i = 0; i < filtjobs.length; i++) {
      filtjobs[i].combinedjob =
        filtjobs[i].jobmain + " - " + filtjobs[i].jobsecond;

      if (filtjobs[i].address) {
        if (filtjobs[i].address.length > 1) {
          for (let j = 0; j < filtjobs[i].address.length; i++) {
            filtjobs[i].combinedTo += filtjobs[i].address[j].locationto + ", ";
            filtjobs[i].combinedFrom +=
              filtjobs[i].address[j].locationfrom + ", ";
          }
        } else if (filtjobs[i].address.length === 1) {
          filtjobs[i].combinedTo = filtjobs[i].address[0].locationto + ", ";
          filtjobs[i].combinedFrom = filtjobs[i].address[0].locationfrom + ", ";
        }
      }
    }
    for (let i = 0; i < filtest.length; i++) {
      filtest[i].combinedjob =
        filtest[i].jobmain + " - " + filtest[i].jobsecond;

      if (filtest[i].address) {
        if (filtest[i].address.length > 1) {
          for (let j = 0; j < filtest[i].address.length; i++) {
            filtest[i].combinedTo += filtest[i].address[j].locationto + ", ";
            filtest[i].combinedFrom +=
              filtest[i].address[j].locationfrom + ", ";
          }
        } else if (filtest[i].address.length === 1) {
          filtest[i].combinedTo = filtest[i].address[0].locationto + ", ";
          filtest[i].combinedFrom = filtest[i].address[0].locationfrom + ", ";
        }
      }
    }

    return (
      <div>
        <FormControl variant="outlined" style={{ width: "100%" }}>
          <b>Estimation Date</b>
          <DatePicker
            onChange={this.onChange}
            format={"DD-MMM-YY"}
            size="large"
            defaultValue={
              (settingStore.workscheddate !== null) &
                (settingStore.workscheddate !== "") &&
              moment(settingStore.workscheddate)
            }
          />
        </FormControl>
        <hr />
        <br />
        <BootstrapTable
          data={filtjobs}
          striped
          hover
          search={true}
          condensed
          exportCSV
        >
          <TableHeaderColumn isKey dataField="title" dataSort>
            Client Name
          </TableHeaderColumn>
          <TableHeaderColumn dataField="phone" dataSort>
            Client Number
          </TableHeaderColumn>
          <TableHeaderColumn dataField="combinedjob" dataSort>
            Job Type
          </TableHeaderColumn>
          <TableHeaderColumn dataField="status" dataSort>
            Time at Customer
          </TableHeaderColumn>
          <TableHeaderColumn dataSort dataField="combinedFrom">
            From
          </TableHeaderColumn>
          <TableHeaderColumn dataSort dataField="combinedTo">
            To
          </TableHeaderColumn>
          <TableHeaderColumn dataSort dataField="status">
            Status
          </TableHeaderColumn>
        </BootstrapTable>
        <br />
        <hr />
        <BootstrapTable
          data={filtest}
          striped
          hover
          search={true}
          condensed
          exportCSV
        >
          <TableHeaderColumn isKey dataField="title" dataSort>
            Client Name
          </TableHeaderColumn>
          <TableHeaderColumn dataField="phone" dataSort>
            Client Number
          </TableHeaderColumn>
          <TableHeaderColumn dataField="combinedjob" dataSort>
            Job Type
          </TableHeaderColumn>
          <TableHeaderColumn dataField="status" dataSort>
            Time at Customer
          </TableHeaderColumn>
          <TableHeaderColumn dataSort dataField="combinedFrom">
            From
          </TableHeaderColumn>
          <TableHeaderColumn dataSort dataField="combinedTo">
            To
          </TableHeaderColumn>
          <TableHeaderColumn dataSort dataField="status">
            Status
          </TableHeaderColumn>
        </BootstrapTable>
        <br />
      </div>
    );
  }
}

export default observer(WorkSchedule);
