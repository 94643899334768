import React from "react";
// First way to import
import moment from "moment";
import { observer } from "mobx-react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import eventStore from "../Stores/EventStore";
import newEventStore from "../Stores/NewEventStore";
import settingStore from "../Stores/SettingStore";

moment.locale("ko", {
  week: {
    dow: 6,
    doy: 6,
  },
});
const localizer = momentLocalizer(moment);

class PerformanceCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
      estCalendar: false,
    };
  }

  componentDidMount() {
    eventStore.changeJobFilter("calreset");
  }

  eventStyleGetter = (event, start, end, isSelected) => {
    var backgroundColor = "#";
    var color = "black";

    if (event.type === "Price") {
      if (event.value >= 400) {
        backgroundColor = "#285a00";
        color = "white";
      } else if (event.value >= 300) {
        backgroundColor = "green";
      } else if (event.value >= 200) {
        backgroundColor = "#9dcc42";
      } else if (event.value >= 100) {
        backgroundColor = "orange";
      } else {
        backgroundColor = "#b30000";
        color = "white";
      }
    }
    if (event.type === "CBM") {
      if (event.value >= 40) {
        backgroundColor = "#285a00";
        color = "white";
      } else if (event.value >= 30) {
        backgroundColor = "green";
      } else if (event.value >= 20) {
        backgroundColor = "#9dcc42";
      } else if (event.value >= 10) {
        backgroundColor = "orange";
      } else {
        backgroundColor = "#b30000";
        color = "white";
      }
    }

    var style = {
      borderRadius: "2px",
      backgroundColor: backgroundColor,
      opacity: 0.7,
      color: color,
      border: "3px",
      borderColor: "black",
      display: "block",
      fontSize: 16,
      fontWeight: "bold",
    };
    return {
      style: style,
    };
  };

  render() {
    let startDate = moment(settingStore.calstartdate);
    let endDate = moment(settingStore.calenddate);
    let cbmList = [];
    let jobdates = newEventStore.sectorSum;
    let dateDiff = endDate.diff(startDate, "days");
    let dailycbm;
    let dailyprice;

    for (let j = 0; j < dateDiff; j++) {
      dailycbm = 0;
      dailyprice = 0;

      for (let i = 0; i < jobdates.length; i++) {
        if (
          moment(jobdates[i].jobdate).format("DD-MMM-YY") ===
          moment(startDate).add(j, "days").format("DD-MMM-YY")
        ) {
          dailycbm += parseFloat(jobdates[i].dailycbm);
          dailyprice += parseFloat(jobdates[i].dailyprice);
        }
      }
      cbmList.push({
        start: moment(startDate).add(j, "days").format("DD-MMM-YY"),
        end: moment(startDate).add(j, "days").format("DD-MMM-YY"),
        desc: dailycbm + " CBM",
        type: "CBM",
        value: dailycbm,
      });
      cbmList.push({
        start: moment(startDate).add(j, "days").format("DD-MMM-YY"),
        end: moment(startDate).add(j, "days").format("DD-MMM-YY"),
        desc: dailyprice + " KD",
        type: "Price",
        value: dailyprice,
      });
    }

    return (
      <div>
        <Calendar
          localizer={localizer}
          events={cbmList}
          eventPropGetter={this.eventStyleGetter}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 500 }}
          defaultDate={new Date()}
          views={["month"]}
          titleAccessor="desc"
          popup
          onNavigate={(e) => {
            settingStore.changeCalDate(e);
          }}
        />
        <b />
      </div>
    );
  }
}

export default observer(PerformanceCalendar);
