import React, { Component } from "react";
import { observer } from "mobx-react";
import { SyncLoader } from "react-spinners";

import eventStore from "../Stores/EventStore";
import ManagementTabs from "./ManagementTabs";
import managementStore from "../Stores/ManagementStore";

class ManagementDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      client: false,
      clientdetail: true,
      job: true,
      remarks: false,
      management: false,
      feedback: false,
      cancellation: false,
      quality: false,
      update: true,
      leaddistribution: false,
    };
  }

  componentDidMount() {
    if (!eventStore.financialsLoaded) {
      eventStore.fetchFinancials();
    }
  }

  render() {
    if (!managementStore.attendanceloaded) {
      managementStore.fetchAttendance();
      return (
        <SyncLoader
          sizeUnit={"px"}
          size={50}
          color={"#0017FF"}
          loading={true}
        />
      );
    }

    return <ManagementTabs />;
  }
}

export default observer(ManagementDashboard);
