import { decorate, observable } from "mobx";
import axios from "axios";
import moment from "moment";

import settingStore from "./SettingStore";
import newEventStore from "./NewEventStore";
import userStore from "./UserStore";

const instance = axios.create({
  // baseURL: "https://easymovecalendar.app/",
  // baseURL: "http://127.0.0.1:8000/",
  // baseURL: "http://157.230.113.108/",
  baseURL: "https://jazzafs.com/",
});

class JobSelectionStore {
  constructor() {
    this.clientID = "";
    this.jobID = "";
    this.jobs = [];
    this.singleclient = {};
    this.singlejob = { address: [], jobdate: [], storage: [] };
    this.loading = false;
    this.Modalloading = false;
    this.showJobs = false;
    this.showModal = false;
    this.showJobModal = false;
    this.editClient = {
      title: "",
      email: "",
      phone1: "",
      phone2: "",
      heardofus: "",
      remarks: "",
      leadType: "",
      id: 0,
      editOn: false,
    };
    this.editJob = {
      id: 0,
      jobmain: "",
      jobsecond: "",
      companygroup: "",
      cbm: 0,
      status: "Lead",
      estimationType: "Pending",
      emfocalpoint: "",
      client: 0,
      leaddate: moment(new Date()).format("YYYY-MM-DD"),
    };
  }

  // Functions

  closeModal() {
    this.showModal = false;
    this.showJobModal = false;
  }

  closeJobList() {
    this.showJobs = false;
  }

  addJob() {
    this.editJob = {
      id: 0,
      jobmain: "",
      jobsecond: "",
      companygroup: "",
      cbm: 0,
      status: "Lead",
      estimationType: "Pending",
      emfocalpoint: userStore.profile.first_name,
      client: this.clientID,
      leaddate: moment(new Date()).format("YYYY-MM-DD"),
    };
  }

  updateJob(val, type) {
    if (type === "jobmain") this.editJob.jobmain = val;
    if (type === "jobsecond") this.editJob.jobsecond = val;
    if (type === "companygroup") this.editJob.companygroup = val;
    if (type === "cbm") this.editJob.cbm = val;
    if (type === "status") this.editJob.status = val;
    if (type === "estimationType") this.editJob.estimationType = val;
    if (type === "emfocalpoint") this.editJob.emfocalpoint = val;
  }

  postJob() {
    console.log("test");
    let data = this.editJob;
    console.log("test2");
    return instance
      .post("/c-job/", data)
      .then((res) => res.data)
      .then((events) => {
        console.log(events.id);
        alert(`New ${events.jobmain} Job Created.`);
        this.selectClient(this.clientID);
        // newEventStore.fetchUpdatedJobs();
      })
      .catch((err) => {
        let message = {
          activity: "Posting New Job",
          ClientName: "",
          ClientID: "",
          JobID: "",
          JobMain: "",
          Status: "",
          Data: data,
          error: err.response,
        };
        settingStore.sendUnifiedEmail(message, "error");
        alert(
          "An error occurred. The data did NOT save. Please contact me (97666886)." +
            "\n" +
            JSON.stringify(err.response)
        );

        console.error(err.response);
      });
  }

  selectClient(id) {
    if (id === "reset") {
      this.clientID = "";
      this.jobs = [];
      this.singleclient = {};
    } else if (id === 0) {
      this.editClient = {
        title: "",
        email: "",
        phone1: "",
        phone2: "",
        heardofus: "",
        remarks: "",
        leadType: "",
        id: 0,
      };
    } else {
      this.clientID = id;
      this.singleclient = newEventStore.clients.filter(
        (client) => client.id === id
      )[0];
      this.fetchSingleClientJobs(id);
    }
  }

  updateClient(val, type) {
    if (type === "phone1") this.editClient.phone1 = val;
    if (type === "phone2") this.editClient.phone2 = val;
    if (type === "title") this.editClient.title = val;
    if (type === "email") this.editClient.email = val;
    if (type === "leadtype") this.editClient.leadtype = val;
    if (type === "heardofus") this.editClient.heardofus = val;
    if (type === "remarks") this.editClient.remarks = val;
    if (type === "editOn") this.editClient.editOn = val;
  }

  postClient() {
    let data = this.editClient;
    if (this.editClient.id === 0) {
      return instance
        .post("/c-client/", data)
        .then((res) => res.data)
        .then((events) => {
          newEventStore.fetchUpdatedClients();
          alert(`New Client (${events.title}) Created.`);
        })
        .catch((err) => {
          let message = {
            activity: "Posting New Client",
            ClientName: "",
            ClientID: "",
            JobID: "",
            JobMain: "",
            Status: "",
            Data: data,
            error: err.response,
          };
          settingStore.sendUnifiedEmail(message, "error");
          alert(
            "An error occurred. The data did NOT save. Please contact me (97666886)." +
              "\n" +
              JSON.stringify(err.response)
          );

          console.error(err.response);
        });
    } else {
      return instance
        .put("/client/" + data.id + "/", data)
        .then((res) => res.data)
        .then((events) => {
          newEventStore.fetchUpdatedClients();

          alert(`${data.title} Details Updated.`);
        })
        .catch((err) => {
          let message = {
            activity: "Editing an Existing Client",
            ClientName: "",
            ClientID: "",
            JobID: "",
            JobMain: "",
            Status: "",
            Data: data,
            error: err.response,
          };
          settingStore.sendUnifiedEmail(message, "error");
          alert(
            "An error occurred. The data did NOT save. Please contact me (97666886)." +
              "\n" +
              JSON.stringify(err.response)
          );
        });
    }
  }

  // Fetching Data

  fetchSingleClientJobs(id) {
    this.loading = true;
    this.jobs = [];

    return instance
      .get("/jobslist/" + id + "/?format=json")
      .then((res) => res.data)
      .then((jobs) => (this.jobs = jobs))
      .then((clients) => {
        console.log("Jobs Fetched");
        this.showJobs = true;
        this.loading = false;
      });
  }

  fetchSingleJob(id) {
    this.Modalloading = true;
    this.singlejob = { address: [], jobdate: [], storage: [] };

    return instance
      .get("/jobdetails/" + id + "/?format=json")
      .then((res) => res.data)
      .then((jobs) => (this.singlejob = jobs[0]))
      .then((clients) => {
        console.log("single Job Fetched");
        this.Modalloading = false;
        this.showModal = true;
      });
  }

  // Computed Lists ---------
}

decorate(JobSelectionStore, {
  clientID: observable,
  jobID: observable,
  jobs: observable,
  singlejob: observable,
  loading: observable,
  showJobs: observable,
  showModal: observable,
  showJobModal: observable,
  Modalloading: observable,
  editClient: observable,
  editJob: observable,
});

const jobSelectionStore = new JobSelectionStore();

export default jobSelectionStore;
