import React from "react";
// First way to import
import { observer } from "mobx-react";
import { Button, Container } from "react-bootstrap";
import FormControl from "@material-ui/core/FormControl";
import { Select } from "antd";
import { Input } from "antd";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import DatePicker from "react-datepicker";

import jobEditStore from "../Stores/JobEditStore";
import "react-datepicker/dist/react-datepicker.css";

const { TextArea } = Input;

class IMTrackPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let imtracks;

    imtracks = jobEditStore.imtracker.map((imtrack, index) => (
      <div key={index}>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={1} md={1}>
            <b>Order:</b>
            <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              size="small"
            >
              <Select
                native
                type="number"
                value={imtrack.order}
                onChange={(e) =>
                  jobEditStore.updateIMTracker(e, index, "order")
                }
              >
                <option value={1}>1</option>
                <option value={2}>2</option>
                <option value={3}>3</option>
                <option value={4}>4</option>
                <option value={5}>5</option>
                <option value={6}>6</option>
                <option value={7}>7</option>
                <option value={8}>8</option>
                <option value={9}>9</option>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={5} md={5}>
            <b>Title:</b>
            <TextArea
              style={{ width: "100%" }}
              placeholder="Add client remarks here"
              autoSize={{ minRows: 1, maxRows: 10 }}
              value={imtrack.title}
              maxLength={399}
              onChange={(e) => jobEditStore.updateIMTracker(e, index, "title")}
            />
          </Grid>
          <Grid item xs={10} sm={5} md={5}>
            <b>Status:</b>
            <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              size="small"
            >
              <Select
                native
                label="Age"
                value={imtrack.status}
                onChange={(e) =>
                  jobEditStore.updateIMTracker(e, index, "status")
                }
              >
                <option value={"Pending"}>Pending</option>
                <option value={"Completed"}>Completed</option>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={1}>
          <Grid item xs={12} sm={1} md={1}>
            <FormControl variant="outlined" style={{ width: "100%" }}>
              <b>Original Date</b>
              <DatePicker
                disabled={imtrack.id !== 0}
                onChange={(date) =>
                  jobEditStore.updateIMTracker(
                    moment(date).format("YYYY-MM-DD"),
                    index,
                    "originaldate"
                  )
                }
                dateFormat="dd-MMM-yy"
                monthsShown={1}
                selected={Date.parse(imtrack.originaldate)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={1} md={1}>
            <FormControl variant="outlined" style={{ width: "100%" }}>
              <b>Revised Date</b>
              <DatePicker
                disabled={imtrack.id === 0}
                onChange={(date) =>
                  jobEditStore.updateIMTracker(
                    moment(date).format("YYYY-MM-DD"),
                    index,
                    "reviseddate"
                  )
                }
                dateFormat="dd-MMM-yy"
                monthsShown={1}
                selected={Date.parse(imtrack.reviseddate)}
              />
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={9} md={9}>
            <b>Remarks:</b>
            <TextArea
              style={{ width: "100%" }}
              placeholder="Add Remarks"
              autoSize={{ minRows: 1, maxRows: 10 }}
              value={imtrack.remark}
              onChange={(e) => jobEditStore.updateIMTracker(e, index, "remark")}
            />
          </Grid>
          <Grid item xs={2} sm={1} md={1}>
            <Button
              variant="outline-danger"
              block
              onClick={() =>
                jobEditStore.postIMTracker([], imtrack.id, "destroy", index)
              }
            >
              <i className="fas fa-times"></i>
            </Button>
          </Grid>
        </Grid>
        <hr />
      </div>
    ));

    return (
      <div>
        <Container style={{ maxWidth: "98%" }}>
          <h6>Quality Score = {jobEditStore.imtrackQuality}%</h6>
          <form>{imtracks}</form>
          <hr />
          <Button
            block
            variant="success"
            onClick={() => jobEditStore.addIMTrack()}
          >
            Add IM Track
          </Button>
          <hr />
        </Container>
      </div>
    );
  }
}

export default observer(IMTrackPage);
