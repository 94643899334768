import React, { Component } from "react";
import { observer } from "mobx-react";
import moment from "moment";

import settingStore from "../Stores/SettingStore";
import eventStore from "../Stores/EventStore";
import jobEditStore from "../Stores/JobEditStore";
import MaterialTable from "material-table";

class PaymentsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTable: true
    };
    this.options = {
      defaultSortName: "id", // default sort column name
      // exportCSVBtn: this.createCustomExportCSVButton,
      defaultSortOrder: "asc", // default sort order
      onRowClick: function(job) {
        jobEditStore.selectJob(job.id);
      }
    };
  }

  render() {
    let paymentlist = eventStore.financialrows.filter(
      payment =>
        moment(payment.paymentdate).format("MMM-YY") ===
        moment(settingStore.startDate).format("MMM-YY")
    );

    let columnnames = [
      { title: "ID", field: "id", removable: true },
      { title: "Amount", field: "invoiceamount", removable: true },
      { title: "Department", field: "category1", removable: true },
      { title: "Type", field: "category2", removable: true },
      { title: "Specific", field: "category5", removable: true },
      {
        title: "Date",
        field: "paymentdate",
        removable: true,
        defaultSort: "desc"
      },
      { title: "Account", field: "bankaccount", removable: true },
      { title: "Method", field: "paymentmethod", removable: true }
    ];

    return (
      <div>
        <MaterialTable
          title={moment(settingStore.startDate).format("MMM-YY") + " Payments"}
          columns={columnnames}
          data={paymentlist}
          paging={false}
          options={{
            paging: false,
            toolbarButtonAlignment: "left",
            tableLayout: "auto",
            padding: "dense",
            maxBodyHeight: 1000
          }}
        />
      </div>
    );
  }
}

export default observer(PaymentsList);
