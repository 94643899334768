import React from "react";
// First way to import
import { observer } from "mobx-react";
import { Button, Container } from "react-bootstrap";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import moment from "moment";

import { DatePicker } from "antd";
import { InputNumber } from "antd";
import { Select } from "antd";

import jobEditStore from "../Stores/JobEditStore";

import "react-datepicker/dist/react-datepicker.css";

const { Option } = Select;

class StoragePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let storagerows;
    storagerows = jobEditStore.storages.map((storage, index) => (
      <div key={index}>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={12} sm={3} md={3}>
            <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              size="small"
            >
              <b>Status</b>
              <Select
                showSearch
                placeholder="Storage Task"
                optionFilterProp="children"
                value={storage.storagestatus}
                onChange={e =>
                  jobEditStore.updateStorage(e, index, "storagestatus")
                }
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value="Pending">Pending</Option>
                <Option value="In Storage">In Storage</Option>
                <Option value="Completed">Completed</Option>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={3} md={3}>
            <FormControl variant="outlined" style={{ width: "100%" }}>
              <b>Start Date</b>
              <DatePicker
                size="large"
                format={"DD-MMM-YY"}
                onChange={e =>
                  jobEditStore.updateStorage(e, index, "storagestartdate")
                }
                defaultValue={
                  (storage.storagestartdate !== null) &
                    (storage.storagestartdate !== "") &&
                  moment(storage.storagestartdate)
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={3} md={3}>
            <FormControl variant="outlined" style={{ width: "100%" }}>
              <b>End Date</b>
              <DatePicker
                size="large"
                format={"DD-MMM-YY"}
                onChange={e =>
                  jobEditStore.updateStorage(e, index, "storageenddate")
                }
                defaultValue={
                  (storage.storageenddate !== null) &
                    (storage.storageenddate !== "") &&
                  moment(storage.storageenddate)
                }
              />
            </FormControl>
          </Grid>

          <Grid item xs={6} sm={3} md={3}>
            <FormControl variant="outlined" style={{ width: "100%" }}>
              <b>Paid To Date</b>
              <DatePicker
                format={"DD-MMM-YY"}
                size="large"
                onChange={e =>
                  jobEditStore.updateStorage(e, index, "storagepaidto")
                }
                defaultValue={
                  (storage.storagepaidto !== null) &
                    (storage.storagepaidto !== "") &&
                  moment(storage.storagepaidto)
                }
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={12} sm={3} md={3}>
            <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              size="small"
            >
              <b>Location</b>
              <Select
                showSearch
                placeholder="Storage Location"
                optionFilterProp="children"
                value={storage.storageloc_t}
                onChange={e =>
                  jobEditStore.updateStorage(e, index, "storageloc_t")
                }
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value="EM Abu Hulaifa">EM Abu Hulaifa</Option>
                <Option value="Al-Ghanim Storage">Al-Ghanim Storage</Option>
                <Option value="Other">Other</Option>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={3} md={3}>
            <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              size="small"
            >
              <b>Storage CBM</b>
              <InputNumber
                width="100%"
                min={0}
                max={100000}
                defaultValue={storage.storagecbm}
                onChange={val =>
                  jobEditStore.updateStorage(val, index, "storagecbm")
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={3} md={3}>
            <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              size="small"
            >
              <b>
                Monthly Cost ({Math.round(10 * storage.storagecbm * 3) / 10} to{" "}
                {Math.round(10 * storage.storagecbm * 7) / 10} KD)
              </b>
              <InputNumber
                min={0}
                max={100000}
                defaultValue={storage.monthlycost}
                onChange={val =>
                  jobEditStore.updateStorage(val, index, "monthlycost")
                }
              />
            </FormControl>
          </Grid>

          <Grid item xs={6} sm={3} md={3}>
            <Button
              variant="outline-danger"
              block
              onClick={() =>
                jobEditStore.postStorageUpdate(
                  [],
                  jobEditStore.storages[index].id,
                  "destroy",
                  index
                )
              }
            >
              <i className="fas fa-times"></i>
            </Button>
          </Grid>
        </Grid>
        <hr />
      </div>
    ));

    if (jobEditStore.storages.length < 1) {
      storagerows = "No Storage Jobs Available";
    }

    return (
      <div>
        <Container style={{ maxWidth: "98%" }}>
          <h4>{jobEditStore.storages.length} Storage Jobs</h4>
          <hr />
          <form>{storagerows}</form>
          <br />
          <Button
            block
            variant="success"
            onClick={() => jobEditStore.addStorage()}
          >
            Add a new Storage Job
          </Button>
        </Container>
      </div>
    );
  }
}

export default observer(StoragePage);
