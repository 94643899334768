import React, { Component } from "react";
import { PDFViewer } from "@react-pdf/renderer";
import { observer } from "mobx-react";

import proposalFooter from "./easymovefooter.png";
import proposalHeader from "./easymoveheader.png";

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
import moment from "moment";
import editJobStore from "../Stores/EditJobStore";

Font.register(
  "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
  { family: "Oswald" }
);

const styles = StyleSheet.create({
  body: {
    paddingTop: 10,
    paddingBottom: 5,
    paddingHorizontal: 35,
  },
  unbreakable: { width: "100%", height: 400 },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Oswald",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: "Oswald",
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  text2: {
    fontSize: 10,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  text3: {
    fontSize: 9,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  text4: {
    fontSize: 8,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
    fontWeight: "ultrabold",
    textDecoration: "underline",
  },
  header2: {
    fontSize: 12,
    textAlign: "left",
    color: "grey",
    fontWeight: "italic",
    textDecoration: "underline",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  headerTite: {
    fontSize: 14,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: { margin: "auto", flexDirection: "row" },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: { margin: "auto", marginTop: 5, fontSize: 10 },
  tableHeaderStyle: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 14,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    fontWeight: "bold",
    paddingTop: 6,
  },
  tableRow1b: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 12,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "20%",
  },
  tableRow1c: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 12,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "40%",
  },
  tableRow2a: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 12,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "80%",
  },
  tableRow2b: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontWeight: "bold",
    fontSize: 12,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "20%",
  },
  tableRow2c: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontWeight: "bold",
    fontSize: 12,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "40%",
  },
  tableRow2d: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "grey",
    color: "white",
    fontWeight: "bold",
    fontSize: 12,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "100%",
  },
  tableRow2e: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 12,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "70%",
  },
  tableRow2f: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontWeight: "bold",
    fontSize: 12,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "30%",
  },
  tableRow3a: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "20%",
  },
  tableRow3b: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "10%",
  },
  tableRow4a: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "10%",
  },
  tableRow4b: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "45%",
  },
  tableRow5a: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "10%",
  },
  tableRow5b: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "45%",
  },
  subtitle2: {
    fontSize: 14,
    fontFamily: "Oswald",
  },
  subtitle3: {
    fontSize: 12,
    margin: 12,
    fontFamily: "Oswald",
  },
  tableRow7a: {
    color: "black",
    fontSize: 10,
    fontWeight: "bold",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    textAlign: "left",
    paddingTop: 6,
    width: "70%",
  },
  tableRow7b: {
    color: "black",
    fontSize: 10,
    fontWeight: "bold",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    textAlign: "left",
    paddingTop: 6,
    width: "30%",
  },
  tableRow8a: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 10,
    fontWeight: "bold",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    textAlign: "left",
    paddingTop: 6,
    width: "20%",
    paddingLeft: 2,
  },
  tableRow8b: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 10,
    fontFamily: "Times-Roman",
    justifyContent: "center",
    textAlign: "left",
    paddingTop: 6,
    width: "30%",
    paddingLeft: 2,
  },
  tableRow9a: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 10,
    fontWeight: "bold",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    textAlign: "left",
    paddingTop: 6,
    width: "50%",
    paddingLeft: 2,
  },
  tableRow9b: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 10,
    fontFamily: "Times-Roman",
    justifyContent: "center",
    textAlign: "center",
    paddingTop: 6,
    width: "50%",
    paddingLeft: 2,
  },
});

class InvoiceExport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showproposal: false,
      packinglist: true,
      storage: true,
      showdiscount: false,
      bank: "KFH",
    };
  }

  render() {
    let payment = this.props.payment;
    let paymentremark;
    if (payment.remarks) {
      paymentremark = (
        <Text style={{ fontSize: 10, fontFamily: "Times-Roman" }}>
          Additional Remarks: {payment.remarks}
        </Text>
      );
    }

    let fullproposal = (
      <PDFViewer
        width={"100%"}
        height={600}
        fileName={`Payment Receipt ${editJobStore.job.id}-
                ${moment().format("YYMMDDHH")} for ${
          editJobStore.client.title
        }`}
      >
        <Document
          author={"Easy Move Logistics Co."}
          title={`Invoice ${editJobStore.job.id}-
                ${moment().format("YYMMDDHH")} for ${
            editJobStore.client.title
          }`}
          creator={"Easy Move Logistics Co."}
        >
          <Page size="A4" style={styles.body}>
            <Image src={proposalHeader} />
            <Text style={styles.header}>
              {editJobStore.customproposal.title} Confirmation of Payment (#
              {payment.id})
            </Text>
            <View style={{ flexDirection: "row", lineHeight: 0.3 }}>
              <Text style={styles.tableRow7a}>Easy Move Logistics Co.</Text>
              <Text style={styles.tableRow7b}>
                Date: {moment(payment.paymentdate).format("DD-MMM-YY")}
              </Text>
            </View>
            <View style={{ flexDirection: "row", lineHeight: 0.3 }}>
              <Text style={styles.tableRow7a}>Hawally, Kuwait</Text>
              <Text style={styles.tableRow7b}>
                Job ID: {editJobStore.job.id}
              </Text>
            </View>
            <View style={{ flexDirection: "row", lineHeight: 0.3 }}>
              <Text style={styles.tableRow7a}>Phone: +965-22060969</Text>
              <Text style={styles.tableRow7b}>
                Service: {editJobStore.job.jobmain}
              </Text>
            </View>
            <View style={{ flexDirection: "row", lineHeight: 0.3 }}>
              <Text style={styles.tableRow7a}>Email: info@easymovekw.com</Text>
              <Text style={styles.tableRow7b}>
                Type: {editJobStore.job.jobsecond}
              </Text>
            </View>

            <Text style={{ lineHeight: 2 }}> </Text>
            <Text
              style={{
                fontSize: 11,
                fontFamily: "Times-Roman",
                lineHeight: 1.5,
              }}
            >
              Below is the confirmation of payment by{" "}
              {editJobStore.client.title} for professional services confirmed on{" "}
              {moment(payment.paymentdate).format("DD-MMM-YYYY")}.
            </Text>

            <Text style={styles.tableHeaderStyle}>
              Payment Receipt {payment.id}
            </Text>
            <View style={{ flexDirection: "row" }}>
              <Text style={styles.tableRow9a}>Client Name</Text>
              <Text style={styles.tableRow9b}>{editJobStore.client.title}</Text>
            </View>
            <View style={{ flexDirection: "row" }}>
              <Text style={styles.tableRow9a}>Total Amount Paid (KWD)</Text>
              <Text style={styles.tableRow9b}>{payment.invoiceamount} KWD</Text>
            </View>
            <View style={{ flexDirection: "row" }}>
              <Text style={styles.tableRow9a}>Payment Date</Text>
              <Text style={styles.tableRow9b}>
                {moment(payment.paymentdate).format("DD-MMM-YYYY")}
              </Text>
            </View>
            <View style={{ flexDirection: "row" }}>
              <Text style={styles.tableRow9a}>Method of Payment</Text>
              <Text style={styles.tableRow9b}>{payment.paymentmethod}</Text>
            </View>
            <View style={{ flexDirection: "row" }}>
              <Text style={styles.tableRow9a}>Paid to Account</Text>
              <Text style={styles.tableRow9b}>{payment.bankaccount}</Text>
            </View>
            <View style={{ flexDirection: "row" }}>
              <Text style={styles.tableRow9a}>
                Payment Referenced to Invoice Number
              </Text>
              <Text style={styles.tableRow9b}>{payment.invoicenumber}</Text>
            </View>
            <Text> </Text>
            {paymentremark}
            <Image
              src={proposalFooter}
              style={{
                position: "absolute",
                bottom: 5,
                left: 0,
                right: 0,
              }}
            />
          </Page>
        </Document>
      </PDFViewer>
    );

    return <div>{fullproposal}</div>;
  }
}

export default observer(InvoiceExport);
