import React from "react";
// First way to import
import { observer } from "mobx-react";
import { Button, Container, Row, Col } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import { Select, Input } from "antd";
import MaterialTable from "material-table";
import Modal from "react-bootstrap/Modal";

import editJobStore from "../../Stores/EditJobStore";
import intSupStore from "../../Stores/IntSupStore";

const { TextArea } = Input;
const { Option } = Select;

class IMPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { show: false, intindex: 0 };
  }

  handleClose = () => this.setState({ show: false });
  handleShow = () => this.setState({ show: true });

  onChangeSelect(type, val) {
    editJobStore.updateJob(type, val);
  }

  onChangeAddCharge(type, val, index) {
    editJobStore.updateAddCharge(type, val, index);
  }

  render() {
    let intquoterows;

    let intquotecompanies = intSupStore.intsuppliers.map((supplier, index) => (
      <Option key={index} value={supplier.id}>
        {supplier.company}
      </Option>
    ));

    let containeroptions;

    if (editJobStore.internationalfee.shippingmethod === "Sea") {
      containeroptions = (
        <Select
          showSearch
          error
          width="100%"
          optionFilterProp="children"
          onChange={(e) => editJobStore.updateIntFee("containerType", e)}
          value={editJobStore.internationalfee.containerType}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          <Option value={"LCL"}>LCL</Option>
          <Option value={"20 Foot"}>20 Foot</Option>
          <Option value={"40 Foot"}>40 Foot</Option>
          <Option value={"40 Foot-HC"}>40 Foot-HC</Option>
          <Option value={"45 Foot"}>45 Foot</Option>
          <Option value={"Open Top"}>Open Top</Option>
          <Option value={"Reefer"}>Reefer</Option>
          <Option value={"Flat Rack"}>Flat Rack</Option>
        </Select>
      );
    } else {
      containeroptions = (
        <Select
          showSearch
          error
          width="100%"
          optionFilterProp="children"
          onChange={(e) => editJobStore.updateIntFee("containerType", e)}
          value={editJobStore.internationalfee.containerType}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          <Option value={editJobStore.internationalfee.shippingmethod}>
            {editJobStore.internationalfee.shippingmethod}
          </Option>
        </Select>
      );
    }

    let volumetricweight = (
      <div>
        {" "}
        <b>
          Volumetric Weight ={" "}
          {Math.round(
            (100 *
              editJobStore.internationalfee.imwidth *
              100 *
              editJobStore.internationalfee.imheight *
              100 *
              editJobStore.internationalfee.imlength) /
              5000
          )}{" "}
          KG
        </b>
      </div>
    );

    let dangerousgoodstab = (
      <div>
        <h4>Section 5: Dangerous Goods: ({editJobStore.imSection5Cost} KD )</h4>
        <Row>
          <Col>
            <TextField
              variant="outlined"
              label="Dangerous Goods Charges"
              type="number"
              size="small"
              style={{ width: "100%" }}
              value={editJobStore.internationalfee.dgrcharge}
              onChange={(e) =>
                editJobStore.updateIntFee("dgrcharge", e.target.value)
              }
            />
          </Col>
          <Col>
            <TextField
              variant="outlined"
              label="Dangerous Handling Charges"
              type="number"
              size="small"
              style={{ width: "100%" }}
              value={editJobStore.internationalfee.dghandling}
              onChange={(e) =>
                editJobStore.updateIntFee("dghandling", e.target.value)
              }
            />
          </Col>
        </Row>
        <hr />
      </div>
    );

    let intquotes = editJobStore.intquote;
    let indexVal = -1;
    for (let i = 0; i < intquotes.length; i++) {
      indexVal = -1;
      if (intquotes[i].company >= 0) {
        indexVal = intSupStore.intsuppliers.findIndex(
          (company) => company.id === intquotes[i].company
        );
      }
      if (indexVal >= 0) {
        intquotes[i].companyname = intSupStore.intsuppliers[indexVal].company;
      }
      if (intquotes[i].rating === "0") {
        intquotes[i].ratingshort = "Approved";
      } else if (intquotes[i].rating === "1") {
        intquotes[i].ratingshort = "High";
      } else if (intquotes[i].rating === "2") {
        intquotes[i].ratingshort = "Average";
      } else if (intquotes[i].rating === "3") {
        intquotes[i].ratingshort = "Low";
      }
    }
    let fixedcolumns = [
      { title: "id", field: "id" },
      { title: "Type", field: "companytype", removable: true },
      { title: "Company", field: "companyname" },
      { title: "Price", field: "price" },
      { title: "Rating", field: "ratingshort" },
      { title: "Remarks", field: "remark" },
    ];
    let intquoterows2 = (
      <MaterialTable
        title="Quotes from IM Suppliers"
        columns={fixedcolumns}
        data={intquotes}
        paging={false}
        editable={{
          isEditable: (rowData) => rowData.name === "a",
        }}
        icons={{
          Add: (props) => {
            return (
              <div>
                {" "}
                <i className="fa fa-plus"></i> Add new Region{" "}
              </div>
            );
          },
        }}
        actions={[
          {
            icon: "add",
            tooltip: "Add Quote",
            isFreeAction: true,
            onClick: (event) => {
              editJobStore.updateIntQuote("add", 0, 0);
              this.setState({
                show: true,
                intindex: editJobStore.intquote.length - 1,
              });
            },
          },
          (rowData) => ({
            icon: "edit",
            tooltip: "Edit Quote",
            onClick: (event, rowData) => {
              editJobStore.updateSelectedIMQuote(
                rowData.price,
                rowData.tableData.id,
                "reset"
              );
              editJobStore.updateSelectedIMQuote(
                rowData.price,
                rowData.tableData.id,
                "price"
              );
              editJobStore.updateSelectedIMQuote(
                rowData.rating,
                rowData.tableData.id,
                "rating"
              );
              editJobStore.updateSelectedIMQuote(
                rowData.id,
                rowData.tableData.id,
                "id"
              );
              editJobStore.updateSelectedIMQuote(
                rowData.remark,
                rowData.tableData.id,
                "remark"
              );
              editJobStore.updateSelectedIMQuote(
                rowData.company,
                rowData.tableData.id,
                "company"
              );
              editJobStore.updateSelectedIMQuote(
                rowData.companytype,
                rowData.tableData.id,
                "companytype"
              );
              this.setState({
                show: true,
                intindex: rowData.tableData.id,
              });
            },
          }),
          (rowData) => ({
            icon: "delete",
            tooltip: "Delete Item",
            onClick: (event, rowData) => {
              editJobStore.updateIntQuote("delete", 0, rowData.tableData.id);
            },
          }),
        ]}
        options={{
          actionsColumnIndex: 0,
          exportAllData: true,
          paging: false,
          toolbarButtonAlignment: "left",
        }}
      />
    );

    intquoterows = (
      <div>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={12} sm={6} md={4}>
            <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              size="small"
            >
              <b>Company Type</b>
              <Select
                showSearch
                placeholder="Company Type"
                optionFilterProp="children"
                value={editJobStore.selectedIMQuote.companytype}
                onChange={(e) =>
                  editJobStore.updateSelectedIMQuote(
                    e,
                    this.state.intindex,
                    "companytype"
                  )
                }
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value={"Freight Forwarder"}>Freight Forwarder</Option>
                <Option value={"Sea Transportation"}>Sea Transportation</Option>
                <Option value={"Air Transporation"}>Air Transporation</Option>
                <Option value={"Land Transportation"}>
                  Land Transportation
                </Option>
                <Option value={"Customs Clearance"}>Customs Clearance</Option>
                <Option value={"Other"}>Other</Option>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              size="small"
            >
              <b>Company</b>

              <Select
                showSearch
                placeholder="Company"
                optionFilterProp="children"
                value={editJobStore.selectedIMQuote.company}
                onChange={(e) =>
                  editJobStore.updateSelectedIMQuote(
                    e,
                    this.state.intindex,
                    "company"
                  )
                }
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {intquotecompanies}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <TextField
              size="small"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Price"
              name="price"
              autoComplete="price"
              type="number"
              value={editJobStore.selectedIMQuote.price}
              onChange={(e) =>
                editJobStore.updateSelectedIMQuote(
                  e.target.value,
                  this.state.intindex,
                  "price"
                )
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={12} sm={4} md={4}>
            <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              size="small"
            >
              <b>Rating</b>
              <Select
                showSearch
                placeholder="Rating"
                optionFilterProp="children"
                value={editJobStore.selectedIMQuote.rating}
                onChange={(e) =>
                  editJobStore.updateSelectedIMQuote(
                    e,
                    this.state.intindex,
                    "rating"
                  )
                }
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value="0">Approved Company</Option>
                <Option value="1">High</Option>
                <Option value="2">Average</Option>
                <Option value="3">Low</Option>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={8} md={8}>
            <FormControl variant="outlined" style={{ width: "100%" }}>
              <TextArea
                size="small"
                placeholder="Quote Remarks"
                autoSize
                value={editJobStore.selectedIMQuote.remark}
                onChange={(e) =>
                  editJobStore.updateSelectedIMQuote(
                    e.target.value,
                    this.state.intindex,
                    "remark"
                  )
                }
              />
            </FormControl>
          </Grid>
        </Grid>
        <hr />
      </div>
    );

    return (
      <div>
        <Modal
          show={this.state.show}
          onHide={this.handleClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>International Quote</Modal.Title>
          </Modal.Header>
          <Modal.Body>{intquoterows}</Modal.Body>
          <Modal.Footer>
            <Button
              variant="success"
              disabled={
                editJobStore.selectedIMQuote.company === "" ||
                editJobStore.selectedIMQuote.companytype === "" ||
                editJobStore.selectedIMQuote.price === "" ||
                editJobStore.selectedIMQuote.price === null
              }
              onClick={() => {
                this.handleClose();
                editJobStore.updateSelectedIMQuote(
                  0,
                  this.state.intindex,
                  "update"
                );
              }}
            >
              Submit
            </Button>
            <Button
              variant="danger"
              onClick={() => {
                this.handleClose();
                editJobStore.updateSelectedIMQuote(
                  0,
                  this.state.intindex,
                  "reset"
                );
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
        <Container style={{ maxWidth: "98%" }}>
          <h3>
            IM Breakdown Details
            <br />
            <h4>
              Client Quoted = {editJobStore.totaljobprice} KD
              <br />
              Charges to Be Paid by Easy Move ={" "}
              {Math.round(
                (1000 *
                  (editJobStore.imSection2Cost +
                    editJobStore.imSection3Cost +
                    editJobStore.imSection4Cost +
                    editJobStore.imSection5Cost)) /
                  1000
              )}
              {" KD"}
              <br />
              Profit = {editJobStore.profitTotal}
              {" KD"}
            </h4>
            <h5>
              Origin Charges = {editJobStore.imSection2Cost} KD
              <br />
              Shipping Charges = {editJobStore.imSection3Cost} KD
              <br />
              Destination Charges = {editJobStore.imSection4Cost} KD
              <br />
              {editJobStore.internationalfee.commodity === "Dangerous Goods" &&
                `
              Dangerous Goods Charges = ${editJobStore.imSection5Cost} KD
              `}
            </h5>
          </h3>
          <hr />
          <h4>Section 1: General Job Details</h4>
          <Row>
            <Col>
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                size="small"
              >
                <b
                  style={{
                    color:
                      editJobStore.internationalfee.commodity === ""
                        ? "red"
                        : "black",
                  }}
                >
                  Commodity *
                </b>
                <Select
                  showSearch
                  error
                  width="100%"
                  optionFilterProp="children"
                  onChange={(e) => editJobStore.updateIntFee("commodity", e)}
                  value={editJobStore.internationalfee.commodity}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value={""}></Option>
                  <Option value={"Personal Effect"}>Personal Effect</Option>
                  <Option value={"General Cargo"}>General Cargo</Option>
                  <Option value={"Break Bulk"}>Break Bulk</Option>
                  <Option value={"Dangerous Goods"}>Dangerous Goods</Option>
                </Select>
              </FormControl>
            </Col>
            <Col>
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                size="small"
              >
                <b
                  style={{
                    color:
                      editJobStore.internationalfee.shippingmethod === ""
                        ? "red"
                        : "black",
                  }}
                >
                  Shipping Method *
                </b>
                <Select
                  showSearch
                  error
                  width="100%"
                  optionFilterProp="children"
                  onChange={(e) =>
                    editJobStore.updateIntFee("shippingmethod", e)
                  }
                  value={editJobStore.internationalfee.shippingmethod}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value={""}></Option>
                  <Option value={"Air"}>Air</Option>
                  <Option value={"Land"}>Land</Option>
                  <Option value={"Sea"}>Sea</Option>
                </Select>
              </FormControl>
            </Col>
            <Col>
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                size="small"
              >
                <b
                  style={{
                    color:
                      editJobStore.internationalfee.containerType === ""
                        ? "red"
                        : "black",
                  }}
                >
                  Container Type *
                </b>
                {containeroptions}
              </FormControl>
            </Col>
            <Col>
              {editJobStore.internationalfee.shippingmethod === "Air" &&
                volumetricweight}
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <TextField
                variant="outlined"
                label="Length (M)"
                type="number"
                size="small"
                style={{ width: "100%" }}
                value={editJobStore.internationalfee.imlength}
                onChange={(e) =>
                  editJobStore.updateIntFee("imlength", e.target.value)
                }
              />
            </Col>
            <Col>
              <TextField
                variant="outlined"
                label="Width (M)"
                type="number"
                size="small"
                style={{ width: "100%" }}
                value={editJobStore.internationalfee.imwidth}
                onChange={(e) =>
                  editJobStore.updateIntFee("imwidth", e.target.value)
                }
              />
            </Col>
            <Col>
              <TextField
                variant="outlined"
                label="Height (M)"
                type="number"
                size="small"
                style={{ width: "100%" }}
                value={editJobStore.internationalfee.imheight}
                onChange={(e) =>
                  editJobStore.updateIntFee("imheight", e.target.value)
                }
              />
            </Col>
            <Col>
              <TextField
                variant="outlined"
                label="Weight (kg)"
                type="number"
                size="small"
                style={{ width: "100%" }}
                value={editJobStore.job.weight}
                onChange={(e) =>
                  editJobStore.updateJob("weight", e.target.value)
                }
              />
            </Col>
          </Row>
          <hr />
          <h4>Section 2: Origin Charges ({editJobStore.imSection2Cost} KD )</h4>
          <Row>
            <Col>
              <TextField
                variant="outlined"
                label="Wrapping and Packaging Charges"
                type="number"
                size="small"
                style={{ width: "100%" }}
                value={editJobStore.internationalfee.intwrapandpack}
                onChange={(e) =>
                  editJobStore.updateIntFee("intwrapandpack", e.target.value)
                }
              />
            </Col>
            <Col>
              <TextField
                variant="outlined"
                label="Crating Charges"
                type="number"
                size="small"
                style={{ width: "100%" }}
                value={editJobStore.internationalfee.cratecharge}
                onChange={(e) =>
                  editJobStore.updateIntFee("cratecharge", e.target.value)
                }
              />
            </Col>
            <Col>
              <TextField
                variant="outlined"
                label="Palletization Charges"
                type="number"
                size="small"
                style={{ width: "100%" }}
                value={editJobStore.internationalfee.palletcharge}
                onChange={(e) =>
                  editJobStore.updateIntFee("palletcharge", e.target.value)
                }
              />
            </Col>
            <Col>
              <TextField
                variant="outlined"
                label="Loading Charges"
                type="number"
                size="small"
                style={{ width: "100%" }}
                value={editJobStore.internationalfee.loadingcharge}
                onChange={(e) =>
                  editJobStore.updateIntFee("loadingcharge", e.target.value)
                }
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <TextField
                variant="outlined"
                label="Transporation Charges"
                type="number"
                size="small"
                style={{ width: "100%" }}
                value={editJobStore.internationalfee.transportationcharges}
                onChange={(e) =>
                  editJobStore.updateIntFee(
                    "transportationcharges",
                    e.target.value
                  )
                }
              />
            </Col>
            <Col>
              <TextField
                variant="outlined"
                label="Origin Storage In/Out Charges"
                type="number"
                size="small"
                style={{ width: "100%" }}
                value={editJobStore.internationalfee.originstorageinout}
                onChange={(e) =>
                  editJobStore.updateIntFee(
                    "originstorageinout",
                    e.target.value
                  )
                }
              />
            </Col>
            <Col>
              <TextField
                variant="outlined"
                label="Origin Clearance Charges"
                type="number"
                size="small"
                style={{ width: "100%" }}
                value={editJobStore.internationalfee.originclearance}
                onChange={(e) =>
                  editJobStore.updateIntFee("originclearance", e.target.value)
                }
              />
            </Col>
          </Row>
          <hr />
          <h4>
            Section 3: Shipping Charges: ({editJobStore.imSection3Cost} KD )
          </h4>
          <Row>
            <Col>
              <TextField
                variant="outlined"
                label="Shipping Line Charges"
                type="number"
                size="small"
                style={{ width: "100%" }}
                value={editJobStore.internationalfee.shippingcharges}
                onChange={(e) =>
                  editJobStore.updateIntFee("shippingcharges", e.target.value)
                }
              />
            </Col>
            <Col>
              <TextField
                variant="outlined"
                label="Transit Clearance Charges"
                type="number"
                size="small"
                style={{ width: "100%" }}
                value={editJobStore.internationalfee.transitclearance}
                onChange={(e) =>
                  editJobStore.updateIntFee("transitclearance", e.target.value)
                }
              />
            </Col>
          </Row>
          <hr />
          <h4>
            Section 4: Destination Charges: ({editJobStore.imSection4Cost} KD )
          </h4>
          <Row>
            <Col>
              <TextField
                variant="outlined"
                label="Delivery Order Charges"
                type="number"
                size="small"
                style={{ width: "100%" }}
                value={editJobStore.internationalfee.docharge}
                onChange={(e) =>
                  editJobStore.updateIntFee("docharge", e.target.value)
                }
              />
            </Col>
            <Col>
              <TextField
                variant="outlined"
                label="Terminal Handling Charges"
                type="number"
                size="small"
                style={{ width: "100%" }}
                value={editJobStore.internationalfee.thcharge}
                onChange={(e) =>
                  editJobStore.updateIntFee("thcharge", e.target.value)
                }
              />
            </Col>
            <Col>
              <TextField
                variant="outlined"
                label="Destination Clearance Charges"
                type="number"
                size="small"
                style={{ width: "100%" }}
                value={editJobStore.internationalfee.destinationclearance}
                onChange={(e) =>
                  editJobStore.updateIntFee(
                    "destinationclearance",
                    e.target.value
                  )
                }
              />
            </Col>
            <Col>
              <TextField
                variant="outlined"
                label="Shuttle Charges"
                type="number"
                size="small"
                style={{ width: "100%" }}
                value={editJobStore.internationalfee.shuttlecharge}
                onChange={(e) =>
                  editJobStore.updateIntFee("shuttlecharge", e.target.value)
                }
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <TextField
                variant="outlined"
                label="Demurrage Charges"
                type="number"
                size="small"
                style={{ width: "100%" }}
                value={editJobStore.internationalfee.demoragecharge}
                onChange={(e) =>
                  editJobStore.updateIntFee("demoragecharge", e.target.value)
                }
              />
            </Col>
            <Col>
              <TextField
                variant="outlined"
                label="Detention Charges"
                type="number"
                size="small"
                style={{ width: "100%" }}
                value={editJobStore.internationalfee.detentioncharge}
                onChange={(e) =>
                  editJobStore.updateIntFee("detentioncharge", e.target.value)
                }
              />
            </Col>
            <Col>
              <TextField
                variant="outlined"
                label="Destination Storage In/Out Charges"
                type="number"
                size="small"
                style={{ width: "100%" }}
                value={editJobStore.internationalfee.destinationstorageinout}
                onChange={(e) =>
                  editJobStore.updateIntFee(
                    "destinationstorageinout",
                    e.target.value
                  )
                }
              />
            </Col>
            <Col>
              <TextField
                variant="outlined"
                label="Unloading Charges"
                type="number"
                size="small"
                style={{ width: "100%" }}
                value={editJobStore.internationalfee.unloadingcharge}
                onChange={(e) =>
                  editJobStore.updateIntFee("unloadingcharge", e.target.value)
                }
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <TextField
                variant="outlined"
                label="Assemnling Charges"
                type="number"
                size="small"
                style={{ width: "100%" }}
                value={editJobStore.internationalfee.assemblingcharge}
                onChange={(e) =>
                  editJobStore.updateIntFee("assemblingcharge", e.target.value)
                }
              />
            </Col>
            <Col>
              <TextField
                variant="outlined"
                label="Debris Removal Charges"
                type="number"
                size="small"
                style={{ width: "100%" }}
                value={editJobStore.internationalfee.debrisremoval}
                onChange={(e) =>
                  editJobStore.updateIntFee("debrisremoval", e.target.value)
                }
              />
            </Col>
            <Col>
              <TextField
                variant="outlined"
                label="Container Return Charges"
                type="number"
                size="small"
                style={{ width: "100%" }}
                value={editJobStore.internationalfee.containerreturn}
                onChange={(e) =>
                  editJobStore.updateIntFee("containerreturn", e.target.value)
                }
              />
            </Col>
          </Row>
          <hr />
          {editJobStore.internationalfee.commodity === "Dangerous Goods" &&
            dangerousgoodstab}
          {intquoterows2}
        </Container>
      </div>
    );
  }
}

export default observer(IMPage);
