import { decorate, observable, computed } from "mobx";
import axios from "axios";
import moment from "moment";

const instance = axios.create({
  // baseURL: "https://easymovecalendar.app/"
  // baseURL: "http://127.0.0.1:8000/",
  // baseURL: "http://157.230.113.108/",
  baseURL: "https://jazzafs.com/",
});

class ManagementStore {
  constructor() {
    this.attendanceloaded = false;
    this.attendance = [];
    this.profiles = [];
    this.pipelinejobs = [];
    this.loading = false;
    this.pipelineloaded = false;
    this.filtType = "All";
    this.filtStaff = "";
    this.filterjobs = {
      lead: true,
      proposal: true,
      booked: true,
      converted: true,
      cancelled: false,
    };
    this.stafflist = {
      reyna: true,
      aasim: true,
      yobel: true,
      katrina: true,
      vinith: true,
    };
  }

  changeStaff(type) {
    if (type === "Reyna") this.stafflist.reyna = !this.stafflist.reyna;
    if (type === "Yobel") this.stafflist.yobel = !this.stafflist.yobel;
    if (type === "Aasim") this.stafflist.aasim = !this.stafflist.aasim;
    if (type === "Katrina") this.stafflist.katrina = !this.stafflist.katrina;
    if (type === "Yobel") this.stafflist.yobel = !this.stafflist.yobel;
  }

  fetchPipeline() {
    if (!this.pipelineloaded) {
      this.loading = true;
      return instance
        .get("/n-pipeline/?format=json")
        .then((res) => res.data)
        .then((jobs) => {
          let filteredjobs = [];
          for (let i = 0; i < jobs.length; i++) {
            for (let j = 0; j < jobs[i].jobdate.length; j++) {
              filteredjobs.push({
                id: jobs[i].id,
                cbm: jobs[i].cbm,
                lastprice: jobs[i].lastprice,
                profit: jobs[i].profit,
                jobmain: jobs[i].jobmain,
                status: jobs[i].status,
                jobdate: jobs[i].jobdate[j].jobdate,
                emfocalpoint: jobs[i].emfocalpoint,
                dailycbm:
                  parseFloat(jobs[i].cbm) / parseFloat(jobs[i].datecount),
                dailyprice:
                  parseFloat(jobs[i].lastprice) / parseFloat(jobs[i].datecount),
                dailyprofit:
                  parseFloat(jobs[i].profit) / parseFloat(jobs[i].datecount),
              });
            }
          }
          this.pipelinejobs = filteredjobs;
          this.pipelineloaded = true;
          this.loading = false;
        });
    }
  }
  alternateLoading() {
    console.log("trial");
    this.loading = true;
    this.loading = false;
  }

  fetchAttendance() {
    this.loading = true;
    return instance
      .get("/attendancelist/?format=json")
      .then((res) => res.data)
      .then((attendance) => {
        this.attendance = attendance;
        if (this.attendance.length > 0) {
          for (let i = 0; i < this.attendance.length; i++) {
            this.attendance[i].newdate = moment(
              this.attendance[i].date
            ).subtract(3, "hours");
            this.attendance[i].formatteddate = moment(this.attendance[i].date)
              .subtract(3, "hours")
              .format("DD-MMM-YY HH:mm");
            this.attendance[i].timeago = moment(this.attendance[i].date)
              .subtract(3, "hours")
              .fromNow();
          }
        }
        console.log("Attendance Loaded");
        this.fetchProfiles();
      });
  }
  fetchProfiles() {
    this.loading = true;
    return instance
      .get("/profiles/?format=json")
      .then((res) => res.data)
      .then((attendance) => {
        this.profiles = attendance;
        if (this.profiles.length > 0) {
          for (let i = 0; i < this.profiles.length; i++) {
            this.profiles[i].newdate = moment(
              this.profiles[i].last_signin
            ).subtract(3, "hours");
            this.profiles[i].formatteddate = moment(
              this.profiles[i].last_signin
            )
              .subtract(3, "hours")
              .format("DD-MMM-YY HH:mm");
            this.profiles[i].timeago = moment(this.profiles[i].last_signin)
              .subtract(3, "hours")
              .fromNow();
          }
        }
        console.log("Attendance Loaded");
        this.loading = false;
        this.attendanceloaded = true;
      });
  }

  changeDashboard(type) {
    this.filtType = type;
  }

  changeJobFilter(type) {
    if (type === "reset") {
      this.filterjobs = {
        lead: true,
        proposal: true,
        booked: true,
        converted: true,
        cancelled: false,
      };
    } else {
      if (type === "lead") {
        this.filterjobs.lead = !this.filterjobs.lead;
      }
      if (type === "proposal") {
        this.filterjobs.proposal = !this.filterjobs.proposal;
      }
      if (type === "booked") {
        this.filterjobs.booked = !this.filterjobs.booked;
      }
      if (type === "converted") {
        this.filterjobs.converted = !this.filterjobs.converted;
      }
      if (type === "cancelled") {
        this.filterjobs.cancelled = !this.filterjobs.cancelled;
      }
    }
  }

  filterStaff(val) {
    this.filtStaff = val;
    if (val === "reset") {
      this.filtStaff = "";
    }
  }

  get filteredPipeline() {
    let filtjobs = this.pipelinejobs;

    if (this.filtType === "Local") {
      filtjobs = filtjobs.filter(
        (job) =>
          job.jobmain === "Local Move" || job.jobmain === "Corporate Move"
      );
    } else if (this.filtType === "im") {
      filtjobs = filtjobs.filter(
        (job) =>
          job.jobmain === "IM - Inbound" ||
          job.jobmain === "IM - Outbound" ||
          job.jobmain === "CIM - Inbound" ||
          job.jobmain === "CIM - Outbound"
      );
    }
    if (this.filtStaff !== "") {
      if (this.filtStaff === "empty") {
        filtjobs = filtjobs.filter((job) => job.emfocalpoint !== "Aasim");
        filtjobs = filtjobs.filter((job) => job.emfocalpoint !== "Katrina");
        filtjobs = filtjobs.filter((job) => job.emfocalpoint !== "Reyna");
        filtjobs = filtjobs.filter((job) => job.emfocalpoint !== "Vinith");
        filtjobs = filtjobs.filter((job) => job.emfocalpoint !== "Yobel");
      } else {
        filtjobs = filtjobs.filter(
          (job) => job.emfocalpoint === this.filtStaff
        );
      }
    }

    if (!this.filterjobs.lead) {
      filtjobs = filtjobs.filter((job) => job.status !== "Lead");
      filtjobs = filtjobs.filter((job) => job.status !== "Estimation");
    }

    if (!this.filterjobs.proposal)
      filtjobs = filtjobs.filter((job) => job.status !== "Proposal");

    if (!this.filterjobs.booked)
      filtjobs = filtjobs.filter((job) => job.status !== "Booked");

    if (!this.filterjobs.converted)
      filtjobs = filtjobs.filter((job) => job.status !== "Converted");

    if (!this.filterjobs.cancelled)
      filtjobs = filtjobs.filter((job) => job.status !== "Cancelled");

    return filtjobs;
  }
}
decorate(ManagementStore, {
  attendanceloaded: observable,
  attendance: observable,
  profiles: observable,
  pipelinejobs: observable,
  loading: observable,
  pipelineloaded: observable,
  filtType: observable,
  filtStaff: observable,
  filterjobs: observable,
  stafflist: observable,
  filteredPipeline: computed,
});

const managementStore = new ManagementStore();
export default managementStore;
