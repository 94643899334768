import React from "react";
// First way to import
import { observer } from "mobx-react";
import { Button, Container } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import { Select } from "antd";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import jobEditStore from "../Stores/JobEditStore";

const { Option } = Select;

class JobDatePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    // let jobdate = jobEditStore.jobdates[0];
    // let index = 0;
    let excludeDates = [];

    for (let i = 0; i < jobEditStore.jobdates.length; i++) {
      // if (jobdate.jobdate !== jobEditStore.jobdates[i].jobdate) {
      excludeDates.push(Date.parse(jobEditStore.jobdates[i].jobdate));
      // }
    }

    let daterows;
    daterows = jobEditStore.jobdates.map((jobdate, index) => (
      <Grid container spacing={1} alignItems="center" key={index}>
        <Grid item xs={12} sm={3} md={2}>
          <FormControl variant="outlined" style={{ width: "100%" }}>
            <b>Job Date</b>
            <DatePicker
              onChange={(date) =>
                jobEditStore.updateJobDate(
                  moment(date).format("YYYY-MM-DD"),
                  index,
                  "jobdate"
                )
              }
              dateFormat="dd-MMM-yy"
              monthsShown={2}
              selected={Date.parse(jobdate.jobdate)}
              excludeDates={excludeDates}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={3} md={2}>
          <FormControl
            variant="outlined"
            style={{ width: "100%" }}
            size="small"
          >
            <b>Status</b>
            <Select
              showSearch
              placeholder="Storage Task"
              optionFilterProp="children"
              value={jobdate.status}
              onChange={(e) => {
                jobEditStore.updateJobDate(e, index, "status");
              }}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value="" />
              <Option value={"Pending"}>Pending</Option>
              <Option value={"Complete"}>Complete</Option>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={7}>
          <FormControl
            variant="outlined"
            style={{ width: "100%" }}
            size="small"
          >
            <TextField
              size="small"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="remark"
              label="remark"
              autoComplete="remark"
              autoFocus
              type="text"
              style={{ color: "red" }}
              value={jobdate.dateremarks}
              onChange={(e) =>
                jobEditStore.updateJobDate(e.target.value, index, "dateremarks")
              }
            />
          </FormControl>
        </Grid>
        <Grid item xs={2} sm={1} md={1}>
          <Button
            variant="outline-danger"
            block
            onClick={() =>
              jobEditStore.postJobDateUpdate(
                [],
                jobEditStore.jobdates[index].id,
                "destroy",
                index
              )
            }
          >
            <i className="fas fa-times"></i>
          </Button>
        </Grid>
      </Grid>
    ));

    if (jobEditStore.jobdates.length < 1) {
      daterows = "No dates selected";
    }

    return (
      <div>
        <Container style={{ maxWidth: "98%" }}>
          <h6>Quality Score = {jobEditStore.datesQuality}%</h6>
          <h4>{jobEditStore.jobdates.length} Schedule Job Days</h4>
          <hr />
          <form>{daterows}</form>
          <br />
          <Button
            block
            variant="success"
            onClick={() => jobEditStore.addJobDate()}
          >
            Add a new Job Date
          </Button>
        </Container>
      </div>
    );
  }
}

export default observer(JobDatePage);
