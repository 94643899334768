import React from "react";
// First way to import
import { observer } from "mobx-react";
import eventStore from "../Stores/EventStore";
import settingStore from "../Stores/SettingStore";
import { Card, Button, Col, Row } from "react-bootstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import Grid from "@material-ui/core/Grid";
import Modal from "react-bootstrap/Modal";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import { Input } from "antd";

import jobEditStore from "../Stores/JobEditStore";
import NewJobModal from "./NewJobModal";
const { TextArea } = Input;

class JobList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
      show: false,
      showUpdate: false,
    };
    this.options = {
      defaultSortName: "id", // default sort column name
      // exportCSVBtn: this.createCustomExportCSVButton,
      defaultSortOrder: "asc", // default sort order
      onRowClick: function (job) {
        jobEditStore.selectJob(job.id);
      },
    };
  }
  handleClose = () => this.setState({ show: false });
  handleCloseUpdate = () => this.setState({ showUpdate: false });
  handleShow = () => {
    jobEditStore.resetPage();
    this.setState({ show: true });
  };
  handleShowUpdate = () => this.setState({ showUpdate: true });

  render() {
    let jobcards;

    if (!eventStore.editClient.job) {
      return <div />;
    } else if (eventStore.editClient.job.length === 1) {
      jobcards = (
        <Card style={{ width: "18rem" }}>
          <Card.Body>
            <Card.Title style={{ fontColor: "black" }}>
              {eventStore.editClient.job[0].status} - (
              {eventStore.editClient.job[0].id})
            </Card.Title>
            <Card.Subtitle style={{ fontColor: "black" }}>
              {eventStore.editClient.job[0].jobmain} -{" "}
              {eventStore.editClient.job[0].jobsecond}
            </Card.Subtitle>
            <Card.Subtitle> </Card.Subtitle>

            <Card.Text>
              Some quick example text to build on the card title and make up the
              bulk of the card's content.
            </Card.Text>
            <Button block variant="dark">
              Edit Job
            </Button>
          </Card.Body>
        </Card>
      );
    } else if (eventStore.editClient.job.length > 1) {
      jobcards = eventStore.editClient.job.map((job, index) => (
        <Col key={index}>
          <Card style={{ width: "18rem" }}>
            <Card.Body>
              <Card.Title style={{ fontColor: "black" }}>
                {job.status} - ({job.id})
              </Card.Title>
              <Card.Subtitle style={{ fontColor: "black" }}>
                {job.jobmain} - {job.jobsecond}
              </Card.Subtitle>
              <Card.Subtitle> </Card.Subtitle>

              <Card.Text>
                Some quick example text to build on the card title and make up
                the bulk of the card's content.
              </Card.Text>
              <Button
                block
                variant="dark"
                onClick={() => eventStore.fetchSingleJob(job.id)}
              >
                Edit Job
              </Button>
            </Card.Body>
          </Card>
        </Col>
      ));
    }

    jobcards = (
      <BootstrapTable
        data={eventStore.editClient.job}
        striped
        hover
        search={true}
        condensed
        options={this.options}
      >
        <TableHeaderColumn isKey dataField="id" dataSort>
          Job id
        </TableHeaderColumn>
        <TableHeaderColumn dataField="jobmain" dataSort>
          Job Type
        </TableHeaderColumn>
        <TableHeaderColumn dataField="jobsecond" dataSort>
          Secondary
        </TableHeaderColumn>
        <TableHeaderColumn dataField="status" dataSort>
          Status
        </TableHeaderColumn>
        <TableHeaderColumn dataField="cbm" dataSort>
          CBM
        </TableHeaderColumn>
        <TableHeaderColumn dataField="price" dataSort>
          Price
        </TableHeaderColumn>
        <TableHeaderColumn dataField="remarks" dataSort>
          Remark
        </TableHeaderColumn>
      </BootstrapTable>
    );

    let newClientForm = (
      <div>
        <form>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="title"
            label="Name"
            name="title"
            autoComplete="title"
            autoFocus
            type="text"
            value={eventStore.editClient.title}
            onChange={(e) =>
              eventStore.updateClientDetails(e.target.value, "title")
            }
          />
          <TextField
            style={{ width: "25%" }}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="phone1"
            label="Phone 1"
            name="phone1"
            autoComplete="phone1"
            autoFocus
            type="text"
            value={eventStore.editClient.phone1}
            onChange={(e) =>
              eventStore.updateClientDetails(e.target.value, "phone1")
            }
          />
          <TextField
            style={{ width: "25%" }}
            variant="outlined"
            margin="normal"
            fullWidth
            id="phone2"
            label="Phone 2"
            name="phone2"
            autoComplete="phone2"
            autoFocus
            type="text"
            value={eventStore.editClient.phone2}
            onChange={(e) =>
              eventStore.updateClientDetails(e.target.value, "phone2")
            }
          />
          <TextField
            style={{ width: "50%" }}
            variant="outlined"
            margin="normal"
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            type="email"
            value={eventStore.editClient.email}
            onChange={(e) =>
              eventStore.updateClientDetails(e.target.value, "email")
            }
          />
          <br />
          <br />
          <FormControl variant="outlined" style={{ width: "50%" }}>
            <InputLabel>Lead Type</InputLabel>
            <Select
              native
              label="Age"
              value={eventStore.editClient.leadtype}
              onChange={(e) =>
                eventStore.updateClientDetails(e.target.value, "leadtype")
              }
              inputProps={{
                name: "age",
                id: "outlined-age-native-simple",
              }}
            >
              <option value="" />
              <option value={"Received Call"}>Received Call</option>
              <option value={"Missed Call"}>Missed Call</option>
              <option value={"Email Lead"}>Email Lead</option>
              <option value={"Online Lead"}>Online Lead</option>
              <option value={"Referral"}>Referral</option>
              <option value={"Other"}>Other</option>
            </Select>
          </FormControl>
          <FormControl variant="outlined" style={{ width: "50%" }}>
            <InputLabel>How They Heard of Us?</InputLabel>
            <Select
              native
              value={eventStore.editClient.heardofus}
              onChange={(e) =>
                eventStore.updateClientDetails(e.target.value, "heardofus")
              }
              inputProps={{
                name: "age",
                id: "outlined-age-native-simple",
              }}
            >
              <option value="" />
              <option value={"Google"}>Google</option>
              <option value={"Instagram"}>Instagram</option>
              <option value={"Facebook"}>Facebook</option>
              <option value={"Google Maps"}>Google Maps</option>
              <option value={"Returning Customer"}>Returning Customer</option>
              <option value={"Referrals"}>Referrals</option>
              <option value={"Other"}>Other</option>
            </Select>
          </FormControl>
          <br />
          <br />
        </form>
        <b>Client Remarks:</b>
        <TextArea
          style={{ width: "100%" }}
          placeholder="Add client remarks here"
          autoSize={{ minRows: 2, maxRows: 10 }}
          value={eventStore.editClient.remarks}
          onChange={(e) =>
            eventStore.updateClientDetails(e.target.value, "remarks")
          }
        />
      </div>
    );

    return (
      <div>
        <Modal
          show={this.state.show}
          onHide={this.handleClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Create A New Job for {eventStore.editClient.title}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <NewJobModal />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                this.handleClose();
                jobEditStore.postUpdate();
              }}
            >
              Create Job
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={this.state.showUpdate}
          onHide={this.handleCloseUpdate}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>
              Update Client {eventStore.editClient.title}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>{newClientForm}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleCloseUpdate}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                this.handleCloseUpdate();
                eventStore.updateClient();
                settingStore.changeNewClient(false);
              }}
            >
              Update Client
            </Button>
          </Modal.Footer>
        </Modal>
        <Card.Title>
          <Button
            size="sm"
            variant="outline-danger"
            onClick={() => {
              //              settingStore.changeViewClient(true);
              //              settingStore.changeEditClient(true);
              this.handleShowUpdate();
            }}
          >
            <i className="fas fa-pencil-alt"></i>
          </Button>
          {"    "}
          <b>{eventStore.editClient.title}</b>{" "}
          {eventStore.editClient.job.length}{" "}
          {eventStore.editClient.job.length === 1 ? "job" : "jobs"}:
        </Card.Title>
        <Grid container style={{ justifyContent: "space-between" }}>
          <Button variant="success" onClick={this.handleShow}>
            + New Job
          </Button>
          <Button
            onClick={() => settingStore.resetViews()}
            variant="outline-danger"
          >
            Close Job List
          </Button>
        </Grid>

        <Row style={{ justifyContent: "flex-start" }}>{jobcards}</Row>
        <br />
        <br />
      </div>
    );
  }
}

export default observer(JobList);
