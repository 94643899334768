import React from "react";
// First way to import
import { observer } from "mobx-react";
import { Modal, Button } from "react-bootstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";

import { Select } from "antd";

import warehouseStore from "../Stores/WarehouseStore";

const { Option } = Select;
class InventoryDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
      show: false,
    };
    this.options3 = {
      defaultSortName: "start",
      defaultSortOrder: "asc",
      onRowClick: function (event) {
        warehouseStore.chooseJob(event);
      },
    };
  }

  componentDidMount() {
    // warehouseStore.fetchInventoryIn();
  }

  handleClose = () => warehouseStore.changeModalView("close");

  render() {
    let addtostockmodal = (
      <div>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl
            variant="outlined"
            style={{ width: "100%" }}
            size="small"
          >
            <b>To:</b>
            <Select
              showSearch
              placeholder="To:"
              optionFilterProp="children"
              value={warehouseStore.userto}
              onChange={(e) => warehouseStore.updateInventory(e, "userto")}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={""}></Option>
              <Option value={"AbuHulaifa"}>AbuHulaifa</Option>
              <Option value={"Faiha"}>Faiha</Option>
            </Select>
          </FormControl>
        </Grid>
      </div>
    );
    let movestockmodal = (
      <div>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl
            variant="outlined"
            style={{ width: "100%" }}
            size="small"
          >
            <b>From:</b>
            <Select
              showSearch
              placeholder="From:"
              optionFilterProp="children"
              value={warehouseStore.userfrom}
              onChange={(e) => warehouseStore.updateInventory(e, "userfrom")}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={""}></Option>
              <Option value={"AbuHulaifa"}>AbuHulaifa</Option>
              <Option value={"Faiha"}>Faiha</Option>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <FormControl
            variant="outlined"
            style={{ width: "100%" }}
            size="small"
          >
            <b>To:</b>
            <Select
              showSearch
              placeholder="To:"
              optionFilterProp="children"
              value={warehouseStore.userto}
              onChange={(e) => warehouseStore.updateInventory(e, "userto")}
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
            >
              <Option value={""}></Option>
              <Option value={"Ravi"}>Ravi</Option>
              <Option value={"Army"}>Army</Option>
            </Select>
          </FormControl>
        </Grid>
      </div>
    );

    const options = {
      defaultSortName: "qty",
      defaultSortOrder: "asc",
    };
    return (
      <div>
        <Modal
          show={warehouseStore.showModal}
          onHide={this.handleClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ fontSize: 16, fontWeight: "bold" }}>
              {warehouseStore.header}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ fontSize: 12 }}>
            <Grid item xs={12} sm={6} md={4}>
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                size="small"
              >
                <b>Material</b>
                <Select
                  showSearch
                  autoFocus
                  placeholder="Material"
                  optionFilterProp="children"
                  value={warehouseStore.material}
                  onChange={(e) =>
                    warehouseStore.updateInventory(e, "material")
                  }
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value={""}></Option>
                  <Option value={"Bubble Wrap"}>Bubble Wrap</Option>
                  <Option value={"Stretch Film"}>Stretch Film</Option>
                  <Option value={"Nylon Wrap"}>Nylon Wrap</Option>
                  <Option value={"Easy Move Boxes"}>Easy Move Boxes</Option>
                  <Option value={"Local Tape"}>Local Tape</Option>
                  <Option value={"Shipping Tape"}>Shipping Tape</Option>
                </Select>
              </FormControl>
            </Grid>
            <Grid item>
              <TextField
                size="small"
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label={
                  warehouseStore.material === "Bubble Wrap"
                    ? "Meters"
                    : "Quantity"
                }
                name="quantity"
                autoComplete="quantity"
                type="number"
                value={warehouseStore.quantity}
                onChange={(e) =>
                  warehouseStore.updateInventory(e.target.value, "quantity")
                }
              />
            </Grid>
            {warehouseStore.header === "Add to Stock" && addtostockmodal}
            {warehouseStore.header === "Withdraw Stock" && movestockmodal}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                if (warehouseStore.header === "Add to Stock") {
                  if (
                    warehouseStore.quantity <= 0 ||
                    isNaN(warehouseStore.quantity)
                  ) {
                    alert("Please add a Valid Quantity");
                  } else if (warehouseStore.material === "") {
                    alert("Please select the material");
                  } else if (warehouseStore.userto === "") {
                    alert("Please select where the Stock will be added");
                  } else {
                    warehouseStore.postInventoryChange();
                  }
                } else {
                  if (
                    warehouseStore.quantity <= 0 ||
                    isNaN(warehouseStore.quantity)
                  ) {
                    alert("Please add a Valid Quantity");
                  } else if (warehouseStore.material === "") {
                    alert("Please select the material");
                  } else if (warehouseStore.userfrom === "") {
                    alert("Please select Where the stock will come from");
                  } else if (warehouseStore.userto === "") {
                    alert("Please select who will receive the stock");
                  } else {
                    warehouseStore.postInventoryChange();
                  }
                }
              }}
            >
              {warehouseStore.header === "Add to Stock"
                ? "Add New Items Stock"
                : `Transfer items to ${warehouseStore.userto}`}
            </Button>
          </Modal.Footer>
        </Modal>
        <Button
          block
          variant="success"
          onClick={() => warehouseStore.changeModalView("Add to Stock")}
        >
          Add New Stock
        </Button>
        <Button
          block
          variant="outline-danger"
          onClick={() => warehouseStore.changeModalView("Withdraw Stock")}
        >
          Withdraw Stock
        </Button>
        <br />
        <Button
          block
          variant={"outline-dark"}
          onClick={() => warehouseStore.updateAllInventory()}
        >
          {!warehouseStore.inventoryUpdated && "Click for "}Combined Inventory
        </Button>
        {warehouseStore.inventoryUpdated && (
          <div>
            <BootstrapTable
              data={warehouseStore.inventorySummary}
              striped
              hover
              condensed
              options={options}
            >
              <TableHeaderColumn isKey dataField="type" dataSort>
                Inventory
              </TableHeaderColumn>
              <TableHeaderColumn width="20%" dataField="qty" dataSort>
                QTY
              </TableHeaderColumn>
            </BootstrapTable>
            <br />
            <Button block variant={"outline-dark"} disabled>
              Abu Hulaifa
            </Button>
            <BootstrapTable
              data={warehouseStore.inventorySummaryAB}
              striped
              hover
              condensed
              options={options}
            >
              <TableHeaderColumn isKey dataField="type" dataSort>
                Inventory
              </TableHeaderColumn>
              <TableHeaderColumn width="20%" dataField="qty" dataSort>
                QTY
              </TableHeaderColumn>
            </BootstrapTable>
            <br />
            <Button block variant={"outline-dark"} disabled>
              Faiha
            </Button>
            <BootstrapTable
              data={warehouseStore.inventorySummaryFA}
              striped
              hover
              condensed
              options={options}
            >
              <TableHeaderColumn isKey dataField="type" dataSort>
                Inventory
              </TableHeaderColumn>
              <TableHeaderColumn width="20%" dataField="qty" dataSort>
                QTY
              </TableHeaderColumn>
            </BootstrapTable>
          </div>
        )}
        <hr />
      </div>
    );
  }
}

export default observer(InventoryDashboard);
