import React, { Component } from "react";
import { observer } from "mobx-react";

import newEventStore from "../Stores/NewEventStore";

import FilterModule from "../FilterModules/FilterModule";

import ReactApexChart from "react-apexcharts";

class OfficeJobDistribution extends Component {
  constructor(props) {
    super(props);
    this.state = {
      job: {},
      modalShow: false,
    };
  }

  render() {
    let stafflist = [];

    let leadjobs = newEventStore.dashboardJobs.filter(
      (job) => job.status === "Lead" || job.status === "Estimation"
    );
    let propjobs = newEventStore.dashboardJobs.filter(
      (job) => job.status === "Proposal"
    );
    let bookjobs = newEventStore.dashboardJobs.filter(
      (job) => job.status === "Booked"
    );

    let vinith = 0;
    let katrina = 0;
    let yobel = 0;
    let reyna = 0;
    let aasim = 0;
    let nauser = 0;

    // Lead
    for (let i = 0; i < leadjobs.length; i++) {
      if (leadjobs[i].emfocalpoint === "Reyna") {
        reyna = reyna + 1;
      } else if (leadjobs[i].emfocalpoint === "Yobel") {
        yobel = yobel + 1;
      } else if (leadjobs[i].emfocalpoint === "Katrina") {
        katrina = katrina + 1;
      } else if (leadjobs[i].emfocalpoint === "Vinith") {
        vinith = vinith + 1;
      } else if (leadjobs[i].emfocalpoint === "Aasim") {
        aasim = aasim + 1;
      } else {
        nauser = nauser + 1;
      }
    }
    stafflist.push({
      name: "Lead",
      data: [reyna, yobel, aasim, katrina, vinith, nauser],
    });
    // Proposal
    vinith = 0;
    katrina = 0;
    yobel = 0;
    reyna = 0;
    aasim = 0;
    nauser = 0;
    for (let i = 0; i < propjobs.length; i++) {
      if (propjobs[i].emfocalpoint === "Reyna") {
        reyna = reyna + 1;
      } else if (propjobs[i].emfocalpoint === "Yobel") {
        yobel = yobel + 1;
      } else if (propjobs[i].emfocalpoint === "Katrina") {
        katrina = katrina + 1;
      } else if (propjobs[i].emfocalpoint === "Vinith") {
        vinith = vinith + 1;
      } else if (propjobs[i].emfocalpoint === "Aasim") {
        aasim = aasim + 1;
      } else {
        nauser = nauser + 1;
      }
    }
    stafflist.push({
      name: "Proposal",
      data: [reyna, yobel, aasim, katrina, vinith, nauser],
    });
    vinith = 0;
    katrina = 0;
    yobel = 0;
    reyna = 0;
    aasim = 0;
    nauser = 0;
    // Booked
    for (let i = 0; i < bookjobs.length; i++) {
      if (bookjobs[i].emfocalpoint === "Reyna") {
        reyna = reyna + 1;
      } else if (bookjobs[i].emfocalpoint === "Yobel") {
        yobel = yobel + 1;
      } else if (bookjobs[i].emfocalpoint === "Katrina") {
        katrina = katrina + 1;
      } else if (bookjobs[i].emfocalpoint === "Vinith") {
        vinith = vinith + 1;
      } else if (bookjobs[i].emfocalpoint === "Aasim") {
        aasim = aasim + 1;
      } else {
        nauser = nauser + 1;
      }
    }
    stafflist.push({
      name: "Booked",
      data: [reyna, yobel, aasim, katrina, vinith, nauser],
    });

    let optionsforchart = {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        foreColor: "black",
        dropShadow: {
          enabled: true,
          bottom: 1,
          right: 1,
          blur: 3,
          opacity: 0.1,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
              colors: "black",
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      xaxis: {
        type: "text",
        categories: [
          "Reyna",
          "Yobel",
          "Aasim",
          "Katrina",
          "Vinith",
          "Not Assigned",
        ],
      },
      legend: {
        position: "top",
        offsetY: 20,
      },
      colors: ["#999999", "#f2ff00", "#00c707"],
      fill: {
        opacity: 1,
      },
      dataLabels: {
        enabled: true,
        style: {
          fontWeight: "bold",
          fontSize: "18px",
        },
        dropShadow: {
          enabled: true,
          left: 2,
          top: 2,
          opacity: 0.7,
        },
      },
    };

    return (
      <div>
        <ReactApexChart
          options={optionsforchart}
          series={stafflist}
          type="bar"
          height={350}
        />
        <div>
          <FilterModule search={"Yes"} />
          <hr />
        </div>
      </div>
    );
  }
}

export default observer(OfficeJobDistribution);
