import React, { Component } from "react";
import { observer } from "mobx-react";
import { SyncLoader } from "react-spinners";

import hrEventStore from "../Stores/HREventStore";
import jobEditStore from "../Stores/JobEditStore";
import MaterialTable from "material-table";

class PendingClients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTable: true,
    };
    this.options = {
      defaultSortName: "id", // default sort column name
      // exportCSVBtn: this.createCustomExportCSVButton,
      defaultSortOrder: "asc", // default sort order
      onRowClick: function (job) {
        jobEditStore.selectJob(job.id);
      },
    };
  }

  render() {
    if (!hrEventStore.leadclienttimeloaded) {
      hrEventStore.fetchLeadClientTimes();
      return (
        <SyncLoader
          sizeUnit={"px"}
          size={50}
          color={"#0017FF"}
          loading={true}
        />
      );
    }
    let jobs = hrEventStore.leadclient;

    let columnnames = [
      { title: "ID", field: "jobid", removable: true },
      { title: "Client", field: "title", removable: true },
      { title: "Type", field: "jobmain", removable: true },
      { title: "Status", field: "status", removable: true },
      {
        title: "Days Since",
        field: "daysdiff",
        removable: true,
        defaultSort: "desc",
      },
      { title: "Focal Point", field: "emfocalpoint", removable: true },
      { title: "Last Update", field: "lastupdatedays", removable: true },
    ];

    return (
      <div>
        <MaterialTable
          title={"Clients Sorted By Last Status Change"}
          columns={columnnames}
          data={jobs}
          paging={false}
          options={{
            paging: false,
            toolbarButtonAlignment: "left",
            tableLayout: "auto",
            grouping: true,
            padding: "dense",
            maxBodyHeight: 1000,
          }}
        />
      </div>
    );
  }
}

export default observer(PendingClients);
