import React, { Component } from "react";
import { observer } from "mobx-react";

import eventStore from "../Stores/EventStore";
import { Button, Row, Col } from "react-bootstrap";
import Grid from "@material-ui/core/Grid";
import moment from "moment";
import MaterialTable from "material-table";
import PaymentRowAll from "./PaymentRowAll";
import PaymentModalNew from "./PaymentModalNew";
import "react-datepicker/dist/react-datepicker.css";

import DynamicFinancials from "./DynamicFinancials";
import userStore from "../Stores/UserStore";
import settingStore from "../Stores/SettingStore";
import ReactGA from "react-ga";
import financialStore from "../Stores/FinancialStore";

function initializeReactGA() {
  if (userStore.user.username !== "jazzaf") {
    ReactGA.initialize("UA-163244126-1");
    ReactGA.pageview(window.location.pathname);
    ReactGA.event({
      category: window.location.pathname,
      action: userStore.user.username,
      label: new Date().toLocaleTimeString()
    });
  }
}

class BankBalances extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editPayment: false,
      financialsLoaded: false,
      modalShow: false,
      indexVal: 0,
      filter: false
    };
    this.options = {
      defaultSortName: "paymentdate", // default sort column name
      defaultSortOrder: "desc", // default sort order
      onRowClick: function(event) {
        this.showModal();
      }
    };
  }

  componentDidMount() {
    initializeReactGA();

    if (!eventStore.financialsLoaded) {
      financialStore.fetchFinancialData();
      eventStore.fetchFinancials();
    }
  }

  showModal = () => {
    this.setState({
      visible: true
    });
  };

  render() {
    let columnnames = [
      { title: "ID", field: "id", removable: true },
      {
        title: "Confirmed",
        field: "expenseheading",
        removable: true,
        lookup: { Yes: "Yes", No: "No" }
      },
      {
        title: "Date",
        field: "paymentdate",
        removable: true,
        defaultSort: "asc"
      },
      { title: "Amount", field: "invoiceamount", removable: true },
      { title: "Department", field: "category1", removable: true },
      {
        title: "Type",
        field: "category2",
        removable: true,

        render: rowData => {
          return rowData.category2 === "Income" ? (
            <p style={{ color: "#006b17", fontWeight: "bold" }}>
              {rowData.category2}
            </p>
          ) : (
            <p style={{ color: "#57000e", fontWeight: "bold" }}>
              {rowData.category2}
            </p>
          );
        }
      },
      { title: "Cash", field: "category3", removable: true },
      { title: "General", field: "category4", removable: true },
      { title: "Specific", field: "category5", removable: true },
      {
        title: "Date",
        field: "paymentdate",
        removable: true,
        defaultSort: "asc"
      },
      { title: "Account", field: "bankaccount", removable: true },
      { title: "Method", field: "paymentmethod", removable: true },
      { title: "Voucher", field: "vouchernumber", removable: true },
      { title: "Invoice", field: "invoicenumber", removable: true },
      { title: "JobID", field: "job", removable: true },
      { title: "Client", field: "clientname", removable: true },
      { title: "Remark", field: "remarks", removable: true }
    ];
    let columnnames2 = [
      { title: "Title", field: "title", removable: true },
      { title: "Jan", field: "Jan", removable: true },
      { title: "Feb", field: "Feb", removable: true },
      { title: "Mar", field: "Mar", removable: true },
      { title: "Apr", field: "Apr", removable: true },
      { title: "May", field: "May", removable: true },
      { title: "Jun", field: "Jun", removable: true },
      { title: "Jul", field: "Jul", removable: true },
      { title: "Aug", field: "Aug", removable: true },
      { title: "Sep", field: "Sep", removable: true },
      { title: "Oct", field: "Oct", removable: true },
      { title: "Nov", field: "Nov", removable: true },
      { title: "Dec", field: "Dec", removable: true },
      { title: "Order", field: "Order", removable: true }
    ];

    if (userStore.access < 2.5) {
      columnnames.push({
        title: "Confirmed",
        field: "expenseheading",
        removable: true,
        lookup: { "": "Blank", Yes: "Yes", No: "No" }
      });
    }

    let modalClose = () => {
      this.setState({ modalShow: false });
      eventStore.postNewPaymentUpdate(eventStore.newpayment, "reset");
    };

    let deletepayment = (
      <Button
        variant="danger"
        block
        onClick={() => {
          eventStore.postNewPaymentUpdate(eventStore.newpayment, "delete");
        }}
      >
        Delete Payment
      </Button>
    );

    let clientForm = (
      <Row>
        <Col>
          <Button
            block
            variant="success"
            onClick={() => {
              eventStore.postNewPaymentUpdate({}, "reset");
              eventStore.changePaymentUpdate(true);
            }}
          >
            Add A New Payment
          </Button>
        </Col>
        <Col>
          <Button
            block
            variant="success"
            onClick={() => {
              eventStore.postNewPaymentUpdate({}, "Gas");
              eventStore.changePaymentUpdate(true);
            }}
          >
            Add A Gas Receipt
          </Button>
        </Col>
        <Col>
          <Button
            block
            variant="success"
            onClick={() => {
              eventStore.postNewPaymentUpdate({}, "Boxes");
              eventStore.changePaymentUpdate(true);
            }}
          >
            Add A Boxes Receipt
          </Button>
        </Col>
      </Row>
    );

    let newPaymentForm = (
      <div>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={6} sm={6} md={6}>
            <Button
              variant="success"
              block
              onClick={() =>
                eventStore.postNewPaymentUpdate(eventStore.newpayment, "post")
              }
            >
              Save
            </Button>
          </Grid>
          <Grid item xs={6} sm={4} md={4}>
            <Button
              variant="warning"
              block
              onClick={() => {
                eventStore.postNewPaymentUpdate(eventStore.newpayment, "reset");
              }}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item xs={6} sm={2} md={2}>
            {eventStore.newpayment.id !== 0 && deletepayment}
          </Grid>
        </Grid>
        <hr />
        <PaymentRowAll />
        <hr />
      </div>
    );

    if (eventStore.paymentupdate) {
      return newPaymentForm;
    }

    let startDate = moment(settingStore.calstartdate);
    let endDate = moment(settingStore.calenddate);

    let financials = eventStore.financialrows.filter(
      financial =>
        moment(financial.paymentdate).format("MMM-YY") ===
        moment(startDate).format("MMM-YY")
    );

    let income = [0, 0, 0, 0, 0, 0];
    let expense = [0, 0, 0, 0, 0, 0];

    for (let i = 0; i < financials.length; i++) {
      if (financials[i].category1 === "Local Move") {
        if (financials[i].category2 === "Income") {
          income[0] += parseFloat(financials[i].invoiceamount);
        } else {
          expense[0] += parseFloat(financials[i].invoiceamount);
        }
      } else if (financials[i].category1 === "Storage") {
        if (financials[i].category2 === "Income") {
          income[1] += parseFloat(financials[i].invoiceamount);
        } else {
          expense[1] += parseFloat(financials[i].invoiceamount);
        }
      } else if (financials[i].category1 === "International Move") {
        if (financials[i].category2 === "Income") {
          income[2] += parseFloat(financials[i].invoiceamount);
        } else {
          expense[2] += parseFloat(financials[i].invoiceamount);
        }
      } else if (financials[i].category1 === "Custom Clearance") {
        if (financials[i].category2 === "Income") {
          income[3] += parseFloat(financials[i].invoiceamount);
        } else {
          expense[3] += parseFloat(financials[i].invoiceamount);
        }
      } else if (financials[i].category1 === "Express Delivery") {
        if (financials[i].category2 === "Income") {
          income[4] += parseFloat(financials[i].invoiceamount);
        } else {
          expense[4] += parseFloat(financials[i].invoiceamount);
        }
      } else {
        if (financials[i].category2 === "Income") {
          income[5] += parseFloat(financials[i].invoiceamount);
        } else {
          expense[5] += parseFloat(financials[i].invoiceamount);
        }
      }
    }

    let currentyear = moment("2020-01-01");
    let financialtable = eventStore.financialrows.filter(
      financial => moment(financial.paymentdate) >= moment(currentyear)
    );
    // 1 = LM - Local Move
    // 2 = ST - Storage
    // 3 = IM - International Move
    // 4 = CC - Custom Clearance
    // 5 = EX - Express Delivery
    // 6 = OT - OTHER

    let allincomes = [];
    let allexpenses = [];
    let allearnings = [];
    let income1 = {
      title: "Income - LM",
      Jan: 0,
      Feb: 0,
      Mar: 0,
      Apr: 0,
      May: 0,
      Jun: 0,
      Jul: 0,
      Aug: 0,
      Sep: 0,
      Oct: 0,
      Nov: 0,
      Dec: 0,
      Total: 0
    };
    let expense1 = Object.assign({}, income1);
    let income2 = Object.assign({}, income1);
    let expense2 = Object.assign({}, income1);
    let income3 = Object.assign({}, income1);
    let expense3 = Object.assign({}, income1);
    let income4 = Object.assign({}, income1);
    let expense4 = Object.assign({}, income1);
    let income5 = Object.assign({}, income1);
    let expense5 = Object.assign({}, income1);
    let income6 = Object.assign({}, income1);
    let expense6 = Object.assign({}, income1);
    let incomeT = Object.assign({}, income1);
    let expenseT = Object.assign({}, income1);
    let earnings1 = Object.assign({}, income1);
    let earnings2 = Object.assign({}, income1);
    let earnings3 = Object.assign({}, income1);
    let earnings4 = Object.assign({}, income1);
    let earnings5 = Object.assign({}, income1);
    let earnings6 = Object.assign({}, income1);
    let earningsT = Object.assign({}, income1);

    expense1.title = "Expense - LM";
    income2.title = "Income - ST";
    expense2.title = "Expense - ST";
    income3.title = "Income - IM";
    expense3.title = "Expense - IM";
    income4.title = "Income - CC";
    expense4.title = "Expense - CC";
    income5.title = "Income - EX";
    expense5.title = "Expense - EX";
    income6.title = "Income - OT";
    expense6.title = "Expense - OT";
    incomeT.title = "Income - All";
    expenseT.title = "Expense - LM";
    earnings1.title = "Expense - ST";
    earnings2.title = "Expense - IM";
    earnings3.title = "Expense - CC";
    earnings4.title = "Expense - EX";
    earnings5.title = "Expense - OT";
    earningsT.title = "Expense - All";

    for (let i = 0; i < financialtable.length; i++) {
      if (
        moment(financialtable[i].paymentdate).format("MMM-YY") ===
        moment("Jan-2020").format("MMM-YY")
      ) {
        if (financialtable[i].category1 === "Local Move") {
          if (financialtable[i].category2 === "Income") {
            income1.Jan += parseFloat(financialtable[i].invoiceamount);
          } else {
            expense1.Jan += parseFloat(financialtable[i].invoiceamount);
          }
        } else if (financialtable[i].category1 === "Storage") {
          if (financialtable[i].category2 === "Income") {
            income2.Jan += parseFloat(financialtable[i].invoiceamount);
          } else {
            expense2.Jan += parseFloat(financialtable[i].invoiceamount);
          }
        } else if (financialtable[i].category1 === "International Move") {
          if (financialtable[i].category2 === "Income") {
            income3.Jan += parseFloat(financialtable[i].invoiceamount);
          } else {
            expense3.Jan += parseFloat(financialtable[i].invoiceamount);
          }
        } else if (financialtable[i].category1 === "Custom Clearance") {
          if (financialtable[i].category2 === "Income") {
            income4.Jan += parseFloat(financialtable[i].invoiceamount);
          } else {
            expense4.Jan += parseFloat(financialtable[i].invoiceamount);
          }
        } else if (financialtable[i].category1 === "Express Delivery") {
          if (financialtable[i].category2 === "Income") {
            income5.Jan += parseFloat(financialtable[i].invoiceamount);
          } else {
            expense5.Jan += parseFloat(financialtable[i].invoiceamount);
          }
        } else if (financialtable[i].category1 === "Other") {
          if (financialtable[i].category2 === "Income") {
            income6.Jan += parseFloat(financialtable[i].invoiceamount);
          } else {
            expense6.Jan += parseFloat(financialtable[i].invoiceamount);
          }
        }
      } else if (
        moment(financialtable[i].paymentdate).format("MMM-YY") ===
        moment("Feb-2020").format("MMM-YY")
      ) {
        if (financialtable[i].category1 === "Local Move") {
          if (financialtable[i].category2 === "Income") {
            income1.Feb += parseFloat(financialtable[i].invoiceamount);
          } else {
            expense1.Feb += parseFloat(financialtable[i].invoiceamount);
          }
        } else if (financialtable[i].category1 === "Storage") {
          if (financialtable[i].category2 === "Income") {
            income2.Feb += parseFloat(financialtable[i].invoiceamount);
          } else {
            expense2.Feb += parseFloat(financialtable[i].invoiceamount);
          }
        } else if (financialtable[i].category1 === "International Move") {
          if (financialtable[i].category2 === "Income") {
            income3.Feb += parseFloat(financialtable[i].invoiceamount);
          } else {
            expense3.Feb += parseFloat(financialtable[i].invoiceamount);
          }
        } else if (financialtable[i].category1 === "Custom Clearance") {
          if (financialtable[i].category2 === "Income") {
            income4.Feb += parseFloat(financialtable[i].invoiceamount);
          } else {
            expense4.Feb += parseFloat(financialtable[i].invoiceamount);
          }
        } else if (financialtable[i].category1 === "Express Delivery") {
          if (financialtable[i].category2 === "Income") {
            income5.Feb += parseFloat(financialtable[i].invoiceamount);
          } else {
            expense5.Feb += parseFloat(financialtable[i].invoiceamount);
          }
        } else if (financialtable[i].category1 === "Other") {
          if (financialtable[i].category2 === "Income") {
            income6.Feb += parseFloat(financialtable[i].invoiceamount);
          } else {
            expense6.Feb += parseFloat(financialtable[i].invoiceamount);
          }
        }
      } else if (
        moment(financialtable[i].paymentdate).format("MMM-YY") ===
        moment("Mar-2020").format("MMM-YY")
      ) {
        if (financialtable[i].category1 === "Local Move") {
          if (financialtable[i].category2 === "Income") {
            income1.Mar += parseFloat(financialtable[i].invoiceamount);
          } else {
            expense1.Mar += parseFloat(financialtable[i].invoiceamount);
          }
        } else if (financialtable[i].category1 === "Storage") {
          if (financialtable[i].category2 === "Income") {
            income2.Mar += parseFloat(financialtable[i].invoiceamount);
          } else {
            expense2.Mar += parseFloat(financialtable[i].invoiceamount);
          }
        } else if (financialtable[i].category1 === "International Move") {
          if (financialtable[i].category2 === "Income") {
            income3.Mar += parseFloat(financialtable[i].invoiceamount);
          } else {
            expense3.Mar += parseFloat(financialtable[i].invoiceamount);
          }
        } else if (financialtable[i].category1 === "Custom Clearance") {
          if (financialtable[i].category2 === "Income") {
            income4.Mar += parseFloat(financialtable[i].invoiceamount);
          } else {
            expense4.Mar += parseFloat(financialtable[i].invoiceamount);
          }
        } else if (financialtable[i].category1 === "Express Delivery") {
          if (financialtable[i].category2 === "Income") {
            income5.Mar += parseFloat(financialtable[i].invoiceamount);
          } else {
            expense5.Mar += parseFloat(financialtable[i].invoiceamount);
          }
        } else if (financialtable[i].category1 === "Other") {
          if (financialtable[i].category2 === "Income") {
            income6.Mar += parseFloat(financialtable[i].invoiceamount);
          } else {
            expense6.Mar += parseFloat(financialtable[i].invoiceamount);
          }
        }
      } else if (
        moment(financialtable[i].paymentdate).format("MMM-YY") ===
        moment("Apr-2020").format("MMM-YY")
      ) {
        if (financialtable[i].category1 === "Local Move") {
          if (financialtable[i].category2 === "Income") {
            income1.Apr += parseFloat(financialtable[i].invoiceamount);
          } else {
            expense1.Apr += parseFloat(financialtable[i].invoiceamount);
          }
        } else if (financialtable[i].category1 === "Storage") {
          if (financialtable[i].category2 === "Income") {
            income2.Apr += parseFloat(financialtable[i].invoiceamount);
          } else {
            expense2.Apr += parseFloat(financialtable[i].invoiceamount);
          }
        } else if (financialtable[i].category1 === "International Move") {
          if (financialtable[i].category2 === "Income") {
            income3.Apr += parseFloat(financialtable[i].invoiceamount);
          } else {
            expense3.Apr += parseFloat(financialtable[i].invoiceamount);
          }
        } else if (financialtable[i].category1 === "Custom Clearance") {
          if (financialtable[i].category2 === "Income") {
            income4.Apr += parseFloat(financialtable[i].invoiceamount);
          } else {
            expense4.Apr += parseFloat(financialtable[i].invoiceamount);
          }
        } else if (financialtable[i].category1 === "Express Delivery") {
          if (financialtable[i].category2 === "Income") {
            income5.Apr += parseFloat(financialtable[i].invoiceamount);
          } else {
            expense5.Apr += parseFloat(financialtable[i].invoiceamount);
          }
        } else if (financialtable[i].category1 === "Other") {
          if (financialtable[i].category2 === "Income") {
            income6.Apr += parseFloat(financialtable[i].invoiceamount);
          } else {
            expense6.Apr += parseFloat(financialtable[i].invoiceamount);
          }
        }
      } else if (
        moment(financialtable[i].paymentdate).format("MMM-YY") ===
        moment("May-2020").format("MMM-YY")
      ) {
        if (financialtable[i].category1 === "Local Move") {
          if (financialtable[i].category2 === "Income") {
            income1.May += parseFloat(financialtable[i].invoiceamount);
          } else {
            expense1.May += parseFloat(financialtable[i].invoiceamount);
          }
        } else if (financialtable[i].category1 === "Storage") {
          if (financialtable[i].category2 === "Income") {
            income2.May += parseFloat(financialtable[i].invoiceamount);
          } else {
            expense2.May += parseFloat(financialtable[i].invoiceamount);
          }
        } else if (financialtable[i].category1 === "International Move") {
          if (financialtable[i].category2 === "Income") {
            income3.May += parseFloat(financialtable[i].invoiceamount);
          } else {
            expense3.May += parseFloat(financialtable[i].invoiceamount);
          }
        } else if (financialtable[i].category1 === "Custom Clearance") {
          if (financialtable[i].category2 === "Income") {
            income4.May += parseFloat(financialtable[i].invoiceamount);
          } else {
            expense4.May += parseFloat(financialtable[i].invoiceamount);
          }
        } else if (financialtable[i].category1 === "Express Delivery") {
          if (financialtable[i].category2 === "Income") {
            income5.May += parseFloat(financialtable[i].invoiceamount);
          } else {
            expense5.May += parseFloat(financialtable[i].invoiceamount);
          }
        } else if (financialtable[i].category1 === "Other") {
          if (financialtable[i].category2 === "Income") {
            income6.May += parseFloat(financialtable[i].invoiceamount);
          } else {
            expense6.May += parseFloat(financialtable[i].invoiceamount);
          }
        }
      } else if (
        moment(financialtable[i].paymentdate).format("MMM-YY") ===
        moment("June-2020").format("MMM-YY")
      ) {
        if (financialtable[i].category1 === "Local Move") {
          if (financialtable[i].category2 === "Income") {
            income1.June += parseFloat(financialtable[i].invoiceamount);
          } else {
            expense1.June += parseFloat(financialtable[i].invoiceamount);
          }
        } else if (financialtable[i].category1 === "Storage") {
          if (financialtable[i].category2 === "Income") {
            income2.June += parseFloat(financialtable[i].invoiceamount);
          } else {
            expense2.June += parseFloat(financialtable[i].invoiceamount);
          }
        } else if (financialtable[i].category1 === "International Move") {
          if (financialtable[i].category2 === "Income") {
            income3.June += parseFloat(financialtable[i].invoiceamount);
          } else {
            expense3.June += parseFloat(financialtable[i].invoiceamount);
          }
        } else if (financialtable[i].category1 === "Custom Clearance") {
          if (financialtable[i].category2 === "Income") {
            income4.June += parseFloat(financialtable[i].invoiceamount);
          } else {
            expense4.June += parseFloat(financialtable[i].invoiceamount);
          }
        } else if (financialtable[i].category1 === "Express Delivery") {
          if (financialtable[i].category2 === "Income") {
            income5.June += parseFloat(financialtable[i].invoiceamount);
          } else {
            expense5.June += parseFloat(financialtable[i].invoiceamount);
          }
        } else if (financialtable[i].category1 === "Other") {
          if (financialtable[i].category2 === "Income") {
            income6.June += parseFloat(financialtable[i].invoiceamount);
          } else {
            expense6.June += parseFloat(financialtable[i].invoiceamount);
          }
        }
      }
    }

    incomeT.Jan =
      parseFloat(income1.Jan) +
      parseFloat(income2.Jan) +
      parseFloat(income3.Jan) +
      parseFloat(income4.Jan) +
      parseFloat(income5.Jan) +
      parseFloat(income6.Jan);
    expenseT.Jan =
      parseFloat(expense1.Jan) +
      parseFloat(expense2.Jan) +
      parseFloat(expense3.Jan) +
      parseFloat(expense4.Jan) +
      parseFloat(expense5.Jan) +
      parseFloat(expense6.Jan);
    earnings1.Jan = parseFloat(income1.Jan) - parseFloat(expense1.Jan);
    earnings2.Jan = parseFloat(income2.Jan) - parseFloat(expense2.Jan);
    earnings3.Jan = parseFloat(income3.Jan) - parseFloat(expense3.Jan);
    earnings4.Jan = parseFloat(income4.Jan) - parseFloat(expense4.Jan);
    earnings5.Jan = parseFloat(income5.Jan) - parseFloat(expense5.Jan);
    earnings6.Jan = parseFloat(income6.Jan) - parseFloat(expense6.Jan);
    earningsT.Jan = parseFloat(earnings1.Jan) - parseFloat(expense6.Jan);
    earningsT.Jan =
      parseFloat(earnings1.Jan) +
      parseFloat(earnings2.Jan) +
      parseFloat(earnings3.Jan) +
      parseFloat(earnings4.Jan) +
      parseFloat(earnings5.Jan) +
      parseFloat(earnings6.Jan);

    incomeT.Feb =
      parseFloat(income1.Feb) +
      parseFloat(income2.Feb) +
      parseFloat(income3.Feb) +
      parseFloat(income4.Feb) +
      parseFloat(income5.Feb) +
      parseFloat(income6.Feb);
    expenseT.Feb =
      parseFloat(expense1.Feb) +
      parseFloat(expense2.Feb) +
      parseFloat(expense3.Feb) +
      parseFloat(expense4.Feb) +
      parseFloat(expense5.Feb) +
      parseFloat(expense6.Feb);
    earnings1.Feb = parseFloat(income1.Feb) - parseFloat(expense1.Feb);
    earnings2.Feb = parseFloat(income2.Feb) - parseFloat(expense2.Feb);
    earnings3.Feb = parseFloat(income3.Feb) - parseFloat(expense3.Feb);
    earnings4.Feb = parseFloat(income4.Feb) - parseFloat(expense4.Feb);
    earnings5.Feb = parseFloat(income5.Feb) - parseFloat(expense5.Feb);
    earnings6.Feb = parseFloat(income6.Feb) - parseFloat(expense6.Feb);
    earningsT.Feb = parseFloat(earnings1.Feb) - parseFloat(expense6.Feb);
    earningsT.Feb =
      parseFloat(earnings1.Feb) +
      parseFloat(earnings2.Feb) +
      parseFloat(earnings3.Feb) +
      parseFloat(earnings4.Feb) +
      parseFloat(earnings5.Feb) +
      parseFloat(earnings6.Feb);

    incomeT.Mar =
      parseFloat(income1.Mar) +
      parseFloat(income2.Mar) +
      parseFloat(income3.Mar) +
      parseFloat(income4.Mar) +
      parseFloat(income5.Mar) +
      parseFloat(income6.Mar);
    expenseT.Mar =
      parseFloat(expense1.Mar) +
      parseFloat(expense2.Mar) +
      parseFloat(expense3.Mar) +
      parseFloat(expense4.Mar) +
      parseFloat(expense5.Mar) +
      parseFloat(expense6.Mar);
    earnings1.Mar = parseFloat(income1.Mar) - parseFloat(expense1.Mar);
    earnings2.Mar = parseFloat(income2.Mar) - parseFloat(expense2.Mar);
    earnings3.Mar = parseFloat(income3.Mar) - parseFloat(expense3.Mar);
    earnings4.Mar = parseFloat(income4.Mar) - parseFloat(expense4.Mar);
    earnings5.Mar = parseFloat(income5.Mar) - parseFloat(expense5.Mar);
    earnings6.Mar = parseFloat(income6.Mar) - parseFloat(expense6.Mar);
    earningsT.Mar = parseFloat(earnings1.Mar) - parseFloat(expense6.Mar);
    earningsT.Mar =
      parseFloat(earnings1.Mar) +
      parseFloat(earnings2.Mar) +
      parseFloat(earnings3.Mar) +
      parseFloat(earnings4.Mar) +
      parseFloat(earnings5.Mar) +
      parseFloat(earnings6.Mar);

    incomeT.Apr =
      parseFloat(income1.Apr) +
      parseFloat(income2.Apr) +
      parseFloat(income3.Apr) +
      parseFloat(income4.Apr) +
      parseFloat(income5.Apr) +
      parseFloat(income6.Apr);
    expenseT.Apr =
      parseFloat(expense1.Apr) +
      parseFloat(expense2.Apr) +
      parseFloat(expense3.Apr) +
      parseFloat(expense4.Apr) +
      parseFloat(expense5.Apr) +
      parseFloat(expense6.Apr);
    earnings1.Apr = parseFloat(income1.Apr) - parseFloat(expense1.Apr);
    earnings2.Apr = parseFloat(income2.Apr) - parseFloat(expense2.Apr);
    earnings3.Apr = parseFloat(income3.Apr) - parseFloat(expense3.Apr);
    earnings4.Apr = parseFloat(income4.Apr) - parseFloat(expense4.Apr);
    earnings5.Apr = parseFloat(income5.Apr) - parseFloat(expense5.Apr);
    earnings6.Apr = parseFloat(income6.Apr) - parseFloat(expense6.Apr);
    earningsT.Apr = parseFloat(earnings1.Apr) - parseFloat(expense6.Apr);
    earningsT.Apr =
      parseFloat(earnings1.Apr) +
      parseFloat(earnings2.Apr) +
      parseFloat(earnings3.Apr) +
      parseFloat(earnings4.Apr) +
      parseFloat(earnings5.Apr) +
      parseFloat(earnings6.Apr);

    incomeT.May =
      parseFloat(income1.May) +
      parseFloat(income2.May) +
      parseFloat(income3.May) +
      parseFloat(income4.May) +
      parseFloat(income5.May) +
      parseFloat(income6.May);
    expenseT.May =
      parseFloat(expense1.May) +
      parseFloat(expense2.May) +
      parseFloat(expense3.May) +
      parseFloat(expense4.May) +
      parseFloat(expense5.May) +
      parseFloat(expense6.May);
    earnings1.May = parseFloat(income1.May) - parseFloat(expense1.May);
    earnings2.May = parseFloat(income2.May) - parseFloat(expense2.May);
    earnings3.May = parseFloat(income3.May) - parseFloat(expense3.May);
    earnings4.May = parseFloat(income4.May) - parseFloat(expense4.May);
    earnings5.May = parseFloat(income5.May) - parseFloat(expense5.May);
    earnings6.May = parseFloat(income6.May) - parseFloat(expense6.May);
    earningsT.May = parseFloat(earnings1.May) - parseFloat(expense6.May);
    earningsT.May =
      parseFloat(earnings1.May) +
      parseFloat(earnings2.May) +
      parseFloat(earnings3.May) +
      parseFloat(earnings4.May) +
      parseFloat(earnings5.May) +
      parseFloat(earnings6.May);

    incomeT.Jun =
      parseFloat(income1.Jun) +
      parseFloat(income2.Jun) +
      parseFloat(income3.Jun) +
      parseFloat(income4.Jun) +
      parseFloat(income5.Jun) +
      parseFloat(income6.Jun);
    expenseT.Jun =
      parseFloat(expense1.Jun) +
      parseFloat(expense2.Jun) +
      parseFloat(expense3.Jun) +
      parseFloat(expense4.Jun) +
      parseFloat(expense5.Jun) +
      parseFloat(expense6.Jun);
    earnings1.Jun = parseFloat(income1.Jun) - parseFloat(expense1.Jun);
    earnings2.Jun = parseFloat(income2.Jun) - parseFloat(expense2.Jun);
    earnings3.Jun = parseFloat(income3.Jun) - parseFloat(expense3.Jun);
    earnings4.Jun = parseFloat(income4.Jun) - parseFloat(expense4.Jun);
    earnings5.Jun = parseFloat(income5.Jun) - parseFloat(expense5.Jun);
    earnings6.Jun = parseFloat(income6.Jun) - parseFloat(expense6.Jun);
    earningsT.Jun = parseFloat(earnings1.Jun) - parseFloat(expense6.Jun);
    earningsT.Jun =
      parseFloat(earnings1.Jun) +
      parseFloat(earnings2.Jun) +
      parseFloat(earnings3.Jun) +
      parseFloat(earnings4.Jun) +
      parseFloat(earnings5.Jun) +
      parseFloat(earnings6.Jun);

    income1.Jan = Math.round(1000 * income1.Jan) / 1000;
    income2.Jan = Math.round(1000 * income2.Jan) / 1000;
    income3.Jan = Math.round(1000 * income3.Jan) / 1000;
    income4.Jan = Math.round(1000 * income4.Jan) / 1000;
    income5.Jan = Math.round(1000 * income5.Jan) / 1000;
    income6.Jan = Math.round(1000 * income6.Jan) / 1000;
    incomeT.Jan = Math.round(1000 * incomeT.Jan) / 1000;
    expense1.Jan = Math.round(1000 * expense1.Jan) / 1000;
    expense2.Jan = Math.round(1000 * expense2.Jan) / 1000;
    expense3.Jan = Math.round(1000 * expense3.Jan) / 1000;
    expense4.Jan = Math.round(1000 * expense4.Jan) / 1000;
    expense5.Jan = Math.round(1000 * expense5.Jan) / 1000;
    expense6.Jan = Math.round(1000 * expense6.Jan) / 1000;
    expenseT.Jan = Math.round(1000 * expenseT.Jan) / 1000;
    earnings1.Jan = Math.round(1000 * earnings1.Jan) / 1000;
    earnings2.Jan = Math.round(1000 * earnings2.Jan) / 1000;
    earnings3.Jan = Math.round(1000 * earnings3.Jan) / 1000;
    earnings4.Jan = Math.round(1000 * earnings4.Jan) / 1000;
    earnings5.Jan = Math.round(1000 * earnings5.Jan) / 1000;
    earnings6.Jan = Math.round(1000 * earnings6.Jan) / 1000;
    earningsT.Jan = Math.round(1000 * earningsT.Jan) / 1000;

    income1.Feb = Math.round(1000 * income1.Feb) / 1000;
    income2.Feb = Math.round(1000 * income2.Feb) / 1000;
    income3.Feb = Math.round(1000 * income3.Feb) / 1000;
    income4.Feb = Math.round(1000 * income4.Feb) / 1000;
    income5.Feb = Math.round(1000 * income5.Feb) / 1000;
    income6.Feb = Math.round(1000 * income6.Feb) / 1000;
    incomeT.Feb = Math.round(1000 * incomeT.Feb) / 1000;
    expense1.Feb = Math.round(1000 * expense1.Feb) / 1000;
    expense2.Feb = Math.round(1000 * expense2.Feb) / 1000;
    expense3.Feb = Math.round(1000 * expense3.Feb) / 1000;
    expense4.Feb = Math.round(1000 * expense4.Feb) / 1000;
    expense5.Feb = Math.round(1000 * expense5.Feb) / 1000;
    expense6.Feb = Math.round(1000 * expense6.Feb) / 1000;
    expenseT.Feb = Math.round(1000 * expenseT.Feb) / 1000;
    earnings1.Feb = Math.round(1000 * earnings1.Feb) / 1000;
    earnings2.Feb = Math.round(1000 * earnings2.Feb) / 1000;
    earnings3.Feb = Math.round(1000 * earnings3.Feb) / 1000;
    earnings4.Feb = Math.round(1000 * earnings4.Feb) / 1000;
    earnings5.Feb = Math.round(1000 * earnings5.Feb) / 1000;
    earnings6.Feb = Math.round(1000 * earnings6.Feb) / 1000;
    earningsT.Feb = Math.round(1000 * earningsT.Feb) / 1000;

    income1.Mar = Math.round(1000 * income1.Mar) / 1000;
    income2.Mar = Math.round(1000 * income2.Mar) / 1000;
    income3.Mar = Math.round(1000 * income3.Mar) / 1000;
    income4.Mar = Math.round(1000 * income4.Mar) / 1000;
    income5.Mar = Math.round(1000 * income5.Mar) / 1000;
    income6.Mar = Math.round(1000 * income6.Mar) / 1000;
    incomeT.Mar = Math.round(1000 * incomeT.Mar) / 1000;
    expense1.Mar = Math.round(1000 * expense1.Mar) / 1000;
    expense2.Mar = Math.round(1000 * expense2.Mar) / 1000;
    expense3.Mar = Math.round(1000 * expense3.Mar) / 1000;
    expense4.Mar = Math.round(1000 * expense4.Mar) / 1000;
    expense5.Mar = Math.round(1000 * expense5.Mar) / 1000;
    expense6.Mar = Math.round(1000 * expense6.Mar) / 1000;
    expenseT.Mar = Math.round(1000 * expenseT.Mar) / 1000;
    earnings1.Mar = Math.round(1000 * earnings1.Mar) / 1000;
    earnings2.Mar = Math.round(1000 * earnings2.Mar) / 1000;
    earnings3.Mar = Math.round(1000 * earnings3.Mar) / 1000;
    earnings4.Mar = Math.round(1000 * earnings4.Mar) / 1000;
    earnings5.Mar = Math.round(1000 * earnings5.Mar) / 1000;
    earnings6.Mar = Math.round(1000 * earnings6.Mar) / 1000;
    earningsT.Mar = Math.round(1000 * earningsT.Mar) / 1000;

    income1.Apr = Math.round(1000 * income1.Apr) / 1000;
    income2.Apr = Math.round(1000 * income2.Apr) / 1000;
    income3.Apr = Math.round(1000 * income3.Apr) / 1000;
    income4.Apr = Math.round(1000 * income4.Apr) / 1000;
    income5.Apr = Math.round(1000 * income5.Apr) / 1000;
    income6.Apr = Math.round(1000 * income6.Apr) / 1000;
    incomeT.Apr = Math.round(1000 * incomeT.Apr) / 1000;
    expense1.Apr = Math.round(1000 * expense1.Apr) / 1000;
    expense2.Apr = Math.round(1000 * expense2.Apr) / 1000;
    expense3.Apr = Math.round(1000 * expense3.Apr) / 1000;
    expense4.Apr = Math.round(1000 * expense4.Apr) / 1000;
    expense5.Apr = Math.round(1000 * expense5.Apr) / 1000;
    expense6.Apr = Math.round(1000 * expense6.Apr) / 1000;
    expenseT.Apr = Math.round(1000 * expenseT.Apr) / 1000;
    earnings1.Apr = Math.round(1000 * earnings1.Apr) / 1000;
    earnings2.Apr = Math.round(1000 * earnings2.Apr) / 1000;
    earnings3.Apr = Math.round(1000 * earnings3.Apr) / 1000;
    earnings4.Apr = Math.round(1000 * earnings4.Apr) / 1000;
    earnings5.Apr = Math.round(1000 * earnings5.Apr) / 1000;
    earnings6.Apr = Math.round(1000 * earnings6.Apr) / 1000;
    earningsT.Apr = Math.round(1000 * earningsT.Apr) / 1000;

    income1.May = Math.round(1000 * income1.May) / 1000;
    income2.May = Math.round(1000 * income2.May) / 1000;
    income3.May = Math.round(1000 * income3.May) / 1000;
    income4.May = Math.round(1000 * income4.May) / 1000;
    income5.May = Math.round(1000 * income5.May) / 1000;
    income6.May = Math.round(1000 * income6.May) / 1000;
    incomeT.May = Math.round(1000 * incomeT.May) / 1000;
    expense1.May = Math.round(1000 * expense1.May) / 1000;
    expense2.May = Math.round(1000 * expense2.May) / 1000;
    expense3.May = Math.round(1000 * expense3.May) / 1000;
    expense4.May = Math.round(1000 * expense4.May) / 1000;
    expense5.May = Math.round(1000 * expense5.May) / 1000;
    expense6.May = Math.round(1000 * expense6.May) / 1000;
    expenseT.May = Math.round(1000 * expenseT.May) / 1000;
    earnings1.May = Math.round(1000 * earnings1.May) / 1000;
    earnings2.May = Math.round(1000 * earnings2.May) / 1000;
    earnings3.May = Math.round(1000 * earnings3.May) / 1000;
    earnings4.May = Math.round(1000 * earnings4.May) / 1000;
    earnings5.May = Math.round(1000 * earnings5.May) / 1000;
    earnings6.May = Math.round(1000 * earnings6.May) / 1000;
    earningsT.May = Math.round(1000 * earningsT.May) / 1000;

    income1.Jun = Math.round(1000 * income1.Jun) / 1000;
    income2.Jun = Math.round(1000 * income2.Jun) / 1000;
    income3.Jun = Math.round(1000 * income3.Jun) / 1000;
    income4.Jun = Math.round(1000 * income4.Jun) / 1000;
    income5.Jun = Math.round(1000 * income5.Jun) / 1000;
    income6.Jun = Math.round(1000 * income6.Jun) / 1000;
    incomeT.Jun = Math.round(1000 * incomeT.Jun) / 1000;
    expense1.Jun = Math.round(1000 * expense1.Jun) / 1000;
    expense2.Jun = Math.round(1000 * expense2.Jun) / 1000;
    expense3.Jun = Math.round(1000 * expense3.Jun) / 1000;
    expense4.Jun = Math.round(1000 * expense4.Jun) / 1000;
    expense5.Jun = Math.round(1000 * expense5.Jun) / 1000;
    expense6.Jun = Math.round(1000 * expense6.Jun) / 1000;
    expenseT.Jun = Math.round(1000 * expenseT.Jun) / 1000;
    earnings1.Jun = Math.round(1000 * earnings1.Jun) / 1000;
    earnings2.Jun = Math.round(1000 * earnings2.Jun) / 1000;
    earnings3.Jun = Math.round(1000 * earnings3.Jun) / 1000;
    earnings4.Jun = Math.round(1000 * earnings4.Jun) / 1000;
    earnings5.Jun = Math.round(1000 * earnings5.Jun) / 1000;
    earnings6.Jun = Math.round(1000 * earnings6.Jun) / 1000;
    earningsT.Jun = Math.round(1000 * earningsT.Jun) / 1000;

    allincomes.push(income1);
    allincomes.push(income2);
    allincomes.push(income3);
    allincomes.push(income4);
    allincomes.push(income5);
    allincomes.push(income6);
    allincomes.push(incomeT);

    allexpenses.push(expense1);
    allexpenses.push(expense2);
    allexpenses.push(expense3);
    allexpenses.push(expense4);
    allexpenses.push(expense5);
    allexpenses.push(expense6);
    allexpenses.push(expenseT);

    allearnings.push(earnings1);
    allearnings.push(earnings2);
    allearnings.push(earnings3);
    allearnings.push(earnings4);
    allearnings.push(earnings5);
    allearnings.push(earnings6);
    allearnings.push(earningsT);

    return (
      <div>
        <PaymentModalNew show={this.state.modalShow} onHide={modalClose} />
        {this.state.editPayment ? newPaymentForm : clientForm}
        <br />

        <DynamicFinancials />

        <hr />
        <Button
          variant="dark"
          onClick={e => {
            let oldDate = moment(startDate);
            oldDate = oldDate.subtract(1, "d");
            settingStore.changeCalDate(oldDate);
          }}
        >
          Last Month (
          {moment(moment(startDate).subtract(1, "days")).format("MMM-YY")})
        </Button>
        {moment(startDate).format("MMM-YY") !== moment().format("MMM-YY") && (
          <Button
            variant="dark"
            onClick={e => {
              let oldDate = moment(endDate);
              oldDate = oldDate.add(1, "d");
              settingStore.changeCalDate(oldDate);
            }}
          >
            Next Month (
            {moment(moment(endDate).add(1, "days")).format("MMM-YY")})
          </Button>
        )}
        <hr />
        <MaterialTable
          title={"Incomes"}
          columns={columnnames2}
          data={allincomes}
          paging={false}
          options={{
            padding: "dense",
            actionsColumnIndex: 0,
            exportAllData: true,
            paging: false,
            toolbarButtonAlignment: "left",
            maxBodyHeight: 250,
            tableLayout: "auto",
            exportFileName: "Easy Move Profit Loss"
          }}
        />
        <MaterialTable
          title={"Expenses"}
          columns={columnnames2}
          data={allexpenses}
          paging={false}
          options={{
            padding: "dense",
            actionsColumnIndex: 0,
            exportAllData: true,
            paging: false,
            toolbarButtonAlignment: "left",
            maxBodyHeight: 250,
            tableLayout: "auto",
            exportFileName: "Easy Move Profit Loss"
          }}
        />
        <MaterialTable
          title={"Earnings"}
          columns={columnnames2}
          data={allearnings}
          paging={false}
          options={{
            padding: "dense",
            actionsColumnIndex: 0,
            exportAllData: true,
            paging: false,
            toolbarButtonAlignment: "left",
            maxBodyHeight: 250,
            tableLayout: "auto",
            exportFileName: "Easy Move Profit Loss"
          }}
        />
        <MaterialTable
          title={
            moment(startDate).format("MMM-YYYY") +
            " Records: (" +
            financials.length +
            " transactions)"
          }
          columns={columnnames}
          data={financialtable}
          paging={true}
          options={{
            padding: "dense",
            actionsColumnIndex: 0,
            exportAllData: true,
            paging: true,
            pageSize: 50,
            toolbarButtonAlignment: "left",
            maxBodyHeight: 1000,
            tableLayout: "auto",
            exportButton: true,
            exportFileName: "Bank Balances",
            grouping: true,
            filtering: true
          }}
          actions={[
            rowData => ({
              icon: "edit",
              tooltip: "Edit Item",
              onClick: (event, rowData) => {
                eventStore.selectPayment(rowData);
                this.setState({ modalShow: true });
              }
            })
          ]}
        />
        <br />
        <br />
      </div>
    );
  }
}

export default observer(BankBalances);
