import React from "react";
// First way to import
import moment from "moment";
import { observer } from "mobx-react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import settingStore from "../Stores/SettingStore";
import MaterialTable from "material-table";

import JobModal from "../FilterModules/JobModal";
import jobSelectionStore from "../Stores/JobSelectionStore";
import intSupStore from "../Stores/IntSupStore";

moment.locale("ko", {
  week: {
    dow: 6,
    doy: 6,
  },
});
const localizer = momentLocalizer(moment);

class IMTrackCalendar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
      modalShow: false,
      estCalendar: false,
      perfCalendar: false,
    };
  }

  componentDidMount() {
    intSupStore.fetchIMTracks();
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  showEstCal() {
    this.setState({ estCalendar: !this.state.estCalendar });
  }

  eventStyleGetter = (event, start, end, isSelected) => {
    var backgroundColor = "#";

    if (event.status === "Booked") {
      backgroundColor = "green";
    } else if (event.status === "Completed") {
      backgroundColor = "#003705";
    } else if (event.status === "Proposal") {
      backgroundColor = "orange";
    } else if (event.status === "Estimation") {
      backgroundColor = "#4aa0b5";
    } else if (event.status === "Lead") {
      backgroundColor = "#6e757c";
    } else if (
      (event.status === "Pending") &
      (moment(event.start) < moment(new Date()))
    ) {
      backgroundColor = "red";
    } else if (event.status === "Pending") {
      backgroundColor = "green";
    }
    var style = {
      backgroundColor: backgroundColor,
      borderRadius: "3px",
      opacity: 0.7,
      border: "3px",
      borderColor: "black",
      display: "block",
      fontSize: 12,
      height: 20,
    };
    return {
      style: style,
    };
  };
  handleClose = () => jobSelectionStore.closeModal();

  render() {
    let completedtracks = intSupStore.imtracks.filter(
      (track) => track.status !== "Completed"
    );

    let columnnames = [
      { title: "Job", field: "job", removable: true },
      {
        title: "Revised Date",
        field: "reviseddate",
        removable: true,
        defaultSort: "asc",
      },
      { title: "Client", field: "clienttitle", removable: true },
      { title: "Status", field: "status", removable: true },
      { title: "Activity", field: "title", removable: true },
      { title: "Original Date", field: "originaldate", removable: true },
      { title: "Remarks", field: "remark", removable: true },
      { title: "Focal Point", field: "emfocalpoint", removable: true },
    ];

    return (
      <div>
        <JobModal
          show={jobSelectionStore.showModal}
          onHide={this.handleClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        />
        <h1>International Move Tracking Calendar</h1>
        <hr />
        {!settingStore.shortLoading && (
          <div>
            <MaterialTable
              title={"Upcoming Activity"}
              columns={columnnames}
              data={completedtracks}
              paging={false}
              onRowClick={(event, rowData) => {
                jobSelectionStore.fetchSingleJob(rowData.job);
              }}
              options={{
                paging: false,
                toolbarButtonAlignment: "left",
                tableLayout: "auto",
                padding: "dense",
                maxBodyHeight: 300,
              }}
            />
            <hr />
            <Calendar
              localizer={localizer}
              events={intSupStore.imtracks}
              eventPropGetter={this.eventStyleGetter}
              startAccessor="reviseddate"
              endAccessor="reviseddate"
              style={{ height: 800 }}
              defaultDate={new Date()}
              views={["month"]}
              titleAccessor="desc"
              popup
              onSelectEvent={(event) => {
                jobSelectionStore.fetchSingleJob(event.job);
              }}
            />
            <hr />
          </div>
        )}

        <br />
        <hr />
        <br />
        <hr />
      </div>
    );
  }
}

export default observer(IMTrackCalendar);
