import React, { Component } from "react";
import { observer } from "mobx-react";

import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import hrEventStore from "../Stores/HREventStore";
import { Button, Container, Row } from "react-bootstrap";
import FormControl from "@material-ui/core/FormControl";
import moment from "moment";
import Card from "@material-ui/core/Card";
import DatePicker from "react-datepicker";

import Grid from "@material-ui/core/Grid";
import { Select } from "antd";
import TextField from "@material-ui/core/TextField";

import userStore from "../Stores/UserStore";
import ReactGA from "react-ga";

function initializeReactGA() {
  if (userStore.user.username !== "jazzaf") {
    ReactGA.initialize("UA-163244126-1");
    ReactGA.pageview(window.location.pathname);
    ReactGA.event({
      category: window.location.pathname,
      action: userStore.user.username,
      label: new Date().toLocaleTimeString()
    });
  }
}

const { Option } = Select;

class HRDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.options = {
      defaultSortName: "id", // default sort column name
      // exportCSVBtn: this.createCustomExportCSVButton,
      defaultSortOrder: "asc", // default sort order
      onRowClick: function(event) {
        if (!hrEventStore.editMember) {
          hrEventStore.changeEdit();
          hrEventStore.chooseTeamMember(event);
        }
      }
    };
  }

  componentDidMount() {
    initializeReactGA();
  }

  render() {
    let staffupdate = (
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={6} sm={4} md={4}>
          <FormControl variant="outlined" style={{ width: "100%" }}>
            <b>Passport Expiry Date</b>
            <DatePicker
              onChange={date =>
                hrEventStore.updateMemberDetails(
                  moment(date).format("YYYY-MM-DD"),
                  "passportexpiry"
                )
              }
              dateFormat="dd-MMM-yy"
              selected={Date.parse(hrEventStore.selectedmember.passportexpiry)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={4} md={4}>
          <FormControl variant="outlined" style={{ width: "100%" }}>
            <b>Civil ID Expiry Date</b>
            <DatePicker
              onChange={date =>
                hrEventStore.updateMemberDetails(
                  moment(date).format("YYYY-MM-DD"),

                  "civilidexpiry"
                )
              }
              dateFormat="dd-MMM-yy"
              selected={Date.parse(hrEventStore.selectedmember.civilidexpiry)}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={4} md={4}>
          <FormControl variant="outlined" style={{ width: "100%" }}>
            <b>Driver License Expiry Date</b>
            <DatePicker
              onChange={date =>
                hrEventStore.updateMemberDetails(
                  moment(date).format("YYYY-MM-DD"),
                  "driverlicenseexpiry"
                )
              }
              dateFormat="dd-MMM-yy"
              selected={Date.parse(
                hrEventStore.selectedmember.driverlicenseexpiry
              )}
            />
          </FormControl>
        </Grid>
      </Grid>
    );
    let carupdate = (
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={6} sm={4} md={4}>
          <FormControl variant="outlined" style={{ width: "100%" }}>
            <b>Registration Expiry Date</b>
            <DatePicker
              onChange={date =>
                hrEventStore.updateMemberDetails(
                  moment(date).format("YYYY-MM-DD"),
                  "carregistrationexpiry"
                )
              }
              dateFormat="dd-MMM-yy"
              selected={Date.parse(
                hrEventStore.selectedmember.carregistrationexpiry
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={4} md={4}>
          <FormControl variant="outlined" style={{ width: "100%" }}>
            <b>Insurance Expiry Date</b>
            <DatePicker
              onChange={date =>
                hrEventStore.updateMemberDetails(
                  moment(date).format("YYYY-MM-DD"),
                  "carinsuranceexpiry"
                )
              }
              dateFormat="dd-MMM-yy"
              selected={Date.parse(
                hrEventStore.selectedmember.carinsuranceexpiry
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6} sm={4} md={4}>
          <FormControl variant="outlined" style={{ width: "100%" }}>
            <b>Full Insurance Expiry Date</b>
            <DatePicker
              onChange={date =>
                hrEventStore.updateMemberDetails(
                  moment(date).format("YYYY-MM-DD"),
                  "generalexpiry"
                )
              }
              dateFormat="dd-MMM-yy"
              selected={Date.parse(hrEventStore.selectedmember.generalexpiry)}
            />
          </FormControl>
        </Grid>
      </Grid>
    );
    let docupdate = (
      <Grid container spacing={1} alignItems="center">
        <Grid item xs={6} sm={4} md={4}>
          <FormControl variant="outlined" style={{ width: "100%" }}>
            <b>General Expiry Date</b>
            <DatePicker
              onChange={date =>
                hrEventStore.updateMemberDetails(
                  moment(date).format("YYYY-MM-DD"),
                  "generalexpiry"
                )
              }
              dateFormat="dd-MMM-yy"
              selected={Date.parse(hrEventStore.selectedmember.generalexpiry)}
            />
          </FormControl>
        </Grid>
      </Grid>
    );

    let editmember = (
      <Card>
        <Container>
          <h4>
            Update {hrEventStore.selectedmember.name} -{" "}
            {hrEventStore.selectedmember.title}
          </h4>
          <br />
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={6} sm={3} md={3}>
              <b>Status:</b>
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                size="small"
              >
                <Select
                  showSearch
                  style={{ width: 200 }}
                  optionFilterProp="children"
                  placeholder="Type"
                  value={hrEventStore.selectedmember.active}
                  onChange={e => hrEventStore.updateMemberDetails(e, "active")}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="Active">Active</Option>
                  <Option value="Not Active">Not Active</Option>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6} sm={3} md={3}>
              <b>Type:</b>
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                size="small"
              >
                <Select
                  showSearch
                  style={{ width: 200 }}
                  optionFilterProp="children"
                  placeholder="Type"
                  value={hrEventStore.selectedmember.title}
                  onChange={e => hrEventStore.updateMemberDetails(e, "title")}
                  filterOption={(input, option) =>
                    option.props.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  <Option value="" />
                  <Option value="Staff">Staff</Option>
                  <Option value="Car">Vehicle</Option>
                  <Option value="Document">Document</Option>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                size="small"
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Name"
                name="name"
                autoComplete="name"
                autoFocus
                type="text"
                value={hrEventStore.selectedmember.name}
                onChange={e =>
                  hrEventStore.updateMemberDetails(e.target.value, "name")
                }
              />
            </Grid>
          </Grid>
          <hr />
          <Grid container spacing={1} alignItems="center">
            {hrEventStore.selectedmember.title === "Staff" && staffupdate}
            {hrEventStore.selectedmember.title === "Car" && carupdate}
            {hrEventStore.selectedmember.title === "Document" && docupdate}
          </Grid>
          <br />
          <hr />
          <Row>
            <Button
              block
              variant="success"
              onClick={() => hrEventStore.postTeamMember("update")}
            >
              Save
            </Button>
            <Button
              block
              variant="danger"
              onClick={() => {
                hrEventStore.chooseTeamMember(1, "New");
                hrEventStore.changeEdit();
              }}
            >
              Cancel
            </Button>
          </Row>
          <b />
          <div style={{ minHeight: 500 }}> </div>
        </Container>
      </Card>
    );

    return (
      <div>
        <h3>HR Dashboard</h3>
        {hrEventStore.editMember ? (
          editmember
        ) : (
          <Button
            variant="success"
            onClick={() => {
              hrEventStore.chooseTeamMember(1, "New");
              hrEventStore.changeEdit();
            }}
          >
            Add New Item
          </Button>
        )}
        <hr />
        <h3>Staff List:</h3>
        <BootstrapTable
          data={hrEventStore.staffList}
          striped
          hover
          search={true}
          condensed
          options={this.options}
        >
          <TableHeaderColumn isKey dataField="id" hidden>
            ID
          </TableHeaderColumn>
          <TableHeaderColumn dataField="name" dataSort>
            Name
          </TableHeaderColumn>
          <TableHeaderColumn dataField="salary" dataSort>
            Salary
          </TableHeaderColumn>
          <TableHeaderColumn dataField="active" dataSort>
            Active
          </TableHeaderColumn>
          <TableHeaderColumn dataField="passportexpiry" dataSort>
            Passport Expiry
          </TableHeaderColumn>
          <TableHeaderColumn dataField="civilidexpiry" dataSort>
            Civil ID Expiry
          </TableHeaderColumn>
          <TableHeaderColumn dataField="driverlicenseexpiry" dataSort>
            Divers License Expiry
          </TableHeaderColumn>
        </BootstrapTable>
        <hr />
        <h3>Vehicle List:</h3>
        <BootstrapTable
          data={hrEventStore.carList}
          striped
          hover
          search={true}
          condensed
          options={this.options}
        >
          <TableHeaderColumn isKey dataField="id" hidden>
            ID
          </TableHeaderColumn>
          <TableHeaderColumn dataField="name" dataSort>
            Name
          </TableHeaderColumn>
          <TableHeaderColumn dataField="active" dataSort>
            Active
          </TableHeaderColumn>
          <TableHeaderColumn dataField="carregistrationexpiry" dataSort>
            Vehicle Registration Expiry
          </TableHeaderColumn>
          <TableHeaderColumn dataField="carinsuranceexpiry" dataSort>
            Vehicle Insurance Expiry
          </TableHeaderColumn>
          <TableHeaderColumn dataField="generalexpiry" dataSort>
            Vehicle Full Insurance Expiry
          </TableHeaderColumn>
        </BootstrapTable>
        <hr />
        <h3>Document List:</h3>
        <BootstrapTable
          data={hrEventStore.documentList}
          striped
          hover
          search={true}
          condensed
          options={this.options}
        >
          <TableHeaderColumn isKey dataField="id" hidden>
            ID
          </TableHeaderColumn>
          <TableHeaderColumn dataField="name" dataSort>
            Name
          </TableHeaderColumn>
          <TableHeaderColumn dataField="active" dataSort>
            Active
          </TableHeaderColumn>
          <TableHeaderColumn dataField="generalexpiry" dataSort>
            Expiry date
          </TableHeaderColumn>
        </BootstrapTable>
      </div>
    );
  }
}

export default observer(HRDashboard);
