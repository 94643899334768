import { decorate, observable, computed } from "mobx";
import axios from "axios";
import moment from "moment";
import userStore from "./UserStore";

const instance = axios.create({
  // baseURL: "https://easymovecalendar.app/"
  // baseURL: "http://127.0.0.1:8000/",
  // baseURL: "http://157.230.113.108/",
  baseURL: "https://jazzafs.com/",
});

class WarehouseStore {
  constructor() {
    this.inventoryinAB = [];
    this.inventoryoutAB = [];
    this.inventoryinFA = [];
    this.inventoryoutFA = [];
    this.loading = false;
    this.inventoryUpdated = false;
    this.showModal = false;
    this.material = "";
    this.quantity = 0;
    this.userfrom = "";
    this.userto = "";
    this.header = "";
  }

  postInventoryChange() {
    let data = {
      material: this.material,
      category: "Inventory",
      quantity: this.quantity,
      jobid: 0,
      userfrom: this.userfrom,
      userto: this.userto,
      paymentdate: moment(new Date()).format("YYYY-MM-DD"),
      useradded: userStore.profile.first_name,
    };
    return instance
      .post("/c-inventory/", data)
      .then((res) => res.data)
      .then((events) => {
        console.log(`${data.quantity} ${data.material} Submitted.`);
        this.resetPage();
        this.showModal = false;
        this.updateAllInventory();
      })
      .catch((err) => {
        alert(
          "An error occurred. The data did NOT save. Please contact me (97666886)."
        );
        console.error(err.response);
      });
  }

  resetPage() {
    this.material = "";
    this.quantity = 0;
    this.userfrom = "";
    this.userto = "";
    this.header = "";
  }

  updateAllInventory() {
    this.inventoryUpdated = false;
    this.fetchInventoryInAB();
  }

  changePage(val) {
    this.page = val;
  }

  changeModalView(type) {
    if (type === "close") {
      this.resetPage();
      this.showModal = false;
    }
    if (type === "Add to Stock") {
      this.header = "Add to Stock";
      this.userfrom = "Add to Stock";
      this.showModal = true;
    }
    if (type === "Withdraw Stock") {
      this.header = "Withdraw Stock";
      this.showModal = true;
    }
  }

  updateInventory(val, type) {
    if (type === "quantity") {
      this.quantity = parseFloat(val);
    } else if (type === "material") {
      this.material = val;
    } else if (type === "userfrom") {
      this.userfrom = val;
    } else if (type === "userto") {
      this.userto = val;
    }
  }

  fetchInventoryInAB() {
    this.loading = true;
    this.inventoryinAB = [];
    this.inventoryoutAB = [];
    return instance
      .get("/inventoryin/abuhulaifa/?format=json")
      .then((res) => res.data)
      .then((inventory) => {
        for (let i = 0; i < inventory.length; i++) {
          inventory[i].type = "In";
          inventory[i].balance = parseFloat(inventory[i].quantity);
          inventory[i].otheruser = inventory[i].userfrom;
        }
        this.inventoryinAB = inventory.filter(
          (inventory) => inventory.category !== "Job Related"
        );
        console.log("Inventory In Abu Hulaifa Fetched");
      })
      .then((inventory) => this.fetchInventoryOutAB())
      .catch((err) => {
        console.error(err.response);
        alert("Error Inventory In Abu Hulaifa");
      });
  }
  fetchInventoryOutAB() {
    return instance
      .get("/inventoryout/abuhulaifa/?format=json")
      .then((res) => res.data)
      .then((inventory) => {
        for (let i = 0; i < inventory.length; i++) {
          inventory[i].type = "Out";
          inventory[i].balance = -parseFloat(inventory[i].quantity);
          inventory[i].otheruser = inventory[i].userto;
        }
        this.inventoryoutAB = inventory.filter(
          (inventory) => inventory.category !== "Job Related"
        );
        console.log("Inventory Out Abu Hulaifa Fetched");
        this.fetchInventoryInFA();
      })
      .catch((err) => {
        console.error(err.response);
        alert("Error Inventory Out Abu Hulaifa");
      });
  }
  fetchInventoryInFA() {
    this.inventoryinFA = [];
    this.inventoryoutFA = [];
    return instance
      .get("/inventoryin/faiha/?format=json")
      .then((res) => res.data)
      .then((inventory) => {
        for (let i = 0; i < inventory.length; i++) {
          inventory[i].type = "In";
          inventory[i].balance = parseFloat(inventory[i].quantity);
          inventory[i].otheruser = inventory[i].userfrom;
        }
        this.inventoryinFA = inventory.filter(
          (inventory) => inventory.category !== "Job Related"
        );
        console.log("Inventory In Faiha Fetched");
      })
      .then((inventory) => this.fetchInventoryOutFA())
      .catch((err) => {
        console.error(err.response);
        alert("Error Inventory In Faiha");
      });
  }
  fetchInventoryOutFA() {
    return instance
      .get("/inventoryout/faiha/?format=json")
      .then((res) => res.data)
      .then((inventory) => {
        for (let i = 0; i < inventory.length; i++) {
          inventory[i].type = "Out";
          inventory[i].balance = -parseFloat(inventory[i].quantity);
          inventory[i].otheruser = inventory[i].userto;
        }
        this.inventoryoutFA = inventory.filter(
          (inventory) => inventory.category !== "Job Related"
        );
        console.log("Inventory Out Faiha Fetched");
        this.loading = false;
        this.inventoryUpdated = true;
      })
      .catch((err) => {
        console.error(err.response);
        alert("Error Inventory Out Faiha");
      });
  }

  get abuhulaifaInventory() {
    return this.inventoryinAB.concat(this.inventoryoutAB);
  }
  get faihaInventory() {
    return this.inventoryinFA.concat(this.inventoryoutFA);
  }
  get allInventory() {
    return this.abuhulaifaInventory.concat(this.faihaInventory);
  }

  get uniqueInventoryItems() {
    let inventory = this.allInventory;
    let items = [];
    for (let i = 0; i < inventory.length; i++) {
      items.push(inventory[i].material);
    }
    let unique = {};
    items.forEach(function (i) {
      if (!unique[i]) {
        unique[i] = true;
      }
    });
    return Object.keys(unique);
  }

  get inventorySummary() {
    let inventorySums = [];
    let materialQty = {};
    for (let i = 0; i < this.uniqueInventoryItems.length; i++) {
      materialQty = { type: "", qty: 0 };
      materialQty.type = this.uniqueInventoryItems[i];
      for (let j = 0; j < this.allInventory.length; j++) {
        if (this.allInventory[j].material === this.uniqueInventoryItems[i]) {
          materialQty.qty += parseFloat(this.allInventory[j].balance);
        }
      }
      inventorySums.push(materialQty);
    }
    return inventorySums;
  }
  get inventorySummaryAB() {
    let inventorySums = [];
    let materialQty = {};
    for (let i = 0; i < this.uniqueInventoryItems.length; i++) {
      materialQty = { type: "", qty: 0 };
      materialQty.type = this.uniqueInventoryItems[i];
      for (let j = 0; j < this.abuhulaifaInventory.length; j++) {
        if (
          this.abuhulaifaInventory[j].material === this.uniqueInventoryItems[i]
        ) {
          materialQty.qty += parseFloat(this.abuhulaifaInventory[j].balance);
        }
      }
      inventorySums.push(materialQty);
    }
    return inventorySums;
  }
  get inventorySummaryFA() {
    let inventorySums = [];
    let materialQty = {};
    for (let i = 0; i < this.uniqueInventoryItems.length; i++) {
      materialQty = { type: "", qty: 0 };
      materialQty.type = this.uniqueInventoryItems[i];
      for (let j = 0; j < this.faihaInventory.length; j++) {
        if (this.faihaInventory[j].material === this.uniqueInventoryItems[i]) {
          materialQty.qty += parseFloat(this.faihaInventory[j].balance);
        }
      }
      inventorySums.push(materialQty);
    }
    return inventorySums;
  }
}

decorate(WarehouseStore, {
  inventoryinAB: observable,
  inventoryinFA: observable,
  inventoryoutAB: observable,
  inventoryoutFA: observable,
  loading: observable,
  quantity: observable,
  material: observable,
  userfrom: observable,
  userto: observable,
  header: observable,
  page: observable,
  inventoryUpdated: observable,
  showModal: observable,
  abuhulaifaInventory: computed,
  faihaInventory: computed,
  allInventory: computed,
  uniqueInventoryItems: computed,
  inventorySummary: computed,
  inventorySummaryAB: computed,
  inventorySummaryFA: computed,
});

const warehouseStore = new WarehouseStore();

export default warehouseStore;
