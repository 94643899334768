import React from "react";
import { observer } from "mobx-react";

import eventStore from "../Stores/EventStore";
import { Button, Modal, Row, Col } from "react-bootstrap";

import moment from "moment";

import "react-datepicker/dist/react-datepicker.css";

import TextField from "@material-ui/core/TextField";

import userStore from "../Stores/UserStore";

import { DatePicker } from "antd";
import { Select, Input } from "antd";
const { Option } = Select;
const { TextArea } = Input;

class PaymentModalNew extends React.Component {
  render() {
    let singlepayment = eventStore.newpayment;
    let category1list = [
      "Local Move",
      "International Move",
      "Storage",
      "Custom Clearance",
      "Express Delivery",
      "General",
      "Transfer",
      "Other"
    ];
    let category2list = [];
    let category3list = ["Cash"];
    let category4list = [];
    let category5list = [];

    if (userStore.access < 3) {
      category2list = [
        "Income",
        "Expense Variable",
        "Expense Fixed",
        "Transfer In",
        "Transfer Out"
      ];
      if (singlepayment.category2 === "Expense Fixed") {
        category3list = ["Cash", "Non-Cash"];
      } else {
        category3list = ["Cash"];
      }
    } else {
      category2list = ["Income", "Expense Variable", "Expense Fixed"];
    }

    if (singlepayment.category2 === "Income") {
      category4list = ["Job Related", "Transfer Between Accounts", "Other"];
    } else if (singlepayment.category2 === "Expense Variable") {
      category4list = [
        "Bank Related",
        "Conference",
        "Job Related",
        "Marketing",
        "Materials",
        "Office",
        "Phone",
        "Vehicle",
        "Transfer Between Accounts",
        "Other"
      ];
    } else if (singlepayment.category2 === "Expense Fixed") {
      if (singlepayment.category3 === "Cash") {
        category4list = [
          "Payroll",
          "Membership",
          "Rent",
          "Bank Related",
          "Other"
        ];
      } else if (singlepayment.category3 === "Non-Cash") {
        category4list = [
          "Depreciation",
          "Interest",
          "Accrued Expense",
          "Other"
        ];
      }
    }

    if (singlepayment.category4 === "Job Related") {
      if (singlepayment.category2 === "Income") {
        category5list = ["Customer Payment", "Other"];
      } else if (singlepayment.category2 === "Expense Variable") {
        category5list = [
          "Refund",
          "Damage",
          "Additional Materials",
          "Clearance Charge",
          "Insurance",
          "Shipping Expense",
          "Other"
        ];
      }
    } else if (singlepayment.category4 === "Transfer Between Accounts") {
      if (singlepayment.category2 === "Income") {
        category5list = [
          "From NBK",
          "From NBK-Clearance",
          "From Mastercard",
          "From KFH",
          "From Boubyan",
          "From Cash"
        ];
      } else {
        category5list = [
          "To NBK",
          "To NBK-Clearance",
          "To Mastercard",
          "To KFH",
          "To Boubyan",
          "To Cash"
        ];
      }
    } else if (singlepayment.category4 === "Materials") {
      category5list = [
        "Boxes",
        "Bubble Wrap",
        "Nylon Wrap",
        "Hanger Box",
        "Tape",
        "Stretch Film",
        "Other"
      ];
    } else if (singlepayment.category4 === "Office") {
      category5list = [
        "Furniture",
        "Printing",
        "Telephone",
        "Computer",
        "Stationary",
        "Transportation",
        "Government Fees",
        "Other"
      ];
    } else if (singlepayment.category4 === "Rent") {
      category5list = [
        "Office 1",
        "Office 2",
        "Office 3 (old)",
        "Storage Facility",
        "Employee Housing",
        "Other"
      ];
    } else if (singlepayment.category4 === "Bank Related") {
      category5list = [
        "Bank Charge",
        "POS Rental",
        "Online Link Charge",
        "Other"
      ];
    } else if (singlepayment.category4 === "Payroll") {
      category5list = [
        "Salary",
        "Bonus",
        "Social Security",
        "Overtime",
        "Insurance",
        "Other"
      ];
    } else if (singlepayment.category4 === "Vehicle") {
      category5list = [
        "Insurance",
        "Full Insurance",
        "Gas",
        "Repairs",
        "Other"
      ];
    } else if (singlepayment.category4 === "Phone") {
      category5list = ["Phone"];
    } else if (singlepayment.category4 === "Marketing") {
      category5list = [
        "Website & SEO",
        "Advertisement",
        "Google Ads",
        "Social Media",
        "Other"
      ];
    } else if (singlepayment.category4 === "Membership") {
      category5list = ["IAM", "FIATA", "ARA", "Other"];
    } else if (singlepayment.category4 === "Conference and Travel") {
      category5list = [
        "Conference",
        "Perdium",
        "Travel",
        "Accomodation",
        "Other"
      ];
    } else if (singlepayment.category3 === "Non-Cash") {
      category5list = [singlepayment.category4];
    } else if (singlepayment.category4 === "Office") {
      category5list = [singlepayment.category4];
    }

    if (singlepayment.category4 === "Other") {
      category5list = ["Other", "Visa Cost"];
    }

    let cat1list = category1list.map((cat, index) => (
      <Option key={index} value={cat}>
        {cat}
      </Option>
    ));
    let cat2list = category2list.map((cat, index) => (
      <Option key={index} value={cat}>
        {cat}
      </Option>
    ));
    let cat3list = category3list.map((cat, index) => (
      <Option key={index} value={cat}>
        {cat}
      </Option>
    ));
    let cat4list = category4list.map((cat, index) => (
      <Option key={index} value={cat}>
        {cat}
      </Option>
    ));
    let cat5list = category5list.map((cat, index) => (
      <Option key={index} value={cat}>
        {cat}
      </Option>
    ));
    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            ID# {eventStore.newpayment.id}: {eventStore.newpayment.category2} on{" "}
            {moment(eventStore.newpayment.paymentdate).format("DD-MMM-YYYY")}
            {eventStore.newpayment.clientname !== "" &&
              ` - Job #${eventStore.newpayment.job}: 
              ${eventStore.newpayment.clientname}`}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col md={3}>
              <b>Type</b>
            </Col>
            <Col md={9}>
              <Select
                showSearch
                placeholder="Type"
                optionFilterProp="children"
                value={singlepayment.category2}
                onChange={e => eventStore.updatePayment(e, "category2")}
                style={{ width: "100%" }}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {cat2list}
              </Select>
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={3}>
              <b>Cash</b>
            </Col>
            <Col md={9}>
              <Select
                showSearch
                placeholder="Cash"
                optionFilterProp="children"
                value={singlepayment.category3}
                onChange={e => eventStore.updatePayment(e, "category3")}
                style={{ width: "100%" }}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {cat3list}
              </Select>
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={3}>
              <b>General</b>
            </Col>
            <Col md={9}>
              <Select
                showSearch
                placeholder="General"
                optionFilterProp="children"
                value={singlepayment.category4}
                onChange={e => eventStore.updatePayment(e, "category4")}
                style={{ width: "100%" }}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {cat4list}
              </Select>
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={3}>
              <b>Specific</b>
            </Col>
            <Col md={9}>
              <Select
                showSearch
                placeholder="Specific"
                optionFilterProp="children"
                value={singlepayment.category5}
                style={{ width: "100%" }}
                onChange={e => eventStore.updatePayment(e, "category5")}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {cat5list}
              </Select>
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={3}>
              <b>Department</b>
            </Col>
            <Col md={9}>
              <Select
                showSearch
                placeholder="Department"
                optionFilterProp="children"
                style={{ width: "100%" }}
                value={singlepayment.category1}
                onChange={e => eventStore.updatePayment(e, "category1")}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {cat1list}
              </Select>
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={3}>
              <b>Payment Method</b>
            </Col>
            <Col md={9}>
              <Select
                showSearch
                placeholder="Payment Method"
                optionFilterProp="children"
                style={{ width: "100%" }}
                value={singlepayment.paymentmethod}
                onChange={e => eventStore.updatePayment(e, "paymentmethod")}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value="Cash">Cash</Option>
                <Option value="KNET">KNET</Option>
                <Option value="Online Link">Online Link</Option>
                <Option value="Check">Check</Option>
                <Option value="Bank Transfer">Bank Transfer</Option>
              </Select>
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={3}>
              <b>Bank Account</b>
            </Col>
            <Col md={9}>
              <Select
                showSearch
                placeholder="Payment Method"
                optionFilterProp="children"
                style={{ width: "100%" }}
                value={singlepayment.bankaccount}
                onChange={e => eventStore.updatePayment(e, "bankaccount")}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value="Cash">Cash</Option>
                <Option value="Boubyan">Boubyan</Option>
                <Option value="NBK">NBK</Option>
                <Option value="NBK-Clearance">NBK-Clearance</Option>
                <Option value="Mastercard">Mastercard</Option>
                <Option value="KFH">KFH</Option>
              </Select>
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={3}>
              <b>Payment Date</b>
            </Col>
            <Col md={9}>
              <DatePicker
                size="large"
                format={"DD-MMM-YY"}
                style={{ width: "100%" }}
                onChange={e => eventStore.updatePayment(e, "paymentdate")}
                defaultValue={
                  (singlepayment.paymentdate !== null) &
                    (singlepayment.paymentdate !== "") &&
                  moment(singlepayment.paymentdate)
                }
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={3}>
              <b>Amount (KD)</b>
            </Col>
            <Col md={9}>
              <TextField
                size="small"
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Invoice Amount KWD"
                name="invoiceamount"
                autoComplete="invoiceamount"
                autoFocus
                type="number"
                style={{ width: "100%" }}
                value={singlepayment.invoiceamount}
                onChange={e =>
                  eventStore.updatePayment(
                    e.target.value,

                    "invoiceamount"
                  )
                }
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={3}>
              <b>Voucher Number</b>
            </Col>
            <Col md={9}>
              <TextField
                size="small"
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Voucher Number"
                name="vouchernumber"
                autoComplete="vouchernumber"
                autoFocus
                type="text"
                style={{ width: "100%" }}
                value={singlepayment.vouchernumber}
                onChange={e =>
                  eventStore.updatePayment(
                    e.target.value,

                    "vouchernumber"
                  )
                }
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={3}>
              <b>Invoice Number</b>
            </Col>
            <Col md={9}>
              <TextField
                size="small"
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Invoice Number"
                name="invoicenumber"
                autoComplete="invoicenumber"
                autoFocus
                type="text"
                style={{ width: "100%" }}
                value={singlepayment.invoicenumber}
                onChange={e =>
                  eventStore.updatePayment(
                    e.target.value,

                    "invoicenumber"
                  )
                }
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col md={3}>
              <b>Remarks</b>
            </Col>
            <Col md={9}>
              <TextArea
                placeholder="Payment Remarks"
                autoSize
                style={{ width: "100%" }}
                value={singlepayment.remarks}
                onChange={e =>
                  eventStore.updatePayment(
                    e.target.value,

                    "remarks"
                  )
                }
              />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col md={3}>
              <b>Available in Bank</b>
            </Col>
            <Col md={9}>
              <Select
                showSearch
                disabled={userStore.access > 1.5}
                placeholder="Cross-Checked"
                style={{ width: "100%" }}
                optionFilterProp="children"
                value={singlepayment.expenseheading}
                onChange={e => eventStore.updatePayment(e, "expenseheading")}
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value=""></Option>
                <Option value="Yes">Yes</Option>
                <Option value="No">No</Option>
              </Select>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <p>Last Updated =</p>
          <Button
            variant={!eventStore.edit ? "outline-secondary " : "success"}
            onClick={() => {
              this.props.onHide();
              this.setState({ modalShow: false });
              eventStore.postNewPaymentUpdate(singlepayment, "post");
            }}
          >
            Yes Make Changes
          </Button>
          <Button
            variant="secondary"
            onClick={() => {
              this.props.onHide();
              this.setState({ modalShow: false });
              eventStore.postNewPaymentUpdate(eventStore.newpayment, "reset");
            }}
          >
            No, Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default observer(PaymentModalNew);
