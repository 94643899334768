import React from "react";
// First way to import
import moment from "moment";
import { observer } from "mobx-react";
import { CircleLoader } from "react-spinners";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Modal, Button } from "react-bootstrap";
import eventStore from "../Stores/EventStore";
import jobSelectionStore from "../Stores/JobSelectionStore";
import editJobStore from "../Stores/EditJobStore";

moment.locale("ko", {
  week: {
    dow: 6,
    doy: 6,
  },
});

class JobModal extends React.Component {
  render() {
    let sortedaddress = jobSelectionStore.singlejob.address;
    let sorteddates = jobSelectionStore.singlejob.jobdate;
    let sortedstorages = jobSelectionStore.singlejob.storage;

    let addresses;
    let jobdatesList;
    let storageList;

    if (sortedaddress.length > 0) {
      sortedaddress = sortedaddress.slice().sort(function (first, second) {
        return -second.order - -first.order;
      });
      addresses = sortedaddress.map((address, index) => (
        <div key={index}>
          {" "}
          {index + 1}) From: {address.locationfrom}
          <br />- To: {address.locationto}
        </div>
      ));
    }
    if (sorteddates.length > 0) {
      sorteddates = sorteddates.slice().sort(function (first, second) {
        return -moment(second.jobdate) - -moment(first.jobdate);
      });
      jobdatesList = sorteddates.map((date, index) => (
        <div key={index}>
          {" "}
          {index + 1}) {moment(date.jobdate).format("DD-MMM-YYYY")}
        </div>
      ));
    }
    if (sortedstorages.length > 0) {
      sortedstorages = sortedstorages.slice().sort(function (first, second) {
        return (
          -moment(second.storagestartdate) - -moment(first.storagestartdate)
        );
      });
      storageList = sortedstorages.map((storage, index) => (
        <div key={index}>
          {" "}
          {index + 1}) {Math.round(1000 * storage.monthlycost) / 1000} KD for{" "}
          {Math.round(10 * storage.storagecbm) / 10} CBM from{" "}
          {moment(storage.storagestartdate).format("DD-MMM-YYYY")} to{" "}
          {moment(storage.storageenddate).format("DD-MMM-YYYY")}
        </div>
      ));
    }

    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        {jobSelectionStore.Modalloading && (
          <CircleLoader
            sizeUnit={"px"}
            size={50}
            color={"#0017FF"}
            loading={true}
          />
        )}
        <Modal.Header closeButton>
          <Modal.Title>
            {jobSelectionStore.singlejob.clienttitle} -{" "}
            {jobSelectionStore.singlejob.status} -{" "}
            {jobSelectionStore.singlejob.emfocalpoint === ""
              ? "Not Assigned"
              : `With ${jobSelectionStore.singlejob.emfocalpoint}`}
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <h4>
            {jobSelectionStore.singlejob.jobmain} -{" "}
            {jobSelectionStore.singlejob.jobsecond}
          </h4>
          <h5>
            {Math.round(1000 * jobSelectionStore.singlejob.lastprice) / 1000} KD
            for {Math.round(10 * jobSelectionStore.singlejob.cbm) / 10} CBM.
            {"    "}
            <i>
              [{Math.round(jobSelectionStore.singlejob.profit * 10) / 10} KD
              Profit]
            </i>
          </h5>
          <p>
            Phone: {jobSelectionStore.singlejob.phone1}{" "}
            {jobSelectionStore.singlejob.phone2} / Email:{" "}
            {jobSelectionStore.singlejob.email}
          </p>
          {sortedaddress.length > 0 && (
            <div>
              <p />
              <b>{jobSelectionStore.singlejob.address.length} Address:</b>{" "}
              {addresses}
              <p />
            </div>
          )}
          <p />
          <b>Job Dates:</b> {jobdatesList}
          <p />
          {sortedstorages.length > 0 && (
            <div>
              <b>Storage Jobs:</b> {storageList}
            </div>
          )}
          <p>
            <b>Remarks:</b> {jobSelectionStore.singlejob.remarks}
          </p>
        </Modal.Body>
        <Modal.Footer>
          <p>
            Last Updated ={" "}
            {moment(jobSelectionStore.singlejob.lastupdate).format("DD-MMM-YY")}{" "}
            by {jobSelectionStore.singlejob.userupdated}
          </p>
          <Button
            variant={!eventStore.edit ? "outline-secondary " : "success"}
            onClick={() => {
              this.props.onHide();
              editJobStore.fetchJob(jobSelectionStore.singlejob.id);
            }}
          >
            Yes Make Changes
          </Button>
          <Button variant="secondary" onClick={this.props.onHide}>
            No, Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default observer(JobModal);
