import React from "react";
// First way to import
import { observer } from "mobx-react";
import { Row, Col } from "react-bootstrap";
import FormControl from "@material-ui/core/FormControl";
import moment from "moment";
import { DatePicker, Select, Input } from "antd";

import editJobStore from "../../Stores/EditJobStore";

const { Option } = Select;
const { TextArea } = Input;

const onChange = (date, dateString) => {
  editJobStore.updateJob("estimationdate", date);
};

class JobTabHeader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let jobpricecomment = `${
      Math.round(1000 * editJobStore.job.lastprice) / 1000
    } KD `;
    if (editJobStore.addchargeprice > 0)
      jobpricecomment += `+ ${
        Math.round(1000 * editJobStore.addchargeprice) / 1000
      } KD `;
    if (editJobStore.totalstorageprice > 0)
      jobpricecomment += `+ ${
        Math.round(1000 * editJobStore.totalstorageprice) / 1000
      } KD `;
    if (editJobStore.totalstorageprice > 0 || editJobStore.addchargeprice > 0) {
      jobpricecomment += ` = ${
        Math.round(1000 * editJobStore.totaljobprice) / 1000
      } KD`;
    }

    let feedbackcells = (
      <div>
        <Row>
          <Col md={3}>
            <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              size="small"
            >
              <b
                style={{
                  color: editJobStore.job.rating === "" ? "red" : "black",
                }}
              >
                Rating
              </b>
              <Select
                showSearch
                error
                width="100%"
                placeholder="Job Status"
                optionFilterProp="children"
                onChange={(e) => editJobStore.updateJob("rating", e)}
                value={parseFloat(editJobStore.job.rating)}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value={1}>Bad</Option>
                <Option value={3}>Neutral</Option>
                <Option value={5}>Good</Option>
                <Option value={0}>Not Reachable</Option>
              </Select>
            </FormControl>
          </Col>
          <Col md={9}>
            <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              size="small"
            >
              <b
                style={{
                  color: editJobStore.job.feedback === "" ? "red" : "black",
                }}
              >
                Customer Feedback
              </b>
              <TextArea
                autoSize={true}
                style={{ width: "100%" }}
                value={editJobStore.job.feedback}
                onChange={(e) =>
                  editJobStore.updateJob("feedback", e.target.value)
                }
              />
            </FormControl>
          </Col>
        </Row>
      </div>
    );
    let cancelledfeedbackcells = (
      <div>
        <Row>
          <Col md={4}>
            <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              size="small"
            >
              <b
                style={{
                  color:
                    editJobStore.job.cancellationreason === ""
                      ? "red"
                      : "black",
                }}
              >
                Cancellation Reason:
              </b>
              <Select
                showSearch
                error
                width="100%"
                placeholder="Job Status"
                optionFilterProp="children"
                onChange={(e) =>
                  editJobStore.updateJob("cancellationreason", e)
                }
                value={editJobStore.job.cancellationreason}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value="" />
                <Option value={"Price Too High"}>Price Too High</Option>
                <Option value={"Client Unreachable"}>Client Unreachable</Option>
                <Option value={"Date NA"}>Date NA</Option>
                <Option value={"Not Interested"}>Not Interested</Option>
                <Option value={"Service Not Available"}>
                  Service Not Available
                </Option>
                <Option value={"Too Long to Price"}>Too Long to Price</Option>
                <Option value={"Other"}>Other</Option>
              </Select>
            </FormControl>
          </Col>
          <Col md={8}>
            <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              size="small"
            >
              <b
                style={{
                  color:
                    editJobStore.job.cancellationremarks === ""
                      ? "red"
                      : "black",
                }}
              >
                Cancellation Remarks:
              </b>
              <TextArea
                autoSize={true}
                style={{ width: "100%" }}
                value={editJobStore.job.cancellationremarks}
                onChange={(e) =>
                  editJobStore.updateJob("cancellationremarks", e.target.value)
                }
              />
            </FormControl>
          </Col>
        </Row>
      </div>
    );

    return (
      <div>
        {" "}
        <Row>
          <Col md={6}>
            <h3>
              Client ({editJobStore.client.id}) {editJobStore.client.title} -
              Job ({editJobStore.job.id}): {editJobStore.job.jobmain} -{" "}
              {editJobStore.job.jobsecond}
            </h3>
          </Col>
          <Col md={3}>
            <h5>
              {jobpricecomment} [Profit = {editJobStore.profitTotal} KD]
            </h5>
          </Col>
          <Col md={3}>
            <h5>
              Paid ({editJobStore.totalPaid}) KD and Outstanding (
              {editJobStore.totalOutstanding}) KD
            </h5>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              size="small"
            >
              <b
                style={{
                  color: editJobStore.job.status === "" ? "red" : "black",
                }}
              >
                Status
              </b>
              <Select
                showSearch
                error
                width="100%"
                placeholder="Job Status"
                optionFilterProp="children"
                onChange={(e) => editJobStore.updateJob("status", e)}
                value={editJobStore.job.status}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value={""}></Option>
                <Option value={"Lead"}>Lead</Option>
                <Option value={"Proposal"}>Proposal</Option>
                <Option value={"Booked"}>Booked</Option>
                <Option value={"Converted"}>Converted</Option>
                <Option value={"Cancelled"}>Cancelled</Option>
              </Select>
            </FormControl>
          </Col>
          <Col>
            <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              size="small"
            >
              <b
                style={{
                  color:
                    editJobStore.job.estimationType === "" ? "red" : "black",
                }}
              >
                Estimation Type
              </b>
              <Select
                showSearch
                error
                width="100%"
                placeholder="Estimation Type"
                optionFilterProp="children"
                onChange={(e) => editJobStore.updateJob("estimationType", e)}
                value={editJobStore.job.estimationType}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value={""}></Option>
                <Option value={"Scheduled"}>Scheduled</Option>
                <Option value={"On Site"}>On Site</Option>
                <Option value={"By Phone"}>By Phone</Option>
                <Option value={"Online"}>Online</Option>
                <Option value={"No Estimation"}>No Estimation</Option>
                <Option value={"Other"}>Other</Option>
              </Select>
            </FormControl>
          </Col>
          <Col>
            <FormControl variant="outlined" style={{ width: "100%" }}>
              <b>Estimation Date</b>
              <DatePicker
                onChange={onChange}
                format={"DD-MMM-YY"}
                size="medium"
                defaultValue={
                  (editJobStore.job.estimationdate !== null) &
                    (editJobStore.job.estimationdate !== "") &&
                  moment(editJobStore.job.estimationdate)
                }
              />
            </FormControl>
          </Col>
          <Col>
            <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              size="small"
            >
              <b
                style={{
                  color: editJobStore.job.emfocalpoint === "" ? "red" : "black",
                }}
              >
                Focal Point
              </b>
              <Select
                showSearch
                error
                width="100%"
                placeholder="Focal Point"
                optionFilterProp="children"
                onChange={(e) => editJobStore.updateJob("emfocalpoint", e)}
                value={editJobStore.job.emfocalpoint}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value={""}></Option>
                <Option value={"Aasim"}>Aasim</Option>
                <Option value={"Katrina"}>Katrina</Option>
                <Option value={"Reyna"}>Reyna</Option>
                <Option value={"Vinith"}>Vinith</Option>
                <Option value={"Yobel"}>Yobel</Option>
                <Option value={"Other"}>Other</Option>
              </Select>
            </FormControl>
          </Col>
        </Row>
        <Row>
          <Col>
            <b>Remarks:</b>
            <TextArea
              autoSize={true}
              style={{ width: "100%" }}
              value={editJobStore.job.remarks}
              onChange={(e) =>
                editJobStore.updateJob("remarks", e.target.value)
              }
            />
          </Col>
        </Row>
        {editJobStore.job.status === "Converted" && feedbackcells}
        {editJobStore.job.status === "Cancelled" && cancelledfeedbackcells}
        <hr />
      </div>
    );
  }
}

export default observer(JobTabHeader);
