import React, { Component } from "react";
import { observer } from "mobx-react";

import eventStore from "../Stores/EventStore";
import settingStore from "../Stores/SettingStore";
import NewClient from "../ClientEdit/NewClient";
import EditClient from "../ClientEdit/EditClient";
import JobList from "../Cards/JobList";
import JobTabsPage from "../JobEdit/JobTabsPage";

class ControlsPage extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let clientForm;
    if (settingStore.newClient) {
      clientForm = <NewClient />;
    } else if (settingStore.viewClient) {
      clientForm = <EditClient />;
    } else if (settingStore.editJob) {
      clientForm = <JobTabsPage />;
    } else if (eventStore.editClient.editOn === true) {
      clientForm = <JobList />;
    }
    if (settingStore.editJob) {
      clientForm = <JobTabsPage />;
    }

    return <div>{clientForm}</div>;
  }
}

export default observer(ControlsPage);
