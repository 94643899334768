import React from "react";
// First way to import
import { observer } from "mobx-react";
import { Button, Container, Row, Col } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import { Select } from "antd";

import editJobStore from "../../Stores/EditJobStore";

const { Option } = Select;

class JobDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  onChangeSelect(type, val) {
    editJobStore.updateJob(type, val);
  }

  onChangeAddCharge(type, val, index) {
    editJobStore.updateAddCharge(type, val, index);
  }

  render() {
    let addChargeTotal = 0;
    for (let i = 0; i < editJobStore.addcharge.length; i++) {
      addChargeTotal =
        addChargeTotal + parseFloat(editJobStore.addcharge[i].price);
    }

    addChargeTotal = Math.round(1000 * addChargeTotal) / 1000;

    let minPrice = 0;
    let maxPrice = 0;

    if (editJobStore.job.jobmain === "Local Move") {
      if (editJobStore.job.jobsecond === "Full Service") {
        minPrice = editJobStore.job.cbm * 7;
        maxPrice = editJobStore.job.cbm * 9;
        if (minPrice < 65) {
          minPrice = 65;
        }
        if (maxPrice < 65) {
          maxPrice = 65;
        }
      } else if (editJobStore.job.jobsecond === "Regular Service") {
        minPrice = editJobStore.job.cbm * 4;
        maxPrice = editJobStore.job.cbm * 6;
        if (minPrice < 65) {
          minPrice = 65;
        }
        if (maxPrice < 65) {
          maxPrice = 65;
        }
      }
    } else if (editJobStore.job.jobmain === "Corporate Move") {
      if (editJobStore.job.jobsecond === "Full Service") {
        minPrice = editJobStore.job.cbm * 8;
        maxPrice = editJobStore.job.cbm * 10;
        if (minPrice < 65) {
          minPrice = 65;
        }
        if (maxPrice < 65) {
          maxPrice = 65;
        }
      } else if (editJobStore.job.jobsecond === "Regular Service") {
        minPrice = editJobStore.job.cbm * 5;
        maxPrice = editJobStore.job.cbm * 7;
        if (minPrice < 65) {
          minPrice = 65;
        }
        if (maxPrice < 65) {
          maxPrice = 65;
        }
      }
    }

    let secondjob;
    if (
      editJobStore.job.jobmain === "Local Move" ||
      editJobStore.job.jobmain === "Corporate Move"
    ) {
      secondjob = (
        <Select
          showSearch
          placeholder="Second Job"
          optionFilterProp="children"
          value={editJobStore.job.jobsecond}
          onChange={(e) => editJobStore.updateJob("jobsecond", e)}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          <Option value="" />
          <Option value={"Full Service"}>Full Service</Option>
          <Option value={"Regular Service"}>Regular Service</Option>
        </Select>
      );
    } else if (
      editJobStore.job.jobmain === "IM - Inbound" ||
      editJobStore.job.jobmain === "CIM - Inbound" ||
      editJobStore.job.jobmain === "IM - Outbound" ||
      editJobStore.job.jobmain === "CIM - Outbound"
    ) {
      secondjob = (
        <Select
          showSearch
          placeholder="Second Job"
          optionFilterProp="children"
          value={editJobStore.job.jobsecond}
          onChange={(e) => editJobStore.updateJob("jobsecond", e)}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          <Option value="" />
          <Option value={"Door to Door"}>Door to Door</Option>
          <Option value={"Port to Port"}>Port to Port</Option>
          <Option value={"Port to Door"}>Port to Door</Option>
          <Option value={"Door to Port"}>Door to Port</Option>
        </Select>
      );
    } else if (editJobStore.job.jobmain === "Other") {
      secondjob = (
        <Select
          placeholder="Second Job"
          optionFilterProp="children"
          value={editJobStore.job.jobsecond}
          onChange={(e) => editJobStore.updateJob("jobsecond", e)}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          <Option value="" />
          <Option value={"Other"}>Other</Option>
        </Select>
      );
    } else {
      secondjob = (
        <Select
          placeholder="Second Job"
          optionFilterProp="children"
          value={editJobStore.job.jobsecond}
          onChange={(e) => editJobStore.updateJob("jobsecond", e)}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          <Option value="" />
          <Option value={"Only Storage"}>Only Storage</Option>
          <Option value={"Only Clearance"}>Only Clearance</Option>
        </Select>
      );
    }

    let addChargeList;

    addChargeList = editJobStore.addcharge.map((addcharge, index) => (
      <div key={index}>
        <br />
        <Row>
          <Col md={2}>
            <b>Add Charge #{index + 1}</b>
          </Col>
          <Col>
            <TextField
              variant="outlined"
              label="Charge Name"
              size="small"
              style={{ width: "100%" }}
              value={addcharge.title}
              onChange={(e) =>
                editJobStore.updateAddCharge("title", e.target.value, index)
              }
            />
          </Col>
          <Col>
            <TextField
              variant="outlined"
              label="Charge Price (KD)"
              size="small"
              type="number"
              style={{ width: "100%" }}
              value={addcharge.price}
              onChange={(e) =>
                editJobStore.updateAddCharge("price", e.target.value, index)
              }
            />
          </Col>
          <Col>
            <Button
              block
              variant="outline-danger"
              onClick={() => editJobStore.updateAddCharge("delete", 0, index)}
            >
              <b>x</b>
            </Button>
          </Col>
        </Row>
      </div>
    ));

    return (
      <div>
        <Container style={{ maxWidth: "98%" }}>
          <h3>Job Details</h3>
          <hr />
          <Row>
            <Col>
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                size="small"
              >
                <b
                  style={{
                    color: editJobStore.job.jobmain === "" ? "red" : "black",
                  }}
                >
                  Main Job *
                </b>
                <Select
                  showSearch
                  error
                  width="100%"
                  placeholder="Select a Main Job"
                  optionFilterProp="children"
                  onChange={(e) => this.onChangeSelect("jobmain", e)}
                  value={editJobStore.job.jobmain}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  style={{ borderColor: "red" }}
                >
                  <Option value={""}></Option>
                  <Option value={"Local Move"}>Local Move</Option>
                  <Option value={"Corporate Move"}>Corporate Move</Option>
                  <Option value={"IM - Inbound"}>IM - Inbound</Option>
                  <Option value={"IM - Outbound"}>IM - Outbound</Option>
                  <Option value={"CIM - Inbound"}>CIM - Inbound</Option>
                  <Option value={"CIM - Outbound"}>CIM - Outbound</Option>
                  <Option value={"Only Storage"}>Only Storage</Option>
                  <Option value={"Only Clearance"}>Only Clearance</Option>
                  <Option value={"Other"}>Other</Option>
                </Select>
              </FormControl>
            </Col>
            <Col>
              <FormControl
                variant="outlined"
                style={{ width: "100%" }}
                size="small"
              >
                <b
                  style={{
                    color: editJobStore.job.jobsecond === "" ? "red" : "black",
                  }}
                >
                  Second Job *
                </b>
                {secondjob}
              </FormControl>
            </Col>
            <Col>
              <TextField
                label="Company/Group"
                style={{ width: "100%" }}
                value={editJobStore.job.companygroup}
                onChange={(e) =>
                  this.onChangeSelect("companygroup", e.target.value)
                }
              />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <b
                style={{
                  color: editJobStore.job.cbm > 0 ? "black" : "red",
                }}
              >
                CBM
              </b>
              <TextField
                required
                variant="outlined"
                size="small"
                type="number"
                style={{ width: "100%", color: "red" }}
                value={editJobStore.job.cbm}
                onChange={(e) => this.onChangeSelect("cbm", e.target.value)}
              />
            </Col>

            <Col>
              <b>Min Price</b>
              <TextField
                disabled
                variant="outlined"
                size="small"
                type="number"
                style={{ width: "100%" }}
                value={minPrice}
              />
            </Col>
            <Col>
              <b>Max Price</b>
              <TextField
                disabled
                variant="outlined"
                size="small"
                type="number"
                style={{ width: "100%" }}
                value={maxPrice}
              />
            </Col>
            <Col>
              <b>Calculated Discount (%)</b>
              <TextField
                disabled
                variant="outlined"
                size="small"
                type="number"
                style={{ width: "100%" }}
                value={
                  Math.round(
                    (1 -
                      editJobStore.job.lastprice /
                        editJobStore.job.firstprice) *
                      10000
                  ) / 100
                }
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col>
              <b
                style={{
                  color: editJobStore.job.firstprice > 0 ? "black" : "red",
                }}
              >
                First Price
              </b>
              <TextField
                required
                variant="outlined"
                size="small"
                type="number"
                style={{ width: "100%" }}
                value={editJobStore.job.firstprice}
                onChange={(e) =>
                  this.onChangeSelect("firstprice", e.target.value)
                }
              />
            </Col>
            <Col>
              <b
                style={{
                  color: editJobStore.job.lastprice > 0 ? "black" : "red",
                }}
              >
                Moving Price ({minPrice} - {maxPrice}) KD
              </b>
              <TextField
                required
                variant="outlined"
                size="small"
                type="number"
                style={{ width: "100%" }}
                value={editJobStore.job.lastprice}
                onChange={(e) =>
                  this.onChangeSelect("lastprice", e.target.value)
                }
              />
            </Col>
            <Col>
              <b>Discount Name</b>
              <TextField
                variant="outlined"
                size="small"
                style={{ width: "100%" }}
                value={editJobStore.job.discountname}
                onChange={(e) =>
                  this.onChangeSelect("discountname", e.target.value)
                }
              />
            </Col>

            <Col>
              <b>Discount Percent (%)</b>
              <TextField
                variant="outlined"
                size="small"
                type="number"
                style={{ width: "100%" }}
                value={editJobStore.job.discountvalue}
                onChange={(e) =>
                  this.onChangeSelect("discountvalue", e.target.value)
                }
              />
            </Col>
          </Row>
          <hr />
          <Row>
            <Col>
              <Button
                block
                variant="outline-success"
                onClick={() => editJobStore.updateAddCharge("add", 0, 0)}
              >
                Add an Additional Charge
              </Button>
            </Col>
            <Col>
              <Button block variant="dark" disabled>
                Total Additional Charges = <b>+{addChargeTotal} KWD</b>
              </Button>
            </Col>
          </Row>
          <hr />
          {addChargeList}
          <hr />
          <Button block variant="dark">
            Total Move (
            {Math.round(1000 * parseFloat(editJobStore.job.lastprice)) / 1000})
            + Add Charges ({addChargeTotal}) ={" "}
            {Math.round(
              1000 *
                (parseFloat(editJobStore.job.lastprice) +
                  parseFloat(addChargeTotal))
            ) / 1000}{" "}
            KWD
          </Button>
        </Container>
      </div>
    );
  }
}

export default observer(JobDetails);
