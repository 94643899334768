import { decorate, observable, computed } from "mobx";
import axios from "axios";
import moment from "moment";
import newEventStore from "./NewEventStore";

const instance = axios.create({
  // baseURL: "https://easymovecalendar.app/"
  // baseURL: "http://127.0.0.1:8000/",
  // baseURL: "http://157.230.113.108/",
  baseURL: "https://jazzafs.com/",
});

class FinancialStore {
  constructor() {
    this.financiallist = [];
    this.filteredfinancial = [];
    this.loading = true;
  }

  fetchFinancialData(user) {
    return instance
      .get("/n-finance/?format=json")
      .then((res) => res.data)
      .then((client) => {
        this.financiallist = client;
        this.filteredfinancial = [];
      })
      .then((job) => {
        let data = {};
        let indexVal;

        for (let i = 0; i < this.financiallist.length; i++) {
          data = {
            lastprice: 0,
            storageprice: 0,
            addchargeprice: 0,
            customerpayment: 0,
            maxyear: 0,
            sorteddates: [],
            clientbalance: 0,
            clientname: "",
            clientid: 0,
            jobid: 0,
            status: "",
            emfocalpoint: "",
            jobmain: "",
            jobsecond: "",
          };

          data.sorteddates = this.financiallist[i].jobdate.sort(function (
            first,
            second
          ) {
            return moment(second.jobdate) - moment(first.jobdate);
          });

          if (data.sorteddates.length > 0) {
            data.maxyear = moment(data.sorteddates[0].jobdate).format("YYYY");
            data.lastjob = moment(data.sorteddates[0].jobdate).format(
              "DD-MMM-YYYY"
            );
          }

          indexVal = -1;
          indexVal = newEventStore.clients.findIndex(
            (client) => client.id === this.financiallist[i].client
          );

          //          if ((data.maxyear === "2020") & (indexVal > 0)) {
          data.clientname = newEventStore.clients[indexVal].title;
          data.clientid = this.financiallist[i].client;
          data.jobid = this.financiallist[i].id;
          data.status = this.financiallist[i].status;
          data.emfocalpoint = this.financiallist[i].emfocalpoint;
          data.jobmain = this.financiallist[i].jobmain;
          data.jobsecond = this.financiallist[i].jobsecond;
          data.lastprice = this.financiallist[i].lastprice;
          for (let k = 0; k < this.financiallist[i].storage.length; k++) {
            data.storageprice +=
              parseFloat(this.financiallist[i].storage[k].monthlycost) *
              Math.round(
                moment(this.financiallist[i].storage[k].storageenddate).diff(
                  moment(this.financiallist[i].storage[k].storagestartdate),
                  "months",
                  true
                )
              );
          }
          for (let q = 0; q < this.financiallist[i].addcharge.length; q++) {
            data.addchargeprice += parseFloat(
              this.financiallist[i].addcharge[q].price
            );
          }
          for (let x = 0; x < this.financiallist[i].payment.length; x++) {
            if (
              this.financiallist[i].payment[x].category5 === "Customer Payment"
            ) {
              data.customerpayment += parseFloat(
                this.financiallist[i].payment[x].invoiceamount
              );
            }
          }
          data.clientbalance =
            parseFloat(data.lastprice) +
            parseFloat(data.storageprice) +
            parseFloat(data.addchargeprice) -
            parseFloat(data.customerpayment);

          data.lastprice = Math.round(1000 * data.lastprice) / 1000;
          data.storageprice = Math.round(1000 * data.storageprice) / 1000;
          data.addchargeprice = Math.round(1000 * data.addchargeprice) / 1000;
          data.customerpayment = Math.round(1000 * data.customerpayment) / 1000;
          data.clientbalance = Math.round(1000 * data.clientbalance) / 1000;

          this.filteredfinancial.push(data);
          data = {
            lastprice: 0,
            storageprice: 0,
            addchargeprice: 0,
            customerpayment: 0,
            maxyear: 0,
            sorteddates: [],
            clientbalance: 0,
            clientname: "",
            clientid: 0,
            jobid: 0,
            status: "",
            emfocalpoint: "",
            jobmain: "",
            jobsecond: "",
          };
          //          }
        }
        this.loading = false;
      });
  }

  get freeofcostJobs() {
    let newfin = this.filteredfinancial.filter((job) => job.maxyear === "2020");
    let newlist = [];
    for (let i = 0; i < newfin.length; i++) {
      if (
        (newfin[i].lastprice === 0) &
        (newfin[i].storageprice === 0) &
        (newfin[i].addchargeprice === 0) &
        (newfin[i].customerpayment === 0)
      ) {
        newlist.push(newfin[i]);
      }
    }
    return newlist;
  }

  get missingPayments() {
    let newfin = this.filteredfinancial.filter((job) => job.maxyear === "2020");

    let newlist = [];
    for (let i = 0; i < newfin.length; i++) {
      if (
        newfin[i].lastprice === 0 ||
        newfin[i].storageprice === 0 ||
        newfin[i].addchargeprice === 0
      ) {
        if (newfin[i].clientbalance > 0) newlist.push(newfin[i]);
      }
    }
    return newlist;
  }

  get overPaid() {
    let newfin = this.filteredfinancial.filter((job) => job.maxyear === "2020");

    let newlist = [];
    for (let i = 0; i < newfin.length; i++) {
      if (
        newfin[i].lastprice === 0 ||
        newfin[i].storageprice === 0 ||
        newfin[i].addchargeprice === 0
      ) {
        if (newfin[i].clientbalance < 0) newlist.push(newfin[i]);
      }
    }
    return newlist;
  }

  get closedBalance() {
    let newfin = this.filteredfinancial.filter((job) => job.maxyear === "2020");

    let newlist = [];
    for (let i = 0; i < newfin.length; i++) {
      if (
        newfin[i].lastprice !== 0 ||
        newfin[i].storageprice !== 0 ||
        newfin[i].addchargeprice !== 0
      ) {
        if (newfin[i].clientbalance === 0) newlist.push(newfin[i]);
      }
    }
    return newlist;
  }
  get allclientfinances() {
    let newfin = this.filteredfinancial.filter((job) => job.maxyear === "2020");

    let newlist = [];
    for (let i = 0; i < newfin.length; i++) {
      if (
        newfin[i].lastprice !== 0 ||
        newfin[i].storageprice !== 0 ||
        newfin[i].addchargeprice !== 0
      ) {
        if (newfin[i].clientbalance !== 0) newlist.push(newfin[i]);
      }
    }
    return newlist;
  }
}

decorate(FinancialStore, {
  loading: observable,
  financiallist: observable,
  filteredfinancial: observable,
  freeofcostJobs: computed,
  missingPayments: computed,
  overPaid: computed,
  closedBalance: computed,
  allclientfinances: computed,
});

const financialStore = new FinancialStore();
export default financialStore;
