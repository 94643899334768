import React, { Component } from "react";
import { observer } from "mobx-react";

import newEventStore from "../Stores/NewEventStore";
import settingStore from "../Stores/SettingStore";
import { Button, Col, Row, Card, Modal } from "react-bootstrap";
import axios from "axios";

const instance = axios.create({
  // baseURL: "https://easymovecalendar.app/"
  // baseURL: "http://127.0.0.1:8000/",
  // baseURL: "http://157.230.113.108/",
  baseURL: "https://jazzafs.com/",
});

class AssignTLModal extends React.Component {
  render() {
    let job = newEventStore.jobforTL;

    let selectTeamLead;
    selectTeamLead = newEventStore.teamleads.map((teamlead, index) => (
      <Button
        key={index}
        variant={
          job.teamLeader === teamlead.first_name ? "success" : "outline-dark"
        }
        block
        onClick={() => newEventStore.selectJobForTL(job, teamlead.first_name)}
      >
        {teamlead.first_name}'s Team
      </Button>
    ));

    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>{job.clienttitle}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <p>
            {job.cbm} CBM for {job.lastprice} KD
          </p>
        </Modal.Body>
        <Modal.Body>
          {selectTeamLead}

          <Button
            variant={
              job.teamLeader === "" || job.teamLeader === null
                ? "success"
                : "outline-dark"
            }
            block
            onClick={() => newEventStore.selectJobForTL(job, "")}
          >
            None
          </Button>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="success"
            onClick={() => {
              let data = { teamLeader: job.teamLeader };

              instance
                .put("/job/" + job.id + "/", data)
                .then((res) => res.data)
                .then((events) => {
                  console.log("successfully added");
                  newEventStore.updateTLjob(job);
                  alert(`Job #${job.clienttitle} TL added.`);
                  this.props.onHide();
                  this.setState({ modalShow: false });
                })
                .catch((err) => {
                  alert(
                    "An error occurred. The data did NOT save. Please contact me (97666886)."
                  );
                  console.error(err.response);
                });
            }}
          >
            Save
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              newEventStore.selectJobForTL(job, "reset");
              this.props.onHide();
            }}
          >
            No, Cancel
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

class AssignJobCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      client: true,
      job: true,
      remarks: true,
      management: true,
      feedback: false,
      cancellation: false,
      update: true,
    };
  }

  render() {
    let job = this.props.job;
    return (
      <Col>
        <Card
          style={{
            width: "150px",
            outline: "#589654",
            boxShadow: "1px 1px 1px #589654",
          }}
        >
          <Card.Header as="h4">
            <Button
              block
              variant={"outline-success"}
              style={{
                backgroundColor: "transparent",
                color: "black",
                fontSize: 11,
              }}
              disabled={settingStore.editJob}
            >
              {job.id}: {job.clienttitle}
            </Button>
          </Card.Header>
          <Card.Body>
            <Card.Title style={{ fontSize: 10 }}>
              {job.jobmain} - {job.status}
            </Card.Title>
            <Card.Title style={{ fontSize: 9 }}>
              {job.cbm} CBM at {job.lastprice} KD
            </Card.Title>
            <Card.Text style={{ fontSize: 8 }}>
              {job.remarks.length > 100 && "..."}
              {job.remarks &&
                job.remarks.substring(
                  job.remarks.length - 100,
                  job.remarks.length
                )}
            </Card.Text>
          </Card.Body>
          <Card.Footer>
            <Card.Text style={{ fontSize: 10 }}>
              {job.teamLeader ? job.teamLeader : "Not Assigned"}
            </Card.Text>
          </Card.Footer>
        </Card>
      </Col>
    );
  }
}

class AssignJobs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      job: {},
      modalShow: false,
    };
  }

  componentDidMount() {
    if (!newEventStore.convLoaded) newEventStore.fetchConverted();
  }

  showModal = () => {
    this.setState({
      modalShow: true,
    });
  };

  handleOk = (e) => {
    this.setState({
      modalShow: false,
    });
  };

  handleCancel = (e) => {
    this.setState({
      modalShow: false,
    });
  };

  render() {
    let modalClose = () => {
      this.setState({ modalShow: false });
    };

    let notassigned = [];
    let armyTL = [];
    let raviTL = [];
    let otherTL = [];

    for (let i = 0; i < newEventStore.bookedJobs.length; i++) {
      if (!newEventStore.bookedJobs[i].teamLeader) {
        notassigned.push(newEventStore.bookedJobs[i]);
      } else if (newEventStore.bookedJobs[i].teamLeader === "Ravi") {
        raviTL.push(newEventStore.bookedJobs[i]);
      } else if (newEventStore.bookedJobs[i].teamLeader === "Army") {
        armyTL.push(newEventStore.bookedJobs[i]);
      } else {
        otherTL.push(newEventStore.bookedJobs[i]);
      }
    }

    let NAJobs = notassigned.map((job, index) => (
      <div
        key={index}
        onClick={() => {
          newEventStore.selectJobForTL(job, "add");
          this.setState({ modalShow: true });
        }}
      >
        <AssignJobCard job={job} key={index} />
      </div>
    ));
    let army = armyTL.map((job, index) => (
      <div
        key={index}
        onClick={() => {
          newEventStore.selectJobForTL(job, "add");
          this.setState({ modalShow: true });
        }}
      >
        <AssignJobCard job={job} key={index} />
      </div>
    ));
    let ravi = raviTL.map((job, index) => (
      <div
        key={index}
        onClick={() => {
          newEventStore.selectJobForTL(job, "add");
          this.setState({ modalShow: true });
        }}
      >
        <AssignJobCard job={job} key={index} />
      </div>
    ));
    let other = otherTL.map((job, index) => (
      <div
        key={index}
        onClick={() => {
          newEventStore.selectJobForTL(job, "add");
          this.setState({ modalShow: true });
        }}
      >
        <AssignJobCard job={job} key={index} />
      </div>
    ));

    return (
      <div>
        <AssignTLModal
          show={this.state.modalShow}
          onHide={modalClose}
          event={this.state.event}
          job={this.state.job}
        />
        <div>
          <Button block variant="dark" disabled>
            <h1>{notassigned.length} Not Assigned Jobs</h1>
          </Button>
          <Row style={{ justifyContent: "flex-start" }}>{NAJobs}</Row>
          <hr />
          <Row>
            <Col>
              <Button block variant="primary" disabled>
                <h1>{armyTL.length} Army's Jobs</h1>
              </Button>
              <Row>{army}</Row>
            </Col>
            <Col>
              <Button block variant="primary" disabled>
                <h1>{raviTL.length} Ravi's Jobs</h1>
              </Button>
              <Row>{ravi}</Row>
            </Col>
            <Col>
              <Button block variant="primary" disabled>
                <h1>{otherTL.length} Other's Jobs</h1>
              </Button>
              <Row>{other}</Row>
            </Col>
          </Row>
          <hr />
        </div>
      </div>
    );
  }
}

export default observer(AssignJobs);
