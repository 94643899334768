import { decorate, observable, computed } from "mobx";
import axios from "axios";
import moment from "moment";
import settingStore from "./SettingStore";
import userStore from "./UserStore";
import emailjs from "emailjs-com";

const instance = axios.create({
  // baseURL: "https://easymovecalendar.app/",
  // baseURL: "http://127.0.0.1:8000/",
  // baseURL: "http://157.230.113.108/",
  baseURL: "https://jazzafs.com/",
});

class HREventStore {
  constructor() {
    this.teammembers = [];
    this.selectedmember = {
      title: "",
      name: " ",
      salary: "",
      active: "Active",
      passportexpiry: moment(new Date()).format("YYYY-MM-DD"),
      civilidexpiry: moment(new Date()).format("YYYY-MM-DD"),
      driverlicenseexpiry: moment(new Date()).format("YYYY-MM-DD"),
      generalexpiry: moment(new Date()).format("YYYY-MM-DD"),
      carregistrationexpiry: moment(new Date()).format("YYYY-MM-DD"),
      carinsuranceexpiry: moment(new Date()).format("YYYY-MM-DD"),
      id: 0,
    };
    this.customemail = {
      fromname: "",
      fromemail: "",
      toname: "",
      toemail: "",
      subject: "",
      bodytext: "",
    };
    this.active = false;
    this.editMember = false;
    this.leadjobs = [];
    this.leadclient = [];
    this.convfeedback = [];
    this.cancfeedback = [];
    this.leads = [];
    this.proposals = [];
    this.leadclienttimeloaded = false;
    this.leadjobtimeloaded = false;
    this.feedbackloaded = false;
  }

  resetEmail() {
    let name =
      userStore.profile.first_name.charAt(0).toUpperCase() +
      userStore.profile.first_name.slice(1) +
      " " +
      userStore.profile.last_name.charAt(0).toUpperCase() +
      userStore.profile.last_name.slice(1);
    this.customemail = {
      fromname: name,
      fromemail: "",
      toname: "",
      toemail: "",
      subject: "",
      bodytext: "",
    };
  }

  sendCustomEmail() {
    var template_params = {
      toemail: this.customemail.toemail,
      fromname: this.customemail.fromname,
      fromemail: this.customemail.fromemail + "@easymovekw.com",
      subject: this.customemail.subject,
      toname: this.customemail.toname,
      bodytext: this.customemail.bodytext,
    };

    console.log(template_params);
    var service_id = "default_service";
    var template_id = "generic_email";
    emailjs.send(
      service_id,
      template_id,
      template_params,
      "user_IaUVUsVD6VMlmBLY50c9p"
    );
    this.resetEmail();
  }

  changeEmail(val, type) {
    if (type === "fromname") {
      this.customemail.fromname = val;
    } else if (type === "fromemail") {
      this.customemail.fromemail = val;
    } else if (type === "toname") {
      this.customemail.toname = val;
    } else if (type === "toemail") {
      this.customemail.toemail = val;
    } else if (type === "subject") {
      this.customemail.subject = val;
    } else if (type === "bodytext") {
      this.customemail.bodytext = val;
    }
  }

  get disabledEmailSend() {
    if (
      this.customemail.fromname === "" ||
      this.customemail.toname === "" ||
      this.customemail.toemail === "" ||
      this.customemail.subject === "" ||
      this.customemail.bodytext === ""
    ) {
      return true;
    }
    return false;
  }

  changeEdit() {
    this.editMember = !this.editMember;
  }

  changeActiveFilter() {
    this.active = !this.active;
  }

  chooseTeamMember(teamMember, type) {
    if (type === "New") {
      this.selectedmember = {
        title: "",
        name: "",
        salary: "",
        active: "Active",
        passportexpiry: null,
        civilidexpiry: null,
        driverlicenseexpiry: null,
        generalexpiry: null,
        carregistrationexpiry: null,
        carinsuranceexpiry: null,
        id: 0,
      };
    } else {
      this.selectedmember = teamMember;
    }
  }

  updateMemberDetails(val, type) {
    if (settingStore.edit === false) {
      alert("Please enable edit to make changes.");
    } else {
      if (type === "title") {
        this.selectedmember.title = val;
      } else if (type === "name") {
        this.selectedmember.name = val;
      } else if (type === "salary") {
        this.selectedmember.salary = val;
      } else if (type === "active") {
        this.selectedmember.active = val;
      } else if (type === "passportexpiry") {
        this.selectedmember.passportexpiry = moment(val).format();
      } else if (type === "civilidexpiry") {
        this.selectedmember.civilidexpiry = moment(val).format();
      } else if (type === "driverlicenseexpiry") {
        this.selectedmember.driverlicenseexpiry = moment(val).format();
      } else if (type === "generalexpiry") {
        this.selectedmember.generalexpiry = moment(val).format();
      } else if (type === "carregistrationexpiry") {
        this.selectedmember.carregistrationexpiry = moment(val).format();
      } else if (type === "carinsuranceexpiry") {
        this.selectedmember.carinsuranceexpiry = moment(val).format();
      }
    }
  }

  postTeamMember(type) {
    let data = this.selectedmember;

    if (data.passportexpiry !== null) {
      data.passportexpiry = moment(data.passportexpiry).format("YYYY-MM-DD");
    }
    if (data.generalexpiry !== null) {
      data.generalexpiry = moment(data.generalexpiry).format("YYYY-MM-DD");
    }
    if (data.civilidexpiry !== null) {
      data.civilidexpiry = moment(data.civilidexpiry).format("YYYY-MM-DD");
    }
    if (data.driverlicenseexpiry !== null) {
      data.driverlicenseexpiry = moment(data.driverlicenseexpiry).format(
        "YYYY-MM-DD"
      );
    }
    if (data.carregistrationexpiry !== null) {
      data.carregistrationexpiry = moment(data.carregistrationexpiry).format(
        "YYYY-MM-DD"
      );
    }
    if (data.carinsuranceexpiry !== null) {
      data.carinsuranceexpiry = moment(data.carinsuranceexpiry).format(
        "YYYY-MM-DD"
      );
    }
    if (this.selectedmember.id === 0) {
      return instance
        .post("/c-teammember/", data)
        .then((res) => res.data)
        .then((events) => {
          this.changeEdit();
          this.fetchTeamMembers();
          alert(`${data.name} Details Added.`);
        })
        .catch((err) => {
          alert(
            "An error occurred. The data did NOT save. Please contact me (97666886)."
          );
          console.error(err.response);
        });
    } else {
      return instance
        .put("/teammember/" + data.id + "/", data)
        .then((res) => res.data)
        .then((events) => {
          // console.log("successfully added");
          this.changeEdit();
          this.fetchTeamMembers();
          alert(`${data.name} Details Updated.`);
        })
        .catch((err) => {
          alert(
            "An error occurred. The data did NOT save. Please contact me (97666886)."
          );
          console.error(err.response);
        });
    }
  }

  fetchLeadJobTimes() {
    if (!this.leadjobtimeloaded) {
      return instance
        .get("/leadtimes/?format=json")
        .then((res) => res.data)
        .then((jobs) => {
          this.leadjobs = jobs;
          console.log("Lead Job Times Fetched");
          this.leadjobtimeloaded = true;
        })
        .catch((err) => {
          console.error(err.response);
          alert("Error");
        });
    }
  }

  fetchLeadClientTimes() {
    if (!this.leadclienttimeloaded) {
      return instance
        .get("/leadtimeclient/?format=json")
        .then((res) => res.data)
        .then((clients) => {
          this.leadclient = [];
          let todaydateval = moment(new Date());
          for (let i = 0; i < clients.length; i++) {
            for (let j = 0; j < clients[i].job.length; j++) {
              if (
                clients[i].job[j].status === "Lead" ||
                clients[i].job[j].status === "Proposal"
              ) {
                let data = {};
                data.title = clients[i].title;
                data.email = clients[i].email;
                data.leadtype = clients[i].leadtype;
                data.phone1 = clients[i].phone1;
                data.phone2 = clients[i].phone2;
                data.heardofus = clients[i].heardofus;
                data.jobid = clients[i].job[j].id;
                data.lastprice = clients[i].job[j].lastprice;
                data.jobmain = clients[i].job[j].jobmain;
                data.jobsecond = clients[i].job[j].jobsecond;
                data.cbm = clients[i].job[j].cbm;
                data.status = clients[i].job[j].status;
                data.emfocalpoint = clients[i].job[j].emfocalpoint;
                data.lastupdate = clients[i].job[j].lastupdate;

                if (clients[i].job[j].status === "Lead") {
                  data.laststatusdate = clients[i].job[j].leaddate;
                  data.daysdiff = todaydateval.diff(
                    clients[i].job[j].leaddate,
                    "days"
                  );
                } else if (clients[i].job[j].status === "Proposal") {
                  data.laststatusdate = clients[i].job[j].proposaldate;
                  data.daysdiff = todaydateval.diff(
                    clients[i].job[j].proposaldate,
                    "days"
                  );
                }
                data.lastupdatedays = todaydateval.diff(
                  clients[i].job[j].lastupdate,
                  "days"
                );
                if (
                  data.status === "Lead" &&
                  data.emfocalpoint === userStore.profile.first_name
                ) {
                  this.leads.push(data);
                }
                if (
                  data.status === "Proposal" &&
                  data.emfocalpoint === userStore.profile.first_name
                ) {
                  this.proposals.push(data);
                }

                this.leadclient.push(data);
              }
            }
          }
        })
        .then((clients) => {
          this.leadclienttimeloaded = true;
          console.log("leads collected");
        })
        .catch((err) => {
          console.error(err.response);
          alert("Error");
        });
    }
  }

  fetchFeedback() {
    if (!this.feedbackloaded) {
      return instance
        .get("/feedbackshort/?format=json")
        .then((res) => res.data)
        .then((clients) => {
          console.log("Feedback Fetched");
          for (let i = 0; i < clients.length; i++) {
            for (let j = 0; j < clients[i].job.length; j++) {
              let data = {};
              data.title = clients[i].title;
              data.jobid = clients[i].job[j].id;
              data.lastprice = clients[i].job[j].lastprice;
              data.jobmain = clients[i].job[j].jobmain;
              data.cancellationdate = clients[i].job[j].cancellationdate;
              data.converteddate = clients[i].job[j].converteddate;
              data.jobsecond = clients[i].job[j].jobsecond;
              data.cbm = clients[i].job[j].cbm;
              data.status = clients[i].job[j].status;
              data.emfocalpoint = clients[i].job[j].emfocalpoint;
              data.rating = clients[i].job[j].rating;
              data.cancellationreason = clients[i].job[j].cancellationreason;
              data.cancellationremarks = clients[i].job[j].cancellationremarks;
              data.feedback = clients[i].job[j].feedback;

              if (clients[i].job[j].status === "Converted") {
                this.convfeedback.push(data);
              } else if (clients[i].job[j].status === "Cancelled") {
                this.cancfeedback.push(data);
              }
            }
          }
        })
        .then(() => (this.feedbackloaded = true))
        .catch((err) => {
          console.error(err.response);
          alert("Error");
        });
    }
  }

  fetchTeamMembers() {
    settingStore.changeLoading(true);

    return instance
      .get("/teammembers/?format=json")
      .then((res) => res.data)
      .then((teammembers) => {
        this.teammembers = teammembers;
        this.selectedmember = teammembers[0];
        console.log("Team Members Fetched");
        settingStore.changeLoading(false);
      })
      .catch((err) => {
        console.error(err.response);
        alert("Error");
      });
  }

  get activeList() {
    let filtactive = this.teammembers;
    if (this.active) {
      filtactive = filtactive.filter(
        (teammember) => teammember.active === "Active"
      );
    }
    return filtactive;
  }

  get staffList() {
    return this.activeList.filter((teammember) => teammember.title === "Staff");
  }
  get carList() {
    return this.activeList.filter((teammember) => teammember.title === "Car");
  }
  get documentList() {
    return this.activeList.filter(
      (teammember) => teammember.title === "Document"
    );
  }
}

decorate(HREventStore, {
  active: observable,
  teammembers: observable,
  selectedmember: observable,
  editMember: observable,
  customemail: observable,
  leadjobs: observable,
  leadclient: observable,
  convfeedback: observable,
  cancfeedback: observable,
  leads: observable,
  proposals: observable,
  leadclienttimeloaded: observable,
  leadjobtimeloaded: observable,
  feedbackloaded: observable,
  activeList: computed,
  staffList: computed,
  carList: computed,
  disabledEmailSend: computed,
  documentList: computed,
});

const hrEventStore = new HREventStore();

export default hrEventStore;
