import React from "react";
import { observer } from "mobx-react";
// import { Button } from "react-bootstrap";
// import ReactDataSheet from "react-datasheet";
// import jobEditStore from "../Stores/JobEditStore";
// import uploadOldData from "../../BackupOldData/uploadolddata";
// import MaterialTable from "material-table";

// const { useState } = React;

class TestPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    // let columns = [
    //   { title: "Title", field: "title" },
    //   { title: "status", field: "status" },
    //   { title: "originaldate", field: "originaldate" },
    //   { title: "reviseddate", field: "reviseddate" },
    //   { title: "order", field: "order" },
    //   { title: "remark", field: "remark" }
    // ];

    return <div></div>;
  }
}

export default observer(TestPage);
