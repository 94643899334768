import React, { Component } from "react";
import "./App.css";
import { observer } from "mobx-react";
import { Switch, Route, Redirect, withRouter } from "react-router-dom";
// import ReactGA from "react-ga";
import { MDBRow, MDBCol } from "mdbreact";

import {
  CircleLoader,
  BounceLoader,
  DotLoader,
  FadeLoader,
  GridLoader,
  HashLoader,
  RotateLoader,
  PacmanLoader,
  SyncLoader,
  ScaleLoader,
} from "react-spinners";

import "../node_modules/react-vis/dist/style.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

import settingStore from "./MainPages/Stores/SettingStore";
import editJobStore from "./MainPages/Stores/EditJobStore";
import SignInPage from "./MainPages/Sign In/SignIn";
import userStore from "./MainPages/Stores/UserStore";
import CalendarPage from "./MainPages/Calendar/CalendarPage";
import EditClient from "./MainPages/ClientEdit/EditClient";
import NavBarPage from "./MainPages/Header and Footer/NavBarPage";
import FooterPage from "./MainPages/Header and Footer/FooterPage";
import ClientListPage from "./MainPages/List Pages/ClientListPage";
import JobListPage from "./MainPages/List Pages/JobListPage";
import WorkSchedule from "./MainPages/List Pages/WorkSchedule";
import ControlsPage from "./MainPages/Controls Page/ControlsPage";
import IMCompanies from "./MainPages/IMCompanies/IMCompanies";
import LocalSalesDashboard from "./MainPages/Dashboard/LocalSalesDashboard";
import TestPage from "./MainPages/TestPages/TestPage";
import AssignJobs from "./MainPages/AssignJobs/AssignJobs";
import HRDashboard from "./MainPages/HRDashboard/HRDashboard";
import TeamRank from "./MainPages/Statistics/TeamRank";
import StoragePage from "./MainPages/Storage/StoragePage";
import FinancialPage from "./MainPages/Financials/FinancialPage";
import BankBalances from "./MainPages/Financials/BankBalances";
import ClientsNotPaid from "./MainPages/Financials/ClientsNotPaid";
import AssignOfficeJobs from "./MainPages/AssignJobs/AssignOfficeJobs";
import ManagementDashboard from "./MainPages/ManagementDashboard/ManagementDashboard";
import PendingClients from "./MainPages/List Pages/PendingClients";
import IMTrackCalendar from "./MainPages/IMCompanies/IMTrackCalendar";
import InventoryDashboard from "./MainPages/TeamLeaderApp/InventoryDashboard";
import TLDashboard from "./MainPages/TeamLeaderApp/TLDashboard";
import InventoryHistory from "./MainPages/TeamLeaderApp/InventoryHistory";
import InventoryHistoryEM from "./MainPages/TeamLeaderApp/InventoryHistoryEM";
import QualityScorePage from "./MainPages/Dashboard/QualityScorePage";
import TestExcelUplaod from "./MainPages/TestPages/TestExcelUplaod";
import NewJobTabs from "./MainPages/JobEdit/NewJobTabs";
import ClientBalances from "./MainPages/Dashboard/ClientBalances";
import ConvertedClients from "./MainPages/Dashboard/ConvertedClients";

class App extends Component {
  getView() {
    let underconstruction = 0;
    if (underconstruction > 0) {
      return (
        <div>
          <h1>
            The System is currently updating, should be done within a few hours.
          </h1>
          <h1>
            Please let me know if you need any information and I will do my best
            to support.
          </h1>
        </div>
      );
    }

    let x = Math.floor(Math.random() * 10);

    let y = (
      <CircleLoader
        sizeUnit={"px"}
        size={500}
        color={"#0017FF"}
        loading={true}
      />
    );
    if (x === 1) {
      y = (
        <BounceLoader
          sizeUnit={"px"}
          size={500}
          color={"#0017FF"}
          loading={true}
        />
      );
    } else if (x === 2) {
      y = (
        <DotLoader
          sizeUnit={"px"}
          size={500}
          color={"#0017FF"}
          loading={true}
        />
      );
    } else if (x === 3) {
      y = (
        <FadeLoader
          sizeUnit={"px"}
          size={500}
          color={"#0017FF"}
          loading={true}
        />
      );
    } else if (x === 4) {
      y = (
        <GridLoader
          sizeUnit={"px"}
          size={500}
          color={"#0017FF"}
          loading={true}
        />
      );
    } else if (x === 5) {
      y = (
        <HashLoader
          sizeUnit={"px"}
          size={500}
          color={"#0017FF"}
          loading={true}
        />
      );
    } else if (x === 6) {
      y = (
        <RotateLoader
          sizeUnit={"px"}
          size={500}
          color={"#0017FF"}
          loading={true}
        />
      );
    } else if (x === 7) {
      y = (
        <PacmanLoader
          sizeUnit={"px"}
          size={500}
          color={"#0017FF"}
          loading={true}
        />
      );
    } else if (x === 8) {
      y = (
        <SyncLoader
          sizeUnit={"px"}
          size={500}
          color={"#0017FF"}
          loading={true}
        />
      );
    } else if (x === 9) {
      y = (
        <ScaleLoader
          sizeUnit={"px"}
          size={500}
          color={"#0017FF"}
          loading={true}
        />
      );
    }
    if (userStore.signedIn) {
      if (settingStore.loading) {
        return <div>{y}</div>;
      } else {
        if (userStore.access === 6) {
          return (
            <Switch>
              <Redirect exact from="/" to="/whdash" />
              <Route path="/whdash" component={InventoryDashboard} />
              <Route path="/inventory" component={InventoryHistoryEM} />
              <Redirect to="/whdash" />
            </Switch>
          );
        } else if (userStore.access > 4.5) {
          return (
            <Switch>
              <Redirect exact from="/" to="/tldash" />
              <Route path="/tldash" component={TLDashboard} />
              <Route path="/inventory" component={InventoryHistory} />
              <Redirect to="/tldash" />
            </Switch>
          );
        }
        if (userStore.access < 2.5) {
          return (
            <Switch>
              <Redirect exact from="/" to="/calendar" />
              <Route path="/calendar" component={CalendarPage} />
              <Route path="/clientlist" component={ClientListPage} />
              <Route path="/joblist" component={JobListPage} />
              <Route path="/workschedule" component={WorkSchedule} />
              <Route path="/createClient" component={EditClient} />
              <Route path="/intsup" component={IMCompanies} />
              <Route path="/dashboard" component={LocalSalesDashboard} />
              <Route path="/assign" component={AssignJobs} />
              <Route path="/assignclient" component={AssignOfficeJobs} />
              <Route path="/hrdashboard" component={HRDashboard} />
              <Route path="/teamrank" component={TeamRank} />
              <Route path="/storage" component={StoragePage} />
              <Route path="/financial" component={FinancialPage} />
              <Route path="/bankbalance" component={BankBalances} />
              <Route path="/outstanding" component={ClientsNotPaid} />
              <Route path="/management" component={ManagementDashboard} />
              <Route path="/pendingclients" component={PendingClients} />
              <Route path="/imtracking" component={IMTrackCalendar} />
              <Route path="/quality" component={QualityScorePage} />
              <Route path="/test" component={TestExcelUplaod} />
              <Route path="/test2" component={TestPage} />
              <Route path="/clientbalance" component={ClientBalances} />
              <Route path="/converted" component={ConvertedClients} />
              <Route
                path="/inventorydashboard"
                component={InventoryDashboard}
              />
              <Redirect to="/calendar" />
            </Switch>
          );
        } else if (userStore.access < 3.5) {
          return (
            <Switch>
              <Redirect exact from="/" to="/calendar" />
              <Route path="/calendar" component={CalendarPage} />
              <Route path="/clientlist" component={ClientListPage} />
              <Route path="/joblist" component={JobListPage} />
              <Route path="/workschedule" component={WorkSchedule} />
              <Route path="/createClient" component={EditClient} />
              <Route path="/intsup" component={IMCompanies} />
              <Route path="/dashboard" component={LocalSalesDashboard} />
              <Route path="/assign" component={AssignJobs} />
              <Route path="/assignclient" component={AssignOfficeJobs} />
              <Route path="/storage" component={StoragePage} />
              <Route path="/financial" component={FinancialPage} />
              <Route path="/pendingclients" component={PendingClients} />
              <Route path="/imtracking" component={IMTrackCalendar} />
              <Route path="/quality" component={QualityScorePage} />
              <Route path="/clientbalance" component={ClientBalances} />
              <Route path="/converted" component={ConvertedClients} />
              <Redirect to="/calendar" />
            </Switch>
          );
        } else {
          return (
            <Switch>
              <Redirect exact from="/" to="/calendar" />
              <Route path="/calendar" component={CalendarPage} />
              <Route path="/clientlist" component={ClientListPage} />
              <Route path="/joblist" component={JobListPage} />
              <Route path="/workschedule" component={WorkSchedule} />
              <Route path="/createClient" component={EditClient} />
              <Route path="/intsup" component={IMCompanies} />
              <Route path="/dashboard" component={LocalSalesDashboard} />
              <Route path="/assign" component={AssignJobs} />
              <Route path="/assignclient" component={AssignOfficeJobs} />
              <Route path="/storage" component={StoragePage} />
              <Route path="/pendingclients" component={PendingClients} />
              <Route path="/imtracking" component={IMTrackCalendar} />
              <Route path="/quality" component={QualityScorePage} />
              <Route path="/clientbalance" component={ClientBalances} />
              <Route path="/converted" component={ConvertedClients} />
              <Redirect to="/calendar" />
            </Switch>
          );
        }
      }
    } else {
      return <SignInPage />;
    }
  }

  componentDidMount() {
    // initializeReactGA();
  }
  render() {
    return (
      <div>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Roboto"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
        />

        <script src="path/to/chartjs/dist/Chart.js" />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap"
        />

        <link
          rel="stylesheet"
          href="https://use.fontawesome.com/releases/v5.7.2/css/all.css"
          integrity="sha384-fnmOCqbTlWIlj8LyTjo7mOUStjsKC4pOpQbqyi7RrhN7udi9RwhKkMHpvLbHG9Sr"
          crossOrigin="anonymous"
        />

        <link
          rel="stylesheet"
          href="https://maxcdn.bootstrapcdn.com/bootstrap/4.2.1/css/bootstrap.min.css"
          integrity="sha384-GJzZqFGwb1QTTN6wy59ffF1BuGJpLSa9DkKMp0DgiMDm4iYMj70gZWKYbI706tWS"
          crossOrigin="anonymous"
        />
        <link
          rel="stylesheet"
          href="https://npmcdn.com/react-bootstrap-table/dist/react-bootstrap-table-all.min.css"
        />
        <script src="https://npmcdn.com/react-bootstrap-table/dist/react-bootstrap-table.min.js" />
        <script
          src="https://cdnjs.cloudflare.com/ajax/libs/jspdf/1.5.3/jspdf.debug.js"
          integrity="sha384-NaWTHo/8YCBYJ59830LTz/P4aQZK1sS0SneOgAvhsIl3zBu8r9RevNg5lHCHAuQ/"
          crossOrigin="anonymous"
        />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
        ></link>
        <script
          type="text/javascript"
          src="https://cdn.jsdelivr.net/npm/emailjs-com@2.4.1/dist/email.min.js"
        ></script>
        <link
          rel="stylesheet"
          href="//cdn.jsdelivr.net/npm/semantic-ui@2.4.2/dist/semantic.min.css"
        />
        <link
          href="node_modules/webdatarocks/webdatarocks.min.css"
          rel="stylesheet"
        />
        <script src="node_modules/webdatarocks/webdatarocks.toolbar.min.js"></script>
        <script src="node_modules/webdatarocks/webdatarocks.js"></script>
        <script src="https://cdn.jsdelivr.net/npm/apexcharts"></script>
        <link href="toastr.css" rel="stylesheet" />
        <script src="toastr.js"></script>

        {userStore.signedIn && <NavBarPage />}
        <MDBRow>
          <MDBCol>
            <br />
            {editJobStore.editclient && (
              <div>
                <br />
                <NewJobTabs />
                <br />
                <hr />
                <br />
              </div>
            )}
            <ControlsPage />
            {this.getView()}
            <b />
          </MDBCol>
          <b />
        </MDBRow>
        <b />
        <b />
        {userStore.access < 2.5 && <FooterPage />}
      </div>
    );
  }
}

export default withRouter(observer(App));
