import React, { Component } from "react";
import { observer } from "mobx-react";

import eventStore from "../Stores/EventStore";
import jobEditStore from "../Stores/JobEditStore";

class JobSummary extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let addresssummary;

    addresssummary = jobEditStore.addresses.map((address, index) => (
      <li key={index}>
        <b>Address {index + 1}:</b> {address.typefrom} to {address.typeto}
        <ul>
          <li>
            <b>From:</b> {address.locationfrom}{" "}
          </li>
          <li>
            <b>To:</b> {address.locationto}
          </li>
        </ul>
      </li>
    ));

    return (
      <div>
        <h4>
          {" "}
          <b>Client:</b> {eventStore.editClient.title} - (
          {jobEditStore.job.jobmain} - {jobEditStore.job.jobsecond})
        </h4>
        <h6>
          <b>{jobEditStore.addresses.length > 1 ? "Addresses" : "Address"}: </b>
        </h6>
        <ol>{addresssummary}</ol>
      </div>
    );
  }
}

export default observer(JobSummary);
