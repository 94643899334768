import { decorate, observable } from "mobx";
import axios from "axios";
import settingStore from "./SettingStore";

const instance = axios.create({
  // baseURL: "https://easymovecalendar.app/"
  // baseURL: "http://127.0.0.1:8000/",
  // baseURL: "http://157.230.113.108/",
  baseURL: "https://jazzafs.com/",
});

class IntSupStore {
  constructor() {
    this.intsuppliers = [];
    this.countries = [];
    this.editIntSupplier = {
      company: "",
      companytype: "",
      organization: "",
      contact: "",
      email: "",
      phone: "",
      remark: "",
      rating: "",
      country: "",
      citystate: "",
      kuwaitoffice: "",
    };
    this.country = 1;
    this.imtracks = [];
    this.tracksperjob = [];
    this.loading = false;
  }

  changeLoadingIM(val) {
    this.loading = val;
  }

  selectIntSupplier(id) {
    this.editIntSupplier = {
      company: "",
      companytype: "",
      organization: "",
      contact: "",
      email: "",
      phone: "",
      remark: "",
      rating: "",
      country: "",
      citystate: "",
      kuwaitoffice: "",
      id: 0,
    };
    if (id !== 0) {
      this.editIntSupplier = this.intsuppliers[
        this.intsuppliers.findIndex((intsupplier) => intsupplier.id === id)
      ];
    }
    this.editIntSupplier.editSupplier = true;
  }

  updateIntSupplierDetails(val, type) {
    if (settingStore.edit === false) {
      alert("Please enable edit to make changes.");
    } else {
      if (type === "company") {
        this.editIntSupplier.company = val;
      } else if (type === "contact") {
        this.editIntSupplier.contact = val;
      } else if (type === "email") {
        this.editIntSupplier.email = val;
      } else if (type === "phone") {
        this.editIntSupplier.phone = val;
      } else if (type === "remark") {
        this.editIntSupplier.remark = val;
      } else if (type === "rating") {
        this.editIntSupplier.rating = val;
      } else if (type === "country") {
        this.editIntSupplier.country = val;
      } else if (type === "citystate") {
        this.editIntSupplier.citystate = val;
      } else if (type === "organization") {
        this.editIntSupplier.organization = val;
      } else if (type === "kuwaitoffice") {
        this.editIntSupplier.kuwaitoffice = val;
      } else if (type === "companytype") {
        this.editIntSupplier.companytype = val;
      } else if (type === "organization") {
        this.editIntSupplier.organization = val;
      }
    }
  }
  cancelChanges() {
    this.editIntSupplier = {
      company: "",
      companytype: "",
      organization: "",
      contact: "",
      email: "",
      phone: "",
      remark: "",
      rating: "",
      country: "",
      citystate: "",
      kuwaitoffice: "",
      editSupplier: false,
    };
  }

  addCountry(val) {
    this.editIntSupplier.country.push(val);
  }

  updateIntSupplier() {
    let data = {
      company: this.editIntSupplier.company,
      contact: this.editIntSupplier.contact,
      organization: this.editIntSupplier.organization,
      email: this.editIntSupplier.email,
      phone: this.editIntSupplier.phone,
      remark: this.editIntSupplier.remark,
      rating: this.editIntSupplier.rating,
      country: this.editIntSupplier.country,
      citystate: this.editIntSupplier.citystate,
      kuwaitoffice: this.editIntSupplier.kuwaitoffice,
      companytype: this.editIntSupplier.companytype,
    };
    if (this.editIntSupplier.id === 0) {
      return instance
        .post("/c-intsupplier/", data)
        .then((res) => res.data)
        .then((events) => {
          // console.log("successfully added");
          //   eventStore.fetchClients();
          alert(`New International Supplier (${data.company}) Created.`);
          this.cancelChanges();
        })
        .catch((err) => {
          let message = {
            activity: "Adding New Supplier",
            ClientName: "",
            ClientID: "",
            JobID: "",
            JobMain: "Supplier",
            Status: "Supplier",
            Data: data,
            error: err.response,
          };
          settingStore.sendUnifiedEmail(message, "error");
          alert(
            "An error occurred. The data did NOT save. Please contact me (97666886)." +
              "\n" +
              JSON.stringify(err.response)
          );
          console.error(err.response);
        });
    } else {
      return instance
        .put("/intsupplier/" + this.editIntSupplier.id + "/", data)
        .then((res) => res.data)
        .then((events) => {
          // console.log("successfully added");
          //   eventStore.fetchClients();
          alert(`${data.company} Details Updated.`);
          this.cancelChanges();
        })
        .catch((err) => {
          let message = {
            activity: "Updating Supplier",
            ClientName: "",
            ClientID: "",
            JobID: "",
            JobMain: "Supplier",
            Status: "Supplier",
            Data: data,
            error: err.response,
          };
          settingStore.sendUnifiedEmail(message, "error");
          alert(
            "An error occurred. The data did NOT save. Please contact me (97666886)." +
              "\n" +
              JSON.stringify(err.response)
          );
          console.error(err.response);
        });
    }
  }

  fetchIntSupplier() {
    return instance
      .get("/intsuppliers/?format=json")
      .then((res) => res.data)
      .then((intsuppliers) => {
        this.intsuppliers = intsuppliers;
        console.log("International Suppliers Received");
      });
  }

  fetchIMTracker(jobid) {
    instance
      .get("/imtrackerjob/" + jobid + "/?format=json")
      .then((res) => res.data)
      .then((imtracker) => {
        this.tracksperjob = imtracker;
        console.log("imtracker received");
        this.loading = false;
      });
  }

  fetchIMTracks() {
    settingStore.changeShortLoading(true);
    return instance
      .get("/n-imtrackerv2/?format=json")
      .then((res) => res.data)
      .then((imtracks) => {
        this.imtracks = imtracks;

        console.log("International Suppliers Received");
        for (let i = 0; i < this.imtracks.length; i++) {
          this.imtracks[i].desc =
            this.imtracks[i].title + " - " + this.imtracks[i].clienttitle;
        }
      })
      .then((track) => {
        settingStore.changeShortLoading(false);
      });
  }

  changeCountry(id) {
    this.country = id;
  }
}

decorate(IntSupStore, {
  intsuppliers: observable,
  countries: observable,
  imtracks: observable,
  tracksperjob: observable,
  editIntSupplier: observable,
  loading: observable,
  country: observable,
});

const intSupStore = new IntSupStore();
intSupStore.fetchIntSupplier();

export default intSupStore;
