import React from "react";
// First way to import
import { observer } from "mobx-react";
import { Button, Container } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import { Select } from "antd";
import { Input } from "antd";
import Grid from "@material-ui/core/Grid";
import Modal from "react-bootstrap/Modal";

import MaterialTable from "material-table";
import jobEditStore from "../Stores/JobEditStore";
import intSupStore from "../Stores/IntSupStore";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";

const { TextArea } = Input;
const { Option } = Select;

class IMQuotePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      intindex: 0,
    };
  }
  handleClose = () => this.setState({ show: false });
  handleShow = () => this.setState({ show: true });

  render() {
    let intquoterows;

    let intquotecompanies = intSupStore.intsuppliers.map((supplier, index) => (
      <Option key={index} value={supplier.id}>
        {supplier.company}
      </Option>
    ));

    let containeroptions;

    let volumetricweight = (
      <Grid item xs={12} sm={6} md={3}>
        <b>
          Volumetric Weight ={" "}
          {Math.round(
            (100 *
              jobEditStore.internationalfee.imwidth *
              100 *
              jobEditStore.internationalfee.imheight *
              100 *
              jobEditStore.internationalfee.imlength) /
              5000
          )}{" "}
          KG
        </b>
      </Grid>
    );

    if (jobEditStore.internationalfee.shippingmethod === "Sea") {
      containeroptions = (
        <Select
          showSearch
          placeholder="Company Type"
          optionFilterProp="children"
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
          value={jobEditStore.internationalfee.containerType}
          onChange={(e) => jobEditStore.updateIntFee(e, "containerType")}
        >
          <Option value={"LCL"}>LCL</Option>
          <Option value={"20 Foot"}>20 Foot</Option>
          <Option value={"40 Foot"}>40 Foot</Option>
          <Option value={"40 Foot-HC"}>40 Foot-HC</Option>
          <Option value={"45 Foot"}>45 Foot</Option>
          <Option value={"Open Top"}>Open Top</Option>
          <Option value={"Reefer"}>Reefer</Option>
          <Option value={"Flat Rack"}>Flat Rack</Option>
        </Select>
      );
    } else {
      containeroptions = (
        <Select
          showSearch
          placeholder="Company Type"
          optionFilterProp="children"
          value={jobEditStore.internationalfee.containerType}
          onChange={(e) => jobEditStore.updateIntFee(e, "containerType")}
          filterOption={(input, option) =>
            option.props.children.toLowerCase().indexOf(input.toLowerCase()) >=
            0
          }
        >
          <Option value={jobEditStore.internationalfee.shippingmethod}>
            {jobEditStore.internationalfee.shippingmethod}
          </Option>
        </Select>
      );
    }

    intquoterows = (
      <div>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={12} sm={6} md={4}>
            <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              size="small"
            >
              <b>Company Type</b>
              <Select
                showSearch
                placeholder="Company Type"
                optionFilterProp="children"
                value={jobEditStore.selectedIMQuote.companytype}
                onChange={(e) =>
                  jobEditStore.updateSelectedIMQuote(
                    e,
                    this.state.intindex,
                    "companytype"
                  )
                }
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value={"Freight Forwarder"}>Freight Forwarder</Option>
                <Option value={"Sea Transportation"}>Sea Transportation</Option>
                <Option value={"Air Transporation"}>Air Transporation</Option>
                <Option value={"Land Transportation"}>
                  Land Transportation
                </Option>
                <Option value={"Customs Clearance"}>Customs Clearance</Option>
                <Option value={"Other"}>Other</Option>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              size="small"
            >
              <b>Company</b>

              <Select
                showSearch
                placeholder="Company"
                optionFilterProp="children"
                value={jobEditStore.selectedIMQuote.company}
                onChange={(e) =>
                  jobEditStore.updateSelectedIMQuote(
                    e,
                    this.state.intindex,
                    "company"
                  )
                }
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {intquotecompanies}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <TextField
              size="small"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Price"
              name="price"
              autoComplete="price"
              type="number"
              value={jobEditStore.selectedIMQuote.price}
              onChange={(e) =>
                jobEditStore.updateSelectedIMQuote(
                  e.target.value,
                  this.state.intindex,
                  "price"
                )
              }
            />
          </Grid>
        </Grid>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={12} sm={4} md={4}>
            <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              size="small"
            >
              <b>Rating</b>
              <Select
                showSearch
                placeholder="Rating"
                optionFilterProp="children"
                value={jobEditStore.selectedIMQuote.rating}
                onChange={(e) =>
                  jobEditStore.updateSelectedIMQuote(
                    e,
                    this.state.intindex,
                    "rating"
                  )
                }
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value="0">Approved Company</Option>
                <Option value="1">High</Option>
                <Option value="2">Average</Option>
                <Option value="3">Low</Option>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={8} md={8}>
            <FormControl variant="outlined" style={{ width: "100%" }}>
              <TextArea
                size="small"
                placeholder="Quote Remarks"
                autoSize
                value={jobEditStore.selectedIMQuote.remark}
                onChange={(e) =>
                  jobEditStore.updateSelectedIMQuote(
                    e.target.value,
                    this.state.intindex,
                    "remark"
                  )
                }
              />
            </FormControl>
          </Grid>
        </Grid>
        <hr />
      </div>
    );

    let dangerousgoodstab = (
      <div>
        <hr />
        <form>
          <h4>
            Section 5: Dangerous Goods: (
            {Math.round(
              1000 *
                (parseFloat(jobEditStore.internationalfee.dgrcharge) +
                  parseFloat(jobEditStore.internationalfee.dghandling))
            ) / 1000}{" "}
            KD )
          </h4>
          <Grid container spacing={1} alignItems="center">
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                size="small"
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Dangerous Goods Charges"
                name="dgrcharge"
                autoComplete="dgrcharge"
                type="number"
                value={jobEditStore.internationalfee.dgrcharge}
                onChange={(e) =>
                  jobEditStore.updateIntFee(e.target.value, "dgrcharge")
                }
              />
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <TextField
                size="small"
                variant="outlined"
                margin="normal"
                required
                fullWidth
                label="Dangerous Handling Charges"
                name="dghandling"
                autoComplete="dghandling"
                type="number"
                value={jobEditStore.internationalfee.dghandling}
                onChange={(e) =>
                  jobEditStore.updateIntFee(e.target.value, "dghandling")
                }
              />
            </Grid>
          </Grid>
        </form>
      </div>
    );

    let intquotes = jobEditStore.intquote;
    let indexVal;
    for (let i = 0; i < intquotes.length; i++) {
      indexVal = -1;
      if (intquotes[i].company >= 0) {
        indexVal = intSupStore.intsuppliers.findIndex(
          (company) => company.id === intquotes[i].company
        );
      }
      if (indexVal >= 0) {
        intquotes[i].companyname = intSupStore.intsuppliers[indexVal].company;
      }
      if (intquotes[i].rating === "0") {
        intquotes[i].ratingshort = "Approved";
      } else if (intquotes[i].rating === "1") {
        intquotes[i].ratingshort = "High";
      } else if (intquotes[i].rating === "2") {
        intquotes[i].ratingshort = "Average";
      } else if (intquotes[i].rating === "3") {
        intquotes[i].ratingshort = "Low";
      }
    }

    let totalcharges = parseFloat(jobEditStore.job.lastprice);

    for (let i = 0; i < jobEditStore.addcharge.length; i++) {
      totalcharges += parseFloat(jobEditStore.addcharge[i].price);
    }

    for (let i = 0; i < jobEditStore.storages.length; i++) {
      totalcharges =
        parseFloat(totalcharges) +
        parseFloat(
          jobEditStore.storages[i].monthlycost *
            moment(jobEditStore.storages[i].storageenddate).diff(
              moment(jobEditStore.storages[i].storagestartdate),
              "months",
              true
            )
        );
    }

    let fixedcolumns = [
      { title: "id", field: "id" },
      { title: "Type", field: "companytype", removable: true },
      { title: "Company", field: "companyname" },
      { title: "Price", field: "price" },
      { title: "Rating", field: "ratingshort" },
      { title: "Remarks", field: "remark" },
    ];
    let intquoterows2 = (
      <MaterialTable
        title="Quotes from IM Suppliers"
        columns={fixedcolumns}
        data={intquotes}
        paging={false}
        editable={{
          isEditable: (rowData) => rowData.name === "a",
        }}
        icons={{
          Add: (props) => {
            return (
              <div>
                {" "}
                <i className="fa fa-plus"></i> Add new Region{" "}
              </div>
            );
          },
        }}
        actions={[
          {
            icon: "add",
            tooltip: "Add Quote",
            isFreeAction: true,
            onClick: (event) => {
              jobEditStore.addIntQuote();
              this.setState({
                show: true,
                intindex: jobEditStore.intquote.length - 1,
              });
            },
          },
          (rowData) => ({
            icon: "edit",
            tooltip: "Edit Quote",
            onClick: (event, rowData) => {
              jobEditStore.updateSelectedIMQuote(
                rowData.price,
                rowData.tableData.id,
                "reset"
              );
              jobEditStore.updateSelectedIMQuote(
                rowData.price,
                rowData.tableData.id,
                "price"
              );
              jobEditStore.updateSelectedIMQuote(
                rowData.rating,
                rowData.tableData.id,
                "rating"
              );
              jobEditStore.updateSelectedIMQuote(
                rowData.id,
                rowData.tableData.id,
                "id"
              );
              jobEditStore.updateSelectedIMQuote(
                rowData.remark,
                rowData.tableData.id,
                "remark"
              );
              jobEditStore.updateSelectedIMQuote(
                rowData.company,
                rowData.tableData.id,
                "company"
              );
              jobEditStore.updateSelectedIMQuote(
                rowData.companytype,
                rowData.tableData.id,
                "companytype"
              );
              this.setState({
                show: true,
                intindex: rowData.tableData.id,
              });
            },
          }),
          (rowData) => ({
            icon: "delete",
            tooltip: "Delete Item",
            onClick: (event, rowData) => {
              jobEditStore.deleteQuote(rowData, rowData.tableData.id);
            },
          }),
        ]}
        options={{
          actionsColumnIndex: 0,
          exportAllData: true,
          paging: false,
          toolbarButtonAlignment: "left",
        }}
      />
    );

    return (
      <div>
        <Modal
          show={this.state.show}
          onHide={this.handleClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>International Quote</Modal.Title>
          </Modal.Header>
          <Modal.Body>{intquoterows}</Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={() => {
                this.handleClose();
                jobEditStore.updateSelectedIMQuote(
                  0,
                  this.state.intindex,
                  "update"
                );
              }}
            >
              Submit
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                this.handleClose();
                jobEditStore.updateSelectedIMQuote(
                  0,
                  this.state.intindex,
                  "reset"
                );
              }}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <Container style={{ maxWidth: "98%" }}>
          <h6>Quality Score = {jobEditStore.imQuality}%</h6>

          <h4>
            Client Quoted at ({Math.round(10 * totalcharges) / 10} KD)
            <br />
            Easy Move Profit at (
            {Math.round(1000 * totalcharges) / 1000 -
              Math.round(
                1000 *
                  (parseFloat(jobEditStore.internationalfee.intwrapandpack) +
                    parseFloat(jobEditStore.internationalfee.cratecharge) +
                    parseFloat(jobEditStore.internationalfee.palletcharge) +
                    parseFloat(jobEditStore.internationalfee.loadingcharge) +
                    parseFloat(
                      jobEditStore.internationalfee.transportationcharges
                    ) +
                    parseFloat(
                      jobEditStore.internationalfee.originstorageinout
                    ) +
                    parseFloat(jobEditStore.internationalfee.originclearance) +
                    parseFloat(jobEditStore.internationalfee.shippingcharges) +
                    parseFloat(jobEditStore.internationalfee.transitclearance) +
                    parseFloat(jobEditStore.internationalfee.docharge) +
                    parseFloat(jobEditStore.internationalfee.thcharge) +
                    parseFloat(
                      jobEditStore.internationalfee.destinationclearance
                    ) +
                    parseFloat(jobEditStore.internationalfee.shuttlecharge) +
                    parseFloat(jobEditStore.internationalfee.demoragecharge) +
                    parseFloat(jobEditStore.internationalfee.detentioncharge) +
                    parseFloat(
                      jobEditStore.internationalfee.destinationstorageinout
                    ) +
                    parseFloat(jobEditStore.internationalfee.unloadingcharge) +
                    parseFloat(jobEditStore.internationalfee.assemblingcharge) +
                    parseFloat(jobEditStore.internationalfee.debrisremoval) +
                    parseFloat(jobEditStore.internationalfee.containerreturn) +
                    parseFloat(jobEditStore.internationalfee.dgrcharge) +
                    parseFloat(jobEditStore.internationalfee.dghandling))
              ) /
                1000}{" "}
            KD)
          </h4>
          <h4>
            Charges to be Paid by Easy Move (
            {Math.round(
              1000 *
                (parseFloat(jobEditStore.internationalfee.intwrapandpack) +
                  parseFloat(jobEditStore.internationalfee.cratecharge) +
                  parseFloat(jobEditStore.internationalfee.palletcharge) +
                  parseFloat(jobEditStore.internationalfee.loadingcharge) +
                  parseFloat(
                    jobEditStore.internationalfee.transportationcharges
                  ) +
                  parseFloat(jobEditStore.internationalfee.originstorageinout) +
                  parseFloat(jobEditStore.internationalfee.originclearance) +
                  parseFloat(jobEditStore.internationalfee.shippingcharges) +
                  parseFloat(jobEditStore.internationalfee.transitclearance) +
                  parseFloat(jobEditStore.internationalfee.docharge) +
                  parseFloat(jobEditStore.internationalfee.thcharge) +
                  parseFloat(
                    jobEditStore.internationalfee.destinationclearance
                  ) +
                  parseFloat(jobEditStore.internationalfee.shuttlecharge) +
                  parseFloat(jobEditStore.internationalfee.demoragecharge) +
                  parseFloat(jobEditStore.internationalfee.detentioncharge) +
                  parseFloat(
                    jobEditStore.internationalfee.destinationstorageinout
                  ) +
                  parseFloat(jobEditStore.internationalfee.unloadingcharge) +
                  parseFloat(jobEditStore.internationalfee.assemblingcharge) +
                  parseFloat(jobEditStore.internationalfee.debrisremoval) +
                  parseFloat(jobEditStore.internationalfee.containerreturn) +
                  parseFloat(jobEditStore.internationalfee.dgrcharge) +
                  parseFloat(jobEditStore.internationalfee.dghandling))
            ) / 1000}{" "}
            KD)
          </h4>
          <h6>
            {" "}
            Origin Charges = ({" "}
            {Math.round(
              1000 *
                (parseFloat(jobEditStore.internationalfee.intwrapandpack) +
                  parseFloat(jobEditStore.internationalfee.cratecharge) +
                  parseFloat(jobEditStore.internationalfee.palletcharge) +
                  parseFloat(jobEditStore.internationalfee.loadingcharge) +
                  parseFloat(
                    jobEditStore.internationalfee.transportationcharges
                  ) +
                  parseFloat(jobEditStore.internationalfee.originstorageinout) +
                  parseFloat(jobEditStore.internationalfee.originclearance))
            ) / 1000}{" "}
            KD)
          </h6>
          <h6>
            {" "}
            Shipping Charges = (
            {Math.round(
              1000 *
                (parseFloat(jobEditStore.internationalfee.shippingcharges) +
                  parseFloat(jobEditStore.internationalfee.transitclearance))
            ) / 1000}{" "}
            KD)
          </h6>
          <h6>
            {" "}
            Destination Charges = (
            {Math.round(
              1000 *
                (parseFloat(jobEditStore.internationalfee.docharge) +
                  parseFloat(jobEditStore.internationalfee.thcharge) +
                  parseFloat(
                    jobEditStore.internationalfee.destinationclearance
                  ) +
                  parseFloat(jobEditStore.internationalfee.shuttlecharge) +
                  parseFloat(jobEditStore.internationalfee.demoragecharge) +
                  parseFloat(jobEditStore.internationalfee.detentioncharge) +
                  parseFloat(
                    jobEditStore.internationalfee.destinationstorageinout
                  ) +
                  parseFloat(jobEditStore.internationalfee.unloadingcharge) +
                  parseFloat(jobEditStore.internationalfee.assemblingcharge) +
                  parseFloat(jobEditStore.internationalfee.debrisremoval) +
                  parseFloat(jobEditStore.internationalfee.containerreturn) +
                  parseFloat(jobEditStore.internationalfee.dgrcharge) +
                  parseFloat(jobEditStore.internationalfee.dghandling))
            ) / 1000}{" "}
            KD)
          </h6>
          <hr />
          <h4>Section 1: General Job Details</h4>
          <form>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12} sm={6} md={3}>
                <FormControl
                  variant="outlined"
                  style={{ width: "100%" }}
                  size="small"
                >
                  <b>Commodity</b>
                  <Select
                    showSearch
                    autoFocus
                    placeholder="Company Type"
                    optionFilterProp="children"
                    value={jobEditStore.internationalfee.commodity}
                    onChange={(e) => jobEditStore.updateIntFee(e, "commodity")}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option value={"Personal Effect"}>Personal Effect</Option>
                    <Option value={"General Cargo"}>General Cargo</Option>
                    <Option value={"Break Bulk"}>Break Bulk</Option>
                    <Option value={"Dangerous Goods"}>Dangerous Goods</Option>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl
                  variant="outlined"
                  style={{ width: "100%" }}
                  size="small"
                >
                  <b>Shipping Method</b>
                  <Select
                    showSearch
                    placeholder="Company Type"
                    optionFilterProp="children"
                    value={jobEditStore.internationalfee.shippingmethod}
                    onChange={(e) =>
                      jobEditStore.updateIntFee(e, "shippingmethod")
                    }
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    <Option value={"Air"}>Air</Option>
                    <Option value={"Land"}>Land</Option>
                    <Option value={"Sea"}>Sea</Option>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <FormControl
                  variant="outlined"
                  style={{ width: "100%" }}
                  size="small"
                >
                  <b>Container Type</b>
                  {containeroptions}
                </FormControl>
              </Grid>
              {jobEditStore.internationalfee.shippingmethod === "Air" &&
                volumetricweight}
            </Grid>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Length (M)"
                  name="imlength"
                  autoComplete="imlength"
                  type="number"
                  value={jobEditStore.internationalfee.imlength}
                  onChange={(e) =>
                    jobEditStore.updateIntFee(e.target.value, "imlength")
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Width (M)"
                  name="imwidth"
                  autoComplete="imwidth"
                  type="number"
                  value={jobEditStore.internationalfee.imwidth}
                  onChange={(e) =>
                    jobEditStore.updateIntFee(e.target.value, "imwidth")
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Height (M)"
                  name="imheight"
                  autoComplete="imheight"
                  type="number"
                  value={jobEditStore.internationalfee.imheight}
                  onChange={(e) =>
                    jobEditStore.updateIntFee(e.target.value, "imheight")
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Weight (kg)"
                  name="weight"
                  autoComplete="weight"
                  type="number"
                  value={jobEditStore.job.weight}
                  onChange={(e) =>
                    jobEditStore.updateJobDetails(e.target.value, "weight")
                  }
                />
              </Grid>
            </Grid>
          </form>
          <hr />
          <form>
            <h4>
              Section 2: Origin Charges: (
              {Math.round(
                1000 *
                  (parseFloat(jobEditStore.internationalfee.intwrapandpack) +
                    parseFloat(jobEditStore.internationalfee.cratecharge) +
                    parseFloat(jobEditStore.internationalfee.palletcharge) +
                    parseFloat(jobEditStore.internationalfee.loadingcharge) +
                    parseFloat(
                      jobEditStore.internationalfee.transportationcharges
                    ) +
                    parseFloat(
                      jobEditStore.internationalfee.originstorageinout
                    ) +
                    parseFloat(jobEditStore.internationalfee.originclearance))
              ) / 1000}{" "}
              KD )
            </h4>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Wrapping and Packing Charges"
                  name="intwrapandpack"
                  autoComplete="intwrapandpack"
                  type="number"
                  value={jobEditStore.internationalfee.intwrapandpack}
                  onChange={(e) =>
                    jobEditStore.updateIntFee(e.target.value, "intwrapandpack")
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Crating Charges"
                  name="cratecharge"
                  autoComplete="cratecharge"
                  type="number"
                  value={jobEditStore.internationalfee.cratecharge}
                  onChange={(e) =>
                    jobEditStore.updateIntFee(e.target.value, "cratecharge")
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Palletization Charges"
                  name="palletcharge"
                  autoComplete="palletcharge"
                  type="number"
                  value={jobEditStore.internationalfee.palletcharge}
                  onChange={(e) =>
                    jobEditStore.updateIntFee(e.target.value, "palletcharge")
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Loading Charge"
                  name="loadingcharge"
                  autoComplete="loadingcharge"
                  type="number"
                  value={jobEditStore.internationalfee.loadingcharge}
                  onChange={(e) =>
                    jobEditStore.updateIntFee(e.target.value, "loadingcharge")
                  }
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12} sm={4} md={4}>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Transporation Charges"
                  name="transportationcharges"
                  autoComplete="transportationcharges"
                  type="number"
                  value={jobEditStore.internationalfee.transportationcharges}
                  onChange={(e) =>
                    jobEditStore.updateIntFee(
                      e.target.value,
                      "transportationcharges"
                    )
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Origin Storage In/Out Charges"
                  name="originstorageinout"
                  autoComplete="originstorageinout"
                  type="number"
                  value={jobEditStore.internationalfee.originstorageinout}
                  onChange={(e) =>
                    jobEditStore.updateIntFee(
                      e.target.value,
                      "originstorageinout"
                    )
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Origin Clearance"
                  name="originclearance"
                  autoComplete="originclearance"
                  type="number"
                  value={jobEditStore.internationalfee.originclearance}
                  onChange={(e) =>
                    jobEditStore.updateIntFee(e.target.value, "originclearance")
                  }
                />
              </Grid>
            </Grid>
          </form>
          <hr />
          <form>
            <h4>
              Section 3: Shipping Charges: (
              {Math.round(
                1000 *
                  (parseFloat(jobEditStore.internationalfee.shippingcharges) +
                    parseFloat(jobEditStore.internationalfee.transitclearance))
              ) / 1000}{" "}
              KD )
            </h4>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Shipping Line Charges"
                  name="shippingcharges"
                  autoComplete="shippingcharges"
                  type="number"
                  value={jobEditStore.internationalfee.shippingcharges}
                  onChange={(e) =>
                    jobEditStore.updateIntFee(e.target.value, "shippingcharges")
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Transit Clearance Charges"
                  name="transitclearance"
                  autoComplete="transitclearance"
                  type="number"
                  value={jobEditStore.internationalfee.transitclearance}
                  onChange={(e) =>
                    jobEditStore.updateIntFee(
                      e.target.value,
                      "transitclearance"
                    )
                  }
                />
              </Grid>
            </Grid>
          </form>
          <hr />
          <form>
            <h4>
              Section 4: Destination Charges: (
              {Math.round(
                1000 *
                  (parseFloat(jobEditStore.internationalfee.docharge) +
                    parseFloat(jobEditStore.internationalfee.thcharge) +
                    parseFloat(
                      jobEditStore.internationalfee.destinationclearance
                    ) +
                    parseFloat(jobEditStore.internationalfee.shuttlecharge) +
                    parseFloat(jobEditStore.internationalfee.demoragecharge) +
                    parseFloat(jobEditStore.internationalfee.detentioncharge) +
                    parseFloat(
                      jobEditStore.internationalfee.destinationstorageinout
                    ) +
                    parseFloat(jobEditStore.internationalfee.unloadingcharge) +
                    parseFloat(jobEditStore.internationalfee.assemblingcharge) +
                    parseFloat(jobEditStore.internationalfee.debrisremoval) +
                    parseFloat(jobEditStore.internationalfee.containerreturn))
              ) / 1000}{" "}
              KD )
            </h4>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Delivery Order Charge"
                  name="docharge"
                  autoComplete="docharge"
                  type="number"
                  value={jobEditStore.internationalfee.docharge}
                  onChange={(e) =>
                    jobEditStore.updateIntFee(e.target.value, "docharge")
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Terminal Handling Charges"
                  name="thcharge"
                  autoComplete="thcharge"
                  type="number"
                  value={jobEditStore.internationalfee.thcharge}
                  onChange={(e) =>
                    jobEditStore.updateIntFee(e.target.value, "thcharge")
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Destination Clearance Charges"
                  name="destinationclearance"
                  autoComplete="destinationclearance"
                  type="number"
                  value={jobEditStore.internationalfee.destinationclearance}
                  onChange={(e) =>
                    jobEditStore.updateIntFee(
                      e.target.value,
                      "destinationclearance"
                    )
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Shuttle Charges"
                  name="shuttlecharge"
                  autoComplete="shuttlecharge"
                  type="number"
                  value={jobEditStore.internationalfee.shuttlecharge}
                  onChange={(e) =>
                    jobEditStore.updateIntFee(e.target.value, "shuttlecharge")
                  }
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Demurrage Charges"
                  name="demoragecharge"
                  autoComplete="demoragecharge"
                  type="number"
                  value={jobEditStore.internationalfee.demoragecharge}
                  onChange={(e) =>
                    jobEditStore.updateIntFee(e.target.value, "demoragecharge")
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Detention Charges"
                  name="detentioncharge"
                  autoComplete="detentioncharge"
                  type="number"
                  value={jobEditStore.internationalfee.detentioncharge}
                  onChange={(e) =>
                    jobEditStore.updateIntFee(e.target.value, "detentioncharge")
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Destination Storage In/Out Charges"
                  name="destinationstorageinout"
                  autoComplete="destinationstorageinout"
                  type="number"
                  value={jobEditStore.internationalfee.destinationstorageinout}
                  onChange={(e) =>
                    jobEditStore.updateIntFee(
                      e.target.value,
                      "destinationstorageinout"
                    )
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} md={3}>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Unloading Charge"
                  name="unloadingcharge"
                  autoComplete="unloadingcharge"
                  type="number"
                  value={jobEditStore.internationalfee.unloadingcharge}
                  onChange={(e) =>
                    jobEditStore.updateIntFee(e.target.value, "unloadingcharge")
                  }
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={12} sm={4} md={4}>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Assembling Charges"
                  name="assemblingcharge"
                  autoComplete="assemblingcharge"
                  type="number"
                  value={jobEditStore.internationalfee.assemblingcharge}
                  onChange={(e) =>
                    jobEditStore.updateIntFee(
                      e.target.value,
                      "assemblingcharge"
                    )
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Debris Removal Charges"
                  name="debrisremoval"
                  autoComplete="debrisremoval"
                  type="number"
                  value={jobEditStore.internationalfee.debrisremoval}
                  onChange={(e) =>
                    jobEditStore.updateIntFee(e.target.value, "debrisremoval")
                  }
                />
              </Grid>
              <Grid item xs={12} sm={4} md={4}>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Container Return Charges"
                  name="containerreturn"
                  autoComplete="containerreturn"
                  type="number"
                  value={jobEditStore.internationalfee.containerreturn}
                  onChange={(e) =>
                    jobEditStore.updateIntFee(e.target.value, "containerreturn")
                  }
                />
              </Grid>
            </Grid>
          </form>
          {jobEditStore.internationalfee.commodity === "Dangerous Goods" &&
            dangerousgoodstab}
          <hr />
          {intquoterows2}
          <hr />
        </Container>
      </div>
    );
  }
}

export default observer(IMQuotePage);
