import React from "react";
// First way to import
import { observer } from "mobx-react";
import { Button, Container, Row, Col } from "react-bootstrap";
import FormControl from "@material-ui/core/FormControl";
import { Select, Input } from "antd";

import editJobStore from "../../Stores/EditJobStore";

const { Option } = Select;
const { TextArea } = Input;

class AddressPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let addresses = editJobStore.addresses.map((address, index) => (
      <div key={index}>
        <Row>
          <Col md={1}>
            <h3>{index + 1})</h3>
          </Col>
          <Col md={1}>
            <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              size="small"
            >
              <b
                style={{
                  color: address.order === 0 ? "red" : "black",
                }}
              >
                Order:
              </b>
              <Select
                size="small"
                showSearch
                error
                width="100%"
                optionFilterProp="children"
                onChange={(e) => editJobStore.updateAddress("order", e, index)}
                value={address.order}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value={0}>0</Option>
                <Option value={1}>1</Option>
                <Option value={2}>2</Option>
                <Option value={3}>3</Option>
                <Option value={4}>4</Option>
                <Option value={5}>5</Option>
                <Option value={6}>6</Option>
                <Option value={7}>7</Option>
                <Option value={8}>8</Option>
                <Option value={9}>9</Option>
              </Select>
            </FormControl>
          </Col>
          <Col md={4}>
            <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              size="small"
            >
              <b
                style={{
                  color: address.typefrom === "" ? "red" : "black",
                }}
              >
                From Type:
              </b>
              <Select
                showSearch
                size="small"
                error
                width="100%"
                optionFilterProp="children"
                onChange={(e) =>
                  editJobStore.updateAddress("typefrom", e, index)
                }
                value={address.typefrom}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value={""}></Option>
                <Option value={"Apartment"}>Apartment</Option>
                <Option value={"Facility"}>Facility</Option>
                <Option value={"House"}>House</Option>
                <Option value={"Office"}>Office</Option>
                <Option value={"Port"}>Port</Option>
                <Option value={"Storage"}>Storage</Option>
                <Option value={"Other"}>Other</Option>
              </Select>
            </FormControl>
          </Col>
          <Col md={4}>
            <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              size="small"
            >
              <b
                style={{
                  color: address.typeto === "" ? "red" : "black",
                }}
              >
                To Type:
              </b>
              <Select
                showSearch
                size="small"
                error
                width="100%"
                optionFilterProp="children"
                onChange={(e) => editJobStore.updateAddress("typeto", e, index)}
                value={address.typeto}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value={""}></Option>
                <Option value={"Apartment"}>Apartment</Option>
                <Option value={"Facility"}>Facility</Option>
                <Option value={"House"}>House</Option>
                <Option value={"Office"}>Office</Option>
                <Option value={"Port"}>Port</Option>
                <Option value={"Storage"}>Storage</Option>
                <Option value={"Other"}>Other</Option>
              </Select>
            </FormControl>
          </Col>
          <Col>
            <Button
              variant="outline-danger"
              block
              size="sm"
              onClick={() => editJobStore.updateAddress("delete", 0, index)}
              md={2}
            >
              x
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <b
              style={{
                color: address.locationfrom === "" ? "red" : "black",
              }}
            >
              From Address:
            </b>
            <TextArea
              autoSize={true}
              value={address.locationfrom}
              onChange={(e) =>
                editJobStore.updateAddress(
                  "locationfrom",
                  e.target.value,
                  index
                )
              }
            />
          </Col>
          <Col>
            <b
              style={{
                color: address.locationto === "" ? "red" : "black",
              }}
            >
              To Address:
            </b>
            <TextArea
              autoSize={true}
              value={address.locationto}
              onChange={(e) =>
                editJobStore.updateAddress("locationto", e.target.value, index)
              }
            />
          </Col>
        </Row>
        <hr />
      </div>
    ));

    return (
      <div>
        <Container style={{ maxWidth: "98%" }}>
          <Row>
            <Col>
              <Button
                variant="outline-success"
                onClick={() => editJobStore.updateAddress("add", 0, 0)}
              >
                Add an Address
              </Button>
            </Col>
            <Col>
              <h3>{editJobStore.addresses.length} Address Details</h3>
            </Col>
          </Row>
          <hr />
          {addresses}
        </Container>
      </div>
    );
  }
}

export default observer(AddressPage);
