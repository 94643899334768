import React from "react";
// First way to import
import moment from "moment";
import { observer } from "mobx-react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Container } from "react-bootstrap";

import editJobStore from "../../Stores/EditJobStore";

moment.locale("ko", {
  week: {
    dow: 6,
    doy: 6,
  },
});

class JobSummaryPage extends React.Component {
  render() {
    let sortedaddress = editJobStore.addresses;
    let sorteddates = editJobStore.jobdates;
    let sortedstorages = editJobStore.storages;

    let addresses;
    let jobdatesList;
    let storageList;

    if (sortedaddress.length > 0) {
      sortedaddress = sortedaddress.slice().sort(function (first, second) {
        return -second.order - -first.order;
      });
      addresses = sortedaddress.map((address, index) => (
        <div key={index}>
          {" "}
          {index + 1}) From: {address.locationfrom}
          <br />- To: {address.locationto}
        </div>
      ));
    }
    if (sorteddates.length > 0) {
      sorteddates = sorteddates.slice().sort(function (first, second) {
        return -moment(second.jobdate) - -moment(first.jobdate);
      });
      jobdatesList = sorteddates.map((date, index) => (
        <div key={index}>
          {" "}
          {index + 1}) {moment(date.jobdate).format("DD-MMM-YYYY")}
        </div>
      ));
    }
    if (sortedstorages.length > 0) {
      sortedstorages = sortedstorages.slice().sort(function (first, second) {
        return (
          -moment(second.storagestartdate) - -moment(first.storagestartdate)
        );
      });
      storageList = sortedstorages.map((storage, index) => (
        <div key={index}>
          {" "}
          {index + 1}) {Math.round(1000 * storage.monthlycost) / 1000} KD for{" "}
          {Math.round(10 * storage.storagecbm) / 10} CBM from{" "}
          {moment(storage.storagestartdate).format("DD-MMM-YYYY")} to{" "}
          {moment(storage.storageenddate).format("DD-MMM-YYYY")}
        </div>
      ));
    }

    return (
      <div>
        <h2>
          {editJobStore.client.title} - {editJobStore.job.status} -{" "}
          {editJobStore.job.emfocalpoint === ""
            ? "Not Assigned"
            : `With ${editJobStore.job.emfocalpoint}`}
        </h2>
        <Container>
          <h4>
            {editJobStore.job.jobmain} - {editJobStore.job.jobsecond}
          </h4>
          <h5>
            {Math.round(1000 * editJobStore.job.lastprice) / 1000} KD for{" "}
            {Math.round(10 * editJobStore.job.cbm) / 10} CBM
          </h5>
          <p>
            Phone: {editJobStore.client.phone1} {editJobStore.client.phone2} /
            Email: {editJobStore.client.email}
          </p>
          {sortedaddress.length > 0 && (
            <div>
              <p />
              <b>{editJobStore.addresses.length} Address:</b> {addresses}
              <p />
            </div>
          )}
          <p />
          <b>Job Dates:</b> {jobdatesList}
          <p />
          {sortedstorages.length > 0 && (
            <div>
              <b>Storage Jobs:</b> {storageList}
            </div>
          )}
          <p>
            <b>Remarks:</b> {editJobStore.job.remarks}
          </p>
          <p>
            Last Updated ={" "}
            {moment(editJobStore.job.lastupdate).format("DD-MMM-YY")} by{" "}
            {editJobStore.job.userupdated}
          </p>
          <br />
          <p>
            <b>Client Added On </b>
            {moment(editJobStore.job.leaddate).format("DD-MMM-YY")}
          </p>
        </Container>
      </div>
    );
  }
}

export default observer(JobSummaryPage);
