import React, { Component } from "react";
import { observer } from "mobx-react";

import {
  SummaryState,
  GroupingState,
  IntegratedGrouping,
  IntegratedSummary,
  DataTypeProvider
} from "@devexpress/dx-react-grid";
import {
  Grid,
  TableHeaderRow,
  TableGroupRow,
  TableSummaryRow,
  DragDropProvider,
  Toolbar,
  GroupingPanel,
  VirtualTable
} from "@devexpress/dx-react-grid-material-ui";

import financialStore from "../Stores/FinancialStore";

const CurrencyFormatter = ({ value }) =>
  value.toLocaleString("en-US", { style: "currency", currency: "KWD" });

const CurrencyTypeProvider = props => (
  <DataTypeProvider formatterComponent={CurrencyFormatter} {...props} />
);

class DynamicClientFinances extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editPayment: false,
      financialsLoaded: false,
      modalShow: false,
      indexVal: 0,
      filter: false
    };
  }

  render() {
    let financialdata = financialStore.allclientfinances;

    let columns = [
      { title: "Job ID", name: "jobid" },
      { title: "Client", name: "clientname" },
      { title: "Status", name: "status" },
      { title: "Main", name: "jobmain" },
      { title: "Focal Point", name: "emfocalpoint" },
      { title: "Second", name: "jobsecond" },
      { title: "Move Price", name: "lastprice" },
      { title: "Additional", name: "addchargeprice" },
      { title: "Storage", name: "storageprice" },
      { title: "Payments", name: "customerpayment" },
      { title: "Balance", name: "clientbalance" },
      { title: "Last Job", name: "lastjob" }
    ];

    const currencyColumns = ["newinvoice"];

    const totalSummaryItems = [
      { columnName: "jobid", type: "count" },
      { columnName: "lastprice", type: "sum" },
      { columnName: "addchargeprice", type: "sum" },
      { columnName: "storageprice", type: "sum" },
      { columnName: "customerpayment", type: "sum" },
      { columnName: "clientbalance", type: "sum" }
    ];

    const groupSummaryItems = [
      { columnName: "jobmain", type: "count" },
      {
        columnName: "clientname",
        type: "count"
      },
      { columnName: "lastprice", type: "sum", alignByColumn: true },
      { columnName: "addchargeprice", type: "sum", alignByColumn: true },
      { columnName: "storageprice", type: "sum", alignByColumn: true },
      { columnName: "customerpayment", type: "sum", alignByColumn: true },
      { columnName: "clientbalance", type: "sum", alignByColumn: true }
    ];

    return (
      <div>
        <h2>
          <b>Profit and Loss Statement </b>
        </h2>

        <Grid rows={financialdata} columns={columns}>
          <CurrencyTypeProvider for={currencyColumns} />
          <DragDropProvider />
          <GroupingState
            defaultGrouping={[
              { columnName: "jobmain" },
              { columnName: "clientname" }
            ]}
          />
          <SummaryState
            totalItems={totalSummaryItems}
            groupItems={groupSummaryItems}
          />
          <IntegratedGrouping />
          <IntegratedSummary />

          <VirtualTable />
          <TableHeaderRow />
          <TableGroupRow showColumnsWhenGrouped />
          <Toolbar />
          <GroupingPanel />
          <TableSummaryRow />
        </Grid>
      </div>
    );
  }
}

export default observer(DynamicClientFinances);
