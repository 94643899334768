import React from "react";

import { observer } from "mobx-react";
import { Tabs, Steps } from "antd";
import { Button, Container } from "react-bootstrap";
import Grid from "@material-ui/core/Grid";
import moment from "moment";

import JobDetails from "./NewJobTabPages/JobDetails";
import JobTabHeader from "./NewJobTabPages/JobTabHeader";
import userStore from "../Stores/UserStore";
import editJobStore from "../Stores/EditJobStore";
import ClientDetails from "./NewJobTabPages/ClientDetails";
import AddressPage from "./NewJobTabPages/AddressPage";
import JobDatesPage from "./NewJobTabPages/JobDatesPage";
import StoragePage from "./NewJobTabPages/StoragePage";
import IMPage from "./NewJobTabPages/IMPage";
import CustomProposal from "../ProposalPics/CustomProposal";
import ProposalExport from "../ProposalPics/ProposalExport";
import RoomsPage from "./NewJobTabPages/RoomsPage";
import PaymentPage from "./NewJobTabPages/PaymentPage";
import TrackingPage from "./NewJobTabPages/TrackingPage";
import JobSummaryPage from "./NewJobTabPages/JobSummaryPage";
import InvoicePage from "../ProposalPics/InvoicePage";
const { TabPane } = Tabs;
const { Step } = Steps;

class JobTab extends React.Component {
  state = {
    tabPosition: "top",
  };

  changeTabPosition = (tabPosition) => {
    this.setState({ tabPosition });
  };

  render() {
    let currentStatus = 0;
    if (editJobStore.job.status === "Proposal") {
      currentStatus = 1;
    } else if (editJobStore.job.status === "Booked") {
      currentStatus = 2;
    } else if (editJobStore.job.status === "Converted") {
      currentStatus = 3;
    } else if (editJobStore.job.status === "Cancelled") {
      currentStatus = 1.5;
    }

    return (
      <div>
        <Grid container justify="space-between">
          <Button variant="warning" onClick={() => editJobStore.closeJobTabs()}>
            Cancel Changes
          </Button>
          <Button
            variant={
              !editJobStore.saveDataCheck ? "outline-success" : "success"
            }
            disabled={!editJobStore.saveDataCheck}
            onClick={() => editJobStore.postJob()}
          >
            Save Data
          </Button>
        </Grid>
        <hr />
        <JobTabHeader />
        <Tabs tabPosition={userStore.profile.editstyle}>
          <TabPane tab="JobSummary" key="1">
            <JobSummaryPage />
          </TabPane>
          <TabPane tab="Client Details" key="2">
            <ClientDetails />
          </TabPane>
          <TabPane tab="Job Details" key="3">
            <JobDetails />
          </TabPane>
          <TabPane tab="Address" key="4">
            <AddressPage />
          </TabPane>
          <TabPane tab="Dates" key="5">
            <JobDatesPage />
          </TabPane>
          <TabPane tab="Storage" key="6">
            <StoragePage />
          </TabPane>
          <TabPane tab="IM" key="7">
            <IMPage />
          </TabPane>
          <TabPane tab="Proposal" key="8">
            <ProposalExport />
          </TabPane>
          <TabPane tab="Payments" key="9">
            <PaymentPage />
          </TabPane>
          <TabPane tab="Packing List" key="10">
            <RoomsPage />
          </TabPane>
          <TabPane tab="Tracking" key="11">
            <TrackingPage />
          </TabPane>
          <TabPane tab="Custom Proposal" key="12">
            <CustomProposal />
          </TabPane>
          {userStore.access < 3.5 && (
            <TabPane tab="Invoice" key="13">
              <InvoicePage />
            </TabPane>
          )}
        </Tabs>
        <br />
        <Container>
          <Steps size="small" current={currentStatus}>
            <Step
              title="Lead"
              description={moment(editJobStore.job.leaddate).format(
                "DD-MMM-YY"
              )}
            />
            <Step
              title="Proposal"
              description={
                currentStatus >= 1
                  ? moment(editJobStore.job.proposaldate).format("DD-MMM-YY")
                  : "Pending"
              }
            />
            <Step
              title="Booked"
              description={
                currentStatus >= 2
                  ? moment(editJobStore.job.bookeddate).format("DD-MMM-YY")
                  : "Pending"
              }
            />
            <Step
              title="Converted"
              description={
                currentStatus >= 3
                  ? moment(editJobStore.job.converteddate).format("DD-MMM-YY")
                  : "Pending"
              }
            />
          </Steps>
        </Container>
      </div>
    );
  }
}

export default observer(JobTab);
