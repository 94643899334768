import React, { Component } from "react";
import { observer } from "mobx-react";
import { Row } from "react-bootstrap";
import eventStore from "../Stores/EventStore";
import hrEventStore from "../Stores/HREventStore";
import jobEditStore from "../Stores/JobEditStore";
import settingStore from "../Stores/SettingStore";
import MaterialTable from "material-table";

import ReactApexChart from "react-apexcharts";

class QualityScorePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      client: true,
      clientdetail: true,
      job: true,
      remarks: false,
      management: false,
      feedback: false,
      cancellation: false,
      update: true
    };
    this.options = {
      defaultSortName: "id", // default sort column name
      // exportCSVBtn: this.createCustomExportCSVButton,
      defaultSortOrder: "asc", // default sort order
      onRowClick: function(job) {
        jobEditStore.selectJob(job.id);
      }
    };
  }

  componentDidMount() {
    hrEventStore.fetchLeadClientTimes();
  }

  render() {
    let columnnames = [{ title: "Job ID", field: "id", removable: true }];
    columnnames.push({ title: "Quality", field: "qualityscore" });
    columnnames.push({ title: "Missing", field: "qualityremark" });
    if (this.state.client) {
      columnnames.push({ title: "Client", field: "title" });
      columnnames.push({
        title: "Status",
        field: "status"
      });
      columnnames.push({ title: "Last Update", field: "lastupdate" });
    }
    if (this.state.clientdetail) {
      columnnames.push({ title: "Phone 1", field: "phone1" });
      columnnames.push({ title: "Phone 2", field: "phone1" });
      columnnames.push({ title: "Email", field: "email" });
    }

    if (this.state.job) {
      columnnames.push({
        title: "Main",
        field: "jobmain"
      });
      columnnames.push({ title: "Type", field: "jobsecond" });
      columnnames.push({ title: "CBM", field: "cbm" });
      columnnames.push({ title: "Price", field: "lastprice" });
    }

    if (
      settingStore.newClient ||
      settingStore.viewClient ||
      settingStore.editJob
    ) {
      return <div />;
    }

    let count = 0;
    let total = 0;
    let leadtime = 0;
    let proptime = 0;
    let leadscore = 100;
    let propscore = 100;

    for (let i = 0; i < hrEventStore.leads.length; i++) {
      count += 1;
      total += parseFloat(hrEventStore.leads[i].daysdiff);
    }
    leadtime = Math.round(total / count);
    count = 0;
    total = 0;
    for (let i = 0; i < hrEventStore.proposals.length; i++) {
      count += 1;
      total += parseFloat(hrEventStore.proposals[i].daysdiff);
    }
    proptime = Math.round(total / count);

    if (proptime > 7) {
      propscore = [Math.round(700 / proptime)];
    } else {
      propscore = [100];
    }
    if (leadtime > 7) {
      leadscore = [Math.round(700 / leadtime)];
    } else {
      leadscore = [100];
    }
    let qualityscore = [eventStore.qualityScore];
    let finalscore = [
      Math.round(
        propscore[0] * 0.25 + leadscore[0] * 0.25 + qualityscore[0] * 0.5
      )
    ];

    let alldata = {
      options: {
        chart: {
          height: 200,
          type: "radialBar"
        },
        plotOptions: {
          radialBar: {
            startAngle: 0,
            endAngle: 360,
            hollow: {
              margin: 0,
              size: "70%",
              background: "#fff",
              image: undefined,
              imageOffsetX: 0,
              imageOffsetY: 0,
              position: "front",
              dropShadow: {
                enabled: true,
                top: 3,
                left: 0,
                blur: 4,
                opacity: 0.24
              }
            },
            track: {
              background: "#fff",
              strokeWidth: "67%",
              margin: 0, // margin is in pixels
              dropShadow: {
                enabled: true,
                top: -3,
                left: 0,
                blur: 4,
                opacity: 0.35
              }
            },

            dataLabels: {
              show: true,
              name: {
                offsetY: -10,
                show: true,
                color: "#888",
                fontSize: "17px"
              },
              value: {
                formatter: function(val) {
                  return parseInt(val);
                },
                color: "#111",
                fontSize: "36px",
                show: true
              }
            }
          }
        },
        fill: {
          type: "gradient",
          gradient: {
            shade: "dark",
            type: "horizontal",
            shadeIntensity: 0.5,
            gradientToColors: ["#ABE5A1"],
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100]
          }
        },
        stroke: {
          lineCap: "round"
        },
        labels: ["Percent"]
      }
    };

    return (
      <div>
        <h1>Quality Score = {eventStore.qualityScore} %</h1>
        <div id="chart">
          <Row>
            <ReactApexChart
              options={alldata.options}
              series={finalscore}
              type="radialBar"
              height={200}
            />
            <ReactApexChart
              options={alldata.options}
              series={qualityscore}
              type="radialBar"
              height={200}
            />
            <ReactApexChart
              options={alldata.options}
              series={leadscore}
              type="radialBar"
              height={200}
            />
            <ReactApexChart
              options={alldata.options}
              series={propscore}
              type="radialBar"
              height={200}
            />
          </Row>
        </div>
        <h1>Lead Score = {leadtime} days</h1>
        <h1>Proposal Score = {proptime} days</h1>
        <hr />
        <MaterialTable
          title={eventStore.filteredUserJobs.length + " Jobs"}
          columns={columnnames}
          data={eventStore.filteredUserJobs}
          paging={true}
          options={{
            actionsColumnIndex: 0,
            exportAllData: true,
            paging: true,
            pageSize: 25,
            pageSizeOptions: [5, 10, 15, 20, 25],
            toolbarButtonAlignment: "left",
            maxBodyHeight: 1000,
            tableLayout: "auto",
            exportButton: true,
            exportFileName: "Easy Move Jobs List",
            grouping: true,
            padding: "dense"
          }}
          actions={[
            rowData => ({
              icon: "edit",
              tooltip: "Edit Item",
              onClick: (event, rowData) => {
                eventStore.selectClient(rowData.client);
                jobEditStore.selectJob(rowData.id);
              }
            })
          ]}
        />
        <br />
        <MaterialTable
          title={hrEventStore.leads.length + " Leads"}
          columns={columnnames}
          data={hrEventStore.leads}
          paging={true}
          options={{
            actionsColumnIndex: 0,
            exportAllData: true,
            paging: true,
            pageSize: 25,
            pageSizeOptions: [5, 10, 15, 20, 25],
            toolbarButtonAlignment: "left",
            maxBodyHeight: 1000,
            tableLayout: "auto",
            exportButton: true,
            exportFileName: "Easy Move Jobs List",
            grouping: true,
            padding: "dense"
          }}
          actions={[
            rowData => ({
              icon: "edit",
              tooltip: "Edit Item",
              onClick: (event, rowData) => {
                eventStore.selectClient(rowData.client);
                jobEditStore.selectJob(rowData.id);
              }
            })
          ]}
        />
        <br />
        <MaterialTable
          title={hrEventStore.proposals.length + " Proposals"}
          columns={columnnames}
          data={hrEventStore.proposals}
          paging={true}
          options={{
            actionsColumnIndex: 0,
            exportAllData: true,
            paging: true,
            pageSize: 25,
            pageSizeOptions: [5, 10, 15, 20, 25],
            toolbarButtonAlignment: "left",
            maxBodyHeight: 1000,
            tableLayout: "auto",
            exportButton: true,
            exportFileName: "Easy Move Jobs List",
            grouping: true,
            padding: "dense"
          }}
          actions={[
            rowData => ({
              icon: "edit",
              tooltip: "Edit Item",
              onClick: (event, rowData) => {
                eventStore.selectClient(rowData.client);
                jobEditStore.selectJob(rowData.id);
              }
            })
          ]}
        />
        <br />
        <hr />
      </div>
    );
  }
}

export default observer(QualityScorePage);
