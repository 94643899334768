import { decorate, observable, computed } from "mobx";
import axios from "axios";
import moment from "moment";
import settingStore from "./SettingStore";

const instance = axios.create({
  //   baseURL: "https://easymovecalendar.app/",
  // baseURL: "http://127.0.0.1:8000/",
  // baseURL: "http://157.230.113.108/",
  baseURL: "https://jazzafs.com/",
});

class StorageStore {
  constructor() {
    this.storages = [];
  }

  fetchStorage() {
    settingStore.changeShortLoading(true);
    return instance
      .get("/n-storagev2/?format=json")
      .then((res) => res.data)
      .then((storage) => {
        for (let i = 0; i < storage.length; i++) {
          storage[i].desc = `${storage[i].clienttitle} ${
            Math.round(10 * storage[i].storagecbm) / 10
          } CBM at ${
            Math.round(1000 * storage[i].monthlycost) / 1000
          } KD - End on ${moment(storage[i].storageenddate).format(
            "DD-MMM-YY"
          )}`;
        }
        this.storages = storage;
        console.log("Storage Received");
      });
  }

  get storageConverted() {
    return this.storages.filter((job) => job.status === "Converted");
  }

  get storageUnpaidJobs() {
    let unpaidStorage = this.storages.filter(
      (job) =>
        (moment(job.storagepaidto) < moment(job.storageenddate)) &
        (job.status === "Converted")
    );

    for (let i = 0; i < unpaidStorage.length; i++) {
      unpaidStorage[i].monthsbehind = Math.round(
        moment(unpaidStorage[i].storageenddate).diff(
          moment(unpaidStorage[i].storagepaidto),
          "months",
          true
        )
      );
      unpaidStorage[i].totalremaining =
        Math.round(
          1000 *
            Math.round(unpaidStorage[i].monthsbehind) *
            unpaidStorage[i].monthlycost
        ) / 1000;
    }
    return unpaidStorage;
  }

  get storageCurrent() {
    return this.storages.filter(
      (job) =>
        (moment(job.storagestartdate) <= moment(new Date())) &
        (moment(job.storageenddate) >= moment(new Date())) &
        (job.status === "Converted")
    );
  }
  get storagePotential() {
    return this.storages.filter(
      (job) => (job.status !== "Converted") & (job.status !== "Cancelled")
    );
  }
  get storageOldNotClosed() {
    return this.storages.filter(
      (job) =>
        (job.status === "Converted") &
        (moment(job.storageenddate) < moment(new Date())) &
        (job.storagestatus !== "Completed")
    );
  }
  get storageOldClosed() {
    return this.storages.filter(
      (job) =>
        (job.status === "Converted") &
        (moment(job.storageenddate) < moment(new Date())) &
        (job.storagestatus === "Completed")
    );
  }

  get managementStorage() {
    let filtjobs = this.storages;

    filtjobs = filtjobs.filter((job) => job.status !== "Cancelled");

    return filtjobs;
  }
}

decorate(StorageStore, {
  storages: observable,
  storageConverted: computed,
  storageUnpaidJobs: computed,
  storageCurrent: computed,
  storagePotential: computed,
  storageOldNotClosed: computed,
  storageOldClosed: computed,
  managementStorage: computed,
});

const storageStore = new StorageStore();

export default storageStore;
