import React from "react";
// First way to import
import { observer } from "mobx-react";
import { Button, Modal, Row, Col } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import editJobStore from "../../Stores/EditJobStore";

import MaterialTable from "material-table";

import "react-datepicker/dist/react-datepicker.css";
import { Select } from "antd";

import StandItems from "../../../BackupOldData/standards/StandItems";

const { Option } = Select;

class EditablePackingList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      showroomname: false,
      showdelete: false,
      showupdate: false,
      title: "",
      cbm: 0,
      calculatedcbm: 0,
      quantity: 1,
      width: 0,
      height: 0,
      length: 0,
      weight: 0,
      remark: "",
      status: "",
      id: 0,
      selectedItem: 0,
    };
    this.newItem = this.newItem.bind(this);
  }
  options = {
    onRowClick: function (row) {
      //   alert(`You click row id: ${row.id}`);
      //   this.handleOpen("add",event);
    },
  };

  newItem = (e, type) => {
    if (type === "title") {
      this.setState({ title: e.target.value });
    } else if (type === "cbm") {
      this.setState({ cbm: e.target.value });
    } else if (type === "quantity") {
      this.setState({ quantity: e.target.value });
    } else if (type === "weight") {
      this.setState({ weight: e.target.value });
    } else if (type === "remark") {
      this.setState({ remark: e.target.value });
    } else if (type === "height") {
      this.setState({ height: e.target.value });
    } else if (type === "width") {
      this.setState({ width: e.target.value });
    } else if (type === "length") {
      this.setState({ length: e.target.value });
    }
  };

  handleClose = () => {
    this.setState({
      show: false,
      showroomname: false,
      showdelete: false,
      showupdate: false,
      title: "",
      cbm: 0,
      length: 0,
      quantity: 1,
      width: 0,
      height: 0,
      weight: 0,
      remark: "",
      status: "",
      id: 0,
      selectedItem: 0,
    });
  };
  handleAddAnother = () => {
    this.setState({
      title: "",
      cbm: 0,
      length: 0,
      quantity: 1,
      width: 0,
      height: 0,
      weight: 0,
      remark: "",
      status: "",
      id: 0,
      selectedItem: 0,
    });
  };

  render() {
    let roomindex = this.props.roomindex;
    let fixedcolumns = [
      { title: "Item", field: "title", removable: true },
      { title: "CBM", field: "cbm" },
      { title: "Quantity", field: "quantity" },
      { title: "Weight", field: "weight" },
      { title: "Remarks", field: "remark" },
    ];

    let optionList = StandItems.map((item, index) => (
      <Option value={item.id} key={index}>
        {item.name}
      </Option>
    ));

    return (
      <div>
        <Modal show={this.state.showroomname} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Edit Room Name</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <TextField
              size="small"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Room Name"
              name="newroom"
              autoComplete="newroom"
              autoFocus
              type="text"
              style={{ color: "red" }}
              value={editJobStore.rooms[roomindex].title}
              onChange={(e) =>
                editJobStore.updateRoom("title", e.target.value, roomindex)
              }
            />
          </Modal.Body>
        </Modal>
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              Add Item to {editJobStore.rooms[roomindex].title}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Select
              showSearch
              style={{ width: 400 }}
              placeholder="Select an Item"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              onChange={(e) => {
                let indexVal = StandItems.findIndex((item) => item.id === e);
                this.setState({ title: StandItems[indexVal].name });
                this.setState({ height: StandItems[indexVal].height });
                this.setState({ width: StandItems[indexVal].width });
                this.setState({ length: StandItems[indexVal].length });
                this.setState({
                  cbm:
                    Math.round(
                      (parseFloat(StandItems[indexVal].height) *
                        parseFloat(StandItems[indexVal].width) *
                        parseFloat(StandItems[indexVal].length)) /
                        10000
                    ) / 100,
                });
              }}
            >
              {optionList}
            </Select>

            <TextField
              size="small"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Item Name"
              name="title"
              autoComplete="title"
              autoFocus
              type="text"
              style={{ color: "red" }}
              value={this.state.title}
              onChange={(e) => this.newItem(e, "title")}
            />
            <TextField
              size="small"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="CBM"
              name="cbm"
              autoComplete="cbm"
              autoFocus
              type="number"
              style={{ color: "red" }}
              value={this.state.cbm}
              onChange={(e) => this.newItem(e, "cbm")}
            />
            <TextField
              size="small"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Quantity"
              name="quantity"
              autoComplete="quantity"
              autoFocus
              type="number"
              style={{ color: "red" }}
              value={this.state.quantity}
              onChange={(e) => this.newItem(e, "quantity")}
            />
            <Row>
              <Col>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Height CM"
                  name="height"
                  autoComplete="height"
                  autoFocus
                  type="number"
                  style={{ color: "red" }}
                  value={this.state.height}
                  onChange={(e) => this.newItem(e, "height")}
                />
              </Col>
              <Col>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Width CM"
                  name="width"
                  autoComplete="width"
                  autoFocus
                  type="number"
                  style={{ color: "red" }}
                  value={this.state.width}
                  onChange={(e) => this.newItem(e, "width")}
                />
              </Col>
              <Col>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Length CM"
                  name="length"
                  autoComplete="length"
                  autoFocus
                  type="number"
                  style={{ color: "red" }}
                  value={this.state.length}
                  onChange={(e) => this.newItem(e, "length")}
                />
              </Col>
            </Row>
            <TextField
              size="small"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Weight"
              name="weight"
              autoComplete="weight"
              autoFocus
              type="number"
              style={{ color: "red" }}
              value={this.state.weight}
              onChange={(e) => this.newItem(e, "weight")}
            />
            <TextField
              size="small"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Remark"
              name="remark"
              autoComplete="remark"
              autoFocus
              type="text"
              style={{ color: "red" }}
              value={this.state.remark}
              onChange={(e) => this.newItem(e, "remark")}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Cancel
            </Button>
            <Button
              variant="primary"
              disabled={this.state.title === ""}
              onClick={() => {
                let data = {
                  title: this.state.title,
                  cbm: this.state.cbm,
                  length: this.state.length,
                  quantity: this.state.quantity,
                  width: this.state.width,
                  height: this.state.height,
                  weight: this.state.weight,
                  remark: this.state.remark,
                  status: this.state.status,
                };

                editJobStore.addItem(data, roomindex);
                this.handleClose();
              }}
            >
              Save Changes
            </Button>
            <Button
              variant="success"
              disabled={this.state.title === ""}
              onClick={() => {
                let data = {
                  title: this.state.title,
                  cbm: this.state.cbm,
                  length: this.state.length,
                  quantity: this.state.quantity,
                  width: this.state.width,
                  height: this.state.height,
                  weight: this.state.weight,
                  remark: this.state.remark,
                  status: this.state.status,
                };

                editJobStore.addItem(data, roomindex);
                this.handleAddAnother();
              }}
            >
              Save and Add Another
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={this.state.showupdate} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>
              Update Item in {editJobStore.rooms[roomindex].title}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Select
              showSearch
              style={{ width: 400 }}
              placeholder="Select an Item"
              optionFilterProp="children"
              filterOption={(input, option) =>
                option.props.children
                  .toLowerCase()
                  .indexOf(input.toLowerCase()) >= 0
              }
              onChange={(e) => {
                let indexVal = StandItems.findIndex((item) => item.id === e);
                this.setState({ title: StandItems[indexVal].name });
                this.setState({ height: StandItems[indexVal].height });
                this.setState({ width: StandItems[indexVal].width });
                this.setState({ length: StandItems[indexVal].length });
                this.setState({
                  cbm:
                    Math.round(
                      (parseFloat(StandItems[indexVal].height) *
                        parseFloat(StandItems[indexVal].width) *
                        parseFloat(StandItems[indexVal].length)) /
                        10000
                    ) / 100,
                });
              }}
            >
              {optionList}
            </Select>

            <TextField
              size="small"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Item Name"
              name="title"
              autoComplete="title"
              autoFocus
              type="text"
              style={{ color: "red" }}
              value={this.state.title}
              onChange={(e) => this.newItem(e, "title")}
            />

            <TextField
              size="small"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="CBM"
              name="cbm"
              autoComplete="cbm"
              autoFocus
              type="number"
              style={{ color: "red" }}
              value={this.state.cbm}
              onChange={(e) => this.newItem(e, "cbm")}
            />
            <TextField
              size="small"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Quantity"
              name="quantity"
              autoComplete="quantity"
              autoFocus
              type="number"
              style={{ color: "red" }}
              value={this.state.quantity}
              onChange={(e) => this.newItem(e, "quantity")}
            />
            <Row>
              <Col>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Height CM"
                  name="height"
                  autoComplete="height"
                  autoFocus
                  type="number"
                  style={{ color: "red" }}
                  value={this.state.height}
                  onChange={(e) => this.newItem(e, "height")}
                />
              </Col>
              <Col>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Width CM"
                  name="width"
                  autoComplete="width"
                  autoFocus
                  type="number"
                  style={{ color: "red" }}
                  value={this.state.width}
                  onChange={(e) => this.newItem(e, "width")}
                />
              </Col>
              <Col>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  label="Length CM"
                  name="length"
                  autoComplete="length"
                  autoFocus
                  type="number"
                  style={{ color: "red" }}
                  value={this.state.length}
                  onChange={(e) => this.newItem(e, "length")}
                />
              </Col>
            </Row>
            <TextField
              size="small"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Weight"
              name="weight"
              autoComplete="weight"
              autoFocus
              type="number"
              style={{ color: "red" }}
              value={this.state.weight}
              onChange={(e) => this.newItem(e, "weight")}
            />
            <TextField
              size="small"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Remark"
              name="remark"
              autoComplete="remark"
              autoFocus
              type="text"
              style={{ color: "red" }}
              value={this.state.remark}
              onChange={(e) => this.newItem(e, "remark")}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Cancel
            </Button>
            <Button
              variant="success"
              disabled={this.state.title === ""}
              onClick={() => {
                let data = {
                  title: this.state.title,
                  cbm: this.state.cbm,
                  length: this.state.length,
                  quantity: this.state.quantity,
                  width: this.state.width,
                  height: this.state.height,
                  weight: this.state.weight,
                  remark: this.state.remark,
                  status: this.state.status,
                  id: this.state.id,
                };
                editJobStore.updateItem(
                  data,
                  roomindex,
                  this.state.selectedItem
                );
                this.handleClose();
              }}
            >
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
        <MaterialTable
          title={editJobStore.rooms[roomindex].title}
          columns={fixedcolumns}
          data={editJobStore.rooms[roomindex].item}
          paging={false}
          editable={{
            isEditable: (rowData) => rowData.name === "a",
          }}
          icons={{
            Add: (props) => {
              return (
                <div>
                  {" "}
                  <i className="fa fa-plus"></i> Add new Region{" "}
                </div>
              );
            },
          }}
          actions={[
            {
              icon: "edit",
              tooltip: "Edit Room",
              isFreeAction: true,
              onClick: (event) => this.setState({ showroomname: true }),
            },
            {
              icon: "delete",
              tooltip: "Delete Room",
              isFreeAction: true,
              onClick: (event) =>
                editJobStore.updateRoom("delete", 0, roomindex),
            },
            {
              icon: "add",
              tooltip: "Add Item",
              isFreeAction: true,
              onClick: (event) => this.setState({ show: true }),
            },
            (rowData) => ({
              icon: "edit",
              tooltip: "Edit Item",
              onClick: (event, rowData) => {
                this.setState({
                  showupdate: true,
                  title: rowData.title,
                  cbm: rowData.cbm,
                  length: rowData.length,
                  quantity: rowData.quantity,
                  width: rowData.width,
                  height: rowData.height,
                  weight: rowData.weight,
                  remark: rowData.remark,
                  status: rowData.status,
                  id: rowData.id,
                  selectedItem: rowData.tableData.id,
                });
              },
            }),
            (rowData) => ({
              icon: "delete",
              tooltip: "Delete Item",
              onClick: (event, rowData) => {
                this.setState({ showdelete: true });
                editJobStore.deleteItem(
                  rowData,
                  rowData.tableData.id,
                  roomindex
                );
              },
            }),
          ]}
          options={{
            actionsColumnIndex: 0,
            exportAllData: true,
            paging: false,
            toolbarButtonAlignment: "left",
          }}
        />
        <hr />
      </div>
    );
  }
}

export default observer(EditablePackingList);
