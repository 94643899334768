import React from "react";
// First way to import
import { observer } from "mobx-react";
import { Button, Container } from "react-bootstrap";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import { Input } from "antd";
import Grid from "@material-ui/core/Grid";

import jobEditStore from "../Stores/JobEditStore";

const { TextArea } = Input;

class AddressRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let indexVal = this.props.addressindex;

    return (
      <div>
        <Container style={{ maxWidth: "98%" }}>
          <br />
          <form>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={1} md={1}>
                <FormControl
                  variant="outlined"
                  style={{ width: "100%" }}
                  size="small"
                >
                  <InputLabel>Order</InputLabel>
                  <Select
                    native
                    type="number"
                    value={jobEditStore.addresses[indexVal].order}
                    onChange={e =>
                      jobEditStore.updateAddress(
                        e.target.value,
                        indexVal,
                        "order"
                      )
                    }
                  >
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={5} md={5}>
                <FormControl
                  variant="outlined"
                  style={{ width: "100%" }}
                  size="small"
                >
                  <InputLabel>From Type</InputLabel>
                  <Select
                    native
                    label="Age"
                    value={jobEditStore.addresses[indexVal].typefrom}
                    onChange={e =>
                      jobEditStore.updateAddress(
                        e.target.value,
                        indexVal,
                        "typefrom"
                      )
                    }
                  >
                    <option value="" />
                    <option value={"House"}>House</option>
                    <option value={"Apartment"}>Apartment</option>
                    <option value={"Office"}>Office</option>
                    <option value={"Facility"}>Facility</option>
                    <option value={"Storage"}>Storage</option>
                    <option value={"Other"}>Other</option>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={10} sm={5} md={5}>
                <FormControl
                  variant="outlined"
                  style={{ width: "100%" }}
                  size="small"
                >
                  <InputLabel>To Type</InputLabel>
                  <Select
                    native
                    label="Age"
                    value={jobEditStore.addresses[indexVal].typeto}
                    onChange={e =>
                      jobEditStore.updateAddress(
                        e.target.value,
                        indexVal,
                        "typeto"
                      )
                    }
                  >
                    <option value="" />
                    <option value={"House"}>House</option>
                    <option value={"Apartment"}>Apartment</option>
                    <option value={"Office"}>Office</option>
                    <option value={"Facility"}>Facility</option>
                    <option value={"Storage"}>Storage</option>
                    <option value={"Other"}>Other</option>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={2} sm={1} md={1}>
                <Button
                  variant="outline-danger"
                  block
                  onClick={() =>
                    jobEditStore.postAddressUpdate(
                      [],
                      jobEditStore.addresses[indexVal].id,
                      "destroy",
                      indexVal
                    )
                  }
                >
                  <i className="fas fa-times"></i>
                </Button>
              </Grid>
            </Grid>
            <Grid container spacing={1}>
              <Grid item xs={12} sm={12} md={6}>
                <b>From Address:</b>
                <TextArea
                  style={{ width: "100%" }}
                  placeholder="Add client remarks here"
                  autoSize={{ minRows: 2, maxRows: 10 }}
                  value={jobEditStore.addresses[indexVal].locationfrom}
                  onChange={e =>
                    jobEditStore.updateAddress(
                      e.target.value,
                      indexVal,
                      "locationfrom"
                    )
                  }
                />
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <b>To Address:</b>
                <TextArea
                  style={{ width: "100%" }}
                  placeholder="Add client remarks here"
                  autoSize={{ minRows: 2, maxRows: 10 }}
                  value={jobEditStore.addresses[indexVal].locationto}
                  onChange={e =>
                    jobEditStore.updateAddress(
                      e.target.value,
                      indexVal,
                      "locationto"
                    )
                  }
                />
              </Grid>
            </Grid>
          </form>
          <br />
        </Container>
      </div>
    );
  }
}

export default observer(AddressRow);
