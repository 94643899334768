import React, { Component } from "react";
import { observer } from "mobx-react";

import { Button } from "react-bootstrap";
import newEventStore from "../Stores/NewEventStore";
import storageStore from "../Stores/StorageStore";
import moment from "moment";
import { Chart } from "react-google-charts";
import { Col, Row } from "react-bootstrap";

class YearEndTarget extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTable: false,
    };
  }

  componentDidMount() {}

  render() {
    let startDate = this.props.start;
    let monthlyTarget = this.props.target;

    let jobdates = newEventStore.sectorSumAll;
    let stJob = storageStore.storageConverted;

    jobdates = jobdates.filter(
      (job) =>
        moment(job.jobdate) >= moment(moment(startDate).subtract(3, "months"))
    );
    jobdates = jobdates.filter(
      (job) => moment(job.jobdate) < moment(moment(startDate).add(3, "months"))
    );

    let monthlyLocal = 0;
    let monthlyIMprofit = 0;
    let monthlyIM = 0;
    let monthlyCC = 0;
    let monthlyOther = 0;
    let monthlyStorage = 0;
    let endDate = moment(startDate).endOf("month");
    let dateDiffMonthly = endDate.diff(startDate, "days");

    for (let j = 0; j < dateDiffMonthly; j++) {
      for (let i = 0; i < stJob.length; i++) {
        if (
          (moment(stJob[i].storagestartdate) <=
            moment(startDate).add(j, "days")) &
          (moment(stJob[i].storageenddate) >= moment(startDate).add(j, "days"))
        ) {
          if (parseFloat(stJob[i].monthlycost) > 0) {
            monthlyStorage +=
              parseFloat(stJob[i].monthlycost) / parseFloat(dateDiffMonthly);
          }
        }
      }
    }

    for (let k = 0; k < dateDiffMonthly + 1; k++) {
      for (let i = 0; i < jobdates.length; i++) {
        if (
          moment(jobdates[i].jobdate).format("DD-MMM-YY") ===
          moment(startDate).add(k, "days").format("DD-MMM-YY")
        ) {
          if (
            jobdates[i].status === "Booked" ||
            jobdates[i].status === "Converted"
          ) {
            if (
              jobdates[i].jobmain === "Local Move" ||
              jobdates[i].jobmain === "Corporate Move"
            ) {
              monthlyLocal += jobdates[i].dailyprice;
            } else if (
              jobdates[i].jobmain === "IM - Outbound" ||
              jobdates[i].jobmain === "CIM - Outbound" ||
              jobdates[i].jobmain === "IM - Inbound" ||
              jobdates[i].jobmain === "CIM - Inbound"
            ) {
              monthlyIM += jobdates[i].dailyprice;
              monthlyIMprofit += jobdates[i].dailyprofit;
            } else if (jobdates[i].jobmain === "Only Clearance") {
              monthlyCC += jobdates[i].dailyprice;
            } else {
              monthlyOther += jobdates[i].dailyprice;
            }
          }
        }
      }
    }

    return (
      <div>
        <Row>
          <Col>
            <h5>
              {moment(startDate).format("MMMM YYYY")} Progress ={" "}
              {Math.round(
                (monthlyStorage + monthlyLocal + monthlyIMprofit) * 1000
              ) / 1000}{" "}
              KD{" "}
              <i>
                (
                {Math.round(
                  (100 * (monthlyStorage + monthlyLocal + monthlyIMprofit)) /
                    monthlyTarget
                )}{" "}
                %)
              </i>
            </h5>
            <Button
              variant={this.state.showTable ? "danger" : "success"}
              onClick={() =>
                this.setState({ showTable: !this.state.showTable })
              }
            >
              {this.state.showTable ? "Hide Details" : "Show Details"}
            </Button>
            {this.state.showTable && (
              <div>
                <li>
                  {moment(startDate).format("MMMM")} Target ={" "}
                  {Math.round(monthlyTarget * 1000) / 1000} KD (
                  {Math.round(
                    (100 * (monthlyStorage + monthlyLocal + monthlyIMprofit)) /
                      monthlyTarget
                  )}{" "}
                  %){" "}
                </li>
                <li>
                  {moment(startDate).format("MMMM")} Local ={" "}
                  {Math.round(monthlyLocal * 1000) / 1000} KD
                </li>
                <li>
                  {moment(startDate).format("MMMM")} IM ={" "}
                  {Math.round(monthlyIMprofit * 1000) / 1000} KD Profit (
                  {Math.round(monthlyIM * 1000) / 1000} KD revenue)
                </li>
                <li>
                  {moment(startDate).format("MMMM")} Storage ={" "}
                  {Math.round(monthlyStorage * 1000) / 1000} KD
                </li>
                <li>
                  <b>
                    {moment(startDate).format("MMMM")} Balance ={" "}
                    {Math.round(
                      (monthlyTarget -
                        (monthlyStorage + monthlyLocal + monthlyIMprofit)) *
                        1000
                    ) / 1000}{" "}
                    KD
                  </b>
                </li>
                <br />
                <li>
                  {moment(startDate).format("MMMM")} Custom Clearance ={" "}
                  {Math.round(monthlyCC * 1000) / 1000} KD
                </li>
                <li>
                  {moment(startDate).format("MMMM")} Other Jobs ={" "}
                  {Math.round(monthlyOther * 1000) / 1000} KD
                </li>
              </div>
            )}
          </Col>
          <Col>
            <Chart
              width={150}
              height={150}
              chartType="Gauge"
              loader={<div>Loading Chart</div>}
              data={[
                ["Label", "Value"],
                [
                  `${Math.round(
                    (100 * (monthlyStorage + monthlyLocal + monthlyIMprofit)) /
                      monthlyTarget
                  )}%`,

                  Math.round(monthlyStorage + monthlyLocal + monthlyIMprofit),
                ],
              ]}
              options={{
                redFrom: 0,
                redTo: 5000,
                yellowFrom: 5000,
                yellowTo: 7750,
                greenFrom: 7750,
                greenTo: 10000,
                minorTicks: 2,
                majorTicks: [0, "", "", 7500, ""],
                max: 10000,
              }}
              rootProps={{ "data-testid": "1" }}
            />
          </Col>
        </Row>

        <hr />
      </div>
    );
  }
}

export default observer(YearEndTarget);

// export default class Example extends PureComponent {
//   static jsfiddleUrl = "https://jsfiddle.net/alidingling/c1rLyqj1/";
