import React, { Component } from "react";
import { observer } from "mobx-react";
import Modal from "react-bootstrap/Modal";

import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import { Button } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import { Input } from "antd";

import newEventStore from "../Stores/NewEventStore";
import jobSelectionStore from "../Stores/JobSelectionStore";
import settingStore from "../Stores/SettingStore";
import JobsTable from "./JobsTable";

const { TextArea } = Input;

class ClientListPage extends Component {
  constructor(props) {
    super(props);
    this.state = { show: false };
    this.options = {
      defaultSortName: "id", // default sort column name
      // exportCSVBtn: this.createCustomExportCSVButton,
      defaultSortOrder: "asc", // default sort order
      onRowClick: function (event) {
        jobSelectionStore.selectClient(event.id);
      },
    };
  }

  handleClose = () => this.setState({ show: false });
  handleShow = () => this.setState({ show: true });

  render() {
    let newClientForm = (
      <div>
        <form>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="title"
            label="Name"
            name="title"
            autoComplete="title"
            autoFocus
            type="text"
            value={jobSelectionStore.editClient.title}
            onChange={(e) =>
              jobSelectionStore.updateClient(e.target.value, "title")
            }
          />
          <TextField
            style={{ width: "25%" }}
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="phone1"
            label="Phone 1"
            name="phone1"
            autoComplete="phone1"
            autoFocus
            type="text"
            value={jobSelectionStore.editClient.phone1}
            onChange={(e) =>
              jobSelectionStore.updateClient(e.target.value, "phone1")
            }
          />
          <TextField
            style={{ width: "25%" }}
            variant="outlined"
            margin="normal"
            fullWidth
            id="phone2"
            label="Phone 2"
            name="phone2"
            autoComplete="phone2"
            autoFocus
            type="text"
            value={jobSelectionStore.editClient.phone2}
            onChange={(e) =>
              jobSelectionStore.updateClient(e.target.value, "phone2")
            }
          />
          <TextField
            style={{ width: "50%" }}
            variant="outlined"
            margin="normal"
            fullWidth
            id="email"
            label="Email"
            name="email"
            autoComplete="email"
            autoFocus
            type="email"
            value={jobSelectionStore.editClient.email}
            onChange={(e) =>
              jobSelectionStore.updateClient(e.target.value, "email")
            }
          />
          <br />
          <br />
          <FormControl variant="outlined" style={{ width: "50%" }}>
            <InputLabel>Lead Type</InputLabel>
            <Select
              native
              label="Age"
              value={jobSelectionStore.editClient.leadtype}
              onChange={(e) =>
                jobSelectionStore.updateClient(e.target.value, "leadtype")
              }
              inputProps={{
                name: "age",
                id: "outlined-age-native-simple",
              }}
            >
              <option value="" />
              <option value={"Received Call"}>Received Call</option>
              <option value={"Missed Call"}>Missed Call</option>
              <option value={"Email Lead"}>Email Lead</option>
              <option value={"Online Lead"}>Online Lead</option>
              <option value={"Referral"}>Referral</option>
              <option value={"Other"}>Other</option>
            </Select>
          </FormControl>
          <FormControl variant="outlined" style={{ width: "50%" }}>
            <InputLabel>How They Heard of Us?</InputLabel>
            <Select
              native
              value={jobSelectionStore.editClient.heardofus}
              onChange={(e) =>
                jobSelectionStore.updateClient(e.target.value, "heardofus")
              }
              inputProps={{
                name: "age",
                id: "outlined-age-native-simple",
              }}
            >
              <option value="" />
              <option value={"Google"}>Google</option>
              <option value={"Instagram"}>Instagram</option>
              <option value={"Facebook"}>Facebook</option>
              <option value={"Google Maps"}>Google Maps</option>
              <option value={"Returning Customer"}>Returning Customer</option>
              <option value={"Referrals"}>Referrals</option>
              <option value={"Other"}>Other</option>
            </Select>
          </FormControl>
          <br />
          <br />
        </form>
        <b>Client Remarks:</b>
        <TextArea
          style={{ width: "100%" }}
          placeholder="Add client remarks here"
          autoSize={{ minRows: 2, maxRows: 10 }}
          value={jobSelectionStore.editClient.remarks}
          onChange={(e) =>
            jobSelectionStore.updateClient(e.target.value, "remarks")
          }
        />
      </div>
    );

    let clientForm = (
      <Button
        block
        variant="success"
        onClick={() => {
          jobSelectionStore.selectClient(0);
          this.handleShow();
        }}
      >
        Add A New Client
      </Button>
    );

    if (
      settingStore.newClient ||
      settingStore.viewClient ||
      settingStore.editJob
    ) {
      return <div />;
    }

    return (
      <div>
        <Modal
          show={this.state.show}
          onHide={this.handleClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Create A New Client</Modal.Title>
          </Modal.Header>
          <Modal.Body>{newClientForm}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                this.handleClose();
                jobSelectionStore.postClient();
                settingStore.changeNewClient(false);
              }}
            >
              Create Client
            </Button>
          </Modal.Footer>
        </Modal>

        {jobSelectionStore.showJobs ? <JobsTable /> : clientForm}

        <br />
        <BootstrapTable
          data={newEventStore.clients}
          striped
          hover
          pagination
          search={true}
          condensed
          options={this.options}
        >
          <TableHeaderColumn isKey dataField="id" dataSort>
            Client id
          </TableHeaderColumn>
          <TableHeaderColumn dataField="title" dataSort>
            Client Name
          </TableHeaderColumn>
          <TableHeaderColumn dataField="email" dataSort>
            Email
          </TableHeaderColumn>
          <TableHeaderColumn dataField="phone1" dataSort>
            Phone
          </TableHeaderColumn>
          <TableHeaderColumn dataField="phone2" dataSort>
            Phone 2
          </TableHeaderColumn>
          <TableHeaderColumn dataField="leadtype" dataSort>
            Lead Type
          </TableHeaderColumn>
        </BootstrapTable>
      </div>
    );
  }
}

export default observer(ClientListPage);
