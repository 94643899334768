import { decorate, observable, computed } from "mobx";
import axios from "axios";
import moment from "moment";

import settingStore from "./SettingStore";
import userStore from "./UserStore";

const instance = axios.create({
  // baseURL: "https://easymovecalendar.app/",
  // baseURL: "http://127.0.0.1:8000/",
  // baseURL: "http://157.230.113.108/",
  baseURL: "https://jazzafs.com/",
});

class EditJobStore {
  constructor() {
    this.loading = true;
    this.jobID = "";
    this.oldstatus = "";
    this.job = {};
    this.client = {};
    this.internationalfee = {
      thcharge: 0,
      docharge: 0,
      originclearance: 0,
      unloadingcharge: 0,
      unpackingcharge: 0,
      assemblingcharge: 0,
      debrisremoval: 0,
      containerreturn: 0,
      intwrapandpack: 0,
      shippingcharges: 0,
      transitclearance: 0,
      destinationclearance: 0,
      firstmilecharge: 0,
      lastmilecharge: 0,
      cratecharge: 0,
      palletcharge: 0,
      dgrcharge: 0,
      dghandling: 0,
      detentioncharge: 0,
      demoragecharge: 0,
      shippingline: 0,
      originstorageinout: 0,
      destinationstorageinout: 0,
      shuttlecharge: 0,
      containerType: "",
      shippingmethod: "",
      imlength: 0,
      imwidth: 0,
      imheight: 0,
      transportationcharges: 0,
      loadingcharge: 0,
      commodity: "",
      id: 0,
    };
    this.storages = [];
    this.material = {};
    this.jobdates = [];
    this.addresses = [];
    this.payments = [];
    this.addcharge = [];
    this.intquote = [
      {
        job: this.job.id,
        companytype: "Freight Forwarder",
        company: "",
        rating: "",
        ratingshort: "",
        price: 0,
        remark: "",
        id: 0,
      },
    ];
    this.rooms = [];
    this.items = [];
    this.client = {};
    this.editclient = false;
    this.updateitems = {
      job: false,
      addcharge: false,
      client: false,
      address: false,
      jobdate: false,
      storage: false,
      intfee: false,
      room: false,
      payment: false,
      imtracker: false,
      intquote: false,
    };
    this.customproposal = {
      title: "",
      body: "",
      cost: 0,
    };
    this.selectedIMQuote = {
      job: this.job.id,
      companytype: "Freight Forwarder",
      company: "",
      rating: "",
      ratingshort: "",
      price: 0,
      remark: "",
      id: 0,
    };
  }

  // Functions
  closeJobTabs() {
    this.editclient = false;
  }
  addRoom(title) {
    this.rooms.push({
      title: title,
      item: [],
      id: 0,
      job: this.job.id,
    });
    this.updateitems.room = true;
    this.updateitems.job = true;
  }
  addItem(data, roomindex) {
    this.rooms[roomindex].item.push({
      title: data.title,
      cbm: data.cbm,
      length: data.length,
      quantity: data.quantity,
      width: data.width,
      height: data.height,
      weight: data.weight,
      remark: data.remark,
      status: data.status,
      room: this.rooms[roomindex].id,
      id: 0,
    });
    this.updateitems.room = true;
    this.updateitems.job = true;
  }

  // UpdateData
  updateJob(type, val) {
    this.updateitems.job = true;
    if (type === "jobmain") this.job.jobmain = val;
    if (type === "jobsecond") this.job.jobsecond = val;
    if (type === "companygroup") this.job.companygroup = val;
    if (type === "cbm") this.job.cbm = val;
    if (type === "firstprice") this.job.firstprice = val;
    if (type === "lastprice") this.job.lastprice = val;
    if (type === "discountname") this.job.discountname = val;
    if (type === "discountvalue") this.job.discountvalue = val;
    if (type === "status") {
      this.job.status = val;
      console.log(val);
      if (val === "Lead") {
        console.log("Caught");
        this.job.leaddate = moment(new Date()).format();
        console.log(this.job.leaddate);
      } else if (val === "Proposal") {
        this.job.proposaldate = moment(new Date()).format();
      } else if (val === "Booked") {
        this.job.bookeddate = moment(new Date()).format();
      } else if (val === "Converted") {
        this.job.converteddate = moment(new Date()).format();
      }
    }
    if (type === "estimationType") this.job.estimationType = val;
    if (type === "emfocalpoint") this.job.emfocalpoint = val;
    if (type === "estimationdate") this.job.estimationdate = val;
    if (type === "weight") this.job.weight = val;
    if (type === "includeservice") this.job.includeservice = val;
    if (type === "excludeservice") this.job.excludeservice = val;
    if (type === "remarks") this.job.remarks = val;
    if (type === "rating") this.job.rating = val;
    if (type === "feedback") this.job.feedback = val;
    if (type === "cancellationreason") this.job.cancellationreason = val;
    if (type === "cancellationremarks") this.job.cancellationremarks = val;
  }

  changeService(id) {
    let indexval = this.job.serviceslist.findIndex(
      (service) => parseFloat(service) === parseFloat(id)
    );

    if (indexval >= 0) {
      this.job.serviceslist.splice(indexval, 1);
    } else {
      this.job.serviceslist.push(id);
    }
    this.updateitems.job = true;
  }
  changeExcludedService(id) {
    let indexval = this.job.excludedserviceslist.findIndex(
      (service) => parseFloat(service) === parseFloat(id)
    );

    if (indexval >= 0) {
      this.job.excludedserviceslist.splice(indexval, 1);
    } else {
      this.job.excludedserviceslist.push(id);
    }
    this.updateitems.job = true;
  }

  updateRoom(type, val, index) {
    if (type === "delete") {
      if (this.rooms[index].id > 0) {
        console.log("destroy");
        return instance
          .delete("/d-room/" + this.rooms[index].id + "/")
          .then((res) => res.data)
          .then((events) => {
            // console.log("successfully added");
            this.rooms.splice(index, 1);
            alert(`Room Deleted.`);
          })
          .catch((err) => {
            let message = {
              activity: "Deleting a Room",
              ClientName: this.client.title,
              ClientID: this.client.id,
              JobID: this.job.id,
              JobMain: this.job.jobmain,
              Status: this.job.status,
              Data: "Room ID = " + this.rooms[index].id,
              error: err.response,
            };
            settingStore.sendUnifiedEmail(message, "error");
            alert(
              "An error occurred. The data did NOT save. Please contact me (97666886)." +
                "\n" +
                JSON.stringify(err.response)
            );
            console.error(err.response);
          });
      } else {
        this.rooms.splice(index, 1);
      }
    } else {
      this.updateitems.job = true;
      this.updateitems.room = true;

      if (type === "title") this.rooms[index].title = val;
    }
  }
  updateItem(data, roomindex, itemindex) {
    this.rooms[roomindex].item[itemindex] = {
      title: data.title,
      cbm: data.cbm,
      length: data.length,
      quantity: data.quantity,
      width: data.width,
      height: data.height,
      weight: data.weight,
      remark: data.remark,
      status: data.status,
      room: this.rooms[roomindex].id,
      id: data.id,
    };
    this.roomUpdate = true;
  }
  deleteItem(data, index, roomindex) {
    if (data.id === 0) {
      editJobStore.rooms[roomindex].item.splice(index, 1);
    } else {
      editJobStore.rooms[roomindex].item.splice(index, 1);
      instance
        .delete("/d-item/" + data.id + "/")
        .then((res) => res.data)
        .then((events) => {
          // console.log("successfully added");
          alert(`Item Deleted.`);
        })
        .catch((err) => {
          let message = {
            activity: "Deleting an Item",
            ClientName: this.client.title,
            ClientID: this.client.id,
            JobID: this.job.id,
            JobMain: this.job.jobmain,
            Status: this.job.status,
            Data: "Item ID = " + data.id,
            error: err.response,
          };
          settingStore.sendUnifiedEmail(message, "error");
          this.errorEmail(err.response, "Delete Item");
          alert(
            "An error occurred. The data did NOT save. Please contact me (97666886)." +
              "\n" +
              JSON.stringify(err.response)
          );
          console.error(err.response);
        });
    }
  }
  updateIntFee(type, val) {
    this.updateitems.job = true;
    if (type === "thcharge") {
      this.internationalfee.thcharge = val;
    } else if (type === "docharge") {
      this.internationalfee.docharge = val;
    } else if (type === "originclearance") {
      this.internationalfee.originclearance = val;
    } else if (type === "unloadingcharge") {
      this.internationalfee.unloadingcharge = val;
    } else if (type === "assemblingcharge") {
      this.internationalfee.assemblingcharge = val;
    } else if (type === "debrisremoval") {
      this.internationalfee.debrisremoval = val;
    } else if (type === "containerreturn") {
      this.internationalfee.containerreturn = val;
    } else if (type === "intwrapandpack") {
      this.internationalfee.intwrapandpack = val;
    } else if (type === "shippingcharges") {
      this.internationalfee.shippingcharges = val;
    } else if (type === "transitclearance") {
      this.internationalfee.transitclearance = val;
    } else if (type === "destinationclearance") {
      this.internationalfee.destinationclearance = val;
    } else if (type === "firstmilecharge") {
      this.internationalfee.firstmilecharge = val;
    } else if (type === "lastmilecharge") {
      this.internationalfee.lastmilecharge = val;
    } else if (type === "cratecharge") {
      this.internationalfee.cratecharge = val;
    } else if (type === "palletcharge") {
      this.internationalfee.palletcharge = val;
    } else if (type === "dgrcharge") {
      this.internationalfee.dgrcharge = val;
    } else if (type === "dghandling") {
      this.internationalfee.dghandling = val;
    } else if (type === "detentioncharge") {
      this.internationalfee.detentioncharge = val;
    } else if (type === "demoragecharge") {
      this.internationalfee.demoragecharge = val;
    } else if (type === "shippingline") {
      this.internationalfee.shippingline = val;
    } else if (type === "originstorageinout") {
      this.internationalfee.originstorageinout = val;
    } else if (type === "destinationstorageinout") {
      this.internationalfee.destinationstorageinout = val;
    } else if (type === "shuttlecharge") {
      this.internationalfee.shuttlecharge = val;
    } else if (type === "containerType") {
      this.internationalfee.containerType = val;
    } else if (type === "shippingmethod") {
      this.internationalfee.shippingmethod = val;
    } else if (type === "imlength") {
      this.internationalfee.imlength = val;
    } else if (type === "imwidth") {
      this.internationalfee.imwidth = val;
    } else if (type === "imheight") {
      this.internationalfee.imheight = val;
    } else if (type === "transportationcharges") {
      this.internationalfee.transportationcharges = val;
    } else if (type === "loadingcharge") {
      this.internationalfee.loadingcharge = val;
    } else if (type === "commodity") {
      this.internationalfee.commodity = val;
    }
    this.updateitems.intfee = true;
  }

  updateClient(type, val) {
    this.updateitems.job = true;
    this.updateitems.client = true;
    if (type === "title") this.client.title = val;
    if (type === "email") this.client.email = val;
    if (type === "phone1") this.client.phone1 = val;
    if (type === "phone2") this.client.phone2 = val;
    if (type === "heardofus") this.client.heardofus = val;
    if (type === "leadtype") this.client.leadtype = val;
    if (type === "remarks") this.client.remarks = val;
  }

  updateJobDate(type, val, index) {
    this.updateitems.job = true;
    if (type === "add") {
      this.updateitems.jobdate = true;
      this.jobdates.push({
        id: 0,
        jobdate: moment(new Date()).format("YY-MM-DD"),
        dateremarks: "",
        status: "Pending",
        job: this.job.id,
      });
    } else if (type === "delete") {
      if (this.jobdates[index].id === 0) {
        this.jobdates.splice(index, 1);
      } else {
        return instance
          .delete("/d-jobdate/" + this.jobdates[index].id + "/")
          .then((res) => res.data)
          .then((events) => {
            // console.log("successfully added");
            this.jobdates.splice(index, 1);
            alert(`Date Deleted.`);
          })
          .catch((err) => {
            let message = {
              activity: "Deleting an Job Date",
              ClientName: this.client.title,
              ClientID: this.client.id,
              JobID: this.job.id,
              JobMain: this.job.jobmain,
              Status: this.job.status,
              Data: "Job Date ID = " + this.jobdates[index].id,
              error: err.response,
            };
            settingStore.sendUnifiedEmail(message, "error");
            alert(
              "An error occurred. The data did NOT save. Please contact me (97666886)." +
                "\n" +
                JSON.stringify(err.response)
            );
            console.error(err.response);
          });
      }
    } else {
      this.updateitems.jobdate = true;
      if (type === "jobdate") this.jobdates[index].jobdate = val;
      if (type === "status") this.jobdates[index].status = val;
      if (type === "dateremarks") this.jobdates[index].dateremarks = val;
    }
  }
  updateIntQuote(type, val, index) {
    this.updateitems.job = true;
    if (type === "add") {
      this.updateitems.intquote = true;
      this.intquote.push({
        job: this.job.id,
        companytype: "Freight Forwarder",
        company: "",
        rating: "",
        ratingshort: "",
        price: 0,
        remark: "",
        id: 0,
      });
    } else if (type === "delete") {
      if (this.intquote[index].id === 0) {
        this.intquote.splice(index, 1);
      } else {
        return instance
          .delete("/d-intquote/" + this.intquote[index].id + "/")
          .then((res) => res.data)
          .then((events) => {
            // console.log("successfully added");
            this.intquote.splice(index, 1);
            alert(`Quote Deleted.`);
          })
          .catch((err) => {
            let message = {
              activity: "Deleting an International Quote",
              ClientName: this.client.title,
              ClientID: this.client.id,
              JobID: this.job.id,
              JobMain: this.job.jobmain,
              Status: this.job.status,
              Data: "IntQuote ID = " + this.intquote[index].id,
              error: err.response,
            };
            settingStore.sendUnifiedEmail(message, "error");
            alert(
              "An error occurred. The data did NOT save. Please contact me (97666886)." +
                "\n" +
                JSON.stringify(err.response)
            );
            console.error(err.response);
          });
      }
    } else {
      this.updateitems.intquote = true;
      if (type === "price") this.intquote[index].price = val;
      if (type === "rating") this.intquote[index].rating = val;
      if (type === "remark") this.intquote[index].remark = val;
      if (type === "company") this.intquote[index].company = val;
      if (type === "companytype") this.intquote[index].companytype = val;
    }
  }

  updateSelectedIMQuote(val, index, type) {
    this.updateitems.job = true;

    if (type === "price") {
      this.selectedIMQuote.price = val;
    } else if (type === "rating") {
      this.selectedIMQuote.rating = val;
    } else if (type === "id") {
      this.selectedIMQuote.id = val;
    } else if (type === "remark") {
      this.selectedIMQuote.remark = val;
    } else if (type === "company") {
      this.selectedIMQuote.company = val;
    } else if (type === "companytype") {
      this.selectedIMQuote.companytype = val;
    } else if (type === "reset") {
      this.selectedIMQuote = {
        companytype: "Freight Forwarder",
        company: "",
        price: 0,
        rating: "",
        remarks: "",
        job: this.job.id,
        id: 0,
      };
    } else if (type === "update") {
      this.intquote[index] = this.selectedIMQuote;
      this.selectedIMQuote = {
        companytype: "Freight Forwarder",
        company: "",
        price: 0,
        rating: "",
        remarks: "",
        job: this.job.id,
        id: 0,
      };
    }
    this.updateitems.intquote = true;
  }

  updateTracker(type, val, index) {
    this.updateitems.job = true;
    if (type === "add") {
      this.updateitems.imtracker = true;
      this.imtracker.push({
        job: this.job.id,
        id: 0,
        title: "",
        status: "Pending",
        remark: "",
        originaldate: null,
        reviseddate: null,
        order: 0,
      });
    } else if (type === "delete") {
      if (this.imtracker[index].id === 0) {
        this.imtracker.splice(index, 1);
      } else {
        return instance
          .delete("/d-imtracker/" + this.imtracker[index].id + "/")
          .then((res) => res.data)
          .then((events) => {
            // console.log("successfully added");
            this.imtracker.splice(index, 1);
            alert(`IM Track Deleted.`);
          })
          .catch((err) => {
            let message = {
              activity: "Deleting an IM Track",
              ClientName: this.client.title,
              ClientID: this.client.id,
              JobID: this.job.id,
              JobMain: this.job.jobmain,
              Status: this.job.status,
              Data: "IntQuote ID = " + this.imtracker[index].id,
              error: err.response,
            };
            settingStore.sendUnifiedEmail(message, "error");
            alert(
              "An error occurred. The data did NOT save. Please contact me (97666886)." +
                "\n" +
                JSON.stringify(err.response)
            );
            console.error(err.response);
          });
      }
    } else {
      this.updateitems.imtracker = true;
      if (type === "title") this.imtracker[index].title = val;
      if (type === "status") this.imtracker[index].status = val;
      if (type === "remark") this.imtracker[index].remark = val;
      if (type === "originaldate") {
        this.imtracker[index].originaldate = val;
        this.imtracker[index].reviseddate = val;
      }
      if (type === "reviseddate") this.imtracker[index].reviseddate = val;
      if (type === "order") this.imtracker[index].order = val;
    }
  }

  updateStorage(type, val, index) {
    this.updateitems.job = true;
    if (type === "add") {
      this.updateitems.storage = true;
      this.storages.push({
        id: 0,
        storageloc_t: "EM Abu Hulaifa",
        storageloc_r: "",
        storageloc_s: "",
        storagestartdate: null,
        storageenddate: null,
        storagepaidto: null,
        monthlycost: 0,
        storagecbm: this.job.cbm,
        storagestatus: "Pending",
        job: this.job.id,
      });
    } else if (type === "delete") {
      if (this.storages[index].id === 0) {
        this.storages.splice(index, 1);
      } else {
        return instance
          .delete("/d-storage/" + this.storages[index].id + "/")
          .then((res) => res.data)
          .then((events) => {
            // console.log("successfully added");
            this.storages.splice(index, 1);
            alert(`Storage Deleted.`);
          })
          .catch((err) => {
            let message = {
              activity: "Deleting a Storage",
              ClientName: this.client.title,
              ClientID: this.client.id,
              JobID: this.job.id,
              JobMain: this.job.jobmain,
              Status: this.job.status,
              Data: "Storage ID = " + this.storages[index].id,
              error: err.response,
            };
            settingStore.sendUnifiedEmail(message, "error");
            alert(
              "An error occurred. The data did NOT save. Please contact me (97666886)." +
                "\n" +
                JSON.stringify(err.response)
            );
            console.error(err.response);
          });
      }
    } else {
      this.updateitems.storage = true;
      if (type === "storageloc_t") this.storages[index].storageloc_t = val;
      if (type === "storagestartdate")
        this.storages[index].storagestartdate = val;
      if (type === "storageenddate") this.storages[index].storageenddate = val;
      if (type === "storagepaidto") this.storages[index].storagepaidto = val;
      if (type === "monthlycost") this.storages[index].monthlycost = val;
      if (type === "storagecbm") this.storages[index].storagecbm = val;
      if (type === "storagestatus") this.storages[index].storagestatus = val;
    }
  }
  updatePayment(type, val, index) {
    this.updateitems.job = true;
    if (type === "add") {
      let department = "";
      if (
        this.job.jobmain === "Local Move" ||
        this.job.jobmain === "Corporate Move"
      ) {
        department = "Local Move";
      } else if (
        this.job.jobmain === "IM - Inbound" ||
        this.job.jobmain === "IM - Outbound" ||
        this.job.jobmain === "CIM - Outbound" ||
        this.job.jobmain === "CIM - Inbound"
      ) {
        department = "International Move";
      } else if (this.job.jobmain === "Storage") {
        department = "Storage";
      } else if (this.job.jobmain === "Custom Clearance") {
        department = "Custom Clearance";
      } else if (this.job.jobmain === "Express Delivery") {
        department = "Express Delivery";
      } else if (this.job.jobmain === "Other") {
        department = "Other";
      }

      this.updateitems.payment = true;
      if (val === "Online Link") {
        this.payments.push({
          job: this.job.id,
          category1: department,
          category2: "Income",
          category3: "Cash",
          category4: "Job Related",
          category5: "Customer Payment",
          paymentmethod: "Online Link",
          bankaccount: "Boubyan",
          expenseheading: "No",
          id: 0,
          title: "",
        });
      } else if (val === "KNET") {
        this.payments.push({
          job: this.job.id,
          category1: department,
          category2: "Income",
          category3: "Cash",
          category4: "Job Related",
          category5: "Customer Payment",
          paymentmethod: "KNET",
          bankaccount: "Boubyan",
          expenseheading: "No",
          id: 0,
          title: "",
        });
      } else if (val === "Cash") {
        this.payments.push({
          job: this.job.id,
          category1: department,
          category2: "Income",
          category3: "Cash",
          category4: "Job Related",
          category5: "Customer Payment",
          paymentmethod: "Cash",
          bankaccount: "Cash",
          expenseheading: "No",
          id: 0,
          title: "",
        });
      } else if (val === "Refund") {
        this.payments.push({
          job: this.job.id,
          category1: department,
          category2: "Expense Variable",
          category3: "Cash",
          category4: "Job Related",
          category5: "Refund",
          paymentmethod: "Check",
          bankaccount: "KFH",
          expenseheading: "No",
          id: 0,
          title: "",
        });
      } else if (val === "Shipping Expense") {
        this.payments.push({
          job: this.job.id,
          category1: "International Move",
          category2: "Expense Variable",
          category3: "Cash",
          category4: "Job Related",
          category5: "Shipping Expense",
          paymentmethod: "Check",
          bankaccount: "KFH",
          expenseheading: "No",
          id: 0,
          title: "",
        });
      } else if (val === "Bank Charge") {
        this.payments.push({
          job: this.job.id,
          category1: department,
          category2: "Expense Variable",
          category3: "Cash",
          category4: "Bank Related",
          category5: "Bank Charge",
          paymentmethod: "Bank Transfer",
          bankaccount: "Boubyan",
          expenseheading: "No",
          id: 0,
          title: "",
        });
      } else {
        this.payments.push({
          job: this.job.id,
          category1: department,
          category2: "",
          category3: "Cash",
          category4: "",
          category5: "",
          paymentmethod: "",
          bankaccount: "",
          expenseheading: "No",
          id: 0,
          title: "",
        });
      }
    } else if (type === "delete") {
      if (this.payments[index].id === 0) {
        this.payments.splice(index, 1);
      } else {
        return instance
          .delete("/d-payment/" + this.payments[index].id + "/")
          .then((res) => res.data)
          .then((events) => {
            // console.log("successfully added");
            this.payments.splice(index, 1);
            alert(`Payment Deleted.`);
          })
          .catch((err) => {
            let message = {
              activity: "Deleting a Payment",
              ClientName: this.client.title,
              ClientID: this.client.id,
              JobID: this.job.id,
              JobMain: this.job.jobmain,
              Status: this.job.status,
              Data: "Paymnent ID = " + this.payments[index].id,
              error: err.response,
            };
            settingStore.sendUnifiedEmail(message, "error");
            alert(
              "An error occurred. The data did NOT save. Please contact me (97666886)." +
                "\n" +
                JSON.stringify(err.response)
            );
            console.error(err.response);
          });
      }
    } else {
      this.updateitems.payment = true;
      if (type === "paymentdate") this.payments[index].paymentdate = val;
      if (type === "category1") this.payments[index].category1 = val;
      if (type === "category2") this.payments[index].category2 = val;
      if (type === "category3") this.payments[index].category3 = val;
      if (type === "category4") this.payments[index].category4 = val;
      if (type === "category5") this.payments[index].category5 = val;
      if (type === "paymenttype") this.payments[index].paymenttype = val;
      if (type === "paymentdetail") this.payments[index].paymentdetail = val;
      if (type === "remarks") this.payments[index].remarks = val;
      if (type === "paymentmethod") this.payments[index].paymentmethod = val;
      if (type === "bankaccount") this.payments[index].bankaccount = val;
      if (type === "invoiceamount") this.payments[index].invoiceamount = val;
      if (type === "invoicenumber") this.payments[index].invoicenumber = val;
      if (type === "vouchernumber") this.payments[index].vouchernumber = val;
      if (type === "expenseheading") this.payments[index].expenseheading = val;
    }
  }

  updateAddCharge(type, val, index) {
    this.updateitems.job = true;
    if (type === "add") {
      this.updateitems.addcharge = true;
      this.addcharge.push({
        id: 0,
        title: "",
        price: 0,
        order: 0,
        job: this.job.id,
      });
    } else if (type === "delete") {
      if (this.addcharge[index].id === 0) {
        this.addcharge.splice(index, 1);
      } else {
        return instance
          .delete("/d-addcharge/" + this.addcharge[index].id + "/")
          .then((res) => res.data)
          .then((events) => {
            // console.log("successfully added");
            this.addcharge.splice(index, 1);
            alert(`Additional Charge Deleted.`);
          })
          .catch((err) => {
            let message = {
              activity: "Deleting an Additional Charge",
              ClientName: this.client.title,
              ClientID: this.client.id,
              JobID: this.job.id,
              JobMain: this.job.jobmain,
              Status: this.job.status,
              Data: "Add Charge ID = " + this.addcharge[index].id,
              error: err.response,
            };
            settingStore.sendUnifiedEmail(message, "error");
            alert(
              "An error occurred. The data did NOT save. Please contact me (97666886)." +
                "\n" +
                JSON.stringify(err.response)
            );
            console.error(err.response);
          });
      }
    } else {
      this.updateitems.addcharge = true;
      if (type === "title") this.addcharge[index].title = val;
      if (type === "price") this.addcharge[index].price = val;
    }
  }
  updateAddress(type, val, index) {
    this.updateitems.job = true;
    if (type === "add") {
      this.updateitems.address = true;
      this.addresses.push({
        id: 0,
        locationfrom: "",
        locationto: "",
        order: 0,
        typefrom: "",
        typeto: "",
        job: this.job.id,
      });
    } else if (type === "delete") {
      if (this.addresses[index].id === 0) {
        this.addresses.splice(index, 1);
      } else {
        return instance
          .delete("/d-address/" + this.addresses[index].id + "/")
          .then((res) => res.data)
          .then((events) => {
            // console.log("successfully added");
            this.addresses.splice(index, 1);
            alert(`Address Deleted.`);
          })
          .catch((err) => {
            let message = {
              activity: "Deleting an Address",
              ClientName: this.client.title,
              ClientID: this.client.id,
              JobID: this.job.id,
              JobMain: this.job.jobmain,
              Status: this.job.status,
              Data: "Address ID = " + this.addresses[index].id,
              error: err.response,
            };
            settingStore.sendUnifiedEmail(message, "error");
            alert(
              "An error occurred. The data did NOT save. Please contact me (97666886)." +
                "\n" +
                JSON.stringify(err.response)
            );
            console.error(err.response);
          });
      }
    } else {
      this.updateitems.address = true;
      if (type === "locationfrom") this.addresses[index].locationfrom = val;
      if (type === "locationto") this.addresses[index].locationto = val;
      if (type === "order") this.addresses[index].order = val;
      if (type === "typefrom") this.addresses[index].typefrom = val;
      if (type === "typeto") this.addresses[index].typeto = val;
    }
  }

  updateCustomProposal(type, val) {
    if (type === "reset") {
      this.customproposal = {
        title: "",
        body: "",
        cost: 0,
      };
    } else if (type === "title") {
      this.customproposal.title = val;
    } else if (type === "body") {
      this.customproposal.body = val;
    } else if (type === "cost") {
      this.customproposal.cost = val;
    }
  }

  // Post Update
  postJob() {
    if (this.updateitems.job) {
      let data = {
        status: this.job.status,
        leaddate: null,
        proposaldate: null,
        bookeddate: null,
        converteddate: null,
        estimationdate: null,
        feedback: this.job.feedback,
        rating: this.job.rating,
        remarks: this.job.remarks,
        cancellationremarks: this.job.cancellationremarks,
        cancellationreason: this.job.cancellationreason,
        discountvalue: this.job.discountvalue,
        discountname: this.job.discountname,
        companygroup: this.job.companygroup,
        firstprice: this.job.firstprice,
        lastprice: this.job.lastprice,
        jobmain: this.job.jobmain,
        jobsecond: this.job.jobsecond,
        cbm: this.job.cbm,
        weight: this.job.weight,
        teamLeader: this.job.teamLeader,
        estCheck: this.job.estCheck,
        cancCheck: this.job.cancCheck,
        proposalsentcheck: this.job.proposalsentcheck,
        estimationType: this.job.estimationType,
        service: this.job.service,
        actualcbm: this.job.actualcbm,
        actualweight: this.job.actualweight,
        teammember: this.job.teammember,
        emfocalpoint: this.job.emfocalpoint,
        followupurgency: this.job.followupurgency,
        serviceslist: this.job.serviceslist,
        excludedserviceslist: this.job.excludedserviceslist,
        includeservice: this.job.includeservice,
        excludeservice: this.job.excludeservice,
        lastupdate: moment(new Date()).format("YYYY-MM-DD"),
        userupdated: userStore.user.username,
        profit: this.profitTotal,

        id: this.job.id,
        // client: this.job.client,
      };

      if ((this.job.lastprice === 0) & (this.job.firstprice > 0)) {
        data.lastprice = this.job.firstprice;
      }
      if ((this.job.firstprice === 0) & (this.job.lastprice > 0)) {
        data.firstprice = this.job.lastprice;
      }

      if (this.job.leaddate !== null) {
        data.leaddate = moment(this.job.leaddate).format("YYYY-MM-DD");
      }
      if (this.job.proposaldate !== null) {
        data.proposaldate = moment(this.job.proposaldate).format("YYYY-MM-DD");
      }
      if (this.job.bookeddate !== null) {
        data.bookeddate = moment(this.job.bookeddate).format("YYYY-MM-DD");
      }
      if (this.job.estimationdate !== null) {
        data.estimationdate = moment(this.job.estimationdate).format(
          "YYYY-MM-DD"
        );
      }
      if (this.job.converteddate !== null) {
        data.converteddate = moment(this.job.converteddate).format(
          "YYYY-MM-DD"
        );
      }
      if (this.job.cancellationdate !== null) {
        data.cancellationdate = moment(this.job.cancellationdate).format(
          "YYYY-MM-DD"
        );
      }
      if (data.cbm === null || data.cbm === "") {
        data.cbm = 0;
      }
      if (data.lastprice === null || data.lastprice === "") {
        data.lastprice = 0;
      }
      if (data.firstprice === null || data.firstprice === "") {
        data.firstprice = 0;
      }

      if (data.profit === null) {
        data.profit = 0;
      }

      instance
        .put("/job/" + this.job.id + "/", data)
        .then((res) => res.data)
        .then((events) => {
          if (this.updateitems.addcharge) {
            for (let i = 0; i < this.addcharge.length; i++) {
              this.postAddCharge(i);
            }
          }
          if (this.updateitems.address) {
            for (let i = 0; i < this.addresses.length; i++) {
              this.postAddress(i);
            }
          }
          if (this.updateitems.jobdate) {
            for (let i = 0; i < this.jobdates.length; i++) {
              this.postJobDate(i);
            }
          }
          if (this.updateitems.storage) {
            for (let i = 0; i < this.storages.length; i++) {
              this.postStorage(i);
            }
          }
          if (this.updateitems.payment) {
            for (let i = 0; i < this.payments.length; i++) {
              this.postPayment(i);
            }
          }
          if (this.updateitems.imtracker) {
            for (let i = 0; i < this.imtracker.length; i++) {
              this.postTrack(i);
            }
          }
          if (this.updateitems.intquote) {
            for (let i = 0; i < this.intquote.length; i++) {
              this.intquote[i].job = this.job.id;
              if (
                (this.intquote[i].company !== "") &
                (this.intquote[i].company !== null)
              ) {
                this.postIntQuote(i);
              }
            }
          }
          if (this.updateitems.room) {
            for (let i = 0; i < this.rooms.length; i++) {
              this.postRoom(i);
            }
          }
          if (this.updateitems.client) this.postClient();
          if (this.updateitems.intfee) this.postIntFee();
          // console.log("successfully added");
          alert(`Job #${events.id} Details Updated.`);
        })
        .then((events) => {
          this.closeJobTabs();
        })
        .catch((err) => {
          let message = {
            activity: "Updating a Job",
            ClientName: this.client.title,
            ClientID: this.client.id,
            JobID: this.job.id,
            JobMain: this.job.jobmain,
            Status: this.job.status,
            Data: data,
            error: err.response,
          };
          settingStore.sendUnifiedEmail(message, "error");
          alert(
            "An error occurred. The data did NOT save. Please contact me (97666886)." +
              "\n" +
              JSON.stringify(err.response)
          );
          console.error(err.response);
        });
    }
  }

  postClient() {
    let data = this.client;
    return instance
      .put("/client/" + data.id + "/", data)
      .then((res) => res.data)
      .then((events) => {
        alert(`Client #${data.id}  Updated.`);
      })
      .catch((err) => {
        let message = {
          activity: "Updating a Client",
          ClientName: this.client.title,
          ClientID: this.client.id,
          JobID: this.job.id,
          JobMain: this.job.jobmain,
          Status: this.job.status,
          Data: data,
          error: err.response,
        };
        settingStore.sendUnifiedEmail(message, "error");
        alert(
          "An error occurred. The data did NOT save. Please contact me (97666886)." +
            "\n" +
            JSON.stringify(err.response)
        );
        console.error(err.response);
      });
  }

  postIntQuote(index) {
    let data = this.intquote[index];

    if (data.id === 0) {
      return instance
        .post("/c-intquote/", data)
        .then((res) => res.data)
        .then((events) => {
          alert(`Quote Created.`);
        })
        .catch((err) => {
          let message = {
            activity: "Creating a New Quote",
            ClientName: this.client.title,
            ClientID: this.client.id,
            JobID: this.job.id,
            JobMain: this.job.jobmain,
            Status: this.job.status,
            Data: data,
            error: err.response,
          };
          settingStore.sendUnifiedEmail(message, "error");
          alert(
            "An error occurred. The data did NOT save. Please contact me (97666886)." +
              "\n" +
              JSON.stringify(err.response)
          );
          console.error(err.response);
        });
    } else {
      return instance
        .put("/intquote/" + data.id + "/", data)
        .then((res) => res.data)
        .then((events) => {
          alert(`Quote #${data.id} Updated.`);
        })
        .catch((err) => {
          let message = {
            activity: "Updating a Quote",
            ClientName: this.client.title,
            ClientID: this.client.id,
            JobID: this.job.id,
            JobMain: this.job.jobmain,
            Status: this.job.status,
            Data: data,
            error: err.response,
          };
          settingStore.sendUnifiedEmail(message, "error");
          alert(
            "An error occurred. The data did NOT save. Please contact me (97666886)." +
              "\n" +
              JSON.stringify(err.response)
          );
          console.error(err.response);
        });
    }
  }
  postTrack(index) {
    let data = this.imtracker[index];

    if (data.originaldate !== null)
      data.originaldate = moment(data.originaldate).format("YYYY-MM-DD");
    if (data.reviseddate !== null)
      data.reviseddate = moment(data.reviseddate).format("YYYY-MM-DD");

    if (data.id === 0) {
      return instance
        .post("/c-imtracker/", data)
        .then((res) => res.data)
        .then((events) => {
          alert(`Track Created.`);
        })
        .catch((err) => {
          let message = {
            activity: "Creating an IM Track",
            ClientName: this.client.title,
            ClientID: this.client.id,
            JobID: this.job.id,
            JobMain: this.job.jobmain,
            Status: this.job.status,
            Data: data,
            error: err.response,
          };
          settingStore.sendUnifiedEmail(message, "error");
          alert(
            "An error occurred. The data did NOT save. Please contact me (97666886)." +
              "\n" +
              JSON.stringify(err.response)
          );
          console.error(err.response);
        });
    } else {
      return instance
        .put("/imtracker/" + data.id + "/", data)
        .then((res) => res.data)
        .then((events) => {
          alert(`Track #${data.id} Updated.`);
        })
        .catch((err) => {
          let message = {
            activity: "Updating an IM Track",
            ClientName: this.client.title,
            ClientID: this.client.id,
            JobID: this.job.id,
            JobMain: this.job.jobmain,
            Status: this.job.status,
            Data: data,
            error: err.response,
          };
          settingStore.sendUnifiedEmail(message, "error");
          alert(
            "An error occurred. The data did NOT save. Please contact me (97666886)." +
              "\n" +
              JSON.stringify(err.response)
          );
          console.error(err.response);
        });
    }
  }

  postPayment(index) {
    let data = this.payments[index];

    if (data.paymentdate !== null)
      data.paymentdate = moment(data.paymentdate).format("YYYY-MM-DD");

    if (data.id === 0) {
      return instance
        .post("/c-payment/", data)
        .then((res) => res.data)
        .then((events) => {
          alert(`Payment Created.`);
        })
        .catch((err) => {
          let message = {
            activity: "Creating a new Payment",
            ClientName: this.client.title,
            ClientID: this.client.id,
            JobID: this.job.id,
            JobMain: this.job.jobmain,
            Status: this.job.status,
            Data: data,
            error: err.response,
          };
          settingStore.sendUnifiedEmail(message, "error");
          alert(
            "An error occurred. The data did NOT save. Please contact me (97666886)." +
              "\n" +
              JSON.stringify(err.response)
          );
          console.error(err.response);
        });
    } else {
      return instance
        .put("/payment/" + data.id + "/", data)
        .then((res) => res.data)
        .then((events) => {
          alert(`Payment #${data.id} Updated.`);
        })
        .catch((err) => {
          let message = {
            activity: "Updating a Payment",
            ClientName: this.client.title,
            ClientID: this.client.id,
            JobID: this.job.id,
            JobMain: this.job.jobmain,
            Status: this.job.status,
            Data: data,
            error: err.response,
          };
          settingStore.sendUnifiedEmail(message, "error");
          alert(
            "An error occurred. The data did NOT save. Please contact me (97666886)." +
              "\n" +
              JSON.stringify(err.response)
          );
          console.error(err.response);
        });
    }
  }
  postStorage(index) {
    let data = this.storages[index];

    if (data.storagestartdate !== null)
      data.storagestartdate = moment(data.storagestartdate).format(
        "YYYY-MM-DD"
      );
    if (data.storageenddate !== null)
      data.storageenddate = moment(data.storageenddate).format("YYYY-MM-DD");
    if (data.storagepaidto !== null)
      data.storagepaidto = moment(data.storagepaidto).format("YYYY-MM-DD");

    if (data.id === 0) {
      return instance
        .post("/c-storage/", data)
        .then((res) => res.data)
        .then((events) => {
          alert(`Storage Job Created.`);
        })
        .catch((err) => {
          let message = {
            activity: "Creating a New Storage",
            ClientName: this.client.title,
            ClientID: this.client.id,
            JobID: this.job.id,
            JobMain: this.job.jobmain,
            Status: this.job.status,
            Data: data,
            error: err.response,
          };
          settingStore.sendUnifiedEmail(message, "error");
          alert(
            "An error occurred. The data did NOT save. Please contact me (97666886)." +
              "\n" +
              JSON.stringify(err.response)
          );
          console.error(err.response);
        });
    } else {
      return instance
        .put("/storage/" + data.id + "/", data)
        .then((res) => res.data)
        .then((events) => {
          alert(`Storage Job #${data.id}  Updated.`);
        })
        .catch((err) => {
          let message = {
            activity: "Updating a Storage",
            ClientName: this.client.title,
            ClientID: this.client.id,
            JobID: this.job.id,
            JobMain: this.job.jobmain,
            Status: this.job.status,
            Data: data,
            error: err.response,
          };
          settingStore.sendUnifiedEmail(message, "error");
          alert(
            "An error occurred. The data did NOT save. Please contact me (97666886)." +
              "\n" +
              JSON.stringify(err.response)
          );
          console.error(err.response);
        });
    }
  }

  postIntFee() {
    let data = this.internationalfee;
    if (data.id === 0) {
      return instance
        .post("/c-intfee/", data)
        .then((res) => res.data)
        .then((events) => {
          alert(`International Fees Added.`);
        })
        .catch((err) => {
          let message = {
            activity: "Create an IM Fee",
            ClientName: this.client.title,
            ClientID: this.client.id,
            JobID: this.job.id,
            JobMain: this.job.jobmain,
            Status: this.job.status,
            Data: data,
            error: err.response,
          };
          settingStore.sendUnifiedEmail(message, "error");
          alert(
            "An error occurred. The data did NOT save. Please contact me (97666886)." +
              "\n" +
              JSON.stringify(err.response)
          );
          console.error(err.response);
        });
    } else {
      return instance
        .put("/intfee/" + data.id + "/", data)
        .then((res) => res.data)
        .then((events) => {
          alert(`International Fees Updated.`);
        })
        .catch((err) => {
          let message = {
            activity: "Updating an IM Fee",
            ClientName: this.client.title,
            ClientID: this.client.id,
            JobID: this.job.id,
            JobMain: this.job.jobmain,
            Status: this.job.status,
            Data: data,
            error: err.response,
          };
          settingStore.sendUnifiedEmail(message, "error");
          alert(
            "An error occurred. The data did NOT save. Please contact me (97666886)." +
              "\n" +
              JSON.stringify(err.response)
          );
          console.error(err.response);
        });
    }
  }

  postJobDate(index) {
    let data = this.jobdates[index];
    if (data.id === 0) {
      return instance
        .post("/c-jobdate/", data)
        .then((res) => res.data)
        .then((events) => {
          alert(`Job Date Created.`);
        })
        .catch((err) => {
          let message = {
            activity: "Creating a new Job Date",
            ClientName: this.client.title,
            ClientID: this.client.id,
            JobID: this.job.id,
            JobMain: this.job.jobmain,
            Status: this.job.status,
            Data: data,
            error: err.response,
          };
          settingStore.sendUnifiedEmail(message, "error");
          alert(
            "An error occurred. The data did NOT save. Please contact me (97666886)." +
              "\n" +
              JSON.stringify(err.response)
          );
          console.error(err.response);
        });
    } else {
      return instance
        .put("/jobdate/" + data.id + "/", data)
        .then((res) => res.data)
        .then((events) => {
          alert(`Job Date #${data.id}  Updated.`);
        })
        .catch((err) => {
          let message = {
            activity: "Updating a Job Date",
            ClientName: this.client.title,
            ClientID: this.client.id,
            JobID: this.job.id,
            JobMain: this.job.jobmain,
            Status: this.job.status,
            Data: data,
            error: err.response,
          };
          settingStore.sendUnifiedEmail(message, "error");
          alert(
            "An error occurred. The data did NOT save. Please contact me (97666886)." +
              "\n" +
              JSON.stringify(err.response)
          );
          console.error(err.response);
        });
    }
  }
  postAddress(index) {
    let data = this.addresses[index];
    if (data.id === 0) {
      return instance
        .post("/c-address/", data)
        .then((res) => res.data)
        .then((events) => {
          alert(`Address Created.`);
        })
        .catch((err) => {
          let message = {
            activity: "Creating a New Address",
            ClientName: this.client.title,
            ClientID: this.client.id,
            JobID: this.job.id,
            JobMain: this.job.jobmain,
            Status: this.job.status,
            Data: data,
            error: err.response,
          };
          settingStore.sendUnifiedEmail(message, "error");
          alert(
            "An error occurred. The data did NOT save. Please contact me (97666886)." +
              "\n" +
              JSON.stringify(err.response)
          );
          console.error(err.response);
        });
    } else {
      return instance
        .put("/address/" + data.id + "/", data)
        .then((res) => res.data)
        .then((events) => {
          alert(`Address #${data.id}  Updated.`);
        })
        .catch((err) => {
          let message = {
            activity: "Updating an Address",
            ClientName: this.client.title,
            ClientID: this.client.id,
            JobID: this.job.id,
            JobMain: this.job.jobmain,
            Status: this.job.status,
            Data: data,
            error: err.response,
          };
          settingStore.sendUnifiedEmail(message, "error");
          alert(
            "An error occurred. The data did NOT save. Please contact me (97666886)." +
              "\n" +
              JSON.stringify(err.response)
          );
          console.error(err.response);
        });
    }
  }
  postNewItem(data) {
    if (data.cbm === "") data.cbm = 0;
    if (data.length === "") data.length = 0;
    if (data.width === "") data.width = 0;
    if (data.weight === "") data.weight = 0;
    if (data.height === "") data.height = 0;
    if (data.quantity === "") data.quantity = 1;

    if (data.id === 0) {
      instance
        .post("/c-item/", data)
        .then((res) => res.data)
        .then((events) => {
          console.log("item added");
        })
        .catch((err) => {
          let message = {
            activity: "Creating a New Item",
            ClientName: this.client.title,
            ClientID: this.client.id,
            JobID: this.job.id,
            JobMain: this.job.jobmain,
            Status: this.job.status,
            Data: data,
            error: err.response,
          };
          settingStore.sendUnifiedEmail(message, "error");
          alert(
            "An error occurred. The data did NOT save. Please contact me (97666886)." +
              "\n" +
              JSON.stringify(err.response)
          );
          console.error(err.response);
        });
    } else {
      instance
        .put("/item/" + data.id + "/", data)
        .then((res) => res.data)
        .then((events) => {
          console.log("item updated");
        })
        .catch((err) => {
          let message = {
            activity: "Updating an Item",
            ClientName: this.client.title,
            ClientID: this.client.id,
            JobID: this.job.id,
            JobMain: this.job.jobmain,
            Status: this.job.status,
            Data: data,
            error: err.response,
          };
          settingStore.sendUnifiedEmail(message, "error");
          alert(
            "An error occurred. The data did NOT save. Please contact me (97666886)." +
              "\n" +
              JSON.stringify(err.response)
          );
          console.error(err.response);
        });
    }
  }
  postRoom(index) {
    let data = this.rooms[index];
    if (data.id === 0) {
      return instance
        .post("/c-room/", data)
        .then((res) => res.data)
        .then((events) => {
          if (data.item.length > 0) {
            for (let i = 0; i < data.item.length; i++) {
              let data2 = {
                id: data.item[i].id,
                title: data.item[i].title,
                cbm: data.item[i].cbm,
                length: data.item[i].length,
                quantity: data.item[i].quantity,
                width: data.item[i].width,
                height: data.item[i].height,
                weight: data.item[i].weight,
                remark: data.item[i].remark,
                status: data.item[i].status,
                room: events.id,
              };
              this.postNewItem(data2);
            }
          }
          alert(`Room Created.`);
        })
        .catch((err) => {
          let message = {
            activity: "Create a New Room",
            ClientName: this.client.title,
            ClientID: this.client.id,
            JobID: this.job.id,
            JobMain: this.job.jobmain,
            Status: this.job.status,
            Data: data,
            error: err.response,
          };
          settingStore.sendUnifiedEmail(message, "error");
          alert(
            "An error occurred. The data did NOT save. Please contact me (97666886)." +
              "\n" +
              JSON.stringify(err.response)
          );
          console.error(err.response);
        });
    } else {
      return instance
        .put("/room/" + data.id + "/", data)
        .then((res) => res.data)
        .then((events) => {
          if (data.item.length > 0) {
            for (let i = 0; i < data.item.length; i++) {
              let data2 = {
                id: data.item[i].id,
                title: data.item[i].title,
                cbm: data.item[i].cbm,
                length: data.item[i].length,
                quantity: data.item[i].quantity,
                width: data.item[i].width,
                height: data.item[i].height,
                weight: data.item[i].weight,
                remark: data.item[i].remark,
                status: data.item[i].status,
                room: events.id,
              };
              this.postNewItem(data2);
            }
          }
          alert(`Room Updated.`);
        })
        .catch((err) => {
          let message = {
            activity: "Updating a Room",
            ClientName: this.client.title,
            ClientID: this.client.id,
            JobID: this.job.id,
            JobMain: this.job.jobmain,
            Status: this.job.status,
            Data: data,
            error: err.response,
          };
          settingStore.sendUnifiedEmail(message, "error");
          alert(
            "An error occurred. The data did NOT save. Please contact me (97666886)." +
              "\n" +
              JSON.stringify(err.response)
          );
          console.error(err.response);
        });
    }
  }
  postAddCharge(index) {
    let data = this.addcharge[index];
    if (data.id === 0) {
      return instance
        .post("/c-addcharge/", data)
        .then((res) => res.data)
        .then((events) => {
          alert(`Additional Charge Created.`);
        })
        .catch((err) => {
          let message = {
            activity: "Creating an Add Charge",
            ClientName: this.client.title,
            ClientID: this.client.id,
            JobID: this.job.id,
            JobMain: this.job.jobmain,
            Status: this.job.status,
            Data: data,
            error: err.response,
          };
          settingStore.sendUnifiedEmail(message, "error");
          alert(
            "An error occurred. The data did NOT save. Please contact me (97666886)." +
              "\n" +
              JSON.stringify(err.response)
          );
          console.error(err.response);
        });
    } else {
      return instance
        .put("/addcharge/" + data.id + "/", data)
        .then((res) => res.data)
        .then((events) => {
          alert(`Additional Charge #${data.id}  Updated.`);
        })
        .catch((err) => {
          let message = {
            activity: "Updating an Add Charge",
            ClientName: this.client.title,
            ClientID: this.client.id,
            JobID: this.job.id,
            JobMain: this.job.jobmain,
            Status: this.job.status,
            Data: data,
            error: err.response,
          };
          settingStore.sendUnifiedEmail(message, "error");
          alert(
            "An error occurred. The data did NOT save. Please contact me (97666886)." +
              "\n" +
              JSON.stringify(err.response)
          );
          console.error(err.response);
        });
    }
  }

  // Fetching Data
  fetchJob(id) {
    if (this.editclient) {
      alert(
        "Please Save or Cancel changes with the open client before choosing a new client."
      );
    } else {
      this.jobID = id;
      this.job = {};
      this.internationalfee = {
        thcharge: 0,
        docharge: 0,
        originclearance: 0,
        unloadingcharge: 0,
        unpackingcharge: 0,
        assemblingcharge: 0,
        debrisremoval: 0,
        containerreturn: 0,
        intwrapandpack: 0,
        shippingcharges: 0,
        transitclearance: 0,
        destinationclearance: 0,
        firstmilecharge: 0,
        lastmilecharge: 0,
        cratecharge: 0,
        palletcharge: 0,
        dgrcharge: 0,
        dghandling: 0,
        detentioncharge: 0,
        demoragecharge: 0,
        shippingline: 0,
        originstorageinout: 0,
        destinationstorageinout: 0,
        shuttlecharge: 0,
        containerType: "",
        shippingmethod: "",
        imlength: 0,
        imwidth: 0,
        imheight: 0,
        transportationcharges: 0,
        loadingcharge: 0,
        commodity: "",
        id: 0,
      };
      this.storages = [];
      this.material = {};
      this.jobdates = [];
      this.addresses = [];
      this.payments = [];
      this.addcharge = [];
      this.intquote = [];
      this.rooms = [];
      this.items = [];
      this.updateitems = {
        job: false,
        addcharge: false,
        client: false,
        address: false,
        jobdate: false,
        storage: false,
        intfee: false,
        room: false,
        payment: false,
        imtracker: false,
        intquote: false,
      };

      if (id > 0) {
        instance
          .get("/job/" + id + "/?format=json")
          .then((res) => res.data)
          .then((job) => {
            if (!job.excludeservice) {
              job.excludeservice = "";
            }
            if (!job.includeservice) {
              job.includeservice = "";
            }
            this.job = job;
            this.fetchClient(job.client);
            this.oldstatus = job.status;
          })
          .then((job) => {
            this.fetchStorage(id);
            this.fetchJobDate(id);
            this.fetchAddress(id);
            this.fetchPayment(id);
            this.fetchAddCharge(id);
            this.fetchIMTracker(id);
            this.fetchRooms(id);
            this.fetchIntQuote(id);
            this.fetchIntFee(id);
          })
          .then((job) => (this.editclient = true))
          .catch((err) => {
            let message = {
              activity: "Fetching Job Error",
              ClientName: this.client.title,
              ClientID: this.client.id,
              JobID: this.job.id,
              JobMain: this.job.jobmain,
              Status: this.job.status,
              Data: id,
              error: err.response,
            };
            settingStore.sendUnifiedEmail(message, "error");
            alert(
              "An error occurred. The data did NOT save. Please contact me (97666886)." +
                "\n" +
                JSON.stringify(err.response)
            );
            console.error(err.response);
          });
      }
    }
  }
  fetchClient(id) {
    instance
      .get("/client/" + id + "/?format=json")
      .then((res) => res.data)
      .then((clientdata) => {
        this.client = clientdata;
        console.log("client received");
      });
  }
  fetchStorage(jobid) {
    instance
      .get("/storagejob/" + jobid + "/?format=json")
      .then((res) => res.data)
      .then((storages) => {
        this.storages = storages;
        console.log("storage received");
      });
  }
  fetchJobDate(jobid) {
    instance
      .get("/jobdatejob/" + jobid + "/?format=json")
      .then((res) => res.data)
      .then((jobdates) => {
        this.jobdates = jobdates;
        console.log("job dates received");
      });
  }
  fetchAddress(jobid) {
    instance
      .get("/addressbyjob/" + jobid + "/?format=json")
      .then((res) => res.data)
      .then((addresses) => {
        this.addresses = addresses;
        console.log("address received");
      });
  }
  fetchPayment(jobid) {
    instance
      .get("/paymentjob/" + jobid + "/?format=json")
      .then((res) => res.data)
      .then((payments) => {
        this.payments = payments;
        console.log("payment job received");
      });
  }
  fetchAddCharge(jobid) {
    instance
      .get("/addchargejob/" + jobid + "/?format=json")
      .then((res) => res.data)
      .then((addcharge) => {
        this.addcharge = addcharge;
        console.log("addcharge received");
      });
  }
  fetchIMTracker(jobid) {
    instance
      .get("/imtrackerjob/" + jobid + "/?format=json")
      .then((res) => res.data)
      .then((imtracker) => {
        this.imtracker = imtracker;
        console.log("imtracker received");
      });
  }
  fetchRooms(jobid) {
    instance
      .get("/roomjob/" + jobid + "/?format=json")
      .then((res) => res.data)
      .then((rooms) => {
        this.rooms = rooms;
        console.log("rooms received");
      });
  }
  fetchIntQuote(jobid) {
    instance
      .get("/intquotejob/" + jobid + "/?format=json")
      .then((res) => res.data)
      .then((intquote) => {
        this.intquote = intquote;
        console.log("intquote received");
      });
  }
  fetchIntFee(jobid) {
    instance
      .get("/intfeejob/" + jobid + "/?format=json")
      .then((res) => res.data)
      .then((internationalfee) => {
        this.internationalfee = {
          thcharge: 0,
          docharge: 0,
          originclearance: 0,
          unloadingcharge: 0,
          unpackingcharge: 0,
          assemblingcharge: 0,
          debrisremoval: 0,
          containerreturn: 0,
          intwrapandpack: 0,
          shippingcharges: 0,
          transitclearance: 0,
          destinationclearance: 0,
          firstmilecharge: 0,
          lastmilecharge: 0,
          cratecharge: 0,
          palletcharge: 0,
          dgrcharge: 0,
          dghandling: 0,
          detentioncharge: 0,
          demoragecharge: 0,
          shippingline: 0,
          originstorageinout: 0,
          destinationstorageinout: 0,
          shuttlecharge: 0,
          containerType: "",
          shippingmethod: "",
          imlength: 0,
          imwidth: 0,
          imheight: 0,
          transportationcharges: 0,
          loadingcharge: 0,
          commodity: "",
          id: 0,
          job: jobid,
        };

        if (internationalfee.length > 0) {
          this.internationalfee = internationalfee[0];
        }
      });
  }

  // Computed Lists ---------

  get saveDataCheck() {
    if (this.updateitems.job) return true;
    if (this.updateitems.client) return true;
    if (this.updateitems.address) return true;
    if (this.updateitems.jobdate) return true;
    if (this.updateitems.storage) return true;
    if (this.updateitems.intfee) return true;
    if (this.updateitems.payment) return true;
    if (this.updateitems.imtracker) return true;
    if (this.updateitems.addcharge) return true;
    if (this.updateitems.room) return true;
    if (this.updateitems.intquote) return true;
    return false;
  }

  get totalstorageprice() {
    let storageprice = 0;
    for (let j = 0; j < this.storages.length; j++) {
      storageprice +=
        parseFloat(this.storages[j].monthlycost) *
        Math.round(
          moment(this.storages[j].storageenddate).diff(
            moment(this.storages[j].storagestartdate),
            "months",
            true
          )
        );
    }
    return Math.round(1000 * storageprice) / 1000;
  }

  get addchargeprice() {
    let acprice = 0;
    for (let i = 0; i < this.addcharge.length; i++) {
      acprice += parseFloat(this.addcharge[i].price);
    }
    return Math.round(1000 * acprice) / 1000;
  }

  get totaljobprice() {
    return (
      Math.round(
        1000 *
          (parseFloat(this.job.lastprice) +
            parseFloat(this.addchargeprice) +
            parseFloat(this.totalstorageprice))
      ) / 1000
    );
  }

  get imSection2Cost() {
    return (
      Math.round(
        1000 *
          (parseFloat(this.internationalfee.intwrapandpack) +
            parseFloat(this.internationalfee.cratecharge) +
            parseFloat(this.internationalfee.palletcharge) +
            parseFloat(this.internationalfee.loadingcharge) +
            parseFloat(this.internationalfee.transportationcharges) +
            parseFloat(this.internationalfee.originstorageinout) +
            parseFloat(this.internationalfee.originclearance))
      ) / 1000
    );
  }
  get imSection3Cost() {
    return (
      Math.round(
        1000 *
          (parseFloat(this.internationalfee.shippingcharges) +
            parseFloat(this.internationalfee.transitclearance))
      ) / 1000
    );
  }
  get imSection4Cost() {
    return (
      Math.round(
        1000 *
          (parseFloat(this.internationalfee.docharge) +
            parseFloat(this.internationalfee.thcharge) +
            parseFloat(this.internationalfee.destinationclearance) +
            parseFloat(this.internationalfee.shuttlecharge) +
            parseFloat(this.internationalfee.demoragecharge) +
            parseFloat(this.internationalfee.detentioncharge) +
            parseFloat(this.internationalfee.destinationstorageinout) +
            parseFloat(this.internationalfee.unloadingcharge) +
            parseFloat(this.internationalfee.assemblingcharge) +
            parseFloat(this.internationalfee.debrisremoval) +
            parseFloat(this.internationalfee.containerreturn))
      ) / 1000
    );
  }
  get imSection5Cost() {
    return (
      Math.round(
        1000 *
          (parseFloat(editJobStore.internationalfee.dgrcharge) +
            parseFloat(editJobStore.internationalfee.dghandling))
      ) / 1000
    );
  }

  get totalPaid() {
    let paid = 0;

    for (let i = 0; i < this.payments.length; i++) {
      if (this.payments[i].category2 === "Income") {
        paid += parseFloat(this.payments[i].invoiceamount);
      }
    }
    return Math.round(1000 * paid) / 1000;
  }

  get totalOutstanding() {
    return Math.round(1000 * (this.totaljobprice - this.totalPaid)) / 1000;
  }

  get profitTotal() {
    let profit = this.job.lastprice;
    let calcprofit =
      Math.round(
        1000 *
          (this.totaljobprice -
            (this.imSection2Cost +
              this.imSection3Cost +
              this.imSection4Cost +
              this.imSection5Cost))
      ) / 1000;
    if (profit === null) {
      profit = 0;
    }

    if (calcprofit === null || calcprofit === "") {
      return profit;
    }

    return calcprofit;
  }
}

decorate(EditJobStore, {
  clientID: observable,
  jobID: observable,
  job: observable,
  jobdates: observable,
  storages: observable,
  singlejob: observable,
  loading: observable,
  showJobs: observable,
  showModal: observable,
  customproposal: observable,
  Modalloading: observable,
  editclient: observable,
  addcharge: observable,
  client: observable,
  addresses: observable,
  payments: observable,
  internationalfee: observable,
  intquote: observable,
  rooms: observable,
  imtracker: observable,
  selectedIMQuote: observable,
  updateitems: observable,
  saveDataCheck: computed,
  totalstorageprice: computed,
  addchargeprice: computed,
  totaljobprice: computed,
  imSection2Cost: computed,
  imSection3Cost: computed,
  imSection4Cost: computed,
  imSection5Cost: computed,
  profitTotal: computed,
  totalPaid: computed,
  totalOutstanding: computed,
});

const editJobStore = new EditJobStore();

export default editJobStore;
