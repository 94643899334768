import React, { Component } from "react";
import { observer } from "mobx-react";

import { Button } from "react-bootstrap";
import MaterialTable from "material-table";
import "react-datepicker/dist/react-datepicker.css";

import inventoryStore from "../Stores/InventoryStore";

class InventoryHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editPayment: false,
      financialsLoaded: false,
      modalShow: false,
      indexVal: 0,
      filter: false
    };
  }

  render() {
    if (inventoryStore.loading) {
      return <div>Please Wait the Page is Loading</div>;
    }

    let columnnames = [
      {
        title: "Item",
        field: "material",
        removable: true,
        defaultGroupOrder: 0
      },
      { title: "Qty", field: "balance", removable: true },
      { title: "Source", field: "otheruser", removable: true },
      {
        title: "Date",
        field: "paymentdate",
        removable: true,
        defaultSort: "desc"
      }
    ];

    return (
      <div>
        <Button
          block
          variant="outline-success"
          onClick={() => inventoryStore.updateAllInventory()}
        >
          Update Inventory History
        </Button>
        {inventoryStore.inventoryUpdated && (
          <MaterialTable
            title={"Inventory"}
            columns={columnnames}
            data={inventoryStore.allinventory}
            paging={true}
            options={{
              paging: true,
              pageSize: 10,
              search: false,
              pageSizeOptions: [5, 10, 15, 20, 25],
              maxBodyHeight: 1000,
              tableLayout: "auto",
              grouping: false,
              padding: "dense"
            }}
          />
        )}
      </div>
    );
  }
}

export default observer(InventoryHistory);
