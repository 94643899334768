import React from "react";
// First way to import
import moment from "moment";
import { observer } from "mobx-react";
import { Modal, Button } from "react-bootstrap";
import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";

import inventoryStore from "../Stores/InventoryStore";

import JobUpdatePage1 from "./JobUpdatePage1";
import JobUpdatePage2 from "./JobUpdatePage2";
import JobUpdatePage3 from "./JobUpdatePage3";

class TLDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
      show: false,
    };
    this.options3 = {
      defaultSortName: "start",
      defaultSortOrder: "asc",
      onRowClick: function (event) {
        inventoryStore.chooseJob(event);
      },
    };
  }

  componentDidMount() {
    // inventoryStore.fetchInventoryIn();
  }

  handleClose = () => inventoryStore.changeModalView(false);
  handleShow = (event) => {
    inventoryStore.chooseJob(event);

    this.setState({ show: true });
  };

  render() {
    const options = {
      defaultSortName: "qty",
      defaultSortOrder: "asc",
    };

    let sortedaddress = inventoryStore.selectedjob.address;
    let addresses;
    let sorteddates;
    let jobdatesvalues;

    if (inventoryStore.selectedjob) {
      if (inventoryStore.selectedjob.address.length > 0) {
        sortedaddress = inventoryStore.selectedjob.address.sort(function (
          first,
          second
        ) {
          return -second.order - -first.order;
        });
        addresses = sortedaddress.map((address, index) => (
          <div key={index}>
            {" "}
            <b>
              {index + 1}) From {address.typefrom}:
            </b>{" "}
            {address.locationfrom}
            <br />
            <b> - To {address.typefrom}:</b> {address.locationto}
            <hr />
          </div>
        ));
      }

      if (inventoryStore.selectedjob.jobdate.length > 0) {
        sorteddates = inventoryStore.selectedjob.jobdate.sort(function (
          first,
          second
        ) {
          return second.jobdate - first.jobdate;
        });
        jobdatesvalues = sorteddates.map((jobdate, index) => (
          <div key={index}>
            {" "}
            {index + 1}): {moment(jobdate.jobdate).format("DD-MMM-YY")} -{" "}
            {jobdate.status}
          </div>
        ));
      }
    }

    if (inventoryStore.page === 1) {
      return <JobUpdatePage1 />;
    } else if (inventoryStore.page === 2) {
      return <JobUpdatePage2 />;
    } else if (inventoryStore.page === 3) {
      return <JobUpdatePage3 />;
    }

    return (
      <div>
        <Modal
          show={inventoryStore.showmodal}
          onHide={this.handleClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title style={{ fontSize: 12, fontWeight: "bold" }}>
              Selected Date:{" "}
              {moment(inventoryStore.selectedjob.start).format("DD-MMM-YY")}
              <br />
              {inventoryStore.selectedjob.title}
              <br />
              {inventoryStore.selectedjob.phone}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ fontSize: 12 }}>
            <b>Job Details: </b>
            {inventoryStore.selectedjob.jobmain} -{" "}
            {inventoryStore.selectedjob.jobsecond} -{" "}
            {inventoryStore.selectedjob.cbm} CBM
            <hr />
            <b>All Job Dates:</b>
            {jobdatesvalues}
            <hr />
            <b>Address:</b>
            {addresses}
            <hr />
            <p style={{ fontSize: 10 }}>
              {" "}
              <b>Remarks: </b>
              {inventoryStore.selectedjob.newremark}
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Cancel
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                this.handleClose();
                inventoryStore.changePage(1);
              }}
            >
              Update Job Date
            </Button>
          </Modal.Footer>
        </Modal>
        <h2>Pending Jobs</h2>
        <hr />
        <BootstrapTable
          data={inventoryStore.TLbooked}
          striped
          hover
          condensed
          options={this.options3}
        >
          <TableHeaderColumn isKey dataField="start" hidden dataSort>
            Original Date
          </TableHeaderColumn>
          <TableHeaderColumn width="30%" dataField="startformat">
            Date
          </TableHeaderColumn>
          <TableHeaderColumn dataField="title">Client</TableHeaderColumn>
        </BootstrapTable>
        <hr />
        <h2>Current Inventory</h2>
        <Button
          block
          variant="outline-success"
          onClick={() => inventoryStore.updateAllInventory()}
        >
          Update Inventory
        </Button>
        {inventoryStore.inventoryUpdated && (
          <BootstrapTable
            data={inventoryStore.inventorySummary}
            striped
            hover
            condensed
            options={options}
          >
            <TableHeaderColumn isKey dataField="type" dataSort>
              Inventory
            </TableHeaderColumn>
            <TableHeaderColumn width="20%" dataField="qty" dataSort>
              QTY
            </TableHeaderColumn>
          </BootstrapTable>
        )}
        <hr />

        <hr />
      </div>
    );
  }
}

export default observer(TLDashboard);
