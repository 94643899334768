import React from "react";
// First way to import
import moment from "moment";
import { observer } from "mobx-react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Button } from "react-bootstrap";

import newEventStore from "../Stores/NewEventStore";
import jobSelectionStore from "../Stores/JobSelectionStore";
import settingStore from "../Stores/SettingStore";
import PerformanceCalendar from "./PerformanceCalendar";
import FilterModule from "../FilterModules/FilterModule";
import JobModal from "../FilterModules/JobModal";

moment.locale("ko", {
  week: {
    dow: 6,
    doy: 6,
  },
});
const localizer = momentLocalizer(moment);

class CalendarComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
      modalShow: false,
      estCalendar: false,
      perfCalendar: false,
    };
  }

  showEstCal() {
    this.setState({ estCalendar: !this.state.estCalendar });
  }

  eventStyleGetter = (event, start, end, isSelected) => {
    var backgroundColor = "#";

    if (event.status === "Booked") {
      backgroundColor = "green";
    } else if (event.status === "Converted") {
      backgroundColor = "#003705";
    } else if (event.status === "Proposal") {
      backgroundColor = "orange";
    } else if (event.status === "Estimation") {
      backgroundColor = "#6e757c";
    } else if (event.status === "Lead") {
      backgroundColor = "#6e757c";
    } else if (event.status === "Cancelled") {
      backgroundColor = "red";
    }
    var style = {
      backgroundColor: backgroundColor,
      borderRadius: "3px",
      opacity: 0.7,
      border: "3px",
      borderColor: "black",
      display: "block",
      fontSize: 12,
      height: 20,
    };
    return {
      style: style,
    };
  };
  handleClose = () => jobSelectionStore.closeModal();

  render() {
    return (
      <div>
        <JobModal
          show={jobSelectionStore.showModal}
          onHide={this.handleClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        />
        <FilterModule type="Calendar" />

        <hr />
        <b />
        <Calendar
          localizer={localizer}
          events={newEventStore.filteredJobs}
          eventPropGetter={this.eventStyleGetter}
          startAccessor="jobdate"
          endAccessor="jobdate"
          style={{ height: 800 }}
          defaultDate={new Date()}
          views={["month"]}
          titleAccessor="desc"
          popup
          onNavigate={(e) => {
            settingStore.changeCalDate(e);
          }}
          onSelectEvent={(event) => {
            jobSelectionStore.fetchSingleJob(event.job);
          }}
        />
        <hr />

        <hr />
        <Button
          block
          onClick={() =>
            this.setState({ estCalendar: !this.state.estCalendar })
          }
        >
          Show Estimation Calendar
        </Button>
        {this.state.estCalendar && (
          <Calendar
            localizer={localizer}
            events={newEventStore.jobs}
            startAccessor="estimationdate"
            endAccessor="estimationdate"
            style={{ height: 500 }}
            defaultDate={new Date()}
            views={["month"]}
            titleAccessor="clienttitle"
            onSelectEvent={(event) => {
              jobSelectionStore.fetchSingleJob(event.id);
            }}
            popup
          />
        )}
        <hr />
        <Button
          block
          variant={this.state.perfCalendar ? "outline-success" : "success"}
          onClick={() =>
            this.setState({ perfCalendar: !this.state.perfCalendar })
          }
        >
          {this.state.perfCalendar
            ? "Hide Daily Performance"
            : "Show Daily Performance"}
        </Button>
        {this.state.perfCalendar && <PerformanceCalendar />}
        <b />
        <hr />
        <b />
        <hr />
      </div>
    );
  }
}

export default observer(CalendarComponent);
