import React, { Component } from "react";
import { observer } from "mobx-react";

import { BootstrapTable, TableHeaderColumn } from "react-bootstrap-table";
import intSupStore from "../Stores/IntSupStore";
import settingStore from "../Stores/SettingStore";
import { Button } from "react-bootstrap";
import SupplierForm from "./SupplierForm";

import userStore from "../Stores/UserStore";
import ReactGA from "react-ga";

function initializeReactGA() {
  if (userStore.user.username !== "jazzaf") {
    ReactGA.initialize("UA-163244126-1");
    ReactGA.pageview(window.location.pathname);
    ReactGA.event({
      category: window.location.pathname,
      action: userStore.user.username,
      label: new Date().toLocaleTimeString(),
    });
  }
}

class IMCompanies extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.options = {
      defaultSortName: "id", // default sort column name
      // exportCSVBtn: this.createCustomExportCSVButton,
      defaultSortOrder: "asc", // default sort order
      onRowClick: function (event) {
        intSupStore.selectIntSupplier(event.id);
      },
    };
  }

  componentDidMount() {
    initializeReactGA();
  }

  render() {
    let clientForm = (
      <Button
        block
        variant="success"
        disabled={intSupStore.editIntSupplier.editSupplier}
        onClick={() => {
          if (settingStore.edit === false) {
            alert("Please Enable Edit to Make Changes.");
          } else {
            intSupStore.selectIntSupplier(0);
          }
        }}
      >
        Add A New Supplier
      </Button>
    );

    if (intSupStore.editIntSupplier.editSupplier) {
      return <SupplierForm />;
    }

    return (
      <div>
        {clientForm}
        <br />
        <Button
          onClick={() => intSupStore.changeCountry(0)}
          variant="outline-danger"
        >
          Clear
        </Button>
        <BootstrapTable
          data={intSupStore.intsuppliers}
          striped
          hover
          search={true}
          condensed
          pagination
          options={this.options}
        >
          <TableHeaderColumn isKey dataField="id" dataSort>
            ID
          </TableHeaderColumn>
          <TableHeaderColumn dataField="companytype" dataSort>
            Type
          </TableHeaderColumn>
          <TableHeaderColumn dataField="company" dataSort>
            Company
          </TableHeaderColumn>
          <TableHeaderColumn dataField="country" dataSort>
            Country
          </TableHeaderColumn>
          <TableHeaderColumn dataField="citystate" dataSort>
            City/State
          </TableHeaderColumn>
          <TableHeaderColumn dataField="contact" dataSort>
            Contact
          </TableHeaderColumn>
          <TableHeaderColumn dataField="phone" dataSort>
            Phone
          </TableHeaderColumn>
          <TableHeaderColumn dataField="email" dataSort>
            Email
          </TableHeaderColumn>
          <TableHeaderColumn dataField="rating" dataSort>
            Rating
          </TableHeaderColumn>
          <TableHeaderColumn dataField="organization" dataSort>
            Organization
          </TableHeaderColumn>
        </BootstrapTable>
        <br />
      </div>
    );
  }
}

export default observer(IMCompanies);
