import { decorate, observable, computed } from "mobx";
import axios from "axios";
import moment from "moment";
import ReactGA from "react-ga";
import emailjs from "emailjs-com";

import newEventStore from "./NewEventStore";
import settingStore from "./SettingStore";
import jobEditStore from "./JobEditStore";
import userStore from "./UserStore";

const instance = axios.create({
  // baseURL: "https://easymovecalendar.app/",
  // baseURL: "http://127.0.0.1:8000/",
  // baseURL: "http://157.230.113.108/",
  baseURL: "https://jazzafs.com/",
});

class EventStore {
  constructor() {
    this.clients = [];
    this.jobdates = [
      {
        title: "",
        client__id: "",
        phone: "",
        jobmain: "",
        jobsecond: "",
        address: "",
        cbm: "",
        price: "",
        start: null,
        end: null,
        desc: "",
        jobdate: [],
      },
    ];
    this.selectedjobdate = { address: [], jobdate: [] };
    this.jobs = [];
    this.editClient = {
      title: "",
      email: "",
      phone1: "",
      phone2: "",
      heardofus: "",
      remarks: "",
      leadType: "",
      id: 0,
      editOn: false,
    };
    this.editJob = {};
    this.selectedClient = null;
    this.teamleads = [];
    this.officestaff = [];
    this.allstaff = [];
    this.selectedstaff = "";
    this.services = [];
    this.excludedservices = [];
    this.queryEvent = "";
    this.filterjobs = {
      lead: true,
      proposal: true,
      booked: true,
      converted: false,
      cancelled: false,
      local: true,
      im: true,
      inbound: true,
      outbound: true,
      corporate: true,
      personal: true,
      storage: true,
      clearance: true,
    };
    this.dashboardType = "All";
    this.jobforTL = {};
    this.jobforOffice = {};
    this.storageJob = [];
    this.selectedStorage = {};
    this.financials = [];
    this.financialsLoaded = false;
    this.paymentupdate = false;
    this.fullclientlist = [];
    this.newpayment = {
      job: null,
      id: 0,
      invoiceamount: 0,
      paymentdate: moment(new Date()).format("YYYY-MM-DD"),
      category1: "",
      category2: "",
      category3: "",
      category4: "",
      category5: "",
      paymenttype: "",
      paymentdetail: "",
      bankaccount: "",
      remarks: "",
      paymentmethod: "",
      vouchernumber: "",
      invoicenumber: "",
      expenseheading: "No",
    };
  }

  resetPage() {
    this.clients = [];
    this.jobdates = [
      {
        title: "",
        client__id: "",
        phone: "",
        jobmain: "",
        jobsecond: "",
        address: "",
        cbm: "",
        price: "",
        start: null,
        end: null,
        desc: "",
        jobdate: [],
      },
    ];
    this.selectedjobdate = { address: [], jobdate: [] };
    this.jobs = [];
    this.editClient = {
      title: "",
      email: "",
      phone1: "",
      phone2: "",
      heardofus: "",
      remarks: "",
      leadType: "",
      id: 0,
      editOn: false,
    };
    this.editJob = {};
    this.selectedClient = null;
    this.teamleads = [];
    this.officestaff = [];
    this.allstaff = [];
    this.selectedstaff = "";
    this.services = [];
    this.excludedservices = [];
    this.queryEvent = "";
    this.filterjobs = {
      lead: true,
      proposal: true,
      booked: true,
      converted: false,
      cancelled: false,
      local: true,
      im: true,
      inbound: true,
      outbound: true,
      corporate: true,
      personal: true,
      storage: true,
      clearance: true,
    };
    this.dashboardType = "All";
    this.jobforTL = {};
    this.jobforOffice = {};
    this.storageJob = [];
    this.selectedStorage = {};
    this.financials = [];
    this.financialsLoaded = false;
    this.paymentupdate = false;
    this.fullclientlist = [];
    this.newpayment = {
      job: null,
      id: 0,
      invoiceamount: 0,
      paymentdate: moment(new Date()).format("YYYY-MM-DD"),
      category1: "",
      category2: "",
      category3: "",
      category4: "",
      category5: "",
      paymenttype: "",
      paymentdetail: "",
      bankaccount: "",
      remarks: "",
      paymentmethod: "",
      vouchernumber: "",
      invoicenumber: "",
      expenseheading: "No",
    };
  }

  errorEmail(response, activity) {
    var template_params = {
      activitytype: activity,
      username: userStore.user.username,
      ClientID: this.editClient.id,
      JobID: this.editClient.title,
      consoleerror: JSON.stringify(response.data),
    };
    emailjs.send(
      "default_service",
      "error",
      template_params,
      "user_IaUVUsVD6VMlmBLY50c9p"
    );
  }
  reloadAllData() {
    settingStore.changeLoading(true);
    settingStore.changeCancelClientLoad(false);
    this.clients = [];
    this.jobdates = [
      {
        title: "",
        client__id: "",
        phone: "",
        jobmain: "",
        jobsecond: "",
        address: "",
        cbm: "",
        price: "",
        start: null,
        end: null,
        desc: "",
        jobdate: [],
      },
    ];
    this.selectedjobdate = { address: [], jobdate: [] };
    this.jobs = [];
    this.editClient = {
      title: "",
      email: "",
      phone1: "",
      phone2: "",
      heardofus: "",
      remarks: "",
      leadType: "",
      id: 0,
      editOn: false,
    };
    this.editJob = {};
    this.selectedClient = null;
    this.teamleads = [];
    this.officestaff = [];
    this.selectedstaff = "";
    this.services = [];
    this.excludedservices = [];
    this.queryEvent = "";
    this.filterjobs = {
      lead: true,
      proposal: true,
      booked: true,
      converted: false,
      cancelled: false,
      local: true,
      im: true,
      inbound: true,
      outbound: true,
      corporate: true,
      personal: true,
      storage: true,
      clearance: true,
    };
    this.dashboardType = "All";
    this.jobforTL = {};
    this.jobforOffice = {};
    this.storageJob = [];
    this.selectedStorage = {};
    this.fetchCalendarDataNew();
  }

  changedashboard(type) {
    this.dashboardType = type;
    if (type === "Local") {
      this.filterjobs.im = false;
      this.filterjobs.local = true;
    } else if (type === "im") {
      this.filterjobs.local = false;
      this.filterjobs.im = true;
    } else if (type === "All") {
      this.filterjobs.local = false;
      this.filterjobs.im = false;
    }
  }

  filterStaff(val) {
    if (val === "reset") {
      this.selectedstaff = "";
    } else {
      this.selectedstaff = val;
    }
  }

  addJobToClient(job) {
    let indexVal = this.clients.findIndex((client) => client.id === job.client);
    if (indexVal >= 0) {
      this.clients[indexVal].job.push(job);
    }
    this.selectClient(job.client);
  }

  selectClient(id) {
    if (id === 0) {
      this.editClient = {
        title: "",
        email: "",
        phone1: "",
        phone2: "",
        heardofus: "",
        remarks: "",
        leadType: "",
        id: 0,
        editOn: true,
        job: [],
      };
    } else {
      this.editClient = {
        title: "",
        email: "",
        phone1: "",
        phone2: "",
        heardofus: "",
        remarks: "",
        leadType: "",
        id: 0,
        job: [],
        editOn: false,
      };
      let indexVal = this.clients.findIndex((client) => client.id === id);
      this.editClient = this.clients[indexVal];

      let selectedjobs = this.jobs.filter((job) => job.client === id);
      this.editClient.job = selectedjobs;

      if (this.editClient.email === null) {
        this.editClient.email = "";
      } else if (this.editClient.heardofus === null) {
        this.editClient.heardofus = "";
      }

      this.editClient.editOn = true;
    }
  }

  searchEvent(query) {
    this.queryEvent = query;
  }

  changeJobFilter(type) {
    if (type === "reset") {
      this.filterjobs = {
        lead: true,
        proposal: true,
        booked: true,
        converted: false,
        cancelled: true,
        local: true,
        im: true,
        inbound: true,
        outbound: true,
        corporate: true,
        personal: true,
        storage: true,
        clearance: true,
      };
      this.queryEvent = "";
    } else if (type === "lead") {
      this.filterjobs.lead = !this.filterjobs.lead;
    } else if (type === "proposal") {
      this.filterjobs.proposal = !this.filterjobs.proposal;
    } else if (type === "booked") {
      this.filterjobs.booked = !this.filterjobs.booked;
    } else if (type === "converted") {
      this.filterjobs.converted = !this.filterjobs.converted;
    } else if (type === "cancelled") {
      this.filterjobs.cancelled = !this.filterjobs.cancelled;
    } else if (type === "local") {
      this.filterjobs.local = !this.filterjobs.local;
    } else if (type === "im") {
      this.filterjobs.im = !this.filterjobs.im;
    } else if (type === "inbound") {
      this.filterjobs.inbound = !this.filterjobs.inbound;
    } else if (type === "outbound") {
      this.filterjobs.outbound = !this.filterjobs.outbound;
    } else if (type === "corporate") {
      this.filterjobs.corporate = !this.filterjobs.corporate;
    } else if (type === "personal") {
      this.filterjobs.personal = !this.filterjobs.personal;
    } else if (type === "storage") {
      this.filterjobs.storage = !this.filterjobs.storage;
    } else if (type === "clearance") {
      this.filterjobs.clearance = !this.filterjobs.clearance;
    } else if (type === "calreset") {
      this.filterjobs = {
        lead: false,
        proposal: false,
        booked: true,
        converted: true,
        cancelled: false,
        local: true,
        im: true,
        inbound: true,
        outbound: true,
        corporate: true,
        personal: true,
        storage: true,
        clearance: true,
      };
    }
  }

  removeEdit() {
    this.editClient.editOn = false;
  }

  selectJob(id) {
    settingStore.changeLoading(true);
    if (id === 0) {
      this.editJob = {};
      settingStore.changeLoading(false);
    } else {
      instance
        .get("/n-job/" + id + "/?format=json")
        .then((res) => res.data)
        .then((job) => {
          this.editJob = job;
          settingStore.changeLoading(false);
          settingStore.changeEditJob(true);
        });
    }
  }

  selectJobDate(jobdate) {
    this.selectedjobdate = jobdate;
  }
  selectJobDate2(jobdate) {
    let indexVal = this.jobs.findIndex(
      (job) => parseFloat(job.id) === parseFloat(jobdate.id)
    );
    this.selectedjobdate = this.jobs[indexVal];
  }
  selectIMJob(imjob) {
    let indexVal = this.jobs.findIndex(
      (job) => parseFloat(job.id) === parseFloat(imjob.job)
    );
    this.selectedjobdate = this.jobs[indexVal];
  }

  updateClientDetails(val, type) {
    if (settingStore.edit === false) {
      alert("Please enable edit to make changes.");
    } else {
      if (type === "phone1") {
        this.editClient.phone1 = val;
      } else if (type === "phone2") {
        this.editClient.phone2 = val;
      } else if (type === "title") {
        this.editClient.title = val;
      } else if (type === "email") {
        this.editClient.email = val;
      } else if (type === "leadtype") {
        this.editClient.leadtype = val;
      } else if (type === "heardofus") {
        this.editClient.heardofus = val;
      } else if (type === "remarks") {
        this.editClient.remarks = val;
      } else if (type === "editOn") {
        this.editClient.editOn = !this.editClient.editOn;
      }
    }
  }

  postService(data) {
    ReactGA.initialize("UA-163244126-1");
    ReactGA.event({
      category: "New Service",
      action: userStore.user.username,
      label: new Date().toLocaleTimeString(),
    });
    return instance
      .post("/c-service/", data)
      .then((res) => res.data)
      .catch((err) => {
        this.errorEmail(err.response, "New Service");
        alert(
          "An error occurred. The data did NOT save. Please contact me (97666886)." +
            "\n" +
            JSON.stringify(err.response)
        );
        console.error(err.response);
      });
  }

  postExcludedService(data) {
    ReactGA.initialize("UA-163244126-1");
    ReactGA.event({
      category: "New Service",
      action: userStore.user.username,
      label: new Date().toLocaleTimeString(),
    });
    return instance
      .post("/c-excludeservice/", data)
      .then((res) => res.data)
      .then((events) => {
        // console.log(events);
      })
      .catch((err) => {
        this.errorEmail(err.response, "New Excluded Service");
        alert(
          "An error occurred. The data did NOT save. Please contact me (97666886)." +
            "\n" +
            JSON.stringify(err.response)
        );

        console.error(err.response);
      });
  }

  updateClient() {
    let data = {
      title: this.editClient.title,
      email: this.editClient.email,
      phone1: this.editClient.phone1,
      phone2: this.editClient.phone2,
      leadtype: this.editClient.leadtype,
      heardofus: this.editClient.heardofus,
      remarks: this.editClient.remarks,
    };

    if (this.editClient.id === 0) {
      ReactGA.initialize("UA-163244126-1");
      ReactGA.event({
        category: "New Client",
        action: userStore.user.username,
        label: new Date().toLocaleTimeString(),
      });

      return instance
        .post("/c-client/", data)
        .then((res) => res.data)
        .then((events) => {
          settingStore.changeLoading(true);
          // console.log("successfully added");
          alert(`New Client (${events.title}) Created.`);
          // this.reloadAllData();
          events.job = [];

          this.clients.push(events);
          this.selectClient(events.id);
          settingStore.changeLoading(false);
        })
        .catch((err) => {
          this.errorEmail(err.response, "New Client Details");
          alert(
            "An error occurred. The data did NOT save. Please contact me (97666886)." +
              "\n" +
              JSON.stringify(err.response)
          );

          console.error(err.response);
        });
    } else {
      ReactGA.initialize("UA-163244126-1");
      ReactGA.event({
        category: "Client Update",
        action: userStore.user.username,
        label: new Date().toLocaleTimeString(),
      });
      return instance
        .put("/client/" + this.editClient.id + "/", data)
        .then((res) => res.data)
        .then((events) => {
          // console.log("successfully added");
          alert(`${data.title} Details Updated.`);
        })
        .catch((err) => {
          this.errorEmail(err.response, "Update Client Details");
          alert(
            "An error occurred. The data did NOT save. Please contact me (97666886)." +
              "\n" +
              JSON.stringify(err.response)
          );
        });
    }
  }

  addNewJob(event) {
    this.jobs.push(event);
  }

  // fetchCalendarData() {
  //   return instance
  //     .get("/n-scal/?format=json")
  //     .then(res => res.data)
  //     .then(clients => {
  //       settingStore.changeLoading(true);
  //       for (let b = 0; b < clients.length; b++) {
  //         this.clients.push(clients[b]);
  //       }
  //       let item;
  //       let singlejob;
  //       let storage = {};
  //       for (let i = 0; i < clients.length; i++) {
  //         if (clients[i].job) {
  //           for (let j = 0; j < clients[i].job.length; j++) {
  //             singlejob = clients[i].job[j];
  //             singlejob.title = clients[i].title;
  //             singlejob.phone1 = clients[i].phone1;
  //             singlejob.phone2 = clients[i].phone2;
  //             singlejob.email = clients[i].email;
  //             if (!singlejob.remarks) {
  //               singlejob.remarks = "";
  //             }
  //             this.jobs.push(singlejob);

  //             if (clients[i].job[j].storage.length > 0) {
  //               for (let m = 0; m < clients[i].job[j].storage.length; m++) {
  //                 storage = clients[i].job[j].storage[m];
  //                 storage.desc =
  //                   clients[i].title +
  //                   " " +
  //                   storage.storagecbm +
  //                   "CBM " +
  //                   storage.monthlycost +
  //                   " KD.";
  //                 storage.title = clients[i].title;
  //                 storage.jobid = clients[i].job[j].id;
  //                 storage.clientid = clients[i].id;
  //                 storage.phone = clients[i].phone1 + " / " + clients[i].phone1;
  //                 storage.email = clients[i].email;
  //                 storage.lastupdate = clients[i].job[j].lastupdate;
  //                 storage.userupdate = clients[i].job[j].userupdated;
  //                 storage.remarks = clients[i].job[j].remarks;
  //                 this.storageJob.push(clients[i].job[j].storage[m]);
  //               }
  //             }
  //             if (clients[i].job[j].jobdate) {
  //               for (let k = 0; k < clients[i].job[j].jobdate.length; k++) {
  //                 if (clients[i].job[j].jobdate[k].jobdate) {
  //                   item = {
  //                     title: clients[i].title,
  //                     id: clients[i].job[j].id,
  //                     status: clients[i].job[j].status,
  //                     client__id: clients[i].id,
  //                     jobmain: clients[i].job[j].jobmain,
  //                     jobsecond: clients[i].job[j].jobsecond,
  //                     address: clients[i].job[j].address,
  //                     cbm: clients[i].job[j].cbm,
  //                     price: Math.round(10 * clients[i].job[j].lastprice) / 10,
  //                     start: clients[i].job[j].jobdate[k].jobdate,
  //                     end: clients[i].job[j].jobdate[k].jobdate,
  //                     remarks: clients[i].job[j].jobdate[k].remarks,
  //                     estimationdate: clients[i].job[j].estimationdate,
  //                     desc:
  //                       clients[i].job[j].cbm +
  //                       "CBM at " +
  //                       Math.round(10 * clients[i].job[j].lastprice) / 10 +
  //                       "KD: " +
  //                       clients[i].title
  //                   };
  //                   if (clients[i].phone2 > 0) {
  //                     item.phone =
  //                       clients[i].phone1 + " - " + clients[i].phone1;
  //                   } else {
  //                     item.phone = clients[i].phone1;
  //                   }
  //                   if (clients[i].job[j].storage.length > 0) {
  //                     item.storagerequired = true;
  //                   } else {
  //                     item.storagerequired = false;
  //                   }
  //                   if (clients[i].job[j].jobdate.length > 1) {
  //                     if (item.price > 0) {
  //                       item.dailyprice =
  //                         item.price / clients[i].job[j].jobdate.length;
  //                     } else {
  //                       item.dailyprice = 0;
  //                     }
  //                   } else {
  //                     if (item.price > 0) {
  //                       item.dailyprice = item.price;
  //                     } else {
  //                       item.dailyprice = 0;
  //                     }
  //                   }
  //                   if (clients[i].job[j].jobdate.length > 1) {
  //                     if (item.cbm) {
  //                       if (item.cbm > 0) {
  //                         item.dailycbm =
  //                           item.cbm / clients[i].job[j].jobdate.length;
  //                       } else {
  //                         item.dailycbm = item.cbm;
  //                       }
  //                     } else {
  //                       item.dailycbm = 0;
  //                     }
  //                   } else {
  //                     if (item.cbm > 0) {
  //                       item.dailycbm = item.cbm;
  //                     } else {
  //                       item.dailycbm = 0;
  //                     }
  //                   }

  //                   this.jobdates.push(item);
  //                 }
  //               }
  //             }
  //           }
  //         }
  //       }
  //     })
  //     .then(clients => {
  //       console.log("Short Clients Fetched" + this.clients.length);
  //       // this.fetchFullCalendar();
  //       this.fetchAllClients();
  //       this.fetchTeamLeads();
  //       this.fetchServices();
  //       this.fetchExcludedServices();
  //     })
  //     .then(clients => {
  //       settingStore.changeLoading(false);
  //     });
  // }

  fetchCalendarDataNew() {
    this.jobs = [];
    this.storageJob = [];
    this.jobdates = [];
    settingStore.changeLoading(true);
    this.fetchTeamLeads();
    this.fetchServices();
    this.fetchExcludedServices();
    newEventStore.fetchJobDates();
    // return instance
    //   .get("/n-jobshort/?format=json")
    //   .then((res) => res.data)
    //   .then((jobs) => {
    //     let item;
    //     let singlejob;
    //     let storage = {};
    //     let indexVal = 0;
    //     for (let j = 0; j < jobs.length; j++) {
    //       indexVal = -1;

    //       indexVal = this.clients.findIndex(
    //         (client) => client.id === jobs[j].client
    //       );
    //       singlejob = jobs[j];
    //       if (indexVal >= 0) {
    //         singlejob.title = this.clients[indexVal].title;
    //         singlejob.phone1 = this.clients[indexVal].phone1;
    //         singlejob.phone2 = this.clients[indexVal].phone2;
    //         singlejob.email = this.clients[indexVal].email;
    //       }

    //       singlejob = jobs[j];
    //       if (!singlejob.remarks) {
    //         singlejob.remarks = "";
    //       }
    //       this.jobs.push(singlejob);

    //       if (jobs[j].storage.length > 0) {
    //         for (let m = 0; m < jobs[j].storage.length; m++) {
    //           storage = jobs[j].storage[m];
    //           storage.desc =
    //             this.clients[indexVal].title +
    //             " " +
    //             storage.storagecbm +
    //             "CBM " +
    //             storage.monthlycost +
    //             " KD.";
    //           storage.title = this.clients[indexVal].title;
    //           storage.jobid = jobs[j].id;
    //           storage.clientid = this.clients[indexVal].id;
    //           storage.phone =
    //             this.clients[indexVal].phone1 +
    //             " / " +
    //             this.clients[indexVal].phone1;
    //           storage.email = this.clients[indexVal].email;
    //           storage.status = jobs[j].status;
    //           storage.lastupdate = jobs[j].lastupdate;
    //           storage.userupdate = jobs[j].userupdated;
    //           storage.remarks = jobs[j].remarks;
    //           this.storageJob.push(jobs[j].storage[m]);
    //         }
    //       }
    //       if (jobs[j].jobdate) {
    //         for (let k = 0; k < jobs[j].jobdate.length; k++) {
    //           if (jobs[j].jobdate[k].jobdate) {
    //             item = {
    //               title: this.clients[indexVal].title,
    //               id: jobs[j].id,
    //               status: jobs[j].status,
    //               client__id: this.clients[indexVal].id,
    //               jobmain: jobs[j].jobmain,
    //               jobsecond: jobs[j].jobsecond,
    //               address: jobs[j].address,
    //               cbm: jobs[j].cbm,
    //               price: Math.round(10 * jobs[j].lastprice) / 10,
    //               start: jobs[j].jobdate[k].jobdate,
    //               jobdateid: jobs[j].jobdate[k].id,
    //               jobdateremarks: jobs[j].jobdate[k].dateremarks,
    //               jobdatestatus: jobs[j].jobdate[k].status,
    //               jobdateTL: jobs[j].teamLeader,
    //               end: jobs[j].jobdate[k].jobdate,
    //               remarks: jobs[j].jobdate[k].remarks,
    //               estimationdate: jobs[j].estimationdate,
    //               desc:
    //                 jobs[j].cbm +
    //                 "CBM at " +
    //                 Math.round(10 * jobs[j].lastprice) / 10 +
    //                 "KD: " +
    //                 this.clients[indexVal].title,
    //             };
    //             if (this.clients[indexVal].phone2 > 0) {
    //               item.phone =
    //                 this.clients[indexVal].phone1 +
    //                 " - " +
    //                 this.clients[indexVal].phone1;
    //             } else {
    //               item.phone = this.clients[indexVal].phone1;
    //             }
    //             if (jobs[j].storage.length > 0) {
    //               item.storagerequired = true;
    //             } else {
    //               item.storagerequired = false;
    //             }
    //             if (jobs[j].jobdate.length > 1) {
    //               if (item.price > 0) {
    //                 item.dailyprice = item.price / jobs[j].jobdate.length;
    //               } else {
    //                 item.dailyprice = 0;
    //               }
    //             } else {
    //               if (item.price > 0) {
    //                 item.dailyprice = item.price;
    //               } else {
    //                 item.dailyprice = 0;
    //               }
    //             }
    //             if (jobs[j].jobdate.length > 1) {
    //               if (item.cbm) {
    //                 if (item.cbm > 0) {
    //                   item.dailycbm = item.cbm / jobs[j].jobdate.length;
    //                 } else {
    //                   item.dailycbm = item.cbm;
    //                 }
    //               } else {
    //                 item.dailycbm = 0;
    //               }
    //             } else {
    //               if (item.cbm > 0) {
    //                 item.dailycbm = item.cbm;
    //               } else {
    //                 item.dailycbm = 0;
    //               }
    //             }

    //             this.jobdates.push(item);
    //           }
    //         }
    //       }
    //     }
    //   })
    //   .then((clients) => {
    //     console.log("Short Clients Fetched" + this.clients.length);
    //     // this.fetchFullCalendar();
    //     this.fetchTeamLeads();
    //     this.fetchServices();
    //     this.fetchExcludedServices();
    //   })
    //   .then((clients) => {
    //     settingStore.changeLoading(false);
    //   });
  }

  fetchAllClients() {
    this.clients = [];

    return instance
      .get("/clients/?format=json")
      .then((res) => res.data)
      .then((clients) => (this.clients = clients))
      .then((clients) => {
        this.fetchCalendarDataNew();
      });
  }

  fetchCancelledClients() {
    settingStore.changeLoading(true);
    return instance
      .get("/n-ccal/?format=json")
      .then((res) => res.data)
      .then((clients) => {
        let item;
        let singlejob;
        let storage = {};
        for (let i = 0; i < clients.length; i++) {
          if (clients[i].job) {
            for (let j = 0; j < clients[i].job.length; j++) {
              singlejob = clients[i].job[j];
              singlejob.title = clients[i].title;
              singlejob.phone1 = clients[i].phone1;
              singlejob.phone2 = clients[i].phone2;
              singlejob.email = clients[i].email;
              this.jobs.push(singlejob);

              if (clients[i].job[j].storage.length > 0) {
                for (let m = 0; m < clients[i].job[j].storage.length; m++) {
                  storage = clients[i].job[j].storage[m];
                  storage.desc =
                    clients[i].title +
                    " " +
                    storage.storagecbm +
                    "CBM " +
                    storage.monthlycost +
                    " KD.";
                  storage.title = clients[i].title;
                  storage.jobid = clients[i].job[j].id;
                  storage.clientid = clients[i].id;
                  storage.phone = clients[i].phone1 + " / " + clients[i].phone1;
                  storage.email = clients[i].email;
                  storage.lastupdate = clients[i].job[j].lastupdate;
                  storage.status = clients[i].job[j].status;
                  storage.userupdate = clients[i].job[j].userupdated;
                  storage.remarks = clients[i].job[j].remarks;
                  this.storageJob.push(clients[i].job[j].storage[m]);
                }
              }
              if (clients[i].job[j].jobdate) {
                for (let k = 0; k < clients[i].job[j].jobdate.length; k++) {
                  if (clients[i].job[j].jobdate[k].jobdate) {
                    item = {
                      title: clients[i].title,
                      id: clients[i].job[j].id,
                      status: clients[i].job[j].status,
                      client__id: clients[i].id,
                      jobmain: clients[i].job[j].jobmain,
                      jobsecond: clients[i].job[j].jobsecond,
                      address: clients[i].job[j].address,
                      cbm: clients[i].job[j].cbm,
                      price: Math.round(10 * clients[i].job[j].lastprice) / 10,
                      start: clients[i].job[j].jobdate[k].jobdate,
                      end: clients[i].job[j].jobdate[k].jobdate,
                      remarks: clients[i].job[j].jobdate[k].remarks,
                      estimationdate: clients[i].job[j].estimationdate,
                      desc:
                        clients[i].job[j].cbm +
                        "CBM at " +
                        Math.round(10 * clients[i].job[j].lastprice) / 10 +
                        "KD: " +
                        clients[i].title,
                    };
                    if (clients[i].phone2 > 0) {
                      item.phone =
                        clients[i].phone1 + " - " + clients[i].phone1;
                    } else {
                      item.phone = clients[i].phone1;
                    }
                    if (clients[i].job[j].storage.length > 0) {
                      item.storagerequired = true;
                    } else {
                      item.storagerequired = false;
                    }
                    if (clients[i].job[j].jobdate.length > 1) {
                      if (item.price > 0) {
                        item.dailyprice =
                          item.price / clients[i].job[j].jobdate.length;
                      } else {
                        item.dailyprice = 0;
                      }
                    } else {
                      if (item.price > 0) {
                        item.dailyprice = item.price;
                      } else {
                        item.dailyprice = 0;
                      }
                    }
                    if (clients[i].job[j].jobdate.length > 1) {
                      if (item.cbm) {
                        if (item.cbm > 0) {
                          item.dailycbm =
                            item.cbm / clients[i].job[j].jobdate.length;
                        } else {
                          item.dailycbm = item.cbm;
                        }
                      } else {
                        item.dailycbm = 0;
                      }
                    } else {
                      if (item.cbm > 0) {
                        item.dailycbm = item.cbm;
                      } else {
                        item.dailycbm = 0;
                      }
                    }

                    this.jobdates.push(item);
                  }
                }
              }
            }
          }
        }
      })
      .then((clients) => {
        console.log("Cancelled Clients Fetched" + this.clients.length);
        settingStore.changeLoading(false);
        settingStore.changeCancelClientLoad(true);
      });
  }

  fetchFinancials() {
    settingStore.changeLoading(true);
    return instance
      .get("/payments/?format=json")
      .then((res) => res.data)
      .then((financials) => {
        this.financials = financials;
        let indexVal;
        for (let i = 0; i < this.financials.length; i++) {
          this.financials[i].invoiceamount =
            Math.round(1000 * this.financials[i].invoiceamount) / 1000;
          if (this.financials[i].expenseheading !== "Yes") {
            if (this.financials[i].paymentmethod === "Cash") {
              this.financials[i].expenseheading = "Yes";
            } else {
              this.financials[i].expenseheading = "No";
            }
          }

          indexVal = this.jobs.findIndex(
            (job) => job.id === this.financials[i].job
          );

          if (indexVal > 0) {
            this.financials[i].clientname = this.jobs[indexVal].title;
          } else {
            this.financials[i].clientname = "";
          }
        }
        console.log("Financials Fetched");
        settingStore.changeLoading(false);
        this.financialsLoaded = true;
      });
  }

  fetchClients() {
    return instance
      .get("/n-clients/?format=json")
      .then((res) => res.data)
      .then((clients) => {
        this.clients = clients;
        console.log(this.clients);
        this.fetchJobs();
        this.editClient = this.clients[0];
        console.log("Clients Fetched");
      });
  }
  fetchJobs() {
    return instance
      .get("/n-job/?format=json")
      .then((res) => res.data)
      .then((jobs) => {
        this.jobs = jobs;
        this.fetchServices();
        this.fetchExcludedServices();
        // this.fetchDates();
        // console.log("jobs");
        // console.log(this.jobs);
        console.log("Jobs Fetched");
      });
  }
  fetchServices() {
    return instance
      .get("/services/?format=json")
      .then((res) => res.data)
      .then((services) => {
        this.services = services;
        // console.log(this.services);
        console.log("Services Fetched");
      });
  }
  fetchExcludedServices() {
    return instance
      .get("/services/?format=json")
      .then((res) => res.data)
      .then((services) => {
        this.excludedservices = services;
        console.log("Excluded Services Fetched");
      });
  }

  fetchSingleJob(id) {
    return instance
      .get("/job/" + id + "/?format=json")
      .then((res) => res.data)
      .then((jobs) => {
        // console.log(jobs);
      });
  }

  fetchDates() {
    return instance
      .get("/jobdates/?format=json")
      .then((res) => res.data)
      .then((jobdates) => {
        this.jobdates = jobdates;
        console.log("Dates Fetched");
        for (let i = 0; i < this.jobdates.length; i++) {
          let jobindex = this.jobs.findIndex(
            (job) => job.id === this.jobdates[i].job
          );
          let clientindex = this.clients.findIndex(
            (client) => client.id === this.jobs[jobindex].client
          );

          this.jobdates[i].id = this.jobs[jobindex].id;
          this.jobdates[i].title = this.clients[clientindex].title;
          this.jobdates[i].client_id = this.clients[clientindex].id;
          this.jobdates[i].phone =
            this.clients[clientindex].phone1 +
            " - " +
            this.clients[clientindex].phone1;
          this.jobdates[i].jobmain = this.jobs[jobindex].jobmain;
          this.jobdates[i].jobsecond = this.jobs[jobindex].jobsecond;
          this.jobdates[i].address = this.jobs[jobindex].address;
          this.jobdates[i].cbm = this.jobs[jobindex].cbm;
          this.jobdates[i].price =
            Math.round(10 * this.jobs[jobindex].lastprice) / 10;
          this.jobdates[i].start = this.jobdates[i].jobdate;
          this.jobdates[i].end = this.jobdates[i].jobdate;
          this.jobdates[i].desc =
            this.jobdates[i].cbm +
            "CBM at " +
            this.jobdates[i].price +
            "KD: " +
            this.jobdates[i].title;
        }

        this.fetchTeamLeads();
      });
  }

  fetchTeamLeads() {
    return instance
      .get("/profiles/?format=json")
      .then((res) => res.data)
      .then((profile) => {
        this.allstaff = profile;
        for (let i = 0; i < this.allstaff.length; i++) {
          this.allstaff[i].date = moment(this.allstaff[i].last_signin).format(
            "DD-MMM-YY"
          );
        }

        this.teamleads = profile.filter((profile) => profile.group === "Field");
        this.officestaff = profile.filter(
          (profile) =>
            profile.group === "Office" || profile.group === "Accounts"
        );
      });
  }

  get includedServices() {
    let servicesfiltered = [];

    for (let k = 0; k < this.services.length; k++) {
      if (
        jobEditStore.job.jobmain === "IM - Inbound" ||
        jobEditStore.job.jobmain === "IM - Outbound" ||
        jobEditStore.job.jobmain === "CIM - Inbound" ||
        jobEditStore.job.jobmain === "CIM - Outbound"
      ) {
        if (this.services[k].servicetype === "IM") {
          servicesfiltered.push({
            id: this.services[k].id,
            title: this.services[k].title,
            included: false,
            servicetype: this.services[k].servicetype,
          });
        }
      } else {
        if (this.services[k].servicetype === "Local") {
          servicesfiltered.push({
            id: this.services[k].id,
            title: this.services[k].title,
            included: false,
            servicetype: this.services[k].servicetype,
          });
        }
      }
    }

    if (
      (jobEditStore.job.serviceslist.length > 0) &
      (servicesfiltered.length > 0)
    ) {
      for (let i = 0; i < jobEditStore.job.serviceslist.length; i++) {
        for (let j = 0; j < servicesfiltered.length; j++) {
          if (servicesfiltered[j].id === jobEditStore.job.serviceslist[i]) {
            servicesfiltered[j].included = true;
          }
        }
      }
    }

    return servicesfiltered;
  }

  get excludedServicesCalc() {
    let servicesfiltered = [];

    for (let k = 0; k < this.excludedservices.length; k++) {
      if (
        jobEditStore.job.jobmain === "IM - Inbound" ||
        jobEditStore.job.jobmain === "IM - Outbound" ||
        jobEditStore.job.jobmain === "CIM - Inbound" ||
        jobEditStore.job.jobmain === "CIM - Outbound"
      ) {
        if (this.excludedservices[k].servicetype === "IM") {
          servicesfiltered.push({
            id: this.excludedservices[k].id,
            title: this.excludedservices[k].title,
            included: false,
            servicetype: this.excludedservices[k].servicetype,
          });
        }
      } else {
        if (this.excludedservices[k].servicetype === "Local") {
          servicesfiltered.push({
            id: this.excludedservices[k].id,
            title: this.excludedservices[k].title,
            included: false,
            servicetype: this.excludedservices[k].servicetype,
          });
        }
      }
    }

    if (
      (jobEditStore.job.excludedserviceslist.length > 0) &
      (servicesfiltered.length > 0)
    ) {
      for (let i = 0; i < jobEditStore.job.excludedserviceslist.length; i++) {
        for (let j = 0; j < servicesfiltered.length; j++) {
          if (
            servicesfiltered[j].id === jobEditStore.job.excludedserviceslist[i]
          ) {
            servicesfiltered[j].included = true;
          }
        }
      }
    }

    return servicesfiltered;
  }

  get filteredEstCal() {
    let filtjobs = this.jobdates;

    for (let i = 0; i < filtjobs.length; i++) {
      filtjobs[i].start = filtjobs[i].estimationdate;
      filtjobs[i].end = filtjobs[i].estimationdate;
    }

    return filtjobs;
  }

  get filteredCal() {
    let filtjobs = this.jobdates;
    if (this.dashboardType === "Local") {
      filtjobs = filtjobs.filter(
        (job) =>
          job.jobmain === "Local Move" || job.jobmain === "Corporate Move"
      );
    } else if (this.dashboardType === "im") {
      filtjobs = filtjobs.filter(
        (job) =>
          job.jobmain === "IM - Inbound" ||
          job.jobmain === "IM - Outbound" ||
          job.jobmain === "CIM - Inbound" ||
          job.jobmain === "CIM - Outbound"
      );
    }
    if (!this.filterjobs.proposal) {
      filtjobs = filtjobs.filter((job) => job.status !== "Proposal");
    }
    if (!this.filterjobs.lead) {
      filtjobs = filtjobs.filter((job) => job.status !== "Lead");
      filtjobs = filtjobs.filter((job) => job.status !== "Estimation");
    }
    if (!this.filterjobs.booked) {
      filtjobs = filtjobs.filter((job) => job.status !== "Booked");
    }
    if (!this.filterjobs.converted) {
      filtjobs = filtjobs.filter((job) => job.status !== "Converted");
    }
    if (!this.filterjobs.cancelled) {
      filtjobs = filtjobs.filter((job) => job.status !== "Cancelled");
    }
    if (!this.filterjobs.storage) {
      filtjobs = filtjobs.filter((job) => job.storage.length === 0);
      filtjobs = filtjobs.filter((job) => job.storage !== "Only Storage");
    }

    return filtjobs;
  }

  get filteredUserJobs() {
    return this.jobs.filter(
      (job) =>
        (job.emfocalpoint === userStore.profile.first_name) &
        (job.status !== "Converted")
    );
  }

  get qualityScore() {
    let qualityscore = 0;
    let qualitycount = 0;
    for (let i = 0; i < this.filteredUserJobs.length; i++) {
      qualitycount += 1;
      qualityscore += parseFloat(eventStore.filteredUserJobs[i].qualityscore);
    }
    return Math.round(qualityscore / qualitycount);
  }

  get filteredlocal() {
    let filtjobs = this.jobs;
    if (this.dashboardType === "Local") {
      filtjobs = filtjobs.filter(
        (job) =>
          (job.jobmain !== "IM - Inbound") &
          (job.jobmain !== "IM - Outbound") &
          (job.jobmain !== "CIM - Inbound") &
          (job.jobmain !== "CIM - Outbound")
      );
    } else if (this.dashboardType === "im") {
      filtjobs = filtjobs.filter(
        (job) =>
          job.jobmain === "IM - Inbound" ||
          job.jobmain === "IM - Outbound" ||
          job.jobmain === "CIM - Inbound" ||
          job.jobmain === "CIM - Outbound"
      );
    }
    if (!this.filterjobs.proposal) {
      filtjobs = filtjobs.filter((job) => job.status !== "Proposal");
    }
    if (!this.filterjobs.lead) {
      filtjobs = filtjobs.filter((job) => job.status !== "Lead");
      filtjobs = filtjobs.filter((job) => job.status !== "Estimation");
    }
    if (!this.filterjobs.booked) {
      filtjobs = filtjobs.filter((job) => job.status !== "Booked");
    }
    if (!this.filterjobs.converted) {
      filtjobs = filtjobs.filter((job) => job.status !== "Converted");
    }
    if (!this.filterjobs.cancelled) {
      filtjobs = filtjobs.filter((job) => job.status !== "Cancelled");
    }
    if (!this.filterjobs.storage) {
      filtjobs = filtjobs.filter((job) => job.storage.length === 0);
      filtjobs = filtjobs.filter((job) => job.storage !== "Only Storage");
    }

    if (this.selectedstaff !== "") {
      if (this.selectedstaff === "empty") {
        filtjobs = filtjobs.filter((job) => job.emfocalpoint === "");
      } else {
        filtjobs = filtjobs.filter(
          (job) => job.emfocalpoint === this.selectedstaff
        );
      }
    }

    if (this.queryEvent !== "") {
      filtjobs = filtjobs.filter((job) =>
        `${job.title} ${job.phone} ${job.email}`
          .toLowerCase()
          .includes(this.queryEvent.toLowerCase())
      );
    }

    return filtjobs;
  }

  get filterWorkSchedule() {
    let filtjobs = this.jobdates;
    filtjobs = filtjobs.filter((job) => job.status !== "Cancelled");
    filtjobs = filtjobs.filter((job) => job.status !== "Lead");
    filtjobs = filtjobs.filter((job) => job.status !== "Proposal");
    filtjobs = filtjobs.filter(
      (job) =>
        moment(job.start).format("DD-MMM-YY") ===
        moment(settingStore.workscheddate).format("DD-MMM-YY")
    );
    return filtjobs;
  }
  get filterEstimationWorkSchedule() {
    let filtjobs = this.jobdates;
    filtjobs = filtjobs.filter((job) => job.status !== "Cancelled");
    filtjobs = filtjobs.filter(
      (job) =>
        moment(job.estimationdate).format("DD-MMM-YY") ===
        moment(settingStore.workscheddate).format("DD-MMM-YY")
    );
    return filtjobs;
  }
  get filterBooked() {
    let filtjobs = this.jobs;
    filtjobs = filtjobs.filter((job) => job.status === "Booked");
    return filtjobs;
  }
  get filterConverted() {
    let filtjobs = this.jobs;
    filtjobs = filtjobs.filter((job) => job.status === "Converted");
    return filtjobs;
  }

  get filterConBooked() {
    let filtjobs = this.jobdates;
    filtjobs = filtjobs.filter(
      (job) => job.status === "Booked" || job.status === "Converted"
    );
    return filtjobs;
  }

  selectJobForTL(job, type) {
    if (type === "reset") {
      this.jobforTL = {};
    } else if (type === "add") {
      this.jobforTL = job;
    } else {
      this.jobforTL.teamLeader = type;
    }
  }
  selectJobForOffice(job, type) {
    if (type === "reset") {
      this.jobforOffice = {};
    } else if (type === "add") {
      this.jobforOffice = job;
    } else {
      this.jobforOffice.emfocalpoint = type;
    }
  }

  updateTLjob(newjob) {
    let indexval = this.jobs.findIndex((joblist) => joblist.id === newjob.id);
    this.jobs.splice(indexval, 1);
    this.jobs.push(newjob);
  }

  selectStorage(event) {
    this.selectedStorage = event;
  }

  changePaymentUpdate(val) {
    this.paymentupdate = val;
  }

  selectPayment(data) {
    this.newpayment = data;
  }

  updatePayment(val, type) {
    if (settingStore.edit === false) {
      alert("Please enable edit to make changes.");
    } else {
      if (type === "paymentdate") {
        this.newpayment.paymentdate = val;
      } else if (type === "category1") {
        this.newpayment.category1 = val;
      } else if (type === "category2") {
        this.newpayment.category2 = val;
      } else if (type === "category3") {
        this.newpayment.category3 = val;
      } else if (type === "category4") {
        this.newpayment.category4 = val;
      } else if (type === "category5") {
        this.newpayment.category5 = val;
      } else if (type === "paymenttype") {
        this.newpayment.paymenttype = val;
      } else if (type === "paymentdetail") {
        this.newpayment.paymentdetail = val;
      } else if (type === "bankaccount") {
        this.newpayment.bankaccount = val;
      } else if (type === "remarks") {
        this.newpayment.remarks = val;
      } else if (type === "paymentmethod") {
        this.newpayment.paymentmethod = val;
      } else if (type === "invoiceamount") {
        this.newpayment.invoiceamount = val;
      } else if (type === "invoicenumber") {
        this.newpayment.invoicenumber = val;
      } else if (type === "vouchernumber") {
        this.newpayment.vouchernumber = val;
      } else if (type === "expenseheading") {
        this.newpayment.expenseheading = val;
      }
    }
  }

  postNewPaymentUpdate(paymentdata, type) {
    let data = {
      job: paymentdata.job,
      id: paymentdata.id,
      invoiceamount: paymentdata.invoiceamount,
      paymentdate: moment(paymentdata.paymentdate).format("YYYY-MM-DD"),
      category1: paymentdata.category1,
      category2: paymentdata.category2,
      category3: paymentdata.category3,
      category4: paymentdata.category4,
      category5: paymentdata.category5,
      paymenttype: paymentdata.paymenttype,
      paymentdetail: paymentdata.paymentdetail,
      bankaccount: paymentdata.bankaccount,
      remarks: paymentdata.remarks,
      paymentmethod: paymentdata.paymentmethod,
      vouchernumber: paymentdata.vouchernumber,
      invoicenumber: paymentdata.invoicenumber,
      expenseheading: paymentdata.expenseheading,
    };

    ReactGA.initialize("UA-163244126-1");
    ReactGA.event({
      category: "New Payment",
      action: userStore.user.username,
      label: new Date().toLocaleTimeString(),
    });

    if (type === "post") {
      if (data.id === 0) {
        return instance
          .post("/c-payment/", data)
          .then((res) => res.data)
          .then((events) => {
            alert(`Payment Created.`);
            this.fetchFinancials();
            this.paymentupdate = false;
          })
          .catch((err) => {
            this.errorEmail(err.response, "New Payment");
            alert(
              "An error occurred. The data did NOT save. Please contact me (97666886)." +
                "\n" +
                JSON.stringify(err.response)
            );

            console.error(err.response);
          });
      } else {
        return instance
          .put("/payment/" + data.id + "/", data)
          .then((res) => res.data)
          .then((events) => {
            alert(`Payment #${data.id}  Updated.`);
            // this.fetchFinancials();
            this.paymentupdate = false;
          })
          .catch((err) => {
            this.errorEmail(err.response, "Update Payment");
            alert(
              "An error occurred. The data did NOT save. Please contact me (97666886)." +
                "\n" +
                JSON.stringify(err.response)
            );

            console.error(err.response);
          });
      }
    } else if (type === "reset") {
      this.newpayment = {
        job: null,
        id: 0,
        invoiceamount: 0,
        paymentdate: moment(new Date()).format("YYYY-MM-DD"),
        category1: "",
        category2: "",
        category3: "",
        category4: "",
        category5: "",
        paymenttype: "",
        paymentdetail: "",
        bankaccount: "",
        remarks: "",
        paymentmethod: "",
        vouchernumber: "",
        invoicenumber: "",
        expenseheading: "No",
      };
      this.paymentupdate = false;
    } else if (type === "Boxes") {
      this.newpayment = {
        job: null,
        id: 0,
        invoiceamount: 0,
        paymentdate: moment(new Date()).format("YYYY-MM-DD"),
        category1: "Local Move",
        category2: "Expense Variable",
        category3: "Cash",
        category4: "Materials",
        category5: "Boxes",
        paymenttype: "",
        paymentdetail: "",
        bankaccount: "Cash",
        remarks: "",
        paymentmethod: "Cash",
        vouchernumber: "",
        invoicenumber: "",
        expenseheading: "No",
      };
      this.paymentupdate = false;
    } else if (type === "Gas") {
      this.newpayment = {
        job: null,
        id: 0,
        invoiceamount: 0,
        paymentdate: moment(new Date()).format("YYYY-MM-DD"),
        category1: "Local Move",
        category2: "Expense Variable",
        category3: "Cash",
        category4: "Vehicle",
        category5: "Gas",
        paymenttype: "",
        paymentdetail: "",
        bankaccount: "Cash",
        remarks: "",
        paymentmethod: "Cash",
        vouchernumber: "",
        invoicenumber: "",
        expenseheading: "No",
      };
      this.paymentupdate = false;
    } else if (type === "delete") {
      return instance
        .delete("/d-payment/" + data.id + "/")
        .then((res) => res.data)
        .then((events) => {
          // console.log("successfully added");
          alert(`Payment Record Deleted.`);
          this.fetchFinancials();
          this.paymentupdate = false;
        })
        .catch((err) => {
          alert(
            "An error occurred. The data did NOT save. Please contact me (97666886)."
          );
          console.error(err.response);
        });
    }
  }

  get searchableJobs() {
    let filtjobs = this.jobs;
    if (this.queryEvent !== "") {
      filtjobs = filtjobs.filter((job) =>
        `${job.title} ${job.phone1} ${job.phone1} ${job.company} ${job.email}`
          .toLowerCase()
          .includes(this.queryEvent.toLowerCase())
      );
    }
    return filtjobs;
  }

  get missingHeardOfUs() {
    return this.clients.filter((client) => client.heardofus === "");
  }

  get financialrows() {
    let paymentrows = this.financials;
    if (userStore.access > 2.5) {
      paymentrows = paymentrows.filter(
        (payment) => payment.category2 !== "Expense Fixed"
      );
    }
    return paymentrows;
  }
  get financialprofitloss() {
    let paymentrows = this.financials;
    for (let i = 0; i < paymentrows.length; i++) {
      if (
        paymentrows[i].category2 === "Income" ||
        paymentrows[i].category2 === "Transfer In"
      ) {
        paymentrows[i].newinvoice = parseFloat(paymentrows[i].invoiceamount);
      } else {
        paymentrows[i].newinvoice =
          -1 * parseFloat(paymentrows[i].invoiceamount);
      }
    }

    return paymentrows;
  }

  get storageConverted() {
    return this.storageJob.filter((job) => job.status === "Converted");
  }

  get storageUnpaidJobs() {
    let unpaidStorage = this.storageJob.filter(
      (job) =>
        (moment(job.storagepaidto) < moment(job.storageenddate)) &
        (job.status === "Converted")
    );

    for (let i = 0; i < unpaidStorage.length; i++) {
      unpaidStorage[i].monthsbehind = Math.round(
        moment(unpaidStorage[i].storageenddate).diff(
          moment(unpaidStorage[i].storagepaidto),
          "months",
          true
        )
      );
      unpaidStorage[i].totalremaining =
        Math.round(
          1000 *
            Math.round(unpaidStorage[i].monthsbehind) *
            unpaidStorage[i].monthlycost
        ) / 1000;
    }
    return unpaidStorage;
  }
  get storageCurrent() {
    return this.storageJob.filter(
      (job) =>
        (moment(job.storagestartdate) <= moment(new Date())) &
        (moment(job.storageenddate) >= moment(new Date())) &
        (job.status === "Converted")
    );
  }
  get storagePotential() {
    return this.storageJob.filter(
      (job) => (job.status !== "Converted") & (job.status !== "Cancelled")
    );
  }
  get storageOldNotClosed() {
    return this.storageJob.filter(
      (job) =>
        (job.status === "Converted") &
        (moment(job.storageenddate) < moment(new Date())) &
        (job.storagestatus !== "Completed")
    );
  }
  get storageOldClosed() {
    return this.storageJob.filter(
      (job) =>
        (job.status === "Converted") &
        (moment(job.storageenddate) < moment(new Date())) &
        (job.storagestatus === "Completed")
    );
  }
}

decorate(EventStore, {
  clients: observable,
  fullclientlist: observable,
  jobs: observable,
  jobdates: observable,
  teamleads: observable,
  allstaff: observable,
  officestaff: observable,
  selectedstaff: observable,
  selectedjobdate: observable,
  selectedClient: observable,
  editClient: observable,
  editJob: observable,
  services: observable,
  filterjobs: observable,
  excludedservices: observable,
  queryEvent: observable,
  jobforTL: observable,
  jobforOffice: observable,
  includedServices: computed,
  excludedServicesCalc: computed,
  dashboardType: observable,
  storageJob: observable,
  selectedStorage: observable,
  financials: observable,
  financialsLoaded: observable,
  paymentupdate: observable,
  newpayment: observable,
  financialrows: computed,
  filteredlocal: computed,
  searchableJobs: computed,
  filteredCal: computed,
  filterWorkSchedule: computed,
  filterEstimationWorkSchedule: computed,
  filteredEstCal: computed,
  filterBooked: computed,
  filterConverted: computed,
  filterConBooked: computed,
  financialprofitloss: computed,
  filteredUserJobs: computed,
  qualityScore: computed,
  missingHeardOfUs: computed,
  storageUnpaidJobs: computed,
  storageCurrent: computed,
  storagePotential: computed,
  storageOldNotClosed: computed,
  storageOldClosed: computed,
});

const eventStore = new EventStore();

// eventStore.fetchAllClients();

export default eventStore;
