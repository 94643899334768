import React from "react";
import { observer } from "mobx-react";
import { Button, Col, Row, InputGroup, FormControl } from "react-bootstrap";

import newEventStore from "../Stores/NewEventStore";
import managementStore from "../Stores/ManagementStore";

class FilterModule extends React.Component {
  render() {
    let filtertype = this.props.type;
    let searchtype = this.props.search;

    let convertedButtonStyleOn = {
      color: "white",
      backgroundColor: "#003705",
      borderColor: "black",
    };
    let convertedButtonStyleOff = {
      color: "#003705",
      backgroundColor: "white",
      borderColor: "black",
    };

    let staffbuttons = newEventStore.officestaff.map((staff, index) => (
      <Col key={index}>
        <Button
          variant={
            newEventStore.selectedstaff === staff.first_name
              ? "dark"
              : "outline-dark"
          }
          onClick={() => newEventStore.filterStaff(staff.first_name)}
          block
        >
          {staff.first_name}
        </Button>
        <br />
      </Col>
    ));

    let searchCode = (
      <InputGroup size="sm" className="mb-3">
        <InputGroup.Prepend>
          <InputGroup.Text id="inputGroup-sizing-sm">
            Search Clients
          </InputGroup.Text>
        </InputGroup.Prepend>
        <FormControl
          aria-label="Small"
          aria-describedby="inputGroup-sizing-sm"
          value={newEventStore.filterjobs.queryEvent}
          onChange={(e) => {
            newEventStore.searchJobs(e.target.value);
          }}
        />
      </InputGroup>
    );

    if (filtertype === "Calendar") {
      return (
        <div>
          <Row>
            <Col xs={12} md={4}>
              <Button
                onClick={() => newEventStore.changeDashboard("All")}
                variant={
                  newEventStore.dashboardType === "All"
                    ? "dark"
                    : "outline-dark"
                }
                block
              >
                All
              </Button>
            </Col>
            <Col xs={12} md={4}>
              <Button
                onClick={() => newEventStore.changeDashboard("Local")}
                variant={
                  newEventStore.dashboardType === "Local"
                    ? "dark"
                    : "outline-dark"
                }
                block
              >
                Local
              </Button>
            </Col>
            <Col xs={12} md={4}>
              <Button
                onClick={() => newEventStore.changeDashboard("im")}
                variant={
                  newEventStore.dashboardType === "im" ? "dark" : "outline-dark"
                }
                block
              >
                IM
              </Button>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col xs={12} md={4} lg={2}>
              <Button
                style={
                  newEventStore.filtercal.converted
                    ? convertedButtonStyleOn
                    : convertedButtonStyleOff
                }
                onClick={() => newEventStore.changeJobFilter("converted")}
                block
              >
                Converted
              </Button>
            </Col>
            <Col xs={12} md={4} lg={2}>
              <Button
                variant={
                  newEventStore.filtercal.booked ? "success" : "outline-success"
                }
                onClick={() => newEventStore.changeJobFilter("booked")}
                block
              >
                Booked
              </Button>
            </Col>
            <Col xs={12} md={4} lg={2}>
              <Button
                block
                variant={
                  newEventStore.filtercal.proposal
                    ? "warning"
                    : "outline-warning"
                }
                onClick={() => newEventStore.changeJobFilter("proposal")}
              >
                Proposal
              </Button>
            </Col>
            <Col xs={12} md={4} lg={2}>
              <Button
                block
                variant={
                  newEventStore.filtercal.lead
                    ? "secondary"
                    : "outline-secondary"
                }
                style={{ outline: "black" }}
                onClick={() => newEventStore.changeJobFilter("lead")}
              >
                Lead
              </Button>
            </Col>
            <Col xs={12} md={4} lg={2}>
              {newEventStore.cancLoaded && (
                <Button
                  block
                  variant={
                    newEventStore.filtercal.cancelled
                      ? "danger"
                      : "outline-danger"
                  }
                  style={{ outline: "black" }}
                  onClick={() => newEventStore.changeJobFilter("cancelled")}
                >
                  Cancelled
                </Button>
              )}
            </Col>

            <Col xs={12} md={4} lg={2}>
              <Button
                variant="outline-danger"
                onClick={() => newEventStore.changeJobFilter("reset")}
                block
              >
                Reset Filter
              </Button>
            </Col>
          </Row>
        </div>
      );
    }
    if (filtertype === "Converted") {
      return (
        <div>
          <Row>
            <Col xs={12} md={4}>
              <Button
                onClick={() => newEventStore.changeDashboard("All")}
                variant={
                  newEventStore.dashboardType === "All"
                    ? "dark"
                    : "outline-dark"
                }
                block
              >
                All
              </Button>
            </Col>
            <Col xs={12} md={4}>
              <Button
                onClick={() => newEventStore.changeDashboard("Local")}
                variant={
                  newEventStore.dashboardType === "Local"
                    ? "dark"
                    : "outline-dark"
                }
                block
              >
                Local
              </Button>
            </Col>
            <Col xs={12} md={4}>
              <Button
                onClick={() => newEventStore.changeDashboard("im")}
                variant={
                  newEventStore.dashboardType === "im" ? "dark" : "outline-dark"
                }
                block
              >
                IM
              </Button>
            </Col>
          </Row>
          <hr />
          <Row>
            {staffbuttons}
            <Col>
              <Button
                variant={
                  newEventStore.selectedstaff === "empty"
                    ? "warning"
                    : "outline-warning"
                }
                onClick={() => newEventStore.filterStaff("empty")}
                block
              >
                No Focal Point
              </Button>
            </Col>
            <Col>
              <Button
                variant="outline-danger"
                onClick={() => newEventStore.filterStaff("reset")}
                block
              >
                Reset
              </Button>
            </Col>
          </Row>
          <hr />
        </div>
      );
    }
    if (filtertype === "Pipeline") {
      return (
        <div>
          <Row>
            <Col xs={12} md={4}>
              <Button
                onClick={() => managementStore.changeDashboard("All")}
                variant={
                  managementStore.filtType === "All" ? "dark" : "outline-dark"
                }
                block
              >
                All
              </Button>
            </Col>
            <Col xs={12} md={4}>
              <Button
                onClick={() => managementStore.changeDashboard("Local")}
                variant={
                  managementStore.filtType === "Local" ? "dark" : "outline-dark"
                }
                block
              >
                Local
              </Button>
            </Col>
            <Col xs={12} md={4}>
              <Button
                onClick={() => managementStore.changeDashboard("im")}
                variant={
                  managementStore.filtType === "im" ? "dark" : "outline-dark"
                }
                block
              >
                IM
              </Button>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col xs={12} md={4} lg={2}>
              <Button
                style={
                  managementStore.filterjobs.converted
                    ? convertedButtonStyleOn
                    : convertedButtonStyleOff
                }
                onClick={() => managementStore.changeJobFilter("converted")}
                block
              >
                Converted
              </Button>
            </Col>
            <Col xs={12} md={4} lg={2}>
              <Button
                variant={
                  managementStore.filterjobs.booked
                    ? "success"
                    : "outline-success"
                }
                onClick={() => managementStore.changeJobFilter("booked")}
                block
              >
                Booked
              </Button>
            </Col>
            <Col xs={12} md={4} lg={2}>
              <Button
                block
                variant={
                  managementStore.filterjobs.proposal
                    ? "warning"
                    : "outline-warning"
                }
                onClick={() => managementStore.changeJobFilter("proposal")}
              >
                Proposal
              </Button>
            </Col>
            <Col xs={12} md={4} lg={2}>
              <Button
                block
                variant={
                  managementStore.filterjobs.lead
                    ? "secondary"
                    : "outline-secondary"
                }
                style={{ outline: "black" }}
                onClick={() => managementStore.changeJobFilter("lead")}
              >
                Lead
              </Button>
            </Col>
            <Col xs={12} md={4} lg={2}>
              <Button
                variant="outline-danger"
                onClick={() => managementStore.changeJobFilter("reset")}
                block
              >
                Reset Filter
              </Button>
            </Col>
          </Row>
        </div>
      );
    }
    if (filtertype === "Storage") {
      return (
        <div>
          <Row>
            <Col xs={12} md={4} lg={2}>
              <Button
                style={
                  managementStore.filterjobs.converted
                    ? convertedButtonStyleOn
                    : convertedButtonStyleOff
                }
                onClick={() => managementStore.changeJobFilter("converted")}
                block
              >
                Converted
              </Button>
            </Col>
            <Col xs={12} md={4} lg={2}>
              <Button
                variant={
                  managementStore.filterjobs.booked
                    ? "success"
                    : "outline-success"
                }
                onClick={() => managementStore.changeJobFilter("booked")}
                block
              >
                Booked
              </Button>
            </Col>
            <Col xs={12} md={4} lg={2}>
              <Button
                block
                variant={
                  managementStore.filterjobs.proposal
                    ? "warning"
                    : "outline-warning"
                }
                onClick={() => managementStore.changeJobFilter("proposal")}
              >
                Proposal
              </Button>
            </Col>
            <Col xs={12} md={4} lg={2}>
              <Button
                block
                variant={
                  managementStore.filterjobs.lead
                    ? "secondary"
                    : "outline-secondary"
                }
                style={{ outline: "black" }}
                onClick={() => managementStore.changeJobFilter("lead")}
              >
                Lead
              </Button>
            </Col>
            <Col xs={12} md={4} lg={2}>
              <Button
                variant="outline-danger"
                onClick={() => managementStore.changeJobFilter("reset")}
                block
              >
                Reset Filter
              </Button>
            </Col>
          </Row>
          <hr />
        </div>
      );
    }

    return (
      <div>
        {!searchtype && searchCode}
        <Row>
          <Col xs={12} md={4}>
            <Button
              onClick={() => newEventStore.changeDashboard("All")}
              variant={
                newEventStore.dashboardType === "All" ? "dark" : "outline-dark"
              }
              block
            >
              All
            </Button>
          </Col>
          <Col xs={12} md={4}>
            <Button
              onClick={() => newEventStore.changeDashboard("Local")}
              variant={
                newEventStore.dashboardType === "Local"
                  ? "dark"
                  : "outline-dark"
              }
              block
            >
              Local
            </Button>
          </Col>
          <Col xs={12} md={4}>
            <Button
              onClick={() => newEventStore.changeDashboard("im")}
              variant={
                newEventStore.dashboardType === "im" ? "dark" : "outline-dark"
              }
              block
            >
              IM
            </Button>
          </Col>
        </Row>
        <hr />
        <Row>
          {staffbuttons}
          <Col>
            <Button
              variant={
                newEventStore.selectedstaff === "empty"
                  ? "warning"
                  : "outline-warning"
              }
              onClick={() => newEventStore.filterStaff("empty")}
              block
            >
              No Focal Point
            </Button>
          </Col>
          <Col>
            <Button
              variant="outline-danger"
              onClick={() => newEventStore.filterStaff("reset")}
              block
            >
              Reset
            </Button>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col xs={12} md={4} lg={2}>
            {newEventStore.convLoaded && (
              <Button
                style={
                  newEventStore.filterjobs.converted
                    ? convertedButtonStyleOn
                    : convertedButtonStyleOff
                }
                onClick={() => newEventStore.changeJobFilter("converted")}
                block
              >
                Converted
              </Button>
            )}
          </Col>
          <Col xs={12} md={4} lg={2}>
            <Button
              variant={
                newEventStore.filterjobs.booked ? "success" : "outline-success"
              }
              onClick={() => newEventStore.changeJobFilter("booked")}
              block
            >
              Booked
            </Button>
          </Col>
          <Col xs={12} md={4} lg={2}>
            <Button
              block
              variant={
                newEventStore.filterjobs.proposal
                  ? "warning"
                  : "outline-warning"
              }
              onClick={() => newEventStore.changeJobFilter("proposal")}
            >
              Proposal
            </Button>
          </Col>
          <Col xs={12} md={4} lg={2}>
            <Button
              block
              variant={
                newEventStore.filterjobs.lead
                  ? "secondary"
                  : "outline-secondary"
              }
              style={{ outline: "black" }}
              onClick={() => newEventStore.changeJobFilter("lead")}
            >
              Lead
            </Button>
          </Col>
          <Col xs={12} md={4} lg={2}>
            {newEventStore.cancLoaded && (
              <Button
                block
                variant={
                  newEventStore.filterjobs.cancelled
                    ? "danger"
                    : "outline-danger"
                }
                style={{ outline: "black" }}
                onClick={() => newEventStore.changeJobFilter("cancelled")}
              >
                Cancelled
              </Button>
            )}
          </Col>

          <Col xs={12} md={4} lg={2}>
            <Button
              variant="outline-danger"
              onClick={() => newEventStore.changeJobFilter("reset")}
              block
            >
              Reset Filter
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default observer(FilterModule);
