import React, { Component } from "react";
import { PDFViewer } from "@react-pdf/renderer";
import { observer } from "mobx-react";
import { Button, Row, Col } from "react-bootstrap";

import proposalFooter from "./easymovefooter.png";
import proposalHeader from "./easymoveheader.png";

import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
} from "@react-pdf/renderer";
import moment from "moment";
import editJobStore from "../Stores/EditJobStore";

Font.register(
  "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
  { family: "Oswald" }
);

const styles = StyleSheet.create({
  body: {
    paddingTop: 10,
    paddingBottom: 5,
    paddingHorizontal: 35,
  },
  unbreakable: { width: "100%", height: 400 },
  title: {
    fontSize: 24,
    textAlign: "center",
    fontFamily: "Oswald",
  },
  author: {
    fontSize: 12,
    textAlign: "center",
    marginBottom: 40,
  },
  subtitle: {
    fontSize: 18,
    margin: 12,
    fontFamily: "Oswald",
  },
  text: {
    margin: 12,
    fontSize: 14,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  text2: {
    fontSize: 10,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  text3: {
    fontSize: 9,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  text4: {
    fontSize: 8,
    textAlign: "justify",
    fontFamily: "Times-Roman",
  },
  image: {
    marginVertical: 15,
    marginHorizontal: 100,
  },
  header: {
    fontSize: 12,
    marginBottom: 20,
    textAlign: "center",
    color: "grey",
    fontWeight: "ultrabold",
    textDecoration: "underline",
  },
  header2: {
    fontSize: 12,
    textAlign: "left",
    color: "grey",
    fontWeight: "italic",
    textDecoration: "underline",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  headerTite: {
    fontSize: 14,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderRightWidth: 0,
    borderBottomWidth: 0,
  },
  tableRow: { margin: "auto", flexDirection: "row" },
  tableCol: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderLeftWidth: 0,
    borderTopWidth: 0,
  },
  tableCell: { margin: "auto", marginTop: 5, fontSize: 10 },
  tableHeaderStyle: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 14,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    fontWeight: "bold",
    paddingTop: 6,
  },
  tableRow1b: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 12,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "20%",
  },
  tableRow1c: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 12,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "40%",
  },
  tableRow2a: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 12,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "80%",
  },
  tableRow2b: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontWeight: "bold",
    fontSize: 12,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "20%",
  },
  tableRow2c: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontWeight: "bold",
    fontSize: 12,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "40%",
  },
  tableRow2d: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "grey",
    color: "white",
    fontWeight: "bold",
    fontSize: 12,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "100%",
  },
  tableRow2e: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 12,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "70%",
  },
  tableRow2f: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontWeight: "bold",
    fontSize: 12,
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "30%",
  },
  tableRow3a: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "20%",
  },
  tableRow3b: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "10%",
  },
  tableRow4a: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "10%",
  },
  tableRow4b: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "45%",
  },
  tableRow5a: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "10%",
  },
  tableRow5b: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 12,
    fontWeight: "bold",
    textAlign: "center",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    paddingTop: 6,
    width: "45%",
  },
  subtitle2: {
    fontSize: 14,
    fontFamily: "Oswald",
  },
  subtitle3: {
    fontSize: 12,
    margin: 12,
    fontFamily: "Oswald",
  },
  tableRow7a: {
    color: "black",
    fontSize: 10,
    fontWeight: "bold",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    textAlign: "left",
    paddingTop: 6,
    width: "70%",
  },
  tableRow7b: {
    color: "black",
    fontSize: 10,
    fontWeight: "bold",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    textAlign: "left",
    paddingTop: 6,
    width: "30%",
  },
  tableRow8a: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "#44546a",
    color: "white",
    fontSize: 10,
    fontWeight: "bold",
    fontFamily: "Times-Roman",
    justifyContent: "center",
    textAlign: "left",
    paddingTop: 6,
    width: "20%",
    paddingLeft: 2,
  },
  tableRow8b: {
    borderStyle: "solid",
    borderWidth: 1,
    backgroundColor: "white",
    color: "black",
    fontSize: 10,
    fontFamily: "Times-Roman",
    justifyContent: "center",
    textAlign: "left",
    paddingTop: 6,
    width: "30%",
    paddingLeft: 2,
  },
});

class InvoiceExport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showproposal: false,
      packinglist: true,
      storage: true,
      showdiscount: false,
      bank: "KFH",
    };
  }

  render() {
    let kfhDetails = (
      <View>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.tableRow8a}>Beneficiary Name</Text>
          <Text style={styles.tableRow8b}>Easy move Logistics Co.</Text>
          <Text style={styles.tableRow8a}>Bank Name</Text>
          <Text style={styles.tableRow8b}>Kuwait Finance House</Text>
        </View>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.tableRow8a}>Account Number</Text>
          <Text style={styles.tableRow8b}>0910 1019 7695</Text>
          <Text style={styles.tableRow8a}>Bank Address</Text>
          <Text style={styles.tableRow8b}>Al Faiha Branch</Text>
        </View>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.tableRow8a}>IBAN Number:</Text>
          <Text style={styles.tableRow8b}>
            KW21 KFHO 0000 0000 0009 1010 1976 95
          </Text>
          <Text style={styles.tableRow8a}>SWIFT Code:</Text>
          <Text style={styles.tableRow8b}>KFHOKWKW</Text>
        </View>
      </View>
    );
    let nbkDetails = (
      <View>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.tableRow8a}>Beneficiary Name</Text>
          <Text style={styles.tableRow8b}>Easy move Logistics Co.</Text>
          <Text style={styles.tableRow8a}>Bank Name</Text>
          <Text style={styles.tableRow8b}>National Bank of Kuwait</Text>
        </View>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.tableRow8a}>Account Number</Text>
          <Text style={styles.tableRow8b}>2022 6861 49</Text>
          <Text style={styles.tableRow8a}>Bank Address</Text>
          <Text style={styles.tableRow8b}>Al Raya Branch</Text>
        </View>
        <View style={{ flexDirection: "row" }}>
          <Text style={styles.tableRow8a}>IBAN Number:</Text>
          <Text style={styles.tableRow8b}>
            KW44 NBOK 0000 0000 0000 2022 6861 49
          </Text>
          <Text style={styles.tableRow8a}>SWIFT Code:</Text>
          <Text style={styles.tableRow8b}>NBOKKWKW</Text>
        </View>
      </View>
    );

    let addcharges;
    addcharges = editJobStore.addcharge.map((charge, index) => (
      <View style={{ flexDirection: "row" }} key={index}>
        <Text style={styles.tableRow2a}>{charge.title}</Text>
        <Text style={styles.tableRow2b}>
          {Math.round(1000 * charge.price) / 1000} KD
        </Text>
      </View>
    ));

    let totaladdchargeprice = 0;
    for (let i = 0; i < editJobStore.addcharge.length; i++) {
      totaladdchargeprice += parseFloat(editJobStore.addcharge[i].price);
    }

    let storageprice = 0;

    for (let i = 0; i < editJobStore.storages.length; i++) {
      if (
        moment(editJobStore.storages[i].storageenddate).diff(
          moment(editJobStore.storages[i].storagestartdate),
          "months",
          true
        ) < 3
      ) {
        storageprice =
          storageprice + 3 * parseFloat(editJobStore.storages[i].monthlycost);
      } else {
        storageprice =
          storageprice +
          editJobStore.storages[i].monthlycost *
            moment(editJobStore.storages[i].storageenddate).diff(
              moment(editJobStore.storages[i].storagestartdate),
              "months",
              true
            );
      }
    }

    storageprice = Math.round(10 * storageprice) / 10;

    let storagecharges;
    storagecharges = editJobStore.storages.map((storage, index) => (
      <View style={{ flexDirection: "row" }} key={index}>
        <Text style={styles.tableRow2a}>
          Storage:{" "}
          {Math.round(
            moment(storage.storageenddate).diff(
              moment(storage.storagestartdate),
              "months",
              true
            )
          )}{" "}
          Months from {moment(storage.storagestartdate).format("DD-MMM-YYYY")}{" "}
          to {moment(storage.storageenddate).format("DD-MMM-YYYY")}{" "}
        </Text>
        <Text style={styles.tableRow2b}>{storageprice} KD</Text>
      </View>
    ));

    let fullproposal = (
      <PDFViewer
        width={"100%"}
        height={600}
        fileName={`Invoice ${editJobStore.job.id}-
                ${moment().format("YYMMDDHH")} for ${
          editJobStore.client.title
        }`}
      >
        <Document
          author={"Easy Move Logistics Co."}
          title={`Invoice ${editJobStore.job.id}-
                ${moment().format("YYMMDDHH")} for ${
            editJobStore.client.title
          }`}
          creator={"Easy Move Logistics Co."}
        >
          <Page size="A4" style={styles.body}>
            <Image src={proposalHeader} />
            <Text style={styles.header}>
              {editJobStore.customproposal.title} Invoice
            </Text>
            <View style={{ flexDirection: "row", lineHeight: 0.3 }}>
              <Text style={styles.tableRow7a}>From:</Text>
              <Text style={styles.tableRow7b}>
                Date: {moment().format("DD-MMM-YY")}
              </Text>
            </View>
            <View style={{ flexDirection: "row", lineHeight: 0.3 }}>
              <Text style={styles.tableRow7a}>Easy Move Logistics Co.</Text>
              <Text style={styles.tableRow7b}>
                Invoice Number: {editJobStore.job.id}-
                {moment().format("YYMMDDHH")}
              </Text>
            </View>
            <View style={{ flexDirection: "row", lineHeight: 0.3 }}>
              <Text style={styles.tableRow7a}>Hawally, Kuwait</Text>
              <Text style={styles.tableRow7b}>
                Service: {editJobStore.job.jobmain}
              </Text>
            </View>
            <View style={{ flexDirection: "row", lineHeight: 0.3 }}>
              <Text style={styles.tableRow7a}>Phone: +965-22060969</Text>
              <Text style={styles.tableRow7b}>
                Type: {editJobStore.job.jobsecond}
              </Text>
            </View>
            <View style={{ flexDirection: "row", lineHeight: 0.3 }}>
              <Text style={styles.tableRow7a}>Email: info@easymovekw.com</Text>
            </View>
            <Text style={{ lineHeight: 2 }}> </Text>
            <Text
              style={{
                fontSize: 11,
                fontFamily: "Times-Roman",
                lineHeight: 1.5,
              }}
            >
              To: {editJobStore.client.title},
            </Text>
            <Text
              style={{
                fontSize: 11,
                fontFamily: "Times-Roman",
              }}
            >
              Please find below the details of our fees for professional
              services:
            </Text>
            <Text style={{ lineHeight: 1 }}> </Text>
            <Text style={styles.tableHeaderStyle}>
              Invoiced Cost of Service
            </Text>
            <View style={{ flexDirection: "row" }}>
              <Text style={styles.tableRow2a}>Service Charges (KWD)</Text>
              <Text style={styles.tableRow2b}>
                {this.state.showdiscount
                  ? `${
                      Math.round(1000 * editJobStore.job.firstprice) / 1000
                    } KD`
                  : `${
                      Math.round(1000 * editJobStore.job.lastprice) / 1000
                    } KD`}
              </Text>
            </View>
            {addcharges}
            {storagecharges}
            <View style={{ flexDirection: "row" }}>
              <Text style={styles.tableRow2e}>Total Charges</Text>
              <Text style={styles.tableRow2f}>
                {Math.round(
                  1000 *
                    (parseFloat(editJobStore.job.lastprice) +
                      parseFloat(totaladdchargeprice) +
                      parseFloat(storageprice))
                ) / 1000}{" "}
                KD
              </Text>
            </View>
            <Text> - </Text>
            <Text style={styles.header2}>Note: </Text>
            <View style={{ fontSize: 11, fontFamily: "Times-Roman" }}>
              <Text>
                * Payment can be accepted by Cash, K-Net, Wire Transfer, Online
                Payment, or Cheque.
              </Text>
              <Text>
                * Cheque should be made payable to "Easy Move Logistics Co.
                W.L.L" and crossed A/C Payee.
              </Text>
              <Text>
                * Any Bank related charges or fees related to the transfer must
                be paid by the sender. The invoice amount is the amount that
                should be received by Easy Move.
              </Text>
            </View>
            <Text style={{ lineHeight: 1 }}> </Text>
            <View style={{ fontSize: 11, fontFamily: "Times-Roman" }}>
              <Text>
                If you wish to pay by Bank Transfer, kindly remit to the
                following Bank Account details:
              </Text>
            </View>
            <Text style={{ lineHeight: 0.5 }}> </Text>
            <View>{this.state.bank === "KFH" ? kfhDetails : nbkDetails}</View>
            <Text style={[styles.tableHeaderStyle, { fontSize: 10 }]}>
              Please Quote the Invoice Number ({editJobStore.job.id}-
              {moment().format("YYMMDDHH")})
            </Text>

            <Image
              src={proposalFooter}
              style={{
                position: "absolute",
                bottom: 5,
                left: 0,
                right: 0,
              }}
            />
          </Page>
        </Document>
      </PDFViewer>
    );

    return (
      <div>
        <Row>
          <Col>
            <Button
              block
              variant={this.state.bank === "KFH" ? "dark" : "outline-dark"}
              onClick={() => this.setState({ bank: "KFH" })}
            >
              KFH
            </Button>
          </Col>
          <Col>
            <Button
              block
              variant={this.state.bank === "NBK" ? "dark" : "outline-dark"}
              onClick={() => this.setState({ bank: "NBK" })}
            >
              NBK
            </Button>
          </Col>
        </Row>
        <hr />
        <br />
        <div>{fullproposal}</div>
      </div>
    );
  }
}

export default observer(InvoiceExport);
