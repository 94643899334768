import React, { Component } from "react";
import { observer } from "mobx-react";
import moment from "moment";

import settingStore from "../Stores/SettingStore";
import hrEventStore from "../Stores/HREventStore";
import jobEditStore from "../Stores/JobEditStore";
import { Row, Col } from "react-bootstrap";
import MaterialTable from "material-table";
import { SyncLoader } from "react-spinners";

class FeedbackAndCancellations extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTable: true,
    };
    this.options = {
      defaultSortName: "id", // default sort column name
      // exportCSVBtn: this.createCustomExportCSVButton,
      defaultSortOrder: "asc", // default sort order
      onRowClick: function (job) {
        jobEditStore.selectJob(job.id);
      },
    };
  }

  componentDidMount() {}

  render() {
    if (!hrEventStore.feedbackloaded) {
      hrEventStore.fetchFeedback();
      return (
        <SyncLoader
          sizeUnit={"px"}
          size={50}
          color={"#0017FF"}
          loading={true}
        />
      );
    }

    let cutoffdate = moment(settingStore.calstartdate);
    let convclients = hrEventStore.convfeedback.filter(
      (job) => cutoffdate.diff(moment(job.converteddate), "days") <= 0
    );
    let cancclients = hrEventStore.cancfeedback.filter(
      (job) => cutoffdate.diff(moment(job.cancellationdate), "days") <= 0
    );

    let canccolumns = [
      { title: "ID", field: "jobid", removable: true },
      { title: "Client", field: "title", removable: true },
      { title: "Type", field: "jobmain", removable: true },
      { title: "Status", field: "status", removable: true },
      {
        title: "Date",
        field: "cancellationdate",
        removable: true,
        defaultSort: "desc",
      },
      { title: "Reason", field: "cancellationreason", removable: true },
      { title: "Remark", field: "cancellationremarks", removable: true },
      { title: "Focal Point", field: "emfocalpoint", removable: true },
    ];
    let convcolumns = [
      { title: "ID", field: "jobid", removable: true },
      { title: "Client", field: "title", removable: true },
      { title: "Type", field: "jobmain", removable: true },
      { title: "Status", field: "status", removable: true },
      {
        title: "Date",
        field: "converteddate",
        removable: true,
        defaultSort: "desc",
      },
      { title: "Rating", field: "rating", removable: true },
      { title: "Feedback", field: "feedback", removable: true },
      { title: "Focal Point", field: "emfocalpoint", removable: true },
    ];

    return (
      <div>
        <Row>
          <Col>
            <MaterialTable
              title={"Cancellation Feedback " + cancclients.length + " jobs"}
              columns={canccolumns}
              data={cancclients}
              paging={false}
              options={{
                paging: false,
                toolbarButtonAlignment: "left",
                tableLayout: "auto",
                grouping: true,
                padding: "dense",
                maxBodyHeight: 300,
              }}
            />
          </Col>
          <Col>
            <MaterialTable
              title={"Converted Feedback " + convclients.length + " jobs"}
              columns={convcolumns}
              data={convclients}
              paging={false}
              options={{
                paging: false,
                toolbarButtonAlignment: "left",
                tableLayout: "auto",
                grouping: true,
                padding: "dense",
                maxBodyHeight: 300,
              }}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

export default observer(FeedbackAndCancellations);
