import React from "react";
// First way to import
import { observer } from "mobx-react";
import { Button, Container, Row, Col } from "react-bootstrap";
import editJobStore from "../../Stores/EditJobStore";
import PaymentRow from "./PaymentRow";

import "react-datepicker/dist/react-datepicker.css";

class PaymentPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let paymentRows;

    paymentRows = editJobStore.payments.map((payment, index) => (
      <div key={index}>
        <h5>
          Payment {index + 1}:{" "}
          {payment.id === 0 ? "Save to get ID" : `ID # ${payment.id}`}
        </h5>
        <PaymentRow payment={payment} indexVal={index} />
      </div>
    ));

    return (
      <div>
        <Container style={{ maxWidth: "98%" }}>
          <h3>{editJobStore.payments.length} Payment Details</h3>
          <form>{paymentRows}</form>
          <hr />
          <Row>
            <Col>
              <Button
                block
                variant="success"
                onClick={() => editJobStore.updatePayment("add", "Cash", 0)}
              >
                Add Cash Payment
              </Button>
            </Col>
            <Col>
              <Button
                block
                variant="dark"
                onClick={() =>
                  editJobStore.updatePayment("add", "Online Link", 0)
                }
              >
                Add Online Link Payment
              </Button>
            </Col>
            <Col>
              <Button
                block
                variant="dark"
                onClick={() => editJobStore.updatePayment("add", "KNET", 0)}
              >
                Add KNET Payment
              </Button>
            </Col>
            <Col>
              <Button
                block
                variant="outline-danger"
                onClick={() =>
                  editJobStore.updatePayment("add", "Bank Charge", 0)
                }
              >
                Add Bank Charge
              </Button>
            </Col>
            <Col>
              <Button
                block
                variant="outline-danger"
                onClick={() =>
                  editJobStore.updatePayment("add", "Shipping Expense", 0)
                }
              >
                Add Shipping Expense
              </Button>
            </Col>
            <Col>
              <Button
                block
                variant="danger"
                onClick={() => editJobStore.updatePayment("add", "Refund", 0)}
              >
                Add Refund
              </Button>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default observer(PaymentPage);
