import React from "react";
// First way to import
import { observer } from "mobx-react";
import { Button, Modal, Row, Col } from "react-bootstrap";

import TextField from "@material-ui/core/TextField";
import editJobStore from "../../Stores/EditJobStore";

import "react-datepicker/dist/react-datepicker.css";
import ItemsPage from "./ItemsPage";
import FullDimensionsPacking from "../PackingListVersions/FullDimensionsPacking";
import OnlyCBMPacking from "../PackingListVersions/OnlyCBMPacking";

class RoomsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { show: false, newroom: "", list: "none" };
    this.handleOpen = this.handleOpen.bind(this);
    this.newRoomName = this.newRoomName.bind(this);
  }
  newRoomName = (e) => {
    this.setState({ newroom: e.target.value });
  };

  handleClose = () => {
    this.setState({ show: false, newroom: "" });
  };
  handleOpen = (e) => {
    this.setState({ show: true });
  };

  render() {
    let roomRows;

    roomRows = editJobStore.rooms.map((room, index) => (
      <div key={index}>
        <ItemsPage roomindex={index} />
      </div>
    ));
    return (
      <div>
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Modal heading</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <TextField
              size="small"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Room Name"
              name="newroom"
              autoComplete="newroom"
              autoFocus
              type="text"
              style={{ color: "red" }}
              value={this.state.newroom}
              onChange={(e) => this.newRoomName(e)}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                editJobStore.addRoom(this.state.newroom);
                this.handleClose();
              }}
            >
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
        <Button variant="success" onClick={this.handleOpen}>
          + Add Room
        </Button>
        <hr></hr>
        {roomRows}
        <hr />
        <Row>
          <Col>
            <Button
              block
              variant={
                this.state.list === "full" ? "outline-danger" : "outline-dark"
              }
              onClick={() => {
                if (this.state.list === "full") {
                  this.setState({ list: "none" });
                } else {
                  this.setState({ list: "full" });
                }
              }}
            >
              {this.state.list === "full"
                ? "Hide Packing List"
                : "Show Full Dimensions"}
            </Button>
          </Col>
          <Col>
            <Button
              block
              variant={
                this.state.list === "cbm" ? "outline-danger" : "outline-dark"
              }
              onClick={() => {
                if (this.state.list === "cbm") {
                  this.setState({ list: "none" });
                } else {
                  this.setState({ list: "cbm" });
                }
              }}
            >
              {this.state.list === "cbm"
                ? "Hide Packing List"
                : "Show CBM Packing List"}
            </Button>
          </Col>
        </Row>
        {this.state.list === "full" && <FullDimensionsPacking />}
        {this.state.list === "cbm" && <OnlyCBMPacking />}
      </div>
    );
  }
}

export default observer(RoomsPage);
