import React, { Component } from "react";
import { observer } from "mobx-react";

import MaterialTable from "material-table";
import newEventStore from "../Stores/NewEventStore";
import FilterModule from "../FilterModules/FilterModule";
import jobSelectionStore from "../Stores/JobSelectionStore";
import JobModal from "../FilterModules/JobModal";

class ClientBalances extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editPayment: false,
      financialsLoaded: false,
      modalShow: false,
      indexVal: 0,
      filter: false,
      show: false,
    };
  }

  componentDidMount() {
    if (!newEventStore.financialloaded) {
      newEventStore.fetchFinancialData();
    }
  }

  handleClose = () => jobSelectionStore.closeModal();

  render() {
    let columnnames = [
      { title: "Job ID", field: "jobid", removable: true },
      { title: "Client", field: "clientname" },
      { title: "Status", field: "status" },
      { title: "Main", field: "jobmain" },
      { title: "Focal Point", field: "emfocalpoint" },
      { title: "Second", field: "jobsecond", removable: true },
      { title: "Move Price", field: "lastprice", removable: true },
      { title: "Additional", field: "addchargeprice", removable: true },
      { title: "Storage", field: "storageprice", removable: true },
      { title: "Payments", field: "customerpayment", removable: true },
      { title: "Balance", field: "clientbalance", removable: true },
      { title: "Last Job", field: "lastjob", removable: true },
    ];

    return (
      <div>
        <JobModal
          show={jobSelectionStore.showModal}
          onHide={this.handleClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        />
        <FilterModule type="Converted" />
        <MaterialTable
          title={
            "Missing Payments " +
            newEventStore.missingPayments.length +
            " Clients"
          }
          columns={columnnames}
          data={newEventStore.missingPayments}
          paging={true}
          options={{
            padding: "dense",
            actionsColumnIndex: 0,
            exportAllData: true,
            paging: true,
            pageSize: 10,
            toolbarButtonAlignment: "left",
            tableLayout: "auto",
            exportButton: true,
            exportFileName: "Missing Payments",
          }}
          actions={[
            (rowData) => ({
              icon: "edit",
              tooltip: "Edit Item",
              onClick: (event, rowData) => {
                jobSelectionStore.fetchSingleJob(rowData.jobid);
              },
            }),
          ]}
        />
        <hr />
        <MaterialTable
          title={
            "Overpaid Clients " + newEventStore.overPaid.length + " Clients"
          }
          columns={columnnames}
          data={newEventStore.overPaid}
          paging={true}
          options={{
            padding: "dense",
            actionsColumnIndex: 0,
            exportAllData: true,
            paging: true,
            pageSize: 5,
            toolbarButtonAlignment: "left",
            tableLayout: "auto",
            exportButton: true,
            exportFileName: "Overpaid Clients",
          }}
          actions={[
            (rowData) => ({
              icon: "edit",
              tooltip: "Edit Item",
              onClick: (event, rowData) => {
                jobSelectionStore.fetchSingleJob(rowData.jobid);
              },
            }),
          ]}
        />
        <hr />
      </div>
    );
  }
}

export default observer(ClientBalances);
