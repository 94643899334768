import React from "react";
import { observer } from "mobx-react";
import {
  Button,
  Container,
  Col,
  Row,
  Card,
  InputGroup,
  FormControl,
} from "react-bootstrap";

import eventStore from "../Stores/EventStore";
import newEventStore from "../Stores/NewEventStore";
import settingStore from "../Stores/SettingStore";
import JobModal from "../FilterModules/JobModal";
import jobSelectionStore from "../Stores/JobSelectionStore";

class LocalSalesDashboard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      event: {},
      modalShow: false,
    };
  }

  componentDidMount() {
    eventStore.changeJobFilter("reset");
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleClose = () => jobSelectionStore.closeModal();

  render() {
    let convertedCard = {
      width: "150px",
      color: "#003705",
      outline: "#003705",
      boxShadow: "1px 1px 1px #003705",
    };
    let convertedButton = {
      backgroundColor: "transparent",
      color: "black",
      fontSize: 11,
      outline: "#003705",
    };

    let jobs;
    if (newEventStore.dashboardJobs.length > 0) {
      jobs = newEventStore.dashboardJobs.map((job, index) => (
        <div key={index}>
          {job.status === "Converted" && (
            <Col>
              <Card key={index} style={convertedCard}>
                <Card.Header as="h4">
                  <Button
                    block
                    onClick={(event) => {
                      jobSelectionStore.fetchSingleJob(job.id);
                    }}
                    style={convertedButton}
                    variant={"outline-dark"}
                    disabled={settingStore.editJob}
                  >
                    {job.id}: {job.clienttitle}
                  </Button>
                </Card.Header>
                <Card.Body>
                  <Card.Title style={{ fontSize: 10 }}>
                    {job.jobmain} - {job.status}
                  </Card.Title>
                  <Card.Title style={{ fontSize: 9 }}>
                    {job.cbm} CBM at {job.lastprice} KD
                  </Card.Title>
                  <Card.Text style={{ fontSize: 8 }}>
                    {job.remarks &&
                      job.remarks.substring(
                        job.remarks.length - 100,
                        job.remarks.length
                      )}
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  <Card.Title style={{ fontSize: 10 }}>
                    <b>Focal Point:</b> {job.emfocalpoint}
                  </Card.Title>
                </Card.Footer>
              </Card>
            </Col>
          )}
          {job.status === "Booked" && (
            <Col>
              <Card
                key={index}
                style={{
                  width: "150px",
                  outline: "#589654",
                  boxShadow: "1px 1px 1px #589654",
                }}
              >
                <Card.Header as="h4">
                  <Button
                    block
                    onClick={(event) => {
                      jobSelectionStore.fetchSingleJob(job.id);
                    }}
                    variant={"outline-success"}
                    style={{
                      backgroundColor: "transparent",
                      color: "black",
                      fontSize: 11,
                    }}
                    disabled={settingStore.editJob}
                  >
                    {job.id}: {job.clienttitle}
                  </Button>
                </Card.Header>
                <Card.Body>
                  <Card.Title style={{ fontSize: 10 }}>
                    {job.jobmain} - {job.status}
                  </Card.Title>
                  <Card.Title style={{ fontSize: 9 }}>
                    {job.cbm} CBM at {job.lastprice} KD
                  </Card.Title>
                  <Card.Text style={{ fontSize: 8 }}>
                    {job.remarks &&
                      job.remarks.substring(
                        job.remarks.length - 100,
                        job.remarks.length
                      )}
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  <Card.Title style={{ fontSize: 10 }}>
                    <b>Focal Point:</b> {job.emfocalpoint}
                  </Card.Title>
                </Card.Footer>
              </Card>
            </Col>
          )}
          {job.status === "Proposal" && (
            <Col>
              <Card
                key={index}
                style={{
                  width: "150px",
                  outline: "#a38c5d",
                  boxShadow: "1px 1px 1px #a38c5d",
                }}
              >
                <Card.Header as="h4">
                  <Button
                    block
                    onClick={(event) => {
                      jobSelectionStore.fetchSingleJob(job.id);
                    }}
                    variant={"outline-warning"}
                    style={{
                      backgroundColor: "transparent",
                      color: "black",
                      fontSize: 11,
                    }}
                    disabled={settingStore.editJob}
                  >
                    {job.id}: {job.clienttitle}
                  </Button>
                </Card.Header>
                <Card.Body>
                  <Card.Title style={{ fontSize: 10 }}>
                    {job.jobmain} - {job.status}
                  </Card.Title>
                  <Card.Title style={{ fontSize: 9 }}>
                    {job.cbm} CBM at {job.lastprice} KD
                  </Card.Title>
                  <Card.Text style={{ fontSize: 8 }}>
                    {job.remarks &&
                      job.remarks.substring(
                        job.remarks.length - 100,
                        job.remarks.length
                      )}
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  <Card.Title style={{ fontSize: 10 }}>
                    <b>Focal Point:</b> {job.emfocalpoint}
                  </Card.Title>
                </Card.Footer>
              </Card>
            </Col>
          )}
          {job.status === "Lead" && (
            <Col>
              <Card
                key={index}
                style={{
                  width: "150px",
                  outline: "#grey",
                  boxShadow: "1px 1px 1px grey",
                }}
              >
                <Card.Header as="h4">
                  <Button
                    block
                    onClick={(event) => {
                      jobSelectionStore.fetchSingleJob(job.id);
                    }}
                    variant={"outline-secondary"}
                    style={{
                      backgroundColor: "transparent",
                      color: "black",
                      fontSize: 11,
                    }}
                    disabled={settingStore.editJob}
                  >
                    {job.id}: {job.clienttitle}
                  </Button>
                </Card.Header>
                <Card.Body>
                  <Card.Title style={{ fontSize: 10 }}>
                    {job.jobmain} - {job.status}
                  </Card.Title>
                  <Card.Title style={{ fontSize: 9 }}>
                    {job.cbm} CBM at {job.lastprice} KD
                  </Card.Title>
                  <Card.Text style={{ fontSize: 8 }}>
                    {job.remarks &&
                      job.remarks.substring(
                        job.remarks.length - 100,
                        job.remarks.length
                      )}
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  <Card.Title style={{ fontSize: 10 }}>
                    <b>Focal Point:</b> {job.emfocalpoint}
                  </Card.Title>
                </Card.Footer>
              </Card>
            </Col>
          )}
          {job.status === "Estimation" && (
            <Col>
              <Card
                key={index}
                style={{
                  width: "150px",
                  outline: "#grey",
                  boxShadow: "1px 1px 1px grey",
                }}
              >
                <Card.Header as="h4">
                  <Button
                    block
                    onClick={(event) => {
                      jobSelectionStore.fetchSingleJob(job.id);
                    }}
                    variant={"outline-secondary"}
                    style={{
                      backgroundColor: "transparent",
                      color: "black",
                      fontSize: 11,
                    }}
                    disabled={settingStore.editJob}
                  >
                    {job.id}: {job.clienttitle}
                  </Button>
                </Card.Header>
                <Card.Body>
                  <Card.Title style={{ fontSize: 10 }}>
                    {job.jobmain} - {job.status}
                  </Card.Title>
                  <Card.Title style={{ fontSize: 9 }}>
                    {job.cbm} CBM at {job.lastprice} KD
                  </Card.Title>
                  <Card.Text style={{ fontSize: 8 }}>
                    {job.remarks &&
                      job.remarks.substring(
                        job.remarks.length - 100,
                        job.remarks.length
                      )}
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  <Card.Title style={{ fontSize: 10 }}>
                    <b>Focal Point:</b> {job.emfocalpoint}
                  </Card.Title>
                </Card.Footer>
              </Card>
            </Col>
          )}
          {job.status === "Cancelled" && (
            <Col>
              <Card
                key={index}
                style={{
                  width: "150px",
                  outline: "#grey",
                  boxShadow: "1px 1px 1px grey",
                }}
              >
                <Card.Header as="h4">
                  <Button
                    block
                    onClick={(event) => {
                      jobSelectionStore.fetchSingleJob(job.id);
                    }}
                    variant={"outline-secondary"}
                    style={{
                      backgroundColor: "transparent",
                      color: "black",
                      fontSize: 11,
                    }}
                    disabled={settingStore.editJob}
                  >
                    {job.id}: {job.clienttitle}
                  </Button>
                </Card.Header>
                <Card.Body>
                  <Card.Title style={{ fontSize: 10 }}>
                    {job.jobmain} - {job.status}
                  </Card.Title>
                  <Card.Title style={{ fontSize: 9 }}>
                    {job.cbm} CBM at {job.lastprice} KD
                  </Card.Title>
                  <Card.Text style={{ fontSize: 8 }}>
                    {job.remarks &&
                      job.remarks.substring(
                        job.remarks.length - 100,
                        job.remarks.length
                      )}
                  </Card.Text>
                </Card.Body>
                <Card.Footer>
                  <Card.Title style={{ fontSize: 10 }}>
                    <b>Focal Point:</b> {job.emfocalpoint}
                  </Card.Title>
                </Card.Footer>
              </Card>
            </Col>
          )}
        </div>
      ));
    }

    let convertedButtonStyleOn = {
      color: "white",
      backgroundColor: "#003705",
      borderColor: "black",
    };
    let convertedButtonStyleOff = {
      color: "#003705",
      backgroundColor: "white",
      borderColor: "black",
    };

    let staffbuttons = newEventStore.officestaff.map((staff, index) => (
      <Col key={index}>
        <Button
          variant={
            newEventStore.selectedstaff === staff.first_name
              ? "dark"
              : "outline-dark"
          }
          onClick={() => newEventStore.filterStaff(staff.first_name)}
          block
        >
          {staff.first_name}
        </Button>
        <br />
      </Col>
    ));

    return (
      <div>
        <JobModal
          show={jobSelectionStore.showModal}
          onHide={this.handleClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        />
        <div fixed="true">
          <Container>
            <h3 style={{ alignText: "center" }}>
              {newEventStore.dashboardType === "im"
                ? "International"
                : newEventStore.dashboardType}
              {" Move "}
              Dashboard
            </h3>
          </Container>
          <InputGroup size="sm" className="mb-3">
            <InputGroup.Prepend>
              <InputGroup.Text id="inputGroup-sizing-sm">
                Search Clients
              </InputGroup.Text>
            </InputGroup.Prepend>
            <FormControl
              aria-label="Small"
              aria-describedby="inputGroup-sizing-sm"
              value={newEventStore.filterjobs.queryEvent}
              onChange={(e) => {
                newEventStore.searchJobs(e.target.value);
              }}
            />
          </InputGroup>
          <Row>
            <Col xs={12} md={4}>
              <Button
                onClick={() => newEventStore.changeDashboard("All")}
                variant={
                  newEventStore.dashboardType === "All"
                    ? "dark"
                    : "outline-dark"
                }
                block
              >
                All
              </Button>
            </Col>
            <Col xs={12} md={4}>
              <Button
                onClick={() => newEventStore.changeDashboard("Local")}
                variant={
                  newEventStore.dashboardType === "Local"
                    ? "dark"
                    : "outline-dark"
                }
                block
              >
                Local
              </Button>
            </Col>
            <Col xs={12} md={4}>
              <Button
                onClick={() => newEventStore.changeDashboard("im")}
                variant={
                  newEventStore.dashboardType === "im" ? "dark" : "outline-dark"
                }
                block
              >
                IM
              </Button>
            </Col>
          </Row>
          <hr />
          <Row>
            {staffbuttons}
            <Col>
              <Button
                variant={
                  newEventStore.selectedstaff === "empty"
                    ? "warning"
                    : "outline-warning"
                }
                onClick={() => newEventStore.filterStaff("empty")}
                block
              >
                No Focal Point
              </Button>
            </Col>
            <Col>
              <Button
                variant="outline-danger"
                onClick={() => newEventStore.filterStaff("reset")}
                block
              >
                Reset
              </Button>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col xs={12} md={4} lg={2}>
              {newEventStore.convLoaded && (
                <Button
                  style={
                    newEventStore.filterjobs.converted
                      ? convertedButtonStyleOn
                      : convertedButtonStyleOff
                  }
                  onClick={() => newEventStore.changeJobFilter("converted")}
                  block
                >
                  Converted
                </Button>
              )}
            </Col>
            <Col xs={12} md={4} lg={2}>
              <Button
                variant={
                  newEventStore.filterjobs.booked
                    ? "success"
                    : "outline-success"
                }
                onClick={() => newEventStore.changeJobFilter("booked")}
                block
              >
                Booked
              </Button>
            </Col>
            <Col xs={12} md={4} lg={2}>
              <Button
                block
                variant={
                  newEventStore.filterjobs.proposal
                    ? "warning"
                    : "outline-warning"
                }
                onClick={() => newEventStore.changeJobFilter("proposal")}
              >
                Proposal
              </Button>
            </Col>
            <Col xs={12} md={4} lg={2}>
              <Button
                block
                variant={
                  newEventStore.filterjobs.lead
                    ? "secondary"
                    : "outline-secondary"
                }
                style={{ outline: "black" }}
                onClick={() => newEventStore.changeJobFilter("lead")}
              >
                Lead
              </Button>
            </Col>
            <Col xs={12} md={4} lg={2}>
              {newEventStore.cancLoaded && (
                <Button
                  block
                  variant={
                    newEventStore.filterjobs.cancelled
                      ? "danger"
                      : "outline-danger"
                  }
                  style={{ outline: "black" }}
                  onClick={() => newEventStore.changeJobFilter("cancelled")}
                >
                  Cancelled
                </Button>
              )}
            </Col>

            <Col xs={12} md={4} lg={2}>
              <Button
                variant="outline-danger"
                onClick={() => newEventStore.changeJobFilter("reset")}
                block
              >
                Reset Filter
              </Button>
            </Col>
          </Row>

          <hr />
          <h3>{newEventStore.dashboardJobs.length} Jobs</h3>
        </div>

        <Row style={{ justifyContent: "flex-start" }}>{jobs}</Row>
      </div>
    );
  }
}

export default observer(LocalSalesDashboard);
