import { decorate, observable, computed } from "mobx";
// import axios from "axios";
import moment from "moment";
import eventStore from "./EventStore";
import emailjs from "emailjs-com";
import userStore from "./UserStore";

class SettingStore {
  constructor() {
    this.edit = true;
    this.newClient = false;
    this.editClient = false;
    this.viewClient = false;
    this.editJob = false;
    this.editJobItem = false;
    this.loading = true;
    this.workscheddate = moment(new Date()).add(1, "days");
    this.startdate = moment(new Date()).startOf("month");
    this.enddate = moment(new Date()).endOf("month");
    this.calstartdate = moment(new Date()).startOf("month");
    this.calenddate = moment(new Date()).endOf("month");
    this.calcDate = new Date();
    this.cancelledClientsLoaded = false;
    this.shortLoading = false;
  }

  // Params: Error
  // 1) activity
  // 2) clientid
  // 3) clienttitle
  // 4) jobid
  // 5) jobmain
  // 6) error

  sendUnifiedEmail(params, type) {
    let template_params = {};
    if (type === "error") {
      template_params = {
        activitytype: params.activity,
        username: userStore.user.username,
        ClientName: params.clienttitle,
        ClientID: params.clientid,
        JobID: params.jobid,
        JobMain: params.jobmain,
        Status: params.status,
        Data: JSON.stringify(params.data),
        consoleerror: JSON.stringify(params.error),
      };
      emailjs.send(
        "default_service",
        "error",
        template_params,
        "user_IaUVUsVD6VMlmBLY50c9p"
      );
    }
  }

  changeWorkScheduleDate(date) {
    this.workscheddate = moment(date);
  }

  resetViews() {
    this.newClient = false;
    this.viewClient = false;
    this.editClient = false;
    this.editJob = false;
    this.editJobItem = false;
    eventStore.removeEdit();
  }

  changeLoading(val) {
    this.loading = val;
  }

  changeShortLoading(val) {
    this.shortLoading = val;
  }

  changeEditJobItem() {
    this.editJobItem = !this.editJobItem;
  }

  changeEdit(val) {
    this.edit = val;
  }
  changeNewClient(val) {
    this.resetViews();
    this.newClient = val;
  }
  changeViewClient(val) {
    this.resetViews();
    this.viewClient = val;
  }
  changeEditClient(val) {
    this.resetViews();
    this.viewClient = val;
    this.editClient = val;
  }
  changeEditJob(val) {
    this.resetViews();
    this.editJob = val;
  }

  changeWorkDate(start, end) {
    this.startdate = moment(start);
    this.enddate = moment(end);
  }

  changeCalDate(date) {
    this.calstartdate = moment(date).startOf("month");
    this.calenddate = moment(date).endOf("month");
  }

  changeCalcDateQuarterly(start, end) {
    this.calstartdate = moment(start).startOf("month");
    this.calenddate = moment(end).endOf("month");
  }

  changeDate(newDay) {
    this.calcDate = new Date(
      moment(newDay).format("YYYY") - 1 + 1,
      moment(newDay).format("MM") - 1,
      1
    );
  }

  changeCancelClientLoad(val) {
    this.cancelledClientsLoaded = val;
  }

  get lockControls() {
    if (this.editClient) {
      return true;
    }
    return false;
  }
}

decorate(SettingStore, {
  edit: observable,
  newClient: observable,
  viewClient: observable,
  editClient: observable,
  editJob: observable,
  editJobItem: observable,
  workscheddate: observable,
  startdate: observable,
  enddate: observable,
  calstartdate: observable,
  calenddate: observable,
  cancelledClientsLoaded: observable,
  lockControls: computed,
  shortLoading: observable,
  loading: observable,
});

const settingStore = new SettingStore();

export default settingStore;
