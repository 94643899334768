import React, { Component } from "react";
import { observer } from "mobx-react";

import managementStore from "../Stores/ManagementStore";

import FilterModule from "../FilterModules/FilterModule";
import settingStore from "../Stores/SettingStore";
import moment from "moment";

import ReactApexChart from "react-apexcharts";

import { SyncLoader } from "react-spinners";

class Pipeline extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options1: {
        chart: {
          type: "bar",
          height: 100,
          stacked: true,
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        xaxis: {
          categories: ["Pipeline", "Target"],
          labels: {
            formatter: function (val) {
              return val + "K";
            },
          },
        },
        colors: ["#003705", "#26A65B", "#d19d00", "#6e757c", "#ffbac9"],
        tooltip: {
          y: {
            formatter: function (val) {
              return 1000 * val + " KD";
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 40,
        },
      },
      options2: {
        chart: {
          type: "bar",
          height: 100,
          stacked: true,
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        xaxis: {
          categories: ["Pipeline", "Target"],
          labels: {
            formatter: function (val) {
              return val;
            },
          },
        },
        colors: ["#003705", "#26A65B", "#d19d00", "#6e757c", "#ffbac9"],
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 40,
        },
      },
    };
  }

  render() {
    if (!managementStore.pipelineloaded) {
      managementStore.fetchPipeline();
      return (
        <SyncLoader
          sizeUnit={"px"}
          size={50}
          color={"#0017FF"}
          loading={true}
        />
      );
    }

    let startDate = moment(settingStore.calstartdate);
    let endDate = moment(settingStore.calenddate);
    let dateDiff = endDate.diff(startDate, "days");
    let jobdates = managementStore.filteredPipeline;

    let totalCBM = 0;
    let totalPrice = 0;
    let totalProfit = 0;
    let totalJobs = [];

    let convCBM = 0;
    let convPrice = 0;
    let convProfit = 0;
    let convJobs = [];
    let bookCBM = 0;
    let bookPrice = 0;
    let bookProfit = 0;
    let bookJobs = [];
    let propCBM = 0;
    let propPrice = 0;
    let propProfit = 0;
    let propJobs = [];
    let leadCBM = 0;
    let leadPrice = 0;
    let leadProfit = 0;
    let leadJobs = [];
    let tarCBM = 1800;
    let tarPrice = 32000;
    let tarProfit = 12000;

    for (let j = 0; j < dateDiff; j++) {
      for (let i = 0; i < jobdates.length; i++) {
        if (
          moment(jobdates[i].jobdate).format("DD-MMM-YY") ===
          moment(startDate).add(j, "days").format("DD-MMM-YY")
        ) {
          if (jobdates[i].status === "Converted") {
            convCBM += parseFloat(jobdates[i].dailycbm);
            convPrice += parseFloat(jobdates[i].dailyprice);
            convProfit += parseFloat(jobdates[i].dailyprofit);
            convJobs.push(jobdates[i].id);
          } else if (jobdates[i].status === "Booked") {
            bookCBM += parseFloat(jobdates[i].dailycbm);
            bookPrice += parseFloat(jobdates[i].dailyprice);
            bookProfit += parseFloat(jobdates[i].dailyprofit);
            bookJobs.push(jobdates[i].id);
          } else if (jobdates[i].status === "Proposal") {
            propCBM += parseFloat(jobdates[i].dailycbm);
            propPrice += parseFloat(jobdates[i].dailyprice);
            propProfit += parseFloat(jobdates[i].dailyprofit);
            propJobs.push(jobdates[i].id);
          } else if (jobdates[i].status === "Lead") {
            leadCBM += parseFloat(jobdates[i].dailycbm);
            leadPrice += parseFloat(jobdates[i].dailyprice);
            leadProfit += parseFloat(jobdates[i].dailyprofit);
            leadJobs.push(jobdates[i].id);
          }
          totalCBM += parseFloat(jobdates[i].dailycbm);
          totalPrice += parseFloat(jobdates[i].dailyprice);
          totalProfit += parseFloat(jobdates[i].dailyprofit);
          totalJobs.push(jobdates[i].id);
        }
      }
    }

    if (managementStore.filtType === "Local") {
      tarCBM = 900;
      tarPrice = 7000;
      tarProfit = 7000;
    } else if (managementStore.filtType === "im") {
      tarCBM = 900;
      tarPrice = 25000;
      tarProfit = 5000;
    }

    totalCBM = Math.round(totalCBM);
    totalPrice = Math.round(totalPrice) / 1000;
    totalProfit = Math.round(totalProfit) / 1000;

    convCBM = Math.round(convCBM);
    convPrice = Math.round(convPrice) / 1000;
    convProfit = Math.round(convProfit) / 1000;
    bookCBM = Math.round(bookCBM);
    bookPrice = Math.round(bookPrice) / 1000;
    bookProfit = Math.round(bookProfit) / 1000;
    propCBM = Math.round(propCBM);
    propPrice = Math.round(propPrice) / 1000;
    propProfit = Math.round(propProfit) / 1000;
    leadCBM = Math.round(leadCBM);
    leadPrice = Math.round(leadPrice) / 1000;
    leadProfit = Math.round(leadProfit) / 1000;
    tarCBM = Math.round(tarCBM);
    tarPrice = Math.round(tarPrice) / 1000;
    tarProfit = Math.round(tarProfit) / 1000;

    let uleads = [...new Set(leadJobs)];
    let uprops = [...new Set(propJobs)];
    let ubooks = [...new Set(bookJobs)];
    let uconvs = [...new Set(convJobs)];
    let utotals = [...new Set(totalJobs)];

    let graphDataPrice = [
      { name: "Converted", data: [convPrice, 0] },
      { name: "Booked", data: [bookPrice, 0] },
      { name: "Proposal", data: [propPrice, 0] },
      { name: "Lead", data: [leadPrice, 0] },
      { name: "Target", data: [0, tarPrice] },
    ];
    let graphDataCBM = [
      { name: "Converted", data: [convCBM, 0] },
      { name: "Booked", data: [bookCBM, 0] },
      { name: "Proposal", data: [propCBM, 0] },
      { name: "Lead", data: [leadCBM, 0] },
      { name: "Target", data: [0, tarCBM] },
    ];
    let graphDataProfit = [
      { name: "Converted", data: [convProfit, 0] },
      { name: "Booked", data: [bookProfit, 0] },
      { name: "Proposal", data: [propProfit, 0] },
      { name: "Lead", data: [leadProfit, 0] },
      { name: "Target", data: [0, tarProfit] },
    ];
    let graphDataJobs = [
      { name: "Converted", data: [uconvs.length, 0] },
      { name: "Booked", data: [ubooks.length, 0] },
      { name: "Proposal", data: [uprops.length, 0] },
      { name: "Lead", data: [uleads.length, 0] },
    ];

    return (
      <div>
        <FilterModule type={"Pipeline"} />
        <h3>
          Total Price: {1000 * totalPrice} KD (
          {Math.round((1000 * totalPrice) / tarPrice) / 10} %)
        </h3>
        <ReactApexChart
          options={this.state.options1}
          series={graphDataPrice}
          type="bar"
          height={200}
        />
        <hr />
        <h3>Total Jobs: {utotals.length} Jobs</h3>
        <ReactApexChart
          options={this.state.options2}
          series={graphDataJobs}
          type="bar"
          height={200}
        />
        <hr />
        <h3>
          Total CBM: {totalCBM} CBM (
          {Math.round((1000 * totalCBM) / tarCBM) / 10}%)
        </h3>
        <ReactApexChart
          options={this.state.options2}
          series={graphDataCBM}
          type="bar"
          height={200}
        />
        <hr />
        <h3>
          Total Profit: {1000 * totalProfit} KD (
          {Math.round((1000 * totalProfit) / tarProfit) / 10}%)
        </h3>
        <ReactApexChart
          options={this.state.options1}
          series={graphDataProfit}
          type="bar"
          height={200}
        />
        <hr />
        <br />
        <hr />
        <br />
        <br />
        <br />
      </div>
    );
  }
}

export default observer(Pipeline);
