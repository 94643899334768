import React from "react";
// First way to import
import { observer } from "mobx-react";
import { Button, Container, Row, Col } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import { Select } from "antd";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import moment from "moment";

import editJobStore from "../../Stores/EditJobStore";

const { Option } = Select;

class JobDatesPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let excludeDates = [];

    for (let i = 0; i < editJobStore.jobdates.length; i++) {
      excludeDates.push(Date.parse(editJobStore.jobdates[i].jobdate));
    }

    let dates = editJobStore.jobdates.map((date, index) => (
      <div key={index}>
        <Row>
          <Col md={3}>
            <Row>
              <b>Job Date #{index + 1}:</b>
            </Row>
            <Row>
              <DatePicker
                popperPlacement="right-start"
                withPortal
                onChange={(date) =>
                  editJobStore.updateJobDate(
                    "jobdate",
                    moment(date).format("YYYY-MM-DD"),
                    index
                  )
                }
                dateFormat="dd-MMM-yy"
                monthsShown={2}
                selected={Date.parse(date.jobdate)}
                excludeDates={excludeDates}
              />
            </Row>
          </Col>

          <Col md={3}>
            <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              size="small"
            >
              <b
                style={{
                  color: date.status === "" ? "red" : "black",
                }}
              >
                Status:
              </b>
              <Select
                showSearch
                error
                width="100%"
                optionFilterProp="children"
                onChange={(e) => editJobStore.updateJobDate("status", e, index)}
                value={date.status}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value={""}></Option>
                <Option value={"Pending"}>Pending</Option>
                <Option value={"Complete"}>Complete</Option>
              </Select>
            </FormControl>
          </Col>

          <Col>
            <b>Date Remarks:</b>
            <TextField
              variant="outlined"
              size="small"
              style={{ width: "100%" }}
              value={date.remarks}
              onChange={(e) =>
                editJobStore.updateJobDate("remarks", e.target.value, index)
              }
            />
          </Col>
          <Col md={1}>
            <Row>Delete</Row>
            <Row>
              <Button
                variant="outline-danger"
                block
                size="sm"
                onClick={() => editJobStore.updateJobDate("delete", 0, index)}
                md={2}
              >
                x
              </Button>
            </Row>
          </Col>
        </Row>
      </div>
    ));

    return (
      <div>
        <Container style={{ maxWidth: "98%" }}>
          <Row>
            <Col>
              <Button
                variant="outline-success"
                onClick={() => editJobStore.updateJobDate("add", 0, 0)}
              >
                Add a Job Date
              </Button>
            </Col>
            <Col>
              <h3>{editJobStore.jobdates.length} Job Dates:</h3>
            </Col>
          </Row>
          <hr />
          <br />
          {dates}
          <br />
          <br />
        </Container>
      </div>
    );
  }
}

export default observer(JobDatesPage);
