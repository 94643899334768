import React, { Component } from "react";
import {
  Nav,
  Navbar,
  Row,
  Button,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { observer } from "mobx-react";
import moment from "moment";

import newEventStore from "../Stores/NewEventStore";
import storageStore from "../Stores/StorageStore";
import userStore from "../Stores/UserStore";
import settingStore from "../Stores/SettingStore";

class FooterPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      start: moment().startOf("month"),
      end: moment().add(1, "M").startOf("month"),
    };
  }
  render() {
    let totalCBM = 0;
    let totalPrice = 0;
    let localCBM = 0;
    let localPrice = 0;
    let imCBM = 0;
    let imPrice = 0;
    let storageCBM = 0;
    let storagePrice = 0;
    let clearanceCBM = 0;
    let clearancePrice = 0;
    let otherCBM = 0;
    let otherPrice = 0;

    let stJob = storageStore.storageConverted;
    let startDate = moment(settingStore.calstartdate);
    let endDate = moment(settingStore.calenddate);
    let dateDiff = parseFloat(endDate.diff(startDate, "days")) + 1;

    for (let j = 0; j < dateDiff; j++) {
      for (let i = 0; i < stJob.length; i++) {
        if (
          (moment(stJob[i].storagestartdate) <=
            moment(startDate).add(j, "days")) &
          (moment(stJob[i].storageenddate) >= moment(startDate).add(j, "days"))
        ) {
          storageCBM += parseFloat(stJob[i].storagecbm) / parseFloat(dateDiff);
          if (parseFloat(stJob[i].monthlycost) > 0) {
            storagePrice +=
              parseFloat(stJob[i].monthlycost) / parseFloat(dateDiff);
          }
        }
      }
    }

    for (let i = 0; i < newEventStore.sectorSum.length; i++) {
      if (
        newEventStore.sectorSum[i].jobmain === "Local Move" ||
        newEventStore.sectorSum[i].jobmain === "Corporate Move"
      ) {
        localCBM += parseFloat(newEventStore.sectorSum[i].dailycbm);
        localPrice += parseFloat(newEventStore.sectorSum[i].dailyprice);
      } else if (
        newEventStore.sectorSum[i].jobmain === "IM - Inbound" ||
        newEventStore.sectorSum[i].jobmain === "IM - Outbound" ||
        newEventStore.sectorSum[i].jobmain === "CIM - Inbound" ||
        newEventStore.sectorSum[i].jobmain === "CIM - Outbound"
      ) {
        imCBM += parseFloat(newEventStore.sectorSum[i].dailycbm);
        imPrice += parseFloat(newEventStore.sectorSum[i].dailyprice);
      } else if (newEventStore.sectorSum[i].jobmain === "Only Clearance") {
        clearanceCBM += parseFloat(newEventStore.sectorSum[i].dailycbm);
        clearancePrice += parseFloat(newEventStore.sectorSum[i].dailyprice);
      } else {
        otherCBM += parseFloat(newEventStore.sectorSum[i].dailycbm);
        otherPrice += parseFloat(newEventStore.sectorSum[i].dailyprice);
      }
      totalCBM += parseFloat(newEventStore.sectorSum[i].dailycbm);
      totalPrice += parseFloat(newEventStore.sectorSum[i].dailyprice);
    }

    totalPrice += parseFloat(storagePrice);

    let summary = (
      <Row style={{ color: "white" }}>
        <div style={{ fontSize: 14 }}>
          {moment(startDate).format("MMM YYYY")}: {Math.round(totalCBM)} CBM for{" "}
          {parseFloat(totalPrice).toFixed(1)} KD.
        </div>
      </Row>
    );

    if (userStore.access > 4.5) {
      return (
        <Navbar sticky="bottom" bg="dark" variant="dark" expand="lg">
          <Nav className="mr-auto"></Nav>
          <Button
            size="sm"
            variant="dark"
            onClick={() => newEventStore.fetchAllClients()}
          >
            Reload Data
          </Button>
        </Navbar>
      );
    }

    return (
      <Navbar fixed="bottom" bg="dark" variant="dark" expand="lg">
        <Nav className="mr-auto">
          <OverlayTrigger
            placement={"top"}
            style={{ width: 500 }}
            overlay={
              <Tooltip id={`tooltip-top`}>
                <div style={{ fontSize: 14 }}>
                  <h4>{moment(startDate).format("MMM YYYY")} Summary</h4>
                  <strong>LM:</strong> {Math.round(localCBM)} CBM for{" "}
                  {parseFloat(localPrice).toFixed(3)} KD.
                  <br />
                  <strong>IM:</strong> {Math.round(imCBM)} CBM for{" "}
                  {parseFloat(imPrice).toFixed(3)} KD.
                  <br />
                  <strong>CC:</strong> {Math.round(clearanceCBM)} CBM for{" "}
                  {parseFloat(clearancePrice).toFixed(3)} KD.
                  <br />
                  <strong>ST:</strong> {Math.round(storageCBM)} CBM for{" "}
                  {Math.round(10 * storagePrice) / 10} KD.
                  <br />
                  <strong>Other:</strong> {Math.round(otherCBM)} CBM for{" "}
                  {parseFloat(otherPrice).toFixed(3)} KD.
                </div>
              </Tooltip>
            }
          >
            <div className="float-right">{summary}</div>
          </OverlayTrigger>
        </Nav>
        <Button
          size="sm"
          variant="dark"
          onClick={() => newEventStore.fetchAllClients()}
        >
          Reload Data
        </Button>
      </Navbar>
    );
  }
}

export default observer(FooterPage);
