import { decorate, observable, computed } from "mobx";
import axios from "axios";
import moment from "moment";
import settingStore from "./SettingStore";
import userStore from "./UserStore";
import eventStore from "./EventStore";

const instance = axios.create({
  // baseURL: "https://easymovecalendar.app/"
  // baseURL: "http://127.0.0.1:8000/",
  // baseURL: "http://157.230.113.108/",
  baseURL: "https://jazzafs.com/",
});

class InventoryStore {
  constructor() {
    this.inventoryin = [];
    this.inventoryout = [];
    this.loading = false;
    this.selectedjob = { address: [], jobdate: [] };
    this.jobdetails = false;
    this.showmodal = false;
    this.cbm = 0;
    this.trip = 0;
    this.bubble = 0;
    this.embox = 0;
    this.stretch = 0;
    this.nylon = 0;
    this.localtape = 0;
    this.shippingtape = 0;
    this.exttruck = 0;
    this.extcrew = 0;
    this.extcarp = 0;
    this.page = 0;
    this.inventoryUpdated = false;
  }

  resetPage() {
    this.selectedjob = { address: [], jobdate: [] };
    this.cbm = 0;
    this.trip = 0;
    this.bubble = 0;
    this.embox = 0;
    this.stretch = 0;
    this.nylon = 0;
    this.localtape = 0;
    this.shippingtape = 0;
    this.exttruck = 0;
    this.extcrew = 0;
    this.extcarp = 0;
    this.page = 0;
  }

  updateAllInventory() {
    this.inventoryUpdated = false;
    this.fetchInventoryIn();
  }

  changePage(val) {
    this.page = val;
  }

  changeJobDetails(val) {
    this.jobdetails = val;
  }

  updateInventory(val, type) {
    if (type === "trip") {
      this.trip += parseFloat(val);
      if (this.trip < 0) this.trip = 0;
    } else if (type === "cbm") {
      this.cbm += parseFloat(val);
      if (this.cbm < 0) this.cbm = 0;
    } else if (type === "exttruck") {
      this.exttruck += parseFloat(val);
      if (this.exttruck < 0) this.exttruck = 0;
    } else if (type === "extcrew") {
      this.extcrew += parseFloat(val);
      if (this.extcrew < 0) this.extcrew = 0;
    } else if (type === "extcarp") {
      this.extcarp += parseFloat(val);
      if (this.extcarp < 0) this.extcarp = 0;
    } else if (type === "bubble") {
      this.bubble += parseFloat(val);
      if (this.bubble < 0) this.bubble = 0;
    } else if (type === "embox") {
      this.embox += parseFloat(val);
      if (this.embox < 0) this.embox = 0;
    } else if (type === "stretch") {
      this.stretch += parseFloat(val);
      if (this.stretch < 0) this.stretch = 0;
    } else if (type === "nylon") {
      this.nylon += parseFloat(val);
      if (this.nylon < 0) this.nylon = 0;
    } else if (type === "localtape") {
      this.localtape += parseFloat(val);
      if (this.localtape < 0) this.localtape = 0;
    } else if (type === "shippingtape") {
      this.shippingtape += parseFloat(val);
      if (this.shippingtape < 0) this.shippingtape = 0;
    }
  }

  fetchJobDetails(id) {
    return instance
      .get("/job/" + id + "/?format=json")
      .then((res) => res.data)
      .then((job) => this.postJobCBM(job));
  }

  postJobCBM(job) {
    let data = job;
    data.actualcbm = this.cbm;
    return instance.put("/job/" + job.id + "/", data).then((res) => res.data);
  }

  postJobDate() {
    let data = {
      status: "Complete",
      job: this.selectedjob.id,
      jobdate: moment(this.selectedjob.start).format("YYYY-MM-DD"),
      jobdateremarks: this.selectedjob.jobdateremarks,
    };

    if (this.cbm > 0) this.fetchJobDetails(this.selectedjob.id);

    return instance
      .put("/jobdate/" + this.selectedjob.jobdateid + "/", data)
      .then((res) => res.data)
      .then((events) => {
        alert(`Job Date Updated. ID #${this.selectedjob.jobdateid}`);
        eventStore.fetchAllClients();
        settingStore.changeLoading(true);
      })
      .then((events) => {
        if (this.trip > 0)
          this.postInventoryUpdate("Truck Trip", this.trip, "Job Related");
        if (this.exttruck > 0)
          this.postInventoryUpdate(
            "External Truck Trip",
            this.exttruck,
            "Job Related"
          );
        if (this.extcrew > 0)
          this.postInventoryUpdate(
            "External Crew",
            this.extcrew,
            "Job Related"
          );
        if (this.extcarp > 0)
          this.postInventoryUpdate(
            "External Carpenter",
            this.extcarp,
            "Job Related"
          );
        if (this.bubble > 0)
          this.postInventoryUpdate("Bubble", this.bubble, "Inventory");
        if (this.embox > 0)
          this.postInventoryUpdate("Easy Move Box", this.embox, "Inventory");
        if (this.stretch > 0)
          this.postInventoryUpdate("Stretch Film", this.stretch, "Inventory");
        if (this.nylon > 0)
          this.postInventoryUpdate("Nylon Wrap", this.nylon, "Inventory");
        if (this.localtape > 0)
          this.postInventoryUpdate("Local Tape", this.localtape, "Inventory");
        if (this.shippingtape > 0)
          this.postInventoryUpdate(
            "Shipping Tape",
            this.shippingtape,
            "Inventory"
          );
      })
      .then((events) => {
        this.resetPage();
      })
      .catch((err) => {
        alert(
          "An error occurred. The data did NOT save. Please contact me (97666886)."
        );
        console.error(err.response);
      });
  }

  postInventoryUpdate(material, quantity, category) {
    let data = {
      material: material,
      category: category,
      quantity: quantity,
      jobid: this.selectedjob.id,
      userfrom: userStore.profile.first_name,
      userto: this.selectedjob.title,
      paymentdate: moment(this.selectedjob.start).format("YYYY-MM-DD"),
      useradded: userStore.profile.first_name,
    };

    return instance
      .post("/c-inventory/", data)
      .then((res) => res.data)
      .then((events) => {
        console.log(`${data.quantity} ${data.material} Used.`);
      })
      .catch((err) => {
        alert(
          "An error occurred. The data did NOT save. Please contact me (97666886)."
        );
        console.error(err.response);
      });
  }

  fetchInventoryIn() {
    this.loading = true;
    this.inventoryin = [];
    this.inventoryout = [];
    let user = userStore.profile.first_name;
    // let user = "Storage";
    return instance
      .get("/inventoryin/" + user + "/?format=json")
      .then((res) => res.data)
      .then((inventory) => {
        for (let i = 0; i < inventory.length; i++) {
          inventory[i].type = "In";
          inventory[i].balance = parseFloat(inventory[i].quantity);
          inventory[i].otheruser = inventory[i].userfrom;
        }
        this.inventoryin = inventory.filter(
          (inventory) => inventory.category !== "Job Related"
        );
        console.log("Inventory In Fetched");
      })
      .then((inventory) => this.fetchInventoryOut())
      .catch((err) => {
        console.error(err.response);
        alert("Error Inventory In");
      });
  }
  fetchInventoryOut() {
    let user = userStore.profile.first_name;
    // let user = "Storage";
    return instance
      .get("/inventoryout/" + user + "/?format=json")
      .then((res) => res.data)
      .then((inventory) => {
        for (let i = 0; i < inventory.length; i++) {
          inventory[i].type = "Out";
          inventory[i].balance = -parseFloat(inventory[i].quantity);
          inventory[i].otheruser = inventory[i].userto;
        }
        this.inventoryout = inventory.filter(
          (inventory) => inventory.category !== "Job Related"
        );
        console.log("Inventory Out Fetched");
        this.loading = false;
        this.inventoryUpdated = true;
      })
      .catch((err) => {
        console.error(err.response);
        alert("Error");
      });
  }

  get allinventory() {
    // let inventory = this.inventoryin;
    // for (let i = 0; i < this.inventoryout.length; i++) {
    //   inventory.push(this.inventoryout[i]);
    // }
    return this.inventoryin.concat(this.inventoryout);
  }

  get uniqueInventoryItems() {
    let inventory = this.allinventory;
    let items = [];

    for (let i = 0; i < inventory.length; i++) {
      items.push(inventory[i].material);
    }

    let unique = {};
    items.forEach(function (i) {
      if (!unique[i]) {
        unique[i] = true;
      }
    });
    return Object.keys(unique);
  }

  get inventorySummary() {
    let inventorySums = [];
    let materialQty = {};
    for (let i = 0; i < this.uniqueInventoryItems.length; i++) {
      materialQty = { type: "", qty: 0 };
      materialQty.type = this.uniqueInventoryItems[i];
      for (let j = 0; j < this.allinventory.length; j++) {
        if (this.allinventory[j].material === this.uniqueInventoryItems[i]) {
          materialQty.qty += parseFloat(this.allinventory[j].balance);
        }
      }
      inventorySums.push(materialQty);
    }
    return inventorySums;
  }

  get TLbooked() {
    let newdate = eventStore.jobdates.filter(
      (job) =>
        (job.jobdateTL === userStore.profile.first_name) &
        (job.jobdatestatus === "Pending") &
        (moment(job.start) <= moment(new Date()).add(1, "d"))
    );
    for (let i = 0; i < newdate.length; i++) {
      newdate[i].startformat = moment(newdate[i].start).format("DD-MMM");
    }
    return newdate;
  }
  get TLconverted() {
    let newdate = eventStore.jobdates.filter(
      (job) =>
        (job.jobdateTL === userStore.profile.first_name) &
        (job.jobdatestatus === "Complete")
    );

    for (let i = 0; i < newdate.length; i++) {
      newdate[i].startformat = moment(newdate[i].start).format("DD-MMM");
    }
    return newdate;
  }

  changeModalView(val) {
    this.showmodal = val;
  }

  chooseJob(inputjob) {
    this.selectedjob = inputjob;
    let indexVal = eventStore.jobs.findIndex(
      (job) => job.id === this.selectedjob.id
    );
    this.selectedjob.jobdate = eventStore.jobs[indexVal].jobdate;

    this.selectedjob.lastdate = moment(new Date(2019, 1, 1)).format(
      "YYYY-MM-DD"
    );
    for (let i = 0; i < this.selectedjob.jobdate.length; i++) {
      if (
        moment(this.selectedjob.jobdate[i].jobdate) >
        moment(this.selectedjob.lastdate)
      ) {
        this.selectedjob.lastdate = moment(
          this.selectedjob.jobdate[i].jobdate
        ).format("YYYY-MM-DD");
      }
    }
    this.selectedjob.newremark = eventStore.jobs[indexVal].remarks;
    this.showmodal = true;
  }
}

decorate(InventoryStore, {
  inventoryin: observable,
  inventoryout: observable,
  loading: observable,
  jobdetails: observable,
  selectedjob: observable,
  showmodal: observable,
  cbm: observable,
  trip: observable,
  bubble: observable,
  embox: observable,
  stretch: observable,
  nylon: observable,
  localtape: observable,
  shippingtape: observable,
  exttruck: observable,
  extcrew: observable,
  extcarp: observable,
  page: observable,
  inventoryUpdated: observable,
  allinventory: computed,
  uniqueInventoryItems: computed,
  inventorySummary: computed,
  TLbooked: computed,
  TLconverted: computed,
});

const inventoryStore = new InventoryStore();

export default inventoryStore;
