import React from "react";
// First way to import
import { observer } from "mobx-react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { ClipLoader } from "react-spinners";
import Paper from "@material-ui/core/Paper";

import CalendarComponent from "./CalendarComponent";
import settingStore from "../Stores/SettingStore";

class CalendarPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      events: []
    };
  }

  render() {
    return (
      <div>
        {settingStore.loading ? (
          <div>
            <ClipLoader
              sizeUnit={"px"}
              size={250}
              color={"#123abc"}
              loading={this.state.loading}
            />
          </div>
        ) : (
          <Paper elevation={5}>
            <CalendarComponent />
          </Paper>
        )}
        <br />
      </div>
    );
  }
}

export default observer(CalendarPage);
