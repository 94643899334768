import React, { Component } from "react";
import { observer } from "mobx-react";

import { Button } from "react-bootstrap";
import MaterialTable from "material-table";
import "react-datepicker/dist/react-datepicker.css";

import warehouseStore from "../Stores/WarehouseStore";

class InventoryHistoryEM extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editPayment: false,
      financialsLoaded: false,
      modalShow: false,
      indexVal: 0,
      filter: false
    };
  }

  render() {
    let columnnames = [
      {
        title: "Item",
        field: "material",
        removable: true,
        defaultGroupOrder: 0
      },
      { title: "Qty", field: "balance", removable: true },
      { title: "Source", field: "otheruser", removable: true },
      {
        title: "Date",
        field: "paymentdate",
        removable: true,
        defaultSort: "desc"
      }
    ];

    return (
      <div>
        <Button
          block
          variant="outline-dark"
          onClick={() => warehouseStore.updateAllInventory()}
        >
          Combined Inventory
        </Button>
        {warehouseStore.inventoryUpdated && (
          <div>
            <MaterialTable
              title={"Combined Inventory"}
              columns={columnnames}
              data={warehouseStore.allInventory}
              paging={true}
              options={{
                paging: true,
                pageSize: 10,
                search: false,
                pageSizeOptions: [5, 10, 15, 20, 25],
                maxBodyHeight: 1000,
                tableLayout: "auto",
                grouping: false,
                padding: "dense"
              }}
            />
            <br />
            <Button
              block
              variant="outline-dark"
              onClick={() => warehouseStore.updateAllInventory()}
            >
              Abu Hulaifa
            </Button>
            <MaterialTable
              title={"Abu Hulaifa Inventory"}
              columns={columnnames}
              data={warehouseStore.abuhulaifaInventory}
              paging={true}
              options={{
                paging: true,
                pageSize: 10,
                search: false,
                pageSizeOptions: [5, 10, 15, 20, 25],
                maxBodyHeight: 1000,
                tableLayout: "auto",
                grouping: false,
                padding: "dense"
              }}
            />
            <br />
            <Button
              block
              variant="outline-dark"
              onClick={() => warehouseStore.updateAllInventory()}
            >
              Faiha
            </Button>
            <MaterialTable
              title={"Faiha Inventory"}
              columns={columnnames}
              data={warehouseStore.faihaInventory}
              paging={true}
              options={{
                paging: true,
                pageSize: 10,
                search: false,
                pageSizeOptions: [5, 10, 15, 20, 25],
                maxBodyHeight: 1000,
                tableLayout: "auto",
                grouping: false,
                padding: "dense"
              }}
            />
          </div>
        )}
        <hr />
        <br />
        <hr />
        <br />
        <hr />
        <br />
      </div>
    );
  }
}

export default observer(InventoryHistoryEM);
