import React, { Component } from "react";
import { observer } from "mobx-react";
import moment from "moment";

import managementStore from "../Stores/ManagementStore";
import Card from "@material-ui/core/Card";
import CardContent from "@material-ui/core/CardContent";
import Typography from "@material-ui/core/Typography";

import { Col } from "react-bootstrap";

class UserLastSignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let current = moment(new Date());
    let user = this.props.user;
    let indexVal = -1;
    if (managementStore.attendanceloaded) {
      indexVal = managementStore.profiles.findIndex(
        (attendance) => attendance.first_name === user.user
      );
    }
    user.critical = "No";
    if (indexVal >= 0) {
      user.lastsignin = managementStore.profiles[indexVal].timeago;
      user.datelastsignin = managementStore.profiles[indexVal].formatteddate;
      if (
        current.diff(
          moment(managementStore.profiles[indexVal].newdate),
          "hours"
        ) > 16
      ) {
        user.critical = "Yes";
      }
    } else {
      user.lastsignin = "Pending";
      user.datelastsignin = "Pending";
      user.critical = "Yes";
    }

    if (user.critical === "Yes") {
      return (
        <Col>
          <Card
            style={{
              background: "linear-gradient(to right bottom, #ffb3c2, #fa0535)",
            }}
          >
            <CardContent>
              <Typography gutterBottom>{user.group}</Typography>
              <Typography variant="h6" component="h6">
                {user.user}: {user.lastsignin}
              </Typography>
            </CardContent>
          </Card>
        </Col>
      );
    } else {
      if (user.group === "Local Team") {
        return (
          <Col>
            <Card
              style={{
                background:
                  "linear-gradient(to right bottom, #baff8f, #aad192)",
              }}
            >
              <CardContent>
                <Typography gutterBottom>{user.group}</Typography>
                <Typography variant="h6" component="h6">
                  {user.user}: {user.lastsignin}
                </Typography>
              </CardContent>
            </Card>
          </Col>
        );
      }
      if (user.group === "IM Team") {
        return (
          <Col>
            <Card
              style={{
                background:
                  "linear-gradient(to right bottom, #c2e0fc, #afcae3)",
              }}
            >
              <CardContent>
                <Typography gutterBottom>{user.group}</Typography>
                <Typography variant="h6" component="h6">
                  {user.user}: {user.lastsignin}
                </Typography>
              </CardContent>
            </Card>
          </Col>
        );
      }
      if (user.group === "Management") {
        return (
          <Col>
            <Card
              style={{
                background:
                  "linear-gradient(to right bottom, #dbcdad, #f5e3bc)",
              }}
            >
              <CardContent>
                <Typography gutterBottom>{user.group}</Typography>
                <Typography variant="h6" component="h6">
                  {user.user}: {user.lastsignin}
                </Typography>
              </CardContent>
            </Card>
          </Col>
        );
      }
    }
  }
}

export default observer(UserLastSignIn);
