import React from "react";
// First way to import

import logo from "../../easymoveicon.png";
import { Nav, Navbar, NavDropdown, Button, Row, Col } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import userStore from "../Stores/UserStore";
import settingStore from "../Stores/SettingStore";
import { observer } from "mobx-react";
// import { Select } from "antd";
import TextField from "@material-ui/core/TextField";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import { Input } from "antd";
import Modal from "react-bootstrap/Modal";

import eventStore from "../Stores/EventStore";
import newEventStore from "../Stores/NewEventStore";
import hrEventStore from "../Stores/HREventStore";
import inventoryStore from "../Stores/InventoryStore";

const { TextArea } = Input;

// const { Option } = Select;

class NavBarPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      search: "title",
      show: false,
    };
  }
  handleClose = () => this.setState({ show: false });
  handleShow = () => this.setState({ show: true });

  render() {
    if (userStore.access > 4.5) {
      return (
        <div>
          <Navbar collapseOnSelect expand="md" bg="dark" variant="dark">
            {inventoryStore.page === 0 && (
              <Navbar.Brand href="#home">
                <img
                  src={logo}
                  width="30"
                  height="30"
                  className="d-inline-block align-top"
                  alt="React Bootstrap logo"
                />
                {"  "}
                <NavLink to="/tldash" style={{ color: "white" }}>
                  Main
                </NavLink>
              </Navbar.Brand>
            )}
            {inventoryStore.page === 0 && (
              <Nav className="mr-auto">
                <NavLink
                  to="/inventory"
                  style={{ textAlign: "center", color: "white" }}
                >
                  Inventory History
                </NavLink>
              </Nav>
            )}
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav>
                <NavDropdown
                  title={
                    userStore.user.username.charAt(0).toUpperCase() +
                    userStore.user.username.slice(1)
                  }
                  id="basic-nav-dropdown"
                >
                  <NavDropdown.Item>
                    {userStore.access} - {userStore.profile.group}
                  </NavDropdown.Item>
                  <NavDropdown.Item>
                    <FormControl component="fieldset">
                      <br />
                      <FormLabel component="legend">Change View</FormLabel>
                      <RadioGroup
                        onChange={(e) =>
                          userStore.postFormatChange(e.target.value)
                        }
                        row
                        aria-label="position"
                        name="position"
                        value={userStore.profile.editstyle}
                      >
                        <FormControlLabel
                          value="top"
                          control={<Radio color="primary" />}
                          label="Horizontal"
                          labelPlacement="start"
                        />
                        <FormControlLabel
                          value="left"
                          control={<Radio color="primary" />}
                          label="Vertical"
                          labelPlacement="start"
                        />
                      </RadioGroup>
                    </FormControl>
                  </NavDropdown.Item>
                  <NavDropdown.Item></NavDropdown.Item>
                  <NavDropdown.Item>
                    <Button
                      variant="outline-danger"
                      onClick={() => userStore.logoutUser()}
                      block
                      size="sm"
                    >
                      Sign Out
                    </Button>
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
          <Modal
            show={this.state.show}
            onHide={this.handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Send an Email</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div>
                <form>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="title"
                    label="From Name"
                    name="title"
                    autoFocus
                    type="string"
                    margin="dense"
                    size="small"
                    value={hrEventStore.customemail.fromname}
                    onChange={(e) =>
                      hrEventStore.changeEmail(e.target.value, "fromname")
                    }
                  />
                  <Row>
                    <Col>
                      <TextField
                        variant="outlined"
                        fullWidth
                        id="title"
                        label="From Email"
                        name="title"
                        margin="dense"
                        type="string"
                        size="small"
                        value={hrEventStore.customemail.fromemail}
                        onChange={(e) =>
                          hrEventStore.changeEmail(e.target.value, "fromemail")
                        }
                      />
                    </Col>
                    <Col>
                      <TextField
                        variant="standard"
                        required
                        fullWidth
                        id="title"
                        label=""
                        margin="dense"
                        name="title"
                        type="string"
                        size="small"
                        disabled
                        value={
                          hrEventStore.customemail.fromemail + "@easymovekw.com"
                        }
                      />
                    </Col>
                  </Row>
                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="title"
                    label="To Name"
                    name="title"
                    margin="dense"
                    type="string"
                    size="small"
                    value={hrEventStore.customemail.toname}
                    onChange={(e) =>
                      hrEventStore.changeEmail(e.target.value, "toname")
                    }
                  />

                  <TextField
                    variant="outlined"
                    required
                    fullWidth
                    id="title"
                    label="Email Subject"
                    name="title"
                    margin="dense"
                    size="small"
                    type="string"
                    value={hrEventStore.customemail.subject}
                    onChange={(e) =>
                      hrEventStore.changeEmail(e.target.value, "subject")
                    }
                  />
                </form>
                <hr />
                <TextArea
                  style={{ width: 700, fontWeight: "bold", fontSize: 20 }}
                  placeholder="Add Email Text Here"
                  autoSize={{ minRows: 2, maxRows: 5 }}
                  value={hrEventStore.customemail.bodytext}
                  onChange={(e) =>
                    hrEventStore.changeEmail(e.target.value, "bodytext")
                  }
                />
                <hr />
                <FormControl component="fieldset">
                  <Row>
                    <Col>
                      <FormLabel component="legend">To</FormLabel>
                      <RadioGroup
                        aria-label="gender"
                        name="gender1"
                        value={hrEventStore.customemail.toemail}
                        onChange={(e) => {
                          hrEventStore.changeEmail(e.target.value, "toemail");
                        }}
                      >
                        <FormControlLabel
                          value="local@easymovekw.com"
                          control={<Radio />}
                          label="Local (Reyna / Yobel)"
                        />
                        <FormControlLabel
                          value="im@easymovekw.com"
                          control={<Radio />}
                          label="IM (Vinith / Katrina)"
                        />
                        <FormControlLabel
                          value="accounts@easymovekw.com"
                          control={<Radio />}
                          label="Accounts (Yobel)"
                        />
                        <FormControlLabel
                          value="relo@easymovekw.com"
                          control={<Radio />}
                          label="Relo (Aasim)"
                        />
                        <FormControlLabel
                          value="ajazzaf@easymovekw.com"
                          control={<Radio />}
                          label="IT (Abdulla)"
                        />
                      </RadioGroup>
                    </Col>
                    <Col>
                      <h5>Email to: {hrEventStore.customemail.toemail}</h5>
                      <TextArea
                        style={{ width: 400, fontWeight: "bold", fontSize: 20 }}
                        placeholder="Add Email Text Here"
                        autoSize={{ minRows: 8, maxRows: 15 }}
                        value={
                          "Dear " +
                          hrEventStore.customemail.toname +
                          ",\n \n" +
                          hrEventStore.customemail.bodytext +
                          "\n \n Best Regards, \n \n" +
                          hrEventStore.customemail.fromname
                        }
                      />
                    </Col>
                  </Row>
                </FormControl>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleClose}>
                Cancel
              </Button>
              <Button
                variant="success"
                disabled={hrEventStore.disabledEmailSend}
                onClick={() => {
                  this.handleClose();
                  hrEventStore.sendCustomEmail();
                }}
              >
                Send Email
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      );
    }

    let cancelbutton = (
      <Button
        variant="outline-danger"
        size="sm"
        onClick={() => {
          newEventStore.fetchCancelled();
        }}
      >
        Load Cancellations
      </Button>
    );
    let convbutton = (
      <Button
        variant="outline-success"
        size="sm"
        onClick={() => {
          newEventStore.fetchConverted();
        }}
      >
        Load Old Jobs
      </Button>
    );
    let reloadData = (
      <Button
        size="sm"
        variant="dark"
        onClick={() => newEventStore.fetchAllClients()}
      >
        Reload
      </Button>
    );

    // let optionList = newEventStore.clients.map((job) => (
    //   <Option value={job.id} key={job.id}>
    //     {job.title}
    //   </Option>
    // ));

    // let optionPhone = newEventStore.clients.map((job) => (
    //   <Option value={job.id} key={job.id}>
    //     {job.title + " " + job.phone1 + " " + job.phone2}
    //   </Option>
    // ));

    // let optionEmail = newEventStore.clients.map((job) => (
    //   <Option value={job.id} key={job.id}>
    //     {job.title} {job.email}
    //   </Option>
    // ));

    // let finalOptions;
    // if (this.state.search === "title") {
    //   finalOptions = optionList;
    // } else if (this.state.search === "phone") {
    //   finalOptions = optionPhone;
    // } else if (this.state.search === "email") {
    //   finalOptions = optionEmail;
    // }

    let bookkeeping = (
      <NavDropdown.Item href="#financial">
        <NavLink
          to="/financial"
          style={{ textAlign: "center", color: "black" }}
          onClick={() => {
            eventStore.changedashboard("Local");
            settingStore.resetViews();
          }}
        >
          Book Keeping
        </NavLink>
      </NavDropdown.Item>
    );

    let managementaccess = (
      <NavDropdown title="Management" id="basic-nav-dropdown">
        <NavDropdown.Item href="#hrdashboard">
          <NavLink
            to="/hrdashboard"
            style={{ textAlign: "center", color: "black" }}
            onClick={() => {
              settingStore.resetViews();
            }}
          >
            HR Dashboard
          </NavLink>
        </NavDropdown.Item>
        <NavDropdown.Item href="#financial">
          <NavLink
            to="/financial"
            style={{ textAlign: "center", color: "black" }}
            onClick={() => {
              settingStore.resetViews();
            }}
          >
            Financials
          </NavLink>
        </NavDropdown.Item>
        <NavDropdown.Item href="#bankbalance">
          <NavLink
            to="/bankbalance"
            style={{ textAlign: "center", color: "black" }}
            onClick={() => {
              settingStore.resetViews();
            }}
          >
            Balance Breakdown
          </NavLink>
        </NavDropdown.Item>
        <NavDropdown.Item href="#outstanding">
          <NavLink
            to="/outstanding"
            style={{ textAlign: "center", color: "black" }}
            onClick={() => {
              settingStore.resetViews();
            }}
          >
            Clients Outstanding
          </NavLink>
        </NavDropdown.Item>
        <NavDropdown.Item href="#management">
          <NavLink
            to="/management"
            style={{ textAlign: "center", color: "black" }}
            onClick={() => {
              settingStore.resetViews();
            }}
          >
            Management Dash
          </NavLink>
        </NavDropdown.Item>
      </NavDropdown>
    );

    return (
      <div>
        <Navbar collapseOnSelect expand="md" bg="dark" variant="dark">
          <Navbar.Brand href="#home">
            <img
              src={logo}
              width="30"
              height="30"
              className="d-inline-block align-top"
              alt="React Bootstrap logo"
            />
            {"  "}
            <NavLink to="/calendar" style={{ color: "white" }}>
              Calendar
            </NavLink>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="mr-auto">
              <NavDropdown title="Lists" id="basic-nav-dropdown">
                <NavDropdown.Item href="#clientlist">
                  <NavLink
                    to="/clientlist"
                    style={{ textAlign: "center", color: "black" }}
                    onClick={() => settingStore.resetViews()}
                  >
                    Clients List
                  </NavLink>
                </NavDropdown.Item>
                <NavDropdown.Item href="#joblist">
                  <NavLink
                    to="/joblist"
                    style={{ textAlign: "center", color: "black" }}
                    onClick={() => settingStore.resetViews()}
                  >
                    Jobs List
                  </NavLink>
                </NavDropdown.Item>
                <NavDropdown.Item href="#pendingclients">
                  <NavLink
                    to="/pendingclients"
                    style={{ textAlign: "center", color: "black" }}
                    onClick={() => settingStore.resetViews()}
                  >
                    Pending Clients
                  </NavLink>
                </NavDropdown.Item>
                <NavDropdown.Item href="#clientbalance">
                  <NavLink
                    to="/clientbalance"
                    style={{ textAlign: "center", color: "black" }}
                    onClick={() => settingStore.resetViews()}
                  >
                    Client Balances
                  </NavLink>
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Local" id="basic-nav-dropdown">
                <NavDropdown.Item href="#workschedule">
                  <NavLink
                    to="/dashboard"
                    style={{ textAlign: "center", color: "black" }}
                    onClick={() => {
                      newEventStore.changeDashboard("Local");
                      settingStore.resetViews();
                    }}
                  >
                    Local Dashboard
                  </NavLink>
                </NavDropdown.Item>
                <NavDropdown.Item href="#storage">
                  <NavLink
                    to="/storage"
                    style={{ textAlign: "center", color: "black" }}
                    onClick={() => {
                      newEventStore.changeDashboard("Local");
                      settingStore.resetViews();
                    }}
                  >
                    Storage
                  </NavLink>
                </NavDropdown.Item>
                {userStore.access < 3.5 && bookkeeping}
              </NavDropdown>
              <NavDropdown title="IM" id="basic-nav-dropdown">
                <NavDropdown.Item>
                  <NavLink
                    to="/dashboard"
                    style={{ textAlign: "center", color: "black" }}
                    onClick={() => {
                      newEventStore.changeDashboard("im");
                      settingStore.resetViews();
                    }}
                  >
                    IM Dashboard
                  </NavLink>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <NavLink
                    to="/intsup"
                    style={{ textAlign: "center", color: "black" }}
                    onClick={() => settingStore.resetViews()}
                  >
                    IM Suppliers
                  </NavLink>
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <NavLink
                    to="/imtracking"
                    style={{ textAlign: "center", color: "black" }}
                  >
                    IM Tracking
                  </NavLink>
                </NavDropdown.Item>
              </NavDropdown>
              <NavDropdown title="Daily" id="basic-nav-dropdown">
                <NavDropdown.Item href="#workschedule">
                  <NavLink
                    to="/workschedule"
                    style={{ textAlign: "center", color: "black" }}
                    onClick={() => {
                      settingStore.resetViews();
                    }}
                  >
                    Work Schedule
                  </NavLink>
                </NavDropdown.Item>
                <NavDropdown.Item href="#assign">
                  <NavLink
                    to="/assign"
                    style={{ textAlign: "center", color: "black" }}
                    onClick={() => {
                      settingStore.resetViews();
                    }}
                  >
                    Assign Job
                  </NavLink>
                </NavDropdown.Item>
                <NavDropdown.Item href="#assignclient">
                  <NavLink
                    to="/assignclient"
                    style={{ textAlign: "center", color: "black" }}
                    onClick={() => {
                      settingStore.resetViews();
                    }}
                  >
                    Assign Client
                  </NavLink>
                </NavDropdown.Item>
                <NavDropdown.Item href="#converted">
                  <NavLink
                    to="/converted"
                    style={{ textAlign: "center", color: "black" }}
                    onClick={() => {
                      settingStore.resetViews();
                    }}
                  >
                    Monthly Conversions
                  </NavLink>
                </NavDropdown.Item>
              </NavDropdown>
              {userStore.access < 2.5 && managementaccess}
              {userStore.access < 2.5 && (
                <Button
                  variant="dark"
                  onClick={() => {
                    hrEventStore.resetEmail();
                    this.setState({ show: !this.state.show });
                  }}
                >
                  Send Email
                </Button>
              )}
            </Nav>

            <Nav>
              <NavDropdown
                title={
                  userStore.user.username.charAt(0).toUpperCase() +
                  userStore.user.username.slice(1)
                }
                id="basic-nav-dropdown"
              >
                <NavDropdown.Item>
                  {userStore.access} - {userStore.profile.group}
                </NavDropdown.Item>
                <NavDropdown.Item>
                  <FormControl component="fieldset">
                    <br />
                    Change Job Tab Format
                    <RadioGroup
                      onChange={(e) =>
                        userStore.postFormatChange(e.target.value)
                      }
                      row
                      aria-label="position"
                      name="position"
                      value={userStore.profile.editstyle}
                    >
                      <FormControlLabel
                        value="top"
                        control={<Radio color="primary" />}
                        label="Horizontal"
                        labelPlacement="start"
                      />
                      <FormControlLabel
                        value="left"
                        control={<Radio color="primary" />}
                        label="Vertical"
                        labelPlacement="start"
                      />
                    </RadioGroup>
                  </FormControl>
                </NavDropdown.Item>
                <NavDropdown.Item></NavDropdown.Item>
                <NavDropdown.Item>
                  <Button
                    variant="outline-danger"
                    onClick={() => userStore.logoutUser()}
                    block
                    size="sm"
                  >
                    Sign Out
                  </Button>
                </NavDropdown.Item>
              </NavDropdown>
            </Nav>
            {!newEventStore.cancLoaded && cancelbutton}
            {!newEventStore.convLoaded && convbutton}
            {userStore.access > 2.5 && reloadData}
          </Navbar.Collapse>
        </Navbar>
        <Modal
          show={this.state.show}
          onHide={this.handleClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Send an Email</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              <form>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="title"
                  label="From Name"
                  name="title"
                  autoFocus
                  type="string"
                  margin="dense"
                  size="small"
                  value={hrEventStore.customemail.fromname}
                  onChange={(e) =>
                    hrEventStore.changeEmail(e.target.value, "fromname")
                  }
                />
                <Row>
                  <Col>
                    <TextField
                      variant="outlined"
                      fullWidth
                      id="title"
                      label="From Email"
                      name="title"
                      margin="dense"
                      type="string"
                      size="small"
                      value={hrEventStore.customemail.fromemail}
                      onChange={(e) =>
                        hrEventStore.changeEmail(e.target.value, "fromemail")
                      }
                    />
                  </Col>
                  <Col>
                    <TextField
                      variant="standard"
                      required
                      fullWidth
                      id="title"
                      label=""
                      margin="dense"
                      name="title"
                      type="string"
                      size="small"
                      disabled
                      value={
                        hrEventStore.customemail.fromemail + "@easymovekw.com"
                      }
                    />
                  </Col>
                </Row>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="title"
                  label="To Name"
                  name="title"
                  margin="dense"
                  type="string"
                  size="small"
                  value={hrEventStore.customemail.toname}
                  onChange={(e) =>
                    hrEventStore.changeEmail(e.target.value, "toname")
                  }
                />

                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  id="title"
                  label="Email Subject"
                  name="title"
                  margin="dense"
                  size="small"
                  type="string"
                  value={hrEventStore.customemail.subject}
                  onChange={(e) =>
                    hrEventStore.changeEmail(e.target.value, "subject")
                  }
                />
              </form>
              <hr />
              <TextArea
                style={{ width: 700, fontWeight: "bold", fontSize: 20 }}
                placeholder="Add Email Text Here"
                autoSize={{ minRows: 2, maxRows: 5 }}
                value={hrEventStore.customemail.bodytext}
                onChange={(e) =>
                  hrEventStore.changeEmail(e.target.value, "bodytext")
                }
              />
              <hr />
              <FormControl component="fieldset">
                <Row>
                  <Col>
                    <FormLabel component="legend">To</FormLabel>
                    <RadioGroup
                      aria-label="gender"
                      name="gender1"
                      value={hrEventStore.customemail.toemail}
                      onChange={(e) => {
                        hrEventStore.changeEmail(e.target.value, "toemail");
                      }}
                    >
                      <FormControlLabel
                        value="local@easymovekw.com"
                        control={<Radio />}
                        label="Local (Reyna / Yobel)"
                      />
                      <FormControlLabel
                        value="im@easymovekw.com"
                        control={<Radio />}
                        label="IM (Vinith / Katrina)"
                      />
                      <FormControlLabel
                        value="accounts@easymovekw.com"
                        control={<Radio />}
                        label="Accounts (Yobel)"
                      />
                      <FormControlLabel
                        value="relo@easymovekw.com"
                        control={<Radio />}
                        label="Relo (Aasim)"
                      />
                      <FormControlLabel
                        value="ajazzaf@easymovekw.com"
                        control={<Radio />}
                        label="IT (Abdulla)"
                      />
                    </RadioGroup>
                  </Col>
                  <Col>
                    <h5>Email to: {hrEventStore.customemail.toemail}</h5>
                    <TextArea
                      style={{ width: 400, fontWeight: "bold", fontSize: 20 }}
                      placeholder="Add Email Text Here"
                      autoSize={{ minRows: 8, maxRows: 15 }}
                      value={
                        "Dear " +
                        hrEventStore.customemail.toname +
                        ",\n \n" +
                        hrEventStore.customemail.bodytext +
                        "\n \n Best Regards, \n \n" +
                        hrEventStore.customemail.fromname
                      }
                    />
                  </Col>
                </Row>
              </FormControl>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Cancel
            </Button>
            <Button
              variant="success"
              disabled={hrEventStore.disabledEmailSend}
              onClick={() => {
                this.handleClose();
                hrEventStore.sendCustomEmail();
              }}
            >
              Send Email
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    );
  }
}

export default observer(NavBarPage);
