import React from "react";
// First way to import
import { observer } from "mobx-react";
import { Button, Container, Row, Col } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import { Select, DatePicker } from "antd";
import moment from "moment";

import editJobStore from "../../Stores/EditJobStore";

const { Option } = Select;

class StoragePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let storages = editJobStore.storages.map((storage, index) => (
      <div key={index}>
        <Row>
          <Col md={3}>
            <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              size="small"
            >
              <b
                style={{
                  color: storage.storagestatus === "" ? "red" : "black",
                }}
              >
                #{index + 1}) Status:
              </b>
              <Select
                showSearch
                error
                width="100%"
                optionFilterProp="children"
                onChange={(e) =>
                  editJobStore.updateStorage("storagestatus", e, index)
                }
                value={storage.storagestatus}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value={""}></Option>
                <Option value={"Pending"}>Pending</Option>
                <Option value={"In Storage"}>In Storage</Option>
                <Option value={"Complete"}>Complete</Option>
              </Select>
            </FormControl>
          </Col>
          <Col md={3}>
            <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              size="small"
            >
              <b
                style={{
                  color: storage.storageloc_t === "" ? "red" : "black",
                }}
              >
                Location:
              </b>
              <Select
                showSearch
                error
                width="100%"
                optionFilterProp="children"
                onChange={(e) =>
                  editJobStore.updateStorage("storageloc_t", e, index)
                }
                value={storage.storageloc_t}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value={""}></Option>
                <Option value={"EM Abu Hulaifa"}>EM Abu Hulaifa</Option>
                <Option value={"Al-Ghanim"}>Al-Ghanim</Option>
                <Option value={"Hawalli Office"}>Hawalli Office</Option>
                <Option value={"Other"}>Other</Option>
              </Select>
            </FormControl>
          </Col>
          <Col md={3}>
            <b
              style={{
                color: storage.storagecbm > 0 ? "black" : "red",
              }}
            >
              Storage CBM:
            </b>
            <TextField
              variant="outlined"
              size="small"
              type="number"
              style={{ width: "100%" }}
              value={storage.storagecbm}
              onChange={(e) =>
                editJobStore.updateStorage("storagecbm", e.target.value, index)
              }
            />
          </Col>
          <Col md={3}>
            <b
              style={{
                color: storage.monthlycost > 0 ? "black" : "red",
              }}
            >
              Monthly Cost:
            </b>
            <TextField
              variant="outlined"
              size="small"
              type="number"
              style={{ width: "100%" }}
              value={storage.monthlycost}
              onChange={(e) =>
                editJobStore.updateStorage("monthlycost", e.target.value, index)
              }
            />
          </Col>
          <Col md={3}>
            <FormControl variant="outlined" style={{ width: "100%" }}>
              <b>Start Date</b>
              <DatePicker
                onChange={(date, dateString) => {
                  editJobStore.updateStorage("storagestartdate", date, index);
                }}
                format={"DD-MMM-YY"}
                size="medium"
                defaultValue={
                  (storage.storagestartdate !== null) &
                    (storage.storagestartdate !== "") &&
                  moment(storage.storagestartdate)
                }
              />
            </FormControl>
          </Col>
          <Col md={3}>
            <FormControl variant="outlined" style={{ width: "100%" }}>
              <b>End Date</b>
              <DatePicker
                onChange={(date, dateString) => {
                  editJobStore.updateStorage("storageenddate", date, index);
                }}
                format={"DD-MMM-YY"}
                size="medium"
                defaultValue={
                  (storage.storageenddate !== null) &
                    (storage.storageenddate !== "") &&
                  moment(storage.storageenddate)
                }
              />
            </FormControl>
          </Col>
          <Col md={3}>
            <FormControl variant="outlined" style={{ width: "100%" }}>
              <b>Paid to Date</b>
              <DatePicker
                onChange={(date, dateString) => {
                  editJobStore.updateStorage("storagepaidto", date, index);
                }}
                format={"DD-MMM-YY"}
                size="medium"
                defaultValue={
                  (storage.storagepaidto !== null) &
                    (storage.storagepaidto !== "") &&
                  moment(storage.storagepaidto)
                }
              />
            </FormControl>
          </Col>
          <Col md={3}>
            <Row>Delete</Row>
            <Row>
              <Button
                variant="outline-danger"
                block
                size="sm"
                onClick={() => editJobStore.updateStorage("delete", 0, index)}
              >
                x
              </Button>
            </Row>
          </Col>
        </Row>
        <hr />
      </div>
    ));

    return (
      <div>
        <Container style={{ maxWidth: "98%" }}>
          <Row>
            <Col>
              <Button
                variant="outline-success"
                onClick={() => editJobStore.updateStorage("add", 0, 0)}
              >
                Add a Storage Job
              </Button>
            </Col>
            <Col>
              <h3>{editJobStore.storages.length} Storage Jobs:</h3>
            </Col>
          </Row>
          <hr />
          <br />
          {storages}
          <br />
          <br />
        </Container>
      </div>
    );
  }
}

export default observer(StoragePage);
