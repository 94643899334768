import React, { Component } from "react";
import { observer } from "mobx-react";
import moment from "moment";
import MaterialTable from "material-table";
import newEventStore from "../Stores/NewEventStore";
import FilterModule from "../FilterModules/FilterModule";
import jobSelectionStore from "../Stores/JobSelectionStore";
import JobModal from "../FilterModules/JobModal";
import { Button, Col, Row } from "react-bootstrap";
import settingStore from "../Stores/SettingStore";
import ReactApexChart from "react-apexcharts";

class ConvertedClients extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "Monthly",
      calc: "Total Job Price",
    };
  }
  componentDidMount() {
    if (!newEventStore.financialloaded) {
      newEventStore.fetchFinancialData();
    }
  }

  handleClose = () => jobSelectionStore.closeModal();

  render() {
    let columnnames = [
      { title: "Job ID", field: "jobid", removable: true },
      { title: "Client", field: "clientname" },
      { title: "Status", field: "status" },
      { title: "Main", field: "jobmain" },
      { title: "Focal Point", field: "emfocalpoint" },
      { title: "Second", field: "jobsecond", removable: true },
      { title: "Move Price", field: "lastprice", removable: true },
      { title: "Additional", field: "addchargeprice", removable: true },
      { title: "Storage", field: "storageprice", removable: true },
      { title: "Payments", field: "customerpayment", removable: true },
      { title: "Total Price", field: "totaljobprice", removable: true },
      { title: "Last Job", field: "lastjob", removable: true },
      { title: "Balance", field: "clientbalance", removable: true },
      { title: "Profit", field: "profit", removable: true },
    ];
    let startdate = settingStore.calstartdate;
    let enddate = settingStore.calenddate;
    let clients = newEventStore.convertedClients;

    let reyna = 0;
    let yobel = 0;
    let aasim = 0;
    let vinith = 0;
    let katrina = 0;
    let nauser = 0;
    let wreyna = 0;
    let wyobel = 0;
    let waasim = 0;
    let wvinith = 0;
    let wkatrina = 0;
    let wnauser = 0;
    let nrreyna = 0;
    let nryobel = 0;
    let nraasim = 0;
    let nrvinith = 0;
    let nrkatrina = 0;
    let nrnauser = 0;

    for (let i = 0; i < clients.length; i++) {
      if (this.state.calc === "Total Job Price") {
        if (clients[i].emfocalpoint === "Reyna") {
          reyna += 1;
          wreyna +=
            parseFloat(clients[i].totaljobprice) -
            parseFloat(clients[i].clientbalance);
          nrreyna += parseFloat(clients[i].clientbalance);
        } else if (clients[i].emfocalpoint === "Aasim") {
          aasim += 1;
          waasim +=
            parseFloat(clients[i].totaljobprice) -
            parseFloat(clients[i].clientbalance);
          nraasim += parseFloat(clients[i].clientbalance);
        } else if (clients[i].emfocalpoint === "Yobel") {
          yobel += 1;
          wyobel +=
            parseFloat(clients[i].totaljobprice) -
            parseFloat(clients[i].clientbalance);
          nryobel += parseFloat(clients[i].clientbalance);
        } else if (clients[i].emfocalpoint === "Vinith") {
          vinith += 1;
          wvinith +=
            parseFloat(clients[i].totaljobprice) -
            parseFloat(clients[i].clientbalance);
          nrvinith += parseFloat(clients[i].clientbalance);
        } else if (clients[i].emfocalpoint === "Katrina") {
          katrina += 1;
          wkatrina +=
            parseFloat(clients[i].totaljobprice) -
            parseFloat(clients[i].clientbalance);
          nrkatrina += parseFloat(clients[i].clientbalance);
        } else {
          nauser += 1;
          wnauser +=
            parseFloat(clients[i].totaljobprice) -
            parseFloat(clients[i].clientbalance);
          nrnauser += parseFloat(clients[i].clientbalance);
        }
      } else {
        if (clients[i].emfocalpoint === "Reyna") {
          reyna += 1;
          wreyna += parseFloat(clients[i].profit);
          nrreyna += parseFloat(clients[i].clientbalance);
        } else if (clients[i].emfocalpoint === "Aasim") {
          aasim += 1;
          waasim += parseFloat(clients[i].profit);
          nraasim += parseFloat(clients[i].clientbalance);
        } else if (clients[i].emfocalpoint === "Yobel") {
          yobel += 1;
          wyobel += parseFloat(clients[i].profit);
          nryobel += parseFloat(clients[i].clientbalance);
        } else if (clients[i].emfocalpoint === "Vinith") {
          vinith += 1;
          wvinith += parseFloat(clients[i].profit);
          nrvinith += parseFloat(clients[i].clientbalance);
        } else if (clients[i].emfocalpoint === "Katrina") {
          katrina += 1;
          wkatrina += parseFloat(clients[i].profit);
          nrkatrina += parseFloat(clients[i].clientbalance);
        } else {
          nauser += 1;
          wnauser += parseFloat(clients[i].profit);
          nrnauser += parseFloat(clients[i].clientbalance);
        }
      }
    }
    wreyna = Math.round(wreyna);
    wyobel = Math.round(wyobel);
    waasim = Math.round(waasim);
    wkatrina = Math.round(wkatrina);
    wvinith = Math.round(wvinith);
    wnauser = Math.round(wnauser);
    nrreyna = Math.round(nrreyna);
    nryobel = Math.round(nryobel);
    nraasim = Math.round(nraasim);
    nrkatrina = Math.round(nrkatrina);
    nrvinith = Math.round(nrvinith);
    nrnauser = Math.round(nrnauser);

    let stafflist = [];
    let wstafflist = [];
    stafflist.push({
      name: "Converted Clients",
      data: [reyna, yobel, aasim, katrina, vinith, nauser],
    });
    wstafflist.push({
      name: "Received Payments",
      data: [wreyna, wyobel, waasim, wkatrina, wvinith, wnauser],
    });
    wstafflist.push({
      name: "Balance Pending",
      data: [nrreyna, nryobel, nraasim, nrkatrina, nrvinith, nrnauser],
    });

    let optionsforchart = {
      chart: {
        type: "bar",
        height: 350,
        stacked: true,
        foreColor: "black",
        dropShadow: {
          enabled: true,
          bottom: 1,
          right: 1,
          blur: 3,
          opacity: 0.1,
        },
      },
      responsive: [
        {
          breakpoint: 480,
          options: {
            legend: {
              position: "bottom",
              offsetX: -10,
              offsetY: 0,
              colors: "black",
            },
          },
        },
      ],
      plotOptions: {
        bar: {
          horizontal: false,
        },
      },
      xaxis: {
        type: "text",
        categories: ["Reyna", "Yobel", "Aasim", "Katrina", "Vinith", "Other"],
      },
      legend: {
        position: "top",
        offsetY: 20,
      },
      colors: ["#086320", "#8c0434", "#00c707"],
      fill: {
        opacity: 1,
      },
      dataLabels: {
        enabled: true,
        style: {
          fontWeight: "bold",
          fontSize: "18px",
        },
        dropShadow: {
          enabled: true,
          left: 2,
          top: 2,
          opacity: 0.7,
        },
      },
    };

    let navigationmonth;
    if (this.state.type === "Monthly") {
      navigationmonth = (
        <Row>
          <Col md={1} />
          <Col>
            <Button
              block
              variant="dark"
              onClick={(e) => {
                let oldDate = moment(startdate);
                oldDate = oldDate.subtract(1, "d");
                settingStore.changeCalDate(oldDate);
              }}
            >
              Last Month (
              {moment(moment(startdate).subtract(1, "days")).format("MMM-YY")})
            </Button>
          </Col>
          <Col />
          <Col>
            <Button
              block
              variant="dark"
              disabled={
                moment(startdate).format("MMM-YY") === moment().format("MMM-YY")
              }
              onClick={(e) => {
                let oldDate = moment(enddate);
                oldDate = oldDate.add(1, "d");
                settingStore.changeCalDate(oldDate);
              }}
            >
              Next Month (
              {moment(moment(enddate).add(1, "days")).format("MMM-YY")})
            </Button>
          </Col>
          <Col md={1} />
        </Row>
      );
    } else {
      navigationmonth = (
        <Row>
          <Col md={1} />
          <Col>
            <Button
              block
              variant="dark"
              onClick={(e) => {
                settingStore.changeCalcDateQuarterly(
                  startdate.subtract(1, "month"),
                  enddate.subtract(1, "month")
                );
                this.setState({ type: "Quarterly" });
              }}
            >
              Last Month (
              {moment(moment(startdate).subtract(1, "days")).format("MMM-YY")})
            </Button>
          </Col>
          <Col />
          <Col>
            <Button
              block
              variant="dark"
              disabled={
                moment(enddate).format("MMM-YY") === moment().format("MMM-YY")
              }
              onClick={(e) => {
                settingStore.changeCalcDateQuarterly(
                  startdate.add(1, "month"),
                  enddate.add(1, "month")
                );
                this.setState({ type: "Quarterly" });
              }}
            >
              Next Month (
              {moment(moment(enddate).add(1, "days")).format("MMM-YY")})
            </Button>
          </Col>
          <Col md={1} />
        </Row>
      );
    }

    return (
      <div>
        <JobModal
          show={jobSelectionStore.showModal}
          onHide={this.handleClose}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        />
        <FilterModule type="Converted" />
        <Row>
          <Col>
            <Button
              block
              variant={
                this.state.calc === "Total Job Price"
                  ? "success"
                  : "outline-success"
              }
              onClick={() => {
                this.setState({ calc: "Total Job Price" });
              }}
            >
              Total Job Price
            </Button>
          </Col>
          <Col>
            <Button
              block
              variant={
                this.state.calc === "Profit" ? "success" : "outline-success"
              }
              onClick={() => {
                this.setState({ calc: "Profit" });
              }}
            >
              Profit
            </Button>
          </Col>
        </Row>
        <h1 style={{ textAlign: "center" }}>
          {this.state.type} View from {moment(startdate).format("DD-MMM-YY")} to{" "}
          {moment(enddate).format("DD-MMM-YY")}
        </h1>
        <Row>
          <Col>
            <Button
              block
              variant={this.state.type === "Monthly" ? "dark" : "outline-dark"}
              onClick={() => {
                settingStore.changeCalDate(moment());
                this.setState({ type: "Monthly" });
              }}
            >
              Monthly
            </Button>
          </Col>
          <Col>
            <Button
              block
              variant={
                this.state.type === "Quarterly" ? "dark" : "outline-dark"
              }
              onClick={() => {
                settingStore.changeCalcDateQuarterly(
                  moment().subtract(2, "month"),
                  moment()
                );
                this.setState({ type: "Quarterly" });
              }}
            >
              Quarterly
            </Button>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col>
            <h2>Converted Client Count</h2>
            <ReactApexChart
              options={optionsforchart}
              series={stafflist}
              type="bar"
              height={350}
            />
          </Col>
          <Col>
            <h2>Converted Client Worth (KD)</h2>
            <ReactApexChart
              options={optionsforchart}
              series={wstafflist}
              type="bar"
              height={350}
            />
          </Col>
        </Row>
        <br />
        {navigationmonth}
        <hr />
        <MaterialTable
          title={"Converted Clients " + clients.length + " Clients"}
          columns={columnnames}
          data={clients}
          paging={true}
          options={{
            padding: "dense",
            actionsColumnIndex: 0,
            exportAllData: true,
            paging: true,
            pageSize: 20,
            toolbarButtonAlignment: "left",
            tableLayout: "auto",
            exportButton: true,
            exportFileName: "Converted Clients",
          }}
          actions={[
            (rowData) => ({
              icon: "edit",
              tooltip: "Edit Item",
              onClick: (event, rowData) => {
                jobSelectionStore.fetchSingleJob(rowData.jobid);
              },
            }),
          ]}
        />
        <hr />
      </div>
    );
  }
}

export default observer(ConvertedClients);
