import React from "react";
// First way to import
import { observer } from "mobx-react";
import { Button } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import { Select } from "antd";
import { Input } from "antd";
import Grid from "@material-ui/core/Grid";
import moment from "moment";

import { DatePicker } from "antd";
import jobEditStore from "../../Stores/JobEditStore";
import userStore from "../../Stores/UserStore";

import "react-datepicker/dist/react-datepicker.css";

const { TextArea } = Input;
const { Option } = Select;

class PaymentRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let singlepayment = this.props.payment;
    let indexVal = this.props.indexVal;

    let category1list = [
      "Local Move",
      "International Move",
      "Storage",
      "Custom Clearance",
      "Express Delivery",
      "Other",
    ];
    let category2list = [];
    let category3list = ["Cash"];
    let category4list = [];
    let category5list = [];

    if (userStore.access < 3) {
      category2list = ["Income", "Expense Variable", "Expense Fixed"];
      if (singlepayment.category2 === "Expense Fixed") {
        category3list = ["Cash", "Non-Cash"];
      } else {
        category3list = ["Cash"];
      }
    } else {
      category2list = ["Income", "Expense Variable", "Expense Fixed"];
    }

    if (singlepayment.category2 === "Income") {
      category4list = ["Job Related", "Other"];
    } else if (singlepayment.category2 === "Expense Variable") {
      category4list = [
        "Bank Related",
        "Conference",
        "Job Related",
        "Marketing",
        "Materials",
        "Office",
        "Phone",
        "Vehicle",
        "Other",
      ];
    } else if (singlepayment.category2 === "Expense Fixed") {
      if (singlepayment.category3 === "Cash") {
        category4list = ["Payroll", "Membership", "Rent", "Other"];
      } else if (singlepayment.category3 === "Non-Cash") {
        category4list = [
          "Depreciation",
          "Interest",
          "Accrued Expense",
          "Other",
        ];
      }
    }

    if (singlepayment.category4 === "Job Related") {
      if (singlepayment.category2 === "Income") {
        category5list = ["Customer Payment", "Other"];
      } else if (singlepayment.category2 === "Expense Variable") {
        category5list = [
          "Refund",
          "Damage",
          "Additional Materials",
          "Clearance Charge",
          "Insurance",
          "Shipping Expense",
          "Crating Expense",
          "Other",
        ];
      }
    } else if (singlepayment.category4 === "Materials") {
      category5list = [
        "Boxes",
        "Bubble Wrap",
        "Nylon Wrap",
        "Hanger Box",
        "Tape",
        "Stretch Film",
        "Other",
      ];
    } else if (singlepayment.category4 === "Office") {
      category5list = [
        "Furniture",
        "Printing",
        "Telephone",
        "Computer",
        "Stationary",
        "Transportation",
        "Government Fees",
        "Other",
      ];
    } else if (singlepayment.category4 === "Rent") {
      category5list = [
        "Office 1",
        "Office 2",
        "Office 3 (old)",
        "Employee Housing",
        "Storage Facility",
        "Other",
      ];
    } else if (singlepayment.category4 === "Bank Related") {
      category5list = ["Bank Charge", "Other"];
    } else if (singlepayment.category4 === "Payroll") {
      category5list = [
        "Salary",
        "Bonus",
        "Social Security",
        "Overtime",
        "Insurance",
        "Other",
      ];
    } else if (singlepayment.category4 === "Vehicle") {
      category5list = [
        "Insurance",
        "Full Insurance",
        "Gas",
        "Repairs",
        "Other",
      ];
    } else if (singlepayment.category4 === "Phone") {
      category5list = ["Phone"];
    } else if (singlepayment.category4 === "Marketing") {
      category5list = [
        "Website & SEO",
        "Advertisement",
        "Google Ads",
        "Social Media",
        "Other",
      ];
    } else if (singlepayment.category4 === "Membership") {
      category5list = ["IAM", "FIATA", "ARA", "Other"];
    } else if (singlepayment.category4 === "Conference and Travel") {
      category5list = [
        "Conference",
        "Perdium",
        "Travel",
        "Accomodation",
        "Other",
      ];
    } else if (singlepayment.category3 === "Non-Cash") {
      category5list = [singlepayment.category4];
    } else if (singlepayment.category4 === "Office") {
      category5list = [singlepayment.category4];
    }

    if (singlepayment.category4 === "Other") {
      category5list = ["Other", "Visa Cost"];
    }

    let cat1list = category1list.map((cat, index) => (
      <Option key={index} value={cat}>
        {cat}
      </Option>
    ));
    let cat2list = category2list.map((cat, index) => (
      <Option key={index} value={cat}>
        {cat}
      </Option>
    ));
    let cat3list = category3list.map((cat, index) => (
      <Option key={index} value={cat}>
        {cat}
      </Option>
    ));
    let cat4list = category4list.map((cat, index) => (
      <Option key={index} value={cat}>
        {cat}
      </Option>
    ));
    let cat5list = category5list.map((cat, index) => (
      <Option key={index} value={cat}>
        {cat}
      </Option>
    ));

    return (
      <div>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={12} sm={6} md={3}>
            <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              size="small"
            >
              <b>Type</b>
              <Select
                showSearch
                placeholder="Type"
                optionFilterProp="children"
                value={singlepayment.category2}
                onChange={(e) =>
                  jobEditStore.updatePayment(e, indexVal, "category2")
                }
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {cat2list}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              size="small"
            >
              <b>Cash</b>
              <Select
                showSearch
                placeholder="Cash"
                optionFilterProp="children"
                value={singlepayment.category3}
                onChange={(e) =>
                  jobEditStore.updatePayment(e, indexVal, "category3")
                }
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {cat3list}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              size="small"
            >
              <b>General</b>
              <Select
                showSearch
                placeholder="General"
                optionFilterProp="children"
                value={singlepayment.category4}
                onChange={(e) =>
                  jobEditStore.updatePayment(e, indexVal, "category4")
                }
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {cat4list}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              size="small"
            >
              <b>Specific</b>
              <Select
                showSearch
                placeholder="Specific"
                optionFilterProp="children"
                value={singlepayment.category5}
                onChange={(e) =>
                  jobEditStore.updatePayment(e, indexVal, "category5")
                }
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {cat5list}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={12} sm={6} md={3}>
            <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              size="small"
            >
              <b>Department</b>
              <Select
                showSearch
                placeholder="Department"
                optionFilterProp="children"
                value={singlepayment.category1}
                onChange={(e) =>
                  jobEditStore.updatePayment(e, indexVal, "category1")
                }
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {cat1list}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              size="small"
            >
              <b>Payment Method</b>
              <Select
                showSearch
                placeholder="Payment Method"
                optionFilterProp="children"
                value={singlepayment.paymentmethod}
                onChange={(e) =>
                  jobEditStore.updatePayment(e, indexVal, "paymentmethod")
                }
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value="Cash">Cash</Option>
                <Option value="KNET">KNET</Option>
                <Option value="Online Link">Online Link</Option>
                <Option value="Check">Check</Option>
                <Option value="Bank Transfer">Bank Transfer</Option>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              size="small"
            >
              <b>Bank Account</b>
              <Select
                showSearch
                placeholder="Payment Method"
                optionFilterProp="children"
                value={singlepayment.bankaccount}
                onChange={(e) =>
                  jobEditStore.updatePayment(e, indexVal, "bankaccount")
                }
                filterOption={(input, option) =>
                  option.props.children
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                <Option value="Cash">Cash</Option>
                <Option value="Boubyan">Boubyan</Option>
                <Option value="NBK">NBK</Option>
                <Option value="NBK-Clearance">NBK-Clearance</Option>
                <Option value="KFH">KFH</Option>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6} sm={3} md={3}>
            <FormControl variant="outlined" style={{ width: "100%" }}>
              <b>Payment Date</b>
              <DatePicker
                size="large"
                format={"DD-MMM-YY"}
                onChange={(e) =>
                  jobEditStore.updatePayment(e, indexVal, "paymentdate")
                }
                defaultValue={
                  (singlepayment.paymentdate !== null) &
                    (singlepayment.paymentdate !== "") &&
                  moment(singlepayment.paymentdate)
                }
              />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container spacing={1} alignItems="center">
          <Grid item xs={12} sm={3} md={3}>
            <b>Amount (KD)</b>
            <TextField
              size="small"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Invoice Amount KWD"
              name="invoiceamount"
              autoComplete="invoiceamount"
              autoFocus
              type="number"
              value={singlepayment.invoiceamount}
              onChange={(e) =>
                jobEditStore.updatePayment(
                  e.target.value,
                  indexVal,
                  "invoiceamount"
                )
              }
            />
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <b>Voucher Number</b>
            <TextField
              size="small"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Voucher Number"
              name="vouchernumber"
              autoComplete="vouchernumber"
              autoFocus
              type="text"
              value={singlepayment.vouchernumber}
              onChange={(e) =>
                jobEditStore.updatePayment(
                  e.target.value,
                  indexVal,
                  "vouchernumber"
                )
              }
            />
          </Grid>
          <Grid item xs={12} sm={3} md={3}>
            <b>Invoice Number</b>
            <TextField
              size="small"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Invoice Number"
              name="invoicenumber"
              autoComplete="invoicenumber"
              autoFocus
              type="text"
              value={singlepayment.invoicenumber}
              onChange={(e) =>
                jobEditStore.updatePayment(
                  e.target.value,
                  indexVal,
                  "invoicenumber"
                )
              }
            />
          </Grid>
          <Grid item xs={12} sm={2} md={2}>
            <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              size="small"
            >
              <b>Remarks</b>
              <TextArea
                placeholder="Payment Remarks"
                autoSize
                value={singlepayment.remarks}
                onChange={(e) =>
                  jobEditStore.updatePayment(
                    e.target.value,
                    indexVal,
                    "remarks"
                  )
                }
              />
            </FormControl>
          </Grid>
          <Grid item xs={2} sm={1} md={1}>
            <Button
              variant="outline-danger"
              block
              onClick={() =>
                jobEditStore.postPaymentUpdate(
                  [],
                  jobEditStore.payments[indexVal].id,
                  "destroy",
                  indexVal
                )
              }
            >
              <i className="fas fa-times"></i>
            </Button>
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default observer(PaymentRow);
