import React from "react";
// First way to import
import { observer } from "mobx-react";
import { Button, Container, Row, Col } from "react-bootstrap";
import TextField from "@material-ui/core/TextField";
import FormControl from "@material-ui/core/FormControl";
import { Select, Input, DatePicker } from "antd";
import moment from "moment";

import editJobStore from "../../Stores/EditJobStore";

const { Option } = Select;
const { TextArea } = Input;

class TrackingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let imtracker = editJobStore.imtracker.map((track, index) => (
      <div key={index}>
        Track #{index + 1}:
        <Row>
          <Col md={4}>
            <b
              style={{
                color: track.title === "" ? "red" : "black",
              }}
            >
              Tracking Title:
            </b>
            <TextField
              variant="outlined"
              size="small"
              style={{ width: "100%" }}
              value={track.title}
              onChange={(e) =>
                editJobStore.updateTracker("title", e.target.value, index)
              }
            />
          </Col>
          <Col md={2}>
            <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              size="small"
            >
              <b
                style={{
                  color: track.status === "" ? "red" : "black",
                }}
              >
                Status:
              </b>
              <Select
                showSearch
                error
                width="100%"
                optionFilterProp="children"
                onChange={(e) => editJobStore.updateTracker("status", e, index)}
                value={track.status}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value={"Pending"}>Pending</Option>
                <Option value={"Completed"}>Completed</Option>
              </Select>
            </FormControl>
          </Col>
          <Col md={3}>
            <FormControl variant="outlined" style={{ width: "100%" }}>
              <b>Original Date</b>
              <DatePicker
                disabled={track.id !== 0}
                format={"DD-MMM-YY"}
                size="medium"
                defaultValue={
                  (track.originaldate !== null) & (track.originaldate !== "") &&
                  moment(track.originaldate)
                }
                onChange={(date) =>
                  editJobStore.updateTracker("originaldate", date, index)
                }
              />
            </FormControl>
          </Col>
          <Col md={3}>
            <FormControl variant="outlined" style={{ width: "100%" }}>
              <b>Revised Date</b>
              <DatePicker
                disabled={track.id === 0}
                format={"DD-MMM-YY"}
                size="medium"
                value={
                  (track.reviseddate !== null) & (track.reviseddate !== "") &&
                  moment(track.reviseddate)
                }
                defaultValue={
                  (track.reviseddate !== null) & (track.reviseddate !== "") &&
                  moment(track.reviseddate)
                }
                onChange={(date) =>
                  editJobStore.updateTracker("reviseddate", date, index)
                }
              />
            </FormControl>
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              size="small"
            >
              <b
                style={{
                  color: track.order === "" ? "red" : "black",
                }}
              >
                #{index + 1}) Order:
              </b>
              <Select
                showSearch
                error
                width="100%"
                optionFilterProp="children"
                onChange={(e) => editJobStore.updateTracker("order", e, index)}
                value={track.order}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
              >
                <Option value={0}>0</Option>
                <Option value={1}>1</Option>
                <Option value={2}>2</Option>
                <Option value={3}>3</Option>
                <Option value={4}>4</Option>
                <Option value={5}>5</Option>
                <Option value={6}>6</Option>
                <Option value={7}>7</Option>
                <Option value={8}>8</Option>
                <Option value={9}>9</Option>
              </Select>
            </FormControl>
          </Col>
          <Col>
            <FormControl
              variant="outlined"
              style={{ width: "100%" }}
              size="small"
            >
              <b>Remarks</b>
              <TextArea
                size="small"
                placeholder="Payment Remarks"
                autoSize
                value={track.remark}
                onChange={(e) =>
                  editJobStore.updateTracker("remark", e.target.value, index)
                }
              />
            </FormControl>
          </Col>
          <Col md={3}>
            <Row>Delete</Row>
            <Row>
              <Button
                variant="outline-danger"
                block
                size="sm"
                onClick={() => editJobStore.updateTracker("delete", 0, index)}
              >
                x
              </Button>
            </Row>
          </Col>
        </Row>
        <hr />
      </div>
    ));

    return (
      <div>
        <Container style={{ maxWidth: "98%" }}>
          <Row>
            <Col>
              <Button
                variant="outline-success"
                onClick={() => editJobStore.updateTracker("add", 0, 0)}
              >
                Add a Tracking Event
              </Button>
            </Col>
            <Col>
              <h3>{editJobStore.imtracker.length} Tracking Events:</h3>
            </Col>
          </Row>
          <hr />
          <br />
          {imtracker}
          <br />
          <br />
        </Container>
      </div>
    );
  }
}

export default observer(TrackingPage);
