import React from "react";
import moment from "moment";
// First way to import
import { observer } from "mobx-react";
import { Button, Container, Row, Col } from "react-bootstrap";
import inventoryStore from "../Stores/InventoryStore";

class JobUpdatePage1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div>
        {inventoryStore.selectedjob.lastdate ===
          inventoryStore.selectedjob.start && (
          <div>
            <b>Total Job CBM:</b>
            <Container>
              <Row>
                <Col>
                  {inventoryStore.cbm >= 5 && (
                    <Button
                      onClick={() => inventoryStore.updateInventory(-5, "cbm")}
                      size="sm"
                      variant={"outline-danger"}
                    >
                      -5
                    </Button>
                  )}
                </Col>
                <Col>
                  {inventoryStore.cbm >= 1 && (
                    <Button
                      onClick={() => inventoryStore.updateInventory(-1, "cbm")}
                      size="sm"
                      variant={"outline-danger"}
                    >
                      -1
                    </Button>
                  )}
                </Col>
                <Col>
                  <Button
                    variant={"outline-dark"}
                    disabled
                    style={{ fontWeight: "bold" }}
                  >
                    {inventoryStore.cbm}
                  </Button>
                </Col>
                <Col>
                  <Button
                    variant={"outline-success"}
                    onClick={() => inventoryStore.updateInventory(1, "cbm")}
                    size="sm"
                  >
                    +1
                  </Button>
                </Col>
                <Col>
                  <Button
                    variant={"outline-success"}
                    onClick={() => inventoryStore.updateInventory(5, "cbm")}
                    size="sm"
                  >
                    +5
                  </Button>
                </Col>
              </Row>
            </Container>
            <hr />
          </div>
        )}

        <h4>
          Today's Job Activity Update{" "}
          {moment(inventoryStore.selectedjob.start).format("DD-MMM-YYYY")}
        </h4>
        <h5>{inventoryStore.selectedjob.title}</h5>
        <hr />

        <b>Truck Trips:</b>
        <Container>
          <Row>
            <Col>
              {inventoryStore.trip >= 5 && (
                <Button
                  onClick={() => inventoryStore.updateInventory(-5, "trip")}
                  size="sm"
                  variant={"outline-danger"}
                >
                  -5
                </Button>
              )}
            </Col>
            <Col>
              {inventoryStore.trip >= 1 && (
                <Button
                  onClick={() => inventoryStore.updateInventory(-1, "trip")}
                  size="sm"
                  variant={"outline-danger"}
                >
                  -1
                </Button>
              )}
            </Col>
            <Col>
              <Button
                variant={"outline-dark"}
                disabled
                style={{ fontWeight: "bold" }}
              >
                {inventoryStore.trip}
              </Button>
            </Col>
            <Col>
              <Button
                variant={"outline-success"}
                onClick={() => inventoryStore.updateInventory(1, "trip")}
                size="sm"
              >
                +1
              </Button>
            </Col>
            <Col>
              <Button
                variant={"outline-success"}
                onClick={() => inventoryStore.updateInventory(5, "trip")}
                size="sm"
              >
                +5
              </Button>
            </Col>
          </Row>
        </Container>
        <hr />
        <b>External Trips Today:</b>
        <Container>
          <Row>
            <Col>
              {inventoryStore.exttruck >= 5 && (
                <Button
                  onClick={() => inventoryStore.updateInventory(-5, "exttruck")}
                  size="sm"
                  variant={"outline-danger"}
                >
                  -5
                </Button>
              )}
            </Col>
            <Col>
              {inventoryStore.exttruck >= 1 && (
                <Button
                  onClick={() => inventoryStore.updateInventory(-1, "exttruck")}
                  size="sm"
                  variant={"outline-danger"}
                >
                  -1
                </Button>
              )}
            </Col>
            <Col>
              <Button
                variant={"outline-dark"}
                disabled
                style={{ fontWeight: "bold" }}
              >
                {inventoryStore.exttruck}
              </Button>
            </Col>
            <Col>
              <Button
                variant={"outline-success"}
                onClick={() => inventoryStore.updateInventory(1, "exttruck")}
                size="sm"
              >
                +1
              </Button>
            </Col>
            <Col>
              <Button
                variant={"outline-success"}
                onClick={() => inventoryStore.updateInventory(5, "exttruck")}
                size="sm"
              >
                +5
              </Button>
            </Col>
          </Row>
        </Container>
        <hr />
        <b>External Hired Crew Today:</b>
        <Container>
          <Row>
            <Col>
              {inventoryStore.extcrew >= 5 && (
                <Button
                  onClick={() => inventoryStore.updateInventory(-5, "extcrew")}
                  size="sm"
                  variant={"outline-danger"}
                >
                  -5
                </Button>
              )}
            </Col>
            <Col>
              {inventoryStore.extcrew >= 1 && (
                <Button
                  onClick={() => inventoryStore.updateInventory(-1, "extcrew")}
                  size="sm"
                  variant={"outline-danger"}
                >
                  -1
                </Button>
              )}
            </Col>
            <Col>
              <Button
                variant={"outline-dark"}
                disabled
                style={{ fontWeight: "bold" }}
              >
                {inventoryStore.extcrew}
              </Button>
            </Col>
            <Col>
              <Button
                variant={"outline-success"}
                onClick={() => inventoryStore.updateInventory(1, "extcrew")}
                size="sm"
              >
                +1
              </Button>
            </Col>
            <Col>
              <Button
                variant={"outline-success"}
                onClick={() => inventoryStore.updateInventory(5, "extcrew")}
                size="sm"
              >
                +5
              </Button>
            </Col>
          </Row>
        </Container>
        <hr />
        <b>External Carpenters Today:</b>
        <Container>
          <Row>
            <Col>
              {inventoryStore.extcarp >= 5 && (
                <Button
                  onClick={() => inventoryStore.updateInventory(-5, "extcarp")}
                  size="sm"
                  variant={"outline-danger"}
                >
                  -5
                </Button>
              )}
            </Col>
            <Col>
              {inventoryStore.extcarp >= 1 && (
                <Button
                  onClick={() => inventoryStore.updateInventory(-1, "extcarp")}
                  size="sm"
                  variant={"outline-danger"}
                >
                  -1
                </Button>
              )}
            </Col>
            <Col>
              <Button
                variant={"outline-dark"}
                disabled
                style={{ fontWeight: "bold" }}
              >
                {inventoryStore.extcarp}
              </Button>
            </Col>
            <Col>
              <Button
                variant={"outline-success"}
                onClick={() => inventoryStore.updateInventory(1, "extcarp")}
                size="sm"
              >
                +1
              </Button>
            </Col>
            <Col>
              <Button
                variant={"outline-success"}
                onClick={() => inventoryStore.updateInventory(5, "extcarp")}
                size="sm"
              >
                +5
              </Button>
            </Col>
          </Row>
        </Container>
        <hr />
        <Row>
          <Col>
            <Button
              block
              variant="danger"
              onClick={() => {
                inventoryStore.resetPage();
                inventoryStore.changePage(0);
              }}
            >
              Cancel
            </Button>
          </Col>
          <Col>
            <Button
              block
              variant="outline-success"
              onClick={() => inventoryStore.changePage(2)}
            >
              Next
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
}

export default observer(JobUpdatePage1);
