import React, { Component } from "react";
import { observer } from "mobx-react";
import moment from "moment";

import eventStore from "../Stores/EventStore";
import settingStore from "../Stores/SettingStore";

import {
  SummaryState,
  GroupingState,
  IntegratedGrouping,
  IntegratedSummary,
  DataTypeProvider
} from "@devexpress/dx-react-grid";
import {
  Grid,
  TableHeaderRow,
  TableGroupRow,
  TableSummaryRow,
  VirtualTable
} from "@devexpress/dx-react-grid-material-ui";

import { DatePicker } from "antd";

const { RangePicker } = DatePicker;

const CurrencyFormatter = ({ value }) =>
  value.toLocaleString("en-US", { style: "currency", currency: "KWD" });

const CurrencyTypeProvider = props => (
  <DataTypeProvider formatterComponent={CurrencyFormatter} {...props} />
);

class DynamicFinancials extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editPayment: false,
      financialsLoaded: false,
      modalShow: false,
      indexVal: 0,
      filter: false
    };
  }

  render() {
    // let financialdata = [
    //   {
    //     category2: "Test",
    //     invoiceamount: 2,
    //     paymentdate: "hellow",
    //     category1: "type"
    //   },
    //   {
    //     category2: "Test",
    //     invoiceamount: 1,
    //     paymentdate: "hellow",
    //     category1: "type"
    //   },
    //   {
    //     category2: "Test2",
    //     invoiceamount: 3,
    //     paymentdate: "hellow",
    //     category1: "type"
    //   },
    //   {
    //     category2: "Test2",
    //     invoiceamount: 4,
    //     paymentdate: "hellow",
    //     category1: "type"
    //   }
    // ];

    let financialdata = eventStore.financialprofitloss.filter(
      row =>
        (moment(row.paymentdate) >= moment(settingStore.startdate)) &
        (moment(row.paymentdate) <= moment(settingStore.enddate))
    );
    let columns = [
      { name: "id", title: "ID" },
      { name: "expenseheading", title: "Checked" },
      { name: "newinvoice", title: "Amount" },
      { name: "category1", title: "Department" },
      { name: "category2", title: "Type" },
      { name: "category3", title: "Cash" },
      { name: "category4", title: "General" },
      { name: "category5", title: "Specific" },
      { name: "paymentdate", title: "Date" },
      { name: "bankaccount", title: "Account" },
      { name: "paymentmethod", title: "Method" },
      { name: "invoicenumber", title: "Invoice" },
      { name: "job", title: "Job ID" },
      { name: "clientname", title: "Client" },
      { name: "remarks", title: "Remarks" }
    ];

    const currencyColumns = ["newinvoice"];

    const totalSummaryItems = [
      { columnName: "id", type: "count" },
      { columnName: "newinvoice", type: "sum" }
    ];

    const groupSummaryItems = [
      {
        columnName: "category1",
        type: "count"
      },
      { columnName: "category2", type: "count" },
      { columnName: "newinvoice", type: "sum", alignByColumn: true }
    ];

    return (
      <div>
        <h2>
          <b>Profit and Loss Statement </b>
          <i>
            ({moment(settingStore.startdate).format("DD-MMM-YY")} to{" "}
            {moment(settingStore.enddate).format("DD-MMM-YY")}){" "}
          </i>
        </h2>
        <RangePicker
          onChange={event =>
            settingStore.changeWorkDate(event[0]._d, event[1]._d)
          }
        />
        <Grid rows={financialdata} columns={columns}>
          <CurrencyTypeProvider for={currencyColumns} />
          <GroupingState
            grouping={[
              { columnName: "bankaccount" },
              { columnName: "category1" },
              { columnName: "category2" },
              { columnName: "category4" },
              { columnName: "category5" }
            ]}
          />
          <SummaryState
            totalItems={totalSummaryItems}
            groupItems={groupSummaryItems}
          />
          <IntegratedGrouping />
          <IntegratedSummary />

          <VirtualTable />
          <TableHeaderRow />
          <TableGroupRow showColumnsWhenGrouped />
          <TableSummaryRow />
        </Grid>
      </div>
    );
  }
}

export default observer(DynamicFinancials);
