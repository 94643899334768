import React, { Component } from "react";
import { observer } from "mobx-react";

import managementStore from "../Stores/ManagementStore";
import storageStore from "../Stores/StorageStore";

import FilterModule from "../FilterModules/FilterModule";
import settingStore from "../Stores/SettingStore";
import moment from "moment";

import ReactApexChart from "react-apexcharts";

import { SyncLoader } from "react-spinners";

class StoragePipeline extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options1: {
        chart: {
          type: "bar",
          height: 100,
          stacked: true,
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        xaxis: {
          categories: ["Pipeline", "Target"],
          labels: {
            formatter: function (val) {
              return val + "K";
            },
          },
        },
        colors: ["#003705", "#26A65B", "#d19d00", "#6e757c", "#ffbac9"],
        tooltip: {
          y: {
            formatter: function (val) {
              return 1000 * val + " KD";
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 40,
        },
      },
      options2: {
        chart: {
          type: "bar",
          height: 100,
          stacked: true,
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        stroke: {
          width: 1,
          colors: ["#fff"],
        },
        xaxis: {
          categories: ["Pipeline", "Target"],
          labels: {
            formatter: function (val) {
              return val;
            },
          },
        },
        colors: ["#003705", "#26A65B", "#d19d00", "#6e757c", "#ffbac9"],
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          offsetX: 40,
        },
      },
    };
  }

  render() {
    if (!managementStore.pipelineloaded) {
      managementStore.fetchPipeline();
      return (
        <SyncLoader
          sizeUnit={"px"}
          size={50}
          color={"#0017FF"}
          loading={true}
        />
      );
    }

    let jobFilters = managementStore.filterjobs;

    let startDate = moment(settingStore.calstartdate);
    let endDate = moment(settingStore.calenddate);
    let dateDiff = endDate.diff(startDate, "days");
    let stJob = storageStore.managementStorage;

    if (!jobFilters.lead) {
      stJob = stJob.filter((job) => job.status !== "Lead");
      stJob = stJob.filter((job) => job.status !== "Estimation");
    }

    if (!jobFilters.proposal)
      stJob = stJob.filter((job) => job.status !== "Proposal");

    if (!jobFilters.booked)
      stJob = stJob.filter((job) => job.status !== "Booked");

    if (!jobFilters.converted)
      stJob = stJob.filter((job) => job.status !== "Converted");

    let storageCBM = 0;
    let storagePrice = 0;
    let storageProfit = 0;

    let stconvCBM = 0;
    let stconvPrice = 0;
    let stconvProfit = 0;
    let stbookCBM = 0;
    let stbookPrice = 0;
    let stbookProfit = 0;
    let stpropCBM = 0;
    let stpropPrice = 0;
    let stpropProfit = 0;
    let stleadCBM = 0;
    let stleadPrice = 0;
    let stleadProfit = 0;
    let sttarCBM = 400;
    let sttarPrice = 1000;
    let sttarProfit = 1000;

    for (let j = 0; j < dateDiff; j++) {
      for (let i = 0; i < stJob.length; i++) {
        if (
          (moment(stJob[i].storagestartdate) <=
            moment(startDate).add(j, "days")) &
          (moment(stJob[i].storageenddate) >= moment(startDate).add(j, "days"))
        ) {
          storageCBM += parseFloat(stJob[i].storagecbm) / parseFloat(dateDiff);
          if (parseFloat(stJob[i].monthlycost) > 0) {
            storagePrice +=
              parseFloat(stJob[i].monthlycost) / parseFloat(dateDiff);
            storageProfit +=
              parseFloat(stJob[i].monthlycost) / parseFloat(dateDiff);
          }

          if (stJob[i].status === "Converted") {
            stconvCBM += parseFloat(stJob[i].storagecbm) / parseFloat(dateDiff);
            if (parseFloat(stJob[i].monthlycost) > 0) {
              stconvPrice +=
                parseFloat(stJob[i].monthlycost) / parseFloat(dateDiff);
              stconvProfit +=
                parseFloat(stJob[i].monthlycost) / parseFloat(dateDiff);
            }
          } else if (stJob[i].status === "Booked") {
            stbookCBM += parseFloat(stJob[i].storagecbm) / parseFloat(dateDiff);
            if (parseFloat(stJob[i].monthlycost) > 0) {
              stbookPrice +=
                parseFloat(stJob[i].monthlycost) / parseFloat(dateDiff);
              stbookProfit +=
                parseFloat(stJob[i].monthlycost) / parseFloat(dateDiff);
            }
          } else if (stJob[i].status === "Proposal") {
            stpropCBM += parseFloat(stJob[i].storagecbm) / parseFloat(dateDiff);
            if (parseFloat(stJob[i].monthlycost) > 0) {
              stpropPrice +=
                parseFloat(stJob[i].monthlycost) / parseFloat(dateDiff);
              stpropProfit +=
                parseFloat(stJob[i].monthlycost) / parseFloat(dateDiff);
            }
          } else if (stJob[i].status === "Lead") {
            stleadCBM += parseFloat(stJob[i].storagecbm) / parseFloat(dateDiff);
            if (parseFloat(stJob[i].monthlycost) > 0) {
              stleadPrice +=
                parseFloat(stJob[i].monthlycost) / parseFloat(dateDiff);
              stleadProfit +=
                parseFloat(stJob[i].monthlycost) / parseFloat(dateDiff);
            }
          }
        }
      }
    }

    storageCBM = Math.round(10 * storageCBM) / 10;
    storagePrice = Math.round(10 * storagePrice) / 10;
    storageProfit = Math.round(10 * storageProfit) / 10;

    stconvCBM = Math.round(10 * stconvCBM) / 10;
    stconvPrice = Math.round(10 * stconvPrice) / 10;
    stconvProfit = Math.round(10 * stconvProfit) / 10;
    stbookCBM = Math.round(10 * stbookCBM) / 10;
    stbookPrice = Math.round(10 * stbookPrice) / 10;
    stbookProfit = Math.round(10 * stbookProfit) / 10;
    stpropCBM = Math.round(10 * stpropCBM) / 10;
    stpropPrice = Math.round(10 * stpropPrice) / 10;
    stpropProfit = Math.round(10 * stpropProfit) / 10;
    stleadCBM = Math.round(10 * stleadCBM) / 10;
    stleadPrice = Math.round(10 * stleadPrice) / 10;
    stleadProfit = Math.round(10 * stleadProfit) / 10;
    sttarCBM = Math.round(10 * sttarCBM) / 10;
    sttarPrice = Math.round(10 * sttarPrice) / 10;
    sttarProfit = Math.round(10 * sttarProfit) / 10;

    let graphDataStoragePrice = [
      { name: "Converted", data: [stconvPrice, 0] },
      { name: "Booked", data: [stbookPrice, 0] },
      { name: "Proposal", data: [stpropPrice, 0] },
      { name: "Lead", data: [stleadPrice, 0] },
      { name: "Target", data: [0, sttarPrice] },
    ];
    let graphDataStorageCBM = [
      { name: "Converted", data: [stconvCBM, 0] },
      { name: "Booked", data: [stbookCBM, 0] },
      { name: "Proposal", data: [stpropCBM, 0] },
      { name: "Lead", data: [stleadCBM, 0] },
      { name: "Target", data: [0, sttarCBM] },
    ];
    let graphDataStorageProfit = [
      { name: "Converted", data: [stconvProfit, 0] },
      { name: "Booked", data: [stbookProfit, 0] },
      { name: "Proposal", data: [stpropProfit, 0] },
      { name: "Lead", data: [stleadProfit, 0] },
      { name: "Target", data: [0, sttarProfit] },
    ];

    return (
      <div>
        <FilterModule type={"Storage"} />
        <h3>
          Total Price: {storagePrice} KD (
          {Math.round((1000 * storagePrice) / sttarPrice) / 10} %)
        </h3>
        <ReactApexChart
          options={this.state.options1}
          series={graphDataStoragePrice}
          type="bar"
          height={200}
        />
        <hr />
        <h3>
          Total CBM: {storageCBM} CBM (
          {Math.round((1000 * storageCBM) / sttarCBM) / 10}%)
        </h3>
        <ReactApexChart
          options={this.state.options2}
          series={graphDataStorageCBM}
          type="bar"
          height={200}
        />
        <hr />
        <h3>
          Total Profit: {storageProfit} KD (
          {Math.round((1000 * storageProfit) / sttarProfit) / 10}%)
        </h3>
        <ReactApexChart
          options={this.state.options1}
          series={graphDataStorageProfit}
          type="bar"
          height={200}
        />
        <hr />
        <br />
        <hr />
        <br />
        <br />
        <br />
      </div>
    );
  }
}

export default observer(StoragePipeline);
