import React from "react";
// First way to import
import moment from "moment";
import { observer } from "mobx-react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { Button } from "react-bootstrap";
import ReactApexChart from "react-apexcharts";

import eventStore from "../Stores/EventStore";
import settingStore from "../Stores/SettingStore";

import userStore from "../Stores/UserStore";
import ReactGA from "react-ga";

function initializeReactGA() {
  if (userStore.user.username !== "jazzaf") {
    ReactGA.initialize("UA-163244126-1");
    ReactGA.pageview(window.location.pathname);
    ReactGA.event({
      category: window.location.pathname,
      action: userStore.user.username,
      label: new Date().toLocaleTimeString()
    });
  }
}

moment.locale("ko", {
  week: {
    dow: 6,
    doy: 6
  }
});

class TeamRank extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      events: [],
      accumulative: false,
      type: "price"
    };
  }

  componentDidMount() {
    initializeReactGA();
  }

  render() {
    let graphValAll = [];
    let xAxisDates = [];

    let accprice = 0;
    let acccbm = 0;

    let startDate = moment(settingStore.calstartdate);
    let endDate = moment(settingStore.calenddate);
    let dateDiff = endDate.diff(startDate, "days");
    let newDate;
    let jobdates = eventStore.filterConBooked;

    for (let k = 0; k < dateDiff; k++) {
      newDate = moment(startDate)
        .add(k, "days")
        .format("DD-MMM-YY");
      xAxisDates.push(moment(newDate).format("DD"));

      for (let i = 0; i < jobdates.length; i++) {
        if (
          moment(jobdates[i].start).format("DD-MMM-YY") ===
          moment(startDate)
            .add(k, "days")
            .format("DD-MMM-YY")
        ) {
          accprice += jobdates[i].dailyprice;
          acccbm += jobdates[i].dailycbm;
        }
      }
      if (this.state.type === "price") {
        graphValAll.push(Math.round(accprice * 10) / 10);
      } else if (this.state.type === "cbm") {
        graphValAll.push(Math.round(acccbm * 10) / 10);
      }
    }

    let lineOptions = {
      options: {
        chart: {
          shadow: {
            enabled: true,
            color: "#000",
            top: 18,
            left: 7,
            blur: 10,
            opacity: 1
          },
          toolbar: {
            show: false
          }
        },
        tooltip: {
          enabled: true,
          enabledOnSeries: undefined,
          shared: true,
          followCursor: false,
          intersect: false,
          inverseOrder: false,
          custom: undefined,
          fillSeriesColor: false,
          theme: false,
          style: {
            fontSize: "12px",
            fontFamily: undefined
          },
          onDatasetHover: {
            highlightDataSeries: false
          },
          x: {
            show: true,
            format: "dd MMM",
            formatter: undefined
          },
          y: {
            formatter: undefined,
            title: {
              formatter: seriesName => seriesName
            }
          },
          z: {
            formatter: undefined,
            title: "Size: "
          },
          marker: {
            show: true
          },

          fixed: {
            enabled: false,
            position: "topRight",
            offsetX: 0,
            offsetY: 0
          }
        },
        colors: ["#d4001c", "#00a841", "#0069a6", "#000000", "#b0b0b0"],
        dataLabels: {
          enabled: true
        },
        stroke: {
          curve: "smooth"
        },
        title: {
          text: `Performance for the month of ${moment(
            settingStore.calstartdate
          ).format("MMMM, YYYY")}`,
          align: "center",
          floating: false,
          style: {
            fontSize: "24px",
            color: "#263238",
            fontWeight: "bold"
          }
        },
        grid: {
          borderColor: "#e7e7e7",
          row: {
            colors: ["#f3f3f3", "transparent"], // takes an array which will be repeated on columns
            opacity: 0.5
          }
        },
        markers: {
          size: 6
        },
        xaxis: {
          categories: xAxisDates,
          title: {
            text: moment(settingStore.calstartdate).format("MMMM - YYYY")
          }
        },
        yaxis: {
          title: {
            text:
              this.state.type.charAt(0).toUpperCase() + this.state.type.slice(1)
          },
          min: 0
        },
        legend: {
          position: "top",
          horizontalAlign: "left",
          floating: true,
          offsetY: -25,
          offsetX: -5,
          fontSize: "16px",
          onItemClick: {
            toggleDataSeries: false
          }
        }
      },
      series: [
        {
          name: "All Team",
          data: graphValAll
        }
      ]
    };

    return (
      <div>
        <Button
          onClick={() => {
            if (this.state.type === "cbm") {
              this.setState({ type: "price" });
            } else {
              this.setState({ type: "cbm" });
            }
          }}
          variant="success"
        >
          {this.state.type === "cbm" ? "See Price" : "See CBM"}
        </Button>
        <ReactApexChart
          options={lineOptions.options}
          series={lineOptions.series}
          type="line"
          height="600"
        />
        <b />
        <Button
          onClick={e => {
            let oldDate = moment(startDate);
            oldDate = oldDate.subtract(1, "d");
            settingStore.changeCalDate(oldDate);
          }}
        >
          Last Month (
          {moment(moment(startDate).subtract(1, "days")).format("MMM-YY")})
        </Button>
        {moment(startDate).format("MMM-YY") !== moment().format("MMM-YY") && (
          <Button
            onClick={e => {
              let oldDate = moment(endDate);
              oldDate = oldDate.add(1, "d");
              settingStore.changeCalDate(oldDate);
            }}
          >
            Next Month (
            {moment(moment(endDate).add(1, "days")).format("MMM-YY")})
          </Button>
        )}
      </div>
    );
  }
}

export default observer(TeamRank);
