import React from "react";
// First way to import
import { observer } from "mobx-react";
import { Button, Modal } from "react-bootstrap";

import TextField from "@material-ui/core/TextField";
import jobEditStore from "../Stores/JobEditStore";

import "react-datepicker/dist/react-datepicker.css";
import EditablePackingList from "./ItemsList/EditablePackingList";
import ItemsListPDF from "./ItemsList/ItemsListPDF";

class PackingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { show: false, newroom: "" };
    this.handleOpen = this.handleOpen.bind(this);
    this.newRoomName = this.newRoomName.bind(this);
  }
  newRoomName = e => {
    this.setState({ newroom: e.target.value });
  };

  handleClose = () => {
    this.setState({ show: false, newroom: "" });
  };
  handleOpen = e => {
    this.setState({ show: true });
  };

  render() {
    let roomRows;

    roomRows = jobEditStore.rooms.map((room, index) => (
      <div key={index}>
        <EditablePackingList roomindex={index} />
      </div>
    ));
    return (
      <div>
        <Modal show={this.state.show} onHide={this.handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Modal heading</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <TextField
              size="small"
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label="Room Name"
              name="newroom"
              autoComplete="newroom"
              autoFocus
              type="text"
              style={{ color: "red" }}
              value={this.state.newroom}
              onChange={e => this.newRoomName(e)}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              Close
            </Button>
            <Button
              variant="primary"
              onClick={() => {
                jobEditStore.addRoom(this.state.newroom);
                this.handleClose();
              }}
            >
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
        <h6>Quality Score = {jobEditStore.packinglistQuality}%</h6>
        <Button variant="success" onClick={this.handleOpen}>
          + Add Room
        </Button>
        <hr></hr>
        {roomRows}
        <hr />
        <ItemsListPDF />
      </div>
    );
  }
}

export default observer(PackingPage);
